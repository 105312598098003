import React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
} from '@mui/material';
import { ErrorAlert } from '../../../../components/Alerts';
import { DialogTitle } from '../../../../components/Alerts/ConfirmAlertDialogTitle';
import {
  DataTypes,
  PlacementKind,
  useFieldDeviceCreateDeviceModelsAndSitesQuery,
  useFieldDeviceCreateGatewayDevicesQuery,
} from '../../../../__generated__/types';
import {
  FieldDeviceCreate,
  FieldDeviceCreateData,
} from '../../common/FieldDeviceCreate';
import { useValidateDeviceIdentifier } from '../../common/useValidateDeviceIdentifier';

const classes = createClasses({
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
});

export type { FieldDeviceCreateData };

type CreateDeviceDialogContainerProps = {
  open: boolean;
  gatewayId: string;
  placementId?: string;
  placementType?: PlacementKind;
  usedDeviceIdentifiers: {
    devices: Array<{
      id: string;
      deviceIdentifier: string;
      parentDeviceId: string;
    }>;
    parentDevice: {
      deviceIdentifier: string;
    };
  };
  onCreate: (createdDevice: FieldDeviceCreateData) => void;
  onCancel: () => void;
};
export const DeviceDetailsDialogContainer: React.FC<
  CreateDeviceDialogContainerProps
> = ({
  open,
  gatewayId,
  placementId,
  placementType,
  usedDeviceIdentifiers,
  onCreate,
  onCancel,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const { validationState, validate } = useValidateDeviceIdentifier(
    usedDeviceIdentifiers,
  );

  const {
    loading: loadingDeviceModelsAndSites,
    error: errorDeviceModelsAndSites,
    data: dataDeviceModelsAndSites,
  } = useFieldDeviceCreateDeviceModelsAndSitesQuery();
  const deviceModels = dataDeviceModelsAndSites?.deviceModels?.items ?? [];
  const allSites = dataDeviceModelsAndSites?.sites?.items ?? [];

  const {
    loading: loadingGateways,
    error: errorGateways,
    data: dataGateways,
  } = useFieldDeviceCreateGatewayDevicesQuery();
  const gateways = dataGateways?.devices?.items;

  const allDeviceModels = deviceModels.map((dm) => ({
    id: dm.id,
    name: dm.name,
    isGateway: dm.deviceType?.isGateway ?? false,
    deviceModelAttributeSets: dm.deviceModelAttributeSets.map(
      (attributeSet) => ({
        id: attributeSet.id,
        name: attributeSet.name,
        attributes: attributeSet.mappingAttributeDeviceModelAttributeSets.map(
          (attribute) => ({
            id: attribute.attribute.id,
            name: attribute.attribute.name,
            required: attribute.attribute.required ?? false,
            dataType: attribute.attribute.dataType,
            value:
              attribute.attribute.required &&
              attribute.attribute.dataType === DataTypes.Bool
                ? 'false'
                : '',
          }),
        ),
      }),
    ),
  }));

  const allGateways = (gateways ?? []).map((gatewayDevice) => ({
    id: gatewayDevice.id,
    name: gatewayDevice.name,
  }));

  const disabledFieldIds = ['parentDeviceId'];

  const getBuildingId = () => {
    let buildingId;
    allSites.forEach((site) => {
      const building = site.buildings.find((b) =>
        b.storeys.find((storey) =>
          storey.zones.find((zone) => zone.id === placementId),
        ),
      );
      buildingId = building?.id;
    });
    return buildingId;
  };
  const buildingId =
    placementType === PlacementKind.Zone ? getBuildingId() : placementId;

  return (
    <>
      <Dialog
        onClose={onCancel}
        open={open}
        fullWidth
        maxWidth="lg"
        aria-labelledby="save-device-dialog-title"
        aria-describedby="save-device-dialog-description"
      >
        <DialogTitle
          id="confirm-alert-dialog-title"
          closeButton={true}
          onClose={onCancel}
        >
          {t('devices:mappings.edit.createDeviceDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-alert-dialog-description">
            {t('devices:mappings.edit.createDeviceDialog.message')}
          </DialogContentText>
          <Divider className={classes.divider} />
          <FieldDeviceCreate
            loading={loadingGateways || loadingDeviceModelsAndSites}
            gatewayId={gatewayId}
            placementId={buildingId}
            placementType={PlacementKind.Building}
            allSites={allSites}
            allGateways={allGateways}
            allDeviceModels={allDeviceModels}
            validateDeviceIdentifier={validate}
            deviceIdentifierValidationState={validationState}
            onSubmit={onCreate}
            onDiscard={onCancel}
            disabledFieldIds={disabledFieldIds}
            buttonsAtBottom
          />
        </DialogContent>
      </Dialog>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorGateways, errorDeviceModelsAndSites]}
      />
    </>
  );
};
