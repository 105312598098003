import {
  CommissioningSvcDeviceModelMatch,
  CommissioningSvcMappingDraftDevice,
  CommissioningSvcMappingDraftDeviceMapping,
} from '@kp/rest-api-javascript-sdk';

export type BACnetTarget = {
  deviceIdentifier: string;
  deviceName?: string;
  objectTypeName?: string;
  objectIdentifier: string;
  objectName?: string;
  description?: string;
  unitName?: string;
  unitUris?: string[];
  unitUri?: string;
  lowLimit?: number;
  highLimit?: number;
  units?: number;
};

export type DeviceModelCapability = {
  id: string;
  name: string;
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
  isMatch: boolean;
  unitUris?: string[];
};
export type DeviceModel = {
  id: string;
  name: string;
  deviceModelCapabilities: DeviceModelCapability[];
};

export type Capability = {
  id: string;
  name: string;
  isMapped: boolean;
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
};
export type CreatedDevice = {
  name: string;
  deviceModelId: string;
};

export type MappedDatapoint = BACnetTarget & {
  isSelected?: boolean;
  model?: DeviceModel;
  device?: CreatedDevice;
  capabilityOptions?: Capability[];
  capability?: Capability;
};

export const findByTarget = (
  targets: MappedDatapoint[],
  target: BACnetTarget,
) =>
  targets.find(
    (t) =>
      t.deviceIdentifier === target.deviceIdentifier &&
      t.objectIdentifier === target.objectIdentifier,
  );
export const findIndexByTarget = (
  targets: BACnetTarget[],
  target: BACnetTarget,
) =>
  targets.findIndex(
    (t) =>
      t.deviceIdentifier === target.deviceIdentifier &&
      t.objectIdentifier === target.objectIdentifier,
  );

export const stateUpdateByModelSelection =
  (model?: DeviceModel) => (oldMappingState: MappedDatapoint[]) => {
    return oldMappingState.map((dp) =>
      dp.isSelected
        ? {
            ...dp,
            model,
            device: dp.model?.id === model?.id ? dp.device : undefined,
            capability: dp.model?.id === model?.id ? dp.capability : undefined,
          }
        : dp,
    );
  };

export const stateUpdateByDeviceSelection =
  (
    device: CreatedDevice | undefined,
    capabilityOptions: Capability[] | undefined,
  ) =>
  (oldMappingState: MappedDatapoint[]) => {
    return oldMappingState.map((dp) =>
      dp.isSelected
        ? {
            ...dp,
            device,
            capabilityOptions,
            capability:
              dp.device?.name === device?.name ? dp.capability : undefined,
          }
        : dp,
    );
  };
export const stateUpdateByRemoveDevice =
  (deviceName: string) => (oldMappingState: MappedDatapoint[]) => {
    return oldMappingState.map((dp) =>
      dp.device?.name === deviceName
        ? {
            ...dp,
            device: undefined,
            model: undefined,
            capabilityOptions: undefined,
            capability: undefined,
          }
        : dp,
    );
  };

export const getPreselectedModel = (mappingsState: MappedDatapoint[]) => {
  const modelsOfSelectedDatapoints = mappingsState
    .filter((m) => m.isSelected)
    .filter(
      (mapping, index, mappings) =>
        !mapping ||
        index ===
          mappings.findIndex((m) => m?.model?.id === mapping?.model?.id),
    );

  // if there is onereturn the model
  return modelsOfSelectedDatapoints.length === 1
    ? modelsOfSelectedDatapoints[0]?.model
    : undefined;
};

export const getPreselectedDevice = (mappingsState: MappedDatapoint[]) => {
  const devicesOfSelectedDatapoints = mappingsState
    .filter((m) => m.isSelected)
    .filter(
      (mapping, index, mappings) =>
        index ===
        mappings.findIndex((m) => m?.device?.name === mapping?.device?.name),
    );

  // if there is one return the device
  return devicesOfSelectedDatapoints.length === 1
    ? devicesOfSelectedDatapoints[0]?.device
    : undefined;
};

type DeviceModelFromAPI = {
  id: string;
  name: string;
  deviceModelCapabilities: Array<{
    id: string;
    fieldSelector?: string | null;
    technicalMax?: number | null;
    technicalMin?: number | null;
    capability: {
      id: string;
      name: string;
      description?: string | null;
    };
    unit: {
      id: string;
      name: string;
      unitSymbol?: string | null;
      uris?: Array<string> | null;
    };
  }>;
};

export const mapMatchingDeviceModels = (
  models: CommissioningSvcDeviceModelMatch[],
) => {
  return models.map((model) => ({
    id: model.id,
    name: model.name,
    deviceModelCapabilities: (model?.deviceModelCapabilities ?? [])
      .map((capability) => ({
        id: capability.id,
        name: capability.capability.name,
        technicalMin:
          typeof capability.technicalMin === 'number'
            ? capability.technicalMin
            : undefined,
        technicalMax:
          typeof capability.technicalMax === 'number'
            ? capability.technicalMax
            : undefined,
        unitName: capability.unit.name,
        unitSymbol: capability.unit.unitSymbol,
        isMatch: capability.isMatch,
      }))
      .filter((capability) => capability.id !== '-1'),
    capabilitiesCount: (model.deviceModelCapabilities ?? []).length,
  }));
};

export const mapDeviceModels = (
  deviceModels: DeviceModelFromAPI[],
): DeviceModel[] => {
  return deviceModels.map((deviceModel) => ({
    id: deviceModel.id,
    name: deviceModel.name,
    deviceModelCapabilities: (deviceModel.deviceModelCapabilities ?? []).map(
      (capability) => ({
        id: capability.id,
        name: capability.capability.name,
        isMatch: true,
        technicalMin: capability.technicalMin ?? undefined,
        technicalMax: capability.technicalMax ?? undefined,
        unitName: capability.unit?.name,
        unitSymbol: capability.unit?.unitSymbol ?? '',
        unitUris: capability.unit?.uris ?? [],
      }),
    ),
  }));
};

export const getCapabilityOptions = (
  targetDeviceName: string,
  targetDeviceModel: DeviceModel | undefined,
  newMappingState: MappedDatapoint[],
) => {
  const deviceModelofTargetDevice =
    targetDeviceModel ??
    newMappingState.find((dp) => dp.device?.name === targetDeviceName)?.model;
  if (!deviceModelofTargetDevice) return [];

  const usedCapabilities = newMappingState
    .filter((dp) => dp.device?.name === targetDeviceName)
    .map((dp) => dp.capability?.id);

  const capabilityOptions =
    deviceModelofTargetDevice.deviceModelCapabilities.map((dmc) => ({
      ...dmc,
      isMapped: usedCapabilities.includes(dmc.id),
    }));
  return capabilityOptions;
};

export const getDraftEntriesWithModels = (
  devices: CommissioningSvcMappingDraftDevice[],
  deviceModels: DeviceModel[],
) => {
  const enrichedDraftEntries = devices.map((draftEntry) => {
    const deviceModel = deviceModels.find(
      (m) => m.id === draftEntry.deviceModelId,
    );
    if (!deviceModel) {
      console.warn('Assigned model not found for device:', draftEntry.name);
    }
    return {
      device: {
        name: draftEntry.name,
        deviceModelId: draftEntry.deviceModelId,
      },
      deviceModel,
      mappings: draftEntry.mappings,
    };
  });
  return enrichedDraftEntries;
};

export const fillAllCapabilityOptions = (
  mappedDatapoints: MappedDatapoint[],
) => {
  const datapointsWithCapabilityOptions = mappedDatapoints.reduce(
    (withOptions, mapping) => {
      if (!mapping.device?.name || mapping.capabilityOptions) {
        return [...withOptions, mapping];
      }

      const mappingsForDevice = mappedDatapoints.filter(
        (m) => m.device?.name === mapping.device?.name,
      );
      const capabilityOptions =
        mapping.model?.deviceModelCapabilities.map((co) => ({
          ...co,
          isMapped: !!mappingsForDevice.find((m) => m.capability?.id === co.id),
        })) ?? [];
      const newWithOptions = [...withOptions, mapping].map((datapoint) => ({
        ...datapoint,
        capabilityOptions:
          datapoint.device?.name === mapping.device?.name
            ? capabilityOptions
            : datapoint.capabilityOptions,
      }));

      return newWithOptions;
    },
    [] as MappedDatapoint[],
  );
  return datapointsWithCapabilityOptions;
};

type DraftEntry = {
  device: CreatedDevice;
  deviceModel?: DeviceModel;
  mappings: CommissioningSvcMappingDraftDeviceMapping[];
};
export const mapDraftEntryMappingsToDatapoints = (
  draftEntries: DraftEntry[],
  scanResult: BACnetTarget[],
) => {
  const datapoints: MappedDatapoint[] = [];

  draftEntries.forEach((draftEntry) => {
    const mappedDatapoints = draftEntry.mappings
      .map((mapping) => {
        const deviceModelCapability =
          draftEntry.deviceModel?.deviceModelCapabilities.find(
            (dmc) => dmc.id === mapping.deviceModelCapabilityId,
          );

        if (!deviceModelCapability) {
          console.warn(
            'Capability not found for mapping:',
            draftEntry.device.name,
            mapping,
          );
          return undefined;
        }

        const mappingDetails = scanResult.find(
          (dp) =>
            dp.deviceIdentifier === mapping.bacnetDeviceId &&
            dp.objectIdentifier === mapping.bacnetObjectId,
        );

        if (!mappingDetails) {
          console.warn(
            'Datapoint not found in scan result for mapping:',
            mapping,
          );
          return undefined;
        }

        return {
          ...mappingDetails,
          deviceIdentifier: mapping.bacnetDeviceId,
          objectIdentifier: mapping.bacnetObjectId,
          unitUri: deviceModelCapability.unitUris?.[0],
          model: draftEntry.deviceModel,
          device: draftEntry.device,
          capabilityOptions: undefined,
          capability: {
            ...deviceModelCapability,
            isMapped: true,
          },
        };
      })
      .filter((dp) => dp) as MappedDatapoint[];
    datapoints.push(...mappedDatapoints);
  });

  return fillAllCapabilityOptions(datapoints);
};

export const cleanDraft = (
  draftEntries: DraftEntry[],
  existingDeviceNames: string[],
) => {
  // check if device names have been used multiple times
  // and remove the "other" mappings that use the duplicated devices names
  const usedDeviceNames: string[] = [];
  const draftEntriesWithModelsAndUniqueDevices = draftEntries.filter((dp) => {
    if (usedDeviceNames.includes(dp.device.name)) {
      console.warn('The device has been used multiple times:', dp.device.name);
      return false;
    }
    usedDeviceNames.push(dp.device.name);
    return true;
  });

  // check if device names already exist on the platform
  // and remove the "other" mappings that use the duplicated devices names
  const draftEntriesWithourExistingDevices =
    draftEntriesWithModelsAndUniqueDevices.filter((dp) => {
      if (existingDeviceNames.includes(dp.device.name)) {
        console.warn(
          'The device already exists on the platform:',
          dp.device.name,
        );
        return false;
      }
      usedDeviceNames.push(dp.device.name);
      return true;
    });

  // check if a  data point was mapped multiple times
  // and remove the "other" mappings that are duplicated
  const usedMappings: string[] = [];
  const uniqueEnrichedDatapoints = draftEntriesWithourExistingDevices.map(
    (dp) => {
      const { mappings } = dp;
      const uniqueMappings = mappings.filter((mapping) => {
        if (
          usedMappings.includes(mapping.bacnetDeviceId + mapping.bacnetObjectId)
        ) {
          console.warn(
            'The datapoint has been mapped multiple times:',
            dp.device.name,
            mapping,
          );
          return false;
        }
        usedMappings.push(mapping.bacnetDeviceId + mapping.bacnetObjectId);
        return true;
      });
      return {
        ...dp,
        mappings: uniqueMappings,
      };
    },
  );

  // check if a capability has been used multiple times
  // and remove the "other" mappings that use the duplicated capability
  const usedCapabilities: string[] = [];
  const uniqueEnrichedDatapointsWithUniqueCapabilities =
    uniqueEnrichedDatapoints.map((dp) => {
      const { mappings } = dp;
      const uniqueMappings = mappings.filter((mapping) => {
        if (
          usedCapabilities.includes(
            dp.device.name + mapping.deviceModelCapabilityId,
          )
        ) {
          console.warn(
            'The capability has been mapped multiple times:',
            dp.device.name,
            mapping,
          );
          return false;
        }
        usedCapabilities.push(dp.device.name + mapping.deviceModelCapabilityId);
        return true;
      });
      return {
        ...dp,
        mappings: uniqueMappings,
      };
    });

  return uniqueEnrichedDatapointsWithUniqueCapabilities;
};
