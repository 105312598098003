import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ActionsBar } from '../../../components/ActionsBar';
import { AddressForm } from '../../../components/Address';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export interface BuildingLocationData {
  location: {
    city: string;
    country: string;
    no: string;
    street: string;
    zip: string;
  };
}

type BuildingLocationProps = BuildingLocationData & {
  loading: boolean;
  onSubmit: (location: BuildingLocationData) => void;
  onDiscard: () => void;
};

export const BuildingLocation: React.FC<BuildingLocationProps> = ({
  loading,
  location,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['buildings', 'general']);

  const defaultValues: BuildingLocationData = {
    location,
  };
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit, reset } = methods;
  useDeepCompareEffect(() => {
    reset({
      location,
    });
  }, [reset, location]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <AddressForm loading={loading} />
    </FormProvider>
  );
};
