import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';

const classes = createClasses({
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

interface DeviceModelDetailsInfoProps {
  loading: boolean;
  isGlobal: boolean;
  onEdit?: () => void;
  name: string;
  isGateway: boolean;
  fieldSelector?: string;
}

export const DeviceModelDetailsInfo: React.FC<DeviceModelDetailsInfoProps> = ({
  loading,
  isGlobal,
  onEdit,
  name,
  isGateway,
  fieldSelector,
}) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  return (
    <FancyCard
      title={t('deviceModels:details.info.title')}
      actions={
        onEdit && (
          <Tooltip
            arrow
            placement="top-end"
            title={
              isGlobal ? (
                <div className={classes.tooltipContent}>
                  {t('deviceModels:globalWarning')}
                </div>
              ) : (
                ''
              )
            }
            aria-label="edit-tooltip"
          >
            <span>
              <IconButton
                aria-label="edit"
                data-testid="card-edit"
                onClick={onEdit}
                size="medium"
                color="primary"
                disabled={isGlobal}
              >
                <Icons.Edit fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )
      }
      fullHeight
      loading={loading}
    >
      <InfoList>
        <InfoListItem term={t('deviceModels:details.info.name')}>
          {name}
        </InfoListItem>
        {isGateway && (
          <InfoListItem term={t('deviceModels:details.info.fieldSelector')}>
            {fieldSelector}
          </InfoListItem>
        )}
      </InfoList>
    </FancyCard>
  );
};
