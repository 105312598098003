import React from 'react';
import {
  Divider,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import * as Yup from 'yup';
import * as Icons from '@mui/icons-material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConfirmAlert } from '../../../components/Alerts';
import { TextField } from '../../../components/Fields';

const rangeMin = 0;
const rangeMax = 4194302;
const timeoutDefault = 60;
const timeoutMax = 900;

const classes = createClasses({
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
});

export type StartDeviceDiscoverySettings = {
  timeout: number;
  rangeStart: number;
  rangeEnd: number;
};

type ConfirmDeviceDiscoveryAlertProps = {
  onConfirm: (settings: StartDeviceDiscoverySettings) => void;
  onCancel: () => void;
  open: boolean;
};

export const ConfirmDeviceDiscoveryAlert: React.FC<
  ConfirmDeviceDiscoveryAlertProps
> = ({ onConfirm, onCancel, open }) => {
  const { t } = useTranslation(['devices', 'general']);

  const validationSchema = Yup.object().shape({
    timeout: Yup.number()
      .required(t('general:errors.required') ?? '')
      .min(0, t('devices:discovery.startDeviceDiscoveryAlert.belowMin') ?? '')
      .max(
        timeoutMax,
        t('devices:discovery.startDeviceDiscoveryAlert.aboveMax') ?? '',
      ),
    rangeStart: Yup.number()
      .required(t('general:errors.required') ?? '')
      .min(
        rangeMin,
        t('devices:discovery.startDeviceDiscoveryAlert.belowMin') ?? '',
      ),
    rangeEnd: Yup.number()
      .required(t('general:errors.required') ?? '')
      .min(
        Yup.ref('rangeStart') ?? '',
        t('devices:discovery.startDeviceDiscoveryAlert.maxMoreThanMin') ?? '',
      )
      .max(
        rangeMax,
        t('devices:discovery.startDeviceDiscoveryAlert.aboveMax') ?? '',
      ),
  });

  const defaultValues: StartDeviceDiscoverySettings = {
    timeout: timeoutDefault,
    rangeStart: rangeMin,
    rangeEnd: rangeMax,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;

  return (
    <ConfirmAlert
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      onConfirm={handleSubmit(onConfirm)}
      confirmButton={t('general:buttons.ok')}
      cancelButton={t('general:buttons.cancel')}
      closeButton
      title={t('devices:discovery.startDeviceDiscoveryAlert.title')}
      message={t('devices:discovery.startDeviceDiscoveryAlert.message')}
      aria-labelledby="start-scan-dialog-title"
      aria-describedby="start-scan-dialog-description"
    >
      <Divider className={classes.divider} />
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<Icons.ExpandMore />}>
          <Typography>
            {t('devices:discovery.startDeviceDiscoveryAlert.configureDetails')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...methods}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="timeout"
                  name="timeout"
                  type="number"
                  label={t(
                    'devices:discovery.startDeviceDiscoveryAlert.timeout',
                  )}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  id="rangeStart"
                  name="rangeStart"
                  type="number"
                  label={t(
                    'devices:discovery.startDeviceDiscoveryAlert.rangeStart',
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="rangeEnd"
                  name="rangeEnd"
                  type="number"
                  label={t(
                    'devices:discovery.startDeviceDiscoveryAlert.rangeEnd',
                  )}
                />
              </Grid>
            </Grid>
          </FormProvider>
        </AccordionDetails>
      </Accordion>
    </ConfirmAlert>
  );
};
