import React from 'react';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DeviceCreateAttributesField } from './DeviceCreateAttributesField';
import { FancyCard } from '../../../components/FancyCard';
import { DataTypes } from '../../../__generated__/types';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  card: {
    height: '100%',
  },
});

export interface AttributeSet {
  id: string;
  name: string;
  attributes: Array<{
    id: string;
    dataType: DataTypes | null;
    name: string;
    required: boolean;
    value?: string;
    enabled?: boolean;
  }>;
}

type DeviceCreateAttributesProps = {
  attributeSets: Array<AttributeSet>;
  loading?: boolean;
};

export const getDeviceCreateAttributesSchema = (
  t: TFunction,
): Yup.ObjectSchema<{ attributeSets: Array<AttributeSet> }> =>
  Yup.object().shape({
    attributeSets: Yup.array()
      .required(t('general:errors.required') ?? '')
      .of(
        Yup.object({
          id: Yup.string().required(),
          name: Yup.string().required(),
          attributes: Yup.array()
            .required(t('general:errors.required') ?? '')
            .of(
              Yup.object({
                id: Yup.string().required(),
                dataType: Yup.mixed<DataTypes>()
                  .oneOf(Object.values(DataTypes))
                  .required(),
                name: Yup.string().required(),
                required: Yup.bool().required(),
                value: Yup.string().when('enabled', ([enabled]) =>
                  enabled
                    ? Yup.string().required(t('general:errors.required') ?? '')
                    : Yup.string().optional(),
                ),
                enabled: Yup.boolean(),
              }),
            ),
        }),
      ),
  });

export const DeviceCreateAttributes: React.FC<DeviceCreateAttributesProps> = ({
  attributeSets,
  loading,
}) => {
  const setWithFieldId = attributeSets.map((attributeSet, setIndex) => ({
    ...attributeSet,
    attributes: attributeSet.attributes.map((attribute, attributeIndex) => {
      return {
        id: attribute.id,
        fieldId: `attributeSets.${setIndex}.attributes.${attributeIndex}`,
      };
    }),
  }));

  return (
    <Grid container spacing={3} item alignItems="stretch" direction="row">
      {setWithFieldId.map(({ id: setId, name, attributes }) => (
        <Grid item xs={12} md={4} key={setId}>
          <FancyCard title={name} loading={loading} className={classes.card}>
            <Grid container spacing={1} className={classes.cardContent}>
              {attributes.map(({ id, fieldId }) => (
                <Grid item xs={12} md={12} key={id}>
                  <DeviceCreateAttributesField
                    loading={loading}
                    fieldId={fieldId}
                  />
                </Grid>
              ))}
            </Grid>
          </FancyCard>
        </Grid>
      ))}
    </Grid>
  );
};
