import React, { useEffect, useState } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useParams, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { getUser } from '../../../api/user';
import { useHeader } from '../../../contexts/header-context';
import { ErrorMessage } from '../../../components/Errors';
import { UserDetails } from './UserDetails';
import { UserDeleteContainer } from '../delete/UserDeleteContainer';
import { NotFound } from '../../errorScreens';

export const UserDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['users', 'general']);
  const { userId } = useParams();
  const navigate = useNavigate();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  const [deleteMode, setDeleteMode] = useState(false);

  const {
    data: response,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['getUser', userId],
    queryFn: () => getUser(userId),
    onError: (err: ApiError) => err,
  });
  const user = response?.data ?? null;

  const roleNames = user?.roles?.map((role) => role.name) ?? [];

  const hierarchy = user
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.USER,
            id: userId,
            name: `${user.firstName} ${user.lastName}`,
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: user ? `${user.firstName} ${user.lastName}` : undefined });
  }, [setTitle, user]);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.USER, ''));

  const handleEdit = () =>
    navigate(`${entityLocation(Entities.USER, userId)}/edit`);

  if (error instanceof Error) {
    if (error.status === 404) {
      return (
        <NotFound
          title={t('users:details.notFound.title') ?? ''}
          subtitle={t('users:details.notFound.subtitle') ?? ''}
          buttonText={t('users:details.notFound.buttonText') ?? ''}
          buttonOnClick={() => navigate(Entities.USER.path)}
        />
      );
    }
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <UserDetails
        loading={loading}
        onDelete={
          hasPermission(Permissions.UserRolesWrite) ? handleDelete : undefined
        }
        onEdit={
          hasPermission(Permissions.UserRolesWrite) ? handleEdit : undefined
        }
        id={userId}
        firstName={user?.firstName ?? ''}
        lastName={user?.lastName ?? ''}
        email={user?.email || '-'}
        roleNames={
          hasPermission(Permissions.UserRolesRead) ? roleNames : undefined
        }
        createdAt={user?.createdAt}
      />
      <UserDeleteContainer
        userId={userId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
