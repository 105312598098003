import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import {
  UnitUpdateType,
  useUnitUpdateMutation,
  useUnitEditDetailsQuery,
} from '../../../__generated__/types';
import { useHeader } from '../../../contexts/header-context';
import { UnitEdit, UnitEditData } from './UnitEdit';
import { NotFound } from '../../errorScreens';

export const UnitEditContainer: React.FC = () => {
  const { t } = useTranslation(['units', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { unitId } = params;
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useUnitEditDetailsQuery({
    variables: { unitId },
  });
  const unit = data?.unit && {
    ...data.unit,
    uris: data.unit.uris?.map((uri) => ({ uri })),
  };

  const hierarchy = unit
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.UNIT,
            id: unit.id,
            name: unit.name || unit.id,
          },
        ],
        t,
      ).concat({
        title: t('units:breadcrumb.edit'),
        location: entityLocation(Entities.UNIT, `${unit.id}/edit`),
      })
    : [];
  useBreadcrumb(hierarchy);

  const [updateUnit, { loading: loadingUpdate, error: errorUpdate }] =
    useUnitUpdateMutation({
      onCompleted: () =>
        navigate(entityLocation(Entities.UNIT, unit?.id ?? '')),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: UnitEditData) => {
    const unitInput: UnitUpdateType = {
      ...inputData,
      id: unitId,
      uris: inputData.uris.map((entry) => entry.uri),
    };
    return updateUnit({
      variables: { unitInput },
    });
  };

  const handleDiscard = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  useEffect(() => {
    setLoading(loading);
    setTitle({ main: data?.unit?.name });
  }, [setLoading, loading, setTitle, data]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !unit) {
    return (
      <NotFound
        title={t('units:details.notFound.title') ?? ''}
        subtitle={t('units:details.notFound.subtitle') ?? ''}
        buttonText={t('units:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.UNIT.path)}
      />
    );
  }
  if (unit?.isGlobal) {
    return (
      <ErrorMessage error={new Error(t('units:details.globalWarning') ?? '')} />
    );
  }

  return (
    <>
      <UnitEdit
        loading={loading || loadingUpdate}
        id={unitId}
        name={unit?.name ?? ''}
        unitSymbol={unit?.unitSymbol ?? ''}
        uris={unit?.uris ?? []}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorUpdate]}
      />
    </>
  );
};
