import { useEffect, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ApiError,
  CommissioningSvcEnrichedBacnetDevice as EnrichedBacnetDevice,
  CommissioningSvcEnrichedBacnetObject as EnrichedBacnetObject,
  CommissioningSvcMappingDraft,
  CommissioningSvcMappingDraftValidationResult,
} from '@kp/rest-api-javascript-sdk';
import {
  getLatestObjectScan,
  getMappingDraft as getMappingDraftFromAPI,
  validateMappingDraft as validateMappingDraftFromAPI,
} from '../../../../api/bacnet';
import { byName } from '../../../../utils/sort';
import { BACnetTarget } from './MappingStateUtils';
import { ScanDataDevice } from '../common/DeviceDropdown';

export type UseMappingDraftData = {
  scanFinished?: Date;
  scanResult?: BACnetTarget[];
  scanResultDevices?: ScanDataDevice[];
  mappingDraft?: CommissioningSvcMappingDraft & {
    updatedAt?: string;
  };
  validationResult?: CommissioningSvcMappingDraftValidationResult;
  loading: boolean;
  error?: ApolloError | ApiError | null;
};

export const useMappingDraftData = (gatewayId: string): UseMappingDraftData => {
  const [initialLoading, setInitialLoading] = useState(true);

  const {
    data: responseScanResultDetails,
    isLoading: loadingScanResultDetails,
    error: errorScanResultDetails,
  } = useQuery({
    queryKey: ['getLatestObjectScan', gatewayId],
    queryFn: () => getLatestObjectScan(gatewayId),
    onError: (err: ApiError) => err,
  });

  const {
    data: responseMappingDraft,
    isLoading: loadingMappingDraft,
    error: errorMappingDraft,
  } = useQuery({
    queryKey: ['getMappingDraft', gatewayId],
    queryFn: () => getMappingDraftFromAPI(gatewayId),
    onError: (err: ApiError) => err,
  });
  const mappingDraft = responseMappingDraft?.data;

  const {
    mutate: validateMappingDraft,
    data: responseMappingDraftValidation,
    isLoading: loadingMappingDraftValidation,
    error: errorMappingDraftValidation,
  } = useMutation({
    mutationFn: validateMappingDraftFromAPI,
    onError: (err: ApiError) => err,
  });

  const scanFinished = useMemo(() => {
    const latestDate = responseScanResultDetails?.data?.reduce(
      (mostRecentFinish, result) =>
        result?.finishedAt && result.finishedAt > mostRecentFinish
          ? result.finishedAt
          : mostRecentFinish,
      '',
    );
    return latestDate ? new Date(latestDate) : undefined;
  }, [responseScanResultDetails?.data]);

  const scanResultWithDevices = useMemo(() => {
    return responseScanResultDetails?.data?.reduce(
      (combinedResult, scanData) => [
        ...combinedResult,
        ...(scanData.scanResult?.unit?.device ?? []),
      ],
      [] as EnrichedBacnetDevice[],
    );
  }, [responseScanResultDetails?.data]);

  const scanResult = useMemo(() => {
    if (!mappingDraft) return [];
    return scanResultWithDevices
      ?.map((d) => {
        return ((d.object ?? []) as EnrichedBacnetObject[]).map((o) => ({
          deviceIdentifier: d.objectIdentifier,
          deviceName: d.objectName,
          unitName: o['X-unit']?.name || String(o.units) || '-',
          unitUris: o['X-unit']?.unitUris ?? [],
          objectTypeName: o['X-object-type'],
          isMappable: o['X-object-type-is-mappable'],
          ...o,
        }));
      })
      .flat();
  }, [mappingDraft, scanResultWithDevices]);

  const scanResultDevices = useMemo(() => {
    return (scanResultWithDevices ?? [])
      .map((d) => ({
        id: d.objectIdentifier,
        name: d.objectName,
      }))
      .sort(byName);
  }, [scanResultWithDevices]);

  useEffect(() => {
    if (!mappingDraft) return;
    if (!scanResult) return;
    validateMappingDraft({
      gatewayId,
      mappingDraft,
    });
  }, [gatewayId, mappingDraft, scanResult, validateMappingDraft]);
  const validationResult = responseMappingDraftValidation?.data;

  useEffect(() => {
    if (scanFinished && scanResult && scanResultDevices && mappingDraft) {
      setInitialLoading(false);
    }
  }, [scanFinished, scanResult, scanResultDevices, mappingDraft]);

  return initialLoading
    ? {
        loading: true,
      }
    : {
        scanFinished,
        scanResult,
        scanResultDevices,
        mappingDraft,
        validationResult,
        loading:
          loadingScanResultDetails ||
          loadingMappingDraft ||
          loadingMappingDraftValidation,
        error:
          errorScanResultDetails ||
          errorMappingDraft ||
          errorMappingDraftValidation,
      };
};
