import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { ErrorAlert } from '../../../components/Alerts';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import { createRegisteredApplication } from '../../../api/application';
import {
  RegisteredApplicationCreate,
  RegisteredApplicationCreateData,
} from './RegisteredApplicationCreate';
import { RegisteredApplicationCreateSuccessDialog } from './RegisteredApplicationCreateSuccessDialog';
import { permissionValuesToStrings } from '../../../constants/Permissions';

export const RegisteredApplicationCreateContainer: React.FC = () => {
  const { t } = useTranslation(['registeredApplications', 'general']);
  const navigate = useNavigate();
  const { setTitle } = useHeader();

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.REGISTERED_APPLICATION,
        id: '/new',
        name: t('registeredApplications:breadcrumb.create'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('registeredApplications:breadcrumb.create') });
  }, [setTitle, t]);

  const {
    mutate: create,
    error,
    isLoading: loading,
    data: response,
  } = useMutation({ mutationFn: createRegisteredApplication });

  const data = response?.data;

  const handleDiscard = () => {
    navigate(entityLocation(Entities.REGISTERED_APPLICATION, ''));
  };

  const handleConfirm = useCallback(() => {
    data?.id &&
      navigate(entityLocation(Entities.REGISTERED_APPLICATION, data.id));
  }, [data, navigate]);

  const handleCreate = ({
    name,
    description,
    url,
    redirectUris,
    permissions,
  }: RegisteredApplicationCreateData) => {
    const uris = redirectUris.map((entry) => entry.uri);
    const permissionList = permissionValuesToStrings(permissions);
    return create({
      name,
      description,
      url,
      redirectUris: uris,
      permissions: permissionList,
    });
  };

  return (
    <>
      <RegisteredApplicationCreate
        loading={loading}
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
      />
      {!error &&
        !loading &&
        data &&
        data.publicOAuth2Client?.id &&
        data.confidentialOAuth2Client?.id && (
          <RegisteredApplicationCreateSuccessDialog
            onConfirm={handleConfirm}
            publicOAuth2Client={data.publicOAuth2Client.id}
            confidentialOAuth2Client={data.confidentialOAuth2Client.id}
            secret={data.confidentialOAuth2Client.secret}
          />
        )}
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error as ApiError]}
      />
    </>
  );
};
