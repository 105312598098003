import { Components, PaletteOptions, ThemeOptions } from '@mui/material';
import { Color } from '../constants/Colors';

export const palette: PaletteOptions = {
  primary: {
    light: Color.lightOrange,
    main: Color.primaryOrange,
    dark: Color.darkOrange,
    contrastText: Color.white,
  },
  secondary: {
    light: Color.lightBlue,
    main: Color.primaryBlue,
    dark: Color.darkBlue,
    contrastText: Color.white,
  },
};

export const components: Components = {
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontWeight: 600,
      },
    },
  },
  MuiTextField: {
    defaultProps: { variant: 'standard' },
  },
};

export const baseTheme: ThemeOptions = {
  palette,
  components,
};
