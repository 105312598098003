import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import { saveAs } from 'file-saver';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import {
  ConnectionStates,
  useGatewayStatusQuery,
  useMappingsCreateGatewayDetailsQuery,
} from '../../../../__generated__/types';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { ErrorAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { useHeader } from '../../../../contexts/header-context';
import { ErrorMessage } from '../../../../components/Errors';
import {
  deployMapping,
  downloadMapping,
  getMappingReviewData,
} from '../../../../api/bacnet';
import { entityLocation } from '../../../../utils/entity';
import { DeviceMappingsReview } from './DeviceMappingsReview';
import { b64toBlob } from '../../../../utils/file-tools';
import { NotFound } from '../../../errorScreens';
import { useGatewayDetailsSubscription } from '../../../../hooks/useGatewayDetailsSubscription';
import { useGatewayStatusSubscription } from '../../../../hooks/useGatewayStatusSubscription';

export const DeviceMappingsReviewContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general', 'errors']);
  const { hasPermission } = useAuth();
  const { gatewayId = '0' } = useParams();
  const { setTitle, setLoading } = useHeader();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useMappingsCreateGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;
  const gatewayLocation = dataGateway?.placementOfDevice;

  const { details: gatewayDetails } = useGatewayDetailsSubscription(gatewayId);
  const gatewayCommissioningStatus = gatewayDetails?.status;

  const { data: initialGatewayStatus } = useGatewayStatusQuery({
    variables: { deviceId: gatewayId },
  });
  const { connectionState: connectionStatus } =
    useGatewayStatusSubscription(gatewayId);

  const gatewayConnectionStatus = useMemo(() => {
    if (!connectionStatus) {
      return (
        initialGatewayStatus?.device?.connectionState ??
        ConnectionStates.Unknown
      );
    }
    return connectionStatus;
  }, [connectionStatus, initialGatewayStatus]);

  const locationState = location.state as {
    backToEntity?: Entities.Entity;
    params?: string;
  } | null;
  const params = locationState?.params ?? '/mappings/edit';
  const returnTo = locationState?.backToEntity ?? Entities.DEVICE;
  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: gatewayId,
            name: gateway.name,
            query: { activeTab: 'FieldDevices' },
          },
        ],
        t,
      )
        .concat({
          title: t('devices:mappings.edit.breadcrumb'),
          location: entityLocation(returnTo, params),
        })
        .concat({
          title: t('devices:mappings.review.breadcrumb'),
          location: entityLocation(
            Entities.DEVICE,
            `${gatewayId}/mappings/review`,
          ),
        })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('devices:mappings.review.title'),
      sub:
        gatewayLocation?.siteName && gatewayLocation?.buildingName
          ? `${gatewayLocation?.siteName}, ${gatewayLocation?.buildingName}`
          : '',
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t, gatewayLocation]);

  const {
    data: responseReviewData,
    isLoading: loadingReviewData,
    error: errorReviewData,
  } = useQuery({
    queryKey: ['getMappingReviewData', gatewayId],
    queryFn: () => getMappingReviewData(gatewayId),
    onError: (err: ApiError) => err,
  });
  const reviewData = responseReviewData;

  const {
    mutateAsync: callDeployMappings,
    isLoading: loadingDeployMappings,
    error: errorDeployMappings,
  } = useMutation({
    mutationFn: deployMapping,
    onError: (err: ApiError) => err,
  });
  const {
    mutateAsync: callDownloadMappings,
    isLoading: loadingDownloadMappings,
    error: errorDownloadMappings,
  } = useMutation({
    mutationFn: downloadMapping,
    onError: (err: ApiError) => err,
  });

  const navigateBack = () => {
    navigate(
      entityLocation(Entities.DEVICE, gatewayId, { activeTab: 'Deployments' }),
    );
  };

  const handleDownloadMappings = async () => {
    const result = await callDownloadMappings(gatewayId);
    const blob = await b64toBlob(result.data.fileContent);
    saveAs(blob, `gatewayConfig-${gatewayId}.zip`);
    navigateBack();
  };
  const handleDeployMappings = async () => {
    const result = await callDeployMappings(gatewayId);
    if (!result.error) navigateBack();
  };

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  if (errorGateway) {
    return <ErrorMessage error={errorGateway} />;
  }
  if (!loadingGateway && !gateway) {
    return (
      <NotFound
        title={t('devices:details.notFound.title') ?? ''}
        subtitle={t('devices:details.notFound.subtitle') ?? ''}
        buttonText={t('devices:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }

  return (
    <>
      <DeviceMappingsReview
        loading={
          loadingGateway ||
          loadingReviewData ||
          loadingDeployMappings ||
          loadingDownloadMappings
        }
        reviewData={reviewData?.data ?? []}
        onDeploy={
          hasPermission(Permissions.CommissioningWrite) &&
          gatewayCommissioningStatus === 'idle' &&
          gatewayConnectionStatus === ConnectionStates.Connected
            ? handleDeployMappings
            : undefined
        }
        onDownload={
          hasPermission(Permissions.CommissioningWrite)
            ? handleDownloadMappings
            : undefined
        }
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorDeployMappings, errorDownloadMappings, errorReviewData]}
      />
    </>
  );
};
