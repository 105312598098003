import React from 'react';
import { Backdrop } from '@mui/material';
import { Loading } from './Loading';

export type LoadingBackdropProps = {
  open: boolean;
};

export const LoadingBackdrop: React.FC<LoadingBackdropProps> = ({ open }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 1000 }}>
      <Loading />
    </Backdrop>
  );
};
