import React from 'react';
import { createClasses } from '@kp/react-ui';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Color } from '../../constants/Colors';
import { ConnectionStates } from '../../__generated__/types';
import { FormattedDate } from '../FormattedDate';

export { ConnectionStates };

const classes = createClasses({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  base: {
    display: 'inline-block',
    borderRadius: '50%',
  },
  [ConnectionStates.Unknown]: {
    backgroundColor: Color.lightGrey,
  },
  [ConnectionStates.Connected]: {
    backgroundColor: Color.ok,
  },
  [ConnectionStates.NeverConnected]: {
    backgroundColor: Color.warning,
  },
  [ConnectionStates.Disconnected]: {
    backgroundColor: Color.error,
  },
  spaceRight: {
    marginRight: 8,
  },
});

interface StatusIndicatorProps {
  status: ConnectionStates;
  size?: number;
  withLabel?: boolean;
  firstConnected?: string;
  lastConnected?: string;
}

const ConnectedTooltip = ({
  children,
  firstConnected,
  lastConnected,
}: {
  children: React.ReactElement<any, any>;
  firstConnected?: string;
  lastConnected?: string;
}) => {
  const { t } = useTranslation(['components']);
  if (!firstConnected && !lastConnected) return children;
  return (
    <Tooltip
      arrow
      placement="bottom"
      title={
        <div>
          {firstConnected && (
            <div>
              {t('components:statusIndicator.firstConnected')}:{' '}
              <FormattedDate value={firstConnected} />
            </div>
          )}
          {lastConnected && (
            <div>
              {t('components:statusIndicator.lastConnected')}:{' '}
              <FormattedDate value={lastConnected} />
            </div>
          )}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  status,
  size = 12,
  withLabel,
  firstConnected,
  lastConnected,
}) => {
  const { t } = useTranslation(['components', 'general', 'errors']);

  const statusText = (() => {
    switch (status) {
      case ConnectionStates.Connected:
        return t('components:statusIndicator.connected');
      case ConnectionStates.Disconnected:
        return t('components:statusIndicator.disconnected');
      case ConnectionStates.NeverConnected:
        return t('components:statusIndicator.neverConnected');
      default:
        return t('components:statusIndicator.unknown');
    }
  })();

  const Indicator = (
    <div
      className={classNames(
        classes.base,
        classes[status],
        withLabel && classes.spaceRight,
      )}
      style={{ width: size, height: size }}
    />
  );
  return (
    <div className={classes.root} data-testid={`status-indicator`}>
      {withLabel ? (
        <ConnectedTooltip
          firstConnected={firstConnected}
          lastConnected={lastConnected}
        >
          <div data-testid={`status-indicator-${status}`}>
            {Indicator}
            {statusText}
          </div>
        </ConnectedTooltip>
      ) : (
        <Tooltip arrow placement="top" title={statusText}>
          <span data-testid={`status-indicator-${status}`}>{Indicator}</span>
        </Tooltip>
      )}
    </div>
  );
};
