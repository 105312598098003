import type { TFunction } from 'i18next';
import { InstanceSize, Status } from '../../../../api/qanteon/common';

export const instanceSizes = (
  t: TFunction,
): { [key in InstanceSize]: string } => ({
  [InstanceSize.Small]: t('integrations:qanteon.instanceSize.label.small'),
  [InstanceSize.Medium]: t('integrations:qanteon.instanceSize.label.medium'),
  [InstanceSize.Large]: t('integrations:qanteon.instanceSize.label.large'),
});

export const statuses = (t: TFunction): { [key in Status]: string } => ({
  [Status.pending]: t('integrations:qanteon.status.label.pending'),
  [Status.available]: t('integrations:qanteon.status.label.available'),
  [Status.failed]: t('integrations:qanteon.status.label.failed'),
});
