import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  DeviceFormLocationProps,
  DeviceFormLocation,
  DeviceFormLocationData,
  getDeviceFormLocationSchema,
} from '../common/DeviceFormLocation';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

export type DeviceLocationData = DeviceFormLocationData;

export type DeviceLocationProps = DeviceFormLocationData & {
  onSubmit: (location: DeviceLocationData) => void;
  onDiscard: () => void;
  loading?: boolean;
  allSites: DeviceFormLocationProps['sites'];
};

export const DeviceLocation: React.FC<DeviceLocationProps> = ({
  loading,
  locationId,
  locationType,
  allSites,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const validationSchema = getDeviceFormLocationSchema(t);
  const defaultValues: DeviceFormLocationData = { locationId, locationType };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { reset, handleSubmit } = methods;
  useEffect(() => {
    reset({
      locationId,
      locationType,
    });
  }, [reset, locationId, locationType]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <DeviceFormLocation sites={allSites} loading={loading} type="edit" />
    </FormProvider>
  );
};
