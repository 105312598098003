import {
  UserSvcInvitationsService as InvitationService,
  UserSvcInvitation,
} from '@kp/rest-api-javascript-sdk';

export enum PendingInvitationStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}
export type PendingInvitation = UserSvcInvitation & {
  inviteeUserId?: string;
  tenantName?: string;
};

/**
 * Get details of a pending invitation
 *
 * @param hash          The invitation hash that was provided to the user via e-mail
 */
export const getPendingInvitation =
  InvitationService.getUserApiPendingInvitations;

type RegisterInvitationInput = {
  hash: string;
  firstName: string;
  lastName: string;
  password: string;
};
/**
 * Register user pending invitation
 *
 * @param hash          The invitation's code
 * @param firstName     First Name of the user to get registered
 * @param lastName      Last Name of the user to get registered
 * @param password      The desired password
 */
export const registerPendingInvitation = ({
  hash,
  ...invitation
}: RegisterInvitationInput) =>
  InvitationService.postUserApiPendingInvitationsRegister(hash, invitation);

/**
 * Accept a pending invitation
 *
 * @param hash          The invitation's code
 */
export const acceptPendingInvitation =
  InvitationService.postUserApiPendingInvitationsAccept;
