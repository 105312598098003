import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GATEWAY_ACCESS_SIGNATURE_EXPIRY_SECONDS } from '../../../constants/Misc';
import { AccessSignatureAlert } from './AccessSignatureAlert';
import { useRegenerateSharedAccessSignatureMutation } from '../../../__generated__/types';
import { ErrorAlert } from '../../../components/Alerts';

type AccessSignature = {
  sharedAccessSignature?: string;
  sharedAccessSignatureExpiry?: string;
};

type AccessSignatureAlertContainerProps = {
  open: boolean;
  deviceId: string;
  onClose: () => void;
  updateGetGatewayConfig: () => void;
};
export const AccessSignatureAlertContainer: React.FC<
  AccessSignatureAlertContainerProps
> = ({ open, deviceId, onClose, updateGetGatewayConfig }) => {
  const { t } = useTranslation(['general', 'errors']);
  const [accessSignature, setAccessSignature] = useState<AccessSignature>();

  const [
    callRegenerateSharedAccessSignature,
    {
      loading: loadingGenerateAccessSignature,
      error: errorGenerateAccessSignature,
    },
  ] = useRegenerateSharedAccessSignatureMutation({
    onCompleted: () => updateGetGatewayConfig(),
  });

  const handleGenerateAccessSignature = async () => {
    const result = await callRegenerateSharedAccessSignature({
      variables: {
        deviceId,
        expirySeconds: GATEWAY_ACCESS_SIGNATURE_EXPIRY_SECONDS,
      },
    }).catch(console.warn);
    setAccessSignature(
      result?.data?.regenerateGatewayDeviceSharedAccessSignature ?? undefined,
    );
  };

  const onCloseAccessSignaturePopup = () => {
    setAccessSignature(undefined);
    onClose();
  };

  return (
    <>
      <AccessSignatureAlert
        open={open}
        loading={loadingGenerateAccessSignature}
        generateAccessSignature={handleGenerateAccessSignature}
        accessSignature={accessSignature}
        onClose={onCloseAccessSignaturePopup}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorGenerateAccessSignature]}
      />
    </>
  );
};
