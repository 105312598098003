import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage } from '../../../components/Errors';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { TenantsList } from './TenantsList';
import { entityLocation } from '../../../utils/entity';
import { useHeader } from '../../../contexts/header-context';
import { useFilters } from '../../../hooks/filters';
import { PaginationFilter } from '../../../api/common';
import { getTenants } from '../../../api/user';

export const TenantsListContainer: React.FC = () => {
  const { t } = useTranslation(['tenants', 'general']);
  const { page, pageSize, setPage, setPageSize } = useFilters();
  const navigate = useNavigate();
  const { setTitle } = useHeader();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.TENANT, t),
    location: Entities.TENANT.path,
  });

  const filter: PaginationFilter = useMemo(
    () => ({
      skip: page * pageSize,
      limit: pageSize,
    }),
    [page, pageSize],
  );

  const {
    data: response,
    error,
    isLoading: loading,
  } = useQuery({
    queryKey: ['getTenants', filter],
    queryFn: () => getTenants(filter),
  });

  const tenants = response?.data ?? [];

  useEffect(() => {
    setTitle({ main: t('tenants:list.title') });
  }, [setTitle, t]);

  const handleAdd = () => navigate(entityLocation(Entities.TENANT, `new`));

  if (error instanceof Error) return <ErrorMessage error={error} />;

  return (
    <TenantsList
      tenants={tenants}
      page={page}
      pageSize={pageSize}
      count={response?.meta?.totalSize ?? 0}
      onChangePageSize={setPageSize}
      onChangePage={setPage}
      loading={loading}
      onAdd={handleAdd}
    />
  );
};
