import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ErrorAlert } from '../../../../components/Alerts';
import { entityLocation } from '../../../../utils/entity';
import * as Entities from '../../../../constants/Entities';
import {
  DataTypes,
  DeviceCreateAttributeValueType,
  PlacementKind,
  useFieldDeviceCreateDeviceModelsAndSitesQuery,
  useFieldDeviceCreateGatewayDevicesQuery,
  useFieldDeviceCreateWithPlacementMutation,
} from '../../../../__generated__/types';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';
import {
  FieldDeviceCreate,
  FieldDeviceCreateData,
} from '../../common/FieldDeviceCreate';
import { useValidateDeviceIdentifier } from '../../common/useValidateDeviceIdentifier';

export const FieldDeviceCreateContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general']);
  const navigate = useNavigate();
  const { setTitle, setLoading } = useHeader();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const placementId = searchParams.get('placementId') ?? undefined;
  const placementType =
    (searchParams.get('placementType') as PlacementKind) ?? undefined;

  const { validationState, validate } = useValidateDeviceIdentifier();

  const {
    loading: loadingDeviceModelsAndSites,
    error: errorDeviceModelsAndSites,
    data: dataDeviceModelsAndSites,
  } = useFieldDeviceCreateDeviceModelsAndSitesQuery();
  const deviceModels = dataDeviceModelsAndSites?.deviceModels?.items;
  const allSites = dataDeviceModelsAndSites?.sites?.items ?? [];

  const {
    loading: loadingGateways,
    error: errorGateways,
    data: dataGateways,
  } = useFieldDeviceCreateGatewayDevicesQuery();
  const gateways = dataGateways?.devices;

  const allDeviceModels = (deviceModels ?? []).map((dm) => ({
    id: dm.id,
    name: dm.name,
    isGateway: dm.deviceType?.isGateway ?? false,
    deviceModelAttributeSets: dm.deviceModelAttributeSets.map(
      (attributeSet) => ({
        id: attributeSet.id,
        name: attributeSet.name,
        attributes: attributeSet.mappingAttributeDeviceModelAttributeSets.map(
          (attribute) => ({
            id: attribute.attribute.id,
            name: attribute.attribute.name,
            required: attribute.attribute.required ?? false,
            dataType: attribute.attribute.dataType,
            value:
              attribute.attribute.required &&
              attribute.attribute.dataType === DataTypes.Bool
                ? 'false'
                : '',
          }),
        ),
      }),
    ),
  }));

  const allGateways = (gateways?.items ?? []).map((gatewayDevice) => ({
    id: gatewayDevice.id,
    name: gatewayDevice.name,
  }));

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.DEVICE,
        id: '/new',
        name: t('devices:breadcrumb.createFieldDevice'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('devices:breadcrumb.createFieldDevice') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loadingDeviceModelsAndSites || loadingGateways);
  }, [setLoading, loadingDeviceModelsAndSites, loadingGateways]);

  const navigateBack = (createdDeviceId?: string) => {
    const locationState = location.state as {
      backToEntity?: Entities.Entity;
      params?: string;
    } | null;
    const returnTo = locationState?.backToEntity ?? Entities.DEVICE;
    const params = (locationState?.params || createdDeviceId) ?? '';
    navigate(entityLocation(returnTo, params));
  };

  const [create, { loading: loadingCreate, error: errorCreate }] =
    useFieldDeviceCreateWithPlacementMutation({
      onCompleted: (result) => {
        navigateBack(result.createDeviceWithPlacement?.id ?? '');
      },
    });

  const handleDiscard = () => navigateBack();

  const handleCreate = (inputData: FieldDeviceCreateData) => {
    const attributeValues: DeviceCreateAttributeValueType[] =
      inputData.attributeSets
        .map((set) =>
          set.attributes
            .filter((attribute) => attribute.required || attribute.value !== '')
            .map((attribute) => ({
              attributeId: attribute.id,
              value: attribute.value ?? '',
            })),
        )
        .flat();
    create({
      variables: {
        device: {
          name: inputData.name,
          description: inputData.description,
          serialNo: inputData.serialNo,
          deviceModelId: inputData.deviceModelId,
          deviceIdentifier: inputData.deviceIdentifier,
          parentDeviceId: inputData.parentDeviceId,
        },
        placementId: inputData.locationId,
        placementType: inputData.locationType ?? PlacementKind.Zone,
        attributeValues,
      },
    }).catch(console.warn);
  };

  return (
    <>
      <FieldDeviceCreate
        loading={
          loadingGateways || loadingDeviceModelsAndSites || loadingCreate
        }
        placementId={placementId}
        placementType={placementType}
        allSites={allSites}
        allGateways={allGateways}
        allDeviceModels={allDeviceModels}
        validateDeviceIdentifier={validate}
        deviceIdentifierValidationState={validationState}
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorGateways, errorDeviceModelsAndSites, errorCreate]}
      />
    </>
  );
};
