import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDeviceAttributesQuery } from '../../../__generated__/types';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { DeviceAttributes } from './DeviceAttributes';
import { useHeader } from '../../../contexts/header-context';

export const DeviceAttributesContainer: React.FC = () => {
  const params = useParams();
  const { deviceId } = params;
  const { t } = useTranslation(['devices', 'general']);
  const { setTitle, setLoading } = useHeader();

  const { data, loading, error, refetch } = useDeviceAttributesQuery({
    variables: { deviceId },
  });

  const { device } = data ?? {};
  const { deviceModelAttributeSets } = device?.deviceModel ?? {};
  const attributeValues = device?.deviceAttributeValues;

  const hierarchy = device
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: deviceId,
            name: device.name,
            query: { activeTab: 'Attributes' },
          },
        ],
        t,
      ).concat({
        title: t('devices:attributes.breadcrumb.title'),
        location: entityLocation(Entities.DEVICE, `${deviceId}/attributes`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('devices:attributes.title') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  const attributeSets = (deviceModelAttributeSets ?? [])
    .map((attributeSet) => ({
      id: attributeSet?.id ?? '',
      name: attributeSet?.name ?? '',
      attributes: attributeSet?.mappingAttributeDeviceModelAttributeSets
        .map((attribute) => ({
          exists: !!attributeValues?.find(
            ({ id }) => id === attribute?.attribute?.id,
          ),
          id: attribute?.attribute?.id ?? '',
          name: attribute?.attribute?.name ?? '',
          required: attribute?.attribute?.required ?? false,
          dataType: attribute?.attribute?.dataType ?? null,
          value:
            attributeValues?.find(({ id }) => id === attribute?.attribute?.id)
              ?.value ?? '',
        }))
        .filter((attribute) => attribute.id !== ''),
    }))
    .filter((attributeSet) => attributeSet.id !== '');

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <DeviceAttributes
      attributeSets={attributeSets}
      deviceId={deviceId}
      onChange={refetch}
    />
  );
};
