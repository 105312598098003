import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import {
  createTenant,
  validateTenantSlug,
  TenantSlugValidationStatus,
} from '../../../api/user';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { entityLocation } from '../../../utils/entity';
import { useHeader } from '../../../contexts/header-context';
import { TenantCreate, TenantCreateData } from './TenantCreate';
import { TenantCreateSuccessDialog } from './TenantCreateSuccessDialog';

export const TenantCreateContainer: React.FC = () => {
  const { t } = useTranslation(['tenants', 'general']);
  const navigate = useNavigate();
  const { setTitle } = useHeader();
  const [inviteContactToTenant, setInviteContactToTenant] = useState(true);

  // const [cache, { set }] = useMap<{ [slug: string]: string | null }>({});

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.TENANT,
        id: '/new',
        name: t('tenants:breadcrumb.create'),
      },
    ],
    t,
  );

  useBreadcrumb(hierarchy);

  const {
    mutate: create,
    isLoading: loadingCreate,
    error: errorCreate,
    data: responseCreate,
  } = useMutation({ mutationFn: createTenant });

  const { mutateAsync: validate, error: errorValidate } = useMutation({
    mutationFn: (slug: string) => validateTenantSlug({ slug }),
  });

  useEffect(() => {
    setTitle({ main: t('tenants:breadcrumb.create') });
  }, [setTitle, t]);

  const handleDiscard = () => {
    navigate(entityLocation(Entities.TENANT, ''));
  };

  const handleSubmit = (inputData: TenantCreateData) => {
    setInviteContactToTenant(inputData.inviteContactToTenant ?? false);
    return create({
      name: inputData.name,
      slug: inputData.slug,
      contactAddressCity: inputData.location.city,
      contactAddressCountry: inputData.location.country,
      contactAddressStreet: inputData.location.street,
      contactAddressHouseNumber: inputData.location.no,
      contactAddressZip: inputData.location.zip,
      contactEmail: inputData.contactEmail,
      contactFirstName: inputData.contactFirstName,
      contactLastName: inputData.contactLastName,
      inviteContactToTenant: inputData.inviteContactToTenant ?? false,
    });
  };

  const validateSlugErrors = useMemo(
    () => ({
      [TenantSlugValidationStatus.Ok]: undefined,
      [TenantSlugValidationStatus.InvalidFormat]: t(
        'tenants:form.slug.errors.invalidFormat',
      ),
      [TenantSlugValidationStatus.AlreadyTaken]: t(
        'tenants:form.slug.errors.alreadyTaken',
      ),
      [TenantSlugValidationStatus.TooLong]: t(
        'tenants:form.slug.errors.tooLong',
      ),
      [TenantSlugValidationStatus.TooShort]: t(
        'tenants:form.slug.errors.tooShort',
      ),
    }),
    [t],
  );

  const validateSlug = async (slug: string) => {
    try {
      const result = await validate(slug);
      const validationResult = result.data;
      return validationResult ? validateSlugErrors[validationResult] : null;
    } catch (e) {
      console.warn(e);
      return t('tenants:form.slug.errors.callFailed');
    }
  };

  const handleConfirm = () => {
    navigate(entityLocation(Entities.TENANT, responseCreate?.data?.id ?? ''));
  };

  return (
    <>
      <TenantCreate
        loading={loadingCreate}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
        validateSlug={validateSlug}
      />
      {!errorCreate && !loadingCreate && responseCreate?.data && (
        <TenantCreateSuccessDialog
          onConfirm={handleConfirm}
          email={responseCreate.data.contactEmail ?? ''}
          tenant={responseCreate.data.name}
          inviteContactToTenant={inviteContactToTenant}
        />
      )}
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorCreate as ApiError, errorValidate as ApiError]}
      />
    </>
  );
};
