import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { getDeployments } from '../../../api/bacnet';
import { ErrorAlert } from '../../../components/Alerts';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useHeader } from '../../../contexts/header-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { DeploymentsList } from './DeploymentsList';
import * as Entities from '../../../constants/Entities';
import { useDeploymentsGatewayDetailsQuery } from '../../../__generated__/types';

export const DeploymentsContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general']);
  const { gatewayId = '0' } = useParams();
  const { setTitle, setLoading } = useHeader();

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useDeploymentsGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;
  const gwLocation = dataGateway?.placementOfDevice;

  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: gatewayId,
            name: gateway.name,
            query: { activeTab: 'Deployments' },
          },
          {
            type: Entities.DEVICE,
            id: `${gatewayId}/deployments`,
            name: t('devices:deployments.breadcrumb'),
          },
        ],
        t,
      )
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('devices:deployments.title'),
      sub:
        gwLocation?.siteName && gwLocation?.buildingName
          ? `${gwLocation?.siteName}, ${gwLocation?.buildingName}`
          : '',
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t, gwLocation]);

  const {
    isLoading: loading,
    data: response,
    error,
  } = useQuery({
    queryKey: ['getDeployments', gatewayId],
    queryFn: () => getDeployments(gatewayId),
    onError: (err: ApiError) => err,
  });

  return (
    <>
      <DeploymentsList loading={loading} deployments={response?.data} />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorGateway]}
      />
    </>
  );
};
