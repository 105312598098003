import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { createClasses } from '@kp/react-ui';
import { Grid, IconButton, Button, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { TFunction } from 'i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FancyCard } from '../../../components/FancyCard';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { Color } from '../../../constants/Colors';
import { InputBaseField } from '../../../components/Fields/InputBaseField';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 28px 16px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    background: Color.inputBackground,
    padding: '8px',
    borderRadius: '4px',
  },
  inputWrapper: {
    flexGrow: 1,
    marginRight: '16px',
    paddingLeft: '8px',
  },
  input: {
    fontSize: '14px',
    fontFamily: 'monospace',
  },
  error: {
    padding: '4px 8px 16px 16px',
    color: 'red',
  },
});

export type UnitUriData = {
  uris: Array<{ uri: string }>;
};

type UnitFormUrisProps = {
  loading?: boolean;
};

export const getUnitFormUrisSchema = (
  t: TFunction,
): Yup.ObjectSchema<UnitUriData> =>
  Yup.object().shape({
    uris: Yup.array()
      .required(t('general:errors.required') ?? '')
      .of(
        Yup.object({
          uri: Yup.string()
            .uri(t('units:validation.uri'))
            .required(t('general:errors.required') ?? ''),
        }),
      ),
  });

export const UnitFormUris: React.FC<UnitFormUrisProps> = ({ loading }) => {
  const { t } = useTranslation(['units', 'general']);
  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext<UnitUriData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'uris',
  });

  return (
    <FancyCard
      title={t('units:details.uris')}
      loading={loading}
      actions={
        <IconButton
          aria-label="add-uri-button"
          data-testid="add-uri-button"
          onClick={() => append({ uri: '' })}
          color="primary"
        >
          <Icons.Add />
        </IconButton>
      }
    >
      <Grid
        container
        spacing={1}
        direction="column"
        className={classes.cardContent}
      >
        {!fields.length && (
          <Grid item xs={12}>
            <EmptyBanner
              description={t('units:form.uri.empty')}
              actionLabel={t('units:form.uri.add')}
              onClickAction={() => append({ uri: '' })}
            />
          </Grid>
        )}
        {!!fields.length &&
          fields.map((field, index) => (
            <Grid item xs={12} key={field.id}>
              <div className={classes.row}>
                <div className={classes.inputWrapper}>
                  <InputBaseField
                    name={`uris.${index}.uri`}
                    placeholder="brix2bytes.io/units/95"
                    inputProps={{
                      className: classes.input,
                      'data-testid': `uri-input-${index}`,
                    }}
                  />
                </div>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  data-testid={`uri-remove-${index}`}
                  onClick={() => remove(index)}
                >
                  {t('registeredApplications:details.remove')}
                </Button>
              </div>
              {errors.uris?.[index]?.uri &&
                touchedFields.uris?.[index]?.uri && (
                  <div className={classes.error}>
                    <Typography variant="caption">
                      {errors.uris?.[index]?.uri?.message}
                    </Typography>
                  </div>
                )}
            </Grid>
          ))}
      </Grid>
    </FancyCard>
  );
};
