import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FancyCard } from '../../../components/FancyCard';
import { TextField } from '../../../components/Fields';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

export type RegisteredApplicationFormInfoData = {
  name: string;
  description: string;
};

type RegisteredApplicationFormInfoProps = {
  loading?: boolean;
};

export const getRegisteredApplicationFormInfoSchema = (
  t: TFunction,
): Yup.ObjectSchema<RegisteredApplicationFormInfoData> =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('general:errors.required') ?? '')
      .min(3, t('general:validation.name.length') ?? ''),
    description: Yup.string()
      .required(t('general:errors.required') ?? '')
      .min(3, t('registeredApplications:validation.description.length') ?? ''),
  });

export const RegisteredApplicationFormInfo: React.FC<RegisteredApplicationFormInfoProps> =
  React.memo(({ loading }) => {
    const { t } = useTranslation(['registeredApplications', 'general']);

    return (
      <FancyCard
        title={t('registeredApplications:details.info.title')}
        loading={loading}
      >
        <Grid
          container
          spacing={1}
          direction="column"
          className={classes.cardContent}
        >
          <Grid item xs={12} md={4}>
            <TextField
              disabled={loading}
              id="name"
              name="name"
              type="text"
              label={t('registeredApplications:details.info.field.name')}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              disabled={loading}
              id="description"
              name="description"
              type="text"
              label={t('general:fields.description')}
            />
          </Grid>
        </Grid>
      </FancyCard>
    );
  });

RegisteredApplicationFormInfo.displayName = 'RegisteredApplicationFormInfo';
