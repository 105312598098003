import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FancyCard } from '../../../../components/FancyCard';
import {
  FieldWithTooltip,
  TextField,
  makeOptional,
} from '../../../../components/Fields';
import { SelectField } from '../../../../components/SelectField/SelectField';
import { InstanceSize } from '../../../../api/qanteon';
import { instanceSizes } from '../common/constants';
import { Color } from '../../../../constants/Colors';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  link: {
    color: Color.link,
    fontWeight: 600,
  },
  instanceSize: {
    '& ~ p': {
      marginBottom: '10px',
    },
  },
});

export type QanteonProvisionFormData = {
  name: string;
  identifier: string;
};

type QanteonProvisionFormProps = {
  loading?: boolean;
};

export const identifierRegex = /^[a-z][a-z0-9]*$/;
export const getQanteonProvisionFormSchema = (
  t: TFunction,
): Yup.ObjectSchema<QanteonProvisionFormData> =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('general:errors.required') ?? '')
      .min(3, t('general:validation.name.length') ?? ''),
    identifier: Yup.string()
      .required(t('general:errors.required') ?? '')
      .min(3, t('general:validation.length.min', { length: '3' }) ?? '')
      .max(15, t('general:validation.length.max', { length: '15' }) ?? '')
      .matches(
        identifierRegex,
        `${t('integrations:qanteon.form.validation.identifier')}`,
      ),
  });

export const QanteonProvisionForm: React.FC<QanteonProvisionFormProps> = ({
  loading,
}) => {
  const { t } = useTranslation(['integrations', 'general']);

  const helperText = (
    <>
      {t('integrations:qanteon.form.helperText.instanceSize')}
      <a
        href="https://kp-mars.atlassian.net/wiki/spaces/MER/pages/1420394497/Sizing"
        className={classes.link}
      >
        {t('integrations:qanteon.form.fields.instanceSize')}
      </a>
    </>
  );

  return (
    <FancyCard title={t('integrations:qanteon.form.title')} loading={loading}>
      <Grid
        container
        spacing={1}
        direction="column"
        className={classes.cardContent}
      >
        <Grid item xs={12} md={4}>
          <FieldWithTooltip
            disabled={loading}
            id="name"
            name="name"
            type="text"
            label={t('general:fields.name')}
            innerComponent={TextField}
            tooltip={{ content: t('integrations:qanteon.form.tooltip.name') }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            disabled={loading}
            id="description"
            name="description"
            type="text"
            label={makeOptional(t, t('general:fields.description'))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldWithTooltip
            disabled={loading}
            id="identifier"
            name="identifier"
            type="text"
            label={t('integrations:qanteon.form.fields.identifier')}
            innerComponent={TextField}
            tooltip={{
              content: t('integrations:qanteon.form.tooltip.identifier'),
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectField
            className={classes.instanceSize}
            disabled={loading}
            id="instanceSize"
            labelId="instanceSize-label"
            name="instanceSize"
            data-testid="instanceSize-select"
            options={Object.keys(instanceSizes(t)).map((id) => ({
              id,
              label: instanceSizes(t)[id as InstanceSize],
            }))}
            fullWidth
            label={t('integrations:qanteon.form.fields.instanceSize')}
            defaultValue={InstanceSize.Small}
            helperText={helperText}
          />
        </Grid>
      </Grid>
    </FancyCard>
  );
};
