import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { ApplicationDetails } from './ApplicationDetails';
import {
  activateApplication,
  deactivateApplication,
  getApplication,
  Status,
} from '../../../api/application';
import { useHeader } from '../../../contexts/header-context';
import { ErrorMessage } from '../../../components/Errors';
import {
  ApplicationPermissionsConfirmDialog,
  ApplicationPermissionsConfirmDialogPermission,
} from '../common/ApplicationPermissionsConfirmDialog';
import { getListKnownPermissions } from '../../../constants/Permissions';
import { useBadge } from '../../../contexts/badge-context';
import { NotFound } from '../../errorScreens';

export const ApplicationDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['applications', 'general']);
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();
  const { refreshApplications } = useBadge();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const knownPermissionsList = useMemo(() => getListKnownPermissions(t), [t]);

  const {
    data: response,
    isFetching: loading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['getApplication', applicationId],
    queryFn: () => getApplication(applicationId),
    onError: (err: ApiError) => err,
  });

  const reload = () => {
    refetch();
    refreshApplications();
  };

  const { mutate: activateApp, ...activateState } = useMutation({
    mutationFn: activateApplication,
    onSuccess: reload,
  });
  const { mutate: deactivateApp, ...deactivateState } = useMutation({
    mutationFn: deactivateApplication,
    onSuccess: reload,
  });

  const application = response?.data ?? null;

  const hierarchy = application
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.APPLICATION,
            id: applicationId,
            name: application.name,
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: application?.name });
  }, [setTitle, application]);

  const handleConfirm = useCallback(() => {
    setConfirmDialogOpen(false);
    return activateApp({
      applicationId,
      consentedPermissions: application?.permissions ?? [],
    });
  }, [activateApp, applicationId, application]);

  const handleCancel = useCallback(() => {
    setConfirmDialogOpen(false);
  }, [setConfirmDialogOpen]);

  const permissions: ApplicationPermissionsConfirmDialogPermission[] =
    useMemo(() => {
      if (!application?.permissions) {
        return [];
      }
      return knownPermissionsList
        .map((permission) => ({
          ...permission,
          hasRead:
            permission.read &&
            application.permissions.includes(permission.read),
          hasWrite:
            permission.write &&
            application.permissions.includes(permission.write),
        }))
        .filter(({ hasRead, hasWrite }) => hasRead || hasWrite);
    }, [application, knownPermissionsList]);

  const handleReapprove = () => {
    setConfirmDialogOpen(true);
  };

  const handleDeactivate = () => {
    return deactivateApp(applicationId);
  };

  const handleActivate = () => {
    return permissions.length > 0
      ? setConfirmDialogOpen(true)
      : handleConfirm();
  };

  if (error instanceof Error) {
    if (error.status === 404) {
      return (
        <NotFound
          title={t('applications:details.notFound.title') ?? ''}
          subtitle={t('applications:details.notFound.subtitle') ?? ''}
          buttonText={t('applications:details.notFound.buttonText') ?? ''}
          buttonOnClick={() => navigate(Entities.APPLICATION.path)}
        />
      );
    }
    return <ErrorMessage error={error} />;
  }
  if (activateState.error instanceof Error) {
    return <ErrorMessage error={activateState.error} />;
  }
  if (deactivateState.error instanceof Error) {
    return <ErrorMessage error={deactivateState.error} />;
  }

  const status = application?.status ? Status[application.status] : undefined;
  return (
    <>
      <ApplicationPermissionsConfirmDialog
        open={confirmDialogOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        permissions={permissions}
      />
      <ApplicationDetails
        loading={loading}
        id={applicationId}
        name={application?.name ?? ''}
        description={application?.description ?? ''}
        url={application?.url ?? ''}
        status={status}
        activatedAt={application?.activatedAt}
        onReapprove={
          hasPermission(Permissions.ApplicationsWrite)
            ? handleReapprove
            : undefined
        }
        onActivate={
          hasPermission(Permissions.ApplicationsWrite)
            ? handleActivate
            : undefined
        }
        onDeactivate={
          hasPermission(Permissions.ApplicationsWrite)
            ? handleDeactivate
            : undefined
        }
        hasPermissionReadOnly={!hasPermission(Permissions.ApplicationsWrite)}
      />
    </>
  );
};
