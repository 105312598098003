import React from 'react';
import { useNavigate } from 'react-router';
import { CapabilityData } from './CapabilityData';
import { useCapabilityDataQuery } from '../../../__generated__/types';
import { Loading } from '../../../components/Loading';
import { ErrorMessage } from '../../../components/Errors';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';

type CapabilityDataContainerProps = {
  deviceId: string;
  id: string;
  name: string;
  unitSymbol: string;
};

export const CapabilityDataContainer: React.FC<
  CapabilityDataContainerProps
> = ({ deviceId, id, name, unitSymbol }) => {
  const navigate = useNavigate();

  const { loading, error, data } = useCapabilityDataQuery({
    variables: { limit: 3, deviceId, id },
  });

  const values = (data?.sensorMeasurements?.items ?? []).map((item) => ({
    timestamp: new Date(item?.utcTimeMeasured),
    value: `${item?.valueString ?? ''} ${unitSymbol}`,
  }));

  const navigateHistory = () => {
    navigate(entityLocation(Entities.DATA, deviceId));
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <CapabilityData
      title={name}
      values={values}
      onShowChart={navigateHistory}
    />
  );
};
