import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { DataTable } from '../../../components/DataTable';
import { Link } from '../../../components/Link';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { TooltipIcon } from '../../../components/TooltipIcon';

interface Capability {
  id: string;
  name?: string | null;
  isGlobal?: boolean | null;
  deviceModelCapabilities?: Array<{
    id: string;
  } | null> | null;
}

interface CapabilityListProps {
  capabilities: Capability[];
  onAdd?: () => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  page: number;
  pageSize: number;
  count: number;
  onSearchSubmit: (query: string) => void;
  searchValue: string;
  loading: boolean;
}

export const CapabilityList: React.FC<CapabilityListProps> = ({
  capabilities,
  onAdd,
  onChangePageSize,
  onChangePage,
  page,
  pageSize,
  count,
  onSearchSubmit,
  searchValue,
  loading,
}) => {
  const { t } = useTranslation(['capabilities', 'general']);

  const headers = [
    {
      key: 'name',
      value: t('capabilities:list.headers.name'),
    },
    {
      key: 'capabilityCount',
      value: t('capabilities:list.headers.capabilityCount'),
    },
    {
      key: 'capabilityType',
      value: (
        <TooltipIcon
          text={t('capabilities:list.headers.capabilityType')}
          tootltipText={t('capabilities:list.capabilityTypeTooltip')}
        />
      ),
    },
    {
      key: 'actions',
      value: '',
    },
  ];

  const rows = capabilities.map((capability) => ({
    key: `${capability.id}-row`,
    cells: [
      {
        key: `${capability.id}-cell-name`,
        element: (
          <Link to={entityLocation(Entities.CAPABILITY, capability.id)}>
            {capability.name || capability.id}
          </Link>
        ),
      },
      {
        key: `${capability.id}-cell-capabilityCount`,
        element: capability.deviceModelCapabilities?.length ?? 0,
      },
      {
        key: `${capability.id}-cell-capabilityType`,
        element: capability.isGlobal
          ? t('general:entityType.global')
          : t('general:entityType.local'),
      },
      {
        key: `${capability.id}-cell-actions`,
        element: null,
      },
    ],
  }));

  return (
    <DataTable
      actions={
        onAdd && (
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<Icons.Add />}
            onClick={onAdd}
          >
            {t('capabilities:list.actions.addButton')}
          </Button>
        )
      }
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      headers={headers}
      rows={rows}
      showPagination
      showSearch
      onSearchSubmit={onSearchSubmit}
      searchValue={searchValue}
      loading={loading}
    />
  );
};
