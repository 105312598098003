import React from 'react';
import { Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { Color } from '../../constants/Colors';

const classes = createClasses({
  fieldWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: '8px',
    marginRight: '4px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

type TooltipProps = {
  content: string;
  type?: 'info' | 'warning';
  testId?: string;
};
export type FieldWithTooltipProps<T> = T & {
  innerComponent: React.FC<T>;
  tooltip: TooltipProps;
};

export const FieldWithTooltip: React.FC<FieldWithTooltipProps<any>> = ({
  tooltip: { content, type, testId },
  innerComponent: Component,
  ...props
}) => {
  const Icon =
    type === 'warning' ? (
      <Icons.Warning
        htmlColor={Color.warning}
        data-testid={testId ?? 'warning-icon'}
      />
    ) : (
      <Icons.Info color="action" data-testid={testId ?? 'info-icon'} />
    );

  return (
    <div className={classes.fieldWithTooltip}>
      <Component {...props} />
      <Tooltip
        arrow
        title={<div className={classes.tooltipContent}>{content}</div>}
        className={classes.tooltip}
        placement="right"
      >
        {Icon}
      </Tooltip>
    </div>
  );
};
