import React, { MouseEventHandler, useState } from 'react';
import { createClasses } from '@kp/react-ui';
import {
  Card,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  IconButton,
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import classNames from 'classnames';

const classes = createClasses({
  root: {
    display: 'flex',
  },
  image: {
    flex: '0 0 auto',
    width: 200,
    height: 'calc(100% - 20px)',
    marginTop: 10,
    marginLeft: 10,
    marginBottom: 10,
    borderRadius: 5,
  },
  content: {
    flex: '1 1 auto',
    marginLeft: 10,
    marginRight: 10,
  },
  contentLoader: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerRoot: {
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 64,
  },
  headerAction: {
    alignSelf: 'center',
    marginTop: 0,
  },
  loader: {
    marginRight: 10,
    display: 'block',
  },
  fullHeight: {
    height: '100%',
  },
  fullLoader: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandIcon: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: 16,
    cursor: 'pointer',
  },
});

interface FancyCardPropsWithHandlers extends FancyCardProps {
  onEdit?: MouseEventHandler<HTMLButtonElement>;
}

interface FancyCardPropsWithActions extends FancyCardProps {
  actions: React.ReactNode;
}

interface FancyCardProps {
  className?: string;
  image?: { url: string; name: string };
  title: React.ReactNode;
  loading?: boolean;
  fullHeight?: boolean;
  fullLoader?: boolean;
  expandable?: boolean;
  defaultExpanded?: boolean;
  children?: React.ReactNode;
}

const isWithHandlers = (
  props: FancyCardPropsWithActions | FancyCardPropsWithHandlers,
): props is FancyCardPropsWithHandlers => {
  return (props as FancyCardPropsWithActions).actions === undefined;
};

const isWithActions = (
  props: FancyCardPropsWithActions | FancyCardPropsWithHandlers,
): props is FancyCardPropsWithActions => {
  return (props as FancyCardPropsWithActions).actions !== undefined;
};

export const FancyCard: React.FC<
  FancyCardPropsWithActions | FancyCardPropsWithHandlers
> = (props) => {
  const {
    children,
    className,
    image,
    title,
    loading,
    fullHeight,
    fullLoader,
    expandable,
    defaultExpanded,
  } = props;

  const [expanded, setExpanded] = useState(defaultExpanded ?? true);

  const renderActions = () => {
    if (isWithActions(props)) {
      const { actions } = props;
      return actions;
    }
    if (isWithHandlers(props)) {
      const { onEdit } = props;
      return (
        <>
          {onEdit && (
            <IconButton
              aria-label="edit"
              data-testid="card-edit"
              onClick={onEdit}
              size="medium"
              color="primary"
            >
              <Icons.Edit fontSize="small" />
            </IconButton>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <Card
      className={classNames(
        classes.root,
        fullHeight && classes.fullHeight,
        className,
      )}
    >
      {image && (
        <CardMedia
          className={classes.image}
          image={image.url}
          title={image.name}
        />
      )}
      <div
        className={classNames(
          classes.content,
          fullLoader && classes.contentLoader,
        )}
      >
        <CardHeader
          title={
            <div className={classes.titleContainer}>
              {title}
              {expandable && expanded && (
                <Icons.ExpandLess
                  aria-label="expand less"
                  data-testid="card-expand"
                  onClick={() => setExpanded(!expanded)}
                  className={classes.expandIcon}
                />
              )}
              {expandable && !expanded && (
                <Icons.ExpandMore
                  aria-label="expand more"
                  data-testid="card-expand"
                  onClick={() => setExpanded(!expanded)}
                  className={classes.expandIcon}
                />
              )}
            </div>
          }
          classes={{
            root: classes.headerRoot,
            action: classes.headerAction,
          }}
          action={
            !loading
              ? renderActions()
              : !fullLoader && (
                  <CircularProgress
                    className={classes.loader}
                    size="20px"
                    color="primary"
                  />
                )
          }
        />
        {expanded && (
          <>
            <Divider />
            {fullLoader && loading ? (
              <div className={classes.fullLoader}>
                <CircularProgress />
              </div>
            ) : (
              children
            )}
          </>
        )}
      </div>
    </Card>
  );
};
