import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../../components/ActionsBar';
import { InstanceSize } from '../../../../api/qanteon/common';
import {
  QanteonProvisionForm,
  QanteonProvisionFormData,
  getQanteonProvisionFormSchema,
} from './QanteonProvisionForm';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type QanteonProvisionCreateData = QanteonProvisionFormData & {
  description: string;
  instanceSize: InstanceSize;
};

type QanteonProvisionCreateProps = Partial<QanteonProvisionCreateData> & {
  loading?: boolean;
  onSubmit: (data: QanteonProvisionCreateData) => void;
  onDiscard: () => void;
};

export const QanteonProvisionCreate: React.FC<QanteonProvisionCreateProps> = ({
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['integrations', 'general']);

  const validationSchema = getQanteonProvisionFormSchema(t);
  const defaultValues: QanteonProvisionCreateData = {
    name: '',
    description: '',
    identifier: '',
    instanceSize: InstanceSize.Small,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('integrations:qanteon.buttons.save')}
        </Button>
      </ActionsBar>
      <QanteonProvisionForm loading={loading} />
    </FormProvider>
  );
};
