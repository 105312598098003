import React from 'react';
import { Button } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../components/DataTable';
import { Link } from '../../../components/Link';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { RegisteredApplication } from '../../../api/application';
import { FormattedDate } from '../../../components/FormattedDate';

interface RegisteredApplicationsListProps {
  applications: RegisteredApplication[];
  onAdd?: () => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
}

export const RegisteredApplicationsList: React.FC<
  RegisteredApplicationsListProps
> = ({
  applications,
  onAdd,
  onChangePage,
  onChangePageSize,
  page,
  pageSize,
  count,
  loading,
}) => {
  const { t } = useTranslation(['registeredApplications', 'general']);

  const headers = [
    {
      key: 'name',
      value: t('registeredApplications:headers.name'),
    },
    {
      key: 'description',
      value: t('general:fields.description'),
    },
    {
      key: 'createdAt',
      value: t('registeredApplications:headers.createdAt'),
    },
    {
      key: 'updatedAt',
      value: t('registeredApplications:headers.updatedAt'),
    },
  ];

  const rows = applications.map((application) => ({
    key: `${application.id}-row`,
    cells: [
      {
        key: `${application.id}-cell-name`,
        element: (
          <Link
            to={entityLocation(Entities.REGISTERED_APPLICATION, application.id)}
          >
            {application.name}
          </Link>
        ),
      },
      {
        key: `${application.id}-cell-description`,
        element:
          application.description && application.description.length > 160
            ? `${application.description.slice(0, 160)}...`
            : application.description ?? '',
      },
      {
        key: `${application.id}-cell-createdAt`,
        element: <FormattedDate value={application.createdAt} />,
      },
      {
        key: `${application.id}-cell-updatedAt`,
        element: <FormattedDate value={application.updatedAt} />,
        isLast: false,
      },
    ],
  }));

  return (
    <DataTable
      actions={
        onAdd && (
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<Icons.Add />}
            onClick={onAdd}
          >
            {t('registeredApplications:actions.addButton')}
          </Button>
        )
      }
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      headers={headers}
      rows={rows}
      showPagination
      loading={loading}
    />
  );
};
