import { CommissioningSvcMappings } from '@kp/rest-api-javascript-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useMappingsEditAllDevicesQuery } from '../../../../__generated__/types';
import {
  BACnetTarget,
  MappedDatapoint,
  findIndexByTarget,
  ExistingDevice,
  mapDevice,
  setCapabilityMapping,
  setCapabilityMappingsFromState,
  findByTarget,
} from './MappingStateUtils';

export type UseMappingState = {
  loading?: boolean;
  error?: Error;
  mappingState: MappedDatapoint[];
  allDevices: ExistingDevice[];
  setStateFromMappings: (
    datapoints: CommissioningSvcMappings,
    scanResult: BACnetTarget[],
  ) => Promise<MappedDatapoint[]>;
  addDevice: (device: ExistingDevice) => void;
  selectDevice: (target: BACnetTarget, device?: ExistingDevice) => void;
  selectCapability: (
    target: BACnetTarget,
    selectedCapabilityId?: string,
  ) => void;
  deleteMapping: (target: BACnetTarget) => void;
};

export const useMappingState = (gatewayId: string): UseMappingState => {
  const [mappingState, setMappingState] = useState<MappedDatapoint[]>([]);
  const [allDevices, setAllDevices] = useState<ExistingDevice[]>([]);

  const {
    loading: loadingAllDevices,
    data: dataAllDevices,
    error: errorAllDevices,
  } = useMappingsEditAllDevicesQuery({
    variables: {
      gatewayId,
    },
  });
  const allLoadedDevices = useMemo(
    () =>
      dataAllDevices?.devices?.items
        ? dataAllDevices.devices?.items?.map(mapDevice)
        : [],
    [dataAllDevices],
  );

  const addDevice = (device: ExistingDevice) => {
    setAllDevices([...allDevices, device]);
  };

  const selectDevice = (target: BACnetTarget, device?: ExistingDevice) => {
    const newMappingState = [...mappingState];
    const targetDatapointIdx = findIndexByTarget(mappingState, target);

    // if no device selected => delete mapping
    if (!device) {
      deleteMapping(target);
      return;
    }

    // update capability options of current device
    const capabilityOptions = device?.deviceModel.deviceModelCapabilities;
    const datapoint = {
      ...target,
      device,
      model: device?.deviceModel,
      capabilityOptions,
      capability: undefined,
    };

    if (targetDatapointIdx > -1) {
      newMappingState[targetDatapointIdx] = datapoint;
    } else {
      newMappingState.push(datapoint);
    }
    setMappingState(newMappingState);

    // device changed => update capability options of previous device
    const targetDatapoint = mappingState[targetDatapointIdx];
    const deviceChanged =
      targetDatapoint?.device && targetDatapoint?.device?.id !== device?.id;
    if (deviceChanged) {
      const newAllDevices = setCapabilityMapping(allDevices, targetDatapoint);
      setAllDevices(newAllDevices);
    }
  };

  const selectCapability = (
    target: BACnetTarget,
    selectedCapabilityId?: string,
  ) => {
    const targetDatapointIdx = findIndexByTarget(mappingState, target);
    const targetDatapoint = mappingState[targetDatapointIdx];

    const targetDevice = targetDatapoint?.device;
    if (!targetDevice) {
      console.warn('selectCapability failed. Target device not found', target);
      return;
    }

    const newMappingState = [...mappingState];

    // update the mapping state with the capability
    const capability = selectedCapabilityId
      ? targetDatapoint.device?.deviceModel.deviceModelCapabilities?.find(
          (c) => c.id === selectedCapabilityId,
        )
      : undefined;
    newMappingState[targetDatapointIdx] = {
      ...targetDatapoint,
      capability,
    };
    setMappingState(newMappingState);

    // set capability mapping in allDevices
    const newAllDevices = setCapabilityMapping(
      allDevices,
      targetDatapoint,
      selectedCapabilityId,
    );
    setAllDevices(newAllDevices);
  };

  const deleteMapping = (target: BACnetTarget) => {
    const targetDatapoint = findByTarget(mappingState, target);
    if (!targetDatapoint) {
      console.warn('Delete failed. Could not find target', target);
      return;
    }
    const newAllDevices = setCapabilityMapping(allDevices, targetDatapoint);
    setAllDevices(newAllDevices);

    const newMappingState = mappingState.filter(
      (dp) =>
        !(
          dp.deviceIdentifier === target.deviceIdentifier &&
          dp.objectIdentifier === target.objectIdentifier
        ),
    );
    setMappingState(newMappingState);
  };

  const setStateFromMappings = useCallback(
    async (mappings: CommissioningSvcMappings, scanResult: BACnetTarget[]) => {
      if (!mappings?.length) {
        setMappingState([]);
        return [];
      }

      const newMappingState = mappings
        .map((m) => {
          const target = scanResult.find(
            (s) =>
              s.deviceIdentifier === m.bacnetDeviceId &&
              s.objectIdentifier === m.bacnetObjectId,
          );
          if (!target) {
            console.warn('setState failed. Target not found for', m);
            return undefined;
          }
          const device = allLoadedDevices.find(
            (d) => d.id === m.mappedDeviceId,
          );
          if (!device) {
            console.warn('setState failed. Device not found for', m);
            return undefined;
          }

          const model = device.deviceModel;
          const dmc = device.deviceModel.deviceModelCapabilities.find(
            (mc) => mc.id === m.mappedCapabilityId,
          );
          if (!dmc) {
            console.warn('setState failed. Capability not found for', m);
            return undefined;
          }
          const capability = {
            ...dmc,
            isMapped: true,
          };
          const mappedDatapoint: MappedDatapoint = {
            ...target,
            model,
            device,
            capability,
          };
          return mappedDatapoint;
        })
        .filter((dp) => dp) as MappedDatapoint[];
      setMappingState(newMappingState);

      // update allDevices
      const allDevicesWithMappings = setCapabilityMappingsFromState(
        allLoadedDevices,
        newMappingState,
      );
      setAllDevices(allDevicesWithMappings);
      return newMappingState;
    },
    [allLoadedDevices],
  );

  return {
    loading: loadingAllDevices,
    error: errorAllDevices,
    mappingState,
    allDevices,
    addDevice,
    setStateFromMappings,
    selectDevice,
    deleteMapping,
    selectCapability,
  };
};
