import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useInterval } from 'react-use';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { ErrorAlert } from '../Alerts';
import {
  useGatewayListQuery,
  ConnectionStates,
} from '../../__generated__/types';
import { ErrorMessage } from '../Errors';
import { GatewayList, Gateway, Type } from './GatewayList';
import {
  getLatestSuccessfulDeployment,
  getDeploymentMappings,
} from '../../api/bacnet';
import { byName } from '../../utils/sort';

export { Type };

type State = Record<number, number>;
type Action = {
  idx: number;
  count: number;
};
const reducer = (state: State, { idx, count }: Action): State => ({
  ...state,
  [idx]: count,
});

const getStatus = (value?: ConnectionStates | null): ConnectionStates =>
  value ?? ConnectionStates.Unknown;

export type GatewayListContainerProps = {
  selectedId: string | undefined;
  onSelect: (id?: string) => void;
  onAdd?: () => void;
};

export const GatewayListContainer: React.FC<GatewayListContainerProps> = ({
  selectedId,
  onSelect,
  onAdd,
}) => {
  const { t } = useTranslation(['general', 'errors']);
  const { hasPermission } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const gatewayIdFromQuery = searchParams.get('gatewayId') ?? undefined;
  const [gateways, setGateways] = useState<Gateway[]>([]);
  const [mappingCounts, dispatch] = useReducer(reducer, {});

  const { loading, error, data, refetch } = useGatewayListQuery();
  useInterval(() => {
    refetch();
  }, 10000);

  const {
    mutateAsync: callGetLasSuccessfulDeployment,
    error: errorLatestSuccessfulDeployment,
  } = useMutation({
    mutationFn: getLatestSuccessfulDeployment,
    onError: (err: ApiError) => err,
  });
  const { mutateAsync: callGetMappings, error: errorMappings } = useMutation({
    mutationFn: getDeploymentMappings,
    onError: (err: ApiError) => err,
  });

  useEffect(() => {
    const allGateways: Gateway[] = (data?.devices?.items ?? [])
      .map((device) => ({
        id: device?.id ?? '-1',
        name: device?.name || '-',
        description: device?.description || '-',
        model: device?.deviceModel?.name || '-',
        connectionState: getStatus(device?.connectionState),
        capabilitiesCount:
          device?.deviceModel?.deviceModelCapabilities?.length ?? 0,
        childCount: device?.inverseParentDevice?.length ?? 0,
        childCapabilitiesCount: device?.inverseParentDevice?.reduce(
          (count, child) =>
            count + child.deviceModel.deviceModelCapabilities.length,
          0,
        ),
      }))
      .filter((device) => device.id !== '-1')
      .sort(byName);

    setGateways(allGateways);

    allGateways.forEach((d, idx) => {
      callGetLasSuccessfulDeployment(d.id)
        .then((result) => {
          const deployment = result.data;
          if (!deployment) return null;
          return callGetMappings({
            gatewayId: d.id,
            deploymentId: deployment.id,
          });
        })
        .then((result) => {
          const count = result?.data?.length ?? 0;
          dispatch({ idx, count });
          return result;
        })
        .catch((e) => console.warn(e));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSelect = (id?: string) => {
    if (selectedId === id) return;
    if (id) {
      searchParams.set('gatewayId', id);
    } else {
      searchParams.delete('gatewayId');
    }
    setSearchParams(searchParams);
    onSelect(id);
  };

  if (error) {
    return <ErrorMessage error={error} message={t('errors:gqlError.query')} />;
  }

  const gatewaysWithMappingCount = gateways.map((gw, idx) => ({
    ...gw,
    mappingCount: mappingCounts[idx],
  }));
  return (
    <>
      <GatewayList
        loading={loading}
        gateways={gatewaysWithMappingCount}
        selectedId={selectedId ?? gatewayIdFromQuery}
        onSelect={handleSelect}
        onAdd={hasPermission(Permissions.DevicesWrite) ? onAdd : undefined}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorLatestSuccessfulDeployment?.body.code !== 'UNKNOWN_GATEWAY' &&
          errorLatestSuccessfulDeployment?.status !== 404
            ? errorLatestSuccessfulDeployment
            : undefined,
          errorMappings,
        ]}
      />
    </>
  );
};
