import React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { Button, IconButton, ListItemText, Grid } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ActionsBar } from '../../../components/ActionsBar';
import { KnownPermissionsType } from '../../../constants/Permissions';
import { PermissionsTable } from '../../../components/PermissionsTable';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  infoIcon: {
    marginRight: '8px',
    display: 'block',
  },
});

export type RegisteredApplicationDetailsPermission = KnownPermissionsType & {
  hasWrite?: boolean;
  hasRead?: boolean;
};

type RegisteredApplicationDetailsProps = {
  id: string;
  name: string;
  description: string;
  createdAt?: string;
  updatedAt?: string;
  confidentialClientId: string;
  publicClientId: string;
  url: string;
  redirectUris: string[];
  permissions: RegisteredApplicationDetailsPermission[];
  loading?: boolean;
  onDelete?: () => void;
  onEdit?: (type: 'info' | 'settings' | 'permissions') => void;
};
export const RegisteredApplicationDetails: React.FC<
  RegisteredApplicationDetailsProps
> = ({
  id,
  name,
  description,
  createdAt,
  updatedAt,
  confidentialClientId,
  publicClientId,
  url,
  redirectUris,
  permissions,
  onDelete,
  onEdit,
  loading,
}) => {
  const { t } = useTranslation(['registeredApplications', 'general']);

  return (
    <>
      {onDelete && (
        <ActionsBar>
          {!loading && (
            <Button
              className={classes.actionButton}
              color="secondary"
              variant="outlined"
              size="large"
              startIcon={<Icons.Delete />}
              onClick={onDelete}
            >
              {t('registeredApplications:actions.deleteButton')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={6}>
              <FancyCard
                title={t('registeredApplications:details.info.title')}
                actions={
                  onEdit && (
                    <span>
                      <IconButton
                        aria-label="edit"
                        data-testid="information-edit"
                        onClick={() => onEdit('info')}
                        size="medium"
                        color="primary"
                      >
                        <Icons.Edit fontSize="small" />
                      </IconButton>
                    </span>
                  )
                }
                loading={loading}
              >
                <InfoList>
                  <InfoListItem
                    term={t('registeredApplications:details.info.id')}
                  >
                    {id}
                  </InfoListItem>
                  <InfoListItem
                    term={t('registeredApplications:details.info.name')}
                  >
                    {name}
                  </InfoListItem>
                  <InfoListItem term={t('general:fields.description')}>
                    {description}
                  </InfoListItem>
                  <InfoListItem
                    term={t('registeredApplications:details.info.createdAt')}
                  >
                    <FormattedDate value={createdAt} />
                  </InfoListItem>
                  <InfoListItem
                    term={t('registeredApplications:details.info.updatedAt')}
                  >
                    <FormattedDate value={updatedAt} />
                  </InfoListItem>
                  <InfoListItem
                    term={t(
                      'registeredApplications:details.info.confidentialClientId',
                    )}
                  >
                    {confidentialClientId}
                  </InfoListItem>
                  <InfoListItem
                    term={t(
                      'registeredApplications:details.info.publicClientId',
                    )}
                  >
                    {publicClientId}
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>
            <Grid item xs={6}>
              <FancyCard
                title={t('registeredApplications:details.settings.title')}
                actions={
                  onEdit && (
                    <span>
                      <IconButton
                        aria-label="edit"
                        data-testid="settings-edit"
                        onClick={() => onEdit('settings')}
                        size="medium"
                        color="primary"
                      >
                        <Icons.Edit fontSize="small" />
                      </IconButton>
                    </span>
                  )
                }
                loading={loading}
              >
                <InfoList>
                  <InfoListItem
                    term={t('registeredApplications:details.url.title')}
                  >
                    {url}
                  </InfoListItem>
                  <InfoListItem
                    term={t(
                      'registeredApplications:details.redirectUris.title',
                    )}
                  >
                    {redirectUris.map((redirectUrl, index) => (
                      <ListItemText key={index.toString()}>
                        {redirectUrl}
                      </ListItemText>
                    ))}
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>

            <Grid item xs={12}>
              <FancyCard
                title={t('general:permissions.headers.title')}
                actions={
                  onEdit && (
                    <span>
                      <IconButton
                        aria-label="edit"
                        data-testid="permissions-edit"
                        onClick={() => onEdit('permissions')}
                        size="medium"
                        color="primary"
                      >
                        <Icons.Edit fontSize="small" />
                      </IconButton>
                    </span>
                  )
                }
                loading={loading}
              >
                <PermissionsTable permissions={permissions} />
              </FancyCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
