import React from 'react';
import { createClasses } from '@kp/react-ui';
import { Button, Grid, Switch, Tooltip, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  DataTableHeader,
  DataTableRow,
} from '../../../../components/DataTable';
import { Link } from '../../../../components/Link';
import * as Entities from '../../../../constants/Entities';
import { entityLocation } from '../../../../utils/entity';
import { UserInvitation, UserInvitationStatus } from '../../../../api/user';
import { UserInvitationsOptions } from '../../../../api/user/invitations';
import { Color } from '../../../../constants/Colors';
import { FormattedDate } from '../../../../components/FormattedDate';

const classes = createClasses({
  tooltipContent: {
    padding: '4px',
    fontSize: '14px',
  },
  actionButton: {
    marginLeft: '150px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface UserInvitationsListProps {
  userInvitations: UserInvitation[];
  onAdd: () => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
  onChangeStatusFilter: () => void;
  statusFilter: UserInvitationsOptions;
}

export const UserInvitationsList: React.FC<UserInvitationsListProps> = ({
  userInvitations,
  onAdd,
  onChangePage,
  onChangePageSize,
  page,
  pageSize,
  count,
  loading,
  onChangeStatusFilter,
  statusFilter,
}) => {
  const { t } = useTranslation(['users', 'general']);

  const headers: DataTableHeader[] = [
    {
      key: 'email',
      value: t('users:invitation.headers.email'),
    },
    {
      key: 'roles',
      value: t('users:invitation.headers.roles'),
    },
    {
      key: 'status',
      value: t('users:invitation.headers.status'),
      align: 'center',
    },
    {
      key: 'createdAt',
      value: t('users:invitation.headers.createdAt'),
    },
  ];

  const rows: DataTableRow[] = userInvitations.map((userInvitation) => ({
    key: `${userInvitation.id}-row`,
    cells: [
      {
        key: `${userInvitation.id}-cell-email`,
        element: (
          <Link
            to={entityLocation(
              Entities.USER,
              `invitations/${userInvitation.id}`,
            )}
          >
            {userInvitation.email}
          </Link>
        ),
      },

      {
        key: `${userInvitation.id}-cell-roles`,
        element: userInvitation.roles?.map(({ name }, index) => (
          <div key={index.toString()}>{name}</div>
        )),
      },
      {
        key: `${userInvitation.id}-cell-status`,
        element: (
          <div className={classes.icon}>
            <Tooltip
              arrow
              placement="left"
              title={
                <div className={classes.tooltipContent}>
                  {userInvitation.status === UserInvitationStatus.Pending
                    ? t('users:invitation.status.pending')
                    : t('users:invitation.status.accepted')}
                </div>
              }
            >
              {userInvitation.status === UserInvitationStatus.Pending ? (
                <Icons.Autorenew
                  data-testid={`status-icon-pending-${userInvitation.id}`}
                  htmlColor={Color.mediumGrey}
                />
              ) : (
                <Icons.AssignmentTurnedIn
                  data-testid={`status-icon-accepted-${userInvitation.id}`}
                  htmlColor={Color.ok}
                />
              )}
            </Tooltip>
          </div>
        ),
        align: 'center',
      },
      {
        key: `${userInvitation.id}-cell-createdAt`,
        element: <FormattedDate value={userInvitation.createdAt} />,
        isLast: false,
      },
    ],
  }));

  return (
    <DataTable
      actions={
        <>
          <Typography component="div">
            <Grid
              component="label"
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item className={classes.icon}>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    <div className={classes.tooltipContent}>
                      {t('users:invitation.status.pending')}
                    </div>
                  }
                >
                  <Icons.Autorenew
                    htmlColor={Color.mediumGrey}
                    data-testid="switch-status-pending"
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Switch
                  checked={
                    statusFilter === UserInvitationsOptions.StatusAccepted
                  }
                  color="secondary"
                  onChange={onChangeStatusFilter}
                  inputProps={{ role: 'switch' }}
                  data-testid="switch-invitations"
                />
              </Grid>
              <Grid item className={classes.icon}>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    <div className={classes.tooltipContent}>
                      {t('users:invitation.status.accepted')}
                    </div>
                  }
                >
                  <Icons.AssignmentTurnedIn
                    htmlColor={Color.ok}
                    data-testid="switch-status-accepted"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Typography>
          <Button
            className={classes.actionButton}
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<Icons.Add />}
            onClick={onAdd}
          >
            {t('users:actions.addButton')}
          </Button>
        </>
      }
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      headers={headers}
      rows={rows}
      showPagination
      loading={loading}
    />
  );
};
