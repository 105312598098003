import React, { useRef, useState } from 'react';
import { Divider, Button, Alert, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { ConfirmAlert } from '../../../../components/Alerts';
import { UploadFile } from '../../../../utils/file-tools';
import { Color } from '../../../../constants/Colors';

const classes = createClasses({
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  filename: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    color: Color.mediumGrey,
    textSize: '80%',
  },
  row: { display: 'flex', flexDirection: 'row' },
  alert: { marginTop: 16 },
});

type ImportMappingsCSVDialogProps = {
  onConfirm: (file: UploadFile) => void;
  onCancel: () => void;
  open: boolean;
};

export const ImportMappingsCSVDialog: React.FC<
  ImportMappingsCSVDialogProps
> = ({ onConfirm, onCancel, open }) => {
  const { t } = useTranslation(['devices', 'general']);

  const clickUploadFile = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<UploadFile>();
  const handleClose = () => {
    if (clickUploadFile.current?.value) {
      clickUploadFile.current.value = '';
    }
    setFile(undefined);
    onCancel();
  };

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget?.files;
    if (files?.length) {
      setFile({
        name: files[0].name,
        content: files[0],
      });
    }
  };

  const handleConfirm = () => {
    if (file) {
      onConfirm(file);
      setFile(undefined);
    }
  };

  return (
    <ConfirmAlert
      open={open}
      maxWidth="md"
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmButton={t('devices:mappings.create.importcsv.upload')}
      options={{ confirmDisabled: file === undefined }}
      cancelButton={t('general:buttons.cancel')}
      closeButton
      title={t('devices:mappings.create.importcsv.title')}
      message={t('devices:mappings.create.importcsv.message')}
      aria-labelledby="upload-mapping-draft-csv-title"
      aria-describedby="upload-mapping-draft-csv-description"
    >
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.row}>
          <label htmlFor={'file-upload-csv'}>
            <input
              accept="text/csv"
              id={'file-upload-csv'}
              data-testid={'file-upload-csv'}
              name={'file-upload-csv'}
              style={{ display: 'none' }}
              type="file"
              onChange={onSelectFile}
              ref={clickUploadFile}
            />
            <Button
              color="primary"
              size="medium"
              data-testid="mapping-draft-select-file-button"
              onClick={() => {
                clickUploadFile.current?.click();
              }}
            >
              {t('devices:mappings.create.importcsv.selectfile')}
            </Button>
          </label>
          <div className={classes.filename}>{file?.name}</div>
        </Grid>
      </Grid>
      <Alert className={classes.alert} severity="warning">
        {t('devices:mappings.create.importcsv.warning')}
      </Alert>
    </ConfirmAlert>
  );
};
