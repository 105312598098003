import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  getUnitFormSettingsSchema,
  UnitData,
  UnitFormSettings,
} from '../common/UnitFormSettings';
import {
  getUnitFormUrisSchema,
  UnitFormUris,
  UnitUriData,
} from '../common/UnitFormUris';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type UnitCreateData = UnitData & UnitUriData;

type UnitCreateProps = {
  loading?: boolean;
  onSubmit: (unit: UnitCreateData) => void;
  onDiscard: () => void;
};

export const UnitCreate: React.FC<UnitCreateProps> = ({
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['units', 'general']);

  const validationSchema = getUnitFormSettingsSchema(t).concat(
    getUnitFormUrisSchema(t),
  );

  const defaultValues: UnitCreateData = {
    name: '',
    unitSymbol: '',
    uris: [],
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UnitFormSettings loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <UnitFormUris loading={loading} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
