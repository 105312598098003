import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DescriptionField, TextField } from '../../../components/Fields';
import { FancyCard } from '../../../components/FancyCard';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

interface BuildingFormInfoProps {
  loading?: boolean;
}

export interface BuildingFormData {
  name: string;
  description?: string;
  dimension?: string;
}

export const getBuildingFormInfoSchema = (
  t: TFunction,
): Yup.ObjectSchema<BuildingFormData> =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('general:errors.required') ?? '')
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
      .matches(/^\S+.*$/, t('buildings:form.validation.name.startsWith') ?? ''),
    description: Yup.string().optional(),
    dimension: Yup.string()
      .max(100, t('general:errors.maxLength', { max: 100 }) ?? '')
      .optional(),
  });

export const BuildingFormInfo: React.FC<BuildingFormInfoProps> = ({
  loading,
}) => {
  const { t } = useTranslation(['buildings', 'general']);

  return (
    <FancyCard title={t('buildings:details.info.title')} loading={loading}>
      <Grid
        container
        spacing={1}
        direction="column"
        className={classes.cardContent}
      >
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading}
            id="name"
            name="name"
            label={t('general:fields.name')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <DescriptionField
            disabled={loading}
            id="description"
            name="description"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading}
            id="dimension"
            name="dimension"
            label={t('buildings:details.info.dimension')}
            optional
          />
        </Grid>
      </Grid>
    </FancyCard>
  );
};
