import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isPast, sub } from 'date-fns';
import { ConfirmAlert } from '../../../components/Alerts';
import { GATEWAY_ACCESS_SIGNATURE_EXPIRY_WARNING_TIME } from '../../../constants/Misc';
import { useLocaleDateFns } from '../../../hooks/date-fns';

type AccessSignatureExpiryAlertProps = {
  expiryDate: Date;
  onGenerate: () => void;
};

export const AccessSignatureExpiryAlert: React.FC<
  AccessSignatureExpiryAlertProps
> = ({ expiryDate, onGenerate }) => {
  const { t } = useTranslation(['devices', 'general']);
  const { formatDefault } = useLocaleDateFns();

  const [accessSignatureExpiringOpen, setAccessSignatureExpiringOpen] =
    useState(false);
  useEffect(() => {
    const isExpiringSoon = isPast(
      sub(expiryDate, GATEWAY_ACCESS_SIGNATURE_EXPIRY_WARNING_TIME),
    );
    setAccessSignatureExpiringOpen(isExpiringSoon);
  }, [expiryDate]);
  const onAccessSignatureExpiringCancel = () =>
    setAccessSignatureExpiringOpen(false);
  const onAccessSignatureExpiringConfirm = () => {
    setAccessSignatureExpiringOpen(false);
    onGenerate();
  };

  if (!expiryDate) return null;
  return (
    <ConfirmAlert
      open={accessSignatureExpiringOpen}
      onClose={onAccessSignatureExpiringCancel}
      onConfirm={onAccessSignatureExpiringConfirm}
      fullWidth
      confirmButton={t(
        'devices:details.gatewayConfig.accessSignatureExpiring.generateButton',
      )}
      cancelButton={t('general:buttons.cancel')}
      closeButton
      title={t('devices:details.gatewayConfig.accessSignatureExpiring.title')}
      message={
        isPast(expiryDate)
          ? t(
              'devices:details.gatewayConfig.accessSignatureExpiring.messagePast',
              { expiryDate: formatDefault(expiryDate) },
            )
          : t(
              'devices:details.gatewayConfig.accessSignatureExpiring.messageSoon',
              { expiryDate: formatDefault(expiryDate) },
            )
      }
    ></ConfirmAlert>
  );
};
