import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import { entityLocation } from '../../../../utils/entity';
import { ErrorMessage } from '../../../../components/Errors';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import {
  DataTypes,
  useDeviceModelCapabilityEditDetailsQuery,
  useDeviceModelCapabilityUpdateMutation,
} from '../../../../__generated__/types';
import {
  DeviceModelCapabilityForm,
  DeviceModelCapabilityData,
} from '../common/DeviceModelCapabilityForm';
import { useHeader } from '../../../../contexts/header-context';
import { UNITLESS_URI } from '../../../../constants/Misc';
import { NotFound } from '../../../errorScreens';

export const DeviceModelCapabilityEditContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { deviceModelId, deviceModelCapabilityId } = params;
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useDeviceModelCapabilityEditDetailsQuery({
    variables: { deviceModelCapabilityId },
  });

  const allUnits = (data?.units?.items ?? []).map((unit) => ({
    id: unit.id,
    unitName: unit.name,
    unitSymbol: unit.unitSymbol ?? undefined,
    uris: unit.uris ?? [],
  }));
  const allCapabilities = data?.capabilities?.items;

  const deviceModelCapability = data?.deviceModelCapability;
  const isDeviceModelGlobal =
    deviceModelCapability?.deviceModel?.isGlobal ?? false;

  const existingFieldSelectors = (
    deviceModelCapability?.deviceModel?.deviceModelCapabilities ?? []
  )
    .map((dmc) => dmc.fieldSelector)
    .filter((selector) => selector) as string[];

  const hierarchy = deviceModelCapability
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICEMODEL,
            id: deviceModelId,
            name: deviceModelCapability.deviceModel?.name || deviceModelId,
          },
        ],
        t,
      ).concat([
        {
          title: t('deviceModels:capabilities.breadcrumb.title'),
          location: '',
        },
        {
          title:
            deviceModelCapability?.capability?.name || deviceModelCapabilityId,
          location: entityLocation(
            Entities.DEVICEMODEL,
            `${deviceModelId}/capabilities/${deviceModelCapabilityId}`,
          ),
        },
        {
          title: t('deviceModels:capabilities.breadcrumb.edit'),
          location: '',
        },
      ])
    : [];
  useBreadcrumb(hierarchy);

  const [
    updateDeviceModelCapability,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useDeviceModelCapabilityUpdateMutation({
    onCompleted: () =>
      navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId)),

    onError: (e) => console.warn(e),
  });

  const handleSubmit = async (inputData: DeviceModelCapabilityData) => {
    const { capabilityId } = inputData;
    const capability = allCapabilities?.find(({ id }) => id === capabilityId);
    const isBoolean = capability?.dataType === DataTypes.Bool;
    const notApplicableUnit = allUnits.find(({ uris }) =>
      uris.includes(UNITLESS_URI),
    );
    return updateDeviceModelCapability({
      variables: {
        deviceModelCapabilityInput: {
          id: deviceModelCapabilityId,
          capabilityId: inputData.capabilityId,
          deviceModelId,
          fieldSelector: inputData.fieldSelector ?? '',
          technicalMax: isBoolean ? undefined : Number(inputData.technicalMax),
          technicalMin: isBoolean ? undefined : Number(inputData.technicalMin),
          unitId: isBoolean ? notApplicableUnit?.id ?? '0' : inputData.unitId,
        },
      },
    });
  };

  const handleDiscard = () => {
    navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId));
  };

  useEffect(() => {
    setLoading(loading);
    setTitle({ main: deviceModelCapability?.capability.name });
  }, [setLoading, loading, setTitle, deviceModelCapability]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !deviceModelCapability) {
    return (
      <NotFound
        title={t('deviceModels:deviceModelCapability.notFound.title') ?? ''}
        subtitle={
          t('deviceModels:deviceModelCapability.notFound.subtitle') ?? ''
        }
        buttonText={
          t('deviceModels:deviceModelCapability.notFound.buttonText') ?? ''
        }
        buttonOnClick={() =>
          navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId))
        }
      />
    );
  }
  if (isDeviceModelGlobal) {
    return (
      <ErrorMessage
        error={new Error(t('deviceModels:capabilities.globalWarning') ?? '')}
      />
    );
  }

  return (
    <>
      <DeviceModelCapabilityForm
        loading={loading || loadingUpdate}
        capabilityId={deviceModelCapability?.capability?.id ?? '0'}
        technicalMax={String(deviceModelCapability?.technicalMax ?? '')}
        technicalMin={String(deviceModelCapability?.technicalMin ?? '')}
        unitId={deviceModelCapability?.unit?.id ?? '0'}
        fieldSelector={deviceModelCapability?.fieldSelector ?? ''}
        allUnits={allUnits ?? []}
        allCapabilities={allCapabilities ?? []}
        existingFieldSelectors={existingFieldSelectors}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
