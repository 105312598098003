import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { IntegrationsListContainer } from './list/IntegrationsListContainer';
import { QanteonProvisionDetailsContainer } from './qanteonProvision/details/QanteonProvisionDetailsContainer';
import { QanteonProvisionCreateContainer } from './qanteonProvision/create/QanteonProvisionCreateContainer';
import { useHeader } from '../../contexts/header-context';
import { NotFound } from '../errorScreens';

const Icon = Pages.INTEGRATIONS.icon;

export const IntegrationsRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<IntegrationsListContainer />} />
      <Route path="/qanteon" element={<QanteonProvisionDetailsContainer />} />
      <Route
        path="/qanteon/new"
        element={
          <ProtectedRoute
            element={<QanteonProvisionCreateContainer />}
            permission={Permissions.QanteonProvisioningWrite}
          />
        }
      />
      <Route path="*" element={<NotFound type="page" />} />
    </Routes>
  );
};
