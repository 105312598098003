import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { TFunction } from 'i18next';
import { makeOptional, TextField, CountryPicker } from '../Fields';
import { FancyCard } from '../FancyCard';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

export interface AddressFormData {
  location: {
    city?: string;
    country?: string;
    no?: string;
    street?: string;
    zip?: string;
  };
}

interface AddressFormProps {
  optional?: boolean;
  loading?: boolean;
  title?: string | null;
}

export const getOptionalAddressSchema = (
  t: TFunction,
): Yup.ObjectSchema<AddressFormData> =>
  Yup.object().shape({
    location: Yup.object().shape({
      city: Yup.string()
        .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
        .optional(),
      country: Yup.string()
        .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
        .optional(),
      street: Yup.string()
        .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
        .optional(),
      no: Yup.string()
        .max(50, t('general:errors.maxLength', { max: 50 }) ?? '')
        .optional(),
      zip: Yup.string()
        .max(50, t('general:errors.maxLength', { max: 50 }) ?? '')
        .optional(),
    }),
  });

export const getRequiredAddressSchema = (
  t: TFunction,
): Yup.ObjectSchema<AddressFormData> =>
  Yup.object().shape({
    location: Yup.object().shape({
      city: Yup.string()
        .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
        .required(t('general:errors.required') ?? ''),
      country: Yup.string()
        .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')

        .required(t('general:errors.required') ?? '')
        .typeError('general:forms.required'),
      street: Yup.string()
        .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
        .required(t('general:errors.required') ?? ''),
      no: Yup.string()
        .max(50, t('general:errors.maxLength', { max: 50 }) ?? '')
        .required(t('general:errors.required') ?? ''),
      zip: Yup.string()
        .max(50, t('general:errors.maxLength', { max: 50 }) ?? '')
        .required(t('general:errors.required') ?? ''),
    }),
  });

export const AddressForm: React.FC<AddressFormProps> = ({
  optional,
  loading,
  title,
}) => {
  const { t } = useTranslation(['general']);
  const titleText = title ?? t('general:address.title');

  const formTitle = optional ? makeOptional(t, titleText) : titleText;

  return (
    <FancyCard title={formTitle} loading={loading}>
      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.cardContent}
      >
        <Grid item xs={12} md={10}>
          <TextField
            id="location.street"
            name="location.street"
            label={t('general:address.street')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="location.no"
            name="location.no"
            label={t('general:address.no')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="location.zip"
            name="location.zip"
            label={t('general:address.zip')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            id="location.city"
            name="location.city"
            label={t('general:address.city')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <CountryPicker
            id="location.country"
            name="location.country"
            label={t('general:address.country')}
            disabled={loading}
          />
        </Grid>
      </Grid>
    </FancyCard>
  );
};
