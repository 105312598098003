import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { DeviceDelete } from './DeviceDelete';
import { useDeviceDeleteMutation } from '../../../__generated__/types';

interface DeviceDeleteContainerProps {
  id: string;
  isOpen: boolean;
  isGateway: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const DeviceDeleteContainer: React.FC<DeviceDeleteContainerProps> = ({
  id,
  isGateway,
  isOpen,
  onCompleted,
  onError,
  onCancel,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const [deleteChildDevices, setDeleteChildDevices] = useState(true);
  const [deleteDevice, { loading, error }] = useDeviceDeleteMutation({
    onCompleted,
    onError,
  });

  const handleConfirm = () =>
    deleteDevice({
      variables: {
        deviceId: id,
        deleteChildDevices: isGateway ? deleteChildDevices : false,
      },
    });

  return (
    <DeviceDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      isGateway={isGateway}
      deleteOptions={
        isGateway
          ? [
              {
                parameter: deleteChildDevices,
                parameterSetAction: setDeleteChildDevices,
                label: t('devices:deleteAlert.gatewayDevice.checkbox'),
              },
            ]
          : undefined
      }
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
