import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { TFunction } from 'i18next';
import { FancyCard } from '../../../components/FancyCard';
import { DescriptionField, TextField } from '../../../components/Fields';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

interface SiteFormInfoProps {
  loading?: boolean;
}

export interface SiteFormInfoData {
  name: string;
  description?: string;
}

export const getSiteFormInfoSchema = (
  t: TFunction,
): Yup.ObjectSchema<SiteFormInfoData> =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('general:errors.required') ?? '')
      .matches(/^\S+.*$/, t('sites:form.validation.name.startsWith') ?? '')
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? ''),
    description: Yup.string().optional(),
  });

export const SiteFormInfo: React.FC<SiteFormInfoProps> = ({ loading }) => {
  const { t } = useTranslation(['sites', 'general']);

  return (
    <FancyCard title={t('sites:details.info.title')} loading={loading}>
      <Grid
        container
        spacing={1}
        direction="column"
        className={classes.cardContent}
      >
        <Grid item xs={12} md={4}>
          <TextField
            id="name"
            name="name"
            label={t('sites:details.info.name')}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <DescriptionField
            id="description"
            name="description"
            disabled={loading}
          />
        </Grid>
      </Grid>
    </FancyCard>
  );
};
