import React from 'react';
import { Card, CardHeader, Divider, Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from '../ActionsBar';

const classes = createClasses({
  root: {
    display: 'flex',
  },
  content: {
    flex: '1 1 auto',
    marginLeft: 10,
    marginRight: 10,
  },
  headerRoot: {
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 64,
  },
  headerAction: {
    alignSelf: 'center',
    marginTop: 0,
  },
  warningIcon: {
    marginRight: '8px',
    display: 'block',
  },
  wrapper: {
    textAlign: 'left',
  },
  message: {
    margin: '32px 32px 32px 32px',
    color: 'red',
  },
  actions: {
    marginRight: '14px',
  },
});

interface FancyErrorCardProps {
  className?: string;
  message?: string | null;
  actionMessage?: string | null;
  onClickAction?: () => void;
}

export const FancyErrorCard: React.FC<FancyErrorCardProps> = ({
  className,
  message,
  actionMessage,
  onClickAction,
}) => {
  const { t } = useTranslation(['general']);

  return (
    <div className={classes.wrapper}>
      <Card className={classNames(classes.root, className)}>
        <div className={classes.content}>
          <CardHeader
            title={t('general:errorBox.title')}
            classes={{
              root: classes.headerRoot,
              action: classes.headerAction,
            }}
            action={
              <Icons.Warning
                className={classes.warningIcon}
                fontSize="medium"
                htmlColor="red"
              />
            }
          />
          <Divider />
          <div className={classes.message}>{message}</div>
          {actionMessage && onClickAction && (
            <>
              <Divider />
              <ActionsBar className={classes.actions}>
                <Button
                  onClick={onClickAction}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  aria-label="error-action"
                >
                  {actionMessage}
                </Button>
              </ActionsBar>
            </>
          )}
        </div>
      </Card>
    </div>
  );
};
