import type { TFunction } from 'i18next';
import type { ElementType } from 'react';
import * as Icons from '@mui/icons-material';
import { Permissions } from '@kp/react-sdk';
import { Badges } from '../contexts/badge-context';

export interface Page {
  label: (t: TFunction) => string;
  icon: ElementType;
  path: string | ((badges: Badges) => string);
  childPaths?: string[];
  permission?: Permissions;
  badge?: (badges: Badges) => boolean;
}

export const DASHBOARD: Page = {
  label: (t) => t('general:page.dashboard'),
  icon: Icons.Dashboard,
  path: '/',
};

export const APPLICATIONS: Page = {
  label: (t) => t('general:page.applications'),
  icon: Icons.Apps,
  path: (badges: Badges) =>
    `/applications${badges.hasUpdatedApplications ? '?sort=status' : ''}`,
  permission: Permissions.ApplicationsRead,
  badge: (badges: Badges) => badges.hasUpdatedApplications,
};

export const SITES: Page = {
  label: (t) => t('general:page.sites'),
  icon: Icons.Business,
  path: '/sites',
  childPaths: ['buildings', 'storeys', 'zones'],
  permission: Permissions.SitesRead,
};

export const DEVICES: Page = {
  label: (t) => t('general:page.devices'),
  icon: Icons.DeviceHub,
  path: '/devices',
  childPaths: ['data'],
  permission: Permissions.DevicesRead,
};

export const DEVICEMODELS: Page = {
  label: (t) => t('general:page.devicemodels'),
  icon: Icons.DevicesOther,
  path: '/devicemodels',
  permission: Permissions.DeviceModelsRead,
};

export const ATTRIBUTES: Page = {
  label: (t) => t('general:page.attributes'),
  icon: Icons.BallotOutlined,
  path: '/attributes',
  permission: Permissions.AttributesRead,
};

export const CAPABILITIES: Page = {
  label: (t) => t('general:page.capabilities'),
  icon: Icons.Reorder,
  path: '/capabilities',
  permission: Permissions.CapabilitiesRead,
};

export const UNITS: Page = {
  label: (t) => t('general:page.units'),
  icon: Icons.ChangeHistory,
  path: '/units',
  permission: Permissions.UnitsRead,
};

export const DEVELOPMENT: Page = {
  label: (t) => t('general:page.development'),
  icon: Icons.Code,
  path: '/development',
  permission: Permissions.RegisteredApplicationsRead,
};

export const USERS: Page = {
  label: (t) => t('general:page.users'),
  icon: Icons.PeopleOutlined,
  path: '/users',
  permission: Permissions.UsersRead,
};

export const USER_INVITATIONS: Page = {
  label: (t) => t('general:page.userInvitations'),
  icon: Icons.PeopleSharp,
  path: '/users/invitations',
  permission: Permissions.InvitationsRead,
};

export const TENANTS: Page = {
  label: (t) => t('general:page.tenants'),
  icon: Icons.BusinessCenter,
  path: '/tenants',
};

export const INTEGRATIONS: Page = {
  label: (t) => t('general:page.integrations'),
  icon: Icons.Extension,
  path: '/integrations',
  permission: Permissions.QanteonProvisioningRead,
};

export const STATUS: Page = {
  label: (t) => t('general:page.status'),
  icon: Icons.MonitorHeart,
  path: '/status',
  permission: Permissions.CommissioningRead,
};
