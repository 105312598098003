import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { DataTable } from '../../../components/DataTable';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { Link } from '../../../components/Link';
import { EmptyBanner } from '../../../components/EmptyBanner';

type Storey = {
  id: string;
  name: string;
};
export type BuildingDetailsStoreysProps = {
  storeys: Array<Storey>;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onAdd?: () => void;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
};

export const BuildingDetailsStoreys: React.FC<BuildingDetailsStoreysProps> = ({
  storeys,
  onChangePageSize,
  onChangePage,
  onAdd,
  page,
  pageSize,
  count,
  loading,
}) => {
  const { t } = useTranslation(['buildings', 'general']);

  const headers = [
    {
      key: t('buildings:details.storeys.header'),
      value: t('buildings:details.storeys.header'),
    },
  ];

  const rows = storeys.map((storey) => {
    return {
      key: storey.id,
      cells: [
        {
          key: `${storey.id}-cell-name`,
          element: (
            <Link to={entityLocation(Entities.STOREY, storey.id)}>
              {storey.name}
            </Link>
          ),
        },
      ],
    };
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        {!loading && (!storeys || !storeys.length) ? (
          <EmptyBanner
            description={t('buildings:details.storeys.nodata')}
            actionLabel={t('buildings:details.storeys.addStorey')}
            onClickAction={onAdd}
          />
        ) : (
          <DataTable
            actions={
              onAdd && (
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  startIcon={<Icons.Add />}
                  onClick={onAdd}
                >
                  {t('buildings:details.storeys.addStorey')}
                </Button>
              )
            }
            title={t('buildings:details.storeys.title')}
            loading={loading}
            headers={headers}
            rows={rows}
            showPagination
            page={loading ? 0 : page}
            pageSize={pageSize}
            count={count}
            onChangePage={onChangePage}
            onChangePageSize={onChangePageSize}
          />
        )}
      </Grid>
    </Grid>
  );
};
