import { Alert, Button, Grid, Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import {
  GridCellParams,
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '../../../../components/DataGrid';
import { FancyCard } from '../../../../components/FancyCard';
import { DeployAlert } from '../../common/DeployAlert';
import { Color } from '../../../../constants/Colors';
import { translateBacnetId } from '../../../../utils/bacnet-device-types';

const classes = createClasses({
  dataGrid: {
    '& .deleted': {
      backgroundColor: Color.deletedMappingEntry,
    },
    '& .changed': {
      backgroundColor: Color.changedMappingEntry,
    },
    '& .new': {
      backgroundColor: Color.newMappingEntry,
    },
  },
});

const Toolbar = () => (
  <GridFooterContainer>
    <GridToolbarFilterButton />
    <GridFooter />
  </GridFooterContainer>
);

type ReviewData = Array<{
  new: {
    deviceName: string;
    deviceModelName: string;
    capabilityName: string;
    bacnetDeviceId: string;
    bacnetObjectId: string;
  };
  previous: {
    deviceName: string;
    deviceModelName: string;
    capabilityName: string;
    bacnetDeviceId: string;
    bacnetObjectId: string;
  };
}>;

export type DeviceMappingsReviewProps = {
  loading: boolean;
  reviewData: ReviewData;
  onDeploy?: () => void;
  onDownload?: () => void;
};

export const DeviceMappingsReview: React.FC<DeviceMappingsReviewProps> = ({
  loading,
  reviewData,
  onDeploy,
  onDownload,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const [shouldConfirm, setShouldConfirm] = useState(false);

  const confirmDeploy = () => setShouldConfirm(true);
  const handleCancel = () => setShouldConfirm(false);
  const handleDeploy = () => {
    setShouldConfirm(false);
    if (onDeploy) onDeploy();
  };
  const handleDownload = () => {
    setShouldConfirm(false);
    if (onDownload) onDownload();
  };

  const getClassname = (params: GridCellParams) => {
    if (params.row.state !== 'changed') return params.row.state;
    if (params.row[params.field] !== params.row.prev[params.field])
      return params.row.state;
    return '';
  };
  const renderValueWithTooltip = (params: GridCellParams): React.ReactNode => {
    let tooltip: string | null = null;
    if (params.row.state === 'changed') {
      if (params.row[params.field] !== params.row.prev[params.field]) {
        tooltip = t('devices:mappings.review.tooltips.changedValue', {
          prev: params.row.prev[params.field],
        });
      }
    }
    if (params.row.state === 'new') {
      tooltip = t('devices:mappings.review.tooltips.newValue');
    }
    if (params.row.state === 'deleted') {
      tooltip = t('devices:mappings.review.tooltips.deletedValue', {
        prev: params.row.prev[params.field],
      });
    }
    if (!tooltip) return String(params.value);
    return (
      <Tooltip arrow placement="bottom" title={tooltip}>
        <span>{String(params.value) || '-'}</span>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'deviceName',
      headerName: t('devices:mappings.review.headers.deviceName') ?? '',
      flex: 1,
      cellClassName: (params) => getClassname(params),
      renderCell: renderValueWithTooltip,
    },
    {
      field: 'deviceModelName',
      headerName: t('devices:mappings.review.headers.deviceModelName') ?? '',
      flex: 1,
      cellClassName: (params) => getClassname(params),
      renderCell: renderValueWithTooltip,
    },
    {
      field: 'capabilityName',
      headerName: t('devices:mappings.review.headers.capabilityName') ?? '',
      flex: 1,
      cellClassName: (params) => getClassname(params),
      renderCell: renderValueWithTooltip,
    },
    {
      field: 'bacnetDeviceId',
      headerName: t('devices:mappings.review.headers.bacnetDeviceId') ?? '',
      flex: 1,
      cellClassName: (params) => getClassname(params),
      renderCell: renderValueWithTooltip,
    },
    {
      field: 'bacnetObjectId',
      headerName: t('devices:mappings.review.headers.bacnetObjectId') ?? '',
      flex: 1,
      cellClassName: (params) => getClassname(params),
      renderCell: renderValueWithTooltip,
    },
  ];

  const rows = useMemo(
    () =>
      reviewData.map((data) => {
        const { new: newData, previous: prevData } = data;
        let state = 'changed';
        if (
          !newData.deviceName &&
          !newData.deviceModelName &&
          !newData.capabilityName &&
          !newData.bacnetDeviceId &&
          !newData.bacnetObjectId
        ) {
          state = 'deleted';
        }
        if (
          !prevData.deviceName &&
          !prevData.deviceModelName &&
          !prevData.capabilityName &&
          !prevData.bacnetDeviceId &&
          !prevData.bacnetObjectId
        ) {
          state = 'new';
        }
        return {
          id:
            newData.bacnetDeviceId +
            newData.bacnetObjectId +
            prevData.bacnetDeviceId +
            prevData.bacnetObjectId,
          ...newData,
          bacnetObjectId: translateBacnetId(newData.bacnetObjectId),
          bacnetDeviceId: translateBacnetId(newData.bacnetDeviceId),
          ...(state === 'deleted'
            ? {
                bacnetDeviceId: translateBacnetId(prevData.bacnetDeviceId),
                bacnetObjectId: translateBacnetId(prevData.bacnetObjectId),
              }
            : {}),
          state,
          prev: {
            ...prevData,
            bacnetObjectId: translateBacnetId(prevData.bacnetObjectId),
            bacnetDeviceId: translateBacnetId(prevData.bacnetDeviceId),
          },
        };
      }),
    [reviewData],
  );

  const deletedCount = reviewData.filter(
    ({ new: newData, previous: prevData }) => {
      return (
        !newData.deviceName &&
        !newData.deviceModelName &&
        !newData.capabilityName &&
        !newData.bacnetDeviceId &&
        !newData.bacnetObjectId &&
        (prevData.deviceName ||
          prevData.deviceModelName ||
          prevData.capabilityName ||
          prevData.bacnetDeviceId ||
          prevData.bacnetObjectId)
      );
    },
  ).length;

  const hasDraft = !!reviewData?.length;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FancyCard
            title={t('devices:mappings.review.mappings')}
            loading={loading}
          >
            <DataGrid
              loading={loading}
              className={classes.dataGrid}
              height={590}
              slots={{
                toolbar: Toolbar,
              }}
              rows={rows}
              columns={columns}
              hideFooter
            />
          </FancyCard>
        </Grid>
        {deletedCount > 0 && (
          <Grid item xs={12} lg={12}>
            <Alert severity="warning">
              {t('devices:mappings.review.dataDeletionWarning', {
                count: deletedCount,
              })}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} lg={12}>
          <Button
            onClick={confirmDeploy}
            disabled={loading || !hasDraft || (!onDeploy && !onDownload)}
            color="primary"
            variant="contained"
          >
            {t('devices:mappings.review.deployButton')}
          </Button>
        </Grid>
      </Grid>
      <DeployAlert
        open={shouldConfirm}
        onCancel={handleCancel}
        onDownload={handleDownload}
        onDeploy={onDeploy ? handleDeploy : undefined}
      />
    </>
  );
};
