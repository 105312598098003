import React, { useState } from 'react';
import {
  Typography,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { KnownPermissionsValueType } from '../../../constants/Permissions';
import { CheckboxField } from '../../../components/Fields';

const classes = createClasses({
  arrowCell: {
    width: '30px',
    paddingRight: 0,
  },
  firstCell: {
    width: '46px',
  },
  labelCell: {
    width: '250px',
  },
  checkboxCell: {
    width: '30px',
  },
  wrapperCell: {
    padding: '0 !important',
  },
});

export type PermissionFormGroupProps = {
  permissionSet: keyof KnownPermissionsValueType;
  title: string;
  enabled?: boolean;
};

export const PermissionFormGroup: React.FC<PermissionFormGroupProps> =
  React.memo(({ title, permissionSet, enabled = true }) => {
    const [open, setOpen] = useState(false);
    const { watch } = useFormContext<{
      permissions: KnownPermissionsValueType;
    }>();
    const permissions = watch('permissions');
    const data = permissions[permissionSet];
    return (
      <>
        <TableHead>
          <TableRow onClick={() => setOpen(!open)}>
            <TableCell align="left" className={classes.arrowCell}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <Icons.KeyboardArrowUp /> : <Icons.KeyboardArrowDown />}
              </IconButton>
            </TableCell>
            <TableCell align="left">{title}</TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} className={classes.wrapperCell}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small">
                  <TableBody>
                    {data.map((p, index) => (
                      <TableRow key={`${p.id}-row`}>
                        <TableCell className={classes.firstCell} />
                        <TableCell align="left" className={classes.labelCell}>
                          <Typography variant="body1">{p.label}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {p.description}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.checkboxCell}
                        >
                          {p.read && (
                            <CheckboxField
                              color="primary"
                              type="checkbox"
                              size="small"
                              value={p.hasRead}
                              inputProps={{
                                // @ts-ignore
                                'data-testid': `${p.read}-checkbox`,
                              }}
                              name={`permissions.${permissionSet}.${index}.hasRead`}
                              disabled={!enabled}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.checkboxCell}
                        >
                          {p.write && (
                            <CheckboxField
                              color="primary"
                              type="checkbox"
                              size="small"
                              value={p.hasWrite}
                              inputProps={{
                                // @ts-ignore
                                'data-testid': `${p.write}-checkbox`,
                              }}
                              name={`permissions.${permissionSet}.${index}.hasWrite`}
                              disabled={!enabled}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </>
    );
  });

PermissionFormGroup.displayName = 'PermissionFormGroup';
