import React from 'react';

export const BuildingInsightsLogo: React.FC = () => (
  <svg
    width="59"
    height="36"
    viewBox="0 0 59 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="59" height="36" rx="8" fill="#282D37" />
    <path
      d="M13.7426 16.4875C14.0992 15.8635 14.6214 15.3541 15.3091 14.9593C15.9968 14.5645 16.7801 14.3671 17.6588 14.3671C18.6013 14.3671 19.4482 14.5899 20.1996 15.0357C20.951 15.4814 21.5432 16.1118 21.9762 16.9269C22.4092 17.7293 22.6257 18.6653 22.6257 19.7351C22.6257 20.7922 22.4092 21.7346 21.9762 22.5624C21.5432 23.3902 20.9446 24.0334 20.1805 24.4919C19.4291 24.9503 18.5885 25.1796 17.6588 25.1796C16.7546 25.1796 15.9586 24.9822 15.2709 24.5874C14.5959 24.1926 14.0865 23.6895 13.7426 23.0782V25.0077H12.0042V10.8712H13.7426V16.4875ZM20.8491 19.7351C20.8491 18.9455 20.6899 18.2578 20.3715 17.672C20.0531 17.0861 19.6201 16.6404 19.0725 16.3347C18.5376 16.0291 17.9454 15.8762 17.2959 15.8762C16.6591 15.8762 16.0669 16.0354 15.5193 16.3538C14.9844 16.6595 14.5514 17.1116 14.2202 17.7102C13.9018 18.296 13.7426 18.9774 13.7426 19.7542C13.7426 20.5438 13.9018 21.2379 14.2202 21.8365C14.5514 22.4223 14.9844 22.8744 15.5193 23.1928C16.0669 23.4985 16.6591 23.6513 17.2959 23.6513C17.9454 23.6513 18.5376 23.4985 19.0725 23.1928C19.6201 22.8744 20.0531 22.4223 20.3715 21.8365C20.6899 21.2379 20.8491 20.5375 20.8491 19.7351Z"
      fill="white"
    />
    <path
      d="M25.5125 21.9893C26.7351 20.9705 27.7094 20.1236 28.4353 19.4486C29.1612 18.7608 29.7662 18.0477 30.2501 17.309C30.7341 16.5703 30.976 15.8444 30.976 15.1312C30.976 14.4817 30.8232 13.9723 30.5176 13.6029C30.2119 13.2336 29.7407 13.0489 29.1039 13.0489C28.4671 13.0489 27.9768 13.2654 27.633 13.6985C27.2891 14.1187 27.1108 14.6982 27.0981 15.4369H24.5C24.5509 13.9086 25.0031 12.7497 25.8563 11.96C26.7224 11.1704 27.8176 10.7756 29.1421 10.7756C30.594 10.7756 31.7083 11.1641 32.4852 11.9409C33.2621 12.7051 33.6505 13.7176 33.6505 14.9784C33.6505 15.9718 33.3831 16.9206 32.8482 17.8248C32.3133 18.729 31.702 19.5186 31.0143 20.1936C30.3265 20.8559 29.4287 21.6582 28.3207 22.6006H33.9562V24.8166H24.5191V22.8299L25.5125 21.9893Z"
      fill="white"
    />
    <path
      d="M38.4114 16.4875C38.768 15.8635 39.2902 15.3541 39.9779 14.9593C40.6656 14.5645 41.4489 14.3671 42.3276 14.3671C43.2701 14.3671 44.117 14.5899 44.8684 15.0357C45.6198 15.4814 46.212 16.1118 46.645 16.9269C47.078 17.7293 47.2945 18.6653 47.2945 19.7351C47.2945 20.7922 47.078 21.7346 46.645 22.5624C46.212 23.3902 45.6134 24.0334 44.8493 24.4919C44.0979 24.9503 43.2573 25.1796 42.3276 25.1796C41.4234 25.1796 40.6274 24.9822 39.9397 24.5874C39.2647 24.1926 38.7553 23.6895 38.4114 23.0782V25.0077H36.673V10.8712H38.4114V16.4875ZM45.5179 19.7351C45.5179 18.9455 45.3587 18.2578 45.0403 17.672C44.7219 17.0861 44.2889 16.6404 43.7413 16.3347C43.2064 16.0291 42.6142 15.8762 41.9647 15.8762C41.3279 15.8762 40.7357 16.0354 40.1881 16.3538C39.6532 16.6595 39.2201 17.1116 38.889 17.7102C38.5706 18.296 38.4114 18.9774 38.4114 19.7542C38.4114 20.5438 38.5706 21.2379 38.889 21.8365C39.2201 22.4223 39.6532 22.8744 40.1881 23.1928C40.7357 23.4985 41.3279 23.6513 41.9647 23.6513C42.6142 23.6513 43.2064 23.4985 43.7413 23.1928C44.2889 22.8744 44.7219 22.4223 45.0403 21.8365C45.3587 21.2379 45.5179 20.5375 45.5179 19.7351Z"
      fill="white"
    />
  </svg>
);
