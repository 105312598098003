import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorAlert } from '../../../components/Alerts';
import {
  useZoneCreateMutation,
  useZoneCreateParentStoreyQuery,
} from '../../../__generated__/types';
import { ZoneForm, ZoneFormData } from '../common/ZoneForm';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useHeader } from '../../../contexts/header-context';

export const ZoneCreateContainer: React.FC = () => {
  const { t } = useTranslation(['zones', 'general']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setTitle } = useHeader();
  const storeyId = searchParams.get('storeyId') ?? '';

  const { loading, error, data } = useZoneCreateParentStoreyQuery({
    variables: { storeyId },
  });

  const storey = data?.storey;

  const hierarchy = storey?.building?.site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: storey.building.site.id,
            name: storey.building.site.name,
          },
          {
            type: Entities.BUILDING,
            id: storey.building.id,
            name: storey.building.name,
          },
          {
            type: Entities.STOREY,
            id: storey.id,
            name: storey.name,
          },
        ],
        t,
      ).concat({
        title: t('zones:breadcrumb.create'),
        location: entityLocation(Entities.STOREY, `${storey.id}/new`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('zones:breadcrumb.edit') });
  }, [setTitle, t]);

  const [create, { loading: loadingCreate, error: errorCreate }] =
    useZoneCreateMutation({
      onCompleted: (result) => {
        navigate(entityLocation(Entities.ZONE, result.createZone?.id ?? ''));
      },
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: ZoneFormData) => {
    return create({
      variables: {
        zone: {
          name: inputData.name,
          description: inputData.description,
          storeyId,
        },
      },
    });
  };

  if (!storeyId) {
    return <ErrorMessage error={new Error(t('zones:notFound') ?? '')} />;
  }

  const handleDiscard = () => {
    navigate(entityLocation(Entities.STOREY, storeyId));
  };

  return (
    <>
      <ZoneForm
        name=""
        description=""
        loading={loading || loadingCreate}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorCreate]}
      />
    </>
  );
};
