import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import {
  CapabilityUpdateType,
  useCapabilityUpdateMutation,
  useCapabilityEditDetailsQuery,
} from '../../../__generated__/types';
import { CapabilityForm, CapabilityData } from '../common/CapabilityForm';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const CapabilityEditContainer: React.FC = () => {
  const { t } = useTranslation(['capabilities', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { capabilityId } = params;
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useCapabilityEditDetailsQuery({
    variables: { capabilityId },
  });
  const capability = data?.capability;

  const hierarchy = capability
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.CAPABILITY,
            id: capability.id,
            name: capability.name || capability.id,
          },
        ],
        t,
      ).concat({
        title: t('capabilities:breadcrumb.edit'),
        location: entityLocation(Entities.CAPABILITY, `${capabilityId}/edit`),
      })
    : [];
  useBreadcrumb(hierarchy);

  const [updateCapability, { loading: loadingUpdate, error: errorUpdate }] =
    useCapabilityUpdateMutation({
      onCompleted: () =>
        navigate(entityLocation(Entities.CAPABILITY, capabilityId)),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: CapabilityData) => {
    const capabilityInput: CapabilityUpdateType = {
      id: capabilityId,
      name: inputData.capabilityName,
      description: inputData.description,
    };
    return updateCapability({
      variables: { capabilityInput },
    });
  };

  const handleDiscard = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  useEffect(() => {
    setLoading(loading);
    setTitle({ main: capability?.name });
  }, [setLoading, loading, setTitle, capability]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !capability) {
    return (
      <NotFound
        title={t('capabilities:details.notFound.title') ?? ''}
        subtitle={t('capabilities:details.notFound.subtitle') ?? ''}
        buttonText={t('capabilities:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.CAPABILITY.path)}
      />
    );
  }
  if (capability?.isGlobal) {
    return (
      <ErrorMessage
        error={new Error(t('capabilities:details.globalWarning') ?? '')}
      />
    );
  }

  return (
    <>
      <CapabilityForm
        loading={loading || loadingUpdate}
        type="edit"
        capabilityName={capability?.name ?? ''}
        description={capability?.description ?? ''}
        dataType={capability?.dataType ?? null}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
