import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import { CapabilityListContainer } from './list/CapabilityListContainer';
import { CapabilityDetailsContainer } from './details/CapabilityDetailsContainer';
import { CapabilityCreateContainer } from './create/CapabilityCreateContainer';
import { CapabilityEditContainer } from './edit/CapabilityEditContainer';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.CAPABILITIES.icon;

export const CapabilitiesRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<CapabilityListContainer />} />
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<CapabilityCreateContainer />}
            permission={Permissions.CapabilitiesWrite}
          />
        }
      />
      <Route path="/:capabilityId" element={<CapabilityDetailsContainer />} />
      <Route
        path="/:capabilityId/edit"
        element={
          <ProtectedRoute
            element={<CapabilityEditContainer />}
            permission={Permissions.CapabilitiesWrite}
          />
        }
      />
    </Routes>
  );
};
