import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Color } from '../../../../constants/Colors';

const classes = createClasses({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

interface QanteonProvisionCreateSuccessDialogProps {
  onConfirm: () => void;
}

export const QanteonProvisionCreateSuccessDialog: React.FC<
  QanteonProvisionCreateSuccessDialogProps
> = ({ onConfirm }) => {
  const { t } = useTranslation(['integrations', 'general']);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onConfirm}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6" component="span">
          {t('integrations:qanteon.create.confirm.title')}
        </Typography>
        <Icons.CheckCircle htmlColor={Color.ok} />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t('integrations:qanteon.create.confirm.message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          {t('general:buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
