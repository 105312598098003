import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import omitEmpty from 'omit-empty-es';
import { useQuery } from '@tanstack/react-query';
import { useHeader } from '../../../../contexts/header-context';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { entityLocation } from '../../../../utils/entity';
import * as Entities from '../../../../constants/Entities';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/UI';
import { ErrorMessage } from '../../../../components/Errors';
import {
  getUserInvitations,
  UserInvitationsFilter,
} from '../../../../api/user';
import { UserInvitationsOptions } from '../../../../api/user/invitations';
import { UserInvitationsList } from './UserInvitationsList';

export const UserInvitationsListContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['users', 'general']);
  const { setTitle } = useHeader();

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.USER,
        id: '',
        name: t('users:breadcrumb.invitations'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('users:invitation.title') });
  }, [setTitle, t]);

  const DEFAULT_FILTER = UserInvitationsOptions.StatusPending;

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, pageSize, statusFilter] = useMemo(
    () => [
      Number(searchParams.get('page') || '1') - 1 || 0,
      Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE,
      searchParams.get('filter') || DEFAULT_FILTER,
    ],
    [searchParams, DEFAULT_FILTER],
  );

  const setPageSize = useCallback(
    (value: number) => {
      setSearchParams(
        omitEmpty({
          pageSize: value === DEFAULT_PAGE_SIZE ? '' : value.toString(),
          filter: searchParams.get('filter') ?? '',
        }),
      );
    },
    [setSearchParams, searchParams],
  );

  const setPage = useCallback(
    (value: number) => {
      setSearchParams(
        omitEmpty({
          page: value ? (value + 1).toString() : '',
          pageSize: searchParams.get('pageSize') ?? '',
          filter: searchParams.get('filter') ?? '',
        }),
      );
    },
    [setSearchParams, searchParams],
  );

  const setStatusFilter = useCallback(
    (value: string) => {
      setSearchParams(
        omitEmpty({
          page: '',
          pageSize: searchParams.get('pageSize') ?? '',
          filter: value,
        }),
      );
    },
    [setSearchParams, searchParams],
  );

  const filter: UserInvitationsFilter = useMemo(
    () => ({
      skip: page * pageSize,
      limit: pageSize,
      sort: 'email',
      filter:
        statusFilter === DEFAULT_FILTER
          ? DEFAULT_FILTER
          : UserInvitationsOptions.StatusAccepted,
    }),
    [page, pageSize, statusFilter, DEFAULT_FILTER],
  );

  const {
    data: response,
    error,
    isLoading: loading,
  } = useQuery({
    queryKey: ['getUserInvitations', filter],
    queryFn: () => getUserInvitations(filter),
  });

  const userInvitations = response?.data ?? [];

  if (error instanceof Error) {
    return <ErrorMessage error={error} />;
  }

  const handleAdd = () =>
    navigate(entityLocation(Entities.USER, 'invitations/new'));

  const handleChangeStatusFilter = () =>
    setStatusFilter(
      statusFilter === DEFAULT_FILTER
        ? UserInvitationsOptions.StatusAccepted
        : DEFAULT_FILTER,
    );

  const filterStringToType = (f: string) =>
    f === DEFAULT_FILTER
      ? DEFAULT_FILTER
      : UserInvitationsOptions.StatusAccepted;

  return (
    <UserInvitationsList
      loading={loading}
      page={page}
      pageSize={pageSize}
      count={response?.meta?.totalSize ?? 0}
      onChangePageSize={setPageSize}
      onChangePage={setPage}
      userInvitations={userInvitations}
      onAdd={handleAdd}
      onChangeStatusFilter={handleChangeStatusFilter}
      statusFilter={filterStringToType(statusFilter)}
    />
  );
};
