import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import { FancyCard } from '../../../components/FancyCard';
import { SelectField } from '../../../components/SelectField/SelectField';
import { FieldWithTooltip, TextField } from '../../../components/Fields';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

export type DeviceModelData = {
  deviceModelName: string;
  deviceTypeId: string;
  deviceIdentifierFieldSelector: string;
};

type DeviceModelFormProps = DeviceModelData & {
  loading?: boolean;
  id: string;
  allDeviceTypes: Array<{
    id: string;
    deviceTypeName: string;
    isGateway: boolean;
  }>;
  type: 'edit' | 'create';
  onSubmit: (deviceModel: DeviceModelData) => void;
  onDiscard: () => void;
};

export const DeviceModelForm: React.FC<DeviceModelFormProps> = ({
  deviceModelName,
  deviceTypeId,
  deviceIdentifierFieldSelector,
  allDeviceTypes,
  type,
  onSubmit,
  onDiscard,
  loading,
}) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  const isGateway = (id: string) =>
    allDeviceTypes.find((dt) => dt.id === id)?.isGateway ?? false;

  const validationSchema = Yup.object().shape({
    deviceModelName: Yup.string()
      .required(t('general:errors.required') ?? '')
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? ''),
    deviceTypeId: Yup.string()
      .required(t('general:errors.required') ?? '')
      .notOneOf(['0'], t('deviceModels:form.selectError') ?? ''),
    deviceIdentifierFieldSelector: Yup.string()
      .when('deviceTypeId', ([value]) =>
        isGateway(value)
          ? Yup.string().required(t('general:errors.required') ?? '')
          : Yup.string().optional(),
      )
      .max(50, t('general:errors.maxLength', { max: 50 }) ?? ''),
  });

  const defaultValues: DeviceModelData = {
    deviceModelName,
    deviceTypeId,
    deviceIdentifierFieldSelector,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset, watch } = methods;
  useEffect(() => {
    reset({
      deviceModelName,
      deviceTypeId,
      deviceIdentifierFieldSelector,
    });
  }, [reset, deviceModelName, deviceTypeId, deviceIdentifierFieldSelector]);
  const deviceTypeIdValue = watch('deviceTypeId');

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="stretch" direction="row">
          <Grid item xs={12}>
            <FancyCard
              title={t('deviceModels:details.info.title')}
              loading={loading}
            >
              <Grid
                container
                spacing={1}
                className={classes.cardContent}
                direction="column"
              >
                <Grid item xs={12} md={6}>
                  <FieldWithTooltip
                    disabled={loading}
                    id="deviceModelName"
                    name="deviceModelName"
                    label={t('deviceModels:form.deviceModelName')}
                    innerComponent={TextField}
                    tooltip={{
                      content: t(
                        'deviceModels:form.infoTooltip.deviceModelName',
                      ),
                    }}
                  />
                </Grid>
                {type === 'create' && (
                  <Grid item xs={12} md={6}>
                    <SelectField
                      disabled={loading}
                      id="deviceType-select"
                      labelId="deviceType-select-label"
                      name="deviceTypeId"
                      data-testid="devicetype-select"
                      options={(allDeviceTypes ?? [])
                        .map((dt) => ({
                          id: dt.id,
                          label: dt.deviceTypeName,
                        }))
                        .concat([
                          {
                            id: '0',
                            label: t('general:forms.pleaseChoose'),
                          },
                        ])}
                      fullWidth
                      emptyLabel={t('general:forms.pleaseChoose')}
                      label={t('deviceModels:form.deviceTypeId')}
                    />
                  </Grid>
                )}
                {isGateway(deviceTypeIdValue) && (
                  <Grid item xs={12} md={8}>
                    <FieldWithTooltip
                      disabled={loading || type !== 'create'}
                      id="deviceIdentifierFieldSelector"
                      name="deviceIdentifierFieldSelector"
                      label={t(
                        'deviceModels:form.deviceIdentifierFieldSelector',
                      )}
                      multiline
                      innerComponent={TextField}
                      tooltip={{
                        content: t(
                          'deviceModels:form.infoTooltip.deviceIdentifierFieldSelector',
                        ),
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </FancyCard>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
