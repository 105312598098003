import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import { ErrorMessage } from '../../../components/Errors';
import {
  useZoneUpdateMutation,
  useZoneEditQuery,
} from '../../../__generated__/types';
import { ZoneForm, ZoneFormData } from '../common/ZoneForm';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityLocation } from '../../../utils/entity';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const ZoneEditContainer: React.FC = () => {
  const { t } = useTranslation(['zones', 'general']);
  const navigate = useNavigate();
  const { zoneId } = useParams();
  const { setTitle } = useHeader();

  const { loading, error, data } = useZoneEditQuery({ variables: { zoneId } });
  const zone = data?.zone;

  const hierarchy =
    zone &&
    zone.name &&
    zone.storey &&
    zone.storey.building &&
    zone.storey.building.site
      ? makeHierarchyBreadcrumb(
          [
            {
              type: Entities.SITE,
              id: zone.storey.building.site.id,
              name: zone.storey.building.site.name,
            },
            {
              type: Entities.BUILDING,
              id: zone.storey.building.id,
              name: zone.storey.building.name,
            },
            {
              type: Entities.STOREY,
              id: zone.storey.id,
              name: zone.storey.name,
            },
            {
              type: Entities.ZONE,
              id: zoneId,
              name: zone.name,
            },
          ],
          t,
        ).concat({
          title: t('zones:breadcrumb.edit'),
          location: entityLocation(Entities.ZONE, `${zoneId}/edit`),
        })
      : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('zones:breadcrumb.edit') });
  }, [setTitle, t]);

  const [updateZone, { loading: loadingUpdate, error: errorUpdate }] =
    useZoneUpdateMutation({
      onCompleted: () => navigate(entityLocation(Entities.ZONE, zoneId)),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: ZoneFormData) => {
    return updateZone({
      variables: {
        zoneInput: {
          name: inputData.name,
          description: inputData.description,
          id: zoneId,
        },
      },
    });
  };

  const handleDiscard = () => {
    navigate(entityLocation(Entities.ZONE, zoneId));
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !zone) {
    return (
      <NotFound
        title={t('zones:details.notFound.title') ?? ''}
        subtitle={t('zones:details.notFound.subtitle') ?? ''}
        buttonText={t('zones:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.ZONE.path)}
      />
    );
  }

  return (
    <>
      <ZoneForm
        name={zone?.name ?? ''}
        description={zone?.description ?? ''}
        loading={loading || loadingUpdate}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
