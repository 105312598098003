import React from 'react';
import { Grid, Button, Tooltip, IconButton, Divider } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from '../../../../components/ActionsBar';
import { FancyCard } from '../../../../components/FancyCard';
import { InfoList } from '../../../../components/InfoList';
import { InfoListItem } from '../../../../components/InfoListItem';
import { DeviceModelAttributeSetDetailsAttributesContainer } from './DeviceModelAttributeSetDetailsAttributesContainer';

type DeviceModelAttributeSetDetailsProps = {
  name: string;
  loading: boolean;
  isGlobal: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

const classes = createClasses({
  divider: {
    marginTop: '36px',
    marginBottom: '14px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

export const DeviceModelAttributeSetDetails: React.FC<
  DeviceModelAttributeSetDetailsProps
> = ({ name, loading, isGlobal, onEdit, onDelete }) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  return (
    <>
      {!isGlobal && (
        <ActionsBar>
          {!loading && onDelete && (
            <Button color="primary" size="large" onClick={onDelete}>
              {t('deviceModels:attributeSet.details.actions.delete')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12}>
              <FancyCard
                title={t('deviceModels:attributeSet.details.info.title')}
                actions={
                  <Tooltip
                    arrow
                    placement="top-end"
                    title={
                      isGlobal ? (
                        <div className={classes.tooltipContent}>
                          {t('deviceModels:attributeSet.globalWarning')}
                        </div>
                      ) : (
                        ''
                      )
                    }
                    aria-label="edit-tooltip"
                  >
                    <span>
                      <IconButton
                        aria-label="edit"
                        data-testid="card-edit"
                        onClick={onEdit}
                        size="medium"
                        color="primary"
                        disabled={!onEdit || isGlobal}
                      >
                        <Icons.Edit fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
                fullHeight
                loading={loading}
              >
                <InfoList>
                  <InfoListItem
                    term={t('deviceModels:attributeSet.details.info.name')}
                  >
                    {name}
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <DeviceModelAttributeSetDetailsAttributesContainer />
        </Grid>
      </Grid>
    </>
  );
};
