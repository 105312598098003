import React from 'react';
import { TableCell as MaterialTableCell } from '@mui/material';

export type TableCellProps = {
  key: string;
  element: React.ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  styles?: React.CSSProperties;
  className?: string;
  align?: 'left' | 'right' | 'center' | 'inherit';
};
export const TableCell: React.FC<TableCellProps> = ({
  element,
  isFirst = false,
  isLast = false,
  styles,
  align,
  className,
}) => {
  if (isFirst)
    return (
      <MaterialTableCell
        component="th"
        scope="row"
        style={styles}
        className={className}
      >
        {element}
      </MaterialTableCell>
    );
  if (isLast)
    return (
      <MaterialTableCell
        align={align ?? 'center'}
        style={styles}
        className={className}
      >
        {element}
      </MaterialTableCell>
    );
  return (
    <MaterialTableCell align={align} style={styles} className={className}>
      {element}
    </MaterialTableCell>
  );
};
