import { ApolloError } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '../../utils/helpers';

type Action = {
  label: string;
  onAction: () => void;
};
type KnownErrors = Error | ApolloError | undefined | null;
export type ErrorAlertProps = {
  title: string;
  message?: string | null;
  errors?: Array<KnownErrors>;
  actions?: Action[];
  showOk?: boolean;
};
export const ErrorAlert: React.FC<ErrorAlertProps> = ({
  title,
  message,
  errors,
  actions,
  showOk = true,
}) => {
  const { t } = useTranslation(['general']);

  const error = errors?.find((e) => e);
  const [showError, setShowError] = useState(!!error);
  const [lastErrorShown, setLastErrorShown] = useState<KnownErrors>();

  useEffect(() => {
    if (error && lastErrorShown !== error) {
      setLastErrorShown(error);
      setShowError(true);
    }
  }, [error, lastErrorShown]);

  const { message: errorMessage, details: errorDetails } =
    getErrorMessage(error);

  const handleClose = () => {
    setShowError(false);
  };

  return (
    <Dialog
      open={showError}
      onClose={actions?.length ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-message">
          {message ?? t('general:errorAlert.message')}
        </DialogContentText>
        {errorMessage && (
          <DialogContentText id="alert-dialog-error">
            {errorMessage}
          </DialogContentText>
        )}
        {errorDetails && (
          <DialogContentText id="alert-dialog-details">
            {errorDetails}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {actions?.map(
          ({ label, onAction }) =>
            label &&
            onAction && (
              <Button key={label} onClick={onAction}>
                {label}
              </Button>
            ),
        )}
        {showOk && (
          <Button onClick={handleClose} color="primary">
            {t('general:buttons.ok')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
