import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { ErrorAlert } from '../../../../components/Alerts';
import { entityLocation } from '../../../../utils/entity';
import * as Entities from '../../../../constants/Entities';
import { ErrorMessage } from '../../../../components/Errors';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';
import {
  getQanteonProvision,
  createQanteonProvision,
} from '../../../../api/qanteon';
import {
  QanteonProvisionCreate,
  QanteonProvisionCreateData,
} from './QanteonProvisionCreate';
import { QanteonProvisionCreateSuccessDialog } from './QanteonProvisionCreateSuccessDialog';
import { QanteonProvisionCreateConfirmDialog } from './QanteonProvisionCreateConfirmDialog';
import { isProvisionFound } from '../../constants';

export const QanteonProvisionCreateContainer: React.FC = () => {
  const { t } = useTranslation(['integrations', 'general']);
  const navigate = useNavigate();
  const { setTitle } = useHeader();

  const [confirmDialogMode, setConfirmDialogMode] = useState(false);
  const [formData, setFormData] = useState<QanteonProvisionCreateData | null>(
    null,
  );

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.INTEGRATION,
        id: '/new',
        name: t('integrations:qanteon.breadcrumb.create'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('integrations:qanteon.breadcrumb.create') });
  }, [setTitle, t]);

  const { error: errorGet, isLoading: loadingGet } = useQuery({
    queryKey: ['getQanteonProvision'],
    queryFn: getQanteonProvision,
  });

  const {
    mutate: create,
    error,
    isLoading: loading,
    data: response,
  } = useMutation({
    mutationFn: createQanteonProvision,
  });

  const data = response?.data;

  const handleDiscard = () => {
    navigate(entityLocation(Entities.INTEGRATION, ''));
  };

  const handleConfirm = useCallback(() => {
    data?.status && navigate(entityLocation(Entities.INTEGRATION, 'qanteon'));
  }, [data, navigate]);

  const handleSubmit = ({
    name,
    description,
    identifier,
    instanceSize,
  }: QanteonProvisionCreateData) => {
    setConfirmDialogMode(true);
    setFormData({ name, description, identifier, instanceSize });
  };

  const handleConfirmDialogConfirm = () => {
    setConfirmDialogMode(false);
    if (formData) {
      create(formData);
    }
  };

  const handleConfirmDialogDiscard = () => setConfirmDialogMode(false);

  const noProvision = isProvisionFound(errorGet as ApiError);

  if (!loadingGet && !noProvision) {
    return (
      <ErrorMessage
        error={
          new Error(t('integrations:qanteon.create.provisionExists') ?? '')
        }
      />
    );
  }

  return (
    <>
      <QanteonProvisionCreate
        loading={loading}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      {!error && !loading && data && (
        <QanteonProvisionCreateSuccessDialog onConfirm={handleConfirm} />
      )}
      <QanteonProvisionCreateConfirmDialog
        open={confirmDialogMode}
        onConfirm={handleConfirmDialogConfirm}
        onDiscard={handleConfirmDialogDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error as ApiError]}
      />
    </>
  );
};
