import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  SiteFormInfo,
  SiteFormInfoData,
  getSiteFormInfoSchema,
} from '../common/SiteFormInfo';
import {
  AddressForm,
  AddressFormData,
  getOptionalAddressSchema,
} from '../../../components/Address';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  section: {
    '& + &': {
      marginTop: '24px',
    },
  },
});

export type SiteCreateData = SiteFormInfoData & AddressFormData;
interface SiteCreateProps {
  loading?: boolean;
  onSubmit: (site: SiteCreateData) => void;
  onDiscard: () => void;
}

export const SiteCreate: React.FC<SiteCreateProps> = ({
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['sites', 'general']);

  const validationSchema = getSiteFormInfoSchema(t).concat(
    getOptionalAddressSchema(t),
  );
  const defaultValues: SiteCreateData = {
    name: '',
    description: '',
    location: {
      city: '',
      country: '',
      no: '',
      street: '',
      zip: '',
    },
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <div className={classes.section}>
        <SiteFormInfo loading={loading} />
      </div>
      <div className={classes.section}>
        <AddressForm loading={loading} optional />
      </div>
    </FormProvider>
  );
};
