import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Icons from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TableContainer,
} from '@mui/material';
import { DataTable } from '../../../components/DataTable';
import { FormattedDate } from '../../../components/FormattedDate';

type CapabilityDataProps = {
  title: string;
  values: Array<{ timestamp: Date; value: string }>;
  onShowChart: () => void;
};

export const CapabilityData: React.FC<CapabilityDataProps> = ({
  title,
  values,
  onShowChart,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const headers = [
    {
      key: t('devices:details.capabilityData.timestamp'),
      value: t('devices:details.capabilityData.timestamp'),
    },
    {
      key: t('devices:details.capabilityData.data'),
      value: t('devices:details.capabilityData.data'),
    },
  ];

  const rows = values.map(({ timestamp, value }) => {
    const ts = timestamp.toISOString();
    return {
      key: ts,
      cells: [
        {
          key: `${ts}-ts`,
          element: <FormattedDate value={ts} />,
        },
        {
          key: `${ts}-value`,
          element: value,
          isLast: false,
        },
      ],
    };
  });

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <IconButton onClick={onShowChart} aria-label="history">
            <Icons.Timeline />
          </IconButton>
        }
      />
      <TableContainer component={CardContent} data-testid={`data-${title}`}>
        {!rows.length ? (
          <div>{t('devices:details.capabilityData.nodata')}</div>
        ) : (
          <DataTable headers={headers} rows={rows} showPagination={false} />
        )}
      </TableContainer>
    </Card>
  );
};
