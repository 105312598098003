import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  SiteFormInfo,
  SiteFormInfoData,
  getSiteFormInfoSchema,
} from '../common/SiteFormInfo';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type SiteEditData = SiteFormInfoData;
type SiteEditProps = SiteEditData & {
  loading?: boolean;
  onSubmit: (location: SiteFormInfoData) => void;
  onDiscard: () => void;
};

export const SiteEdit: React.FC<SiteEditProps> = ({
  loading,
  name,
  description,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['sites', 'general']);

  const validationSchema = getSiteFormInfoSchema(t);
  const defaultValues: SiteFormInfoData = {
    name,
    description,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    reset({
      name,
      description,
    });
  }, [reset, name, description]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <SiteFormInfo loading={loading} />
    </FormProvider>
  );
};
