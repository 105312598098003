import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridFooter,
  GridFooterContainer,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbarFilterButton,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import { FancyCard } from '../FancyCard';
import { DataGrid } from '../DataGrid';
import { Link } from '../Link';
import { entityLocation } from '../../utils/entity';
import * as Entities from '../../constants/Entities';
import { EmptyBanner } from '../EmptyBanner';
import { ConfirmAlert } from '../Alerts';

type FieldDevice = {
  id: string;
  name: string;
  model: string;
  capabilitiesCount: number;
  mappingCount?: number;
};
type FieldDeviceListProps = {
  loading?: boolean;
  devices: FieldDevice[];
  scrollToDeviceId?: string;
  defaultExpanded?: boolean;
  onSelectDevice?: (id?: string) => void;
  onEditMappings?: () => void;
  onCreateMappings?: () => void;
  onDecommissionGateway?: () => void;
  onAddDevice?: () => void;
  hasDeployment: boolean;
};

const Toolbar = () => {
  return (
    <GridFooterContainer>
      <GridToolbarFilterButton />
      <GridFooter />
    </GridFooterContainer>
  );
};

const NoRowsOverlay = () => {
  const { t } = useTranslation(['components', 'general']);
  return (
    <EmptyBanner description={t('components:fieldDeviceList.noDevices')} />
  );
};

export const FieldDeviceList: React.FC<FieldDeviceListProps> = ({
  loading,
  devices,
  scrollToDeviceId,
  defaultExpanded,
  onSelectDevice,
  onEditMappings,
  onCreateMappings,
  onDecommissionGateway,
  onAddDevice,
  hasDeployment,
}) => {
  const { t } = useTranslation(['components', 'general']);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const [showDecommissioningAlert, setShowDecommissioningAlert] =
    useState(false);

  const apiRef = useGridApiRef();

  useEffect(() => {
    const devicePosition = devices.findIndex((d) => d.id === scrollToDeviceId);
    if (devicePosition !== -1 && apiRef?.current?.scrollToIndexes) {
      apiRef.current.scrollToIndexes({ rowIndex: devicePosition, colIndex: 0 });
    }
  }, [scrollToDeviceId, devices, apiRef]);

  const handleSelect = ([currentId]: GridRowSelectionModel) => {
    const [previousId] = selectionModel;
    const selectedId = previousId === currentId ? undefined : String(currentId);
    const newSelection = previousId === currentId ? [] : [currentId];
    if (onSelectDevice) onSelectDevice(selectedId);
    setSelectionModel(newSelection);
  };
  const handleDecommissionGatewayCancel = () =>
    setShowDecommissioningAlert(false);
  const handleDecommissionGateway = () => setShowDecommissioningAlert(true);
  const handleDecommissionGatewayConfirm = () => {
    setShowDecommissioningAlert(false);
    if (onDecommissionGateway) onDecommissionGateway();
  };

  const columns = [
    {
      field: 'name',
      headerName: t('components:fieldDeviceList.headers.name'),
      description: t('components:fieldDeviceList.headers.nameDescription'),
      flex: 1,
      renderCell: !onSelectDevice
        ? (params: GridRenderCellParams) => (
            <Link to={entityLocation(Entities.DEVICE, params.row.id)}>
              {params.value}
            </Link>
          )
        : undefined,
    },
    {
      field: 'model',
      headerName: t('components:fieldDeviceList.headers.deviceModel'),
      description: t(
        'components:fieldDeviceList.headers.deviceModelDescription',
      ),
      flex: 1,
    },
    {
      field: 'description',
      headerName: t('components:fieldDeviceList.headers.description'),
      description: t(
        'components:fieldDeviceList.headers.descriptionDescription',
      ),
      flex: 1,
    },
    {
      field: 'mappedCapabilities',
      headerName: t('components:fieldDeviceList.headers.mappedCapabilities'),
      description: t(
        'components:fieldDeviceList.headers.mappedCapabilitiesDescription',
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.mappingCount ?? '-'}/${params.row.capabilitiesCount}`,
    },
  ];

  return (
    <>
      <FancyCard
        title={t('components:fieldDeviceList.title')}
        loading={loading}
        expandable
        defaultExpanded={defaultExpanded ?? false}
        actions={
          <>
            {hasDeployment && onEditMappings && (
              <Button
                onClick={onEditMappings}
                color="primary"
                size="large"
                aria-label="edit-mappings-button"
                data-testid="edit-mappings-button"
              >
                {t('components:fieldDeviceList.actions.editMappings')}
              </Button>
            )}
            {hasDeployment && onDecommissionGateway && (
              <Button
                onClick={handleDecommissionGateway}
                color="primary"
                size="large"
                aria-label="decommission-gateway-button"
                data-testid="decommission-gateway-button"
              >
                {t('components:fieldDeviceList.actions.decommissionGateway')}
              </Button>
            )}
            {!hasDeployment && onCreateMappings && (
              <Button
                onClick={onCreateMappings}
                color="primary"
                size="large"
                aria-label="create-mappings-button"
                data-testid="create-mappings-button"
              >
                {t('components:fieldDeviceList.actions.createMappings')}
              </Button>
            )}
            {onAddDevice && (
              <Button
                onClick={onAddDevice}
                color="primary"
                size="large"
                aria-label="add-device-button"
                data-testid="add-device-button"
              >
                {t('components:fieldDeviceList.actions.addDevice')}
              </Button>
            )}
          </>
        }
      >
        <DataGrid
          loading={loading}
          apiRef={apiRef}
          components={{
            Toolbar,
            NoRowsOverlay,
          }}
          rows={devices}
          columns={columns}
          onRowSelectionModelChange={handleSelect}
          rowSelectionModel={selectionModel}
          disableMultipleRowSelection
          disableRowSelectionOnClick={!onSelectDevice}
          hideFooter
          hideFooterSelectedRowCount
        />
      </FancyCard>
      <ConfirmAlert
        open={showDecommissioningAlert}
        onClose={handleDecommissionGatewayCancel}
        onConfirm={handleDecommissionGatewayConfirm}
        title={t('components:decommissionGatewayAlert.title')}
        message={t('components:decommissionGatewayAlert.message')}
        cancelButton={t('general:buttons.cancel')}
        confirmButton={t('general:buttons.delete')}
      />
    </>
  );
};
