import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import { StoreyDetailsZones } from './StoreyDetailsZones';
import { useFilters } from '../../../hooks/filters';
import { ErrorMessage } from '../../../components/Errors';
import { useStoreyDetailsZonesQuery } from '../../../__generated__/types';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';

export const StoreyDetailsZonesContainer: React.FC = () => {
  const params = useParams();
  const { storeyId } = params;
  const { page, pageSize, setPage, setPageSize } = useFilters();
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const { loading, error, data } = useStoreyDetailsZonesQuery({
    variables: {
      take: pageSize,
      skip: page * pageSize,
      where: {
        storeyId: { eq: storeyId },
      },
    },
  });

  const handleAdd = () =>
    navigate(entityLocation(Entities.ZONE, `new?storeyId=${storeyId}`));

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const zones = data?.zones?.items ?? [];
  const count = data?.zones?.totalCount ?? 0;

  return (
    <StoreyDetailsZones
      onAdd={hasPermission(Permissions.ZonesWrite) ? handleAdd : undefined}
      loading={loading}
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      zones={zones}
    />
  );
};
