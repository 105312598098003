import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Divider } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { BuildingDetailsStoreysContainer } from './BuildingDetailsStoreysContainer';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ActionsBar } from '../../../components/ActionsBar';
import { AddressCard } from '../../../components/Address';
import { BuildingDetailsDevicesContainer } from './BuildingDetailsDevicesContainer';

const classes = createClasses({
  description: {
    whiteSpace: 'pre-wrap',
  },
  divider: {
    marginTop: '24px',
    marginBottom: '14px',
  },
});

type BuildingDetailsProps = {
  name: string;
  description: string;
  dimensions: string;
  street?: string;
  no?: string;
  zip?: string;
  city?: string;
  country?: string;
  image: { url: string; name: string };
  onDelete?: () => void;
  onEditInfo?: () => void;
  onEditLocation?: () => void;
  loading?: boolean;
  storeys: number;
  showStoreys?: boolean;
  showDevices?: boolean;
};

export const BuildingDetails: React.FC<BuildingDetailsProps> = ({
  name,
  description,
  image,
  street,
  no,
  zip,
  city,
  country,
  onDelete,
  onEditInfo,
  onEditLocation,
  loading,
  dimensions,
  storeys,
  showStoreys,
  showDevices,
}) => {
  const { t } = useTranslation(['buildings', 'general']);

  return (
    <>
      {onDelete && (
        <ActionsBar>
          {!loading && (
            <Button color="primary" size="large" onClick={onDelete}>
              {t('buildings:details.actions.delete')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12} sm={12} md={8}>
              <FancyCard
                title={t('buildings:details.info.title')}
                onEdit={onEditInfo}
                image={image}
                loading={loading}
                fullHeight
              >
                <InfoList>
                  <InfoListItem term={t('general:fields.name')}>
                    {name}
                  </InfoListItem>
                  {description && (
                    <InfoListItem
                      term={t('general:fields.description')}
                      className={classes.description}
                    >
                      {description}
                    </InfoListItem>
                  )}
                  {dimensions && (
                    <InfoListItem
                      term={t('buildings:details.info.dimension')}
                      className={classes.description}
                    >
                      {dimensions}
                    </InfoListItem>
                  )}
                  <InfoListItem
                    term={t('buildings:details.info.floors')}
                    className={classes.description}
                  >
                    {storeys}
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AddressCard
                city={city}
                street={street}
                no={no}
                zip={zip}
                country={country}
                onEdit={onEditLocation}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showDevices && <BuildingDetailsDevicesContainer />}
      {showStoreys && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Divider className={classes.divider} />
            <BuildingDetailsStoreysContainer />
          </Grid>
        </Grid>
      )}
    </>
  );
};
