import React from 'react';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { Link } from '../../../components/Link';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';

interface DeviceDetailsLocationProps {
  zone?: {
    id: string;
    name: string;
  };
  storey?: {
    id: string;
    name: string;
  };
  building?: {
    id: string;
    name: string;
  };
  site?: {
    id: string;
    name: string;
  };
  onEdit?: () => void;
  loading?: boolean;
}

export const DeviceDetailsLocation: React.FC<DeviceDetailsLocationProps> = ({
  zone,
  storey,
  building,
  site,
  onEdit,
  loading,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  return (
    <FancyCard
      title={t('devices:details.location.title')}
      onEdit={onEdit}
      loading={loading}
      fullHeight
    >
      <InfoList>
        {site && (
          <InfoListItem term={t('devices:details.location.site')}>
            <Link to={entityLocation(Entities.SITE, site.id)}>{site.name}</Link>
          </InfoListItem>
        )}
        {building && (
          <InfoListItem term={t('devices:details.location.building')}>
            <Link to={entityLocation(Entities.BUILDING, building.id)}>
              {building.name}
            </Link>
          </InfoListItem>
        )}
        {storey && (
          <InfoListItem term={t('devices:details.location.floor')}>
            <Link to={entityLocation(Entities.STOREY, storey.id)}>
              {storey.name}
            </Link>
          </InfoListItem>
        )}
        {zone && (
          <InfoListItem term={t('devices:details.location.zone')}>
            <Link to={entityLocation(Entities.ZONE, zone.id)}>{zone.name}</Link>
          </InfoListItem>
        )}
      </InfoList>
    </FancyCard>
  );
};
