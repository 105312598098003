import React, { useState } from 'react';
import { FormHelperText, InputLabel } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { makeOptional } from '../Fields';

interface DataTypeInputDateTimeProps {
  disabled?: boolean;
  value: string;
  id: string;
  name: string;
  label?: string;
  className?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

export const DataTypeInputDateTime = React.forwardRef<
  HTMLDivElement,
  DataTypeInputDateTimeProps
>(
  (
    {
      disabled,
      value,
      id,
      name,
      label,
      className,
      onChange,
      autoFocus = true,
      error,
      helperText,
      optional,
    },
    ref,
  ) => {
    const { t } = useTranslation(['general']);
    const [dateValue, setDateValue] = useState<Date | null>(
      value ? new Date(value) : null,
    );

    const handleDateChange = (date: Date | null) => {
      if (!date || String(date) === 'Invalid Date') return;
      setDateValue(date);
      onChange(date.toISOString());
    };

    return (
      <>
        <InputLabel shrink htmlFor={id}>
          {optional ? makeOptional(t, label) : label}
        </InputLabel>
        <DateTimePicker
          ref={ref}
          slotProps={{
            textField: {
              id,
              name,
              className,
              fullWidth: true,
              error,
            },
          }}
          disabled={disabled}
          ampm={false}
          autoFocus={autoFocus}
          value={dateValue}
          format="dd-MM-yyyy HH:mm"
          onChange={handleDateChange}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </>
    );
  },
);

DataTypeInputDateTime.displayName = 'DataTypeInputDateTime';
