import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { UserInvitationDelete } from './UserInvitationDelete';
import { deleteUserInvitation } from '../../../../api/user';

interface UserInvitationDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
}

export const UserInvitationDeleteContainer: React.FC<
  UserInvitationDeleteContainerProps
> = ({ id, isOpen, onCompleted, onCancel }) => {
  const {
    mutate: deleteInvitation,
    error,
    isLoading: loading,
  } = useMutation({
    mutationFn: deleteUserInvitation,
    onSuccess: onCompleted,
  });

  const handleConfirm = () => deleteInvitation(id);

  return (
    <UserInvitationDelete
      error={error as ApiError}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
