import { IconButton, IconButtonProps } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import React, { useEffect, useState } from 'react';
import * as Icons from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Color } from '../../constants/Colors';

const classes = createClasses({
  checkIcon: {
    color: Color.ok,
  },
});

type CopyIconButtonProps = IconButtonProps & {
  value: string;
  id?: string;
  onCopy?: () => void;
};
export const CopyIconButton: React.FC<CopyIconButtonProps> = ({
  id = '0',
  value,
  onCopy,
  ...iconButtonProps
}) => {
  const [copying, setCopying] = useState<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (copying) clearTimeout(copying);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copying]);

  const handleCopy = () => {
    const timeout = setTimeout(() => setCopying(undefined), 500);
    setCopying(timeout);
    if (onCopy) onCopy();
  };

  return (
    <CopyToClipboard text={value} onCopy={handleCopy}>
      <IconButton
        size="small"
        data-testid={`copy-to-clipboard-${id}`}
        {...iconButtonProps}
      >
        {copying ? (
          <Icons.Check
            className={classes.checkIcon}
            data-testid={`copy-to-clipboard-success-${id}`}
            fontSize="small"
          />
        ) : (
          <Icons.ContentCopy fontSize="small" />
        )}
      </IconButton>
    </CopyToClipboard>
  );
};
