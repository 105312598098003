import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  acceptPendingInvitation,
  getPendingInvitation,
} from '../../../api/user';
import { GlobalLoading } from '../../../components/GlobalLoading';
import { FancyErrorCard } from '../../../components/FancyErrorCard';
import { AuthAcceptInvitation } from './AuthAcceptInvitation';

export const AuthAcceptInvitationContainer: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['auth', 'general']);
  const navigate = useNavigate();
  const code = searchParams.get('code') ?? '';

  const {
    data: response,
    error,
    isLoading: loading,
  } = useQuery({
    queryKey: ['getPendingInvitation', code],
    queryFn: () => getPendingInvitation(code),
  });

  const { mutate: accept, ...acceptState } = useMutation({
    mutationFn: acceptPendingInvitation,
  });

  const handleSubmit = () => {
    accept(code);
  };

  // Wait until we have the invitation data
  if (loading) {
    return <GlobalLoading />;
  }

  // In case there is no code provided we show an error
  if (!code) {
    return <FancyErrorCard message={t('auth:register.errors.code')} />;
  }

  // For errors coming from the backend
  if (error instanceof Error) {
    if (error.message === 'Not Found') {
      return <FancyErrorCard message={t('auth:register.errors.code')} />;
    }

    return <FancyErrorCard message={error.message} />;
  }

  // In case the invitation is for non-existing user we redirect the user to
  // the registration page
  if (
    response?.data &&
    !response.data.inviteeUserId &&
    !response.data.isAzureUser
  ) {
    navigate(`/register?code=${code}`);
    return <GlobalLoading />;
  }

  // In case the user has accepted the invitation we redirect to success page
  if (!acceptState.isLoading && acceptState?.data?.data?.status === 'success') {
    navigate('/landing?from=accept-invitation');
    return <GlobalLoading />;
  }
  return (
    <AuthAcceptInvitation
      onSubmit={handleSubmit}
      error={(acceptState.error as Error)?.message}
      loading={acceptState.isLoading}
      tenant={response?.data?.tenantName ?? ''}
      email={response?.data?.email ?? ''}
    />
  );
};
