import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAuth } from '@kp/react-sdk';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { AuthRegister, RegisterData } from './AuthRegister';
import {
  getPendingInvitation,
  registerPendingInvitation,
} from '../../../api/user';
import { GlobalLoading } from '../../../components/GlobalLoading';
import { FancyErrorCard } from '../../../components/FancyErrorCard';

export const AuthRegisterContainer: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['auth', 'general']);
  const { isAuthenticated, signOut } = useAuth();
  const navigate = useNavigate();
  const code = searchParams.get('code') ?? '';

  const {
    data: response,
    error,
    isLoading: loading,
  } = useQuery({
    queryKey: ['getPendingInvitation', code],
    queryFn: () => getPendingInvitation(code),
  });

  const { mutate: register, ...registerState } = useMutation({
    mutationFn: registerPendingInvitation,
  });

  const handleSubmit = (data: RegisterData) =>
    register({
      hash: code,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
    });

  // Wait until we have registered the user
  if (loading) {
    return <GlobalLoading />;
  }

  // In case the user is authenticated we should not allow them to register
  if (isAuthenticated) {
    return (
      <FancyErrorCard
        message={t('auth:register.errors.authenticated')}
        actionMessage={t('auth:register.signout')}
        onClickAction={() => {
          signOut();
        }}
      />
    );
  }

  // In case there is no code provided we also throw a message
  if (!code) {
    return <FancyErrorCard message={t('auth:register.errors.code')} />;
  }

  // For errors coming from the backend
  if (error instanceof Error) {
    if (error.message === 'Not Found') {
      return <FancyErrorCard message={t('auth:register.errors.code')} />;
    }
    return <FancyErrorCard message={error.message} />;
  }

  // In case the invitation is for already existing user we redirect the user to
  // the accept registration page
  if (response?.data && response.data.inviteeUserId) {
    navigate(`/accept-invitation?code=${code}`);
    return <GlobalLoading />;
  }

  // In case the user has been registered successfully we redirect to success page
  if (
    !registerState.isLoading &&
    registerState.data?.data?.status === 'success'
  ) {
    navigate('/landing');
    return <GlobalLoading />;
  }

  const registerError = (err?: ApiError) => {
    if (err?.statusText === 'Conflict') {
      return t('auth:register.errors.conflict');
    }
    return err?.message;
  };

  return (
    <AuthRegister
      onSubmit={handleSubmit}
      error={registerError(registerState.error as ApiError)}
      loading={registerState.isLoading}
      tenant={response?.data?.tenantName ?? ''}
      email={response?.data?.email ?? ''}
    />
  );
};
