import React from 'react';
import {
  Alert,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { InfoAlert } from '../../../components/Alerts';
import { Loading } from '../../../components/Loading';
import { CopyIconButton } from '../../../components/CopyIconButton';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  tableCell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontFamily: 'monospace',
  },
  accessSignatureWarning: {
    marginTop: 16,
  },
});

type AccessSignature = {
  sharedAccessSignature?: string;
  sharedAccessSignatureExpiry?: string;
};

type AccessSignatureAlertProps = {
  open: boolean;
  loading?: boolean;
  generateAccessSignature: () => void;
  accessSignature?: AccessSignature;
  onClose: () => void;
};

export const AccessSignatureAlert: React.FC<AccessSignatureAlertProps> = ({
  open,
  loading,
  generateAccessSignature,
  accessSignature,
  onClose,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  return (
    <InfoAlert
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      title={t('devices:details.gatewayConfig.accessSignatureAlert.title')}
      message={t('devices:details.gatewayConfig.accessSignatureAlert.message')}
    >
      <Divider className={classes.divider} />
      {loading && <Loading />}
      {!loading && !accessSignature && (
        <Button
          color="primary"
          variant="contained"
          onClick={generateAccessSignature}
        >
          {t(
            'devices:details.gatewayConfig.accessSignatureAlert.generateButton',
          )}
        </Button>
      )}
      {!loading && accessSignature && (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                {t(
                  'devices:details.gatewayConfig.accessSignatureAlert.sharedAccessSignature',
                )}
              </TableCell>
              <TableCell
                align="right"
                className={classes.tableCell}
                data-testid="sharedAccessSignature"
              >
                {accessSignature?.sharedAccessSignature}
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                <CopyIconButton
                  id={'sharedAccessSignature'}
                  value={
                    accessSignature?.sharedAccessSignature
                      ? String(accessSignature.sharedAccessSignature)
                      : ''
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t(
                  'devices:details.gatewayConfig.accessSignatureAlert.sharedAccessSignatureExpiry',
                )}
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                {accessSignature?.sharedAccessSignatureExpiry ? (
                  <FormattedDate
                    value={
                      new Date(accessSignature.sharedAccessSignatureExpiry)
                    }
                  />
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      )}
      {!loading && (
        <Alert severity="warning" className={classes.accessSignatureWarning}>
          {t('devices:details.gatewayConfig.accessSignatureAlert.warning')}
        </Alert>
      )}
    </InfoAlert>
  );
};
