import React from 'react';
import { useParams } from 'react-router';
import { ErrorMessage } from '../../../../components/Errors';
import {
  DataTypes,
  useDeviceModelAttributeSetDetailsAttributesQuery,
} from '../../../../__generated__/types';
import { notEmpty } from '../../../../utils/helpers';
import { DeviceModelAttributeSetDetailsAttributes } from './DeviceModelAttributeSetDetailsAttributes';
import { ConditionalLoading } from '../../../../components/ConditionalLoading';

export const DeviceModelAttributeSetDetailsAttributesContainer: React.FC =
  () => {
    const { deviceModelAttributeSetId } = useParams();

    const { loading, error, data } =
      useDeviceModelAttributeSetDetailsAttributesQuery({
        variables: {
          deviceModelAttributeSetId,
        },
      });

    const deviceModel = data?.deviceModelAttributeSet?.deviceModel;
    const isDeviceModelGlobal = deviceModel?.isGlobal ?? false;
    const allAttributes = data?.attributes?.items;
    const deviceModelAttributeSets =
      deviceModel?.deviceModelAttributeSets ?? [];
    const deviceModelAttributeSet = data?.deviceModelAttributeSet;

    // Gives back the attribute ids used in other sets of the same device model, so we
    // can remove those from the available attributes for this set.
    const reservedAttributeIds = deviceModelAttributeSets
      .filter(({ id }) => id !== deviceModelAttributeSetId)
      .reduce<string[]>(
        (result, set) =>
          result.concat(
            set.mappingAttributeDeviceModelAttributeSets.map(
              ({ attributeId }) => attributeId,
            ),
          ),
        [],
      );

    const mappings =
      deviceModelAttributeSet?.mappingAttributeDeviceModelAttributeSets
        ?.filter(notEmpty)
        .map((mapping) => ({
          mappingId: mapping.id,
          attributeId: mapping.attributeId,
          attributeName: mapping.attribute?.name ?? '',
          required: mapping.attribute?.required ?? false,
          dataType: mapping.attribute?.dataType || DataTypes.NotSpecified,
        })) ?? [];

    const allAttributeValues = (allAttributes ?? [])
      .filter(({ id }) => !reservedAttributeIds?.includes(id))
      .map((a) => ({
        attributeId: a.id,
        attributeName: a.name,
        required: a.required ?? false,
        dataType: a.dataType || DataTypes.NotSpecified,
      }));

    if (error) {
      return <ErrorMessage error={error} />;
    }

    return (
      <ConditionalLoading loading={loading}>
        <DeviceModelAttributeSetDetailsAttributes
          attributeSetId={deviceModelAttributeSetId}
          mappings={mappings}
          allAttributes={allAttributeValues}
          isDeviceModelGlobal={isDeviceModelGlobal}
        />
      </ConditionalLoading>
    );
  };
