import {
  CommissioningSvcDeploymentService as DeploymentService,
  CommissioningSvcGatewayService as GatewayService,
  CommissioningSvcLookupService as LookupService,
  CommissioningSvcMappingDraft,
} from '@kp/rest-api-javascript-sdk';

export type DeviceDiscoverySettings = {
  timeout: number;
  rangeStart: number;
  rangeEnd: number;
};

export const getGatewayDetails = (gatewayId: string) =>
  GatewayService.getCommissioningApiGateways(gatewayId);

export const getLatestMappings = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysMappingAll(gatewayId);

export const getGatewayConfigFile = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysConfigurationFile(gatewayId);

export const getDeployments = async (gatewayId: string) =>
  DeploymentService.getCommissioningApiGatewaysDeployments(gatewayId);

export const deployMapping = async (gatewayId: string) =>
  DeploymentService.postCommissioningApiGatewaysDeploy(gatewayId);

export const getLatestSuccessfulDeployment = async (gatewayId: string) =>
  DeploymentService.getCommissioningApiGatewaysDeploymentsLatestSuccessful(
    gatewayId,
  );
export const getLatestDeployment = async (gatewayId: string) =>
  DeploymentService.getCommissioningApiGatewaysDeploymentsLatest(gatewayId);

export const getDeployment = async (gatewayId: string, deploymentId: string) =>
  DeploymentService.getCommissioningApiGatewaysDeployments1(
    gatewayId,
    deploymentId,
  );

export const getDeploymentMappings = async ({
  gatewayId,
  deploymentId,
}: {
  gatewayId: string;
  deploymentId: string;
}) =>
  DeploymentService.getCommissioningApiGatewaysDeploymentsMapping(
    gatewayId,
    deploymentId,
  );

export const deleteMappings = async (gatewayId: string) =>
  GatewayService.deleteCommissioningApiGatewaysMappingAll(gatewayId);

export const saveMapping = async ({
  gatewayId,
  mapping,
}: {
  gatewayId: string;
  mapping: {
    bacnetDeviceId: string;
    bacnetObjectId: string;
    mappedDeviceId: string;
    mappedCapabilityId: string;
  };
}) => GatewayService.putCommissioningApiGatewaysMapping(gatewayId, mapping);

export const deleteMapping = async ({
  gatewayId,
  mapping,
}: {
  gatewayId: string;
  mapping: {
    bacnetDeviceId: string;
    bacnetObjectId: string;
  };
}) => GatewayService.deleteCommissioningApiGatewaysMapping(gatewayId, mapping);

export const getMappingReviewData = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysMappingReview(gatewayId);

export const downloadMapping = async (gatewayId: string) =>
  DeploymentService.postCommissioningApiGatewaysDeployDownload(gatewayId);

export const getMappingDraftCSV = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysMappingDraftCsv(gatewayId);

export const getDeviceScan = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysDeviceScan(gatewayId);

export const startDeviceScan = async ({
  gatewayId,
  settings,
}: {
  gatewayId: string;
  settings: DeviceDiscoverySettings;
}) =>
  GatewayService.putCommissioningApiGatewaysDeviceScan(gatewayId, {
    action: 'trigger',
    settings,
  });
export const uploadDeviceScan = async ({
  gatewayId,
  xml,
}: {
  gatewayId: string;
  xml: string;
}) =>
  GatewayService.putCommissioningApiGatewaysDeviceScan(gatewayId, {
    action: 'upload',
    xml,
  });

export const getDevices = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysDevices(gatewayId);

export const getLatestObjectScan = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysObjectScan(gatewayId);

export const startObjectScan = async ({
  gatewayId,
  deviceIds,
}: {
  gatewayId: string;
  deviceIds: string[];
}) =>
  GatewayService.putCommissioningApiGatewaysObjectScan(gatewayId, {
    action: 'trigger',
    deviceIds,
  });
export const uploadObjectScan = async ({
  gatewayId,
  deviceIds,
  xml,
}: {
  gatewayId: string;
  deviceIds: string[];
  xml: string;
}) =>
  GatewayService.putCommissioningApiGatewaysObjectScan(gatewayId, {
    action: 'trigger',
    deviceIds,
    xml,
  });
export const deleteObjectScan = async ({
  gatewayId,
  deviceId,
}: {
  gatewayId: string;
  deviceId: string;
}) =>
  GatewayService.deleteCommissioningApiGatewaysObjectScan(gatewayId, deviceId);

export const getMatchingModels = async (unitUris: string[]) =>
  LookupService.postCommissioningApiDeviceModelLookup({ unitUris });

export const getMappingDraft = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysMappingDraft(gatewayId);

export const saveMappingDraft = async ({
  gatewayId,
  mappingDraft,
}: {
  gatewayId: string;
  mappingDraft: CommissioningSvcMappingDraft;
}) =>
  GatewayService.putCommissioningApiGatewaysMappingDraft(
    gatewayId,
    mappingDraft,
  );

export const postMappingDraftCSV = async ({
  gatewayId,
  csv,
}: {
  gatewayId: string;
  csv: string;
}) =>
  GatewayService.postCommissioningApiGatewaysMappingDraftCsv(gatewayId, {
    csv,
    delimiter: 'auto',
  });

export const getReviewData = async (gatewayId: string) =>
  GatewayService.getCommissioningApiGatewaysMappingDraftReview(gatewayId);

export const deployMappingDraft = async (gatewayId: string) =>
  GatewayService.postCommissioningApiGatewaysMappingDraftDeploy(gatewayId);

export const deleteMappingDraft = async (gatewayId: string) =>
  GatewayService.deleteCommissioningApiGatewaysMappingDraft(gatewayId);

export const downloadMappingDraftMappings = async (gatewayId: string) =>
  GatewayService.postCommissioningApiGatewaysMappingDraftDeployDownload(
    gatewayId,
  );
export const validateMappingDraft = async ({
  gatewayId,
  mappingDraft,
}: {
  gatewayId: string;
  mappingDraft: CommissioningSvcMappingDraft;
}) =>
  GatewayService.postCommissioningApiGatewaysMappingDraftValidate(
    gatewayId,
    mappingDraft,
  );
export const decommissionGateway = async (gatewayId: string) =>
  GatewayService.postCommissioningApiGatewaysDecommission(gatewayId);

export const refreshGatewayFirmwareInfo = async (gatewayId: string) =>
  GatewayService.postCommissioningApiGatewaysFetchGatewayDeviceProperties(
    gatewayId,
  );
