import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../constants/UI';

interface FiltersHook<T> {
  page: number;
  pageSize: number;
  query: string;
  sort: T;
  sortDirection?: 'asc' | 'desc';
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setQuery: (query: string) => void;
  setSort: (sort: T, direction?: 'asc' | 'desc') => void;
}

const paramToSortDirection = (param?: string | null) => {
  switch (param) {
    case 'asc':
    case 'desc':
      return param;
    default:
      return undefined;
  }
};

export const useFilters = <T extends string>(prefix = ''): FiltersHook<T> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const makeParam = useCallback(
    (name: string) =>
      prefix
        ? `${prefix}${name.replace(/^./, (str) => str.toUpperCase())}`
        : name,
    [prefix],
  );
  const setParam = useCallback(
    (name: string, value: string | null) => {
      if (value) {
        searchParams.set(makeParam(name), value);
      } else {
        searchParams.delete(makeParam(name));
      }
    },
    [searchParams, makeParam],
  );

  const [page, pageSize, query, sort, sortDirection] = useMemo(
    () => [
      Number(searchParams.get(makeParam('page')) || '1') - 1 || 0,
      Number(searchParams.get(makeParam('pageSize'))) || DEFAULT_PAGE_SIZE,
      searchParams.get(makeParam('query')) ?? '',
      (searchParams.get(makeParam('sort')) ?? '') as T,
      paramToSortDirection(searchParams.get(makeParam('sortDirection'))),
    ],
    [searchParams, makeParam],
  );

  const setQuery = useCallback(
    (value: string) => {
      setParam('query', value);
      if (page && page !== 1) {
        setParam('page', null);
      }
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams, page, setParam],
  );

  const setPageSize = useCallback(
    (value: number) => {
      setParam(
        'pageSize',
        value === DEFAULT_PAGE_SIZE ? null : value.toString(),
      );
      if (value > pageSize) {
        setParam('page', null);
      }
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams, pageSize, setParam],
  );

  const setPage = useCallback(
    (value: number) => {
      setParam('page', value ? String(value + 1) : null);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams, setParam],
  );

  const setSort = useCallback(
    (value: T, direction?: 'asc' | 'desc') => {
      setParam('sort', value);
      setParam('sortDirection', direction ?? null);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams, setParam],
  );

  return {
    page,
    pageSize,
    query,
    sort,
    sortDirection,
    setPage,
    setPageSize,
    setQuery,
    setSort,
  };
};
