import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { DeviceAttributesAttributeCard } from './DeviceAttributesAttributeCard';
import {
  DataTypes,
  useSetDeviceAttributeValueMutation,
  useUnsetDeviceAttributeValueMutation,
} from '../../../__generated__/types';

interface DeviceAttributesAttributeContainerProps {
  id: string;
  name: string;
  value: string;
  required: boolean;
  exists: boolean;
  dataType: DataTypes | null;
  deviceId: string;
  onChange: () => void;
}

export const DeviceAttributesAttributeContainer: React.FC<
  DeviceAttributesAttributeContainerProps
> = ({ id, value, name, required, deviceId, dataType, onChange, exists }) => {
  const [editMode, setEditMode] = useState(false);
  const [tempValue, setTempValue] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);

  const [setAttributeValue, { loading: loadingSet, error: errorSet }] =
    useSetDeviceAttributeValueMutation({
      onError: (e) => {
        console.warn(e);
      },
    });

  const [unsetAttributeValue, { loading: loadingUnset, error: errorUnset }] =
    useUnsetDeviceAttributeValueMutation({
      onError: (e) => {
        console.warn(e);
      },
    });

  useEffect(() => {
    setHasError(!!(errorSet || errorUnset));
  }, [errorSet, errorUnset]);

  const handleDiscard = () => {
    setTempValue(null);
    setEditMode(false);
  };

  const handleEdit = () => {
    if (dataType === DataTypes.Bool && !value) {
      setTempValue('false');
    } else {
      setTempValue(value);
    }
    setEditMode(true);
  };

  const handleSubmit = () => {
    setAttributeValue({
      variables: {
        deviceId,
        attributeId: id,
        value: tempValue ?? '',
      },
    })
      .then(() => {
        setEditMode(false);
        return onChange();
      })
      .then(() => {
        setTempValue(null);
        return true;
      })
      .catch(() => {
        setTempValue(null);
        setEditMode(false);
      });
  };

  const handleDelete = () => {
    unsetAttributeValue({
      variables: {
        deviceId,
        attributeId: id,
      },
    })
      .then(() => {
        setEditMode(false);
        return onChange();
      })
      .then(() => {
        setTempValue(null);
        return true;
      })
      .catch(() => {
        setTempValue(null);
        setEditMode(false);
      });
  };

  const handleChange = (newValue: string) => setTempValue(newValue);
  return (
    <>
      <Snackbar
        open={hasError}
        autoHideDuration={7000}
        onClose={() => setHasError(false)}
      >
        <Alert severity="error">
          {errorSet?.message || errorUnset?.message}
        </Alert>
      </Snackbar>
      <DeviceAttributesAttributeCard
        id={id}
        exists={exists}
        name={name}
        value={tempValue ?? value}
        edit={editMode}
        loading={loadingSet || loadingUnset}
        required={required}
        dataType={dataType}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
        onChange={handleChange}
      />
    </>
  );
};
