import { useSubscription, gql, ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';

export type GatewayDetails = {
  status?: 'idle' | 'deploying' | 'scanning_devices' | 'scanning_objects';
  firmwareVersion?: string;
  lastUpdated?: number;
};
export type UseGatewaySubscription = GatewayDetails & {
  error: ApolloError | undefined;
};

export const useCommissioningStatusSubscription = (
  gatewayId?: string,
): UseGatewaySubscription => {
  const [details, setDetails] = useState<GatewayDetails>();

  const COMMISSIONING_STATUS_SUBSCRIPTION = gql`
    subscription OnGatewayCommissioningStatus($gatewayIds: [String!]) {
      commissioningStatusChanged(gatewayIds: $gatewayIds) {
        id
        status
        properties {
          firmwareVersion
        }
      }
    }
  `;

  const { error: errorSubscription } = useSubscription(
    COMMISSIONING_STATUS_SUBSCRIPTION,
    {
      skip: !gatewayId,
      variables: { gatewayIds: gatewayId ? [gatewayId] : [] },
      onData: (result) => {
        setDetails({
          status: result.data.data?.commissioningStatusChanged.status,
          firmwareVersion:
            result.data.data?.commissioningStatusChanged.properties
              ?.firmwareVersion,
          lastUpdated: Date.now(),
        });
      },
    },
  );

  useEffect(() => {
    if (errorSubscription) {
      console.warn('Subscription Error', errorSubscription);
    }
  }, [errorSubscription]);

  return {
    ...details,
    error: errorSubscription,
  };
};
