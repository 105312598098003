import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, ListItemText } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ActionsBar } from '../../../components/ActionsBar';
import { FormattedDate } from '../../../components/FormattedDate';

interface UserDetailsProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roleNames?: string[];
  createdAt?: string;
  loading?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
}

export const UserDetails: React.FC<UserDetailsProps> = ({
  id,
  firstName,
  lastName,
  email,
  roleNames,
  createdAt,
  onDelete,
  onEdit,
  loading,
}) => {
  const { t } = useTranslation(['users', 'general']);

  return (
    <>
      {onDelete && (
        <ActionsBar>
          {!loading && (
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              startIcon={<Icons.Delete />}
              onClick={onDelete}
            >
              {t('users:actions.deleteButton')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={6}>
              <FancyCard
                title={t('users:details.info.title')}
                loading={loading}
              >
                <InfoList>
                  <InfoListItem term={t('users:details.info.id')}>
                    {id}
                  </InfoListItem>
                  <InfoListItem term={t('users:details.info.firstName')}>
                    {firstName}
                  </InfoListItem>
                  <InfoListItem term={t('users:details.info.lastName')}>
                    {lastName}
                  </InfoListItem>
                  <InfoListItem term={t('users:details.info.email')}>
                    {email}
                  </InfoListItem>
                  <InfoListItem term={t('users:details.info.createdAt')}>
                    <FormattedDate value={createdAt} />
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>
            {roleNames && (
              <Grid item xs={6}>
                <FancyCard
                  title={t('users:details.roles.title')}
                  loading={loading}
                  onEdit={onEdit}
                >
                  <InfoList>
                    <InfoListItem term={t('users:details.roles.name')}>
                      {roleNames.map((roleName, index) => (
                        <ListItemText key={index.toString()}>
                          {roleName}
                        </ListItemText>
                      ))}
                    </InfoListItem>
                  </InfoList>
                </FancyCard>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
