import React from 'react';
import { ApolloError } from '@apollo/client';
import { DeviceModelAttributeSetDelete } from './DeviceModelAttributeSetDelete';
import { useDeviceModelAttributeSetDeleteMutation } from '../../../../__generated__/types';

interface DeviceModelDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const DeviceModelDeleteContainer: React.FC<
  DeviceModelDeleteContainerProps
> = ({ id, isOpen, onCompleted, onError, onCancel }) => {
  const [deleteDeviceModelAttributeSet, { loading, error }] =
    useDeviceModelAttributeSetDeleteMutation({
      onCompleted,
      onError,
    });

  const handleConfirm = () =>
    deleteDeviceModelAttributeSet({
      variables: { deviceModelAttributeSetId: id },
    });

  return (
    <DeviceModelAttributeSetDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
