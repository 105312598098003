import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { Grid, Button, Snackbar, Tooltip } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ActionsBar } from '../../../components/ActionsBar';
import { Status } from '../../../api/application';
import { Color } from '../../../constants/Colors';
import { CopyIconButton } from '../../../components/CopyIconButton';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  description: {
    whiteSpace: 'pre-wrap',
  },
  button: {
    marginRight: '8px',
  },
  openButton: {
    color: Color.alternativeBlack,
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  icon: {
    marginRight: '8px',
    display: 'block',
  },
  updated: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbar: {
    background: Color.alternativeBlack,
    color: Color.white,
    padding: '8px 16px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  copyIcon: {
    marginLeft: '8px',
  },
  url: {
    color: Color.alternativeBlack,
    textDecoration: 'none',
  },
});

type ApplicationDetailsProps = {
  id: string;
  name: string;
  description: string;
  url: string;
  status?: Status;
  activatedAt?: string;
  onActivate?: () => void;
  onDeactivate?: () => void;
  onReapprove?: () => void;
  hasPermissionReadOnly: boolean;
  loading?: boolean;
};

export const ApplicationDetails: React.FC<ApplicationDetailsProps> = ({
  id,
  name,
  description,
  url,
  status,
  activatedAt,
  onActivate,
  onDeactivate,
  onReapprove,
  hasPermissionReadOnly,
  loading,
}) => {
  const { t } = useTranslation(['applications', 'general']);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Snackbar
        data-testid="application-url-copied-snackbar"
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={() => setSnackbarOpen(false)}
      >
        <div className={classes.snackbar}>
          {t('applications:details.info.urlCopied')}
        </div>
      </Snackbar>
      {(onDeactivate ||
        onActivate ||
        onReapprove ||
        (status !== Status.deactivated && hasPermissionReadOnly)) && (
        <ActionsBar>
          <>
            {url && status !== Status.deactivated && (
              <Button
                color="secondary"
                size="large"
                disabled={loading}
                aria-label="open-button"
                className={classes.openButton}
                component="a"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons.OpenInNew className={classes.icon} />
                {t('applications:details.actions.open')}
              </Button>
            )}
            {onReapprove && status === Status.updated && (
              <Button
                color="secondary"
                size="large"
                onClick={onReapprove}
                variant="outlined"
                disabled={loading}
                aria-label="reapprove-button"
                className={classes.button}
              >
                {t('applications:details.actions.reapprove')}
              </Button>
            )}
            {onDeactivate && status !== Status.deactivated && (
              <Button
                color="primary"
                size="large"
                aria-label="deactivate-button"
                onClick={onDeactivate}
                disabled={loading}
              >
                {t('applications:details.actions.deactivate')}
              </Button>
            )}
            {onActivate && status === Status.deactivated && (
              <Button
                color="secondary"
                size="large"
                variant="outlined"
                aria-label="activate-button"
                onClick={onActivate}
                disabled={loading}
              >
                {t('applications:details.actions.activate')}
              </Button>
            )}
          </>
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12} sm={12} md={12}>
              <FancyCard
                title={t('applications:details.info.title')}
                loading={loading}
                fullHeight
              >
                <InfoList>
                  <InfoListItem term={t('applications:details.info.id')}>
                    {id}
                  </InfoListItem>
                  <InfoListItem term={t('general:fields.name')}>
                    {name}
                  </InfoListItem>
                  <InfoListItem
                    term={t('applications:details.info.activationStatus')}
                  >
                    {status === Status.activated &&
                      t('applications:details.info.status.activated')}
                    {status === Status.deactivated &&
                      t('applications:details.info.status.deactivated')}
                    {status === Status.updated && (
                      <div className={classes.updated}>
                        {t('applications:details.info.status.updated')}
                        <Tooltip
                          arrow
                          placement="right"
                          title={
                            <div className={classes.tooltipContent}>
                              {hasPermissionReadOnly
                                ? t(
                                    'applications:details.info.tooltip.updatedUser',
                                  )
                                : t(
                                    'applications:details.info.tooltip.updatedAdmin',
                                  )}
                            </div>
                          }
                        >
                          <Icons.FiberManualRecord
                            fontSize="medium"
                            color="primary"
                            className={classes.icon}
                            data-testid="updated-icon"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </InfoListItem>
                  {status === Status.activated && (
                    <InfoListItem
                      term={t('applications:details.info.activatedAt')}
                    >
                      <FormattedDate value={activatedAt} />
                    </InfoListItem>
                  )}
                  <InfoListItem
                    className={classes.description}
                    term={t('general:fields.description')}
                  >
                    {description}
                  </InfoListItem>
                  {url && (
                    <InfoListItem term={t('applications:details.info.url')}>
                      {url}
                      <CopyIconButton
                        value={url}
                        onCopy={() => setSnackbarOpen(true)}
                        aria-label="copy-to-clipboard-application-url"
                        color="secondary"
                        className={classes.copyIcon}
                      />
                    </InfoListItem>
                  )}
                </InfoList>
              </FancyCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
