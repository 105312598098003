import React from 'react';
import { FallbackProps } from '@kp/react-sdk/app-insights';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../Alerts';

export const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();

  return (
    <ErrorAlert
      title={t('general:errors.unknownError.title')}
      message={t('general:errors.unknownError.message')}
      errors={[error]}
      actions={[
        {
          label: t('general:errors.unknownError.toDashboard'),
          onAction: () => window.location.replace('/'),
        },
        {
          label: t('general:errors.unknownError.retry'),
          onAction: resetErrorBoundary,
        },
      ]}
      showOk={false}
    />
  );
};
