import React, { useEffect } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useHeader } from '../../../contexts/header-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { IntegrationsList } from './IntegrationsList';
import { getQanteonProvision, Status } from '../../../api/qanteon';
import { isProvisionFound } from '../constants';

export const IntegrationsListContainer: React.FC = () => {
  const { t } = useTranslation(['integrations', 'general']);
  const { setTitle } = useHeader();
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.INTEGRATION, t),
    location: Entities.INTEGRATION.path,
  });

  const {
    data: response,
    error,
    isLoading: loading,
  } = useQuery({
    queryKey: ['getQanteonProvision'],
    queryFn: getQanteonProvision,
    onError: (err: ApiError) => err,
  });

  useEffect(() => {
    setTitle({ main: t('integrations:list.title') });
  }, [setTitle, t]);

  const noProvision = isProvisionFound(error);

  if (error && !noProvision) {
    return <ErrorMessage error={error} />;
  }

  const handleAdd = () =>
    navigate(entityLocation(Entities.INTEGRATION, `qanteon/new`));

  const integrations = () => {
    if (!noProvision || response?.data) {
      return [
        {
          integration: 'Qanteon',
          name: response?.data?.name || '-',
          status: (response?.data?.status as Status) || '-',
          url: response?.data?.url || '-',
          path: 'qanteon',
        },
      ];
    }
    return [];
  };

  return (
    <IntegrationsList
      loading={loading}
      integrations={integrations()}
      onAdd={
        hasPermission(Permissions.QanteonProvisioningWrite)
          ? handleAdd
          : undefined
      }
      noProvision={noProvision}
    />
  );
};
