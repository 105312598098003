import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import { DevicesListContainer } from './list/DevicesListContainer';
import { DeviceDetailsContainer } from './details/DeviceDetailsContainer';
import { DeviceAttributesContainer } from './attributes/DeviceAttributesContainer';
import { DeviceLocationContainer } from './location/DeviceLocationContainer';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';
import { GatewayCreateContainer } from './create/gateway/GatewayCreateContainer';
import { FieldDeviceCreateContainer } from './create/fieldDevice/FieldDeviceCreateContainer';
import { GatewayEditContainer } from './edit/gateway/GatewayEditContainer';
import { FieldDeviceEditContainer } from './edit/fieldDevice/FieldDeviceEditContainer';
import { DiscoveryContainer } from './discovery/DiscoveryContainer';
import { DeploymentsContainer } from './deployments/DeploymentsContainer';
import { DeviceMappingsDraftCreateContainer } from './mappings/draftCreate/DeviceMappingsDraftCreateContainer';
import { DeviceMappingsDraftReviewContainer } from './mappings/draftReview/DeviceMappingsDraftReviewContainer';
import { DeviceMappingsEditContainer } from './mappings/edit/DeviceMappingsEditContainer';
import { DeviceMappingsReviewContainer } from './mappings/review/DeviceMappingsReviewContainer';
import { NotFound } from '../errorScreens/NotFound';

const Icon = Pages.DEVICES.icon;

export const DevicesRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<DevicesListContainer />} />
      <Route
        path="/new-gateway"
        element={
          <ProtectedRoute
            element={<GatewayCreateContainer />}
            permission={Permissions.DevicesWrite}
          />
        }
      />
      <Route
        path="/new-fielddevice"
        element={
          <ProtectedRoute
            element={<FieldDeviceCreateContainer />}
            permission={Permissions.DevicesWrite}
          />
        }
      />
      <Route path="/:deviceId" element={<DeviceDetailsContainer />} />
      <Route
        path="/:deviceId/editgateway"
        element={
          <ProtectedRoute
            element={<GatewayEditContainer />}
            permission={Permissions.DevicesWrite}
          />
        }
      />
      <Route
        path="/:deviceId/editfielddevice"
        element={
          <ProtectedRoute
            element={<FieldDeviceEditContainer />}
            permission={Permissions.DevicesWrite}
          />
        }
      />
      <Route
        path="/:deviceId/attributes"
        element={
          <ProtectedRoute
            element={<DeviceAttributesContainer />}
            permission={Permissions.DevicesWrite}
          />
        }
      />
      <Route
        path="/:deviceId/location"
        element={
          <ProtectedRoute
            element={<DeviceLocationContainer />}
            permission={Permissions.DevicesWrite}
          />
        }
      />
      <Route
        path="/:gatewayId/discovery"
        element={<ProtectedRoute element={<DiscoveryContainer />} />}
      />
      <Route
        path="/:gatewayId/deployments"
        element={<ProtectedRoute element={<DeploymentsContainer />} />}
      />
      <Route
        path="/:gatewayId/mappings/draftCreate"
        element={
          <ProtectedRoute element={<DeviceMappingsDraftCreateContainer />} />
        }
      />
      <Route
        path="/:gatewayId/mappings/draftReview"
        element={
          <ProtectedRoute element={<DeviceMappingsDraftReviewContainer />} />
        }
      />
      <Route
        path="/:gatewayId/mappings/edit"
        element={<ProtectedRoute element={<DeviceMappingsEditContainer />} />}
      />
      <Route
        path="/:gatewayId/mappings/review"
        element={<ProtectedRoute element={<DeviceMappingsReviewContainer />} />}
      />
      <Route path="*" element={<NotFound type="page" />} />
    </Routes>
  );
};
