import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Grid, Divider, Typography } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { DataTable } from '../../../components/DataTable';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { Link } from '../../../components/Link';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  divider: {
    marginTop: '24px',
    marginBottom: '14px',
  },
  warning: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: '24px',
  },
  icon: {
    marginRight: '16px',
  },
});

type Device = {
  id: string;
  name: string;
  deviceModel: { name: string };
};
export type BuildingDetailsDevicesProps = {
  devices: Array<Device>;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
};

export const BuildingDetailsDevices: React.FC<BuildingDetailsDevicesProps> = ({
  devices,
  onChangePageSize,
  onChangePage,
  loading,
  page,
  pageSize,
  count,
}) => {
  const { t } = useTranslation(['buildings', 'general']);

  const headers = [
    {
      key: t('buildings:details.devicesList.headers.name'),
      value: t('buildings:details.devicesList.headers.name'),
    },
    {
      key: t('buildings:details.devicesList.headers.deviceModel'),
      value: t('buildings:details.devicesList.headers.deviceModel'),
    },
  ];

  const rows = (devices ?? []).map((device) => ({
    key: `${device.id}`,
    cells: [
      {
        key: `${device.id}-cell-name`,
        element: (
          <Link to={entityLocation(Entities.DEVICE, device.id)}>
            {device.name}
          </Link>
        ),
      },
      {
        key: `${device.id}-cell-deviceModel`,
        element: device.deviceModel.name,
        isLast: false,
      },
    ],
  }));

  if (!devices?.length && !loading) return null;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Divider className={classes.divider} />
        <DataTable
          title={t('buildings:details.devices.title')}
          info={
            <div className={classes.warning}>
              <Icons.Warning
                className={classes.icon}
                fontSize="medium"
                htmlColor={Color.warning}
                data-testid="warning-icon"
              />
              <Typography variant="body1">
                {t('buildings:details.devices.warning')}
              </Typography>
            </div>
          }
          headers={headers}
          loading={loading}
          rows={rows}
          showPagination
          page={page}
          pageSize={pageSize}
          count={count}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
      </Grid>
    </Grid>
  );
};
