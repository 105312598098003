import React, { useState } from 'react';
import { Alert, Button, Grid, Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { CommissioningSvcDeploymentWithScans as Deployments } from '@kp/rest-api-javascript-sdk';
import { LoadingButton } from '@mui/lab';
import { FancyCard } from '../../../components/FancyCard';
import { DeployAlert } from '../common/DeployAlert';
import { MappingStats, MappingStatsTable } from '../common/MappingStatsTable';
import { Color } from '../../../constants/Colors';
import { DeploymentStatsTable } from './DeploymentStatsTable';
import {
  translateCommissioningStatus,
  CommissioningStatus,
} from '../common/statusTranslation';
import { ConnectionStates } from '../../../__generated__/types';
import { GatewayStatus } from '../../../hooks/useGatewayStatusSubscription';

const classes = createClasses({
  cardContent: {
    marginBottom: 16,
  },
  deployWarning: {
    marginBottom: 16,
  },
  deployButtonContainer: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 16,
    minWidth: 300,
  },
  deployButtonTooltipContainer: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 300,
  },
  deployButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  deployIcon: {
    marginLeft: 8,
  },
  deployError: {
    marginTop: 8,
    padding: 2,
    color: Color.error,
  },
});
type DeviceDetailsDeployCardProps = {
  loading?: boolean;
  hasMappingDraft: boolean;
  mappingStats: MappingStats;
  commissioningStatus?: CommissioningStatus;
  gatewayStatus: GatewayStatus;
  previousDeployments: Deployments[];
  deploymentError?: string | null;
  onViewList: () => void;
  onDownload?: () => void;
  onDeploy?: () => void;
};

export const DeviceDetailsDeployCard: React.FC<
  DeviceDetailsDeployCardProps
> = ({
  loading,
  hasMappingDraft,
  mappingStats,
  commissioningStatus,
  gatewayStatus,
  previousDeployments,
  deploymentError,
  onViewList,
  onDownload,
  onDeploy,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const confirmDeploy = () => setShouldConfirm(true);
  const handleCancel = () => setShouldConfirm(false);
  const handleDeploy = () => {
    setShouldConfirm(false);
    if (onDeploy) onDeploy();
  };
  const handleDownload = () => {
    setShouldConfirm(false);
    if (onDownload) onDownload();
  };

  const removesMapping = mappingStats?.removed && mappingStats.removed > 0;
  const changesMappings =
    !!mappingStats?.new || !!mappingStats?.changed || hasMappingDraft;
  const canDeployMappings =
    onDeploy &&
    commissioningStatus === 'idle' &&
    gatewayStatus?.connectionState === ConnectionStates.Connected &&
    (changesMappings || removesMapping);
  const canDownloadMappings = onDownload && (changesMappings || removesMapping);

  const successfulDeployments = previousDeployments.filter(
    (d) => d.status === 'deployed',
  );
  const failedDeployments = previousDeployments.filter(
    (d) => d.status === 'failed',
  );
  const previousDeploymentStats = {
    successful: successfulDeployments.length,
    lastSuccessful: successfulDeployments[0]?.deployedAt,
    failed: failedDeployments.length,
    lastFailed: failedDeployments[0]?.createdAt,
  };

  return (
    <>
      <FancyCard
        title={t('devices:details.deployCard.title')}
        loading={loading}
        actions={
          <Button
            color="primary"
            onClick={onViewList}
            disabled={
              !previousDeploymentStats?.successful &&
              !previousDeploymentStats?.failed
            }
            data-testid={'view-deployments-button'}
          >
            {t('devices:details.deployCard.viewDeployments')}
          </Button>
        }
      >
        <Grid container spacing={2} className={classes.cardContent}>
          <Grid item xs={12} lg={4}>
            <MappingStatsTable stats={mappingStats} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DeploymentStatsTable stats={previousDeploymentStats} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <div className={classes.deployButtonContainer}>
              {removesMapping ? (
                <Alert severity="warning" className={classes.deployWarning}>
                  {t('devices:details.deployCard.dataDeletionWarning', {
                    count: mappingStats.removed,
                  })}
                </Alert>
              ) : null}
              <Tooltip
                arrow
                placement="bottom"
                title={
                  t('devices:details.deployCard.commissioningStatus') +
                  translateCommissioningStatus(
                    gatewayStatus.connectionState !== ConnectionStates.Connected
                      ? ConnectionStates.Disconnected
                      : commissioningStatus,
                    t,
                  )
                }
              >
                <div className={classes.deployButtonTooltipContainer}>
                  <LoadingButton
                    loading={loading || commissioningStatus === 'deploying'}
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={confirmDeploy}
                    disabled={!canDeployMappings && !canDownloadMappings}
                    data-testid={'deploy-button'}
                  >
                    <div className={classes.deployButtonContent}>
                      {t('devices:details.deployCard.deployButton')}
                      <Icons.PlayCircleFilled className={classes.deployIcon} />
                    </div>
                  </LoadingButton>
                </div>
              </Tooltip>
              {deploymentError && (
                <div className={classes.deployError}>
                  {t('devices:details.deployCard.deployError') +
                    deploymentError}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </FancyCard>
      <DeployAlert
        open={shouldConfirm}
        onCancel={handleCancel}
        onDownload={canDownloadMappings ? handleDownload : undefined}
        onDeploy={canDeployMappings ? handleDeploy : undefined}
      />
    </>
  );
};
