import React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { GridRenderCellParams, DataGrid } from '../../../components/DataGrid';
import { FancyCard } from '../../../components/FancyCard';
import {
  ConnectionStates,
  StatusIndicator,
} from '../../../components/StatusIndicator';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { OFFLINE_THRESHOLD_IN_MINUTES } from '../../../constants/Misc';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  banner: {
    margin: 'auto',
  },
  bannerContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
});

type NoRowsOverlayProps = { children: React.ReactNode };
const NoRowsOverlay: React.FC<NoRowsOverlayProps> = ({ children }) => {
  return <div className={classes.bannerContainer}>{children}</div>;
};

type StatusOverviewDeviceDataProps = {
  loading?: boolean;
  data?: Array<{
    id: string;
    capability: string;
    status: ConnectionStates;
    ts: string;
    value: string;
  }>;
};
export const StatusOverviewDeviceData: React.FC<
  StatusOverviewDeviceDataProps
> = ({ loading, data }) => {
  const { t } = useTranslation(['status', 'general']);

  const columns = [
    {
      field: 'status',
      headerName: t('status:overview.data.headers.status'),
      description: t('status:overview.data.headers.statusDescription', {
        threshold: OFFLINE_THRESHOLD_IN_MINUTES,
      }),
      width: 60,
      renderCell: (params: GridRenderCellParams) => (
        <StatusIndicator status={params.row.status} />
      ),
      cellClassName: 'MuiDataGrid-cell--textCenter',
    },
    {
      field: 'capability',
      headerName: t('status:overview.data.headers.capability'),
      description: t('status:overview.data.headers.capabilityDescription'),
      flex: 1,
    },
    {
      field: 'ts',
      headerName: t('status:overview.data.headers.ts'),
      description: t('status:overview.data.headers.tsDescription'),
      renderCell: (params: GridRenderCellParams) => (
        <FormattedDate value={params.value} />
      ),
      flex: 1,
    },
    {
      field: 'value',
      headerName: t('status:overview.data.headers.value'),
      description: t('status:overview.data.headers.valueDescription'),
      flex: 1,
    },
  ];

  return (
    <FancyCard loading={loading} title={t('status:overview.data.title')}>
      <DataGrid
        loading={loading}
        slotProps={{
          noRowsOverlay: {
            children: data ? (
              <EmptyBanner
                className={classes.banner}
                description={t('status:overview.noData')}
              />
            ) : (
              <EmptyBanner
                className={classes.banner}
                description={t('status:overview.noDevice')}
              />
            ),
          },
        }}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        rows={(!loading && data) || []}
        columns={columns}
        hideFooter
        hideFooterSelectedRowCount
      />
    </FancyCard>
  );
};
