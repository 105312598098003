import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTypes } from '../../__generated__/types';

interface DataTypeLabelProps {
  dataType: DataTypes | null;
}

export const DataTypeLabel: React.FC<DataTypeLabelProps> = ({ dataType }) => {
  const { t } = useTranslation(['general']);

  // We actually need to specify those with a map, rather than do something
  // smarter, like `t(`dataType.label.${dataType.name}`)`, because the locale extractor
  // should be able to catch those separately
  const dataTypeLabel = useMemo<{ [key in DataTypes]: string }>(
    () => ({
      [DataTypes.NotSpecified]: t('general:dataType.label.not-specified'),
      [DataTypes.Integer]: t('general:dataType.label.integer'),
      [DataTypes.Decimal]: t('general:dataType.label.decimal'),
      [DataTypes.Float]: t('general:dataType.label.float'),
      [DataTypes.Double]: t('general:dataType.label.double'),
      [DataTypes.Bool]: t('general:dataType.label.boolean'),
      [DataTypes.String]: t('general:dataType.label.string'),
      [DataTypes.Date]: t('general:dataType.label.date'),
      [DataTypes.Datetime]: t('general:dataType.label.date-time'),
    }),
    [t],
  );

  return <span>{dataTypeLabel[dataType ?? DataTypes.NotSpecified]}</span>;
};
