import { TFunction } from 'i18next';
import { ConnectionStates } from '../../../__generated__/types';

export type ScanStatus =
  | 'created'
  | 'started'
  | 'completed'
  | 'failed'
  | 'timedout';

export type CommissioningStatus =
  | 'idle'
  | 'deploying'
  | 'scanning_devices'
  | 'scanning_objects';

export type DeploymentStatus = 'created' | 'started' | 'deployed' | 'failed';

export const translateScanStatus = (
  status: ScanStatus | undefined,
  t: TFunction,
) => {
  switch (status) {
    case 'created':
      return t('devices:common.scanStatus.created');
    case 'started':
      return t('devices:common.scanStatus.started');
    case 'completed':
      return t('devices:common.scanStatus.completed');
    case 'failed':
      return t('devices:common.scanStatus.failed');
    case 'timedout':
      return t('devices:common.scanStatus.timedout');
    default:
      return t('devices:common.scanStatus.notStarted');
  }
};
export const translateCommissioningStatus = (
  status: CommissioningStatus | ConnectionStates | undefined,
  t: TFunction,
) => {
  switch (status) {
    case 'idle':
      return t('devices:common.gatewayStatus.idle');
    case 'scanning_devices':
      return t('devices:common.gatewayStatus.scanning_devices');
    case 'scanning_objects':
      return t('devices:common.gatewayStatus.scanning_objects');
    case 'DISCONNECTED':
      return t('devices:common.gatewayStatus.offline');
    default:
      return t('devices:common.gatewayStatus.unknown');
  }
};
export const translateDeploymentStatus = (
  status: DeploymentStatus | undefined,
  t: TFunction,
) => {
  switch (status) {
    case 'created':
      return t('devices:common.deploymentStatus.created');
    case 'started':
      return t('devices:common.deploymentStatus.deploying');
    case 'deployed':
      return t('devices:common.deploymentStatus.deployed');
    case 'failed':
      return t('devices:common.deploymentStatus.failed');
    default:
      return t('devices:common.deploymentStatus.unknown');
  }
};
