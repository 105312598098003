import React from 'react';
import { ApolloError } from '@apollo/client';
import { StoreyDelete } from './StoreyDelete';
import { useStoreyDeleteMutation } from '../../../__generated__/types';

interface StoreyDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const StoreyDeleteContainer: React.FC<StoreyDeleteContainerProps> = ({
  id,
  isOpen,
  onCompleted,
  onError,
  onCancel,
}) => {
  const [deleteStorey, { loading, error }] = useStoreyDeleteMutation({
    onCompleted,
    onError,
  });

  const handleConfirm = () => deleteStorey({ variables: { id } });

  return (
    <StoreyDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
