import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useFilters } from '../../../hooks/filters';
import { BuildingDetailsStoreys } from './BuildingDetailsStoreys';
import { useBuildingDetailsStoreysQuery } from '../../../__generated__/types';
import { ErrorMessage } from '../../../components/Errors';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';

export const BuildingDetailsStoreysContainer: React.FC = () => {
  const { page, pageSize, setPage, setPageSize } = useFilters('storey');
  const navigate = useNavigate();
  const { buildingId } = useParams();
  const { hasPermission } = useAuth();

  const { loading, error, data } = useBuildingDetailsStoreysQuery({
    variables: {
      take: pageSize,
      skip: page * pageSize,
      where: {
        buildingId: { eq: buildingId },
      },
    },
  });

  const handleAdd = () =>
    navigate(entityLocation(Entities.STOREY, `new?buildingId=${buildingId}`));

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const storeys = data?.storeys?.items ?? [];
  const count = data?.storeys?.totalCount ?? 0;

  return (
    <BuildingDetailsStoreys
      onAdd={hasPermission(Permissions.StoreysWrite) ? handleAdd : undefined}
      loading={loading}
      storeys={storeys}
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
    />
  );
};
