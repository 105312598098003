import React from 'react';
import { createClasses } from '@kp/react-ui';
import { ListItem, ListItemText, Typography } from '@mui/material';
import classNames from 'classnames';

const classes = createClasses({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

interface InfoListItemProps {
  term: React.ReactNode;
  className?: string;
  itemClassName?: string;
  children: React.ReactNode;
}

export const InfoListItem: React.FC<InfoListItemProps> = ({
  term,
  children,
  className,
  itemClassName,
}) => {
  return (
    <ListItem className={classNames(classes.root, itemClassName)}>
      <ListItemText
        primary={
          <Typography variant="caption" color="textSecondary">
            {term}
          </Typography>
        }
        secondary={
          <Typography variant="subtitle1" className={className}>
            {children}
          </Typography>
        }
      />
    </ListItem>
  );
};
