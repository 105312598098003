import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createClasses } from '@kp/react-ui';
import {
  GridFooterContainer,
  GridToolbarFilterButton,
  GridFooter,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel,
  useGridApiRef,
  GridSortDirection,
  GridRowSelectionModel,
  GridCellCheckboxRenderer,
  selectedIdsLookupSelector,
  GridActionsCellItem,
  GridRowParams,
  GridColDef,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import {
  debounce,
  IconButton,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../../components/FancyCard';
import { Color } from '../../../../constants/Colors';
import { DataGrid } from '../../../../components/DataGrid';
import { EmptyBanner } from '../../../../components/EmptyBanner';
import { DeviceDropdown, ScanDataDevice } from '../common/DeviceDropdown';
import { useLocaleDateFns } from '../../../../hooks/date-fns';
import { BACnetTarget, MappedDatapoint } from './MappingStateUtils';
import { Loading } from '../../../../components/Loading';
import { ImportMappingsCSVDialog } from './ImportMappingsCSVDialog';
import { UploadFile } from '../../../../utils/file-tools';
import { DeviceListDialog } from './DeviceListDialog';
import { ConfirmAlert } from '../../../../components/Alerts';
import { CapabilitySelectionDropdown } from './CapabilitySelectionDropdown';
import { translateBacnetId } from '../../../../utils/bacnet-device-types';

const classes = createClasses({
  card: {
    height: 440,
  },
  subheader: {
    fontSize: '60%',
  },
  banner: {
    margin: 'auto',
  },
  bannerContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  loadingIcon: {
    margin: 0,
    color: Color.lightGrey,
  },
  cellIcon: {
    marginLeft: 10,
  },
});

const isSelectedSortModel = {
  field: '__check__',
  sort: 'desc' as GridSortDirection,
};

const isMappableFilterModel = {
  items: [
    {
      field: 'isMappable',
      id: 2,
      operator: 'is',
      value: 'true',
    },
  ],
};

type ToolbarProps = {
  disabled: boolean;
  devices: ScanDataDevice[];
  selectedDeviceIds: string[];
  onFilterChange: (deviceIds: string[]) => void;
};
const Toolbar: React.FC<ToolbarProps> = ({
  disabled,
  devices,
  selectedDeviceIds,
  onFilterChange,
}) => (
  <GridFooterContainer>
    <GridToolbarFilterButton />
    <DeviceDropdown
      disabled={disabled}
      devices={devices}
      selectedDeviceIds={selectedDeviceIds}
      onChange={onFilterChange}
    />
    <GridFooter />
  </GridFooterContainer>
);
const NoRowsOverlay = () => {
  const { t } = useTranslation(['devices', 'general']);
  return (
    <div className={classes.bannerContainer}>
      <EmptyBanner
        className={classes.banner}
        description={t('devices:mappings.create.scanResult.noData')}
      />
    </div>
  );
};
const CardTitle = ({
  scanFinishDate,
}: {
  scanFinishDate: Date | undefined;
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const { formatDefault } = useLocaleDateFns();

  const titlePostfix = scanFinishDate
    ? ` ${t('devices:mappings.create.scanResult.from')} ${formatDefault(
        scanFinishDate,
      )}`
    : '';
  return (
    <div>
      {t('devices:mappings.create.scanResult.title')}
      <span className={classes.subheader}>{titlePostfix}</span>
    </div>
  );
};

type ScanDataWithMappedDatapoints = MappedDatapoint & {
  canSelect: boolean;
  onSelectCapability: (
    target: BACnetTarget,
  ) => (event: SelectChangeEvent<string>) => void;
  onDeleteMapping: (
    target: BACnetTarget,
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export type DeviceMappingsScanResultCardProps = {
  initialLoading?: boolean;
  loading?: boolean;
  downloading?: boolean;
  uploading?: boolean;
  selectionMode: boolean;
  scanResultDevices: ScanDataDevice[];
  scanResultWithMappings: MappedDatapoint[];
  onSelectCapability?: (
    bacnetTarget: BACnetTarget,
    capabilityId: string,
  ) => void;
  onDeleteMapping?: (bacnetTarget: BACnetTarget) => void;
  scanFinishDate?: Date;
  onSelectScanDate?: (bacnetTarget: BACnetTarget[]) => void;
  onDiscovery?: () => void;
  onDownload?: () => void;
  onUpload?: (file: UploadFile) => void;
  onDeleteDevice?: (deviceNamer: string) => void;
};
export const DeviceMappingsScanResultCard: React.FC<
  DeviceMappingsScanResultCardProps
> = ({
  initialLoading,
  loading,
  downloading,
  uploading,
  selectionMode,
  scanResultWithMappings,
  scanResultDevices,
  onSelectCapability,
  onDeleteMapping,
  scanFinishDate,
  onSelectScanDate,
  onDiscovery,
  onDownload,
  onUpload,
  onDeleteDevice,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const apiRef = useGridApiRef();

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    isMappableFilterModel,
  );
  const [filterDeviceIds, setFilterDeviceIds] = useState<string[]>([]);

  const [shouldShowUploadFile, setShowUploadFile] = useState(false);
  const [shouldShowDeviceList, setShowDeviceList] = useState(false);

  const [shouldShowDeleteAlert, setShowDeleteAlert] = useState(false);
  const [mappingToDelete, setMappingToDelete] = useState<BACnetTarget>();

  useEffect(() => {
    if (selectionMode) setSelectionModel([]);
  }, [selectionMode]);

  useEffect(() => {
    const baseItems = (filterModel?.items ?? []).filter(
      (item) => item.id !== 1,
    );
    const newFilterModel = { items: baseItems };
    setFilterModel(newFilterModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatedSelectionModel = scanResultWithMappings
      .map((dp, index) => ({ isSelected: dp.isSelected, index }))
      .filter((dp) => dp.isSelected)
      .map((dp) => dp.index);
    setSelectionModel(updatedSelectionModel);
  }, [scanResultWithMappings, selectionMode]);

  useEffect(() => {
    setTimeout(
      () => apiRef.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 }),
      50,
    );
    const cleanModel = sortModel.filter((m) => m.field !== '__check__');
    if (selectionMode) {
      setSortModel(cleanModel);
    } else {
      setSortModel([isSelectedSortModel, ...cleanModel]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMode]);

  const handleSelectCapability = useCallback(
    (target: BACnetTarget) => (event: SelectChangeEvent<string>) => {
      if (onSelectCapability) onSelectCapability(target, event.target.value);
    },
    [onSelectCapability],
  );

  const handleSort = (model: GridSortModel) => {
    setSortModel(model);
  };
  const handleFilter = (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const propagateDebouncedSelect = useMemo(
    () =>
      debounce((selectedIndexes: GridRowSelectionModel) => {
        const selection = selectedIndexes.map((index) => ({
          ...scanResultWithMappings[index as number],
          unitUri: scanResultWithMappings[index as number].unitUris?.[0],
        }));
        if (onSelectScanDate) onSelectScanDate(selection);
      }, 250),
    [onSelectScanDate, scanResultWithMappings],
  );

  const handleSelect = (model: GridRowSelectionModel) => {
    setSelectionModel(model);
    propagateDebouncedSelect(model);
  };

  const handleBACnetDeviceFilter = (deviceIds: string[]) => {
    setFilterDeviceIds(deviceIds);
  };

  const handleClickDownload = () => {
    if (onDownload) onDownload();
  };

  const handleCancelUpload = () => setShowUploadFile(false);
  const handleConfirmUpload = (file: UploadFile) => {
    setShowUploadFile(false);
    if (onUpload) onUpload(file);
  };
  const handleClickUpload = () => setShowUploadFile(true);

  const handleClickShowDeviceList = () => setShowDeviceList(true);
  const handleCloseDeviceList = () => setShowDeviceList(false);

  const handleClickDeleteMapping = useCallback(
    (target: BACnetTarget) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setMappingToDelete(target);
        setShowDeleteAlert(true);
      },
    [],
  );
  const handleCancelDelete = () => setShowDeleteAlert(false);
  const handleConfirmDelete = () => {
    if (onDeleteMapping && mappingToDelete) onDeleteMapping(mappingToDelete);
    setShowDeleteAlert(false);
  };

  const columns: GridColDef[] = [
    {
      field: '__check__',
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      maxWidth: 50,
      renderHeader: () => '@',
      renderCell: (params: GridRenderCellParams) => {
        if ((params.row.model || params.row.device) && !params.row.capability) {
          return (
            <Icons.Autorenew color="primary" className={classes.cellIcon} />
          );
        }
        if (params.row.canSelect) {
          return <GridCellCheckboxRenderer {...params} />;
        }
        if (params.row.isSelected) {
          return <Icons.Check color="primary" className={classes.cellIcon} />;
        }
        return '';
      },
      valueGetter: (params: GridRenderCellParams) => {
        const selectionLookup = selectedIdsLookupSelector(apiRef);
        return selectionLookup[params.id] !== undefined;
      },
    },
    {
      field: 'description',
      headerName:
        t('devices:mappings.create.scanResult.headers.description') ?? '',
      description:
        t(
          'devices:mappings.create.scanResult.headers.descriptionDescription',
        ) ?? '',
      flex: 1,
    },
    {
      field: 'deviceIdentifierStr',
      headerName:
        t('devices:mappings.create.scanResult.headers.deviceIdentifier') ?? '',
      description:
        t(
          'devices:mappings.create.scanResult.headers.deviceIdentifierDescription',
        ) ?? '',
      flex: 1,
    },
    {
      field: 'deviceName',
      headerName:
        t('devices:mappings.create.scanResult.headers.deviceName') ?? '',
      description:
        t('devices:mappings.create.scanResult.headers.deviceNameDescription') ??
        '',
      flex: 1,
    },
    {
      field: 'objectTypeName',
      headerName:
        t('devices:mappings.create.scanResult.headers.objectTypeName') ?? '',
      description:
        t(
          'devices:mappings.create.scanResult.headers.objectTypeNameDescription',
        ) ?? '',
      flex: 1,
    },
    {
      field: 'objectName',
      headerName:
        t('devices:mappings.create.scanResult.headers.objectName') ?? '',
      description:
        t('devices:mappings.create.scanResult.headers.objectNameDescription') ??
        '',
      flex: 1,
    },
    {
      field: 'objectIdentifierStr',
      headerName:
        t('devices:mappings.create.scanResult.headers.objectIdentifier') ?? '',
      description:
        t(
          'devices:mappings.create.scanResult.headers.objectIdentifierDescription',
        ) ?? '',
      flex: 1,
    },
    {
      field: 'presentValue',
      headerName:
        t('devices:mappings.create.scanResult.headers.presentValue') ?? '',
      description:
        t(
          'devices:mappings.create.scanResult.headers.presentValueDescription',
        ) ?? '',
      align: 'right',
      flex: 1,
    },
    {
      field: 'unitName',
      headerName:
        t('devices:mappings.create.scanResult.headers.unitName') ?? '',
      description:
        t('devices:mappings.create.scanResult.headers.unitNameDescription') ??
        '',
      flex: 1,
    },
    {
      field: 'lowLimit',
      headerName:
        t('devices:mappings.create.scanResult.headers.lowLimit') ?? '',
      description:
        t('devices:mappings.create.scanResult.headers.lowLimitDescription') ??
        '',
      type: 'number',
      flex: 1,
    },
    {
      field: 'highLimit',
      headerName:
        t('devices:mappings.create.scanResult.headers.highLimit') ?? '',
      description:
        t('devices:mappings.create.scanResult.headers.highLimitDescription') ??
        '',
      type: 'number',
      flex: 1,
    },
    {
      field: 'isMappable',
      headerName:
        t('devices:mappings.create.scanResult.headers.isMappable') ?? '',
      description:
        t('devices:mappings.create.scanResult.headers.isMappableDescription') ??
        '',
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'mappedDeviceModel',
      headerName:
        t('devices:mappings.create.scanResult.headers.mappedDeviceModel') ?? '',
      description:
        t(
          'devices:mappings.create.scanResult.headers.mappedDeviceModelDescription',
        ) ?? '',
      flex: 1,
      valueGetter: (params: GridRenderCellParams) => params.row.model?.name,
    },
    {
      field: 'mappedDevice',
      headerName:
        t('devices:mappings.create.scanResult.headers.mappedDevice') ?? '',
      description:
        t(
          'devices:mappings.create.scanResult.headers.mappedDeviceDescription',
        ) ?? '',
      flex: 1,
      valueGetter: (params: GridRenderCellParams) => params.row.device?.name,
    },
    {
      field: 'mappedCapability',
      headerName:
        t('devices:mappings.create.scanResult.headers.mappedCapability') ?? '',
      description:
        t(
          'devices:mappings.create.scanResult.headers.mappedCapabilityDescription',
        ) ?? '',
      flex: 1,
      valueGetter: (params: GridRenderCellParams) =>
        params.row.capability?.name,
      renderCell: (
        params: GridRenderCellParams<
          ScanDataWithMappedDatapoints,
          ScanDataWithMappedDatapoints
        >,
      ) => {
        if (
          !params.row.isSelected ||
          !params.row.capabilityOptions?.length ||
          !params.row.onSelectCapability
        ) {
          return params.row.capability?.name;
        }
        if (!params.row.model || !params.row.device) {
          return undefined;
        }

        return (
          <CapabilitySelectionDropdown
            onSelect={params.row.onSelectCapability(params.row)}
            capabilityOptions={params.row.capabilityOptions}
            selectedCapabilityId={params.row.capability?.id}
            datapointUnitName={params.row.unitName}
            datapointUnitUris={params.row.unitUris}
          />
        );
      },
    },
    {
      field: 'action',
      type: 'actions',
      getActions: (params: GridRowParams) =>
        params.row.canSelect &&
        (params.row.model || params.row.device || params.row.capability)
          ? [
              <GridActionsCellItem
                key={params.row.deviceIdentifier + params.row.objectIdentifier}
                icon={<Icons.Delete />}
                data-testid="delete-button"
                onClick={params.row.onDeleteMapping?.(params.row)}
                disabled={!onDeleteMapping}
                label={t('devices:mappings.create.scanResult.deleteMapping')}
              />,
            ]
          : [],
    },
  ];

  const rows = useMemo(
    () =>
      scanResultWithMappings
        .filter(
          (dp) =>
            !filterDeviceIds.length ||
            filterDeviceIds.includes(dp.deviceIdentifier),
        )
        .map((dp) => ({
          ...dp,
          objectIdentifierStr: translateBacnetId(dp.objectIdentifier),
          deviceIdentifierStr: translateBacnetId(dp.deviceIdentifier),
          canSelect: selectionMode,
          onSelectCapability: handleSelectCapability,
          onDeleteMapping: handleClickDeleteMapping,
        })),
    [
      selectionMode,
      filterDeviceIds,
      scanResultWithMappings,
      handleSelectCapability,
      handleClickDeleteMapping,
    ],
  );

  const devices = scanResultWithMappings
    .map((dp) => dp.device?.name)
    .reduce((nameCountMap, name) => {
      return name
        ? { ...nameCountMap, [name]: (nameCountMap[name] ?? 0) + 1 }
        : nameCountMap;
    }, {} as Record<string, number>);

  return (
    <>
      <FancyCard
        title={<CardTitle scanFinishDate={scanFinishDate} />}
        className={classes.card}
        loading={initialLoading}
        actions={
          <>
            {onDiscovery && (
              <IconButton
                disabled={loading || initialLoading}
                aria-label={`start-scan-button`}
                data-testid={`start-scan-button`}
                onClick={onDiscovery}
                size="medium"
                color="primary"
              >
                <Tooltip
                  arrow
                  title={
                    t('devices:mappings.create.scanResult.startScan') ?? ''
                  }
                  placement="bottom"
                >
                  <Icons.TrackChanges fontSize="medium" />
                </Tooltip>
              </IconButton>
            )}
            {onDownload && (
              <IconButton
                disabled={downloading || loading || initialLoading}
                color="primary"
                size="medium"
                onClick={handleClickDownload}
                data-testid="mapping-draft-download-button"
              >
                {downloading ? (
                  <Loading className={classes.loadingIcon} size={24} />
                ) : (
                  <Tooltip
                    arrow
                    title={
                      t('devices:mappings.create.scanResult.download') ?? ''
                    }
                    placement="bottom"
                  >
                    <Icons.Upload fontSize="medium" />
                  </Tooltip>
                )}
              </IconButton>
            )}
            {onUpload && (
              <IconButton
                disabled={uploading || loading || initialLoading}
                color="primary"
                size="medium"
                data-testid="mapping-draft-upload-button"
                onClick={handleClickUpload}
              >
                {uploading ? (
                  <Loading className={classes.loadingIcon} size={24} />
                ) : (
                  <Tooltip
                    arrow
                    title={t('devices:mappings.create.scanResult.upload') ?? ''}
                    placement="bottom"
                  >
                    <Icons.Download fontSize="medium" />
                  </Tooltip>
                )}
              </IconButton>
            )}
            <IconButton
              disabled={loading || initialLoading}
              aria-label={`show-devices-button`}
              data-testid={`show-devices-button`}
              onClick={handleClickShowDeviceList}
              size="medium"
              color="primary"
            >
              <Tooltip
                arrow
                title={
                  t('devices:mappings.create.scanResult.showDevices') ?? ''
                }
                placement="bottom"
              >
                <Icons.DeviceHub fontSize="medium" />
              </Tooltip>
            </IconButton>
          </>
        }
      >
        <DataGrid
          loading={initialLoading}
          apiRef={apiRef}
          height={360}
          filterModel={filterModel}
          sortModel={sortModel}
          rowSelectionModel={selectionModel}
          slotProps={{
            toolbar: {
              disabled: loading ?? !scanResultWithMappings.length,
              devices: scanResultDevices,
              selectedDeviceIds: filterDeviceIds,
              onFilterChange: handleBACnetDeviceFilter,
            },
          }}
          slots={{
            toolbar: Toolbar,
            noRowsOverlay: NoRowsOverlay,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                deviceIdentifierStr: false,
                objectIdentifierStr: false,
                lowLimit: false,
                highLimit: false,
                isMappable: false,
              },
            },
          }}
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={handleSelect}
          onSortModelChange={handleSort}
          onFilterModelChange={handleFilter}
          hideFooter
          checkboxSelection
          disableRowSelectionOnClick={!selectionMode}
        />
      </FancyCard>
      <ImportMappingsCSVDialog
        open={shouldShowUploadFile}
        onCancel={handleCancelUpload}
        onConfirm={handleConfirmUpload}
      />
      <DeviceListDialog
        open={shouldShowDeviceList}
        devices={devices}
        onClose={handleCloseDeviceList}
        onDelete={onDeleteDevice}
      />
      <ConfirmAlert
        open={shouldShowDeleteAlert}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t('devices:mappings.create.deleteMappingAlert.title')}
        message={t('devices:mappings.create.deleteMappingAlert.message')}
        cancelButton={t('general:buttons.cancel')}
        confirmButton={t('general:buttons.delete')}
      />
    </>
  );
};
