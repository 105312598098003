import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeOptional } from '../Fields';

interface DataTypeInputStringProps {
  disabled?: boolean;
  value: string;
  id: string;
  name: string;
  label?: string;
  className?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

export const DataTypeInputString = React.forwardRef<
  HTMLInputElement,
  DataTypeInputStringProps
>(
  (
    {
      disabled,
      value,
      id,
      name,
      label,
      className,
      onChange,
      autoFocus = true,
      error,
      helperText,
      optional,
    },
    ref,
  ) => {
    const { t } = useTranslation(['general']);
    return (
      <TextField
        inputRef={ref}
        fullWidth
        disabled={disabled}
        id={id}
        name={name}
        label={optional ? makeOptional(t, label) : label}
        InputLabelProps={{ shrink: true }}
        defaultValue={value}
        autoFocus={autoFocus}
        inputProps={{ className, maxLength: 250 }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        }}
        error={error}
        helperText={helperText}
      />
    );
  },
);

DataTypeInputString.displayName = 'DataTypeInputString';
