import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import {
  ApiError,
  CommissioningSvcNullValue as NullValue,
  CommissioningSvcObjectScan as ObjectScan,
} from '@kp/rest-api-javascript-sdk';
import { useMutation } from '@tanstack/react-query';
import * as API from '../../../api/bacnet';
import { Devices, ObjectDiscoveryCard } from './ObjectDiscoveryCard';
import { ErrorAlert } from '../../../components/Alerts';
import { ConnectionStates } from '../details/DeviceDetailsInfo';
import { CommissioningStatus } from './CommissioningStatus';

type ObjectDiscoveryCardContainerProps = {
  loading?: boolean;
  gatewayId?: string;
  gatewayStatus?: ConnectionStates;
  commissioningStatus?: CommissioningStatus;
  uploading: boolean;
};

const isValidScan = (
  scan: NullValue | ObjectScan | undefined,
): scan is ObjectScan => (scan as ObjectScan)?.id !== undefined;

export const ObjectDiscoveryCardContainer: React.FC<
  ObjectDiscoveryCardContainerProps
> = ({ loading, gatewayId, gatewayStatus, commissioningStatus, uploading }) => {
  const { t } = useTranslation(['general']);
  const { hasPermission } = useAuth();
  const [devices, setDevices] = useState<Devices>();

  const {
    mutate: callGetDevices,
    isLoading: loadingObjectScan,
    error: errorObjectScan,
  } = useMutation({
    mutationFn: API.getDevices,
    onSuccess: (result) => {
      const mappedDevices = result?.data?.map((d) => ({
        ...d,
        scan: isValidScan(d.scan) ? d.scan : undefined,
      }));
      setDevices(mappedDevices);
    },
    onError: (err: ApiError) => err,
  });
  useEffect(() => {
    setDevices(undefined);
    if (gatewayId) {
      callGetDevices(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);
  useEffect(() => {
    if (gatewayId) {
      callGetDevices(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissioningStatus, uploading]);

  const {
    mutate: callStartObjectScan,
    isLoading: loadingStartObjectScan,
    error: errorStartObjectScan,
  } = useMutation({
    mutationFn: API.startObjectScan,
    onSuccess: () => {
      if (gatewayId) {
        callGetDevices(gatewayId);
      }
    },
    onError: (err: ApiError) => err,
  });
  const {
    mutate: callUploadObjectScan,
    isLoading: loadingUploadObjectScan,
    error: errorUploadObjectScan,
  } = useMutation({
    mutationFn: API.uploadObjectScan,
    onSuccess: () => {
      if (gatewayId) {
        callGetDevices(gatewayId);
      }
    },
    onError: (err: ApiError) => err,
  });

  const {
    mutate: callDeleteObjectScan,
    isLoading: loadingDeleteObjectScan,
    error: errorDeleteObjectScan,
  } = useMutation({
    mutationFn: API.deleteObjectScan,
    onSuccess: () => {
      if (gatewayId) {
        callGetDevices(gatewayId);
      }
    },
    onError: (err: ApiError) => err,
  });

  const handleStartScan = async (deviceIds: string[]) => {
    if (!gatewayId) return;
    callStartObjectScan({ gatewayId, deviceIds });
  };

  const handleSelectFile = async (deviceIds: string[], xml: string) => {
    if (!gatewayId) return;
    callUploadObjectScan({ gatewayId, deviceIds, xml });
  };

  const handleDeleteObjectScan = async (deviceId: string) => {
    if (!gatewayId) return;
    callDeleteObjectScan({ gatewayId, deviceId });
  };

  return (
    <>
      <ObjectDiscoveryCard
        loading={
          loading ||
          uploading ||
          loadingObjectScan ||
          loadingUploadObjectScan ||
          loadingStartObjectScan ||
          loadingDeleteObjectScan
        }
        devices={devices}
        gatewayStatus={gatewayStatus}
        commissioningStatus={commissioningStatus}
        onStartScan={
          hasPermission(Permissions.CommissioningWrite)
            ? handleStartScan
            : undefined
        }
        onSelectFile={
          hasPermission(Permissions.CommissioningWrite)
            ? handleSelectFile
            : undefined
        }
        onDeleteObjectScan={
          hasPermission(Permissions.CommissioningWrite)
            ? handleDeleteObjectScan
            : undefined
        }
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorObjectScan?.status !== 404 ? errorObjectScan : undefined,
          errorUploadObjectScan,
          errorStartObjectScan,
          errorDeleteObjectScan,
        ]}
      />
    </>
  );
};
