import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface UserRoleDescriptionProps {
  roleName: string;
}

export const UserRoleDescription: React.FC<UserRoleDescriptionProps> = ({
  roleName,
}) => {
  const { t } = useTranslation(['general']);

  // We actually need to specify those with a map, because the locale extractor
  // should be able to catch those separately
  const roleDescription = useMemo<Record<string, string>>(
    () => ({
      'Tenant Admin': t('general:userRoles.description.tenantAdmin'),
      'Power User': t('general:userRoles.description.powerUser'),
      'Standard User': t('general:userRoles.description.standardUser'),
      Developer: t('general:userRoles.description.developer'),
    }),
    [t],
  );

  return <span>{roleDescription[roleName]}</span>;
};
