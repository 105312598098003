import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import * as Entities from '../../../../constants/Entities';
import { ErrorMessage } from '../../../../components/Errors';
import { entityLocation } from '../../../../utils/entity';
import { useHeader } from '../../../../contexts/header-context';
import { useDeviceModelCapabilityDetailsQuery } from '../../../../__generated__/types';
import { DeviceModelCapabilityDeleteContainer } from '../delete/DeviceModelCapabilityDeleteContainer';
import { DeviceModelCapabilityDetails } from './DeviceModelCapabilityDetails';
import { UNITLESS_URI } from '../../../../constants/Misc';
import { NotFound } from '../../../errorScreens';

export const DeviceModelCapabilityDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { setTitle } = useHeader();
  const { deviceModelId, deviceModelCapabilityId } = params;
  const { hasPermission } = useAuth();

  const [deleteMode, setDeleteMode] = useState(false);

  const { data, loading, error } = useDeviceModelCapabilityDetailsQuery({
    variables: { deviceModelCapabilityId },
  });

  const deviceModelCapability = data?.deviceModelCapability;
  const deviceModel = deviceModelCapability?.deviceModel;
  const isUnitlessUnit =
    !!deviceModelCapability?.unit?.uris?.includes(UNITLESS_URI);

  const hierarchy = deviceModel
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICEMODEL,
            id: deviceModelId,
            name: deviceModel?.name || deviceModelId,
          },
        ],
        t,
      ).concat([
        {
          title: t('deviceModels:capabilities.breadcrumb.title'),
          location: '',
        },
        {
          title:
            deviceModelCapability?.capability?.name || deviceModelCapabilityId,
          location: entityLocation(
            Entities.DEVICEMODEL,
            `${deviceModelId}/capabilities/${deviceModelCapabilityId}`,
          ),
        },
      ])
    : [];

  useBreadcrumb(hierarchy);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId));
  const handleEdit = () =>
    navigate(
      entityLocation(
        Entities.DEVICEMODEL,
        `${deviceModelId}/capabilities/${deviceModelCapabilityId}/edit`,
      ),
    );

  useEffect(() => {
    setTitle({ main: deviceModelCapability?.capability?.name });
  }, [setTitle, deviceModelCapability]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !deviceModelCapability) {
    return (
      <NotFound
        title={t('deviceModels:deviceModelCapability.notFound.title') ?? ''}
        subtitle={
          t('deviceModels:deviceModelCapability.notFound.subtitle') ?? ''
        }
        buttonText={
          t('deviceModels:deviceModelCapability.notFound.buttonText') ?? ''
        }
        buttonOnClick={() =>
          navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId))
        }
      />
    );
  }
  if (
    deviceModelCapability?.deviceModelId &&
    deviceModelId !== deviceModelCapability?.deviceModelId
  ) {
    return (
      <NotFound
        title={t('deviceModels:deviceModelCapability.notFound.title') ?? ''}
        subtitle={
          t('deviceModels:deviceModelCapability.notFound.subtitle') ?? ''
        }
        buttonText={
          t('deviceModels:deviceModelCapability.notFound.buttonText') ?? ''
        }
        buttonOnClick={() =>
          navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId))
        }
      />
    );
  }

  const isLocked =
    !!deviceModelCapability?.isLocked &&
    !hasPermission(Permissions.DeviceModelsWriteLocked);
  const deletePermission = deviceModelCapability?.isLocked
    ? hasPermission(Permissions.DeviceModelsWriteLocked)
    : hasPermission(Permissions.DeviceModelsWrite);
  const editPermission =
    hasPermission(Permissions.DeviceModelsWrite) ||
    hasPermission(Permissions.DeviceModelsWriteLocked);

  return (
    <>
      <DeviceModelCapabilityDetails
        loading={loading}
        name={deviceModelCapability?.capability.name ?? ''}
        isGlobal={deviceModel?.isGlobal ?? false}
        isLocked={isLocked}
        technicalMin={deviceModelCapability?.technicalMin ?? 0}
        technicalMax={deviceModelCapability?.technicalMax ?? 0}
        unitName={deviceModelCapability?.unit?.name ?? ''}
        unitSymbol={deviceModelCapability?.unit?.unitSymbol ?? ''}
        isUnitless={isUnitlessUnit}
        fieldSelector={deviceModelCapability?.fieldSelector ?? ''}
        onDelete={deletePermission ? handleDelete : undefined}
        onEdit={editPermission ? handleEdit : undefined}
      />
      <DeviceModelCapabilityDeleteContainer
        id={deviceModelCapabilityId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
