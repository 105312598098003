import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import {
  DeviceModelCreateType,
  useDeviceModelCreateMutation,
  useDeviceTypesQuery,
} from '../../../__generated__/types';
import { DeviceModelForm, DeviceModelData } from '../common/DeviceModelForm';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { ErrorMessage } from '../../../components/Errors';
import { useHeader } from '../../../contexts/header-context';

export const DeviceModelCreateContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const { setTitle } = useHeader();

  const {
    loading: loadingDeviceTypes,
    error: errorDeviceTypes,
    data: dataDeviceTypes,
  } = useDeviceTypesQuery();
  const allDeviceTypes = (dataDeviceTypes?.deviceTypes?.items ?? []).map(
    (dt) => ({
      id: dt.id,
      deviceTypeName: dt.name,
      isGateway: dt.isGateway ?? false,
    }),
  );

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.DEVICEMODEL,
        id: '/new',
        name: t('deviceModels:breadcrumb.create'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  const [createDeviceModel, { loading: loadingCreate, error: errorCreate }] =
    useDeviceModelCreateMutation({
      onError: (error) => console.warn(error),
    });

  const handleDiscard = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  const handleCreate = async (inputData: DeviceModelData) => {
    const deviceModel: DeviceModelCreateType = {
      name: inputData.deviceModelName,
      deviceTypeId: inputData.deviceTypeId,
      deviceIdentifierFieldSelector: inputData.deviceIdentifierFieldSelector,
    };

    return createDeviceModel({
      variables: { deviceModel },
    }).then((result) => {
      const deviceModelId = result.data?.createDeviceModel?.id ?? '0';
      return navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId));
    });
  };

  useEffect(() => {
    setTitle({ main: t('deviceModels:breadcrumb.create') });
  }, [setTitle, t]);

  const emptyDeviceModel = {
    id: '0',
    deviceModelName: '',
    deviceTypeId: '0',
    deviceIdentifierFieldSelector: '',
  };

  if (errorDeviceTypes) {
    return <ErrorMessage error={errorDeviceTypes} />;
  }

  return (
    <>
      <DeviceModelForm
        loading={loadingDeviceTypes || loadingCreate}
        type="create"
        {...emptyDeviceModel}
        allDeviceTypes={allDeviceTypes}
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorCreate]}
      />
    </>
  );
};
