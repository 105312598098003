import React, { useMemo } from 'react';
import * as Icons from '@mui/icons-material';
import { createClasses } from '@kp/react-ui';
import {
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataTypeLabel } from '../../../../components/DataTypeLabel';
import { DataTypes } from '../../../../__generated__/types';
import { Autocomplete } from '../../../../components/Autocomplete';

const classes = createClasses({
  container: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    borderRadius: '5px',
  },
  requiredIcon: {
    margin: '0 0 0 10px',
  },
  divider: {
    height: 35,
    margin: '8px 10px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'center',
    padding: '0 10px 0 5px',
  },
  formContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  select: {
    minWidth: '250px',
  },
  dataType: {
    marginLeft: '8px',
  },
  actions: {
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  iconButton: {
    width: '40px',
    height: '40px',
  },
  loader: {
    margin: '0 10px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

export type Attribute = {
  attributeId: string;
  attributeName: string;
  required: boolean;
  dataType: DataTypes | null;
};
export type AttributeMapping = Attribute & {
  mappingId: string;
};
type DeviceModelAttributeSetDetailsAttributesCardProps = {
  mapping: AttributeMapping;
  loadingSave: boolean;
  loadingDelete: boolean;
  showSave: boolean;
  onChange?: (id: string) => void;
  onSave?: () => void;
  onDelete?: () => void;
  allAttributes: Array<Attribute>;
  isDeviceModelGlobal: boolean;
};

export const DeviceModelAttributeSetDetailsAttributesCard: React.FC<
  DeviceModelAttributeSetDetailsAttributesCardProps
> = ({
  mapping,
  loadingSave,
  loadingDelete,
  showSave,
  onChange,
  onSave,
  onDelete,
  allAttributes,
  isDeviceModelGlobal,
}) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: string | null,
  ) => {
    if (onChange) onChange(value ?? '0');
  };

  const attributeOptions = useMemo(
    () =>
      allAttributes
        .map(({ attributeId: id, attributeName: label }) => ({
          id,
          label,
        }))
        .concat([
          {
            id: '0',
            label: t('general:forms.pleaseChoose'),
          },
        ]),
    [allAttributes, t],
  );

  return (
    <Paper className={classes.container}>
      <Tooltip
        arrow
        placement="top"
        title={
          <div className={classes.tooltipContent}>
            {mapping.required
              ? t('general:forms.required')
              : t('general:forms.optional')}
          </div>
        }
      >
        <Icons.FiberManualRecord
          fontSize="medium"
          color={mapping.required ? 'primary' : 'disabled'}
          className={classes.requiredIcon}
        />
      </Tooltip>
      <Divider orientation="vertical" className={classes.divider} />
      <div className={classes.content}>
        <div className={classes.formContent}>
          <FormControl>
            <Tooltip
              arrow
              placement="top-end"
              title={
                isDeviceModelGlobal ? (
                  <div className={classes.tooltipContent}>
                    {t('deviceModels:attributeMapping.globalWarning')}
                  </div>
                ) : (
                  ''
                )
              }
              aria-label="select-tooltip"
            >
              <Autocomplete
                id="attribute-select"
                options={attributeOptions}
                className={classes.select}
                value={mapping.attributeId}
                onChange={handleChange}
                disabled={!onChange || isDeviceModelGlobal}
              />
            </Tooltip>
          </FormControl>
          {mapping.attributeId !== '0' && (
            <Tooltip
              className={classes.dataType}
              arrow
              placement="top"
              title={
                <div className={classes.tooltipContent}>
                  {t('deviceModels:attributeMapping.dataType')}
                </div>
              }
            >
              <div>
                <DataTypeLabel dataType={mapping.dataType} />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={classes.actions}>
          {loadingSave && (
            <CircularProgress size="20px" className={classes.loader} />
          )}
          {!loadingSave && showSave && !loadingDelete && (
            <IconButton
              name="save"
              aria-label="save"
              color="primary"
              className={classes.iconButton}
              onClick={onSave}
            >
              <Icons.Check fontSize="small" />
            </IconButton>
          )}
          {loadingDelete ? (
            <CircularProgress size="20px" className={classes.loader} />
          ) : (
            !isDeviceModelGlobal &&
            onDelete && (
              <IconButton
                name="delete"
                aria-label="delete"
                className={classes.iconButton}
                onClick={onDelete}
              >
                <Icons.Delete fontSize="small" />
              </IconButton>
            )
          )}
        </div>
      </div>
    </Paper>
  );
};
