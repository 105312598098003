import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthLanding } from './AuthLanding';

export const AuthLandingContainer: React.FC = () => {
  const [searchParams] = useSearchParams();
  return (
    <AuthLanding
      fromAcceptInvitation={searchParams.get('from') === 'accept-invitation'}
    />
  );
};
