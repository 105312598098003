import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { FieldDeviceList } from './FieldDeviceList';
import {
  useFieldDeviceListGatewayDetailsLazyQuery,
  useFieldDeviceListGatewayDetailsQuery,
} from '../../__generated__/types';
import { ErrorAlert } from '../Alerts';
import { ErrorMessage } from '../Errors';
import { getLatestDeployment, getLatestMappings } from '../../api/bacnet';
import { byName } from '../../utils/sort';

type FieldDeviceListContainerProps = {
  loading?: boolean;
  gatewayId?: string;
  defaultExpanded?: boolean;
  onSelectDevice?: (id?: string) => void;
  onEditMappings?: () => void;
  onCreateMappings?: () => void;
  onDecommissionGateway?: () => Promise<unknown>;
  onAddDevice?: () => void;
  isHidden?: boolean;
};
export const FieldDeviceListContainer: React.FC<
  FieldDeviceListContainerProps
> = ({
  loading,
  gatewayId: passedGatewayId,
  defaultExpanded,
  onSelectDevice,
  onEditMappings,
  onCreateMappings,
  onDecommissionGateway,
  onAddDevice,
  isHidden,
}) => {
  const { t } = useTranslation(['general', 'errors']);
  const { gatewayId = passedGatewayId ?? '0' } = useParams();

  const [searchParams] = useSearchParams();
  const scrollToDeviceId = searchParams.get('scrollToDevice') ?? undefined;

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useFieldDeviceListGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const [
    refetchGateway,
    {
      loading: loadingRefetchGateway,
      error: errorRefetchGateway,
      data: dataGRefetchateway,
    },
  ] = useFieldDeviceListGatewayDetailsLazyQuery();
  const gateway = dataGRefetchateway?.device ?? dataGateway?.device;

  const {
    isLoading: loadingMappings,
    data: responseMappings,
    error: errorMappings,
  } = useQuery({
    queryKey: ['getLatestMappings', gatewayId],
    queryFn: () => getLatestMappings(gatewayId),
    onError: (err: ApiError) => err,
  });

  const {
    mutate: callGetLatestDeployments,
    isLoading: loadingLatestDeployment,
    data: responseLatestDeployment,
    error: errorLatestDeployment,
  } = useMutation({
    mutationFn: getLatestDeployment,
    onError: (err: ApiError) => err,
  });

  useEffect(() => {
    if (onEditMappings || onCreateMappings) {
      callGetLatestDeployments(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDecommissionGateway = () => {
    if (onDecommissionGateway) {
      onDecommissionGateway()
        .then(() => {
          if (onEditMappings || onCreateMappings) {
            callGetLatestDeployments(gatewayId);
          }
          return refetchGateway({
            variables: {
              gatewayId,
            },
          });
        })
        .catch(console.warn);
    }
  };
  const hasNoDeployment =
    !loadingLatestDeployment &&
    !responseLatestDeployment?.data &&
    !errorLatestDeployment;
  const hasMappings =
    responseLatestDeployment?.data?.mapping?.total &&
    responseLatestDeployment.data.mapping.total > 0;

  const hasDeployment = !hasNoDeployment && !!hasMappings;

  const devices = useMemo(
    () =>
      (gateway?.inverseParentDevice ?? [])
        .map((device) => {
          const capabilityIds = device.deviceModel.deviceModelCapabilities.map(
            (dmc) => dmc.id,
          );
          const mappingCount = capabilityIds.filter((cId) =>
            responseMappings?.data?.find(
              (m) =>
                m.mappedCapabilityId === cId && m.mappedDeviceId === device.id,
            ),
          ).length;

          const capabilitiesCount =
            device?.deviceModel?.deviceModelCapabilities?.length ?? 0;

          return {
            id: device?.id ?? '-',
            name: device?.name || '-',
            description: device?.description || '-',
            model: device?.deviceModel?.name || '-',
            capabilitiesCount,
            mappingCount,
          };
        })
        .sort(byName),
    [gateway, responseMappings],
  );

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  if (errorGateway) {
    return (
      <ErrorMessage
        error={errorGateway}
        message={t('errors:notFound.gateway')}
      />
    );
  }

  // hide datagrid warning if the element is hidden
  if (isHidden) return null;

  return (
    <>
      <FieldDeviceList
        loading={
          loading ||
          loadingGateway ||
          loadingRefetchGateway ||
          loadingMappings ||
          loadingLatestDeployment
        }
        devices={devices}
        scrollToDeviceId={scrollToDeviceId}
        defaultExpanded={defaultExpanded}
        onSelectDevice={onSelectDevice}
        onEditMappings={onEditMappings}
        onCreateMappings={onCreateMappings}
        onDecommissionGateway={
          onDecommissionGateway ? handleDecommissionGateway : undefined
        }
        onAddDevice={onAddDevice}
        hasDeployment={hasDeployment}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorMappings?.status !== 404 ? errorMappings : undefined,
          errorLatestDeployment?.status !== 404
            ? errorLatestDeployment
            : undefined,
          errorRefetchGateway,
        ]}
      />
    </>
  );
};
