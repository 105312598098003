import React from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';

export type LoadingRowProps = {
  span: number;
};
export const LoadingRow: React.FC<LoadingRowProps> = ({ span }) => {
  return (
    <TableRow>
      <TableCell colSpan={span} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>
  );
};
