import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  TextField,
  AutocompleteInputChangeReason,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../../components/FancyCard';
import { DeviceTooltip } from './DeviceTooltip';
import { ModelTooltip } from './ModelTooltip';
import { CreatedDevice } from './MappingStateUtils';

const classes = createClasses({
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '22px 16px 10px 16px',
  },
  modelSelection: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    padding: 8,
  },
  deviceSelectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '66%',
    alignItems: 'flex-end',
  },
  deviceSelection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 8,
  },
  createDeviceButton: {
    height: 25,
    minWidth: 180,
    marginLeft: 8,
    marginBottom: 8,
    padding: 0,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export type DeviceModel = {
  id: string;
  name: string;
  deviceModelCapabilities: Array<{
    id: string;
    name: string;
    technicalMin?: number;
    technicalMax?: number;
    unitName: string;
    unitSymbol: string;
    isMatch: boolean;
  }>;
};
export type Device = {
  name: string;
  deviceModelId: string;
};

type DeviceMappingsDeviceAssignmentCardProps = {
  loading?: boolean;
  selectedDeviceModel?: DeviceModel;
  deviceModels?: DeviceModel[];
  selectedDevice?: Device;
  allDevices: Device[];
  assignedCapabilities: Array<{ deviceName: string; capabilityId: string }>;
  onCancel?: () => void;
  onDone?: () => void;
  onSelectDeviceModel?: (modelId?: string) => void;
  onSelectDevice?: (deviceName?: string) => void;
  onCreateDevice?: (deviceName: string) => void;
};

export const DeviceMappingsDeviceAssignmentCard: React.FC<
  DeviceMappingsDeviceAssignmentCardProps
> = ({
  loading,
  selectedDeviceModel,
  deviceModels = [],
  selectedDevice,
  allDevices,
  assignedCapabilities,
  onCancel,
  onDone,
  onSelectDeviceModel,
  onSelectDevice,
  onCreateDevice,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const [deviceNameToCreate, setDeviceNameToCreate] = useState<string>();

  const handleSelectDeviceModel = (event: SelectChangeEvent<string>) => {
    const modelId =
      event.target.value === 'NONE' ? undefined : event.target.value;
    if (onSelectDeviceModel) onSelectDeviceModel(modelId);
  };

  const handleDeviceNameInput = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setDeviceNameToCreate(value ?? undefined);
  };

  const handleCreateDevice = (deviceName?: string) => {
    if (!deviceName) return;
    if (onCreateDevice) onCreateDevice(deviceName);
  };

  const handleSelectDevice = (
    event: React.SyntheticEvent<Element, Event>,
    value: CreatedDevice | string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<CreatedDevice | string> | undefined,
  ) => {
    let deviceName: string | null = null;
    if (typeof value === 'string') deviceName = value.trim();
    if (typeof value !== 'string' && value?.name) deviceName = value.name;
    if (onSelectDevice) onSelectDevice(deviceName ?? undefined);
  };

  const handleDone = () => {
    if (onDone) onDone();
  };
  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const devices = allDevices.filter(
    (d) => d.deviceModelId === selectedDeviceModel?.id,
  );

  return (
    <>
      <FancyCard
        title={t('devices:mappings.create.deviceAssignmentCard.title')}
        loading={loading}
        actions={
          <>
            <Button
              color="secondary"
              variant="text"
              onClick={handleCancel}
              disabled={!onCancel}
            >
              {t('general:buttons.cancel')}
            </Button>
            <Button
              color="primary"
              variant="text"
              onClick={handleDone}
              disabled={!onDone}
            >
              {t('general:buttons.done')}
            </Button>
          </>
        }
      >
        <div className={classes.cardContent}>
          <div className={classes.modelSelection}>
            <div>
              {t('devices:mappings.create.deviceAssignmentCard.model.title')}
            </div>
            <Select
              data-testid={'model-select'}
              variant="standard"
              defaultValue="NONE"
              value={selectedDeviceModel?.id ?? 'NONE'}
              onChange={handleSelectDeviceModel}
              disabled={!onSelectDeviceModel || !deviceModels.length || loading}
            >
              <MenuItem value="NONE" data-testid={'model-select-none'}>
                <em>
                  {deviceModels.length
                    ? t('devices:mappings.create.scanResult.noneMale')
                    : t(
                        'devices:mappings.create.deviceAssignmentCard.noModelsFound',
                      )}
                </em>
              </MenuItem>
              {deviceModels.map((model) => (
                <MenuItem key={model.id} value={model.id}>
                  <div className={classes.menuItem}>
                    <span>{model.name}</span>
                    <span>
                      <ModelTooltip model={model} />
                    </span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.deviceSelectionContainer}>
            <div className={classes.deviceSelection}>
              <div>
                {t('devices:mappings.create.deviceAssignmentCard.device.title')}
              </div>
              <Autocomplete
                data-testid={'device-name-input'}
                onChange={handleSelectDevice}
                onInputChange={handleDeviceNameInput}
                freeSolo
                options={devices}
                getOptionLabel={(device) => (device as Device)?.name ?? ''}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, device, { selected }) => (
                  <MenuItem {...props}>
                    <div className={classes.menuItem}>
                      <span>{device?.name}</span>
                      <span>
                        <DeviceTooltip
                          device={device}
                          deviceModels={deviceModels}
                          assignedCapabilities={assignedCapabilities}
                        />
                      </span>
                    </div>
                  </MenuItem>
                )}
                renderInput={(params) => <TextField {...params} />}
                disabled={!onSelectDevice || !selectedDeviceModel || loading}
                value={selectedDevice ?? ''}
              />
            </div>
            <Button
              color="primary"
              variant="text"
              className={classes.createDeviceButton}
              startIcon={<Icons.Edit />}
              onClick={() => handleCreateDevice(deviceNameToCreate)}
              disabled={
                !onSelectDevice ||
                !deviceNameToCreate ||
                !!devices.find((d) => d.name === deviceNameToCreate.trim()) ||
                loading
              }
            >
              {t('devices:mappings.create.scanResult.createDeviceButton')}
            </Button>
          </div>
        </div>
      </FancyCard>
    </>
  );
};
