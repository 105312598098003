import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import { ZoneDetails } from './ZoneDetails';
import { useZoneDetailsQuery } from '../../../__generated__/types';
import { ErrorMessage } from '../../../components/Errors';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityLocation } from '../../../utils/entity';
import { ZoneDeleteContainer } from '../delete/ZoneDeleteContainer';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const ZoneDetailsContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['zones', 'general']);
  const { zoneId } = useParams();
  const [deleteMode, setDeleteMode] = useState(false);
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  const { loading, error, data } = useZoneDetailsQuery({
    variables: { zoneId },
  });

  const zone = data?.zone;

  const hierarchy =
    zone &&
    zone.name &&
    zone.storey &&
    zone.storey.building &&
    zone.storey.building.site
      ? makeHierarchyBreadcrumb(
          [
            {
              type: Entities.SITE,
              id: zone.storey.building.site.id,
              name: zone.storey.building.site.name,
            },
            {
              type: Entities.BUILDING,
              id: zone.storey.building.id,
              name: zone.storey.building.name,
            },
            {
              type: Entities.STOREY,
              id: zone.storey.id,
              name: zone.storey.name,
            },
            {
              type: Entities.ZONE,
              id: zone.id,
              name: zone.name,
            },
          ],
          t,
        )
      : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: zone?.name });
  }, [setTitle, zone]);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.STOREY, zone?.storey?.id ?? ''));
  const handleEdit = () =>
    navigate(`${entityLocation(Entities.ZONE, zoneId)}/edit`);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !zone) {
    return (
      <NotFound
        title={t('zones:details.notFound.title') ?? ''}
        subtitle={t('zones:details.notFound.subtitle') ?? ''}
        buttonText={t('zones:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.ZONE.path)}
      />
    );
  }

  const image = {
    url:
      zone?.image?.url ??
      `${process.env.PUBLIC_URL}/assets/placeholders/zone.png`,
    name: zone?.image?.name ?? '',
  };

  return (
    <>
      <ZoneDetails
        name={zone?.name ?? ''}
        image={image}
        description={zone?.description ?? ''}
        loading={loading}
        onDelete={
          hasPermission(Permissions.ZonesWrite) ? handleDelete : undefined
        }
        onEdit={hasPermission(Permissions.ZonesWrite) ? handleEdit : undefined}
        showDevices={hasPermission(Permissions.DevicesRead)}
      />
      <ZoneDeleteContainer
        id={zoneId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
