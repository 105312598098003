import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingBackdrop } from '../../../components/Loading';
import { ConfirmAlert, ErrorAlert } from '../../../components/Alerts';

interface UserDeleteProps {
  error?: Error;
  isLoading?: boolean;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const UserDelete: React.FC<UserDeleteProps> = ({
  error,
  isLoading = false,
  isOpen,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation(['users', 'general']);

  return (
    <>
      <LoadingBackdrop open={isLoading} />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error]}
      />
      <ConfirmAlert
        open={isOpen}
        closeButton
        onClose={onClose}
        onConfirm={onConfirm}
        title={t('users:deleteAlert.title')}
        message={t('users:deleteAlert.message')}
        confirmButton={t('users:deleteAlert.remove')}
      />
    </>
  );
};
