import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '80px',
    justifyContent: 'center',
  },
});

interface SiteCreateSuccessDialogProps {
  onConfirm: () => void;
  onDiscard: () => void;
  loading?: boolean;
}

export const SiteCreateSuccessDialog: React.FC<
  SiteCreateSuccessDialogProps
> = ({ onConfirm, onDiscard, loading }) => {
  const { t } = useTranslation(['sites', 'general']);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onDiscard}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6" component="span">
          {t('sites:create.success.title')}
        </Typography>
        <Icons.CheckCircle htmlColor={Color.ok} />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t('sites:create.success.building')}
        </DialogContentText>
        <div className={classes.actions}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              startIcon={<Icons.FileCopyOutlined />}
              onClick={onConfirm}
            >
              {t('sites:create.success.actions.clone.building')}
            </Button>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDiscard} color="primary" disabled={loading}>
          {t('sites:create.success.actions.manualCreation')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
