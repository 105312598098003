import { createClasses } from '@kp/react-ui';
import React from 'react';
import {
  tooltipClasses,
  Tooltip,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Color } from '../../../../constants/Colors';
import { createRangeString } from '../../../../utils/helpers';

const classes = createClasses({
  tooltip: {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      backgroundColor: Color.white,
      border: '1px solid',
      borderColor: Color.border,
    },
  },
  tooltipHeader: {
    color: Color.black,
    fontSize: '130%',
  },
  highlightMatch: {
    backgroundColor: Color.assignedRow,
  },
});

type DeviceModel = {
  id: string;
  name: string;
  deviceModelCapabilities: Array<{
    id: string;
    name: string;
    technicalMin?: number;
    technicalMax?: number;
    unitName: string;
    unitSymbol: string;
    isMatch: boolean;
  }>;
};
type ModelTooltipProps = {
  model: DeviceModel;
};

export const ModelTooltip: React.FC<ModelTooltipProps> = ({ model }) => {
  const { t } = useTranslation(['devices', 'general']);

  const matchingCapabilitiesCount = model.deviceModelCapabilities.filter(
    (c) => c.isMatch,
  ).length;
  const capabilitiesCount = model.deviceModelCapabilities.length;

  return (
    <Tooltip
      placement="left"
      classes={{ popper: classes.tooltip }}
      title={
        <div>
          <div className={classes.tooltipHeader}>
            {t('devices:mappings.create.scanResult.theseCapabilitiesMatched', {
              matchingCapabilitiesCount,
              capabilitiesCount,
            })}
          </div>
          <TableContainer>
            <Table size="small">
              <TableHead></TableHead>
              <TableBody>
                {model.deviceModelCapabilities?.map((capability) => {
                  return (
                    <TableRow
                      key={capability.id}
                      className={classNames(
                        capability.isMatch && classes.highlightMatch,
                      )}
                    >
                      <TableCell>{capability.name}</TableCell>
                      <TableCell align="right">
                        {createRangeString(capability)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    >
      <span>
        {matchingCapabilitiesCount}/{capabilitiesCount}
      </span>
    </Tooltip>
  );
};
