import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import {
  AttributeUpdateType,
  useAttributeEditDetailsQuery,
  useAttributeUpdateMutation,
} from '../../../__generated__/types';
import { AttributeData, AttributeForm } from '../common/AttributeForm';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const AttributeEditContainer: React.FC = () => {
  const { t } = useTranslation(['attributes', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { attributeId } = params;
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useAttributeEditDetailsQuery({
    variables: { attributeId },
  });
  const attribute = data?.attribute;

  const hierarchy = attribute
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.ATTRIBUTE,
            id: attribute.id,
            name: attribute.name || attribute.id,
          },
        ],
        t,
      ).concat({
        title: t('attributes:breadcrumb.edit'),
        location: entityLocation(Entities.ATTRIBUTE, `${attribute.id}/edit`),
      })
    : [];
  useBreadcrumb(hierarchy);

  const [updateAttribute, { loading: loadingUpdate, error: errorUpdate }] =
    useAttributeUpdateMutation({
      onCompleted: () =>
        navigate(entityLocation(Entities.ATTRIBUTE, attribute?.id ?? '')),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: AttributeData) => {
    const attributeInput: AttributeUpdateType = {
      id: attributeId,
      name: inputData.attributeName,
      description: inputData.description,
    };
    return updateAttribute({
      variables: { attributeInput },
    });
  };

  const handleDiscard = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  useEffect(() => {
    setLoading(loading);
    setTitle({ main: attribute?.name });
  }, [setLoading, loading, setTitle, attribute]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !attribute) {
    return (
      <NotFound
        title={t('attributes:details.notFound.title') ?? ''}
        subtitle={t('attributes:details.notFound.subtitle') ?? ''}
        buttonText={t('attributes:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.ATTRIBUTE.path)}
      />
    );
  }
  if (attribute?.isGlobal) {
    return (
      <ErrorMessage
        error={new Error(t('attributes:details.globalWarning') ?? '')}
      />
    );
  }

  return (
    <>
      <AttributeForm
        loading={loading || loadingUpdate}
        type="edit"
        attributeName={attribute?.name ?? ''}
        description={attribute?.description ?? ''}
        dataType={attribute?.dataType ?? null}
        required={attribute?.required ?? false}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
