import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  getTenantFormInfoSchema,
  TenantFormInfo,
  TenantFormInfoData,
} from '../common/TenantFormInfo';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type TenantEditData = TenantFormInfoData;

type TenantEditProps = TenantEditData & {
  onSubmit: (unit: TenantEditData) => void;
  onDiscard: () => void;
  loading?: boolean;
};

export const TenantEdit: React.FC<TenantEditProps> = ({
  name,
  slug,
  contactFirstName,
  contactEmail,
  contactLastName,
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['tenants', 'general']);

  const validationSchema = getTenantFormInfoSchema(t);
  const defaultValues: TenantEditData = {
    name,
    slug,
    contactEmail,
    contactLastName,
    contactFirstName,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    reset({
      name,
      slug,
      contactEmail,
      contactLastName,
      contactFirstName,
    });
  }, [reset, name, slug, contactEmail, contactLastName, contactFirstName]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <TenantFormInfo loading={loading} type="edit" />
    </FormProvider>
  );
};
