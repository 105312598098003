import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ActionsBar } from '../../../components/ActionsBar';
import { DataTypeLabel } from '../../../components/DataTypeLabel';
import { DataTypes } from '../../../__generated__/types';

const classes = createClasses({
  description: {
    whiteSpace: 'pre-wrap',
  },
});

type CapabilityDetailsProps = {
  name: string;
  description: string;
  dataType: DataTypes | null;
  isGlobal: boolean;
  isLocked: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  loading?: boolean;
};

export const CapabilityDetails: React.FC<CapabilityDetailsProps> = ({
  name,
  description,
  dataType,
  isGlobal,
  isLocked,
  onEdit,
  onDelete,
  loading,
}) => {
  const { t } = useTranslation(['capabilities', 'general']);

  const getTooltipTitle = () => {
    if (isGlobal) {
      return t('capabilities:details.globalWarning');
    }
    if (isLocked) {
      return t('capabilities:details.lockedWarning');
    }
    return '';
  };

  return (
    <>
      {!isGlobal && onDelete && (
        <ActionsBar>
          {!loading && (
            <Button color="primary" size="large" onClick={onDelete}>
              {t('capabilities:details.actions.delete')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12} sm={12} md={8}>
              <FancyCard
                title={t('capabilities:details.info.title')}
                actions={
                  onEdit && (
                    <Tooltip
                      arrow
                      placement="top-end"
                      title={getTooltipTitle()}
                      aria-label="settings-tooltip"
                    >
                      <span>
                        <IconButton
                          aria-label="edit"
                          data-testid="card-edit"
                          onClick={onEdit}
                          size="medium"
                          color="primary"
                          disabled={isGlobal || isLocked}
                        >
                          <Icons.Edit fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                }
                loading={loading}
                fullHeight
              >
                <InfoList>
                  <InfoListItem term={t('capabilities:details.info.name')}>
                    {name}
                  </InfoListItem>
                  {description && (
                    <InfoListItem
                      className={classes.description}
                      term={t('general:fields.description')}
                    >
                      {description}
                    </InfoListItem>
                  )}
                </InfoList>
              </FancyCard>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FancyCard
                title={t('capabilities:details.settings.title')}
                loading={loading}
                fullHeight
                actions={
                  onEdit && (
                    <Tooltip
                      arrow
                      placement="top-end"
                      title={t('capabilities:details.settings.disable')}
                      aria-label="non-editable-tooltip"
                    >
                      <span>
                        <IconButton
                          aria-label="edit"
                          onClick={onEdit}
                          size="medium"
                          color="primary"
                          // This is just an ui element that marks that the data type
                          // is non-editable
                          disabled
                        >
                          <Icons.Edit fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                }
              >
                <InfoList>
                  <InfoListItem
                    term={t('capabilities:details.settings.dataType')}
                  >
                    <DataTypeLabel dataType={dataType} />
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
