import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';

const classes = createClasses({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

interface QanteonProvisionCreateConfirmDialogProps {
  open: boolean;
  onConfirm: () => void;
  onDiscard: () => void;
}

export const QanteonProvisionCreateConfirmDialog: React.FC<
  QanteonProvisionCreateConfirmDialogProps
> = ({ open, onConfirm, onDiscard }) => {
  const { t } = useTranslation(['integrations', 'general']);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onDiscard}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6" component="span">
          {t('integrations:qanteon.create.confirmDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('integrations:qanteon.create.confirmDialog.message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDiscard} color="secondary" autoFocus>
          {t('general:buttons.cancel')}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {t('general:buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
