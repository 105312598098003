import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  getRegisteredApplicationFormInfoSchema,
  RegisteredApplicationFormInfo,
  RegisteredApplicationFormInfoData,
} from '../common/RegisteredApplicationFormInfo';
import {
  RegisteredApplicationFormUrl,
  getRegisteredApplicationFormUrlSchema,
  RegisteredApplicationFormUrlData,
} from '../common/RegisteredApplicationFormUrl';
import {
  RegisteredApplicationFormRedirectUris,
  getRegisteredApplicationFormRedirectUrisSchema,
  RegisteredApplicationFormRedirectUrisData,
} from '../common/RegisteredApplicationFormRedirectUris';
import {
  RegisteredApplicationFormPermissions,
  RegisteredApplicationFormPermissionsData,
} from '../common/RegisteredApplicationFormPermissions';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type RegisteredApplicationEditData = RegisteredApplicationFormInfoData &
  RegisteredApplicationFormUrlData &
  RegisteredApplicationFormRedirectUrisData &
  RegisteredApplicationFormPermissionsData;

type RegisteredApplicationEditProps = RegisteredApplicationEditData & {
  loading?: boolean;
  onSubmit: (unit: RegisteredApplicationEditData) => void;
  onDiscard: () => void;
};

export const RegisteredApplicationEdit: React.FC<
  RegisteredApplicationEditProps
> = ({
  name,
  description,
  url,
  redirectUris,
  permissions,
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['registeredApplications', 'general']);
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const typeToShow =
    type === 'info' || type === 'settings' || type === 'permissions'
      ? type
      : 'info';

  const validationSchema = getRegisteredApplicationFormInfoSchema(t)
    .concat(getRegisteredApplicationFormUrlSchema(t))
    .concat(getRegisteredApplicationFormRedirectUrisSchema(t));

  const defaultValues: RegisteredApplicationEditData = {
    name,
    description,
    url,
    redirectUris,
    permissions,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useDeepCompareEffect(() => {
    reset({
      name,
      description,
      url,
      redirectUris,
      permissions,
    });
  }, [reset, name, description, url, redirectUris, permissions]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <Grid container spacing={3}>
        {typeToShow === 'info' && (
          <Grid item xs={12}>
            <RegisteredApplicationFormInfo loading={loading} />
          </Grid>
        )}
        {typeToShow === 'settings' && (
          <>
            <Grid item xs={12}>
              <RegisteredApplicationFormUrl loading={loading} />
            </Grid>
            <Grid item xs={12}>
              <RegisteredApplicationFormRedirectUris loading={loading} />
            </Grid>
          </>
        )}
        {typeToShow === 'permissions' && (
          <Grid item xs={12}>
            <RegisteredApplicationFormPermissions loading={loading} />
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};
