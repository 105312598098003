import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { ConfirmAlert, InfoAlert } from '../../../../components/Alerts';

const classes = createClasses({
  deviceList: {
    maxHeight: '50vh',
    overflowX: 'scroll',
  },
  deviceListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  divider: {
    marginTop: 8,
  },
});

type DeviceListDialogProps = {
  open: boolean;
  devices: Record<string, number>;
  onClose: () => void;
  onDelete?: (deviceName: string) => void;
};

export const DeviceListDialog: React.FC<DeviceListDialogProps> = ({
  open,
  devices,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const [shouldShowDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deviceToDelete, setDeviceToDelete] = useState<string>();

  const handleClickDeleteDevice = (deviceName: string) => () => {
    setDeviceToDelete(deviceName);
    setShowDeleteAlert(true);
  };
  const handleCancelDeleteDevice = () => setShowDeleteAlert(false);
  const handleConfirmDeleteDevice = () => {
    if (onDelete && deviceToDelete) onDelete(deviceToDelete);
    setShowDeleteAlert(false);
  };

  return (
    <>
      <InfoAlert
        open={open}
        maxWidth="md"
        onClose={onClose}
        title={t('devices:mappings.create.deviceListDialog.title')}
        message={
          Object.keys(devices).length
            ? t('devices:mappings.create.deviceListDialog.message')
            : t('devices:mappings.create.deviceListDialog.emptyMessage')
        }
      >
        <Divider className={classes.divider} />
        <List className={classes.deviceList}>
          {Object.keys(devices).map((deviceName) => (
            <ListItem key={deviceName} className={classes.deviceListItem}>
              <ListItemText>
                {t('devices:mappings.create.deviceListDialog.device', {
                  deviceName,
                  count: devices[deviceName],
                })}
              </ListItemText>
              <ListItemIcon>
                <IconButton
                  onClick={handleClickDeleteDevice(deviceName)}
                  size="medium"
                  color="secondary"
                >
                  <Icons.Delete />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </InfoAlert>
      <ConfirmAlert
        open={shouldShowDeleteAlert}
        onClose={handleCancelDeleteDevice}
        onConfirm={handleConfirmDeleteDevice}
        title={t('devices:mappings.create.deleteDeviceAlert.title')}
        message={t('devices:mappings.create.deleteDeviceAlert.message', {
          deviceName: deviceToDelete,
          count: deviceToDelete ? devices[deviceToDelete] : 0,
        })}
        cancelButton={t('general:buttons.cancel')}
        confirmButton={t('general:buttons.delete')}
      />
    </>
  );
};
