import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createClasses } from '@kp/react-ui';
import {
  GridFooterContainer,
  GridToolbarFilterButton,
  GridFooter,
  GridFilterModel,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
  GridRowParams,
  GridActionsCellItem,
  gridExpandedSortedRowIdsSelector,
  GridRowId,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Button, SelectChangeEvent } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../../components/FancyCard';
import { Color } from '../../../../constants/Colors';
import { DataGrid } from '../../../../components/DataGrid';
import { EmptyBanner } from '../../../../components/EmptyBanner';
import { DeviceDropdown, ScanDataDevice } from '../common/DeviceDropdown';
import { useLocaleDateFns } from '../../../../hooks/date-fns';
import {
  BACnetTarget,
  Capability,
  ExistingDevice,
  MappedDatapoint,
} from './MappingStateUtils';
import { ConfirmAlert } from '../../../../components/Alerts';
import { CapabilitySelectionDropdown } from './CapabilitySelectionDropdown';
import { byName } from '../../../../utils/sort';
import { DeviceSelectionDropdown } from './DeviceSelectionDropdown';
import { translateBacnetId } from '../../../../utils/bacnet-device-types';
import { UNITLESS_URI } from '../../../../constants/Misc';

const classes = createClasses({
  card: {
    height: 700,
  },
  subheader: {
    fontSize: '60%',
  },
  banner: {
    margin: 'auto',
  },
  bannerContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  loadingIcon: {
    margin: 0,
    color: Color.lightGrey,
  },
  cellIcon: {
    marginLeft: 10,
  },
  deleteIcon: {
    color: Color.mediumGrey,
  },
});

const isMappableFilterModel = {
  items: [
    {
      field: 'isMappable',
      id: 2,
      operator: 'is',
      value: 'true',
    },
  ],
};

type ToolbarProps = {
  disabled: boolean;
  devices: ScanDataDevice[];
  selectedDeviceIds: string[];
  onFilterChange: (deviceIds: string[]) => void;
};
const Toolbar: React.FC<ToolbarProps> = ({
  disabled,
  devices,
  selectedDeviceIds,
  onFilterChange,
}) => (
  <GridFooterContainer>
    <GridToolbarFilterButton />
    <DeviceDropdown
      disabled={disabled}
      devices={devices}
      selectedDeviceIds={selectedDeviceIds}
      onChange={onFilterChange}
    />
    <GridFooter />
  </GridFooterContainer>
);
const NoRowsOverlay = () => {
  const { t } = useTranslation(['devices', 'general']);
  return (
    <div className={classes.bannerContainer}>
      <EmptyBanner
        className={classes.banner}
        description={t('devices:mappings.edit.scanResult.noData')}
      />
    </div>
  );
};
const CardTitle = ({
  scanFinishDate,
}: {
  scanFinishDate: Date | undefined;
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const { formatDefault } = useLocaleDateFns();

  const titlePostfix = scanFinishDate
    ? ` ${t('devices:mappings.edit.scanResult.from')} ${formatDefault(
        scanFinishDate,
      )}`
    : '';
  return (
    <div>
      {t('devices:mappings.edit.scanResult.title')}
      <span className={classes.subheader}>{titlePostfix}</span>
    </div>
  );
};

type ScanDataWithMappedDatapoints = MappedDatapoint & {
  capabilityOptions?: Capability[];
  deviceOptions?: ExistingDevice[];
  onSelectDevice: (
    target: BACnetTarget,
  ) => (event: SelectChangeEvent<string>) => void;
  onSelectCapability: (
    target: BACnetTarget,
  ) => (event: SelectChangeEvent<string>) => void;
  onDeleteMapping: (
    target: BACnetTarget,
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export type DeviceMappingsScanResultCardProps = {
  loadingAction?: boolean;
  loadingData?: boolean;
  allDevices: ExistingDevice[];
  scanResultWithMappings: MappedDatapoint[];
  onSelectDevice?: (
    bacnetTarget: BACnetTarget,
    device?: ExistingDevice,
  ) => void;
  onSelectCapability?: (
    bacnetTarget: BACnetTarget,
    capabilityId?: string,
  ) => void;
  onDeleteMapping?: (bacnetTarget: BACnetTarget) => void;
  scanFinishDate?: Date;
  onCreateDevice?: () => void;
};
export const DeviceMappingsScanResultCard: React.FC<
  DeviceMappingsScanResultCardProps
> = ({
  loadingAction,
  loadingData,
  allDevices,
  scanResultWithMappings,
  onSelectDevice,
  onSelectCapability,
  onDeleteMapping,
  scanFinishDate,
  onCreateDevice,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const apiRef = useGridApiRef();

  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    isMappableFilterModel,
  );
  const [filterDeviceIds, setFilterDeviceIds] = useState<string[]>([]);

  const [shouldShowDeleteAlert, setShowDeleteAlert] = useState(false);
  const [mappingToDelete, setMappingToDelete] = useState<BACnetTarget>();

  const [activeDatapoint, setActiveDatapoint] = useState<BACnetTarget>();
  const [visibleRows, setVisibleRows] = React.useState<GridRowId[]>([]);

  useEffect(() => {
    const baseItems = (filterModel?.items ?? []).filter(
      (item) => item.id !== 1,
    );
    const newFilterModel = { items: baseItems };
    setFilterModel(newFilterModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scanResultDevices = useMemo(() => {
    const resultDevices: Record<string, ScanDataDevice> = {};
    (scanResultWithMappings ?? []).forEach((d) => {
      resultDevices[d.deviceIdentifier] = {
        id: d.deviceIdentifier,
        name: d.deviceName ?? '',
      };
    });

    return Object.values(resultDevices).sort(byName);
  }, [scanResultWithMappings]);

  const handleSelectCapability = useCallback(
    (target: BACnetTarget) => (event: SelectChangeEvent<string>) => {
      const { value } = event.target;
      if (onSelectCapability) onSelectCapability(target, value);
      setActiveDatapoint(value ? undefined : target);
    },
    [onSelectCapability],
  );
  const handleSelectDevice = useCallback(
    (target: BACnetTarget) => (event: SelectChangeEvent<string>) => {
      const { value } = event.target;
      const device = allDevices?.find((d) => d.id === value);
      if (onSelectDevice) onSelectDevice(target, device);
      setActiveDatapoint(value ? target : undefined);
    },
    [allDevices, onSelectDevice],
  );

  const scrollToActiveDatapoint = useCallback(() => {
    if (activeDatapoint) {
      const rowIndex = visibleRows.indexOf(activeDatapoint.id);
      apiRef.current.scrollToIndexes({ rowIndex });
    }
  }, [activeDatapoint, apiRef, visibleRows]);
  useEffect(() => {
    scrollToActiveDatapoint();
  }, [scrollToActiveDatapoint, visibleRows]);

  const handleFilter = (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleBACnetDeviceFilter = (deviceIds: string[]) => {
    setFilterDeviceIds(deviceIds);
  };

  const handleClickDeleteMapping = useCallback(
    (target: BACnetTarget) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setMappingToDelete(target);
        setShowDeleteAlert(true);
      },
    [],
  );
  const handleCancelDelete = () => setShowDeleteAlert(false);
  const handleConfirmDelete = () => {
    setActiveDatapoint(undefined);
    if (onDeleteMapping && mappingToDelete) onDeleteMapping(mappingToDelete);
    setShowDeleteAlert(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName:
        t('devices:mappings.edit.scanResult.headers.description') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.descriptionDescription') ??
        '',
      flex: 1,
    },
    {
      field: 'deviceIdentifierStr',
      headerName:
        t('devices:mappings.edit.scanResult.headers.deviceIdentifier') ?? '',
      description:
        t(
          'devices:mappings.edit.scanResult.headers.deviceIdentifierDescription',
        ) ?? '',
      flex: 1,
    },
    {
      field: 'deviceName',
      headerName:
        t('devices:mappings.edit.scanResult.headers.deviceName') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.deviceNameDescription') ??
        '',
      flex: 1,
    },
    {
      field: 'objectTypeName',
      headerName:
        t('devices:mappings.edit.scanResult.headers.objectTypeName') ?? '',
      description:
        t(
          'devices:mappings.edit.scanResult.headers.objectTypeNameDescription',
        ) ?? '',
      flex: 1,
    },
    {
      field: 'objectName',
      headerName:
        t('devices:mappings.edit.scanResult.headers.objectName') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.objectNameDescription') ??
        '',
      flex: 1,
    },
    {
      field: 'objectIdentifierStr',
      headerName:
        t('devices:mappings.edit.scanResult.headers.objectIdentifier') ?? '',
      description:
        t(
          'devices:mappings.edit.scanResult.headers.objectIdentifierDescription',
        ) ?? '',
      flex: 1,
    },
    {
      field: 'presentValue',
      headerName:
        t('devices:mappings.edit.scanResult.headers.presentValue') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.presentValueDescription') ??
        '',
      align: 'right',
      flex: 1,
    },
    {
      field: 'unitName',
      headerName: t('devices:mappings.edit.scanResult.headers.unitName') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.unitNameDescription') ?? '',
      flex: 1,
    },
    {
      field: 'lowLimit',
      headerName: t('devices:mappings.edit.scanResult.headers.lowLimit') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.lowLimitDescription') ?? '',
      type: 'number',
      flex: 1,
    },
    {
      field: 'highLimit',
      headerName: t('devices:mappings.edit.scanResult.headers.highLimit') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.highLimitDescription') ??
        '',
      type: 'number',
      flex: 1,
    },
    {
      field: 'isMappable',
      headerName:
        t('devices:mappings.edit.scanResult.headers.isMappable') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.isMappableDescription') ??
        '',
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'mappedDeviceModel',
      headerName:
        t('devices:mappings.edit.scanResult.headers.mappedDeviceModel') ?? '',
      description:
        t(
          'devices:mappings.edit.scanResult.headers.mappedDeviceModelDescription',
        ) ?? '',
      flex: 1,
      valueGetter: (params) => params.row.model?.name,
    },
    {
      field: 'mappedDevice',
      headerName:
        t('devices:mappings.edit.scanResult.headers.mappedDevice') ?? '',
      description:
        t('devices:mappings.edit.scanResult.headers.mappedDeviceDescription') ??
        '',
      flex: 1,
      valueGetter: (params) => params.row.device?.name,
      renderCell: (
        params: GridRenderCellParams<
          ScanDataWithMappedDatapoints,
          ScanDataWithMappedDatapoints
        >,
      ) => {
        if (!params.row.onSelectDevice) {
          return params.row.device?.name;
        }

        return (
          <DeviceSelectionDropdown
            onSelect={params.row.onSelectDevice(params.row)}
            deviceOptions={params.row.deviceOptions}
            selectedDeviceId={params.row.device?.id}
          />
        );
      },
    },
    {
      field: 'mappedCapability',
      headerName:
        t('devices:mappings.edit.scanResult.headers.mappedCapability') ?? '',
      description:
        t(
          'devices:mappings.edit.scanResult.headers.mappedCapabilityDescription',
        ) ?? '',
      flex: 1,
      valueGetter: (params) => params.row.capability?.name,
      renderCell: (
        params: GridRenderCellParams<
          ScanDataWithMappedDatapoints,
          ScanDataWithMappedDatapoints
        >,
      ) => {
        if (
          !params.row.capabilityOptions?.length ||
          !params.row.onSelectCapability
        ) {
          return params.row.capability?.name;
        }
        if (!params.row.model || !params.row.device) {
          return undefined;
        }

        return (
          <CapabilitySelectionDropdown
            onSelect={params.row.onSelectCapability(params.row)}
            capabilityOptions={params.row.capabilityOptions}
            selectedCapabilityId={params.row.capability?.id}
            datapointUnitName={params.row.unitName}
            datapointUnitUris={params.row.unitUris}
          />
        );
      },
    },
    {
      field: 'action',
      type: 'actions',
      getActions: (params: GridRowParams) =>
        params.row.model || params.row.device || params.row.capability
          ? [
              <GridActionsCellItem
                key={params.row.deviceIdentifier + params.row.objectIdentifier}
                icon={<Icons.Delete />}
                onClick={params.row.onDeleteMapping?.(params.row)}
                disabled={!onDeleteMapping}
                label={t('devices:mappings.edit.scanResult.deleteMapping')}
              />,
            ]
          : [],
    },
  ];

  const rows = useMemo(() => {
    const getDeviceOptions = (target: MappedDatapoint) => {
      const targetUnitName = target.unitName;
      const targetUnitUris = target.unitUris;
      const devicesWithUnmappedCapabilities = allDevices.filter(
        (device) =>
          !!device.deviceModel.deviceModelCapabilities.find(
            (dmc) => !dmc.isMapped,
          ),
      );
      const deviceOptions = devicesWithUnmappedCapabilities.filter(
        (device) =>
          !!device.deviceModel.deviceModelCapabilities.find(
            (dmc) =>
              (targetUnitUris?.includes(UNITLESS_URI) ||
                dmc.unitName === targetUnitName) &&
              !dmc.isMapped,
          ),
      );
      // add currently selected device
      if (
        target.device &&
        !deviceOptions.find((d) => d.id === target.device?.id)
      ) {
        deviceOptions.push(target.device);
      }

      return deviceOptions;
    };

    return scanResultWithMappings
      .filter(
        (dp) =>
          !filterDeviceIds.length ||
          filterDeviceIds.includes(dp.deviceIdentifier),
      )
      .map((dp) => {
        const deviceOptions = getDeviceOptions(dp);
        const device = allDevices.find((d) => d.id === dp.device?.id);
        const capabilityOptions = device?.deviceModel.deviceModelCapabilities;
        return {
          ...dp,
          objectIdentifierStr: translateBacnetId(dp.objectIdentifier),
          deviceIdentifierStr: translateBacnetId(dp.deviceIdentifier),
          deviceOptions,
          capabilityOptions,
          onSelectDevice: handleSelectDevice,
          onSelectCapability: handleSelectCapability,
          onDeleteMapping: handleClickDeleteMapping,
        };
      });
  }, [
    allDevices,
    filterDeviceIds,
    scanResultWithMappings,
    handleSelectDevice,
    handleSelectCapability,
    handleClickDeleteMapping,
  ]);

  return (
    <>
      <FancyCard
        loading={loadingAction || loadingData}
        title={<CardTitle scanFinishDate={scanFinishDate} />}
        className={classes.card}
        actions={
          <>
            {onCreateDevice && (
              <Button
                disabled={loadingAction || loadingData}
                onClick={onCreateDevice}
                size="large"
                color="primary"
              >
                {t('devices:mappings.edit.scanResult.addDeviceButton')}
              </Button>
            )}
          </>
        }
      >
        <DataGrid
          loading={loadingData}
          apiRef={apiRef}
          height={620}
          filterModel={filterModel}
          slotProps={{
            toolbar: {
              disabled: loadingData ?? !scanResultWithMappings.length,
              devices: scanResultDevices,
              selectedDeviceIds: filterDeviceIds,
              onFilterChange: handleBACnetDeviceFilter,
            },
          }}
          slots={{
            toolbar: Toolbar,
            noRowsOverlay: NoRowsOverlay,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                deviceIdentifierStr: false,
                objectIdentifierStr: false,
                lowLimit: false,
                highLimit: false,
                isMappable: false,
              },
            },
          }}
          rows={rows}
          columns={columns}
          onFilterModelChange={handleFilter}
          hideFooter
          disableRowSelectionOnClick
          onStateChange={() => {
            setVisibleRows(gridExpandedSortedRowIdsSelector(apiRef));
          }}
        />
      </FancyCard>
      <ConfirmAlert
        open={shouldShowDeleteAlert}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t('devices:mappings.edit.deleteMappingAlert.title')}
        message={t('devices:mappings.edit.deleteMappingAlert.message')}
        cancelButton={t('general:buttons.cancel')}
        confirmButton={t('general:buttons.delete')}
      />
    </>
  );
};
