import React from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useNavigate, useParams } from 'react-router';
import { ErrorMessage } from '../../../components/Errors';
import { useFilters } from '../../../hooks/filters';
import { useSiteDetailsBuildingsQuery } from '../../../__generated__/types';
import { SiteDetailsBuildings } from './SiteDetailsBuildings';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';

export const SiteDetailsBuildingsContainer: React.FC = () => {
  const { page, pageSize, setPage, setPageSize } = useFilters();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();
  const { siteId } = useParams();

  const { loading, error, data } = useSiteDetailsBuildingsQuery({
    variables: {
      take: pageSize,
      skip: page * pageSize,
      where: {
        siteId: { eq: siteId },
      },
    },
  });

  const handleAdd = () =>
    navigate(entityLocation(Entities.BUILDING, `new?siteId=${siteId}`));

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const buildings = data?.buildings?.items ?? [];
  const count = data?.buildings?.totalCount ?? 0;

  return (
    <SiteDetailsBuildings
      onAdd={hasPermission(Permissions.BuildingsWrite) ? handleAdd : undefined}
      loading={loading}
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      buildings={buildings}
    />
  );
};
