import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { v4 as uuid } from 'uuid';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  FieldDeviceFormData,
  FieldDeviceFormInfo,
  getFieldDeviceFormInfoSchema,
} from './FieldDeviceFormInfo';
import {
  DeviceFormLocationProps,
  DeviceFormLocation,
  DeviceFormLocationData,
  getDeviceFormLocationSchema,
} from './DeviceFormLocation';
import {
  DeviceCreateAttributes,
  AttributeSet,
  getDeviceCreateAttributesSchema,
} from './DeviceCreateAttributes';
import { PlacementKind } from '../../../__generated__/types';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  section: {
    '& + &': {
      marginTop: '24px',
    },
  },
});

export type ValidationState = 'LOADING' | 'ERROR' | 'VALID' | 'INVALID';

export type FieldDeviceCreateData = FieldDeviceFormData &
  DeviceFormLocationData & { attributeSets: AttributeSet[] };

interface FieldDeviceCreateProps {
  allSites: DeviceFormLocationProps['sites'];
  allGateways: Array<{ id: string; name: string }>;
  allDeviceModels: Array<{
    id: string;
    name: string;
    isGateway: boolean;
    deviceModelAttributeSets: AttributeSet[];
  }>;
  placementId?: string;
  placementType?: PlacementKind;
  loading?: boolean;
  validateDeviceIdentifier: (deviceIdentifier: string) => void;
  deviceIdentifierValidationState: ValidationState;
  onSubmit: (device: FieldDeviceCreateData) => void;
  onDiscard: () => void;
  buttonsAtBottom?: boolean;
  gatewayId?: string;
  deviceModelId?: string;
  disabledFieldIds?: string[];
}

export const FieldDeviceCreate: React.FC<FieldDeviceCreateProps> = ({
  allSites,
  allGateways,
  allDeviceModels,
  placementId,
  placementType,
  loading,
  validateDeviceIdentifier,
  deviceIdentifierValidationState,
  onSubmit,
  onDiscard,
  buttonsAtBottom,
  gatewayId,
  deviceModelId,
  disabledFieldIds,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const deviceIdentifier = useMemo(uuid, []);

  const validationSchema = getFieldDeviceFormInfoSchema(
    t,
    deviceIdentifierValidationState !== 'INVALID',
  )
    .concat(getDeviceFormLocationSchema(t))
    .concat(getDeviceCreateAttributesSchema(t));

  let initialAttributeSet: AttributeSet[] = [];
  if (deviceModelId) {
    const selectedModel = allDeviceModels.find((dm) => dm.id === deviceModelId);
    const selectedAttributeSets = selectedModel?.deviceModelAttributeSets;
    initialAttributeSet = (selectedAttributeSets ?? [])?.map(
      (attributeSet) => ({
        ...attributeSet,
        attributes: attributeSet.attributes.map((attribute) => ({
          ...attribute,
          enabled: attribute.required,
        })),
      }),
    );
  }
  const defaultValues: FieldDeviceCreateData = {
    name: '',
    description: '',
    serialNo: '',
    deviceIdentifier,
    parentDeviceId: gatewayId ?? '0',
    deviceModelId: deviceModelId ?? '0',
    locationId: placementId ?? '0',
    locationType: placementType,
    attributeSets: initialAttributeSet,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;
  const attributeSets = watch('attributeSets');

  const handleModelSelection = (value: string) => {
    const selectedModel = allDeviceModels.find((dm) => dm.id === value);
    const selectedAttributeSets = selectedModel?.deviceModelAttributeSets;
    setValue('deviceModelId', value);
    setValue(
      'attributeSets',
      (selectedAttributeSets ?? []).map((attributeSet) => ({
        ...attributeSet,
        attributes: attributeSet.attributes.map((attribute) => ({
          ...attribute,
          enabled: attribute.required,
        })),
      })),
      { shouldTouch: false },
    );
    clearErrors('attributeSets');
  };

  const handleDeviceIdentifierValidation = (newDeviceIdentifier: string) => {
    if (newDeviceIdentifier !== undefined) {
      validateDeviceIdentifier(newDeviceIdentifier);
    }
  };

  useEffect(() => {
    if (
      errors.deviceIdentifier?.message === t('devices:form.notUniqueError') &&
      deviceIdentifierValidationState !== 'INVALID'
    ) {
      clearErrors('deviceIdentifier');
    } else if (
      !errors.deviceIdentifier &&
      deviceIdentifierValidationState === 'INVALID'
    ) {
      setError('deviceIdentifier', {
        message: t('devices:form.notUniqueError') ?? '',
      });
    }
  }, [
    deviceIdentifierValidationState,
    errors.deviceIdentifier,
    setError,
    clearErrors,
    t,
  ]);

  return (
    <FormProvider {...methods}>
      {!buttonsAtBottom && (
        <ActionsBar>
          <Button
            onClick={onDiscard}
            color="secondary"
            size="large"
            className={classes.actionButton}
            aria-label="discard-button"
          >
            {t('general:buttons.discard')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className={classes.actionButton}
            color="primary"
            variant="outlined"
            size="large"
            aria-label="save-button"
            disabled={loading}
          >
            {t('general:buttons.save')}
          </Button>
        </ActionsBar>
      )}
      <div className={classes.section}>
        <Grid container spacing={3} alignItems="stretch" direction="row">
          <Grid item xs={12} sm={12} md={8}>
            <FieldDeviceFormInfo
              loading={loading}
              onSelectModel={handleModelSelection}
              validateDeviceIdentifier={handleDeviceIdentifierValidation}
              allGateways={allGateways}
              allDeviceModels={allDeviceModels}
              disabledFieldIds={disabledFieldIds}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <DeviceFormLocation
              type="create"
              sites={allSites}
              loading={loading}
            />
          </Grid>
        </Grid>
      </div>
      {attributeSets && attributeSets?.length > 0 && (
        <div className={classes.section}>
          <DeviceCreateAttributes
            attributeSets={attributeSets}
            loading={loading}
          />
        </div>
      )}
      {buttonsAtBottom && (
        <ActionsBar>
          <Button
            onClick={onDiscard}
            color="secondary"
            size="large"
            className={classes.actionButton}
            aria-label="discard-button"
          >
            {t('general:buttons.discard')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className={classes.actionButton}
            color="primary"
            variant="outlined"
            size="large"
            aria-label="save-button"
            disabled={loading}
          >
            {t('general:buttons.save')}
          </Button>
        </ActionsBar>
      )}
    </FormProvider>
  );
};
