import React, { FormEventHandler, useCallback, useMemo, useState } from 'react';
import { createClasses } from '@kp/react-ui';
import { debounce, IconButton, InputBase, Paper, Tooltip } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const classes = createClasses({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: '10px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  root: {
    padding: '6px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  textInput: {
    fontSize: '16px',
    marginRight: '10px',
    marginLeft: '4px',
    width: '400px',
  },
  loader: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    width: '40px',
    height: '40px',
  },
  resetButton: {
    width: '40px',
    height: '40px',
    opacity: 0,
    transition: `opacity 0.2s`,
  },
  resetButtonActive: {
    opacity: 1,
  },
});

interface DataTableSearchBoxProps {
  onChange?: () => void;
  onSubmit?: (value: string) => void;
  value?: string;
  disabled?: boolean;
}

export const DataTableSearchBox: React.FC<DataTableSearchBoxProps> = ({
  onSubmit,
  value,
  disabled,
}) => {
  const [query, setQuery] = useState(value ?? '');
  const { t } = useTranslation(['general']);

  const submit = useMemo(
    () =>
      debounce((q?: string) => {
        if (onSubmit) {
          onSubmit(q ?? '');
        }
      }, 250),
    [onSubmit],
  );

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (event) => {
      setQuery(event.target.value);
      submit(event.target.value);
    },
    [submit],
  );

  const handleSubmit: FormEventHandler<
    HTMLFormElement | HTMLButtonElement | HTMLDivElement
  > = useCallback(
    (event) => {
      if (onSubmit) {
        onSubmit(query ?? '');
      }
      event.preventDefault();
    },
    [query, onSubmit],
  );

  const handleClear = useCallback(() => {
    if (onSubmit) {
      onSubmit('');
    }
    setQuery('');
  }, [onSubmit]);

  return (
    <div className={classes.wrapper}>
      <Paper
        elevation={1}
        className={classes.root}
        component="form"
        onSubmit={handleSubmit}
      >
        <IconButton
          color="primary"
          disabled={disabled}
          className={classes.iconButton}
          onClick={handleSubmit}
          data-testid="table-search-button"
          type="submit"
        >
          <Icons.SearchOutlined fontSize="medium" />
        </IconButton>
        <InputBase
          fullWidth
          value={query}
          placeholder={t('general:dataTableSearchBox.placeholder') ?? ''}
          name="search"
          autoFocus
          inputProps={{ className: classes.textInput }}
          onChange={handleChange}
        />
        <IconButton
          color="primary"
          disabled={disabled || !query}
          className={classNames(
            classes.resetButton,
            query && classes.resetButtonActive,
          )}
          onClick={handleClear}
          data-testid="table-clear-button"
          type="reset"
        >
          <Icons.Close fontSize="medium" />
        </IconButton>
      </Paper>
      <Tooltip
        arrow
        title={
          <div className={classes.tooltipContent}>
            {t('general:dataTableSearchBox.info')}
          </div>
        }
        className={classes.tooltip}
        placement="right"
      >
        <Icons.Info color="action" />
      </Tooltip>
    </div>
  );
};
