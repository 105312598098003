import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { Loading } from '../../../components/Loading';
import { ScanStatus, translateScanStatus } from '../common/statusTranslation';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  table: {
    height: 132,
  },
  loading: {
    margin: 25,
  },
});

export type DeviceScan = {
  numOfDevices?: number;
  status?: ScanStatus;
  startedAt?: string | null;
  finishedAt?: string | null;
  error?: string | null;
};

export type DeviceScanTableProps = DeviceScan & {
  loading?: boolean;
};

export const DeviceScanTable: React.FC<DeviceScanTableProps> = ({
  loading,
  numOfDevices,
  status,
  startedAt,
  finishedAt,
  error,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const renderValue = (v?: null | number | string) => v || '-';
  return (
    <Table size="small" className={classes.table}>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell>
              <Loading className={classes.loading} />
            </TableCell>
          </TableRow>
        ) : (
          <>
            <TableRow>
              <TableCell>
                {t('devices:discovery.deviceScanTable.numOfDevices')}
              </TableCell>
              <TableCell align="right">{renderValue(numOfDevices)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('devices:discovery.deviceScanTable.status')}
              </TableCell>
              <TableCell align="right">
                {translateScanStatus(status, t)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('devices:discovery.deviceScanTable.startedAt')}
              </TableCell>
              <TableCell align="right">
                <FormattedDate value={startedAt} alt={'-'} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('devices:discovery.deviceScanTable.finishedAt')}
              </TableCell>
              <TableCell align="right">
                <FormattedDate value={finishedAt} alt={'-'} />
              </TableCell>
            </TableRow>
            {error && (
              <TableRow>
                <TableCell>
                  {t('devices:discovery.deviceScanTable.error')}
                </TableCell>
                <TableCell align="right">{renderValue(error)}</TableCell>
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </Table>
  );
};
