import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogProps,
} from '@mui/material';
import { DialogTitle } from './ConfirmAlertDialogTitle';

export type ConfirmOptions = {
  confirmDisabled?: boolean;
};

export type ConfirmAlertProps = DialogProps & {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  options?: ConfirmOptions;
  closeButton?: boolean;
  cancelButton?: string | null;
  confirmButton: string;
};

export const ConfirmAlert: React.FC<ConfirmAlertProps> = ({
  onClose,
  onConfirm,
  title,
  message,
  options,
  children,
  closeButton = false,
  cancelButton,
  confirmButton,
  ...rest
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="confirm-alert-dialog-title"
      aria-describedby="confirm-alert-dialog-description"
      {...rest}
    >
      <DialogTitle
        id="confirm-alert-dialog-title"
        closeButton={closeButton}
        onClose={onClose}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-alert-dialog-description">
          {message}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {cancelButton && (
          <Button onClick={onClose} color="secondary" autoFocus>
            {cancelButton}
          </Button>
        )}
        <Button
          onClick={onConfirm}
          color="primary"
          disabled={options?.confirmDisabled}
        >
          {confirmButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
