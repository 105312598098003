import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  BuildingFormData,
  BuildingFormInfo,
  getBuildingFormInfoSchema,
} from '../common/BuildingFormInfo';
import { AddressFormData, AddressForm } from '../../../components/Address';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  section: {
    '& + &': {
      marginTop: '24px',
    },
  },
});

export type BuildingCreateData = BuildingFormData & AddressFormData;

interface BuildingCreateProps {
  location: BuildingCreateData['location'];
  loading?: boolean;
  onSubmit: (building: BuildingCreateData) => void;
  onDiscard: () => void;
}

export const BuildingCreate: React.FC<BuildingCreateProps> = ({
  location,
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['buildings', 'general']);

  const validationSchema = getBuildingFormInfoSchema(t);
  const defaultValues: BuildingCreateData = {
    name: '',
    description: '',
    dimension: '',
    location,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useDeepCompareEffect(() => {
    reset({
      location,
    });
  }, [reset, location]);
  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <div className={classes.section}>
        <BuildingFormInfo loading={loading} />
      </div>
      <div className={classes.section}>
        <AddressForm loading={loading} optional />
      </div>
    </FormProvider>
  );
};
