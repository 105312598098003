import React from 'react';
import { Grid, Button, Divider } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { SiteDetailsBuildingsContainer } from './SiteDetailsBuildingsContainer';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ActionsBar } from '../../../components/ActionsBar';
import { AddressCard } from '../../../components/Address';

const classes = createClasses({
  description: {
    whiteSpace: 'pre-wrap',
  },
  divider: {
    marginTop: '24px',
    marginBottom: '14px',
  },
});

type SiteDetailsProps = {
  name: string;
  description: string;
  street?: string;
  no?: string;
  zip?: string;
  city?: string;
  country?: string;
  image: { url: string; name: string };
  onDelete?: () => void;
  onEditInfo?: () => void;
  onEditLocation?: () => void;
  loading?: boolean;
  showBuildings?: boolean;
};

export const SiteDetails: React.FC<SiteDetailsProps> = ({
  name,
  description,
  image,
  street,
  no,
  zip,
  city,
  country,
  onDelete,
  onEditInfo,
  onEditLocation,
  loading,
  showBuildings,
}) => {
  const { t } = useTranslation(['sites', 'general']);

  return (
    <>
      {onDelete && (
        <ActionsBar>
          {!loading && (
            <Button color="primary" size="large" onClick={onDelete}>
              {t('sites:details.actions.delete')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12} sm={12} md={8}>
              <FancyCard
                title={t('sites:details.info.title')}
                onEdit={onEditInfo}
                image={image}
                loading={loading}
                fullHeight
              >
                <InfoList>
                  <InfoListItem term={t('sites:details.info.name')}>
                    {name}
                  </InfoListItem>
                  <InfoListItem
                    term={t('general:fields.description')}
                    className={classes.description}
                  >
                    {description}
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AddressCard
                city={city}
                street={street}
                no={no}
                zip={zip}
                country={country}
                onEdit={onEditLocation}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showBuildings && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Divider className={classes.divider} />
            <SiteDetailsBuildingsContainer />
          </Grid>
        </Grid>
      )}
    </>
  );
};
