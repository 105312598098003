import React from 'react';
import { DeviceDetailsInfo } from './DeviceDetailsInfo';
import { GatewayStatus } from '../../../hooks/useGatewayStatusSubscription';

interface DeviceDetailsInfoContainerProps {
  onEdit?: () => void;
  imageUrl: string;
  name: string;
  description: string;
  deviceIdentifier?: string;
  serialNumber: string;
  parentDevice?: { id: string; name: string };
  deviceModel?: { id: string; name: string };
  loading?: boolean;
  isLocked: boolean;
  isGateway: boolean;
  gatewayStatus: GatewayStatus;
}

export const DeviceDetailsInfoContainer: React.FC<
  DeviceDetailsInfoContainerProps
> = ({
  onEdit,
  imageUrl,
  name,
  description,
  deviceIdentifier,
  serialNumber,
  parentDevice,
  deviceModel,
  loading,
  isLocked,
  isGateway,
  gatewayStatus,
}) => {
  return (
    <DeviceDetailsInfo
      loading={loading}
      isLocked={isLocked}
      onEdit={onEdit}
      deviceIdentifier={deviceIdentifier}
      imageUrl={imageUrl}
      name={name}
      description={description}
      serialNumber={serialNumber}
      parentDevice={parentDevice}
      deviceModel={deviceModel}
      gatewayStatus={isGateway ? gatewayStatus : undefined}
    />
  );
};
