import React from 'react';
import { useParams } from 'react-router';
import { ErrorMessage } from '../../../components/Errors';
import { useFilters } from '../../../hooks/filters';
import { useBuildingDetailsDevicesQuery } from '../../../__generated__/types';
import { BuildingDetailsDevices } from './BuildingDetailsDevices';

export const BuildingDetailsDevicesContainer: React.FC = () => {
  const { page, pageSize, setPage, setPageSize } = useFilters('device');
  const { buildingId } = useParams();

  const { error, data, loading, previousData } = useBuildingDetailsDevicesQuery(
    {
      variables: {
        take: pageSize,
        skip: page * pageSize,
        where: {
          and: [{ buildingId: { eq: buildingId } }, { zoneId: { eq: null } }],
        },
      },
    },
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const devices = (data?.devicesByBuildings?.items ?? []).map(
    (deviceByBuilding) => deviceByBuilding?.device,
  );
  const count = data?.devicesByBuildings?.totalCount ?? 0;

  return (
    <BuildingDetailsDevices
      loading={loading && !!previousData?.devicesByBuildings?.items?.length}
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      devices={devices}
    />
  );
};
