/**
 * Response coming from the microservice that is paginated
 */
export interface PaginatedResponse<T> {
  meta: {
    currLimit: number;
    currSkip: number;
    currSize: number;
    totalSize: number;
  };
  data: T[];
}

/**
 * Response coming from the microservice that is not paginated
 */
export interface EntityResponse<T> {
  data: T;
}

/**
 * Pagination filter
 */
export interface PaginationFilter {
  skip?: number;
  limit?: number;
}

/**
 * Sorting directions
 */
export enum SortDirections {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 * Sorting filter
 */
export interface SortingFilter<T> extends PaginationFilter {
  sort?: T;
  sortDir?: SortDirections;
}

/**
 * Conditional filter
 */
export interface ConditionalFilter<T> {
  filter?: T;
}
