import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface SelectFieldOption {
  id: string;
  label: string;
}

interface SelectFieldProps extends SelectProps {
  name: string;
  label: string;
  emptyLabel?: string | null;
  options: SelectFieldOption[];
  error?: boolean;
  helperText?: JSX.Element;
  fullWidth?: boolean;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  emptyLabel,
  options,
  fullWidth,
  helperText,
  labelId,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl variant="standard" fullWidth={fullWidth} error={!!error}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
              {...field}
              fullWidth={fullWidth}
              renderValue={(selectedId) =>
                options.find(({ id }) => id === selectedId)?.label ?? emptyLabel
              }
              labelId={labelId}
              {...rest}
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
            <FormHelperText>{error ? error.message : null}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
