import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { GRAPHQL_URI } from '../utils/env';

const WEBSOCKET_RETRIES = 1000;
const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
});

const subscriptionUrl = `${GRAPHQL_URI.replace('http', 'ws')}${
  GRAPHQL_URI.endsWith('/') ? '' : '/'
}subscription/`;

const authLink = setContext((_, { headers }) => {
  // eslint-disable-next-line no-underscore-dangle
  const token = window.__AUTH_TOKEN__;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: subscriptionUrl,
    lazy: true,
    lazyCloseTimeout: 5,
    retryAttempts: WEBSOCKET_RETRIES,
    retryWait: (retries) => {
      const delay = Math.min(1000 * 2 ** retries, 30000);
      if (retries >= WEBSOCKET_RETRIES - 1) {
        console.error('Socket Closed. Retry limit reached.');
      } else {
        console.warn('Socket Closed. Retrying in', delay, 'ms');
      }
      return new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
    },
    shouldRetry: () => true,
    connectionParams: () => {
      // eslint-disable-next-line no-underscore-dangle
      const token = window.__AUTH_TOKEN__;
      return {
        authorization: token ? `Bearer ${token}` : '',
      };
    },
  }),
);

const createSplitLink = () =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

const apolloClient = new ApolloClient({
  link: createSplitLink(),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

type GraphQLProviderProps = {
  children: React.ReactNode;
};
export const GraphQLProvider: React.FC<GraphQLProviderProps> = ({
  children,
}) => <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
