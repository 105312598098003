export type BACnetTarget = {
  id: string;
  deviceIdentifier: string;
  deviceName?: string;
  objectTypeName?: string;
  objectIdentifier: string;
  objectName?: string;
  description?: string;
  unitName?: string;
  unitUris?: string[];
  unitUri?: string;
  lowLimit?: number;
  highLimit?: number;
  units?: number;
};

export type Capability = {
  id: string;
  name: string;
  isMapped: boolean;
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
};
export type DeviceModel = {
  id: string;
  name: string;
  deviceModelCapabilities: Capability[];
};

export type ExistingDevice = {
  id: string;
  name: string;
  deviceModelId: string;
  deviceModel: DeviceModel;
};

export type MappedDatapoint = BACnetTarget & {
  model?: DeviceModel;
  device?: ExistingDevice;
  capability?: Capability;
};

type DeviceModelCapabilityFromAPI = {
  id: string;
  technicalMax?: number | null;
  technicalMin?: number | null;
  capability: {
    id: string;
    name: string;
    description?: string | null;
  };
  unit: {
    id: string;
    name: string;
    unitSymbol?: string | null;
    uris?: Array<string> | null;
  };
};
type DeviceModelFromAPI = {
  id: string;
  name: string;
  deviceModelCapabilities: Array<DeviceModelCapabilityFromAPI>;
};

type DeviceFromAPI = {
  id: string;
  name: string;
  deviceModelId: string;
  deviceModel: DeviceModelFromAPI;
};

export const findByTarget = (
  targets: MappedDatapoint[],
  target: BACnetTarget,
) =>
  targets.find(
    (t) =>
      t.deviceIdentifier === target.deviceIdentifier &&
      t.objectIdentifier === target.objectIdentifier,
  );
export const findIndexByTarget = (
  targets: BACnetTarget[],
  target: BACnetTarget,
) =>
  targets.findIndex(
    (t) =>
      t.deviceIdentifier === target.deviceIdentifier &&
      t.objectIdentifier === target.objectIdentifier,
  );

const mapDeviceModelCapability = (
  capability: DeviceModelCapabilityFromAPI,
) => ({
  id: capability.id,
  name: capability.capability.name,
  isMapped: false,
  technicalMin: capability.technicalMin ?? undefined,
  technicalMax: capability.technicalMax ?? undefined,
  unitName: capability.unit?.name,
  unitSymbol: capability.unit?.unitSymbol ?? '',
  unitUris: capability.unit?.uris ?? [],
});

const mapDeviceModel = (deviceModel: DeviceModelFromAPI): DeviceModel => {
  return {
    id: deviceModel.id,
    name: deviceModel.name,
    deviceModelCapabilities: (deviceModel.deviceModelCapabilities ?? []).map(
      mapDeviceModelCapability,
    ),
  };
};

export const mapDevice = (device: DeviceFromAPI): ExistingDevice => {
  return { ...device, deviceModel: mapDeviceModel(device.deviceModel) };
};

export const setCapabilityMapping = (
  allDevices: ExistingDevice[],
  target: MappedDatapoint,
  selectedCapabilityId?: string,
) => {
  const newAllDevices = [...allDevices];
  const allDevicesIndex = newAllDevices.findIndex(
    (d) => d.id === target.device?.id,
  );
  const allDevicesDevice = newAllDevices[allDevicesIndex];
  const deviceCapabilities =
    allDevicesDevice.deviceModel.deviceModelCapabilities;
  const mappedCapabilities = deviceCapabilities.map((c) => {
    const isMapped =
      c.id === selectedCapabilityId || c.id === target.capability?.id
        ? !c.isMapped
        : c.isMapped;
    return {
      ...c,
      isMapped,
    };
  });
  newAllDevices[allDevicesIndex] = {
    ...allDevicesDevice,
    deviceModel: {
      ...allDevicesDevice.deviceModel,
      deviceModelCapabilities: mappedCapabilities,
    },
  };
  return newAllDevices;
};

export const setCapabilityMappingsFromState = (
  allDevices: ExistingDevice[],
  mappingState: MappedDatapoint[],
) => {
  return allDevices.map((d) => {
    const mappingsForDevice = mappingState.filter(
      (dp) => dp.device?.id === d.id,
    );
    const deviceCapabilities = d.deviceModel.deviceModelCapabilities;
    const mappedCapabilities = deviceCapabilities.map((c) => ({
      ...c,
      isMapped: !!mappingsForDevice.find(
        (mapping) => mapping.capability?.id === c.id,
      ),
    }));
    return {
      ...d,
      deviceModel: {
        ...d.deviceModel,
        deviceModelCapabilities: mappedCapabilities,
      },
    };
  });
};
