import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import { AttributeListContainer } from './list/AttributeListContainer';
import { AttributeDetailsContainer } from './details/AttributeDetailsContainer';
import { AttributeCreateContainer } from './create/AttributeCreateContainer';
import { AttributeEditContainer } from './edit/AttributeEditContainer';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.ATTRIBUTES.icon;

export const AttributesRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<AttributeListContainer />} />
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<AttributeCreateContainer />}
            permission={Permissions.AttributesWrite}
          />
        }
      />
      <Route path="/:attributeId" element={<AttributeDetailsContainer />} />
      <Route
        path="/:attributeId/edit"
        element={
          <ProtectedRoute
            element={<AttributeEditContainer />}
            permission={Permissions.AttributesWrite}
          />
        }
      />
    </Routes>
  );
};
