import React from 'react';
import { ApolloError } from '@apollo/client';
import { DeviceModelDelete } from './DeviceModelDelete';
import { useDeviceModelDeleteMutation } from '../../../__generated__/types';

interface DeviceModelDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const DeviceModelDeleteContainer: React.FC<
  DeviceModelDeleteContainerProps
> = ({ id, isOpen, onCompleted, onError, onCancel }) => {
  const [deleteDeviceModel, { loading, error }] = useDeviceModelDeleteMutation({
    onCompleted,
    onError,
  });

  const handleConfirm = () =>
    deleteDeviceModel({
      variables: { deviceModelId: id },
    });

  return (
    <DeviceModelDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
