import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { DataTable } from '../../../components/DataTable';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { Link } from '../../../components/Link';
import { EmptyBanner } from '../../../components/EmptyBanner';

type Device = {
  id: string;
  name: string;
  deviceModel: { name: string };
};
export type ZoneDetailsDevicesProps = {
  devices: Array<Device>;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onAdd?: (type: 'gateway' | 'fielddevice') => void;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
};

export const ZoneDetailsDevices: React.FC<ZoneDetailsDevicesProps> = ({
  devices,
  onChangePageSize,
  onChangePage,
  onAdd,
  page,
  pageSize,
  count,
  loading,
}) => {
  const { t } = useTranslation(['zones', 'general']);

  const handleAdd = (type: 'gateway' | 'fielddevice') => () => {
    if (onAdd) onAdd(type);
  };
  const headers = [
    {
      key: t('zones:details.devicesList.headers.name'),
      value: t('zones:details.devicesList.headers.name'),
    },
    {
      key: t('zones:details.devicesList.headers.deviceModel'),
      value: t('zones:details.devicesList.headers.deviceModel'),
    },
  ];

  const rows = (devices ?? []).map((device) => ({
    key: `${device.id}`,
    cells: [
      {
        key: `${device.id}-cell-name`,
        element: (
          <Link to={entityLocation(Entities.DEVICE, device.id)}>
            {device.name}
          </Link>
        ),
      },
      {
        key: `${device.id}-cell-deviceModel`,
        element: device.deviceModel.name,
        isLast: false,
      },
    ],
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        {!loading && (!devices || !devices.length) ? (
          <EmptyBanner
            description={t('zones:details.devices.empty')}
            actionLabel={t('zones:details.devices.addFieldDevice')}
            onClickAction={handleAdd('fielddevice')}
          />
        ) : (
          <DataTable
            actions={
              onAdd && (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    startIcon={<Icons.Add />}
                    onClick={handleAdd('gateway')}
                  >
                    {t('zones:details.devices.addGateway')}
                  </Button>
                  <Divider> </Divider>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    startIcon={<Icons.Add />}
                    onClick={handleAdd('fielddevice')}
                  >
                    {t('zones:details.devices.addFieldDevice')}
                  </Button>
                </>
              )
            }
            title={t('zones:details.devices.title')}
            loading={loading}
            headers={headers}
            rows={rows}
            showPagination
            page={page}
            pageSize={pageSize}
            count={count}
            onChangePage={onChangePage}
            onChangePageSize={onChangePageSize}
          />
        )}
      </Grid>
    </Grid>
  );
};
