import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Grid, Button, Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DescriptionField,
  TextField,
  makeOptional,
} from '../../../components/Fields';
import { FancyCard } from '../../../components/FancyCard';
import { DataTypeSelect } from '../../../components/DataTypeSelect';
import { ActionsBar } from '../../../components/ActionsBar';
import { DataTypes } from '../../../__generated__/types';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  actionButton: {
    marginLeft: '8px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  warningIcon: {
    marginRight: '8px',
    display: 'block',
  },
});

export type CapabilityData = {
  capabilityName: string;
  dataType: DataTypes | null;
  description?: string;
};

type CapabilityFormProps = CapabilityData & {
  loading?: boolean;
  type: 'edit' | 'create';
  onSubmit: (capability: CapabilityData) => void;
  onDiscard: () => void;
};

export const CapabilityForm: React.FC<CapabilityFormProps> = ({
  type,
  capabilityName,
  description,
  onSubmit,
  dataType,
  onDiscard,
  loading,
}) => {
  const { t } = useTranslation(['capabilities', 'general']);

  const validationSchema = Yup.object().shape({
    capabilityName: Yup.string()
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
      .required(t('general:errors.required') ?? ''),
  });

  const defaultValues: CapabilityData = {
    capabilityName,
    description,
    dataType,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { reset, handleSubmit } = methods;
  useEffect(() => {
    reset({ capabilityName, description, dataType });
  }, [reset, capabilityName, description, dataType]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="stretch" direction="row">
          <Grid item xs={12} sm={12} md={type === 'create' ? 8 : 12}>
            <FancyCard title={t('capabilities:form.title')} loading={loading}>
              <Grid container spacing={1} className={classes.cardContent}>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={loading}
                    id="capabilityName"
                    name="capabilityName"
                    label={t('capabilities:form.capabilityName')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <DescriptionField
                    disabled={loading}
                    id="description"
                    name="description"
                  />
                </Grid>
              </Grid>
            </FancyCard>
          </Grid>
          {type === 'create' && (
            <Grid item xs={12} sm={12} md={4}>
              <FancyCard
                title={t('capabilities:details.settings.title')}
                loading={loading}
                actions={
                  <Tooltip
                    arrow
                    placement="top-end"
                    title={
                      <div className={classes.tooltipContent}>
                        {t('capabilities:details.settings.disable')}
                      </div>
                    }
                    aria-label="non-editable-tooltip"
                  >
                    <Icons.Warning
                      className={classes.warningIcon}
                      fontSize="medium"
                      htmlColor={Color.warning}
                    />
                  </Tooltip>
                }
              >
                <Grid container spacing={1} className={classes.cardContent}>
                  <Grid item xs={12} md={12}>
                    <DataTypeSelect
                      disabled={loading}
                      id="dataType"
                      name="dataType"
                      allowedValues={[
                        DataTypes.NotSpecified,
                        DataTypes.Integer,
                        DataTypes.Decimal,
                        DataTypes.Bool,
                        DataTypes.Double,
                        DataTypes.Float,
                      ]}
                      fullWidth
                      label={makeOptional(t, t('capabilities:form.dataType'))}
                    />
                  </Grid>
                </Grid>
              </FancyCard>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
};
