import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { RegisteredApplicationsListContainer } from './list/RegisteredApplicationsListContainer';
import { RegisteredApplicationDetailsContainer } from './details/RegisteredApplicationDetailsContainer';
import { RegisteredApplicationCreateContainer } from './create/RegisteredApplicationCreateContainer';
import { RegisteredApplicationEditContainer } from './edit/RegisteredApplicationEditContainer';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.DEVELOPMENT.icon;

export const RegisteredApplicationsRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<RegisteredApplicationsListContainer />} />
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<RegisteredApplicationCreateContainer />}
            permission={Permissions.RegisteredApplicationsWrite}
          />
        }
      />
      <Route
        path="/:applicationId"
        element={<RegisteredApplicationDetailsContainer />}
      />
      <Route
        path="/:applicationId/edit"
        element={
          <ProtectedRoute
            element={<RegisteredApplicationEditContainer />}
            permission={Permissions.RegisteredApplicationsWrite}
          />
        }
      />
    </Routes>
  );
};
