import React from 'react';
import { countries } from 'countries-list';
import {
  Autocomplete,
  InputBaseComponentProps,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type CountryKey = keyof typeof countries;
type Option = CountryKey | '';

type CountryPickerProps = {
  id: string;
  name: string;
  label: string;
  disabled?: boolean;
};

export const CountryPicker: React.FC<CountryPickerProps> = ({
  id,
  name,
  label,
  disabled = false,
}) => {
  const options = [''].concat(Object.keys(countries)) as Option[];
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <Autocomplete
          id={id}
          disabled={disabled}
          {...field}
          onChange={(_, data) => onChange(data ?? '')}
          getOptionLabel={(option: Option) =>
            option ? `${countries[option].emoji} ${countries[option].name}` : ''
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={label}
                inputProps={
                  {
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  } as InputBaseComponentProps
                }
                InputLabelProps={{ shrink: true }}
              />
            );
          }}
          autoHighlight
          options={options}
        />
      )}
    />
  );
};
