import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { createClasses } from '@kp/react-ui';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  Tooltip,
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataTypeSelect } from '../../../components/DataTypeSelect';
import { FancyCard } from '../../../components/FancyCard';
import { ActionsBar } from '../../../components/ActionsBar';
import { DataTypes } from '../../../__generated__/types';
import {
  DescriptionField,
  makeOptional,
  TextField,
} from '../../../components/Fields';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  formControlLabel: {
    top: 8,
    left: -14,
  },
  actionButton: {
    marginLeft: '8px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  warningIcon: {
    marginRight: '8px',
    display: 'block',
  },
});

export type AttributeData = {
  attributeName: string;
  description?: string;
  dataType: DataTypes | null;
  required: boolean;
};

type AttributeFormProps = AttributeData & {
  loading?: boolean;
  type: 'edit' | 'create';
  onSubmit: (attribute: AttributeData) => void;
  onDiscard: () => void;
};

export const AttributeForm: React.FC<AttributeFormProps> = ({
  attributeName,
  description,
  dataType,
  required,
  onSubmit,
  onDiscard,
  type,
  loading,
}) => {
  const { t } = useTranslation(['attributes', 'general']);

  const [toggle, setToggle] = React.useState(required);

  const validationSchema = Yup.object().shape({
    attributeName: Yup.string()
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
      .required(t('general:errors.required') ?? ''),
  });

  const defaultValues: AttributeData = {
    attributeName,
    description,
    dataType,
    required,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { reset, handleSubmit, setValue } = methods;
  useEffect(() => {
    reset({ attributeName, description, dataType, required });
  }, [reset, attributeName, description, dataType, required]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="stretch" direction="row">
          <Grid item xs={12} sm={12} md={type === 'create' ? 8 : 12}>
            <FancyCard
              title={t('attributes:details.info.title')}
              loading={loading}
            >
              <Grid container spacing={1} className={classes.cardContent}>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={loading}
                    id="attributeName"
                    name="attributeName"
                    label={t('attributes:form.attributeName')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <DescriptionField
                    disabled={loading}
                    id="description"
                    name="description"
                    label={t('general:fields.description')}
                    multiline
                  />
                </Grid>
              </Grid>
            </FancyCard>
          </Grid>
          {type === 'create' && (
            <Grid item xs={12} sm={12} md={4}>
              <FancyCard
                title={t('attributes:details.settings.title')}
                loading={loading}
                actions={
                  <Tooltip
                    arrow
                    placement="top-end"
                    title={
                      <div className={classes.tooltipContent}>
                        {t('attributes:details.settings.disable')}
                      </div>
                    }
                    aria-label="non-editable-tooltip"
                  >
                    <Icons.Warning
                      className={classes.warningIcon}
                      fontSize="medium"
                      htmlColor={Color.warning}
                      data-testid="warning-icon"
                    />
                  </Tooltip>
                }
              >
                <Grid container spacing={1} className={classes.cardContent}>
                  <Grid item xs={12} md={12}>
                    <DataTypeSelect
                      disabled={loading}
                      id="dataType"
                      name="dataType"
                      allowedValues={[
                        DataTypes.NotSpecified,
                        DataTypes.Integer,
                        DataTypes.Decimal,
                        DataTypes.Bool,
                        DataTypes.String,
                        DataTypes.Date,
                        DataTypes.Datetime,
                      ]}
                      fullWidth
                      label={makeOptional(t, t('attributes:form.dataType'))}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <InputLabel shrink className={classes.formControlLabel}>
                        {t('general:forms.required')}
                      </InputLabel>
                      <FormControlLabel
                        className={classes.formControl}
                        control={
                          <Switch
                            disabled={loading}
                            id="required"
                            name="required"
                            type="checkbox"
                            color="primary"
                            checked={toggle}
                            onChange={() => {
                              setToggle(!toggle);
                              setValue('required', !toggle);
                            }}
                          />
                        }
                        label={String(
                          toggle
                            ? t('general:forms.required')
                            : t('general:forms.optional'),
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </FancyCard>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
};
