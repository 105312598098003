import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { ActionsBar } from '../../../components/ActionsBar';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { AddressCard } from '../../../components/Address';
import { Address } from '../../../utils/helpers';

const classes = createClasses({
  divider: {
    marginTop: '24px',
    marginBottom: '14px',
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
});

type TenantDetailsProps = {
  id: string;
  name: string;
  slug: string;
  contactName: string;
  email: string;
  address: Address;
  onDelete?: () => void;
  onEditInfo?: () => void;
  onEditLocation?: () => void;
  loading?: boolean;
};

export const TenantDetails: React.FC<TenantDetailsProps> = ({
  name,
  slug,
  contactName,
  email,
  address,
  onDelete,
  onEditInfo,
  onEditLocation,
  loading,
}) => {
  const { t } = useTranslation(['tenants', 'general']);
  return (
    <>
      <ActionsBar>
        {!loading && (
          <Button color="primary" size="large" onClick={onDelete}>
            {t('tenants:details.tenantActions.delete')}
          </Button>
        )}
      </ActionsBar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12} sm={12} md={8}>
              <FancyCard
                title={t('tenants:details.info.title')}
                onEdit={onEditInfo}
                loading={loading}
                fullHeight
              >
                <InfoList>
                  <InfoListItem
                    term={t('tenants:details.info.name')}
                    className={classes.text}
                  >
                    {name}
                  </InfoListItem>
                  <InfoListItem
                    term={t('tenants:details.info.slug')}
                    className={classes.text}
                  >
                    {slug}
                  </InfoListItem>
                  <InfoListItem
                    term={t('tenants:details.info.contact')}
                    className={classes.text}
                  >
                    {contactName}
                  </InfoListItem>
                  <InfoListItem
                    term={t('tenants:details.info.email')}
                    className={classes.text}
                  >
                    {email}
                  </InfoListItem>
                </InfoList>
              </FancyCard>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AddressCard
                title={t('tenants:details.address')}
                city={address?.city ?? ''}
                street={address?.street ?? ''}
                no={address?.no ?? ''}
                zip={address?.zip ?? ''}
                country={address?.country ?? ''}
                onEdit={onEditLocation}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
