import React from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeOptional } from '../Fields';

interface DataTypeInputIntegerProps {
  disabled?: boolean;
  value: string;
  id: string;
  name: string;
  label?: string;
  className?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

export const DataTypeInputInteger = React.forwardRef<
  HTMLInputElement,
  DataTypeInputIntegerProps
>(
  (
    {
      disabled,
      value,
      id,
      name,
      label,
      className,
      onChange,
      autoFocus = true,
      error,
      helperText,
      optional,
    },
    ref,
  ) => {
    const { t } = useTranslation(['general']);
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
      onChange(event.target.value);

    return (
      <NumericFormat
        getInputRef={ref}
        fullWidth
        value={value}
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={handleChange}
        customInput={TextField}
        className={className}
        id={id}
        name={name}
        decimalScale={0}
        label={optional ? makeOptional(t, label) : label}
        InputLabelProps={{
          shrink: true,
        }}
        error={error}
        helperText={helperText}
      />
    );
  },
);

DataTypeInputInteger.displayName = 'DataTypeInputInteger';
