import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import {
  useSiteCreateMutation,
  useSiteCreateLocationCreateMutation,
  useSiteCreateBuildingMutation,
} from '../../../__generated__/types';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { SiteCreateSuccessDialog } from './SiteCreateSuccessDialog';
import { SiteCreateData, SiteCreate } from './SiteCreate';
import { useHeader } from '../../../contexts/header-context';

export const SiteCreateContainer: React.FC = () => {
  const { t } = useTranslation(['sites', 'general']);
  const navigate = useNavigate();
  const [createdSiteId, setCreatedSiteId] = useState<null | string>(null);
  const [locationData, setLocationData] = useState<
    null | SiteCreateData['location']
  >(null);
  const [name, setName] = useState<null | string>(null);
  const [description, setDescription] = useState<null | string>(null);
  const { setTitle } = useHeader();

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.SITE,
        id: '/new',
        name: t('sites:breadcrumb.create'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('sites:breadcrumb.create') });
  }, [setTitle, t]);

  const [
    createLocation,
    { loading: loadingLocationCreate, error: errorLocationCreate },
  ] = useSiteCreateLocationCreateMutation();

  const [createSite, { loading: loadingCreate, error: errorCreate }] =
    useSiteCreateMutation({
      onCompleted: (result) => {
        setCreatedSiteId(result.createSite?.id ?? null);
      },
    });

  const [
    createBuilding,
    { loading: loadingCreateBuilding, error: errorCreateBuilding },
  ] = useSiteCreateBuildingMutation({
    onCompleted: (result) => {
      navigate(
        entityLocation(Entities.BUILDING, result.createBuilding?.id ?? '0'),
      );
    },
  });

  const handleDiscard = () => {
    navigate(entityLocation(Entities.SITE, ''));
  };

  const handleCreateBuilding = () => {
    if (locationData && createdSiteId && name) {
      createLocation({
        variables: { locationInput: { ...locationData, county: '' } },
      })
        .then((result) => result.data?.createLocation?.id)
        .then((locationId) =>
          createBuilding({
            variables: {
              building: {
                siteId: createdSiteId,
                name,
                description,
                ...(locationId ? { locationId } : {}),
              },
            },
          }),
        )
        .catch((e) => console.warn(e));
    }
  };

  const handleCreate = (data: SiteCreateData) => {
    setLocationData(data.location);
    setName(data.name);
    setDescription(data.description ?? '');
    return createLocation({
      variables: { locationInput: { ...data.location, county: '' } },
    })
      .then((result) =>
        createSite({
          variables: {
            site: {
              name: data.name,
              description: data.description,
              locationId: result.data?.createLocation?.id,
            },
          },
        }),
      )
      .catch((e) => console.warn(e));
  };

  return (
    <>
      <SiteCreate
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
        loading={loadingCreate || loadingLocationCreate}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorCreate, errorLocationCreate, errorCreateBuilding]}
      />
      {createdSiteId && (
        <SiteCreateSuccessDialog
          onConfirm={handleCreateBuilding}
          onDiscard={() => {
            navigate(entityLocation(Entities.SITE, createdSiteId));
          }}
          loading={loadingCreateBuilding}
        />
      )}
    </>
  );
};
