import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import { UnitListContainer } from './list/UnitListContainer';
import { UnitDetailsContainer } from './details/UnitDetailsContainer';
import { UnitCreateContainer } from './create/UnitCreateContainer';
import { UnitEditContainer } from './edit/UnitEditContainer';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.UNITS.icon;

export const UnitRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<UnitListContainer />} />
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<UnitCreateContainer />}
            permission={Permissions.UnitsWrite}
          />
        }
      />
      <Route path="/:unitId" element={<UnitDetailsContainer />} />
      <Route
        path="/:unitId/edit"
        element={
          <ProtectedRoute
            element={<UnitEditContainer />}
            permission={Permissions.UnitsWrite}
          />
        }
      />
    </Routes>
  );
};
