import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import { ErrorMessage } from '../../../components/Errors';
import {
  useBuildingEditDetailsQuery,
  useBuildingUpdateMutation,
} from '../../../__generated__/types';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useHeader } from '../../../contexts/header-context';
import { BuildingEdit, BuildingEditData } from './BuildingEdit';
import { NotFound } from '../../errorScreens';

export const BuildingEditContainer: React.FC = () => {
  const { t } = useTranslation(['buildings', 'general']);
  const navigate = useNavigate();
  const { buildingId } = useParams();
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useBuildingEditDetailsQuery({
    variables: { buildingId },
  });
  const building = data?.building;

  const hierarchy = building?.site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: building.site.id,
            name: building.site.name,
          },
          {
            type: Entities.BUILDING,
            id: buildingId,
            name: building.name,
          },
        ],
        t,
      ).concat({
        title: t('buildings:breadcrumb.edit'),
        location: entityLocation(Entities.BUILDING, `${buildingId}/edit`),
      })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('buildings:breadcrumb.edit') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  const [update, { loading: loadingUpdate, error: errorUpdate }] =
    useBuildingUpdateMutation({
      onCompleted: () =>
        navigate(entityLocation(Entities.BUILDING, buildingId)),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: BuildingEditData) => {
    if (!building?.id) {
      return;
    }
    // eslint-disable-next-line no-void
    void update({ variables: { building: { id: building.id, ...inputData } } });
  };

  const handleDiscard = () => {
    navigate(entityLocation(Entities.BUILDING, buildingId));
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !building) {
    return (
      <NotFound
        title={t('buildings:details.notFound.title') ?? ''}
        subtitle={t('buildings:details.notFound.subtitle') ?? ''}
        buttonText={t('buildings:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.BUILDING.path)}
      />
    );
  }

  return (
    <>
      <BuildingEdit
        loading={loading || loadingUpdate}
        name={building?.name ?? ''}
        description={building?.description ?? ''}
        dimension={building?.dimension ?? ''}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
