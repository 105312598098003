import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { UsersListContainer } from './list/UsersListContainer';
import { UserDetailsContainer } from './details/UserDetailsContainer';
import { UserEditContainer } from './edit/UserEditContainer';
import { UserInvitationsListContainer } from './invitations/list/UserInvitationsListContainer';
import { UserInvitationCreateContainer } from './invitations/create/UserInvitationCreateContainer';
import { UserInvitationDetailsContainer } from './invitations/details/UserInvitationDetailsContainer';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.USERS.icon;

export const UsersRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<UsersListContainer />} />
      <Route
        path="/:userId"
        element={
          <ProtectedRoute
            element={<UserDetailsContainer />}
            permission={Permissions.UserDetailsRead}
          />
        }
      />
      <Route path="/:userId/edit" element={<UserEditContainer />} />
      <Route
        path="/invitations/"
        element={
          <ProtectedRoute
            element={<UserInvitationsListContainer />}
            permission={Permissions.InvitationsRead}
          />
        }
      />
      <Route
        path="/invitations/new"
        element={
          <ProtectedRoute
            element={<UserInvitationCreateContainer />}
            permission={Permissions.InvitationsWrite}
          />
        }
      />
      <Route
        path="/invitations/:userInvitationId"
        element={
          <ProtectedRoute
            element={<UserInvitationDetailsContainer />}
            permission={Permissions.InvitationsRead}
          />
        }
      />
    </Routes>
  );
};
