import React, { useEffect, useMemo } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useHeader } from '../../../contexts/header-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { UsersList } from './UsersList';
import { useFilters } from '../../../hooks/filters';
import { getUsers } from '../../../api/user';
import { SortingFilter } from '../../../api/common';

export const UsersListContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['users', 'general']);
  const { page, pageSize, setPage, setPageSize } = useFilters();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.USER, t),
    location: Entities.USER.path,
  });

  const filter: SortingFilter<'firstName'> = useMemo(
    () => ({
      skip: page * pageSize,
      limit: pageSize,
      sort: 'firstName',
    }),
    [page, pageSize],
  );

  const {
    data: response,
    error,
    isFetching: loading,
    refetch,
  } = useQuery({
    queryKey: ['getUsers', filter],
    queryFn: () => getUsers(filter),
  });
  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  const users = response?.data ?? [];

  useEffect(() => {
    setTitle({ main: t('users:title') });
  }, [setTitle, t]);

  if (error instanceof Error) {
    return <ErrorMessage error={error} />;
  }

  const handleAdd = () =>
    navigate(entityLocation(Entities.USER, 'invitations/new'), {
      state: { from: 'users' },
    });

  const handleShowInvitations = () =>
    navigate(entityLocation(Entities.USER, 'invitations'));

  return (
    <UsersList
      users={users}
      onAdd={
        hasPermission(Permissions.InvitationsWrite) ? handleAdd : undefined
      }
      onShowInvitations={
        hasPermission(Permissions.InvitationsRead)
          ? handleShowInvitations
          : undefined
      }
      linkToDetails={hasPermission(Permissions.UserDetailsRead)}
      loading={loading}
      page={page}
      pageSize={pageSize}
      count={response?.meta?.totalSize ?? 0}
      onChangePageSize={setPageSize}
      onChangePage={setPage}
    />
  );
};
