import React, { ChangeEvent, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';
import { DeviceScan, DeviceScanTable } from './DeviceScanTable';
import { EmptyBanner } from '../../../components/EmptyBanner';
import {
  ConfirmDeviceDiscoveryAlert,
  StartDeviceDiscoverySettings,
} from './ConfirmDeviceDiscoveryAlert';
import { ConnectionStates } from '../details/DeviceDetailsInfo';
import { CommissioningStatus } from './CommissioningStatus';

const classes = createClasses({
  cardContent: {
    minHeight: 200,
  },
  banner: {
    margin: 'auto',
  },
  button: {
    margin: 8,
    marginBottom: 16,
  },
});

export type DeviceDiscoveryCardProps = {
  loading?: boolean;
  commissioningStatus?: CommissioningStatus;
  gatewayStatus?: ConnectionStates;
  deviceScan?: DeviceScan;
  onStartScan?: (settings: StartDeviceDiscoverySettings) => void;
  onSelectFile?: (content: string) => void;
};

export const DeviceDiscoveryCard: React.FC<DeviceDiscoveryCardProps> = ({
  loading,
  deviceScan,
  commissioningStatus,
  gatewayStatus,
  onStartScan,
  onSelectFile,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const [shouldStartScan, setShouldStartScan] = useState(false);

  const confirmStartScan = () => setShouldStartScan(true);
  const handleCancelStartScan = () => setShouldStartScan(false);
  const handleStartScan = (settings: StartDeviceDiscoverySettings) => {
    setShouldStartScan(false);
    if (onStartScan) onStartScan(settings);
  };

  const handleSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const content = await fileList?.[0]?.text();
    if (content && onSelectFile) {
      onSelectFile(content);
    }
  };

  const isIdle = commissioningStatus === 'idle';
  const isOffline = gatewayStatus !== ConnectionStates.Connected;

  return (
    <>
      <FancyCard
        title={t('devices:discovery.deviceDiscoveryCard.title')}
        loading={loading}
      >
        <Grid container spacing={2} className={classes.cardContent}>
          <Grid item xs={12}>
            {!loading && !deviceScan ? (
              <EmptyBanner
                className={classes.banner}
                description={t(
                  'devices:discovery.deviceDiscoveryCard.noDeviceScan',
                )}
              />
            ) : (
              <DeviceScanTable loading={loading} {...deviceScan} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              onClick={confirmStartScan}
              color="primary"
              size="large"
              aria-label="restart-scan-button"
              variant="outlined"
              disabled={loading || !isIdle || isOffline || !onStartScan}
            >
              {!deviceScan
                ? t('devices:discovery.deviceDiscoveryCard.startScan')
                : t('devices:discovery.deviceDiscoveryCard.restartScan')}
            </Button>
            <label htmlFor={'file-upload-device'}>
              <input
                accept="text/xml"
                id={'file-upload-device'}
                data-testid={'file-upload-device'}
                name={'file-upload-device'}
                style={{ display: 'none' }}
                type="file"
                onChange={handleSelectFile}
                disabled={loading || !isIdle || !onSelectFile}
              />
              <Button
                className={classes.button}
                color="secondary"
                size="large"
                aria-label="upload-scan-button"
                variant="outlined"
                component="span"
                disabled={loading || !isIdle || !onSelectFile}
              >
                {t('devices:discovery.deviceDiscoveryCard.uploadScan')}
              </Button>
            </label>
          </Grid>
        </Grid>
      </FancyCard>
      <ConfirmDeviceDiscoveryAlert
        open={shouldStartScan}
        onCancel={handleCancelStartScan}
        onConfirm={handleStartScan}
      />
    </>
  );
};
