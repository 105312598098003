import React from 'react';
import { createClasses } from '@kp/react-ui';
import {
  DataGridPro as MuiDataGrid,
  DataGridProProps as MuiDataGridProps,
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridRowSelectionModel,
  GridToolbarFilterButton,
  GridValueGetterParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';

const classes = createClasses({
  tableContainer: {
    display: 'flex',
  },
  dataGrid: {
    border: 0,
  },
  hideCellFocus: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
      {
        outline: 'none',
      },
  },
  hideHeaderCheckbox: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
      {
        display: 'none',
      },
  },
  hideFooterBorder: {
    '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
      border: 0,
    },
  },
});

type DataGridProps = MuiDataGridProps & {
  containerClassName?: string;
  hideHeaderCheckbox?: boolean;
  height?: number;
};

export const DataGrid: React.FC<DataGridProps> = ({
  containerClassName,
  hideHeaderCheckbox,
  columns: passedColumns,
  height = 270,
  className,
  ...rest
}) => {
  const columns = React.useRef<GridColDef[]>(passedColumns);

  return (
    <div
      className={classNames(classes.tableContainer, containerClassName)}
      style={{ height }}
    >
      <MuiDataGrid
        density={'compact'}
        className={classNames(
          classes.dataGrid,
          classes.hideCellFocus,
          classes.hideFooterBorder,
          className,
          hideHeaderCheckbox && classes.hideHeaderCheckbox,
        )}
        style={{ height }}
        columns={columns.current}
        {...rest}
      />
    </div>
  );
};

export { GridFooter, GridFooterContainer, GridToolbarFilterButton };
export type {
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
};
