import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { getRegisteredApplication } from '../../../api/application';
import { useHeader } from '../../../contexts/header-context';
import { ErrorMessage } from '../../../components/Errors';
import {
  RegisteredApplicationDetails,
  RegisteredApplicationDetailsPermission,
} from './RegisteredApplicationDetails';
import { RegisteredApplicationDeleteContainer } from '../delete/RegisteredApplicationDeleteContainer';
import { getListKnownPermissions } from '../../../constants/Permissions';
import { NotFound } from '../../errorScreens';

export const RegisteredApplicationDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['registeredApplications', 'general']);
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();
  const [deleteMode, setDeleteMode] = useState(false);
  const knownPermissionsList = useMemo(() => getListKnownPermissions(t), [t]);

  const {
    data: response,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['getRegisteredApplication', applicationId],
    queryFn: () => getRegisteredApplication(applicationId),
    onError: (err: ApiError) => err,
  });

  const application = response?.data;

  const hierarchy = application
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.REGISTERED_APPLICATION,
            id: applicationId,
            name: application.name,
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: application?.name });
  }, [setTitle, application]);

  const permissions: RegisteredApplicationDetailsPermission[] = useMemo(() => {
    if (!application?.permissions) {
      return [];
    }
    return knownPermissionsList
      .map((permission) => ({
        ...permission,
        hasRead:
          permission.read && application.permissions?.includes(permission.read),
        hasWrite:
          permission.write &&
          application.permissions?.includes(permission.write),
      }))
      .filter(({ hasRead, hasWrite }) => hasRead || hasWrite);
  }, [application, knownPermissionsList]);

  if (error instanceof Error) {
    if (error.status === 404) {
      return (
        <NotFound
          title={t('registeredApplications:details.notFound.title') ?? ''}
          subtitle={t('registeredApplications:details.notFound.subtitle') ?? ''}
          buttonText={
            t('registeredApplications:details.notFound.buttonText') ?? ''
          }
          buttonOnClick={() => navigate(Entities.REGISTERED_APPLICATION.path)}
        />
      );
    }
    return <ErrorMessage error={error} />;
  }

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.REGISTERED_APPLICATION, ''));

  const handleEdit = (type: 'info' | 'settings' | 'permissions') =>
    navigate(
      `${entityLocation(
        Entities.REGISTERED_APPLICATION,
        applicationId,
      )}/edit?type=${type}`,
    );

  return (
    <>
      <RegisteredApplicationDetails
        loading={loading}
        onDelete={
          hasPermission(Permissions.RegisteredApplicationsWrite)
            ? handleDelete
            : undefined
        }
        onEdit={
          hasPermission(Permissions.RegisteredApplicationsWrite)
            ? handleEdit
            : undefined
        }
        id={applicationId}
        name={application?.name ?? ''}
        description={application?.description ?? ''}
        createdAt={application?.createdAt}
        updatedAt={application?.updatedAt}
        confidentialClientId={application?.confidentialOAuth2Client?.id ?? ''}
        publicClientId={application?.publicOAuth2Client?.id ?? ''}
        url={application?.url ?? ''}
        redirectUris={application?.redirectUris ?? []}
        permissions={permissions}
      />

      <RegisteredApplicationDeleteContainer
        id={applicationId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
