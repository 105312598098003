import React from 'react';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';
import { createRangeString } from '../../../utils/helpers';

export type DeviceDetailsCapabilitiesCapability = {
  id: string;
  name: string;
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
};

interface DeviceDetailsCapabilitiesProps {
  capabilities?: DeviceDetailsCapabilitiesCapability[];
}

export const DeviceDetailsCapabilities: React.FC<
  DeviceDetailsCapabilitiesProps
> = ({ capabilities }) => {
  const { t } = useTranslation(['devices', 'general']);

  return (
    <FancyCard title={t('devices:details.capabilities.title')}>
      {capabilities?.map((capability) => (
        <TableContainer
          key={capability.id}
          component={CardContent}
          data-testid="capabilities-table"
        >
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{capability.name}</TableCell>
                <TableCell align="right">
                  {t('devices:details.capabilities.value')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('devices:details.capabilities.range')}
                </TableCell>
                <TableCell align="right">
                  {createRangeString(capability)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('devices:details.capabilities.unit')}
                </TableCell>
                <TableCell align="right">
                  {`${capability.unitName} (${capability.unitSymbol})`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </FancyCard>
  );
};
