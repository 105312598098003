import React from 'react';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../FancyCard';
import { InfoList } from '../InfoList';
import { InfoListItem } from '../InfoListItem';
import { countryCodeToCountry } from '../../utils/helpers';
import { EmptyBanner } from '../EmptyBanner';

interface AddressCardProps {
  street?: string;
  no?: string;
  zip?: string;
  city?: string;
  country?: string;
  title?: string | null;
  loading?: boolean;
  onEdit?: () => void;
}

export const AddressCard: React.FC<AddressCardProps> = ({
  street,
  no,
  zip,
  city,
  country,
  title,
  loading,
  onEdit,
}) => {
  const { t } = useTranslation(['general']);
  const hasAddress = !!(street || zip || city || country);

  return (
    <FancyCard
      title={title ?? t('general:address.title')}
      onEdit={onEdit}
      loading={loading}
      fullLoader
      fullHeight
    >
      {!hasAddress ? (
        <EmptyBanner
          description={t('general:address.nodata')}
          actionLabel={t('general:address.addButton')}
          onClickAction={onEdit}
        />
      ) : (
        <InfoList>
          {street && (
            <InfoListItem term={t('general:address.street')}>
              {street}
              {no && ` ${no}`}
            </InfoListItem>
          )}
          {zip && (
            <InfoListItem term={t('general:address.zip')}>{zip}</InfoListItem>
          )}
          {city && (
            <InfoListItem term={t('general:address.city')}>{city}</InfoListItem>
          )}
          {country && (
            <InfoListItem term={t('general:address.country')}>
              {countryCodeToCountry(country)}
            </InfoListItem>
          )}
        </InfoList>
      )}
    </FancyCard>
  );
};
