import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { LoadingBackdrop } from '../../../components/Loading';
import { ConfirmAlert, ErrorAlert } from '../../../components/Alerts';

interface CapabilityDeleteProps {
  error?: Error | ApolloError;
  isLoading?: boolean;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const CapabilityDelete: React.FC<CapabilityDeleteProps> = ({
  error,
  isLoading = false,
  isOpen,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation(['capabilities', 'general']);

  return (
    <>
      <LoadingBackdrop open={isLoading} />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error]}
      />
      <ConfirmAlert
        open={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        title={t('capabilities:deleteAlert.title')}
        message={t('capabilities:deleteAlert.message')}
        cancelButton={t('general:buttons.cancel')}
        confirmButton={t('general:buttons.delete')}
      />
    </>
  );
};
