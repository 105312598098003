import React, { useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { FancyCard } from '../../../components/FancyCard';
import {
  AutocompleteField,
  FieldWithTooltip,
  TextField,
  DescriptionField,
} from '../../../components/Fields';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  field: {
    minHeight: '70px',
  },
});

export interface FieldDeviceFormData {
  name: string;
  description?: string;
  serialNo?: string;
  deviceIdentifier: string;
  parentDeviceId?: string;
  deviceModelId: string;
}

type FieldDeviceFormInfoProps = {
  allGateways: Array<{ id: string; name: string }>;
  allDeviceModels: Array<{
    id: string;
    name: string;
    isGateway: boolean;
  }>;
  onSelectModel?: (deviceModelId: string) => void;
  validateDeviceIdentifier?: (deviceIdentifier: string) => void;
  loading?: boolean;
  disabledFieldIds?: string[];
};

export const getFieldDeviceFormInfoSchema = (
  t: TFunction,
  valid: boolean,
): Yup.ObjectSchema<FieldDeviceFormData> =>
  Yup.object().shape({
    name: Yup.string()
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
      .required(t('general:errors.required') ?? ''),
    description: Yup.string().optional(),
    serialNo: Yup.string()
      .max(50, t('general:errors.maxLength', { max: 50 }) ?? '')
      .optional(),
    deviceModelId: Yup.string()
      .required(t('general:errors.required') ?? '')
      .notOneOf(['0'], t('devices:form.selectError') ?? ''),
    parentDeviceId: Yup.string()
      .required(t('general:errors.required') ?? '')
      .notOneOf(['0'], t('devices:form.selectError') ?? ''),
    deviceIdentifier: Yup.string()
      .max(100, t('general:errors.maxLength', { max: 100 }) ?? '')
      .required(t('general:errors.required') ?? '')
      .test(
        'deviceIdentifier',
        t('devices:form.notUniqueError') ?? '',
        (value) => !value || valid,
      ),
  });

export const FieldDeviceFormInfo: React.FC<FieldDeviceFormInfoProps> = ({
  loading,
  onSelectModel,
  allGateways,
  allDeviceModels,
  validateDeviceIdentifier,
  disabledFieldIds,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const handleSelectModel = useCallback(
    (event: React.ChangeEventHandler<unknown>, value: string | null) => {
      if (onSelectModel) {
        onSelectModel(value ?? '0');
      }
    },
    [onSelectModel],
  );

  const handleValidateDeviceIdentifier: React.FocusEventHandler<
    HTMLInputElement
  > = (event) => {
    if (validateDeviceIdentifier) validateDeviceIdentifier(event.target.value);
  };

  const gatewayOptions = useMemo(
    () =>
      allGateways
        .map(({ id, name: label }) => ({
          id,
          label,
        }))
        .concat([
          {
            id: '0',
            label: t('devices:form.selectGateway'),
          },
        ]),
    [allGateways, t],
  );

  const deviceModelOptions = useMemo(
    () =>
      allDeviceModels
        .map(({ id, name: label }) => ({
          id,
          label,
        }))
        .concat([
          {
            id: '0',
            label: t('devices:form.selectDeviceModel'),
          },
        ]),
    [allDeviceModels, t],
  );

  return (
    <FancyCard title={t('devices:details.info.title')} loading={loading}>
      <Grid container spacing={1} className={classes.cardContent}>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading}
            id="name"
            name="name"
            label={t('devices:details.info.name')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <DescriptionField
            disabled={loading}
            id="description"
            name="description"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldWithTooltip
            disabled={loading}
            id="serialNo"
            name="serialNo"
            label={t('devices:details.info.serialNumber')}
            optional
            innerComponent={TextField}
            tooltip={{
              content: t('devices:form.infoTooltip.serialNumber'),
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FieldWithTooltip
            disabled={loading || disabledFieldIds?.includes('deviceModelId')}
            id="deviceModelId"
            name="deviceModelId"
            data-testid="device-model-select"
            className={classes.field}
            options={deviceModelOptions}
            innerComponent={AutocompleteField}
            onChange={handleSelectModel}
            fullWidth
            label={t('devices:details.info.deviceModel')}
            tooltip={{
              content: t('devices:edit.info.deviceModelTooltip'),
              type: 'warning',
              testId: 'device-model-warning',
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FieldWithTooltip
            disabled={loading || disabledFieldIds?.includes('deviceIdentifier')}
            id="deviceIdentifier"
            name="deviceIdentifier"
            data-testid="device-identifier-input"
            label={t('devices:details.info.deviceIdentifier')}
            innerComponent={TextField}
            onBlur={handleValidateDeviceIdentifier}
            tooltip={{
              content: t('devices:form.infoTooltip.deviceIdentifier'),
              type: 'warning',
              testId: 'device-identifier-warning',
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FieldWithTooltip
            disabled={loading || disabledFieldIds?.includes('parentDeviceId')}
            id="parentDeviceId"
            name="parentDeviceId"
            data-testid="device-parent-select"
            className={classes.field}
            options={gatewayOptions}
            innerComponent={AutocompleteField}
            fullWidth
            label={t('devices:details.info.parentDevice')}
            tooltip={{
              content: t('devices:edit.info.parentDeviceTooltip'),
              type: 'warning',
              testId: 'parent-device-warning',
            }}
          />
        </Grid>
      </Grid>
    </FancyCard>
  );
};
