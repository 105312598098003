import React from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface CheckboxFieldProps extends CheckboxProps {
  name: string;
  Label: Omit<FormControlLabelProps, 'checked' | 'name' | 'value' | 'control'>;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  Label,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field } }) => {
        return (
          <FormControlLabel
            control={<Checkbox {...field} {...props} checked={!!value} />}
            {...Label}
          />
        );
      }}
    />
  );
};
