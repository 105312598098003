import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { ErrorAlert } from '../../../components/Alerts';
import { ErrorMessage } from '../../../components/Errors';
import { useStatusGatewayDetailsLazyQuery } from '../../../__generated__/types';
import {
  FieldDevice,
  StatusOverviewFieldDeviceList,
} from './StatusOverviewFieldDeviceList';
import { byName } from '../../../utils/sort';
import {
  getLatestSuccessfulDeployment,
  getDeploymentMappings,
} from '../../../api/bacnet';

type StatusOverviewFieldDeviceListContainerProps = {
  gatewayId?: string;
  onSelectDevice: (device?: FieldDevice) => void;
};
export const StatusOverviewFieldDeviceListContainer: React.FC<
  StatusOverviewFieldDeviceListContainerProps
> = ({ gatewayId, onSelectDevice }) => {
  const { t } = useTranslation(['status', 'general', 'errors']);
  const [devices, setDevices] = useState<FieldDevice[]>();
  const [loading, setLoading] = useState(false);

  const [callGatewayDetails, { error: errorGateway }] =
    useStatusGatewayDetailsLazyQuery();

  const {
    mutateAsync: callGetLatestSuccessfulDeployment,
    error: errorLatestSuccessfulDeployment,
  } = useMutation({
    mutationFn: getLatestSuccessfulDeployment,
    onError: (err: ApiError) => err,
  });

  const { mutateAsync: callGetMappings, error: errorMappings } = useMutation({
    mutationFn: getDeploymentMappings,
    onError: (err: ApiError) => err,
  });

  useEffect(() => {
    setDevices(undefined);
    if (!gatewayId) return;
    setLoading(true);

    const getData = async () => {
      const [gatewayPromiseResult, mappingsPromiseResult] =
        await Promise.allSettled([
          callGatewayDetails({ variables: { gatewayId } }),
          callGetLatestSuccessfulDeployment(gatewayId).then(
            (deploymentsResult) => {
              const deployment = deploymentsResult.data;
              return deployment
                ? callGetMappings({
                    gatewayId,
                    deploymentId: deployment.id,
                  })
                : undefined;
            },
          ),
        ]);

      const gatewayData =
        gatewayPromiseResult.status === 'fulfilled'
          ? gatewayPromiseResult.value
          : undefined;
      const mappingsResult =
        mappingsPromiseResult.status === 'fulfilled'
          ? mappingsPromiseResult.value
          : undefined;

      const gateway = gatewayData?.data?.device;
      const childDevices = gateway?.inverseParentDevice ?? [];
      const mappings = mappingsResult?.data ?? [];

      const mappedDevices = childDevices
        .map((device) => {
          return {
            id: device?.id ?? '-1',
            name: device?.name || '-',
            description: device?.description || '-',
            model: device?.deviceModel?.name || '-',
            capabilities:
              device?.deviceModel?.deviceModelCapabilities?.map((c) => ({
                id: c.id,
                name: c.capability.name,
                unitSymbol: c.unit.unitSymbol ?? '',
              })) ?? [],
            parentDeviceName: gateway?.name || '-',
            mappingCount:
              mappings?.filter((m) => m.mappedDeviceId === device.id).length ||
              0,
          };
        })
        .filter((device) => device.id !== '-1')
        .sort(byName);
      return mappedDevices;
    };

    getData()
      .then((mappedDevices) => {
        setDevices(mappedDevices);
        setLoading(false);
        return mappedDevices;
      })
      .catch((e) => {
        console.warn(e);
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  if (errorGateway) {
    return (
      <ErrorMessage
        error={errorGateway}
        message={t('errors:notFound.gateway')}
      />
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusOverviewFieldDeviceList
            loading={loading}
            devices={devices}
            onSelect={onSelectDevice}
          />
        </Grid>
      </Grid>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorLatestSuccessfulDeployment?.body.code !== 'UNKNOWN_GATEWAY' &&
          errorLatestSuccessfulDeployment?.status !== 404
            ? errorLatestSuccessfulDeployment
            : undefined,
          errorMappings,
        ]}
      />
    </>
  );
};
