import {
  UserSvcTenantsService as TenantService,
  UserSvcTenant,
} from '@kp/rest-api-javascript-sdk';
import { PaginationFilter } from '../common';

export type Tenant = UserSvcTenant;

export enum TenantSlugValidationStatus {
  AlreadyTaken = 'ALREADY_TAKEN',
  InvalidFormat = 'INVALID_FORMAT',
  Ok = 'OK',
  TooLong = 'TOO_LONG',
  TooShort = 'TOO_SHORT',
}

/**
 * List all tenants
 *
 * @param filter       The filter parameters
 */
export const getTenants = async (filter: PaginationFilter = {}) =>
  TenantService.getUserApiTenants(filter.skip, filter.limit);

/**
 * Get details of a tenant
 *
 * @param tenantId       The id of a tenant that will be fetched
 */
export const getTenant = TenantService.getUserApiTenants1;

/**
 * Delete a tenant
 *
 * @param tenantId       The id of the tenant
 */
export const deleteTenant = TenantService.deleteUserApiTenants;

type TenantUpdateInput = Partial<Omit<Tenant, 'id' | 'slug'>> & {
  tenantId: string;
};
/**
 * Update a tenant
 *
 * @param tenantId  The id of the tenant that will be updated
 * @param updates   The updates of the tenant
 */
export const updateTenant = ({ tenantId, ...updates }: TenantUpdateInput) =>
  TenantService.patchUserApiTenants(tenantId, updates);

/**
 * Create a tenant
 *
 * @param data   The data of the tenant
 */
export const createTenant = TenantService.postUserApiTenants;

/**
 * Validate tenant slug
 *
 * @param slug   The slug to validate
 */
export const validateTenantSlug = TenantService.postUserApiTenantsValidateSlug;
