import React, { MouseEventHandler } from 'react';
import { createClasses } from '@kp/react-ui';
import { Button, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import classNames from 'classnames';
import { Color } from '../../constants/Colors';

const classes = createClasses({
  banner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '16px',
    textAlign: 'center',
  },
  icon: {
    width: 50,
    height: 50,
    marginTop: '12px',
    marginBottom: '12px',
    '& svg': {
      width: 50,
      height: 50,
    },
  },
  actions: {
    display: 'flex',
    marginTop: '16px',
  },
  button: {
    '& + &': {
      marginLeft: '12px',
    },
  },
});

interface EmptyBannerProps {
  className?: string;
  description: string;
  actionLabel?: string | null;
  onClickAction?: MouseEventHandler<HTMLButtonElement>;
  disableAction?: boolean;
  disableIcon?: boolean;
}

export const EmptyBanner: React.FC<EmptyBannerProps> = ({
  className,
  description,
  actionLabel,
  onClickAction,
  disableAction,
  disableIcon,
}) => {
  return (
    <div className={classNames(classes.banner, className)}>
      <Icons.InfoOutlined
        htmlColor={Color.lightGrey}
        className={classes.icon}
      />
      <Typography variant="subtitle1">{description}</Typography>
      {!disableAction && onClickAction && (
        <div className={classes.actions}>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            startIcon={!disableIcon && <Icons.Add />}
            onClick={onClickAction}
            className={classes.button}
          >
            {actionLabel}
          </Button>
        </div>
      )}
    </div>
  );
};
