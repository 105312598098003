import React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { Link } from '../../../components/Link';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import {
  ConnectionStates,
  StatusIndicator,
} from '../../../components/StatusIndicator';
import { GatewayStatus } from '../../../hooks/useGatewayStatusSubscription';

const classes = createClasses({
  description: {
    whiteSpace: 'pre-wrap',
  },
});

export { ConnectionStates };
interface DeviceDetailsInfoProps {
  onEdit?: () => void;
  imageUrl: string;
  name: string;
  description: string;
  deviceIdentifier?: string;
  serialNumber: string;
  parentDevice?: { id: string; name: string };
  deviceModel?: { id: string; name: string };
  loading?: boolean;
  isLocked: boolean;
  gatewayStatus: GatewayStatus | undefined;
}

export const DeviceDetailsInfo: React.FC<DeviceDetailsInfoProps> = ({
  onEdit,
  imageUrl,
  name,
  description,
  deviceIdentifier,
  serialNumber,
  parentDevice,
  deviceModel,
  loading,
  isLocked,
  gatewayStatus,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  return (
    <FancyCard
      title={t('devices:details.info.title')}
      image={{ url: imageUrl, name: '' }}
      loading={loading}
      fullHeight
      actions={
        onEdit && (
          <Tooltip
            arrow
            placement="top-end"
            title={isLocked ? t('capabilities:details.lockedWarning') : ''}
            aria-label="details-tooltip"
          >
            <span>
              <IconButton
                aria-label="edit"
                data-testid="card-edit"
                onClick={onEdit}
                size="medium"
                color="primary"
                disabled={isLocked}
              >
                <Icons.Edit fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )
      }
    >
      <InfoList>
        <InfoListItem term={t('devices:details.info.name')}>
          {name}
        </InfoListItem>
        <InfoListItem
          term={t('general:fields.description')}
          className={classes.description}
        >
          {description}
        </InfoListItem>
        <InfoListItem term={t('devices:details.info.serialNumber')}>
          {serialNumber}
        </InfoListItem>
        <InfoListItem term={t('devices:details.info.deviceIdentifier')}>
          {deviceIdentifier}
        </InfoListItem>
        {parentDevice && (
          <>
            <InfoListItem term={t('devices:details.info.parentDevice')}>
              <Link to={entityLocation(Entities.DEVICE, parentDevice.id)}>
                {parentDevice.name}
              </Link>
            </InfoListItem>
          </>
        )}
        {deviceModel && (
          <InfoListItem term={t('devices:details.info.deviceModel')}>
            <Link to={entityLocation(Entities.DEVICEMODEL, deviceModel.id)}>
              {deviceModel.name}
            </Link>
          </InfoListItem>
        )}
        {gatewayStatus && (
          <InfoListItem
            term={t('devices:details.info.gatewayConnectionStatus')}
          >
            <StatusIndicator
              status={
                gatewayStatus?.connectionState ?? ConnectionStates.Unknown
              }
              withLabel
              firstConnected={gatewayStatus?.firstConnected}
              lastConnected={gatewayStatus?.lastConnected}
            />
          </InfoListItem>
        )}
      </InfoList>
    </FancyCard>
  );
};
