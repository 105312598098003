/**
 * This is the uri of the unit that is Unitless
 * It is used for assigning a capability to a device model which has a boolean data type.
 * It also has a special role when handling mappings.
 * We can't reference that unit by its id, because it's not really the same across the
 * environments.
 *
 * Note that this can become a part of the `contexts` one day.
 */
export const UNITLESS_URI = 'https://qudt.org/vocab/unit/UNITLESS';

// The time after which a device is reported as offline if no data came in.
export const OFFLINE_THRESHOLD_IN_MINUTES = 3;

export const ATTR_SET_NAME_GATEWAY_PROPERTIES = 'Gateway Properties';

export const ATTR_NAME_GATEWAY_FIRMWARE_VERSION = 'Firmware Version';

// The default expiry time for a shared access token for gateways (
// 0.5 years in seconds =  0.5 * 365 * 24 * 60 * 60
export const GATEWAY_ACCESS_SIGNATURE_EXPIRY_SECONDS = 15768000;
// expiration warning when the access signature expires in < 4 weeks
export const GATEWAY_ACCESS_SIGNATURE_EXPIRY_WARNING_TIME = { weeks: 4 };
