import {
  ApplicationSvcApplicationDevelopmentService as RegisteredApplicationService,
  ApplicationSvcApplicationPermissions,
  ApplicationSvcApplicationRedirectUris,
  ApplicationSvcRegisteredApplication,
  ApplicationSvcRegisteredApplicationDetails,
  OpenAPI,
} from '@kp/rest-api-javascript-sdk';
import { SortingFilter } from '../common';

import { REST_URI } from '../../utils/env';

// eslint-disable-next-line no-underscore-dangle
OpenAPI.TOKEN = async () => window.__AUTH_TOKEN__ ?? '';
OpenAPI.BASE = REST_URI;

export type RegisteredApplication = ApplicationSvcRegisteredApplication;
export type RegisteredApplicationDetails =
  ApplicationSvcRegisteredApplicationDetails;

/**
 * List all registered applications
 *
 * @param filter  The filter parameters
 */
export const getRegisteredApplications = async (
  filter: SortingFilter<'name' | 'createdAt'> = {},
) =>
  RegisteredApplicationService.getApplicationApiRegisteredApplications(
    filter.sort,
    filter.sortDir,
    filter.skip,
    filter.limit,
  );

/**
 * Get details of a registered application
 *
 * @param applicationId  The id of an application that will be fetched
 */
export const getRegisteredApplication =
  RegisteredApplicationService.getApplicationApiRegisteredApplications1;

/**
 * Create a new application
 *
 * @param name           The name of the new application
 * @param description    The description of the new application
 * @param url            The url of the new application
 * @param redirectUris   The redirect urls of the new application
 * @param permissions    The permissions new application requires
 */
export const createRegisteredApplication =
  RegisteredApplicationService.postApplicationApiRegisteredApplications;

type UpdateApplicationInput = {
  applicationId: string;
  name?: string;
  description?: string;
  permissions?: ApplicationSvcApplicationPermissions;
  redirectUris?: ApplicationSvcApplicationRedirectUris;
  url?: string;
};
/**
 * Update a registered application
 *
 * @param applicationId  The id of an application that will be updated
 * @param updates        The updates of the registered application
 */
export const updateRegisteredApplication = ({
  applicationId,
  ...update
}: UpdateApplicationInput) =>
  RegisteredApplicationService.patchApplicationApiRegisteredApplications(
    applicationId,
    update,
  );

/**
 * Delete a registered application
 *
 * @param applicationId  The id of an application that will be deleted
 */
export const deleteRegisteredApplication =
  RegisteredApplicationService.deleteApplicationApiRegisteredApplications;
