import React from 'react';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { FancyCard } from '../../../components/FancyCard';
import { DeviceLocationField, Site } from './DeviceLocationField';
import { PlacementKind } from '../../../__generated__/types';

const classes = createClasses({
  root: {
    height: '100%',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  infoIcon: {
    marginRight: '8px',
    display: 'block',
  },
});

export type DeviceFormLocationData = {
  locationType?: PlacementKind;
  locationId: string;
};
export interface DeviceFormLocationProps {
  type: 'edit' | 'create';
  sites: Array<Site>;
  loading?: boolean;
}

export const getDeviceFormLocationSchema = (
  t: TFunction,
): Yup.ObjectSchema<DeviceFormLocationData> =>
  Yup.object().shape({
    locationId: Yup.string()
      .required(t('general:errors.required') ?? '')
      .notOneOf(['0'], t('general:errors.required') ?? ''),
    locationType: Yup.mixed<PlacementKind>().oneOf(
      Object.values(PlacementKind),
    ),
  });

export const DeviceFormLocation: React.FC<DeviceFormLocationProps> = React.memo(
  ({ type, sites, loading }) => {
    const { t } = useTranslation(['devices', 'general']);
    const { setValue, formState, watch } =
      useFormContext<DeviceFormLocationData>();
    const error = formState.errors?.locationId?.message;
    const [locationId, locationType] = watch(['locationId', 'locationType']);

    return (
      <FancyCard
        title={t('devices:location.title')}
        className={type === 'create' ? classes.root : undefined}
        loading={loading}
        actions={
          <Tooltip
            arrow
            placement="top-end"
            title={
              <div className={classes.tooltipContent}>
                {t('devices:location.rules')}
              </div>
            }
            aria-label="non-editable-tooltip"
          >
            <Icons.Info
              fontSize="medium"
              className={classes.infoIcon}
              color="action"
              data-testid="card-info-icon"
            />
          </Tooltip>
        }
      >
        <Grid container spacing={1} className={classes.cardContent}>
          <DeviceLocationField
            loading={loading}
            id="locationId"
            setFieldValue={setValue}
            sites={sites}
            locationType={locationType}
            value={locationId}
            error={!!error}
            helperText={error}
          />
        </Grid>
      </FancyCard>
    );
  },
);

DeviceFormLocation.displayName = 'DeviceFormLocation';
