import React from 'react';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';

interface DeviceModelDetailsSettingsProps {
  loading: boolean;
  deviceTypeName: string;
}

export const DeviceModelDetailsSettings: React.FC<
  DeviceModelDetailsSettingsProps
> = ({ loading, deviceTypeName }) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  return (
    <FancyCard
      title={t('deviceModels:details.settings.title')}
      fullHeight
      loading={loading}
    >
      <InfoList>
        <InfoListItem term={t('deviceModels:details.settings.deviceType')}>
          {deviceTypeName}
        </InfoListItem>
      </InfoList>
    </FancyCard>
  );
};
