import { useEffect, useState } from 'react';
import {
  ApiError,
  CommissioningSvcGatewayDetails as GatewayDetails,
} from '@kp/rest-api-javascript-sdk';
import { useMutation } from '@tanstack/react-query';
import * as API from '../api/bacnet';
import { useCommissioningStatusSubscription } from './useCommissioningStatusSubscription';

export type UseGatewayStatus = {
  details: GatewayDetails | undefined;
  loading: boolean;
  error: ApiError | undefined | null;
  refresh: () => void;
};

export const useGatewayDetailsSubscription = (
  gatewayId?: string,
): UseGatewayStatus => {
  const [details, setDetails] = useState<GatewayDetails>();
  const [prevStatus, setprevStatus] = useState<string>();

  const {
    mutate: loadGatewayDetails,
    isLoading: loading,
    error,
  } = useMutation({
    mutationFn: API.getGatewayDetails,
    onSuccess: (result) => {
      setDetails(result.data);
    },

    onError: (err: ApiError) => err,
  });
  const { mutate: refreshGatewayDetails, error: refreshError } = useMutation({
    mutationFn: API.getGatewayDetails,
    onSuccess: (result) => {
      setDetails(result.data);
    },
    onError: (err: ApiError) => err,
  });

  const { status, error: errorSubscription } =
    useCommissioningStatusSubscription(gatewayId);
  useEffect(() => {
    if (!gatewayId) return;
    if (status === prevStatus) return;
    setprevStatus(status);
    refreshGatewayDetails(gatewayId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, gatewayId]);

  useEffect(() => {
    setDetails(undefined);
    if (gatewayId) {
      loadGatewayDetails(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  useEffect(() => {
    if (errorSubscription) {
      console.warn('Subscription Error', errorSubscription);
    }
  }, [errorSubscription]);

  const refresh = () => {
    if (gatewayId) refreshGatewayDetails(gatewayId);
  };

  return {
    details,
    loading,
    error: error || refreshError,
    refresh,
  };
};
