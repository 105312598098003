import React from 'react';
import { Grid, Button, Divider } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { DeviceModelDetailsSettings } from './DeviceModelDetailsSettings';
import { ActionsBar } from '../../../components/ActionsBar';
import { DeviceModelDetailsCapabilitiesContainer } from './DeviceModelDetailsCapabilitiesContainer';
import { DeviceModelDetailsAttributeSetsContainer } from './DeviceModelDetailsAttributeSetsContainer';
import { DeviceModelDetailsInfo } from './DeviceModelDetailsInfo';

type DeviceModelDetailsProps = {
  name: string;
  isGateway: boolean;
  deviceIdentifierFieldSelector: string;
  deviceTypeName: string;
  loading: boolean;
  isGlobal: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

const classes = createClasses({
  description: {
    whiteSpace: 'pre-wrap',
  },
  divider: {
    marginTop: '24px',
    marginBottom: '14px',
  },
});

export const DeviceModelDetails: React.FC<DeviceModelDetailsProps> = ({
  name,
  isGateway,
  deviceIdentifierFieldSelector,
  loading,
  deviceTypeName,
  isGlobal,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  return (
    <>
      {!isGlobal && onDelete && (
        <ActionsBar>
          {!loading && (
            <Button color="primary" size="large" onClick={onDelete}>
              {t('deviceModels:details.actions.delete')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid
          item
          container
          xs={12}
          spacing={3}
          alignItems="stretch"
          direction="row"
        >
          <Grid item xs={12} sm={12} md={6}>
            <DeviceModelDetailsInfo
              loading={loading}
              isGlobal={isGlobal}
              onEdit={onEdit}
              name={name}
              isGateway={isGateway}
              fieldSelector={deviceIdentifierFieldSelector}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <DeviceModelDetailsSettings
              loading={loading}
              deviceTypeName={deviceTypeName}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
          <DeviceModelDetailsAttributeSetsContainer />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
          <DeviceModelDetailsCapabilitiesContainer />
        </Grid>
      </Grid>
    </>
  );
};
