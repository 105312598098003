import React, { useEffect } from 'react';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import { Routes, Route } from 'react-router-dom';
import { BuildingCreateContainer } from './create/BuildingCreateContainer';
import { BuildingDetailsContainer } from './details/BuildingDetailsContainer';
import { BuildingEditContainer } from './edit/BuildingEditContainer';
import * as Pages from '../../constants/Pages';
import { BuildingLocationContainer } from './location/BuildingLocationContainer';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.SITES.icon;

export const BuildingsRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<BuildingCreateContainer />}
            permission={Permissions.BuildingsWrite}
          />
        }
      />
      <Route path="/:buildingId" element={<BuildingDetailsContainer />} />
      <Route
        path="/:buildingId/edit"
        element={
          <ProtectedRoute
            element={<BuildingEditContainer />}
            permission={Permissions.BuildingsWrite}
          />
        }
      />
      <Route
        path="/:buildingId/location"
        element={
          <ProtectedRoute
            element={<BuildingLocationContainer />}
            permission={Permissions.BuildingsWrite}
          />
        }
      />
    </Routes>
  );
};
