import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { useAuth } from '@kp/react-sdk';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Badge,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import {
  SIDEBAR_SECTIONS,
  SIDEBAR_OPENED_WIDTH,
  SIDEBAR_CLOSED_WIDTH,
} from '../../constants/UI';
import { Color } from '../../constants/Colors';
import { useBadge } from '../../contexts/badge-context';

const classes = createClasses({
  drawer: {
    flexShrink: 0,
  },
  drawerClosed: {},
  drawerPaper: {
    width: SIDEBAR_OPENED_WIDTH,
    transition: `width 0.2s`,
    boxShadow: `1px 0 10px ${Color.sidebarShadow}`,
    borderRight: `1px solid ${Color.sidebarBorder}`,
  },
  drawerPaperClosed: {
    width: SIDEBAR_CLOSED_WIDTH - 1,
    overflow: 'hidden',
    transition: `width 0.2s`,
  },
  drawerContainer: {
    overflow: 'auto',
    paddingTop: '24px',
    paddingBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  drawerContainerClosed: {
    overflow: 'hidden',
    paddingTop: '24px',
    paddingBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  sections: {
    flexGrow: 1,
  },
  listItem: {
    borderRadius: '0 8px 8px 0',
    width: SIDEBAR_OPENED_WIDTH - 10,
    margin: '3px 0',
    border: '1px solid transparent',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  listItemActive: {
    border: '1px solid',
    borderColor: Color.border,
  },
  listItemIcon: {
    paddingLeft: 8,
    marginRight: 4,
  },
  list: {
    padding: 0,
    overflow: 'hidden',
    minWidth: SIDEBAR_OPENED_WIDTH - 10,
  },
  separatorContainer: {
    height: '44px',
    overflow: 'hidden',
    transition: `opacity 0.2s, height 0.2s`,
  },
  separatorContainerClosed: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transition: `opacity 0.2s, height 0.2s`,
  },
  separator: {
    fontWeight: 600,
    color: Color.separator,
    padding: '24px 26px 8px 26px',
    lineHeight: '1',
    display: 'block',
  },
});

const SidebarLink = React.forwardRef<
  any,
  Omit<NavLinkProps, 'className'> & {
    activeClassName?: string;
    className?: string;
  }
>(({ className, activeClassName, ...props }, ref) => (
  <NavLink
    ref={ref}
    className={({ isActive }) =>
      classNames(isActive && activeClassName, className)
    }
    {...props}
  />
));

SidebarLink.displayName = 'SidebarLink';

interface SidebarProps {
  open?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ open }) => {
  const { roles, hasPermission } = useAuth();
  const { t } = useTranslation(['general']);
  const location = useLocation();
  const { badges } = useBadge();

  const sections = useMemo(() => {
    const isSuperAdmin = roles?.includes('superadmin');
    return SIDEBAR_SECTIONS.map(({ items, ...rest }) => ({
      ...rest,
      items: items
        .filter((item) =>
          item.permission ? hasPermission(item.permission) : true,
        )
        .map((item) => ({
          ...item,
          badge: item.badge ? item.badge(badges) : false,
          path: typeof item.path === 'function' ? item.path(badges) : item.path,
        })),
    })).filter(({ id }) => (id === 'administration' ? isSuperAdmin : true));
  }, [roles, hasPermission, badges]);

  const isActive = (paths: string[]): boolean =>
    paths.includes(location.pathname.split('/')[1]);

  return (
    <Drawer
      className={classNames(classes.drawer, !open && classes.drawerClosed)}
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClosed,
        ),
      }}
      open={open}
      anchor="left"
      data-testid="sidebar"
    >
      <Toolbar />
      <div
        className={classNames(
          classes.drawerContainer,
          !open && classes.drawerContainerClosed,
        )}
      >
        <div className={classes.sections}>
          {sections.map(
            ({ id, label: sectionLabel, items }) =>
              items.length > 0 && (
                <div key={id}>
                  <div
                    className={classNames(
                      classes.separatorContainer,
                      !open && classes.separatorContainerClosed,
                    )}
                  >
                    <Typography
                      variant="overline"
                      className={classes.separator}
                    >
                      {sectionLabel(t)}
                    </Typography>
                  </div>
                  <List className={classes.list}>
                    {items.map(
                      ({ label, icon: Icon, path, childPaths, badge }) => (
                        <ListItem
                          button
                          key={label(t)}
                          component={SidebarLink}
                          to={path}
                          activeClassName={classNames(
                            'Mui-selected',
                            classes.listItemActive,
                          )}
                          className={classNames(
                            classes.listItem,
                            childPaths &&
                              isActive(childPaths) &&
                              'Mui-selected',
                            childPaths &&
                              isActive(childPaths) &&
                              classes.listItemActive,
                          )}
                          end={path === '/'}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            {badge ? (
                              <Badge color="primary" variant="dot">
                                <Icon />
                              </Badge>
                            ) : (
                              <Icon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={label(t)} />
                        </ListItem>
                      ),
                    )}
                  </List>
                </div>
              ),
          )}
        </div>
      </div>
    </Drawer>
  );
};
