import React, { useState } from 'react';
import {
  DialogContentText,
  FormControlLabel,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { ConfirmAlert } from '../../../components/Alerts';

const classes = createClasses({
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
});

type DeployAlertProps = {
  onDeploy?: () => void;
  onDownload?: () => void;
  onCancel: () => void;
  open: boolean;
};

type Action = 'deploy' | 'download';
export const DeployAlert: React.FC<DeployAlertProps> = ({
  onDeploy,
  onDownload,
  onCancel,
  open,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const [action, setAction] = useState<Action | undefined>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value as Action);
  };

  const handleCancel = () => {
    setAction(undefined);
    onCancel();
  };
  const handleClose = () => {
    if (action === 'deploy' && onDeploy) onDeploy();
    if (action === 'download' && onDownload) onDownload();
    setAction(undefined);
  };

  const radioOptions = [
    onDeploy && {
      value: 'deploy',
      label: t('devices:details.deployCard.deployAlert.deployRadio'),
    },
    onDownload && {
      value: 'download',
      label: t('devices:details.deployCard.deployAlert.downloadRadio'),
    },
  ].filter((ro) => ro) as Array<{ value: string; label: string }>;

  return (
    <ConfirmAlert
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleCancel}
      onConfirm={handleClose}
      confirmButton={t('general:buttons.ok')}
      cancelButton={t('general:buttons.cancel')}
      closeButton
      options={{ confirmDisabled: !action }}
      title={t('devices:details.deployCard.deployAlert.title')}
      message={t('devices:details.deployCard.deployAlert.message')}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <Divider className={classes.divider} />
      <div className={classes.divider} />
      <DialogContentText>
        {t('devices:details.deployCard.deployAlert.warning')}
      </DialogContentText>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup name="radio-buttons-group" onChange={handleChange}>
            {radioOptions.map((o) => (
              <DialogContentText key={o.value}>
                <FormControlLabel control={<Radio />} {...o} />
              </DialogContentText>
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </ConfirmAlert>
  );
};
