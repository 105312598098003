import React, { useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { Loading } from '../../../components/Loading';
import { CopyIconButton } from '../../../components/CopyIconButton';
import { useLocaleDateFns } from '../../../hooks/date-fns';
import { AccessSignatureExpiryAlert } from './AccessSignatureExpiryAlert';
import { BasicConfirmAlert } from '../../../components/Alerts/BasicConfirmAlert';

const classes = createClasses({
  card: {
    minHeight: 208,
  },
  banner: {
    marginTop: 16,
    margin: 'auto',
  },
  table: {
    marginTop: 16,
    marginBottom: 16,
  },
  tableCell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontFamily: 'monospace',
  },
});

type DeviceDetailsGatewayConfigProps = {
  host: string;
  port: number;
  clientId: string;
  user: string;
  sharedAccessSignatureExpiry?: string;
  loading?: boolean;
  onDownload: () => void;
  onGenerateAccessSignature: () => void;
};
export const DeviceDetailsGatewayConfig: React.FC<
  DeviceDetailsGatewayConfigProps
> = ({
  loading,
  host,
  port,
  clientId,
  user,
  sharedAccessSignatureExpiry,
  onDownload,
  onGenerateAccessSignature,
}) => {
  const { t } = useTranslation(['gateways', 'general']);
  const { formatDefaultStr } = useLocaleDateFns();
  const [showDownloadWarning, setShowDownloadWarning] = useState(false);

  const handleDownload = () => setShowDownloadWarning(true);
  const handleCancelDownload = () => setShowDownloadWarning(false);
  const handleConfirmDownload = () => {
    onDownload();
    setShowDownloadWarning(false);
  };

  const rows = [
    {
      key: 'host',
      name: t('devices:details.gatewayConfig.host'),
      value: host ?? '',
      copy: host ?? '',
    },
    {
      key: 'port',
      name: t('devices:details.gatewayConfig.port'),
      value: port ?? '',
      copy: port ?? '',
    },
    {
      key: 'clientId',
      name: t('devices:details.gatewayConfig.clientId'),
      value: clientId ?? '',
      copy: clientId ?? '',
    },
    {
      key: 'user',
      name: t('devices:details.gatewayConfig.user'),
      value: user ?? '',
      copy: user ?? '',
    },
    {
      key: 'accessSignature',
      name: t('devices:details.gatewayConfig.accessSignature'),
      value: sharedAccessSignatureExpiry
        ? t('devices:details.gatewayConfig.accessSignatureDate', {
            date: formatDefaultStr(sharedAccessSignatureExpiry),
          })
        : t('devices:details.gatewayConfig.accessSignatureNotGenerated'),
      copy: undefined,
    },
  ];

  const expiryDate = sharedAccessSignatureExpiry
    ? new Date(sharedAccessSignatureExpiry)
    : null;

  return (
    <>
      <FancyCard
        title={t('devices:details.gatewayConfig.title')}
        loading={loading}
        className={classes.card}
        actions={
          sharedAccessSignatureExpiry && (
            <>
              <Tooltip
                arrow
                placement="top-end"
                title={t('devices:details.gatewayConfig.downloadTooltip') ?? ''}
              >
                <IconButton
                  data-testid="download-button"
                  onClick={handleDownload}
                  size="medium"
                  color="primary"
                >
                  <Icons.SaveAlt fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )
        }
      >
        {loading && <Loading />}
        {!loading && (
          <Table size="small" className={classes.table}>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.key}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.value}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {row.key === 'accessSignature' ? (
                      <IconButton onClick={onGenerateAccessSignature}>
                        <Icons.Refresh
                          id={row.key}
                          data-testid={`generateAccessSignature`}
                          fontSize="small"
                        />
                      </IconButton>
                    ) : (
                      <CopyIconButton
                        id={row.key}
                        value={row.copy ? String(row.copy) : ''}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </FancyCard>
      <BasicConfirmAlert
        open={showDownloadWarning}
        onCancel={handleCancelDownload}
        onConfirm={handleConfirmDownload}
        title={t('devices:details.gatewayConfig.downloadWarning.title')}
        message={t('devices:details.gatewayConfig.downloadWarning.message')}
      />
      {expiryDate && (
        <AccessSignatureExpiryAlert
          expiryDate={expiryDate}
          onGenerate={onGenerateAccessSignature}
        />
      )}
    </>
  );
};
