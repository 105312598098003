import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { noop } from 'react-use/lib/misc/util';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useMutation } from '@tanstack/react-query';
import { getApplications, Status } from '../api/application';

export interface Badges {
  hasUpdatedApplications: boolean;
}

interface BadgeContextValue {
  refreshApplications: () => void;
  badges: Badges;
}

const BadgeContext = createContext<BadgeContextValue>({
  badges: {
    hasUpdatedApplications: false,
  },
  refreshApplications: noop,
});

export const useBadge = (): BadgeContextValue => useContext(BadgeContext);

type BadgeProviderProps = {
  children: React.ReactNode;
};
export const BadgeProvider: React.FC<BadgeProviderProps> = ({ children }) => {
  const [isInitialized, setInitialized] = useState(false);
  const { hasPermission, isAuthenticated } = useAuth();
  const { mutate: fetchApplications, data: response } = useMutation({
    mutationFn: getApplications,
  });

  const refreshApplications = useCallback(
    () =>
      hasPermission(Permissions.ApplicationsRead)
        ? fetchApplications({ status: Status.updated })
        : Promise.resolve(),
    [fetchApplications, hasPermission],
  );

  useEffect(() => {
    if (isAuthenticated && !isInitialized) {
      setInitialized(true);
      refreshApplications();
    }
  }, [isInitialized, refreshApplications, isAuthenticated]);

  const value = useMemo(
    () => ({
      badges: {
        hasUpdatedApplications: !!response?.data?.length,
      },
      refreshApplications,
    }),
    [response, refreshApplications],
  );

  return (
    <BadgeContext.Provider value={value}>{children}</BadgeContext.Provider>
  );
};
