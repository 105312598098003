import React, { useEffect, useState } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import * as Entities from '../../../../constants/Entities';
import { ErrorAlert } from '../../../../components/Alerts';
import { entityLocation } from '../../../../utils/entity';
import { getUserInvitation, resendUserInvitation } from '../../../../api/user';
import { useHeader } from '../../../../contexts/header-context';
import { ErrorMessage } from '../../../../components/Errors';
import { UserInvitationDetails } from './UserInvitationDetails';
import { UserInvitationDeleteContainer } from '../delete/UserInvitationDeleteContainer';
import { UserInvitationActionSuccessDialog } from '../../common/UserInvitationActionSuccessDialog';
import { NotFound } from '../../../errorScreens';

export const UserInvitationDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['users', 'general']);
  const navigate = useNavigate();
  const { userInvitationId } = useParams();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  const [deleteMode, setDeleteMode] = useState(false);
  const [resendMode, setResendMode] = useState(false);

  const {
    data: responseUserInvitation,
    isLoading: loadingUserInvitation,
    error: errorUserInvitation,
  } = useQuery({
    queryKey: ['getUserInvitation', userInvitationId],
    queryFn: () => getUserInvitation(userInvitationId),
    onError: (err: ApiError) => err,
  });

  const {
    mutate: resendInvitation,
    data: resendResponse,
    error: resendError,
    isLoading: resendLoading,
  } = useMutation({ mutationFn: resendUserInvitation });

  const userInvitation = responseUserInvitation?.data ?? null;
  const roleNames = userInvitation?.roles?.map((role) => role.name) ?? [];

  const hierarchy = userInvitation
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.USER,
            id: 'invitations',
            name: t('users:breadcrumb.invitations'),
          },
        ],
        t,
      ).concat([
        {
          title: userInvitation.email,
          location: entityLocation(
            Entities.USER,
            `invitations/${userInvitationId}`,
          ),
        },
      ])
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: userInvitation?.email });
  }, [setTitle, userInvitation]);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.USER, 'invitations'));

  const handleResend = () => {
    setResendMode(true);
    return resendInvitation(userInvitationId);
  };

  if (errorUserInvitation instanceof Error) {
    if (errorUserInvitation.status === 404) {
      return (
        <NotFound
          title={t('users:invitation.notFound.title') ?? ''}
          subtitle={t('users:invitation.notFound.subtitle') ?? ''}
          buttonText={t('users:invitation.notFound.buttonText') ?? ''}
          buttonOnClick={() =>
            navigate(entityLocation(Entities.USER, 'invitations'))
          }
        />
      );
    }
    return <ErrorMessage error={errorUserInvitation} />;
  }

  const isResendSuccessful =
    resendResponse?.data?.status && resendResponse.data.status === 'success';

  return (
    <>
      <UserInvitationDetails
        loading={loadingUserInvitation}
        onDelete={
          hasPermission(Permissions.InvitationsWrite) ? handleDelete : undefined
        }
        onResend={
          hasPermission(Permissions.InvitationsWrite) ? handleResend : undefined
        }
        id={userInvitationId}
        email={userInvitation?.email ?? ''}
        status={userInvitation?.status ?? ''}
        roleNames={roleNames}
        createdAt={userInvitation?.createdAt}
        updatedAt={userInvitation?.updatedAt}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[resendError as Error]}
      />
      {resendMode &&
        !resendError &&
        !resendLoading &&
        isResendSuccessful &&
        userInvitation?.email && (
          <UserInvitationActionSuccessDialog
            onConfirm={() => setResendMode(false)}
            dialogTitle={t('users:invitation.resend.confirm.title')}
            dialogContentText={t('users:invitation.resend.confirm.message', {
              email: userInvitation.email,
            })}
          />
        )}
      <UserInvitationDeleteContainer
        id={userInvitationId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};
