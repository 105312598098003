import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../components/Errors';
import { Loading } from '../../components/Loading';
import { useBreadcrumb } from '../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../utils/breadcrumb';
import {
  DataPoint,
  useDeviceDetailsForDataQuery,
  useHistoricalDataQuery,
} from '../../__generated__/types';
import {
  HistoricalDataChart,
  HistoricalDataChartProps,
} from './HistoricalDataChart';
import * as Entities from '../../constants/Entities';
import { useHeader } from '../../contexts/header-context';
import { NotFound } from '../errorScreens';

type CapabilityWithData = Record<
  string,
  Array<Pick<DataPoint, 'utcTimeMeasured' | 'valueString'>>
>;

export const HistoricalDataContainer: React.FC = () => {
  const { t } = useTranslation(['historicalData', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { deviceId } = params;
  const { setTitle } = useHeader();

  useEffect(() => {
    setTitle({ main: t('historicalData:title') });
  }, [setTitle, t]);

  const {
    data: dataDevice,
    loading: loadingDevice,
    error: errorDevice,
  } = useDeviceDetailsForDataQuery({
    variables: { deviceId },
  });

  const placement = dataDevice?.placementOfDevice;
  const device = dataDevice?.device;

  const hierarchy = [
    ...(placement?.siteId && placement?.siteName
      ? [
          {
            type: Entities.SITE,
            id: placement.siteId,
            name: placement.siteName,
          },
        ]
      : []),
    ...(placement?.buildingId && placement?.buildingName
      ? [
          {
            type: Entities.BUILDING,
            id: placement.buildingId,
            name: placement.buildingName,
          },
        ]
      : []),
    ...(placement?.storeyId && placement?.storeyName
      ? [
          {
            type: Entities.STOREY,
            id: placement.storeyId,
            name: placement.storeyName,
          },
        ]
      : []),
    ...(placement?.zoneId && placement?.zoneName
      ? [
          {
            type: Entities.ZONE,
            id: placement.zoneId,
            name: placement.zoneName,
          },
        ]
      : []),
    ...(device?.name
      ? [
          {
            type: Entities.DEVICE,
            id: deviceId,
            name: device.name,
          },
        ]
      : []),
    ...(device?.name
      ? [
          {
            type: Entities.DATA,
            id: deviceId,
            name: t('historicalData:breadcrumb'),
          },
        ]
      : []),
  ];

  useBreadcrumb(
    hierarchy.length > 0 ? makeHierarchyBreadcrumb(hierarchy, t) : [],
  );

  const capabilities = (device?.deviceModel?.deviceModelCapabilities ?? [])
    .map((capability) => ({
      id: capability?.id ?? '',
      name: capability?.capability?.name ?? '',
      unitName: capability?.unit?.name ?? '',
      unitSymbol: capability?.unit?.unitSymbol ?? '',
    }))
    .filter((capability) => capability.id !== '');

  const deviceModelCapabilityIds = capabilities.map((c) => c.id);
  const {
    data,
    loading: loadingData,
    error: errorData,
  } = useHistoricalDataQuery({
    variables: { limit: 5000, deviceId, deviceModelCapabilityIds },
  });

  const capabilityDataMap: CapabilityWithData = {};
  (data?.sensorMeasurements?.items ?? []).forEach((item) => {
    if (item) {
      capabilityDataMap[item.deviceModelCapabilityId] = [
        ...(capabilityDataMap[item.deviceModelCapabilityId] ?? []),
        item,
      ];
    }
  });

  const chartData = Object.keys(capabilityDataMap)
    .map((capabilityId) => {
      const details = capabilities.find((c) => c.id === capabilityId);
      const seriesData = capabilityDataMap[capabilityId]
        .map(({ utcTimeMeasured, valueString }) =>
          utcTimeMeasured && valueString
            ? [new Date(utcTimeMeasured).valueOf(), Number(valueString)]
            : undefined,
        )
        .filter((v) => v) as Array<number[]>;
      const sortedSeriesData = [...seriesData].sort((a, b) => {
        if (a[0] > b[0]) return 1;
        if (a[0] < b[0]) return -1;
        return 0;
      });

      return {
        details,
        values: sortedSeriesData,
      };
    })
    .filter((d) => d.details) as HistoricalDataChartProps['data'];

  if (loadingDevice || loadingData) {
    return <Loading />;
  }
  if (errorDevice) {
    return <ErrorMessage error={errorDevice} />;
  }
  if (errorData) {
    return <ErrorMessage error={errorData} />;
  }
  if (!loadingDevice && !device) {
    return (
      <NotFound
        title={t('historicalData:deviceNotFound.title') ?? ''}
        subtitle={t('historicalData:deviceNotFound.subtitle') ?? ''}
        buttonText={t('historicalData:deviceNotFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }
  if (!loadingData && !data) {
    return (
      <NotFound
        title={t('historicalData:dataNotFound.title') ?? ''}
        subtitle={t('historicalData:dataNotFound.subtitle') ?? ''}
        buttonText={t('historicalData:dataNotFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }

  return <HistoricalDataChart data={chartData} />;
};
