type SortableByName = {
  name: string;
};
export const byName = (a: SortableByName, b: SortableByName) =>
  a.name.localeCompare(b.name);

type SortableByField = Record<string, any>;
export const byField =
  (field: string, dir: 'ASC' | 'DESC' = 'ASC') =>
  (a: SortableByField, b: SortableByField) => {
    if (dir === 'ASC') {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
      return 0;
    }
    if (a[field] < b[field]) return 1;
    if (a[field] > b[field]) return -1;
    return 0;
  };
