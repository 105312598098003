import React from 'react';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ActionsBar } from '../../../components/ActionsBar';
import { UserRole } from '../../../api/user';
import { UserForm } from '../common/UserForm';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

interface RoleWitStatus extends UserRole {
  enabled: boolean;
}
export interface UserEditRolesData {
  roles: RoleWitStatus[];
}

type UserEditProps = UserEditRolesData & {
  loading?: boolean;
  onDiscard: () => void;
  onSubmit: (roles: UserEditRolesData) => void;
};

export const UserEdit: React.FC<UserEditProps> = ({
  roles,
  loading,
  onDiscard,
  onSubmit,
}) => {
  const { t } = useTranslation(['users', 'general']);

  const validationSchema = Yup.object().shape({
    roles: Yup.array()
      .of(
        Yup.object().shape({
          enabled: Yup.boolean(),
        }),
      )
      .test(
        'roles',
        t('users:invitation.create.validation.roles') ?? '',
        (values) => !!values?.some((role) => role.enabled),
      ),
  });

  const defaultValues: UserEditRolesData = {
    roles,
  };
  const methods = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useDeepCompareEffect(() => {
    reset({
      roles,
    });
  }, [reset, roles]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('users:actions.saveButton')}
        </Button>
      </ActionsBar>
      <UserForm type="edit" loading={loading} />
    </FormProvider>
  );
};
