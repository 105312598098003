import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isAfter, sub } from 'date-fns';
import { Grid } from '@mui/material';
import { useInterval } from 'react-use';
import { FieldDevice } from './StatusOverviewFieldDeviceList';
import { ErrorAlert } from '../../../components/Alerts';
import { StatusOverviewDeviceData } from './StatusOverviewDeviceData';
import { ConnectionStates } from '../../../components/StatusIndicator';
import { OFFLINE_THRESHOLD_IN_MINUTES } from '../../../constants/Misc';
import { useDeviceDataQuery } from '../../../__generated__/types';

type DeviceData = {
  deviceId: string;
  deviceModelCapabilityId: string;
  utcTimeMeasured: string;
  valueString: string;
};

const getStatus = (d?: DeviceData): ConnectionStates => {
  let status = ConnectionStates.Unknown;
  if (d?.utcTimeMeasured) {
    status = ConnectionStates.Disconnected;
    if (
      isAfter(
        new Date(d.utcTimeMeasured),
        sub(new Date(), { minutes: OFFLINE_THRESHOLD_IN_MINUTES }),
      )
    ) {
      status = ConnectionStates.Connected;
    }
  }
  return status;
};

type StatusOverviewDeviceDataContainerProps = {
  loading?: boolean;
  device: FieldDevice;
};

export const StatusOverviewDeviceDataContainer: React.FC<
  StatusOverviewDeviceDataContainerProps
> = ({ device }) => {
  const { t } = useTranslation(['status', 'general']);

  const capabilities = useMemo(() => device.capabilities ?? [], [device]);
  const deviceModelCapabilityIds = capabilities.map((c) => c.id);
  const { loading, error, data, refetch } = useDeviceDataQuery({
    variables: { deviceId: device.id, deviceModelCapabilityIds },
  });
  useInterval(() => {
    refetch();
  }, 10000);

  const mappedData = useMemo(
    () =>
      capabilities.map((capability) => {
        const sensorData = (data?.latestSensorData ?? [])
          .map((d) => ({
            deviceId: d?.deviceId ?? '',
            deviceModelCapabilityId: d?.deviceModelCapabilityId ?? '-1',
            utcTimeMeasured: d?.utcTimeMeasured ?? '',
            valueString: d?.valueString ?? '',
          }))
          .filter((v) => v.deviceModelCapabilityId !== '-1');

        const capabilityData = sensorData.find(
          (d) => d.deviceModelCapabilityId === capability.id,
        );
        const ts = capabilityData?.utcTimeMeasured;
        const value = capabilityData?.valueString
          ? `${capabilityData.valueString ?? ''} ${
              capability?.unitSymbol ?? ''
            }`
          : '';
        const status = getStatus(capabilityData);

        return {
          id: capability.id,
          capability: capability?.name ?? '',
          status,
          ts,
          value,
        };
      }),
    [capabilities, data],
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusOverviewDeviceData data={mappedData} loading={loading} />
        </Grid>
      </Grid>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error]}
      />
    </>
  );
};
