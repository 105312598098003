import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import {
  ApiError,
  CommissioningSvcEnrichedBacnetDevice as EnrichedBacnetDevice,
  CommissioningSvcEnrichedBacnetObject as EnrichedBacnetObject,
} from '@kp/rest-api-javascript-sdk';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  PlacementKind,
  useMappingsEditDeviceCreateWithPlacementMutation,
  useMappingsEditGatewayDetailsQuery,
} from '../../../../__generated__/types';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { ErrorAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { useHeader } from '../../../../contexts/header-context';
import { ErrorMessage } from '../../../../components/Errors';
import {
  getLatestObjectScan,
  getLatestMappings,
  deleteMapping as deleteMappingFromAPI,
  saveMapping,
} from '../../../../api/bacnet';
import { entityLocation } from '../../../../utils/entity';
import { DeviceMappingsScanResultCard } from './DeviceMappingsScanResultCard';
import { useMappingState } from './useMappingState';
import { BACnetTarget, ExistingDevice, mapDevice } from './MappingStateUtils';
import {
  FieldDeviceCreateData,
  DeviceDetailsDialogContainer,
} from './DeviceDetailsDialogContainer';
import { NotFound } from '../../../errorScreens';

export const DeviceMappingsEditContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general', 'errors']);
  const navigate = useNavigate();
  const { hasPermission } = useAuth();
  const { gatewayId = '0' } = useParams();
  const { setTitle, setLoading } = useHeader();

  const {
    loading,
    error,
    mappingState,
    allDevices,
    setStateFromMappings,
    addDevice,
    selectDevice,
    selectCapability,
    deleteMapping,
  } = useMappingState(gatewayId);

  const [showCreateDevice, setShowCreateDevice] = useState(false);

  const emptyDeviceIdentifiers = {
    devices: [],
    parentDevice: { deviceIdentifier: '' },
  };

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useMappingsEditGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;
  const gatewayLocation = dataGateway?.placementOfDevice;
  const placementId =
    (gatewayLocation?.zoneId || gatewayLocation?.buildingId) ?? undefined;
  const placementType =
    (gatewayLocation?.mappingPlacementType as PlacementKind) ?? undefined;

  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: gatewayId,
            name: gateway.name,
            query: { activeTab: 'FieldDevices' },
          },
        ],
        t,
      ).concat({
        title: t('devices:mappings.edit.breadcrumb'),
        location: entityLocation(
          Entities.DEVICE,
          `${gatewayId}/mappings/draftCreate`,
        ),
      })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('devices:mappings.edit.title'),
      sub:
        gatewayLocation?.siteName && gatewayLocation?.buildingName
          ? `${gatewayLocation?.siteName}, ${gatewayLocation?.buildingName}`
          : '',
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t, gatewayLocation]);

  const {
    isLoading: loadingScanResultDetails,
    data: responseScanResultDetails,
    error: errorScanResultDetails,
  } = useQuery({
    queryKey: ['getLatestObjectScan', gatewayId],
    queryFn: () => getLatestObjectScan(gatewayId),
    onError: (err: ApiError) => err,
  });

  const {
    isLoading: loadingMappings,
    data: responseMappings,
    error: errorMappings,
  } = useQuery({
    queryKey: ['getLatestMappings', gatewayId],
    queryFn: () => getLatestMappings(gatewayId),
    onError: (err: ApiError) => err,
  });

  const {
    mutate: callSaveMapping,
    isLoading: loadingSaveMapping,
    error: errorSaveMapping,
  } = useMutation({
    mutationFn: saveMapping,
    onError: (err: ApiError) => err,
  });
  const {
    mutate: callDeleteMapping,
    isLoading: loadingDeleteMapping,
    error: errorDeleteMapping,
  } = useMutation({
    mutationFn: deleteMappingFromAPI,
    onError: (err: ApiError) => err,
  });

  const [
    createDevice,
    { loading: loadingCreateDevice, error: errorCreateDevice },
  ] = useMappingsEditDeviceCreateWithPlacementMutation();

  const scanFinished = useMemo(() => {
    const latestDate = responseScanResultDetails?.data?.reduce(
      (mostRecentFinish, result) =>
        result?.finishedAt && result.finishedAt > mostRecentFinish
          ? result.finishedAt
          : mostRecentFinish,
      '',
    );
    return latestDate ? new Date(latestDate) : undefined;
  }, [responseScanResultDetails]);
  const scanResultWithDevices = useMemo(
    () =>
      responseScanResultDetails?.data?.reduce(
        (combinedResult, scanData) => [
          ...combinedResult,
          ...(scanData.scanResult?.unit?.device ?? []),
        ],
        [] as EnrichedBacnetDevice[],
      ),
    [responseScanResultDetails],
  );
  const scanResult = useMemo(
    () =>
      scanResultWithDevices
        ?.map((d) => {
          return ((d.object ?? []) as EnrichedBacnetObject[]).map((o) => ({
            id: d.objectIdentifier + o.objectIdentifier,
            deviceIdentifier: d.objectIdentifier,
            deviceName: d.objectName,
            unitName: o['X-unit']?.name || String(o.units) || '-',
            unitUris: o['X-unit']?.unitUris ?? [],
            objectTypeName: o['X-object-type'],
            isMappable: o['X-object-type-is-mappable'],
            ...o,
          }));
        })
        .flat(),
    [scanResultWithDevices],
  );

  useEffect(() => {
    if (!responseMappings?.data) return;
    if (!scanResult) return;
    setStateFromMappings(responseMappings?.data, scanResult);
  }, [gatewayId, responseMappings, scanResult, setStateFromMappings]);

  const handleSelectDevice = (
    target: BACnetTarget,
    device?: ExistingDevice,
  ) => {
    selectDevice(target, device);
    if (!device) {
      const mapping = {
        bacnetDeviceId: target.deviceIdentifier,
        bacnetObjectId: target.objectIdentifier,
      };
      callDeleteMapping({ gatewayId, mapping });
    }
  };
  const handleSelectCapability = (
    target: BACnetTarget,
    capabilityId?: string,
  ) => {
    selectCapability(target, capabilityId);
    if (capabilityId) {
      const datapoint = mappingState.find(
        (dp) =>
          dp.deviceIdentifier === target.deviceIdentifier &&
          dp.objectIdentifier === target.objectIdentifier,
      );
      if (!datapoint?.device) {
        console.warn('Saving failed! Device not found for', target);
        return;
      }
      const mapping = {
        bacnetDeviceId: target.deviceIdentifier,
        bacnetObjectId: target.objectIdentifier,
        mappedDeviceId: datapoint.device.id,
        mappedCapabilityId: capabilityId,
      };
      callSaveMapping({ gatewayId, mapping });
    } else {
      const mapping = {
        bacnetDeviceId: target.deviceIdentifier,
        bacnetObjectId: target.objectIdentifier,
      };
      callDeleteMapping({ gatewayId, mapping });
    }
  };

  const handleShowCreateDevice = () => {
    setShowCreateDevice(true);
  };
  const handleCancelCreateDevice = () => {
    setShowCreateDevice(false);
  };

  const handleCreateDevice = (inputData: FieldDeviceCreateData) => {
    setShowCreateDevice(false);

    const attributeValues = inputData.attributeSets
      .map((set) =>
        set.attributes
          .filter((attribute) => attribute.required || attribute.value !== '')
          .map((attribute) => ({
            attributeId: attribute.id,
            value: attribute.value ?? '',
          })),
      )
      .flat();

    const device = {
      name: inputData.name,
      description: inputData.description,
      serialNo: inputData.serialNo,
      deviceModelId: inputData.deviceModelId,
      deviceIdentifier: inputData.deviceIdentifier,
      parentDeviceId: inputData.parentDeviceId,
    };

    createDevice({
      variables: {
        device,
        placementId: inputData.locationId,
        placementType: inputData.locationType ?? PlacementKind.Zone,
        attributeValues,
      },
    })
      .then((result) => {
        const createdDevice = result.data?.createDeviceWithPlacement;
        if (!createdDevice || !createdDevice.id || !createdDevice.deviceModel) {
          console.warn('create device failed', createdDevice);
          return createdDevice;
        }
        const newDevice = {
          ...device,
          id: createdDevice.id,
          deviceModel: createdDevice.deviceModel,
        };
        addDevice(mapDevice(newDevice));
        return createdDevice;
      })
      .catch(console.warn);
  };

  const handleDeleteMapping = async (target: BACnetTarget) => {
    deleteMapping(target);
    callDeleteMapping({
      gatewayId,
      mapping: {
        bacnetDeviceId: target.deviceIdentifier,
        bacnetObjectId: target.objectIdentifier,
      },
    });
  };

  const handleReview = () => {
    navigate(entityLocation(Entities.DEVICE, `${gatewayId}/mappings/review`), {
      state: {
        backToEntity: Entities.DEVICE,
        params: `${gatewayId}/mappings/edit`,
      },
    });
  };

  const scanResultWithMappings = useMemo(
    () =>
      (scanResult ?? []).map((sd) => {
        const mapping = mappingState.find(
          (dp) =>
            dp.deviceIdentifier === sd.deviceIdentifier &&
            dp.objectIdentifier === sd.objectIdentifier,
        );
        return {
          ...sd,
          ...mapping,
          id: sd.deviceIdentifier + sd.objectIdentifier,
        };
      }),
    [scanResult, mappingState],
  );

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  if (errorGateway) {
    return <ErrorMessage error={errorGateway} />;
  }
  if (!loadingGateway && !gateway) {
    return (
      <NotFound
        title={t('devices:details.notFound.title') ?? ''}
        subtitle={t('devices:details.notFound.subtitle') ?? ''}
        buttonText={t('devices:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <DeviceMappingsScanResultCard
            loadingAction={
              loadingDeleteMapping || loadingSaveMapping || loadingCreateDevice
            }
            loadingData={
              loading ||
              loadingGateway ||
              loadingScanResultDetails ||
              loadingMappings
            }
            scanFinishDate={scanFinished}
            scanResultWithMappings={scanResultWithMappings}
            allDevices={allDevices}
            onCreateDevice={
              hasPermission(Permissions.CommissioningWrite)
                ? handleShowCreateDevice
                : undefined
            }
            onSelectDevice={
              hasPermission(Permissions.CommissioningWrite)
                ? handleSelectDevice
                : undefined
            }
            onSelectCapability={
              hasPermission(Permissions.CommissioningWrite)
                ? handleSelectCapability
                : undefined
            }
            onDeleteMapping={
              hasPermission(Permissions.CommissioningWrite)
                ? handleDeleteMapping
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Button
            onClick={handleReview}
            color="primary"
            variant="contained"
            disabled={
              !hasPermission(Permissions.CommissioningRead) ||
              loadingDeleteMapping ||
              loadingSaveMapping ||
              loadingCreateDevice ||
              loading ||
              loadingGateway ||
              loadingScanResultDetails ||
              loadingMappings
            }
          >
            {t('devices:mappings.edit.reviewButton')}
          </Button>
        </Grid>
      </Grid>
      <DeviceDetailsDialogContainer
        open={showCreateDevice}
        gatewayId={gatewayId}
        placementId={placementId}
        placementType={placementType}
        usedDeviceIdentifiers={emptyDeviceIdentifiers}
        onCancel={handleCancelCreateDevice}
        onCreate={handleCreateDevice}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorScanResultDetails,
          errorMappings,
          error,
          errorDeleteMapping,
          errorSaveMapping,
          errorCreateDevice,
        ]}
      />
    </>
  );
};
