import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as Pages from '../../constants/Pages';
import { TenantsListContainer } from './list/TenantsListContainer';
import { TenantCreateContainer } from './create/TenantCreateContainer';
import { TenantDetailsContainer } from './details/TenantDetailsContainer';
import { TenantEditContainer } from './edit/TenantEditContainer';
import { TenantLocationContainer } from './location/TenantLocationContainer';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.TENANTS.icon;

export const TenantsRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<TenantsListContainer />} />
      <Route path="/new" element={<TenantCreateContainer />} />
      <Route path="/:tenantId" element={<TenantDetailsContainer />} />
      <Route path="/:tenantId/edit" element={<TenantEditContainer />} />
      <Route path="/:tenantId/location" element={<TenantLocationContainer />} />
    </Routes>
  );
};
