import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ApplicationsListContainer } from './list/ApplicationsListContainer';
import { ApplicationDetailsContainer } from './details/ApplicationDetailsContainer';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.APPLICATIONS.icon;

export const ApplicationsRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<ApplicationsListContainer />} />
      <Route path="/:applicationId" element={<ApplicationDetailsContainer />} />
    </Routes>
  );
};
