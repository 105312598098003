import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { countries } from 'countries-list';
import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { createAddressString } from '../../../utils/helpers';
import { TenantDetails } from './TenantDetails';
import { ErrorMessage } from '../../../components/Errors';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { TenantDeleteContainer } from '../delete/TenantDeleteContainer';
import { useHeader } from '../../../contexts/header-context';
import { getTenant } from '../../../api/user';
import { NotFound } from '../../errorScreens';

export const TenantDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['tenants', 'general']);
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const { setTitle } = useHeader();
  const [deleteMode, setDeleteMode] = useState(false);

  const {
    data: response,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['getTenant', tenantId],
    queryFn: () => getTenant(tenantId),
    onError: (err: ApiError) => err,
  });

  const tenant = response?.data ?? null;

  const hierarchy = tenant
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.TENANT,
            id: tenantId,
            name: tenant.name ?? '',
          },
        ],
        t,
      )
    : [];
  useBreadcrumb(hierarchy);

  const address = {
    street: tenant?.contactAddressStreet ?? '',
    no: tenant?.contactAddressHouseNumber ?? '',
    zip: tenant?.contactAddressZip ?? '',
    city: tenant?.contactAddressCity ?? '',
    country:
      countries[tenant?.contactAddressCountry as keyof typeof countries]
        ?.name ?? '',
  };

  const location = createAddressString(address);

  const contactName = `${tenant?.contactFirstName ?? ''} ${
    tenant?.contactLastName ?? ''
  }`;

  useEffect(() => {
    setTitle({ main: tenant?.name, sub: location });
  }, [setTitle, tenant, location]);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.TENANT, ''));
  const handleEdit = () =>
    navigate(`${entityLocation(Entities.TENANT, tenantId)}/edit`);
  const handleEditLocation = () =>
    navigate(`${entityLocation(Entities.TENANT, tenantId)}/location`);

  if (error instanceof Error) {
    if (error.status === 404) {
      return (
        <NotFound
          title={t('tenants:details.notFound.title') ?? ''}
          subtitle={t('tenants:details.notFound.subtitle') ?? ''}
          buttonText={t('tenants:details.notFound.buttonText') ?? ''}
          buttonOnClick={() => navigate(Entities.TENANT.path)}
        />
      );
    }
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <TenantDetails
        id={tenant?.id ?? ''}
        name={tenant?.name ?? ''}
        slug={tenant?.slug ?? ''}
        contactName={contactName}
        email={tenant?.contactEmail || '-'}
        address={address}
        onDelete={handleDelete}
        onEditInfo={handleEdit}
        onEditLocation={handleEditLocation}
        loading={loading}
      />
      <TenantDeleteContainer
        id={tenantId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
