import React from 'react';
import { createClasses } from '@kp/react-ui';
import { Breadcrumbs as MaterialBreadcrumbs, Typography } from '@mui/material';
import { useBreadcrumb } from '../../contexts/breadcrumb-context';
import { Link } from '../Link';

const classes = createClasses({
  link: {
    fontSize: '14px',
  },
});

export const Breadcrumbs: React.FC = () => {
  const links = useBreadcrumb();

  return (
    <MaterialBreadcrumbs
      maxItems={10}
      itemsAfterCollapse={5}
      aria-label="breadcrumb"
      className={classes.link}
    >
      {links.map((link, index) => {
        if (!link.location || index === links.length - 1) {
          return (
            <Typography key={link.location} className={classes.link}>
              {link.title}
            </Typography>
          );
        }
        return (
          <Link key={link.location} to={link.location} color="primary">
            {link.title}
          </Link>
        );
      })}
    </MaterialBreadcrumbs>
  );
};
