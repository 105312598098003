import React from 'react';
import { Button, Grid, Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { createRangeString } from '../../../utils/helpers';
import { Link } from '../../../components/Link';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import {
  DataTable,
  DataTableHeader,
  DataTableRow,
} from '../../../components/DataTable';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { ConditionalLoading } from '../../../components/ConditionalLoading';

const classes = createClasses({
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

export type DeviceModelDetailCapability = {
  id: string;
  name: string;
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
};

type DeviceModelDetailsCapabilitiesProps = {
  capabilities: Array<DeviceModelDetailCapability>;
  deviceModelId: string;
  isDeviceModelGlobal: boolean;
  loading: boolean;
  onAdd?: () => void;
};

export const DeviceModelDetailsCapabilities: React.FC<
  DeviceModelDetailsCapabilitiesProps
> = ({ capabilities, deviceModelId, isDeviceModelGlobal, loading, onAdd }) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  const headers: DataTableHeader[] = [
    {
      key: t('deviceModels:details.capabilities.headers.name'),
      value: t('deviceModels:details.capabilities.headers.name'),
    },
    {
      key: t('deviceModels:details.capabilities.headers.range'),
      value: t('deviceModels:details.capabilities.headers.range'),
    },
    {
      key: t('deviceModels:details.capabilities.headers.unit'),
      value: t('deviceModels:details.capabilities.headers.unit'),
      align: 'right',
    },
  ];

  const rows: DataTableRow[] = capabilities.map((capability) => {
    return {
      key: `${capability.id}`,
      cells: [
        {
          key: `${capability.id}-cell-name`,
          element: (
            <Link
              to={entityLocation(
                Entities.DEVICEMODEL,
                `${deviceModelId}/capabilities/${capability.id}`,
              )}
            >
              {capability.name}
            </Link>
          ),
        },
        {
          key: `${capability.id}-cell-range`,
          element: createRangeString(capability),
        },
        {
          key: `${capability.id}-cell-unit`,
          element: capability.unitSymbol
            ? `${capability.unitName} (${capability.unitSymbol})`
            : capability.unitName,
          align: 'right',
        },
      ],
    };
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <ConditionalLoading loading={loading}>
          {capabilities.length === 0 ? (
            <EmptyBanner
              description={t('deviceModels:details.capabilities.empty')}
              actionLabel={t('deviceModels:details.capabilities.add')}
              onClickAction={onAdd}
              disableAction={!onAdd || isDeviceModelGlobal}
            />
          ) : (
            <DataTable
              actions={
                <Tooltip
                  arrow
                  placement="top-end"
                  title={
                    isDeviceModelGlobal ? (
                      <div className={classes.tooltipContent}>
                        {t('deviceModels:details.capabilities.globalWarning')}
                      </div>
                    ) : (
                      ''
                    )
                  }
                  aria-label="add-capability-tooltip"
                >
                  <span>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="large"
                      startIcon={<Icons.Add />}
                      onClick={onAdd}
                      data-testid="add-capability-button"
                      disabled={!onAdd || isDeviceModelGlobal}
                    >
                      {t('deviceModels:details.capabilities.add')}
                    </Button>
                  </span>
                </Tooltip>
              }
              title={t('deviceModels:details.capabilities.title')}
              loading={loading}
              showPagination={false}
              headers={headers}
              rows={rows}
            />
          )}
        </ConditionalLoading>
      </Grid>
    </Grid>
  );
};
