/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const namespacesToPreload = [
  'applications',
  'buildings',
  'deviceModels',
  'historicalData',
  'storeys',
  'users',
  'attributes',
  'capabilities',
  'devices',
  'registeredApplications',
  'tenants',
  'zones',
  'auth',
  'dashboard',
  'general',
  'sites',
  'units',
  'components',
  'status',
  'gateways',
  'deployments',
  'errors',
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en', 'de'],
    debug: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
  });

i18n.loadNamespaces(namespacesToPreload);

export default i18n;
