import React from 'react';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ActionsBar } from '../../../../components/ActionsBar';
import { UserForm } from '../../common/UserForm';
import { UserRole } from '../../../../api/user';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

interface RoleWitStatus extends UserRole {
  enabled: boolean;
}
export interface UserInvitationCreateData {
  email: string;
  roles: RoleWitStatus[];
}

type UserInvitationCreateProps = Partial<UserInvitationCreateData> & {
  roles: UserRole[];
  loading?: boolean;
  onSubmit: (invitationData: UserInvitationCreateData) => void;
  onDiscard: () => void;
};

export const UserInvitationCreate: React.FC<UserInvitationCreateProps> = ({
  roles,
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['users', 'general']);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('general:errors.required') ?? '')
      .asciiEmail(t('general:validation.email')),
    roles: Yup.array()
      .of(
        Yup.object().shape({
          enabled: Yup.boolean(),
        }),
      )
      .test(
        'roles',
        t('users:invitation.create.validation.roles') ?? '',
        (values) => !!values?.some((role) => role.enabled),
      ),
  });
  const defaultValues: UserInvitationCreateData = { email: '', roles };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useDeepCompareEffect(() => reset({ roles }), [reset, roles]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('users:actions.addButton')}
        </Button>
      </ActionsBar>
      <UserForm type="invite" loading={loading} />
    </FormProvider>
  );
};
