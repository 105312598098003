import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { saveAs } from 'file-saver';
import { useMutation } from '@tanstack/react-query';
import {
  useMappingsCreateDeviceByNameLazyQuery,
  useMappingsCreateGatewayDetailsQuery,
} from '../../../../__generated__/types';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { ErrorAlert, InfoAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { useHeader } from '../../../../contexts/header-context';
import { ErrorMessage } from '../../../../components/Errors';
import {
  saveMappingDraft as saveMappingDraftFromAPI,
  postMappingDraftCSV,
  getMappingDraftCSV,
  deleteMappingDraft as deleteMappingDraftFromAPI,
} from '../../../../api/bacnet';
import { entityLocation } from '../../../../utils/entity';
import { DeviceMappingsScanResultCard } from './DeviceMappingsScanResultCard';
import { DeviceMappingsDeviceAssignmentCard } from './DeviceMappingsDeviceAssignmentCard';
import { useMappingState } from './useMappingState';
import { BACnetTarget } from './MappingStateUtils';
import { b64toBlob, UploadFile } from '../../../../utils/file-tools';
import {
  MappingDraftValidationErrorDialog,
  ValidationResult,
} from '../common/MappingDraftValidationErrorDialog';
import { BasicConfirmAlert } from '../../../../components/Alerts/BasicConfirmAlert';
import { useMappingDraftData } from './useMappingDraftData';
import { NotFound } from '../../../errorScreens';

export const DeviceMappingsDraftCreateContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general', 'errors']);
  const { hasPermission } = useAuth();
  const { gatewayId = '0' } = useParams();
  const navigate = useNavigate();
  const { setTitle, setLoading } = useHeader();

  const {
    loading,
    error,
    mappingState,
    setStateFromMappingDraft,
    getMappingDraft,
    selectDatapoints,
    selectDeviceModel,
    selectDevice,
    deleteDevice,
    deleteMapping,
    selectCapability,
    storeStateSnapshot,
    restoreStateSnapshot,
    resetStateSnapshot,
    deviceModelSelection,
    allCreatedDevices,
    selectedDeviceModel,
    selectedDevice,
  } = useMappingState();

  const [selectionMode, setSelectionMode] = useState(true);
  const [mappingDraftValidationResult, setMappingDraftValidationResult] =
    useState<ValidationResult>();
  const [shouldConfirmDeleteDraft, setShouldConfirmDeleteDraft] =
    useState(false);
  const [deviceExistsOn, setDeviceExistsOn] = useState<'PLATFORM' | 'DRAFT'>();

  const {
    scanFinished,
    scanResult,
    scanResultDevices = [],
    mappingDraft,
    validationResult,
    loading: loadingInitialData,
    error: errorInitialData,
  } = useMappingDraftData(gatewayId);

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (loadingInitialData) return;
    if (!mappingDraft) return;
    if (!scanResult) return;
    if (!validationResult?.isValid) {
      setInitialized(true);
      setMappingDraftValidationResult({
        showDialog: true,
        source: 'VALIDATION',
        ...validationResult,
      });
      return;
    }
    setStateFromMappingDraft(mappingDraft, scanResult)
      .then(() =>
        // only allow interaction once the initial state has been set
        setInitialized(true),
      )
      .catch(console.warn);
  }, [
    mappingDraft,
    validationResult,
    scanResult,
    loadingInitialData,
    setStateFromMappingDraft,
  ]);

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useMappingsCreateGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;
  const gatewayLocation = dataGateway?.placementOfDevice;

  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: gatewayId,
            name: gateway.name,
            query: { activeTab: 'FieldDevices' },
          },
        ],
        t,
      ).concat({
        title: t('devices:mappings.create.breadcrumb'),
        location: entityLocation(
          Entities.DEVICE,
          `${gatewayId}/mappings/draftCreate`,
        ),
      })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('devices:mappings.create.title'),
      sub:
        gatewayLocation?.siteName && gatewayLocation?.buildingName
          ? `${gatewayLocation?.siteName}, ${gatewayLocation?.buildingName}`
          : '',
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t, gatewayLocation]);

  const {
    mutate: saveMappingDraft,
    isLoading: loadingSaveMappingDraft,
    error: errorSaveMappingDraft,
  } = useMutation({
    mutationFn: saveMappingDraftFromAPI,
    onError: (err: ApiError) => err,
  });

  const {
    mutate: deleteMappingDraft,
    isLoading: loadingDeleteMappingDraft,
    error: errorDeleteMappingDraft,
  } = useMutation({
    mutationFn: deleteMappingDraftFromAPI,
    onError: (err: ApiError) => err,
  });

  const {
    mutateAsync: callDownloadMappingCSV,
    isLoading: loadingDownloadMappingCSV,
    error: errorDownloadMappingCSV,
  } = useMutation({
    mutationFn: getMappingDraftCSV,
    onError: (err: ApiError) => err,
  });

  const {
    mutateAsync: callUploadMappingCSV,
    isLoading: loadingUploadMappingCSV,
    error: errorUploadMappingCSV,
  } = useMutation({
    mutationFn: postMappingDraftCSV,
    onSuccess: (result) => {
      if (scanResult && result?.data) {
        setStateFromMappingDraft(result.data, scanResult);
      }
    },
    onError: (err: ApiError) => err,
  });

  const [
    loadDevicesByName,
    { loading: loadingDevicesByName, error: errorDevicesByName },
  ] = useMappingsCreateDeviceByNameLazyQuery();

  const handleCleanDraft = async () => {
    if (mappingDraft && scanResult) {
      setMappingDraftValidationResult({
        ...mappingDraftValidationResult,
        showDialog: false,
      });
      const newMappingState = await setStateFromMappingDraft(
        mappingDraft,
        scanResult,
      );
      const mappingDraftFromState = getMappingDraft(newMappingState);
      saveMappingDraft({ gatewayId, mappingDraft: mappingDraftFromState });
    }
  };

  const handleResetDraft = () => {
    deleteMappingDraft(gatewayId);
    setMappingDraftValidationResult({
      ...mappingDraftValidationResult,
      showDialog: false,
    });
    setStateFromMappingDraft({ devices: [] }, scanResult ?? []);
  };

  const handleSelectScanDate = (selection: BACnetTarget[]) => {
    selectDatapoints(selection);
  };

  const handleSelectCapability = (
    target: BACnetTarget,
    capabilityId: string,
  ) => {
    storeStateSnapshot();
    selectCapability(target, capabilityId);
  };

  const handleSelectDeviceModel = async (modelId?: string) => {
    setSelectionMode(false);
    storeStateSnapshot();
    selectDeviceModel(modelId);
  };

  const handleSelectDevice = async (deviceName?: string) => {
    setSelectionMode(false);
    storeStateSnapshot();
    selectDevice(deviceName);
  };

  const handleCreateDevice = async (deviceName: string) => {
    setSelectionMode(false);
    storeStateSnapshot();
    const deviceNameIsAmbiguous = allCreatedDevices.find(
      (device) => device.name === deviceName,
    );
    if (deviceNameIsAmbiguous) {
      setDeviceExistsOn('DRAFT');
      return;
    }
    // check if device name is unique and does not exist on platform
    const existingDevicesByName = (
      await loadDevicesByName({
        variables: {
          deviceNames: [deviceName],
        },
      })
    )?.data?.devices;

    if (existingDevicesByName?.items?.length) {
      setDeviceExistsOn('PLATFORM');
      return;
    }
    selectDevice(deviceName);
  };

  const handleConfirmDeviceExists = () => {
    setDeviceExistsOn(undefined);
  };

  const handleDeleteDevice = async (deviceName: string) => {
    const newMappingState = deleteDevice(deviceName);
    const mappingDraftFromState = getMappingDraft(newMappingState);
    saveMappingDraft({ gatewayId, mappingDraft: mappingDraftFromState });
  };

  const handleDeleteMapping = async (target: BACnetTarget) => {
    const newMappingState = deleteMapping(target);
    const mappingDraftFromState = getMappingDraft(newMappingState);
    saveMappingDraft({ gatewayId, mappingDraft: mappingDraftFromState });
  };

  const handleDone = () => {
    const mappingDraftFromState = getMappingDraft();
    saveMappingDraft({ gatewayId, mappingDraft: mappingDraftFromState });
    setSelectionMode(true);
    selectDatapoints([]);
    resetStateSnapshot();
  };

  const handleCancel = () => {
    setSelectionMode(true);
    selectDatapoints([]);
    restoreStateSnapshot();
  };

  const handleDiscovery = () => {
    navigate(entityLocation(Entities.DEVICE, `${gatewayId}/discovery`), {
      state: {
        backToEntity: Entities.DEVICE,
        params: `${gatewayId}/mappings/draftCreate`,
      },
    });
  };

  const handleReview = () => {
    navigate(
      entityLocation(Entities.DEVICE, `${gatewayId}/mappings/draftReview`),
      {
        state: {
          backToEntity: Entities.DEVICE,
          params: `${gatewayId}/mappings/draftCreate`,
        },
      },
    );
  };

  const handleDownloadMappingCSV = async () => {
    const mappings = await callDownloadMappingCSV(gatewayId);
    if (!mappings?.data?.fileContent) return;
    const blob = await b64toBlob(mappings.data.fileContent);
    saveAs(blob, mappings.data.fileName);
  };

  const handleUploadCSV = async (data: UploadFile) => {
    let uploadError: ApiError | undefined;
    const fileContent = await data.content.text();
    try {
      await callUploadMappingCSV({
        gatewayId,
        csv: fileContent,
      });
    } catch (err) {
      uploadError = err as ApiError;
    }
    if (!uploadError || !uploadError.body) return;
    const { code, message } = uploadError.body;
    if (code !== 'DRAFT_VALIDATION_ERROR' || !message) return;

    try {
      const errors = JSON.parse(message) as ValidationResult['errors'];
      setMappingDraftValidationResult({
        showDialog: true,
        source: 'UPLOAD_CSV',
        isValid: false,
        errors,
      });
    } catch (e) {
      console.warn('Error parsing CSV Upload Error', e);
    }
  };

  const confirmDeleteDraft = () => setShouldConfirmDeleteDraft(true);
  const handleCancelDeleteDraft = () => setShouldConfirmDeleteDraft(false);
  const handleDeleteDraft = () => {
    setShouldConfirmDeleteDraft(false);
    deleteMappingDraft(gatewayId);
    setStateFromMappingDraft({ devices: [] }, scanResult ?? []);
  };

  const navigateBack = () => {
    navigate(
      entityLocation(Entities.DEVICE, gatewayId, { activeTab: 'FieldDevices' }),
    );
  };

  const handleConfirmError = () => {
    setMappingDraftValidationResult({
      ...mappingDraftValidationResult,
      showDialog: false,
    });
  };

  const assignedCapabilities = useMemo(
    () =>
      mappingState.map((dp) => ({
        deviceName: dp.device?.name ?? '',
        capabilityId: dp.capability?.id ?? '',
      })),
    [mappingState],
  );
  const hasSelectedItems = useMemo(
    () => mappingState.filter((dp) => dp.isSelected).length > 0,
    [mappingState],
  );
  const hasUnfinishedMappings = useMemo(
    () =>
      mappingState.filter((dp) => dp.model && (!dp.device || !dp.capability))
        .length === 0,
    [mappingState],
  );

  const scanResultWithMappings = useMemo(
    () =>
      (scanResult ?? []).map((sd, id) => {
        const mapping = mappingState.find(
          (dp) =>
            dp.deviceIdentifier === sd.deviceIdentifier &&
            dp.objectIdentifier === sd.objectIdentifier,
        );
        return {
          id,
          ...sd,
          ...mapping,
        };
      }),
    [scanResult, mappingState],
  );
  const doneEnabled = hasSelectedItems && hasUnfinishedMappings;
  const cancelEnabled = hasSelectedItems;
  const hasDraft = !!mappingState?.length;

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  if (errorGateway) {
    return <ErrorMessage error={errorGateway} />;
  }
  if (!loadingGateway && !gateway) {
    return (
      <NotFound
        title={t('devices:details.notFound.title') ?? ''}
        subtitle={t('devices:details.notFound.subtitle') ?? ''}
        buttonText={t('devices:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <DeviceMappingsScanResultCard
            initialLoading={!initialized}
            loading={loadingUploadMappingCSV}
            downloading={loadingDownloadMappingCSV}
            uploading={loadingUploadMappingCSV}
            selectionMode={selectionMode}
            scanFinishDate={scanFinished}
            scanResultDevices={scanResultDevices}
            scanResultWithMappings={scanResultWithMappings}
            onDeleteDevice={
              hasPermission(Permissions.CommissioningWrite)
                ? handleDeleteDevice
                : undefined
            }
            onSelectCapability={
              hasPermission(Permissions.CommissioningWrite)
                ? handleSelectCapability
                : undefined
            }
            onSelectScanDate={
              hasPermission(Permissions.CommissioningWrite)
                ? handleSelectScanDate
                : undefined
            }
            onDiscovery={handleDiscovery}
            onDownload={
              hasPermission(Permissions.CommissioningRead)
                ? handleDownloadMappingCSV
                : undefined
            }
            onUpload={
              hasPermission(Permissions.CommissioningWrite)
                ? handleUploadCSV
                : undefined
            }
            onDeleteMapping={
              hasPermission(Permissions.CommissioningWrite)
                ? handleDeleteMapping
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <DeviceMappingsDeviceAssignmentCard
            loading={
              !initialized ||
              loadingInitialData ||
              loading ||
              loadingDevicesByName
            }
            assignedCapabilities={assignedCapabilities}
            selectedDeviceModel={selectedDeviceModel}
            deviceModels={deviceModelSelection}
            selectedDevice={selectedDevice}
            allDevices={allCreatedDevices}
            onDone={doneEnabled ? handleDone : undefined}
            onCancel={cancelEnabled ? handleCancel : undefined}
            onSelectDeviceModel={
              hasPermission(Permissions.CommissioningWrite)
                ? handleSelectDeviceModel
                : undefined
            }
            onSelectDevice={
              hasPermission(Permissions.CommissioningWrite)
                ? handleSelectDevice
                : undefined
            }
            onCreateDevice={
              hasPermission(Permissions.CommissioningWrite)
                ? handleCreateDevice
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                disabled={
                  !initialized ||
                  !hasDraft ||
                  !hasPermission(Permissions.CommissioningWrite) ||
                  !selectionMode ||
                  loadingSaveMappingDraft ||
                  loadingInitialData ||
                  loadingDeleteMappingDraft ||
                  loading
                }
                onClick={confirmDeleteDraft}
                color="secondary"
                variant="outlined"
              >
                {t('devices:mappings.review.deleteButton')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleReview}
                color="primary"
                variant="contained"
                disabled={
                  !initialized ||
                  !hasDraft ||
                  !hasPermission(Permissions.CommissioningRead) ||
                  !selectionMode ||
                  loadingSaveMappingDraft ||
                  loadingInitialData ||
                  loadingDeleteMappingDraft ||
                  loading
                }
              >
                {t('devices:mappings.create.reviewButton')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MappingDraftValidationErrorDialog
        validationResult={mappingDraftValidationResult}
        actions={
          mappingDraftValidationResult?.source === 'VALIDATION'
            ? [
                {
                  onClick: navigateBack,
                  title: t('general:buttons.cancel'),
                },
                {
                  onClick: handleResetDraft,
                  title: t(
                    'devices:mappings.create.mappingDraftValidationDialog.buttons.resetAndSave',
                  ),
                },
                {
                  onClick: handleCleanDraft,
                  title: t(
                    'devices:mappings.create.mappingDraftValidationDialog.buttons.cleanAndSave',
                  ),
                },
              ]
            : [
                {
                  onClick: handleConfirmError,
                  title: t('general:buttons.ok'),
                },
              ]
        }
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorUploadMappingCSV?.body.code !== 'DRAFT_VALIDATION_ERROR'
            ? errorUploadMappingCSV
            : undefined,
          errorGateway,
          errorInitialData,
          errorSaveMappingDraft,
          error,
          errorDownloadMappingCSV,
          errorDeleteMappingDraft,
          errorDevicesByName,
        ]}
      />
      <BasicConfirmAlert
        open={shouldConfirmDeleteDraft}
        onCancel={handleCancelDeleteDraft}
        onConfirm={handleDeleteDraft}
        title={t('devices:mappings.create.deleteMappingDraftAlert.title')}
        message={t('devices:mappings.create.deleteMappingDraftAlert.message')}
      />
      <InfoAlert
        open={!!deviceExistsOn}
        maxWidth="md"
        onClose={handleConfirmDeviceExists}
        title={t('devices:mappings.create.deviceExistsDialog.title')}
        message={
          deviceExistsOn === 'DRAFT'
            ? t(
                'devices:mappings.create.deviceExistsDialog.deviceExsistsInDraftMessage',
              )
            : t(
                'devices:mappings.create.deviceExistsDialog.deviceExsistsOnPlatformMessage',
              )
        }
      />
    </>
  );
};
