import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { Capability } from './MappingStateUtils';
import { MatchIconWithTooltip } from './MatchIconWithTooltip';
import { UNITLESS_URI } from '../../../../constants/Misc';

const classes = createClasses({
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  select: {
    fontSize: '0.875rem',
  },
});

export type CapabilitySelectionDropdownProps = {
  onSelect: (event: SelectChangeEvent<string>) => void;
  capabilityOptions: Capability[];
  selectedCapabilityId?: string;
  datapointUnitName?: string;
  datapointUnitUris?: string[];
};
export const CapabilitySelectionDropdown: React.FC<
  CapabilitySelectionDropdownProps
> = ({
  selectedCapabilityId,
  onSelect,
  capabilityOptions,
  datapointUnitName,
  datapointUnitUris,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  return (
    <FormControl variant="standard" fullWidth>
      <Select
        defaultValue=""
        value={selectedCapabilityId ?? ''}
        onChange={onSelect}
        renderValue={(cId) =>
          capabilityOptions.find(({ id }: { id: string }) => id === cId)?.name
        }
        disableUnderline
        SelectDisplayProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'data-testid': 'capability-selection-dropdown',
        }}
        className={classes.select}
      >
        <MenuItem value="" dense>
          <em>{t('devices:mappings.edit.scanResult.noneFemale')}</em>
        </MenuItem>
        {capabilityOptions.map((capabilityOption) => {
          const unitsMatch =
            datapointUnitUris?.includes(UNITLESS_URI) ||
            datapointUnitName === capabilityOption.unitName;
          return (
            <MenuItem
              key={capabilityOption.id}
              value={capabilityOption.id}
              disabled={capabilityOption.isMapped || !unitsMatch}
              className={classes.menuItem}
              dense
            >
              {capabilityOption.name}
              <MatchIconWithTooltip
                capability={capabilityOption}
                unitsMatch={unitsMatch}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
