import { createClasses } from '@kp/react-ui';
import React from 'react';
import {
  tooltipClasses,
  Tooltip,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Color } from '../../../../constants/Colors';

const classes = createClasses({
  tooltip: {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      backgroundColor: Color.white,
      border: '1px solid',
      borderColor: Color.border,
    },
  },
  tooltipHeader: {
    color: Color.black,
    fontSize: '130%',
  },
  highlightMatch: {
    backgroundColor: Color.assignedRow,
  },
});

type DeviceModel = {
  id: string;
  name: string;
  deviceModelCapabilities: Array<{
    id: string;
    name: string;
  }>;
};
type Device = {
  name: string;
  deviceModelId: string;
};

type DeviceTooltipProps = {
  device: Device;
  deviceModels: DeviceModel[];
  assignedCapabilities: Array<{ deviceName: string; capabilityId: string }>;
};

export const DeviceTooltip: React.FC<DeviceTooltipProps> = ({
  device,
  deviceModels,
  assignedCapabilities,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const deviceModel = deviceModels.find((m) => m.id === device.deviceModelId);
  if (!deviceModel) return null;
  const capabilitiesCount = deviceModel.deviceModelCapabilities.length;
  const assignedCapabilitiesForDevice = assignedCapabilities.filter(
    (c) => c.deviceName === device.name && c.capabilityId,
  );
  const assignedCapabilitiesCount = assignedCapabilitiesForDevice.length;

  return (
    <Tooltip
      placement="left"
      classes={{ popper: classes.tooltip }}
      title={
        <div>
          <div className={classes.tooltipHeader}>
            {t(
              'devices:mappings.create.scanResult.theseCapabilitiesAreAssigned',
              {
                assignedCapabilitiesCount,
                capabilitiesCount,
              },
            )}
          </div>
          <TableContainer>
            <Table size="small">
              <TableHead></TableHead>
              <TableBody>
                {deviceModel.deviceModelCapabilities?.map((capability) => {
                  const assignedCapability = assignedCapabilitiesForDevice.find(
                    (c) => c.capabilityId === capability.id,
                  );
                  return (
                    <TableRow
                      key={capability.id}
                      className={classNames(
                        assignedCapability && classes.highlightMatch,
                      )}
                    >
                      <TableCell>{capability.name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    >
      <span>
        {assignedCapabilitiesCount}/{capabilitiesCount}
      </span>
    </Tooltip>
  );
};
