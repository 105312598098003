import React from 'react';
import { createClasses } from '@kp/react-ui';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataTypeValue } from '../../../components/DataTypeValue';
import { AttributeDecorator } from '../../../components/AttributeDecorator';
import { DataTypes } from '../../../__generated__/types';

const classes = createClasses({
  value: {
    fontSize: 13,
    margin: 0,
  },
  name: {
    display: 'flex',
    justifyContent: 'flexStart',
    alignItems: 'center',
  },
});

interface DeviceDetailsAttributesProps {
  title: string;
  attributes: Array<{
    id: string;
    name: string;
    exists?: boolean;
    value: string;
    dataType: DataTypes | null;
    required: boolean;
  }>;
}

export const DeviceDetailsAttributes: React.FC<
  DeviceDetailsAttributesProps
> = ({ title, attributes }) => {
  const { t } = useTranslation(['devices', 'general']);
  return (
    <TableContainer component={CardContent}>
      <Table aria-label="Attributes Table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>{title}</TableCell>
            <TableCell align="right">
              {t('devices:details.attributes.value')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attributes.map((attribute) => (
            <TableRow key={attribute.id}>
              <TableCell className={classes.name}>
                {attribute.name}
                <div data-testid={`attribute-decorator-${attribute.id}`}>
                  <AttributeDecorator
                    required={attribute.required}
                    hasValue={attribute.value.trim().length > 0}
                  />
                </div>
              </TableCell>
              <TableCell align="right">
                {attribute.exists && (
                  <DataTypeValue
                    dataType={attribute.dataType}
                    value={attribute.value}
                    className={classes.value}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
