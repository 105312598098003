import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useLocaleDateFns } from '../../../hooks/date-fns';

export type DeploymentStats = {
  successful: number;
  lastSuccessful?: string;
  failed: number;
  lastFailed?: string;
};
export type DeploymentStatsTableProps = {
  stats?: DeploymentStats;
};

export const DeploymentStatsTable: React.FC<DeploymentStatsTableProps> = ({
  stats,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const { formatDefaultStr } = useLocaleDateFns();

  const renderValue = (v?: number) => (v || v === 0 ? v : '-');
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              {t('devices:details.deployCard.deploymentStatsTable.header')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {t(
                'devices:details.deployCard.deploymentStatsTable.lastSuccessful',
              )}
            </TableCell>
            <TableCell align="right">
              {stats?.lastSuccessful
                ? formatDefaultStr(stats.lastSuccessful)
                : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t(
                'devices:details.deployCard.deploymentStatsTable.totalSuccessful',
              )}
            </TableCell>
            <TableCell align="right">
              {renderValue(stats?.successful)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t('devices:details.deployCard.deploymentStatsTable.lastFailed')}
            </TableCell>
            <TableCell align="right">
              {stats?.lastFailed ? formatDefaultStr(stats.lastFailed) : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t('devices:details.deployCard.deploymentStatsTable.totalFailed')}
            </TableCell>
            <TableCell align="right">{renderValue(stats?.failed)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
