import { useSubscription, gql, ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ConnectionStates, HeartbeatStates } from '../__generated__/types';

export type GatewayStatus = {
  heartbeatState?: HeartbeatStates;
  connectionState?: ConnectionStates;
  firstConnected?: string;
  lastConnected?: string;
};
export type UseGatewayStatusSubscription = GatewayStatus & {
  error: ApolloError | undefined;
};

export const useGatewayStatusSubscription = (
  deviceId?: string,
): UseGatewayStatusSubscription => {
  const [details, setDetails] = useState<GatewayStatus>();

  const GATEWAY_STATUS_SUBSCRIPTION = gql`
    subscription OnGatewayStatus($deviceId: UUID!) {
      deviceStateChanged(deviceId: $deviceId) {
        deviceId
        heartbeatState
        connectionState
        firstConnected
        lastConnected
      }
    }
  `;

  const { error: errorSubscription } = useSubscription(
    GATEWAY_STATUS_SUBSCRIPTION,
    {
      skip: !deviceId,
      variables: { deviceId },
      onData: (result) => {
        setDetails({
          heartbeatState: result.data.data?.deviceStateChanged.heartbeatState,
          connectionState: result.data.data?.deviceStateChanged.connectionState,
          firstConnected: result.data.data?.deviceStateChanged.firstConnected,
          lastConnected: result.data.data?.deviceStateChanged.lastConnected,
        });
      },
    },
  );

  useEffect(() => {
    if (errorSubscription) {
      console.warn('Subscription Error', errorSubscription);
    }
  }, [errorSubscription]);

  return {
    ...details,
    error: errorSubscription,
  };
};
