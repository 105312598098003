import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ErrorMessage } from '../../../components/Errors';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import * as Pages from '../../../constants/Pages';
import {
  DeviceDiscoveryCardContainer,
  UploadStatus,
} from './DeviceDiscoveryCardContainer';
import { useGatewayDetailsSubscription } from '../../../hooks/useGatewayDetailsSubscription';
import { ObjectDiscoveryCardContainer } from './ObjectDiscoveryCardContainer';
import {
  ConnectionStates,
  useGatewayNameQuery,
  useGatewayStatusQuery,
} from '../../../__generated__/types';
import { useGatewayStatusSubscription } from '../../../hooks/useGatewayStatusSubscription';

type DiscoveryContainerInternalProps = {
  gatewayId: string;
};
const DiscoveryContainerInternal: React.FC<DiscoveryContainerInternalProps> = ({
  gatewayId,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const [uploading, setUploading] = useState(false);

  const {
    details: gatewayDetails,
    loading: loadingGetGatewayDetails,
    error: errorGetGatewayDetails,
  } = useGatewayDetailsSubscription(gatewayId);

  const handleUploadStatus = (status: UploadStatus) => {
    setUploading(status === UploadStatus.uploading);
  };

  const { data: initialGatewayStatus } = useGatewayStatusQuery({
    variables: { deviceId: gatewayId },
  });
  const { connectionState: connectionStatus } =
    useGatewayStatusSubscription(gatewayId);

  const gatewayConnectionStatus = useMemo(() => {
    if (!connectionStatus) {
      return (
        initialGatewayStatus?.device?.connectionState ??
        ConnectionStates.Unknown
      );
    }
    return connectionStatus;
  }, [connectionStatus, initialGatewayStatus]);

  if (errorGetGatewayDetails) {
    return (
      <ErrorMessage
        error={errorGetGatewayDetails}
        message={t('errors:notFound.gateway')}
      />
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DeviceDiscoveryCardContainer
            loading={loadingGetGatewayDetails}
            gatewayId={gatewayId}
            commissioningStatus={gatewayDetails?.status}
            gatewayStatus={gatewayConnectionStatus}
            onUpload={handleUploadStatus}
          />
        </Grid>
        <Grid item xs={12}>
          <ObjectDiscoveryCardContainer
            loading={loadingGetGatewayDetails}
            gatewayId={gatewayId}
            commissioningStatus={gatewayDetails?.status}
            gatewayStatus={gatewayConnectionStatus}
            uploading={uploading}
          />
        </Grid>
      </Grid>
    </>
  );
};

// Delay the Rendering because of this issue with Apollo subscriptions:
// https://github.com/apollographql/apollo-client/issues/7608
export const DiscoveryContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general']);
  const { gatewayId } = useParams();

  const { setTitle, setIcon, setLoading } = useHeader();
  const [isShown, setIsShown] = useState(false);

  const { data, loading: gatewayLoading } = useGatewayNameQuery({
    skip: !gatewayId,
    variables: { deviceId: gatewayId },
  });
  const device = data?.device;

  const hierarchy = device
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: device.id,
            name: device.name || device.id,
            query: { activeTab: 'DeviceDiscovery' },
          },
        ],
        t,
      ).concat({
        title: t('devices:breadcrumb.discovery'),
        location: entityLocation(Entities.DEVICE, `${gatewayId}/discovery`),
      })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('devices:discovery.title') });
    setIcon(Pages.DEVICES.icon);
  }, [setTitle, setIcon, t]);
  useEffect(() => {
    setLoading(gatewayLoading);
  }, [setLoading, gatewayLoading]);

  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, 300);
  }, []);

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  return isShown ? <DiscoveryContainerInternal gatewayId={gatewayId} /> : null;
};
