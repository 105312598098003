import React, { useEffect, useMemo } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useHeader } from '../../../contexts/header-context';
import { useFilters } from '../../../hooks/filters';
import { RegisteredApplicationsList } from './RegisteredApplicationsList';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { getRegisteredApplications } from '../../../api/application';
import { SortingFilter } from '../../../api/common';

export const RegisteredApplicationsListContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['registeredApplications', 'general']);
  const { page, pageSize, setPage, setPageSize } = useFilters();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.REGISTERED_APPLICATION, t),
    location: Entities.REGISTERED_APPLICATION.path,
  });

  const filter: SortingFilter<'name'> = useMemo(
    () => ({
      skip: page * pageSize,
      limit: pageSize,
      sort: 'name',
    }),
    [page, pageSize],
  );

  const {
    data: response,
    error,
    isLoading: loading,
  } = useQuery({
    queryKey: ['getRegisteredApplications', filter],
    queryFn: () => getRegisteredApplications(filter),
  });

  const applications = response?.data ?? [];

  useEffect(() => {
    setTitle({ main: t('registeredApplications:title') });
  }, [setTitle, t]);

  if (error instanceof Error) {
    return <ErrorMessage error={error} />;
  }

  const handleAdd = () =>
    navigate(entityLocation(Entities.REGISTERED_APPLICATION, `new`));

  return (
    <RegisteredApplicationsList
      loading={loading}
      page={page}
      pageSize={pageSize}
      count={response?.meta?.totalSize ?? 0}
      onChangePageSize={setPageSize}
      onChangePage={setPage}
      applications={applications}
      onAdd={
        hasPermission(Permissions.RegisteredApplicationsWrite)
          ? handleAdd
          : undefined
      }
    />
  );
};
