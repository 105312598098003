import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../FancyCard';
import { ConnectionStates, StatusIndicator } from '../StatusIndicator';
import {
  GridFooter,
  GridFooterContainer,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbarFilterButton,
  GridValueGetterParams,
  DataGrid,
} from '../DataGrid';

export { ConnectionStates };
export enum Type {
  gateway = 'gateway',
  fieldDevice = 'field-device',
}
export type Gateway = {
  name: string;
  id: string;
  model: string;
  connectionState: ConnectionStates;
  capabilitiesCount: number;
  childCapabilitiesCount?: number;
  mappingCount?: number;
};
type GatewayListProps = {
  loading?: boolean;
  gateways: Gateway[];
  selectedId?: string;
  onSelect: (id?: string) => void;
  onAdd?: () => void;
};

const Toolbar = () => (
  <GridFooterContainer>
    <GridToolbarFilterButton />
    <GridFooter />
  </GridFooterContainer>
);

export const GatewayList: React.FC<GatewayListProps> = ({
  loading,
  gateways,
  selectedId,
  onSelect,
  onAdd,
}) => {
  const { t } = useTranslation(['components', 'general']);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );

  const handleSelect = ([currentId]: GridRowSelectionModel) => {
    if (!currentId) {
      onSelect(undefined);
      setSelectionModel([]);
      return;
    }
    const [previousId] = selectionModel;
    const newSelectedId =
      previousId === currentId ? undefined : String(currentId);
    const newSelection = previousId === currentId ? [] : [currentId];
    onSelect(newSelectedId);
    setSelectionModel(newSelection);
  };

  useEffect(() => {
    // set preselected row when gateways are loaded
    // onSelectionModelChange is triggered when the rows change!
    if (gateways.length) {
      onSelect(selectedId);
      setSelectionModel(selectedId ? [selectedId] : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, gateways, onSelect]);

  const handleAdd = () => {
    if (onAdd) onAdd();
    setSelectionModel([]);
  };

  const columns = [
    {
      field: 'connectionState',
      headerName: t('components:gatewayList.headers.connectionState'),
      description: t(
        'components:gatewayList.headers.connectionStateDescription',
      ),
      width: 60,
      renderCell: (params: GridRenderCellParams) => (
        <StatusIndicator status={params.row.connectionState} />
      ),
      cellClassName: 'MuiDataGrid-cell--textCenter',
    },
    {
      field: 'name',
      headerName: t('components:gatewayList.headers.name'),
      description: t('components:gatewayList.headers.nameDescription'),
      flex: 1,
    },
    {
      field: 'model',
      headerName: t('components:gatewayList.headers.deviceModel'),
      description: t('components:gatewayList.headers.deviceModelDescription'),
      flex: 1,
    },
    {
      field: 'description',
      headerName: t('components:gatewayList.headers.description'),
      description: t('components:gatewayList.headers.descriptionDescription'),
      flex: 1,
    },

    {
      field: 'childCount',
      headerName: t('components:gatewayList.headers.childCount'),
      description: t('components:gatewayList.headers.childCountDescription'),
      flex: 1,
    },
    {
      field: 'mappedCapabilities',
      headerName: t('components:gatewayList.headers.mappedCapabilities'),
      description: t(
        'components:gatewayList.headers.mappedCapabilitiesDescription',
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.mappingCount ?? '-'}/${
          params.row.childCapabilitiesCount
        }`,
    },
  ];

  return (
    <FancyCard
      title={t('components:gatewayList.title')}
      loading={loading}
      actions={
        onAdd && (
          <IconButton
            aria-label="add-button"
            data-testid="add-button"
            onClick={handleAdd}
          >
            <Icons.Add fontSize="medium" color="primary" />
          </IconButton>
        )
      }
    >
      <DataGrid
        loading={loading}
        slots={{
          toolbar: Toolbar,
        }}
        rows={gateways}
        columns={columns}
        onRowSelectionModelChange={handleSelect}
        rowSelectionModel={selectionModel}
        hideFooter
        hideFooterSelectedRowCount
      />
    </FancyCard>
  );
};
