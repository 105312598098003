import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../../components/Alerts';
import { entityLocation } from '../../../../utils/entity';
import * as Entities from '../../../../constants/Entities';
import {
  DataTypes,
  useDeviceModelCapabilityCreateDetailsQuery,
  useDeviceModelCapabilityCreateMutation,
} from '../../../../__generated__/types';
import {
  DeviceModelCapabilityData,
  DeviceModelCapabilityForm,
} from '../common/DeviceModelCapabilityForm';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { ErrorMessage } from '../../../../components/Errors';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';
import { UNITLESS_URI } from '../../../../constants/Misc';

export const DeviceModelCapabilityCreateContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { deviceModelId } = params;
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useDeviceModelCapabilityCreateDetailsQuery({
    variables: { deviceModelId },
  });

  const deviceModel = data?.deviceModel;

  const allUnits = (data?.units?.items ?? []).map((unit) => ({
    id: unit.id,
    unitName: unit.name,
    unitSymbol: unit.unitSymbol ?? undefined,
    uris: unit.uris ?? [],
  }));
  const allCapabilities = data?.capabilities?.items;

  const existingFieldSelectors = (deviceModel?.deviceModelCapabilities ?? [])
    .map((dmc) => dmc.fieldSelector)
    .filter((selector) => selector) as string[];

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.DEVICEMODEL,
        id: deviceModelId,
        name: deviceModel?.name || deviceModelId,
      },
    ],
    t,
  ).concat([
    {
      title: t('deviceModels:capabilities.breadcrumb.create'),
      location: entityLocation(
        Entities.DEVICEMODEL,
        `${deviceModelId}/capabilities/new`,
      ),
    },
  ]);
  useBreadcrumb(hierarchy);

  const [
    createDeviceModelCapability,
    { loading: loadingCreate, error: errorCreate },
  ] = useDeviceModelCapabilityCreateMutation({
    onCompleted: () =>
      navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId)),
    onError: (e) => console.warn(e),
  });

  const handleDiscard = () => {
    navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId));
  };

  const handleCreate = (inputData: DeviceModelCapabilityData) => {
    const { capabilityId } = inputData;
    const capability = allCapabilities?.find(({ id }) => id === capabilityId);
    const isBoolean = capability?.dataType === DataTypes.Bool;
    const notApplicableUnit = allUnits.find(({ uris }) =>
      uris.includes(UNITLESS_URI),
    );
    return createDeviceModelCapability({
      variables: {
        deviceModelCapabilityInput: {
          capabilityId,
          deviceModelId,
          fieldSelector: inputData.fieldSelector ?? '',
          technicalMax: isBoolean ? undefined : Number(inputData.technicalMax),
          technicalMin: isBoolean ? undefined : Number(inputData.technicalMin),
          unitId: isBoolean ? notApplicableUnit?.id ?? '' : inputData.unitId,
        },
      },
    });
  };

  useEffect(() => {
    setLoading(loading);
    setTitle({ main: t('deviceModels:capabilities.breadcrumb.create') });
  }, [setLoading, loading, setTitle, t]);

  if (!loading && !deviceModel) {
    return (
      <ErrorMessage
        error={new Error(t('deviceModels:capabilities.dataNotFound') ?? '')}
      />
    );
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (deviceModel?.isGlobal) {
    return (
      <ErrorMessage error={new Error(t('deviceModels:globalWarning') ?? '')} />
    );
  }

  return (
    <>
      <DeviceModelCapabilityForm
        loading={loading || loadingCreate}
        capabilityId="0"
        unitId="0"
        allUnits={allUnits ?? []}
        allCapabilities={allCapabilities ?? []}
        existingFieldSelectors={existingFieldSelectors}
        technicalMax=""
        technicalMin=""
        fieldSelector=""
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorCreate]}
      />
    </>
  );
};
