import React, { useEffect, useMemo, useState } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DeviceDetails } from './DeviceDetails';
import {
  ConnectionStates,
  HeartbeatStates,
  useDeviceDetailsQuery,
  useGatewayStatusQuery,
} from '../../../__generated__/types';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { DeviceDeleteContainer } from '../delete/DeviceDeleteContainer';
import { entityLocation } from '../../../utils/entity';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';
import {
  useGatewayStatusSubscription,
  GatewayStatus,
} from '../../../hooks/useGatewayStatusSubscription';

export const DeviceDetailsContainer: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { deviceId } = params;
  const { setTitle, setLoading } = useHeader();
  const { t } = useTranslation(['devices', 'general']);
  const { hasPermission } = useAuth();

  const [deleteMode, setDeleteMode] = useState(false);

  const { data, loading, error } = useDeviceDetailsQuery({
    variables: { deviceId },
  });
  const device = data?.device;
  const isGateway = data?.device?.deviceModel?.deviceType?.isGateway ?? false;
  const placement = data?.placementOfDevice;

  const attributeValues = device?.deviceAttributeValues;
  const { deviceModelAttributeSets } = device?.deviceModel ?? {};

  const hierarchy = [
    ...(placement?.siteId && placement?.siteName
      ? [
          {
            type: Entities.SITE,
            id: placement.siteId,
            name: placement.siteName,
          },
        ]
      : []),
    ...(placement?.buildingId && placement?.buildingName
      ? [
          {
            type: Entities.BUILDING,
            id: placement.buildingId,
            name: placement.buildingName,
          },
        ]
      : []),
    ...(placement?.storeyId && placement?.storeyName
      ? [
          {
            type: Entities.STOREY,
            id: placement.storeyId,
            name: placement.storeyName,
          },
        ]
      : []),
    ...(placement?.zoneId && placement?.zoneName
      ? [
          {
            type: Entities.ZONE,
            id: placement.zoneId,
            name: placement.zoneName,
          },
        ]
      : []),
    ...(device?.name
      ? [
          {
            type: Entities.DEVICE,
            id: deviceId,
            name: device.name,
          },
        ]
      : []),
  ];

  useBreadcrumb(
    hierarchy.length > 0 ? makeHierarchyBreadcrumb(hierarchy, t) : [],
  );

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.DEVICE, ''));
  const handleEdit = () =>
    navigate(
      `${entityLocation(Entities.DEVICE, deviceId)}/edit${
        isGateway ? 'gateway' : 'fielddevice'
      }`,
    );
  const handleEditAttributes = () =>
    navigate(`${entityLocation(Entities.DEVICE, deviceId)}/attributes`);
  const handleEditLocation = () =>
    navigate(`${entityLocation(Entities.DEVICE, deviceId)}/location`);

  const capabilities = (device?.deviceModel?.deviceModelCapabilities ?? [])
    .map((capability) => ({
      id: capability?.id ?? '',
      name: capability?.capability?.name ?? '',
      technicalMin: capability?.technicalMin ?? undefined,
      technicalMax: capability?.technicalMax ?? undefined,
      unitName: capability?.unit?.name ?? '',
      unitSymbol: capability?.unit?.unitSymbol ?? '',
    }))
    .filter((capability) => capability.id !== '');

  const location = placement
    ? `${placement?.buildingName ?? ''} - ${placement?.locationCity ?? ''}, ${
        placement?.locationCountry ?? ''
      }`
    : t('devices:details.noZone');

  const attributeSets = (deviceModelAttributeSets ?? [])
    .map((attributeSet) => ({
      id: attributeSet?.id ?? '',
      name: attributeSet?.name ?? '',
      attributes: attributeSet?.mappingAttributeDeviceModelAttributeSets
        .map((attribute) => ({
          id: attribute?.attribute?.id ?? '',
          name: attribute?.attribute?.name ?? '',
          required: attribute?.attribute?.required ?? false,
          dataType: attribute?.attribute?.dataType ?? null,
          exists: !!attributeValues?.find(
            ({ id }) => id === attribute?.attribute?.id,
          ),
          value:
            attributeValues?.find(({ id }) => id === attribute?.attribute?.id)
              ?.value ?? '',
        }))
        .filter((attribute) => attribute.id !== ''),
    }))
    .filter((attributeSet) => attributeSet.id !== '');

  useEffect(() => {
    setTitle({ main: device?.name, sub: location });
  }, [setTitle, location, device]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const { data: initialGatewayStatus } = useGatewayStatusQuery({
    variables: { deviceId },
    skip: !isGateway,
  });
  const gatewayStatus = useGatewayStatusSubscription(
    isGateway ? deviceId : undefined,
  );
  const gatewayConnectionStatus = useMemo(() => {
    if (!gatewayStatus.connectionState) {
      if (!initialGatewayStatus) {
        return {
          heartbeatState: HeartbeatStates.Unknown,
          connectionState: ConnectionStates.Unknown,
          firstConnected: undefined,
          lastConnected: undefined,
        } as GatewayStatus;
      }

      return {
        heartbeatState: initialGatewayStatus?.device?.heartbeatState,
        connectionState: initialGatewayStatus?.device?.connectionState,
        firstConnected: initialGatewayStatus?.device?.firstConnected,
        lastConnected: initialGatewayStatus?.device?.lastConnected,
      } as GatewayStatus;
    }
    return gatewayStatus;
  }, [gatewayStatus, initialGatewayStatus]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !device) {
    return (
      <NotFound
        title={t('devices:details.notFound.title') ?? ''}
        subtitle={t('devices:details.notFound.subtitle') ?? ''}
        buttonText={t('devices:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }

  const isLocked =
    !!device?.isLocked && !hasPermission(Permissions.DevicesWriteLocked);
  const deletePermission = device?.isLocked
    ? hasPermission(Permissions.DevicesWriteLocked)
    : hasPermission(Permissions.DevicesWrite);
  const editInfoPermission =
    hasPermission(Permissions.DevicesWrite) ||
    hasPermission(Permissions.DevicesWriteLocked);

  return (
    <>
      <DeviceDetails
        loading={loading}
        id={deviceId}
        name={device?.name ?? ''}
        description={device?.description || '-'}
        imageUrl={`${process.env.PUBLIC_URL}/assets/placeholders/device.png`}
        capabilities={capabilities}
        location={location}
        serialNumber={device?.serialNo || '-'}
        isLocked={isLocked}
        deviceIdentifier={device?.deviceIdentifier || '-'}
        deviceModel={
          device?.deviceModel
            ? {
                id: device?.deviceModel.id ?? '',
                name: device?.deviceModel.name ?? '',
              }
            : undefined
        }
        parentDevice={
          device?.parentDevice
            ? {
                id: device.parentDevice.id ?? '',
                name: device.parentDevice.name ?? '',
              }
            : undefined
        }
        attributeSets={attributeSets}
        isGateway={isGateway}
        onDelete={deletePermission ? handleDelete : undefined}
        onEditInfo={editInfoPermission ? handleEdit : undefined}
        onEditLocation={
          hasPermission(Permissions.DevicesWrite)
            ? handleEditLocation
            : undefined
        }
        onEditAttributes={
          hasPermission(Permissions.DevicesWrite)
            ? handleEditAttributes
            : undefined
        }
        zone={
          placement?.zoneId && placement?.zoneName
            ? {
                id: placement.zoneId,
                name: placement.zoneName,
              }
            : undefined
        }
        storey={
          placement?.storeyId && placement?.storeyName
            ? {
                id: placement.storeyId,
                name: placement.storeyName,
              }
            : undefined
        }
        building={
          placement?.buildingId && placement?.buildingName
            ? {
                id: placement.buildingId,
                name: placement.buildingName,
              }
            : undefined
        }
        site={
          placement?.siteId && placement?.siteName
            ? {
                id: placement.siteId,
                name: placement.siteName,
              }
            : undefined
        }
        showData={hasPermission(Permissions.DeviceDataRead)}
        gatewayConnectionStatus={gatewayConnectionStatus}
      />
      <DeviceDeleteContainer
        id={deviceId}
        isOpen={deleteMode}
        isGateway={isGateway}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
