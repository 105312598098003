import { TFunction } from 'i18next';
import { Page } from './Pages';
import * as Pages from './Pages';

export const PAGE_SIZES = [5, 10, 15, 25, 50];
export const DEFAULT_PAGE_SIZE = 15;
export const SIDEBAR_OPENED_WIDTH = 280;
export const SIDEBAR_CLOSED_WIDTH = 75;

interface SidebarSection {
  id: string;
  label: (t: TFunction) => string;
  items: Page[];
}

export const SIDEBAR_SECTIONS: SidebarSection[] = [
  {
    id: 'essentials',
    label: (t) => t('general:sidebar.section.essentials'),
    items: [Pages.DASHBOARD, Pages.APPLICATIONS, Pages.SITES, Pages.DEVICES],
  },
  {
    id: 'gateways',
    label: (t) => t('general:sidebar.section.configuration'),
    items: [
      Pages.DEVICEMODELS,
      Pages.ATTRIBUTES,
      Pages.CAPABILITIES,
      Pages.UNITS,
      Pages.USERS,
    ],
  },
  {
    id: 'commissioning',
    label: (t) => t('general:sidebar.section.commissioning'),
    items: [Pages.STATUS],
  },
  {
    id: 'extras',
    label: (t) => t('general:sidebar.section.extras'),
    items: [Pages.DEVELOPMENT, Pages.INTEGRATIONS],
  },
  {
    id: 'administration',
    label: (t) => t('general:sidebar.section.administration'),
    items: [Pages.TENANTS],
  },
];
