import React from 'react';
import * as Icons from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../components/Link';
import { DataTable, DataTableRow } from '../../../components/DataTable';
import { Tenant } from '../../../api/user';
import { createAddressString } from '../../../utils/helpers';

interface TenantListProps {
  tenants: Array<Tenant>;
  onAdd: () => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  page: number;
  pageSize: number;
  count: number;
  loading?: boolean;
}

export const TenantsList: React.FC<TenantListProps> = ({
  tenants,
  onAdd,
  onChangePage,
  onChangePageSize,
  page,
  pageSize,
  count,
  loading,
}) => {
  const { t } = useTranslation(['tenants', 'general']);

  const headers = [
    {
      key: t('tenants:list.headers.name'),
      value: t('tenants:list.headers.name'),
    },
    {
      key: t('tenants:list.headers.contact'),
      value: t('tenants:list.headers.contact'),
    },
    {
      key: t('tenants:list.headers.email'),
      value: t('tenants:list.headers.email'),
    },
    {
      key: t('tenants:list.headers.address'),
      value: t('tenants:list.headers.address'),
    },
  ];

  const rows =
    tenants
      .map((tenant) => {
        if (!tenant?.id) {
          return null;
        }
        const address = {
          street: tenant.contactAddressStreet,
          no: tenant.contactAddressHouseNumber,
          zip: tenant.contactAddressZip,
          city: tenant.contactAddressCity,
          country: tenant.contactAddressCountry,
        };

        return {
          key: `${tenant.id}-row`,
          cells: [
            {
              key: `${tenant.id}-cell-name`,
              element: (
                <Link to={`/tenants/${tenant.id}`}>{tenant.name}</Link>
              ) as React.ReactNode,
            },
            {
              key: `${tenant.id}-cell-contact`,
              element: `${tenant.contactFirstName ?? ''} ${
                tenant.contactLastName ?? ''
              }`,
            },
            {
              key: `${tenant.id}-cell-email`,
              element: tenant.contactEmail || '-',
            },
            {
              key: `${tenant.id}-cell-address`,
              element: (createAddressString(address) || '-') as React.ReactNode,
              isLast: false,
            },
          ],
        };
      })
      .filter<DataTableRow>((row): row is DataTableRow => row !== null) ?? [];

  return (
    <DataTable
      actions={
        onAdd && (
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<Icons.Add />}
            onClick={onAdd}
          >
            {t('tenants:list.addButton')}
          </Button>
        )
      }
      headers={headers}
      rows={rows}
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      showPagination
      loading={loading}
    />
  );
};
