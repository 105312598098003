import React, { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { Permissions, useAuth } from '@kp/react-sdk';
import {
  useDeviceModelAttributeSetDetailsAttributeCreateMutation,
  useDeviceModelAttributeSetDetailsAttributeUpdateMutation,
  useDeviceModelAttributeSetDetailsAttributeDeleteMutation,
  MappingAttributeDeviceModelAttributeSetCreateType,
  MappingAttributeDeviceModelAttributeSetUpdateType,
} from '../../../../__generated__/types';

import {
  Attribute,
  AttributeMapping,
  DeviceModelAttributeSetDetailsAttributesCard,
} from './DeviceModelAttributeSetDetailsAttributesCard';

export type DeviceModelAttributeSetDetailsAttributesCardContainerProps = {
  attributeSetId: string;
  mapping: AttributeMapping;
  onUpdate?: (mapingId: string, mapping: AttributeMapping) => void;
  onDelete?: (mapingId: string) => void;
  allAttributes: Array<Attribute>;
  isDeviceModelGlobal: boolean;
};

export const DeviceModelAttributeSetDetailsAttributesCardContainer: React.FC<
  DeviceModelAttributeSetDetailsAttributesCardContainerProps
> = ({
  attributeSetId,
  mapping,
  onUpdate,
  onDelete,
  allAttributes,
  isDeviceModelGlobal,
}) => {
  const { hasPermission } = useAuth();

  const [mappingState, setMappingState] = useState(mapping);
  const [errorState, setErrorState] = useState(false);
  const handleError = (e: Error | ApolloError) => {
    console.warn(e);
    setErrorState(true);
  };
  const [createMapping, { loading: loadingCreate, error: errorCreate }] =
    useDeviceModelAttributeSetDetailsAttributeCreateMutation();

  const [editMapping, { loading: loadingUpdate, error: errorUpdate }] =
    useDeviceModelAttributeSetDetailsAttributeUpdateMutation();

  const [deleteMapping, { loading: loadingDelete, error: errorDelete }] =
    useDeviceModelAttributeSetDetailsAttributeDeleteMutation();

  const handleChange = (id: string) => {
    const selectedAttribute = allAttributes.find((a) => a.attributeId === id);
    setMappingState({
      ...mappingState,
      ...selectedAttribute,
    });
  };
  const handleCreateMapping = () => {
    const mappingInput: MappingAttributeDeviceModelAttributeSetCreateType = {
      attributeId: mappingState.attributeId,
      deviceModelAttributeSetId: attributeSetId,
    };
    return createMapping({
      variables: {
        mappingInput,
      },
    });
  };
  const handleUpdateMapping = () => {
    const mappingInput: MappingAttributeDeviceModelAttributeSetUpdateType = {
      id: mappingState.mappingId,
      attributeId: mappingState.attributeId,
      deviceModelAttributeSetId: attributeSetId,
    };

    return editMapping({
      variables: {
        mappingInput,
      },
    });
  };
  const handleDelete = () => {
    return deleteMapping({
      variables: {
        mappingId: mappingState.mappingId,
      },
    })
      .then(() => (onDelete ? onDelete(mappingState.mappingId) : undefined))
      .catch((e) => {
        handleError(e);
      });
  };

  const handleSave = () => {
    const createdMappingId = '0';
    if (mappingState.mappingId === createdMappingId) {
      handleCreateMapping()
        .then((result) => {
          const mappingId =
            result.data?.createMappingAttributeDeviceModelAttributeSet?.id ??
            '0';
          const newMapping = { ...mappingState, mappingId };
          setMappingState(newMapping);
          if (onUpdate) onUpdate(createdMappingId, newMapping);
          return result;
        })
        .catch((e) => {
          handleError(e);
        });
    } else {
      handleUpdateMapping()
        .then((result) => {
          if (onUpdate) onUpdate(mappingState.mappingId, mappingState);
          return result;
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };

  const showSave =
    mappingState.attributeId !== '0' &&
    mappingState.attributeId !== mapping.attributeId;

  return (
    <>
      <Snackbar
        data-testid="attribute-error-snackbar"
        open={errorState}
        autoHideDuration={7000}
        onClose={() => {
          setErrorState(false);
        }}
      >
        <Alert severity="error">
          {errorCreate?.message || errorDelete?.message || errorUpdate?.message}
        </Alert>
      </Snackbar>
      <DeviceModelAttributeSetDetailsAttributesCard
        mapping={mappingState}
        showSave={showSave}
        loadingSave={loadingCreate || loadingUpdate}
        loadingDelete={loadingDelete}
        onChange={
          hasPermission(Permissions.AttributesWrite) ? handleChange : undefined
        }
        onSave={
          hasPermission(Permissions.AttributesWrite) ? handleSave : undefined
        }
        onDelete={
          hasPermission(Permissions.AttributesWrite) ? handleDelete : undefined
        }
        allAttributes={allAttributes}
        isDeviceModelGlobal={isDeviceModelGlobal}
      />
    </>
  );
};
