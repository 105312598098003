import React from 'react';
import { createClasses } from '@kp/react-ui';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, AutocompleteProps } from '../Autocomplete';

const classes = createClasses({
  field: {
    minHeight: '70px',
  },
});

export type AutocompleteFieldProps = Omit<
  AutocompleteProps,
  'value' | 'defaultValue'
> & {
  name: string;
};

export const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  className,
  ...props
}) => {
  const { control } = useFormContext();
  const { name } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...field}
            onChange={(_, data) => onChange(data)}
            {...props}
            className={classNames(className, classes.field)}
            error={!!error}
            helperText={error ? error.message : null}
          />
        );
      }}
    />
  );
};
