import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Loading } from '../../../components/Loading';
import { translateScanStatus } from './statusTranslation';
import { FormattedDate } from '../../../components/FormattedDate';

export type DeviceScanStats = null | {
  status?: 'created' | 'started' | 'completed' | 'failed' | 'timedout';
  startedAt?: string | null;
  finishedAt?: string | null;
  numOfDevices?: number;
};
export type DeviceScanStatsTableProps = DeviceScanStats & {
  loading?: boolean;
};

export const DeviceScanStatsTable: React.FC<DeviceScanStatsTableProps> = ({
  loading,
  status,
  startedAt,
  finishedAt,
  numOfDevices,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            {t('devices:common.deviceScanStatsTable.header')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell>
              <Loading />
            </TableCell>
          </TableRow>
        ) : (
          <>
            <TableRow>
              <TableCell>
                {t('devices:common.deviceScanStatsTable.status')}
              </TableCell>
              <TableCell align="right">
                {translateScanStatus(status, t)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('devices:common.deviceScanStatsTable.numOfDevices')}
              </TableCell>
              <TableCell align="right">
                {!finishedAt ? '-' : numOfDevices}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('devices:common.deviceScanStatsTable.startedAt')}
              </TableCell>
              <TableCell align="right">
                <FormattedDate value={startedAt} alt="-" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('devices:common.deviceScanStatsTable.finishedAt')}
              </TableCell>
              <TableCell align="right">
                <FormattedDate value={finishedAt} alt="-" />
              </TableCell>
            </TableRow>
          </>
        )}
      </TableBody>
    </Table>
  );
};
