import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { createClasses } from '@kp/react-ui';
import { Sidebar } from '../Sidebar';
import { Header } from '../Header';
import { Color } from '../../constants/Colors';
import { SIDEBAR_OPENED_WIDTH, SIDEBAR_CLOSED_WIDTH } from '../../constants/UI';
import { PageHeader } from '../PageHeader';

const classes = createClasses({
  content: {
    padding: 50,
    color: Color.black,
    flexGrow: 1,
    marginLeft: SIDEBAR_CLOSED_WIDTH - 1,
    transition: `margin 0.2s`,
  },
  narrowContent: {
    marginLeft: SIDEBAR_OPENED_WIDTH,
    transition: `margin 0.2s`,
  },
  root: {
    display: 'flex',
  },
});

type LayoutProps = { children?: React.ReactNode };
export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleCollapseClick = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [sidebarOpen]);

  return (
    <div className={classes.root}>
      <Header onCollapseClick={handleCollapseClick} sidebarOpen={sidebarOpen} />
      <Sidebar open={sidebarOpen} />
      <div
        className={classNames(
          classes.content,
          sidebarOpen && classes.narrowContent,
        )}
      >
        <PageHeader />
        {children}
      </div>
    </div>
  );
};
