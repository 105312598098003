import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FancyCard } from '../../../components/FancyCard';
import { DeviceDetailsAttributes } from './DeviceDetailsAttributes';
import { DataTypes } from '../../../__generated__/types';

export interface AttributeSet {
  id: string;
  name: string;
  attributes: Array<{
    id: string;
    exists?: boolean;
    dataType: DataTypes | null;
    name: string;
    required: boolean;
    value: string;
  }>;
}

interface DeviceDetailsAttributeSetsProps {
  attributeSets: Array<AttributeSet>;
  onEdit?: () => void;
  onTriggerUpdate?: () => void;
  updating?: boolean;
  updateDisabled?: boolean;
}

export const DeviceDetailsAttributeSets: React.FC<
  DeviceDetailsAttributeSetsProps
> = ({ attributeSets, onEdit, onTriggerUpdate, updating, updateDisabled }) => {
  const { t } = useTranslation(['devices', 'general']);
  return (
    <FancyCard
      title={t('devices:details.attributes.title')}
      actions={
        <>
          {onTriggerUpdate && (
            <LoadingButton
              loading={updating}
              disabled={updateDisabled}
              onClick={onTriggerUpdate}
              color="primary"
              size="large"
              aria-label="trigger-gateway-attributes-update"
              data-testid="trigger-gateway-attributes-update"
            >
              {t('devices:details.attributes.actions.triggerUpdate')}
            </LoadingButton>
          )}
          {onEdit && (
            <IconButton
              aria-label="edit"
              data-testid="card-edit"
              onClick={onEdit}
              size="medium"
              color="primary"
            >
              <Icons.Edit fontSize="small" />
            </IconButton>
          )}
        </>
      }
    >
      {attributeSets.map((attributeSet) => (
        <DeviceDetailsAttributes
          key={attributeSet.id}
          title={attributeSet.name}
          attributes={attributeSet.attributes}
        />
      ))}
    </FancyCard>
  );
};
