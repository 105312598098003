import React from 'react';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CapabilityDataContainer } from './CapabilityDataContainer';
import {
  DeviceDetailsCapabilities,
  DeviceDetailsCapabilitiesCapability,
} from './DeviceDetailsCapabilities';
import { DeviceDetailsLocation } from './DeviceDetailsLocation';
import {
  AttributeSet,
  DeviceDetailsAttributeSets,
} from './DeviceDetailsAttributeSets';
import { ActionsBar } from '../../../components/ActionsBar';
import { GatewayDetailsTabsContainer } from './GatewayDetailsTabsContainer';
import { DeviceDetailsInfoContainer } from './DeviceDetailsInfoContainer';
import { GatewayStatus } from '../../../hooks/useGatewayStatusSubscription';

type DeviceDetailsProps = {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  loading?: boolean;
  capabilities?: Array<DeviceDetailsCapabilitiesCapability>;
  location?: string;
  serialNumber: string;
  isLocked: boolean;
  deviceIdentifier?: string;
  parentDevice?: { id: string; name: string };
  deviceModel?: { id: string; name: string };
  isGateway: boolean;
  attributeSets: Array<AttributeSet>;
  onEditInfo?: () => void;
  onEditLocation?: () => void;
  onDelete?: () => void;
  onEditAttributes?: () => void;
  zone?: {
    id: string;
    name: string;
  };
  storey?: {
    id: string;
    name: string;
  };
  building?: {
    id: string;
    name: string;
  };
  site?: {
    id: string;
    name: string;
  };
  showData: boolean;
  gatewayConnectionStatus: GatewayStatus;
};

export const DeviceDetails: React.FC<DeviceDetailsProps> = ({
  id,
  name,
  description,
  loading,
  imageUrl,
  capabilities,
  serialNumber,
  isLocked,
  parentDevice,
  deviceIdentifier,
  deviceModel,
  isGateway,
  attributeSets,
  onEditInfo,
  onEditLocation,
  onDelete,
  onEditAttributes,
  site,
  building,
  storey,
  zone,
  showData,
  gatewayConnectionStatus,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  return (
    <>
      <ActionsBar>
        {!loading && onDelete && (
          <Button color="primary" onClick={onDelete}>
            {t('devices:details.actions.delete')}
          </Button>
        )}
      </ActionsBar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12} sm={12} md={8}>
              <DeviceDetailsInfoContainer
                loading={loading}
                isLocked={isLocked}
                isGateway={isGateway}
                onEdit={onEditInfo}
                deviceIdentifier={deviceIdentifier}
                imageUrl={imageUrl}
                name={name}
                description={description}
                serialNumber={serialNumber}
                parentDevice={parentDevice}
                deviceModel={deviceModel}
                gatewayStatus={gatewayConnectionStatus}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <DeviceDetailsLocation
                loading={loading}
                site={site}
                building={building}
                storey={storey}
                zone={zone}
                onEdit={onEditLocation}
              />
            </Grid>
          </Grid>
        </Grid>
        {isGateway && (
          <Grid item xs={12} sm={12}>
            <GatewayDetailsTabsContainer
              deviceId={id}
              attributeSets={attributeSets}
              capabilities={capabilities}
              showData={showData}
              gatewayStatus={gatewayConnectionStatus}
            />
          </Grid>
        )}
        {!isGateway && attributeSets && attributeSets.length > 0 && (
          <Grid item xs={12} sm={12}>
            <DeviceDetailsAttributeSets
              attributeSets={attributeSets}
              onEdit={onEditAttributes}
            />
          </Grid>
        )}
        {!isGateway && capabilities && capabilities.length > 0 && (
          <Grid item xs={12} sm={12}>
            <DeviceDetailsCapabilities capabilities={capabilities} />
          </Grid>
        )}
        {!isGateway &&
          showData &&
          capabilities?.map((capability) => (
            <Grid item xs={12} sm={12} key={capability.id}>
              <CapabilityDataContainer
                deviceId={id}
                id={capability.id}
                name={capability.name}
                unitSymbol={capability.unitSymbol}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};
