import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogTitle } from './ConfirmAlertDialogTitle';

export type InfoAlertProps = DialogProps & {
  title: string;
  message?: string | null;
  onClose: () => void;
};
export const InfoAlert: React.FC<InfoAlertProps> = ({
  title,
  message,
  children,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(['devices', 'general']);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="info-alert-dialog-title"
      aria-describedby="info-alert-dialog-description"
      {...rest}
    >
      <DialogTitle id="info-alert-dialog-title" closeButton onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="info-alert-dialog-description">
          {message}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('general:buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
