import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import { BuildingDetails } from './BuildingDetails';
import { useBuildingDetailsQuery } from '../../../__generated__/types';
import { ErrorMessage } from '../../../components/Errors';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { createAddressString } from '../../../utils/helpers';
import { BuildingDeleteContainer } from '../delete/BuildingDeleteContainer';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const BuildingDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['buildings', 'general']);
  const navigate = useNavigate();
  const { hasPermission } = useAuth();
  const { buildingId } = useParams();
  const { setTitle } = useHeader();
  const [deleteMode, setDeleteMode] = useState(false);

  const { loading, error, data } = useBuildingDetailsQuery({
    variables: {
      buildingId,
      where: { buildingId: { eq: buildingId } },
    },
  });
  const building = data?.building;
  const storeyCount = data?.storeys?.totalCount ?? 0;

  const hierarchy = building?.site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: building.site.id,
            name: building.site.name,
          },
          {
            type: Entities.BUILDING,
            id: building.id,
            name: building.name,
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: building?.name,
      sub: createAddressString(building?.location),
    });
  }, [setTitle, building]);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.SITE, building?.site?.id ?? ''));
  const handleEditInfo = () =>
    navigate(`${entityLocation(Entities.BUILDING, buildingId)}/edit`);
  const handleEditLocation = () =>
    navigate(`${entityLocation(Entities.BUILDING, buildingId)}/location`);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !building) {
    return (
      <NotFound
        title={t('buildings:details.notFound.title') ?? ''}
        subtitle={t('buildings:details.notFound.subtitle') ?? ''}
        buttonText={t('buildings:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.BUILDING.path)}
      />
    );
  }

  const location = building?.location;

  const image = {
    url:
      building?.image?.url ??
      `${process.env.PUBLIC_URL}/assets/placeholders/building.png`,
    name: building?.image?.name ?? '',
  };

  return (
    <>
      <BuildingDetails
        loading={loading}
        name={building?.name ?? ''}
        description={building?.description ?? ''}
        dimensions={building?.dimension ?? ''}
        city={location?.city ?? ''}
        street={location?.street ?? ''}
        no={location?.no ?? ''}
        country={location?.country ?? ''}
        zip={location?.zip ?? ''}
        image={image}
        onDelete={
          hasPermission(Permissions.BuildingsWrite) ? handleDelete : undefined
        }
        onEditInfo={
          hasPermission(Permissions.BuildingsWrite) ? handleEditInfo : undefined
        }
        onEditLocation={
          hasPermission(Permissions.BuildingsWrite)
            ? handleEditLocation
            : undefined
        }
        storeys={storeyCount}
        showStoreys={hasPermission(Permissions.StoreysRead)}
        showDevices={hasPermission(Permissions.DevicesRead)}
      />
      <BuildingDeleteContainer
        id={buildingId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
