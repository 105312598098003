import React from 'react';
import { Button, Divider, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from '../../../components/ActionsBar';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ZoneDetailsDevicesContainer } from './ZoneDetailsDevicesContainer';

const classes = createClasses({
  divider: {
    marginTop: '24px',
    marginBottom: '14px',
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
});

export type ZoneDetailsProps = {
  name: string;
  image: { url: string; name: string };
  description: string;
  onDelete?: () => void;
  onEdit?: () => void;
  loading?: boolean;
  showDevices: boolean;
};

export const ZoneDetails: React.FC<ZoneDetailsProps> = ({
  name,
  image,
  description,
  onDelete,
  onEdit,
  loading,
  showDevices,
}) => {
  const { t } = useTranslation(['zones', 'general']);

  return (
    <>
      {onDelete && (
        <ActionsBar>
          {!loading && (
            <Button color="primary" size="large" onClick={onDelete}>
              {t('zones:details.actions.delete')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12} sm={12} md={12}>
              <FancyCard
                title={t('zones:info.title')}
                onEdit={onEdit}
                image={image}
                loading={loading}
                fullHeight
              >
                <InfoList>
                  <InfoListItem
                    term={t('zones:info.name')}
                    className={classes.text}
                    key={classes.text}
                  >
                    {name}
                  </InfoListItem>
                  {description && (
                    <InfoListItem
                      term={t('general:fields.description')}
                      className={classes.text}
                    >
                      {description}
                    </InfoListItem>
                  )}
                </InfoList>
              </FancyCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showDevices && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Divider className={classes.divider} />
            <ZoneDetailsDevicesContainer />
          </Grid>
        </Grid>
      )}
    </>
  );
};
