import React from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { CommissioningSvcMappingDraftValidationResult } from '@kp/rest-api-javascript-sdk';

const classes = createClasses({
  divider: {
    marginTop: 8,
    marginBottom: 0,
  },
  datapointList: {
    maxHeight: '50vh',
    overflowX: 'scroll',
  },
  datapointListItem: {
    alignItems: 'flex-start',
  },
  datapointListItemText: {
    paddingTop: 8,
    width: 200,
    maxWidth: 200,
  },
  errorListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  alert: {
    marginTop: 16,
  },
});

type ValidationSource =
  | 'VALIDATION'
  | 'UPLOAD_CSV'
  | 'DEPLOY'
  | 'DOWNLOAD'
  | 'UNKNOWN';
export type ValidationResult = {
  source?: ValidationSource;
  showDialog: boolean;
  isValid?: boolean;
  errors?: CommissioningSvcMappingDraftValidationResult['errors'];
};

type MappingDraftValidationErrorDialogProps = {
  validationResult?: ValidationResult;
  actions: Array<{
    onClick: () => void | Promise<void>;
    title: string;
  }>;
};

export const MappingDraftValidationErrorDialog: React.FC<
  MappingDraftValidationErrorDialogProps
> = ({ actions, validationResult }) => {
  const { t } = useTranslation(['devices', 'general']);

  const translateSource = (source?: ValidationSource) => {
    switch (source) {
      case 'VALIDATION':
        return {
          title: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.VALIDATION.title',
          ),
          message: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.VALIDATION.message',
          ),
          warning: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.VALIDATION.warning',
          ),
        };
      case 'UPLOAD_CSV':
        return {
          title: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.UPLOAD_CSV.title',
          ),
          message: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.UPLOAD_CSV.message',
          ),
          warning: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.UPLOAD_CSV.warning',
          ),
        };
      case 'DEPLOY':
        return {
          title: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.DEPLOY.title',
          ),
          message: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.DEPLOY.message',
          ),
          warning: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.DEPLOY.warning',
          ),
        };
      case 'DOWNLOAD':
        return {
          title: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.DOWNLOAD.title',
          ),
          message: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.DOWNLOAD.message',
          ),
          warning: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.DOWNLOAD.warning',
          ),
        };
      default:
        return {
          title: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.UNKOWN.title',
          ),
          message: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.UNKOWN.message',
          ),
          warning: t(
            'devices:mappings.common.mappingDraftValidationDialog.content.UNKOWN.warning',
          ),
        };
    }
  };
  const translateError = (errorCode: string) => {
    switch (errorCode) {
      case 'UNKNOWN_DEVICE_MODEL':
        return t(
          'devices:mappings.common.mappingDraftValidationDialog.error.UNKNOWN_DEVICE_MODEL',
        );
      case 'INVALID_DEVICE_MODEL_CAPABILITY':
        return t(
          'devices:mappings.common.mappingDraftValidationDialog.error.INVALID_DEVICE_MODEL_CAPABILITY',
        );
      case 'DEVICE_NAME_ALREADY_IN_USE':
        return t(
          'devices:mappings.common.mappingDraftValidationDialog.error.DEVICE_NAME_ALREADY_IN_USE',
        );
      case 'AMBIGUOUS_DEVICE_NAME':
        return t(
          'devices:mappings.common.mappingDraftValidationDialog.error.AMBIGUOUS_DEVICE_NAME',
        );
      case 'CAPABILITY_MAPPED_MULTIPLE_TIMES':
        return t(
          'devices:mappings.common.mappingDraftValidationDialog.error.CAPABILITY_MAPPED_MULTIPLE_TIMES',
        );
      case 'DATAPOINT_MAPPED_MULTIPLE_TIMES':
        return t(
          'devices:mappings.common.mappingDraftValidationDialog.error.DATAPOINT_MAPPED_MULTIPLE_TIMES',
        );
      case 'MAPPED_DATAPOINT_MISSING_IN_CURRENT_SCAN':
        return t(
          'devices:mappings.common.mappingDraftValidationDialog.error.MAPPED_DATAPOINT_MISSING_IN_CURRENT_SCAN',
        );
      default:
        return t(
          'devices:mappings.common.mappingDraftValidationDialog.error.UNKNOWN',
        );
    }
  };

  const errors = validationResult?.errors ?? [];
  const dialogContent = translateSource(validationResult?.source);

  return (
    <Dialog open={!!validationResult?.showDialog} maxWidth="md">
      <DialogTitle>{dialogContent.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContent.message}</DialogContentText>

        <Divider className={classes.divider} />
        <List className={classes.datapointList}>
          {errors.map((error) => (
            <ListItem
              key={error.bacnetDeviceId + error.bacnetObjectId}
              className={classes.datapointListItem}
            >
              <ListItemText className={classes.datapointListItemText}>
                {`${error.bacnetDeviceId} - ${error.bacnetObjectId}`}
              </ListItemText>
              <List>
                {error.errors.map(({ code }, index) => (
                  <ListItem
                    key={code + index}
                    className={classes.errorListItem}
                  >
                    <ListItemText>{translateError(code)}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </ListItem>
          ))}
        </List>
        <Divider className={classes.divider} />
        {dialogContent.warning && (
          <Alert className={classes.alert} severity="warning">
            {dialogContent.warning}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {actions.map(({ onClick, title }, index) => (
          <Button
            key={title}
            onClick={onClick}
            color={index !== actions.length - 1 ? 'secondary' : 'primary'}
          >
            {title}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
