import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import { ErrorMessage } from '../../../components/Errors';
import { useDeviceModelDetailsCapabilitiesQuery } from '../../../__generated__/types';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { DeviceModelDetailsCapabilities } from './DeviceModelDetailsCapabilities';

export const DeviceModelDetailsCapabilitiesContainer: React.FC = () => {
  const navigate = useNavigate();
  const { deviceModelId } = useParams();
  const { hasPermission } = useAuth();

  const { loading, error, data } = useDeviceModelDetailsCapabilitiesQuery({
    variables: { deviceModelId },
  });

  const deviceModel = data?.deviceModel;
  const isDeviceModelGlobal = deviceModel?.isGlobal ?? false;

  const capabilities = (deviceModel?.deviceModelCapabilities ?? [])
    .map((capability) => ({
      id: capability?.id ?? '',
      name: capability?.capability?.name ?? '',
      technicalMin: capability?.technicalMin ?? undefined,
      technicalMax: capability?.technicalMax ?? undefined,
      unitName: capability?.unit?.name ?? '',
      unitSymbol: capability?.unit?.unitSymbol ?? '',
    }))
    .filter((capability) => capability.id !== '');

  const handleAdd = () =>
    navigate(
      entityLocation(Entities.DEVICEMODEL, `${deviceModelId}/capabilities/new`),
    );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <DeviceModelDetailsCapabilities
      onAdd={
        hasPermission(Permissions.CapabilitiesWrite) ? handleAdd : undefined
      }
      loading={loading}
      capabilities={capabilities}
      deviceModelId={deviceModelId}
      isDeviceModelGlobal={isDeviceModelGlobal}
    />
  );
};
