import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { DeviceModelsListContainer } from './list/DeviceModelsListContainer';
import { DeviceModelDetailsContainer } from './details/DeviceModelDetailsContainer';
import { DeviceModelCreateContainer } from './create/DeviceModelCreateContainer';
import { DeviceModelEditContainer } from './edit/DeviceModelEditContainer';
import { DeviceModelCapabilityDetailsContainer } from './capabilities/details/DeviceModelCapabilityDetailsContainer';
import { DeviceModelCapabilityEditContainer } from './capabilities/edit/DeviceModelCapabilityEditContainer';
import { DeviceModelCapabilityCreateContainer } from './capabilities/create/DeviceModelCapabilityCreateContainer';
import { DeviceModelAttributeSetDetailsContainer } from './attributeSets/details/DeviceModelAttributeSetDetailsContainer';
import { DeviceModelAttributeSetCreateContainer } from './attributeSets/create/DeviceModelAttributeSetCreateContainer';
import { DeviceModelAttributeSetEditContainer } from './attributeSets/edit/DeviceModelAttributeSetEditContainer';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.DEVICEMODELS.icon;

export const DeviceModelsRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<DeviceModelsListContainer />} />
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<DeviceModelCreateContainer />}
            permission={Permissions.DeviceModelsWrite}
          />
        }
      />
      <Route path="/:deviceModelId" element={<DeviceModelDetailsContainer />} />
      <Route
        path="/:deviceModelId/edit"
        element={
          <ProtectedRoute
            element={<DeviceModelEditContainer />}
            permission={Permissions.DeviceModelsWrite}
          />
        }
      />
      <Route
        path="/:deviceModelId/capabilities/new"
        element={
          <ProtectedRoute
            element={<DeviceModelCapabilityCreateContainer />}
            permission={Permissions.DeviceModelsWrite}
          />
        }
      />
      <Route
        path="/:deviceModelId/capabilities/:deviceModelCapabilityId"
        element={<DeviceModelCapabilityDetailsContainer />}
      />
      <Route
        path="/:deviceModelId/capabilities/:deviceModelCapabilityId/edit"
        element={
          <ProtectedRoute
            element={<DeviceModelCapabilityEditContainer />}
            permission={Permissions.DeviceModelsWrite}
          />
        }
      />
      <Route
        path="/:deviceModelId/attribute-sets/new"
        element={
          <ProtectedRoute
            element={<DeviceModelAttributeSetCreateContainer />}
            permission={Permissions.DeviceModelsWrite}
          />
        }
      />
      <Route
        path="/:deviceModelId/attribute-sets/:deviceModelAttributeSetId"
        element={<DeviceModelAttributeSetDetailsContainer />}
      />
      <Route
        path="/:deviceModelId/attribute-sets/:deviceModelAttributeSetId/edit"
        element={
          <ProtectedRoute
            element={<DeviceModelAttributeSetEditContainer />}
            permission={Permissions.DeviceModelsWrite}
          />
        }
      />
    </Routes>
  );
};
