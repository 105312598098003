import React from 'react';
import { List, ListProps } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import classNames from 'classnames';

const classes = createClasses({
  root: {
    paddingTop: 16,
  },
});

export const InfoList: React.FC<ListProps> = ({ className, ...props }) => {
  return <List className={classNames(classes.root, className)} {...props} />;
};
