import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import { ErrorMessage } from '../../../components/Errors';
import { useDeviceModelDetailsAttributeSetsQuery } from '../../../__generated__/types';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { DeviceModelDetailsAttributeSets } from './DeviceModelDetailsAttributeSets';

export const DeviceModelDetailsAttributeSetsContainer: React.FC = () => {
  const navigate = useNavigate();
  const { deviceModelId } = useParams();
  const { hasPermission } = useAuth();

  const { loading, error, data } = useDeviceModelDetailsAttributeSetsQuery({
    variables: { deviceModelId },
  });

  const deviceModel = data?.deviceModel;
  const isDeviceModelGlobal = deviceModel?.isGlobal ?? false;

  const attributeSets = (deviceModel?.deviceModelAttributeSets ?? [])
    .map((attributeSet) => ({
      id: attributeSet?.id ?? '',
      name: attributeSet?.name ?? '',
      attributesCount: (
        attributeSet?.mappingAttributeDeviceModelAttributeSets ?? []
      ).length,
    }))
    .filter((attributeSet) => attributeSet.id !== '');

  const handleAdd = () =>
    navigate(
      entityLocation(
        Entities.DEVICEMODEL,
        `${deviceModelId}/attribute-sets/new`,
      ),
    );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <DeviceModelDetailsAttributeSets
      onAdd={hasPermission(Permissions.AttributesWrite) ? handleAdd : undefined}
      loading={loading}
      attributeSets={attributeSets}
      deviceModelId={deviceModelId}
      isDeviceModelGlobal={isDeviceModelGlobal}
    />
  );
};
