import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as Icons from '@mui/icons-material';
import { HistoricalDataContainer } from './HistoricalDataContainer';
import { useHeader } from '../../contexts/header-context';

export const DataRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icons.ShowChart);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/:deviceId" element={<HistoricalDataContainer />} />
    </Routes>
  );
};
