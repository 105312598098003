import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useNavigate } from 'react-router';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import * as Entities from '../../../../constants/Entities';
import {
  getQanteonProvision,
  InstanceSize,
  Status,
} from '../../../../api/qanteon';
import { useHeader } from '../../../../contexts/header-context';
import { ErrorMessage } from '../../../../components/Errors';
import { QanteonProvisionDetails } from './QanteonProvisionDetails';
import { NotFound } from '../../../errorScreens';

export const QanteonProvisionDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['registeredApplications', 'general']);
  const navigate = useNavigate();
  const { setTitle } = useHeader();

  const {
    data: response,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['getQanteonProvision'],
    queryFn: getQanteonProvision,
    onError: (err: ApiError) => err,
  });

  const qanteonProvision = response?.data ?? null;

  const hierarchy = qanteonProvision
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.INTEGRATION,
            id: 'qanteon',
            name: 'Qanteon',
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: 'Qanteon' });
  }, [setTitle]);

  if (error instanceof Error) {
    if (error.status === 404) {
      return (
        <NotFound
          title={t('integrations:qanteon.notFound.title') ?? ''}
          subtitle={t('integrations:qanteon.notFound.subtitle') ?? ''}
          buttonText={t('integrations:qanteon.notFound.buttonText') ?? ''}
          buttonOnClick={() => navigate(Entities.INTEGRATION.path)}
        />
      );
    }
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <QanteonProvisionDetails
        loading={loading}
        status={qanteonProvision?.status as Status}
        name={qanteonProvision?.name}
        description={qanteonProvision?.description}
        activatedAt={qanteonProvision?.activatedAt}
        activatedBy={qanteonProvision?.activatedBy}
        instanceSize={qanteonProvision?.instanceSize as InstanceSize}
        url={qanteonProvision?.url}
      />
    </>
  );
};
