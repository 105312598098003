import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FancyCard } from '../../../../components/FancyCard';
import { InfoList } from '../../../../components/InfoList';
import { InfoListItem } from '../../../../components/InfoListItem';
import { InstanceSize, Status } from '../../../../api/qanteon/common';
import { instanceSizes, statuses } from '../common/constants';
import { Color } from '../../../../constants/Colors';
import { FormattedDate } from '../../../../components/FormattedDate';

const classes = createClasses({
  url: {
    color: Color.link,
  },
});

interface QanteonProvisionDetailsProps {
  status?: Status;
  name?: string;
  description?: string;
  activatedAt?: string;
  activatedBy?: string;
  instanceSize?: InstanceSize;
  url?: string;
  loading: boolean;
}

export const QanteonProvisionDetails: React.FC<
  QanteonProvisionDetailsProps
> = ({
  status,
  name,
  description,
  activatedAt,
  activatedBy,
  instanceSize,
  url,
  loading,
}) => {
  const { t } = useTranslation(['integrations', 'general']);

  return (
    <>
      <Grid container spacing={3} alignItems="stretch" direction="row">
        <Grid item xs={12}>
          <FancyCard
            title={t('integrations:qanteon.details.title')}
            loading={loading}
          >
            <InfoList>
              <InfoListItem term={t('integrations:qanteon.details.status')}>
                {statuses(t)[status ?? Status.pending]}
              </InfoListItem>
              <InfoListItem term={t('general:fields.name')}>
                {name}
              </InfoListItem>
              {description && (
                <InfoListItem term={t('general:fields.description')}>
                  {description}
                </InfoListItem>
              )}
              <InfoListItem
                term={t('integrations:qanteon.details.activatedAt')}
              >
                <FormattedDate value={activatedAt} />
              </InfoListItem>
              <InfoListItem
                term={t('integrations:qanteon.details.activatedBy')}
              >
                {activatedBy}
              </InfoListItem>
              <InfoListItem
                term={t('integrations:qanteon.details.instanceSize')}
              >
                {instanceSizes(t)[instanceSize ?? InstanceSize.Small]}
              </InfoListItem>
              <InfoListItem term={t('integrations:qanteon.details.url')}>
                <a
                  className={classes.url}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {url}
                </a>
              </InfoListItem>
            </InfoList>
          </FancyCard>
        </Grid>
      </Grid>
    </>
  );
};
