import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Permissions, useAuth } from '@kp/react-sdk';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { FieldDeviceListContainer } from '../../../components/FieldDeviceList';
import {
  AttributeSet,
  DeviceDetailsAttributeSets,
} from './DeviceDetailsAttributeSets';
import {
  DeviceDetailsCapabilities,
  DeviceDetailsCapabilitiesCapability,
} from './DeviceDetailsCapabilities';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { CapabilityDataContainer } from './CapabilityDataContainer';
import { DeviceDetailsGatewayConfig } from './DeviceDetailsGatewayConfig';
import { ErrorAlert } from '../../../components/Alerts';
import {
  getGatewayConfigFile,
  getDeployments,
  downloadMapping,
  deployMapping,
  refreshGatewayFirmwareInfo,
  decommissionGateway,
  getMappingDraft,
} from '../../../api/bacnet';
import { DeviceDetailsDiscoveryCard } from './DeviceDetailsDiscoveryCard';
import { DeviceDetailsDeployCard } from './DeviceDetailsDeployCard';
import { b64toBlob } from '../../../utils/file-tools';
import {
  useCommissioningStatusSubscription,
  useGatewayDetailsSubscription,
} from '../../../hooks';
import {
  ATTR_NAME_GATEWAY_FIRMWARE_VERSION,
  ATTR_SET_NAME_GATEWAY_PROPERTIES,
} from '../../../constants/Misc';
import { AccessSignatureAlertContainer } from './AccessSignatureAlertContainer';
import { useDeviceDetailsGatewayConfigQuery } from '../../../__generated__/types';
import { GatewayStatus } from '../../../hooks/useGatewayStatusSubscription';

const classes = createClasses({
  tabContainer: {
    width: '100%',
    backgroundColor: 'white',
    border: 1,
    borderColor: 'divider',
  },
  tabs: {
    display: 'flex',
    borderBottom: 1,
    borderColor: 'divider',
    flextDirection: 'row',
  },
});

enum TabIds {
  configuration = 'Configuration',
  deviceDiscovery = 'DeviceDiscovery',
  fieldDevices = 'FieldDevices',
  deployments = 'Deployments',
  attributes = 'Attributes',
  capabilities = 'Capabilities',
  data = 'Data',
}

type GatewayDetailsTabsContainerProps = {
  deviceId: string;
  attributeSets: Array<AttributeSet>;
  capabilities?: DeviceDetailsCapabilitiesCapability[];
  showData?: boolean;
  gatewayStatus: GatewayStatus;
};
export const GatewayDetailsTabsContainer: React.FC<
  GatewayDetailsTabsContainerProps
> = ({ deviceId, attributeSets, capabilities, showData, gatewayStatus }) => {
  const { t } = useTranslation(['general', 'errors']);
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab') as TabIds;

  const [value, setValue] = React.useState(activeTab || TabIds.configuration);

  const [showAccessSignatureAlert, setShowAccessSignatureAlert] =
    useState(false);
  const [updatingFirmwareVersion, setUpdatingFirmwareVersion] = useState(false);

  const {
    firmwareVersion,
    lastUpdated,
    error: errorSubscription,
  } = useCommissioningStatusSubscription(deviceId);

  useEffect(() => {
    if (firmwareVersion) {
      setUpdatingFirmwareVersion(false);
    }
  }, [firmwareVersion, lastUpdated]);

  const syncedAttributeSets: AttributeSet[] = useMemo(() => {
    if (!firmwareVersion) {
      return attributeSets;
    }

    return attributeSets.map(({ name, attributes, ...set }) =>
      name === ATTR_SET_NAME_GATEWAY_PROPERTIES
        ? {
            name,
            ...set,
            attributes: attributes.map((attribute) =>
              attribute.name === ATTR_NAME_GATEWAY_FIRMWARE_VERSION
                ? {
                    ...attribute,
                    value: firmwareVersion,
                    exists: true,
                  }
                : attribute,
            ),
          }
        : { name, attributes, ...set },
    );
  }, [attributeSets, firmwareVersion]);

  const handleChange = (event: React.SyntheticEvent, newValue: TabIds) => {
    setValue(newValue);
    searchParams.set('activeTab', newValue);
    setSearchParams(searchParams);
  };
  const hasUpdateableAttributes = (attrSets: Array<AttributeSet>) => {
    for (const set of attrSets) {
      if (set.name === ATTR_SET_NAME_GATEWAY_PROPERTIES) {
        for (const attr of set.attributes) {
          if (attr.name === ATTR_NAME_GATEWAY_FIRMWARE_VERSION) return true;
        }
      }
    }

    return false;
  };

  const {
    data: responseGetConfig,
    loading: loadingGetConfig,
    error: errorGetConfig,
    refetch: updateGetGatewayConfig,
  } = useDeviceDetailsGatewayConfigQuery({
    variables: { deviceId },
    skip: !deviceId,
  });

  const {
    details: gatewayDetails,
    loading: loadingGetGatewayDetails,
    error: errorGetGatewayDetails,
  } = useGatewayDetailsSubscription(deviceId);
  const deviceScan = gatewayDetails?.deviceScan;
  const objectScans = gatewayDetails?.objectScans;
  const mappingStats = gatewayDetails?.mapping;
  const deploymentError = gatewayDetails?.deploymentError;
  const commissioningStatus = gatewayDetails?.status;

  const {
    refetch: callGetDeployments,
    isLoading: loadingGetDeployments,
    data: deployments,
    error: errorGetDeployments,
  } = useQuery({
    queryKey: ['getDeployments', deviceId],
    queryFn: () => getDeployments(deviceId),
    onError: (err: ApiError) => err,
  });
  useEffect(() => {
    if (commissioningStatus === 'idle') {
      callGetDeployments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, commissioningStatus]);

  const { data: responseMappingDraft } = useQuery({
    queryKey: ['getMappingDraft', deviceId],
    queryFn: () => getMappingDraft(deviceId),
    onError: (err: ApiError) => err,
  });
  const hasMappingDraft = responseMappingDraft?.data
    ? responseMappingDraft.data.devices.length > 0
    : false;

  const {
    mutateAsync: callGetConfigFile,
    isLoading: loadingGetConfigFile,
    error: errorGetConfigFile,
  } = useMutation({
    mutationFn: getGatewayConfigFile,
    onSuccess: () => updateGetGatewayConfig(),
    onError: (err: ApiError) => err,
  });

  const {
    mutateAsync: callDownloadMappings,
    isLoading: loadingDownloadMappings,
    error: errorDownloadMappings,
  } = useMutation({
    mutationFn: downloadMapping,
    onError: (err: ApiError) => err,
  });
  const {
    mutateAsync: callDecommissionGateway,
    isLoading: loadingDecommissionGateway,
    error: errorDecommissionGateway,
  } = useMutation({
    mutationFn: decommissionGateway,
    onError: (err: ApiError) => err,
  });
  const {
    mutate: callDeployMappings,
    isLoading: loadingDeployMappings,
    error: errorDeployMappings,
  } = useMutation({
    mutationFn: deployMapping,
    onError: (err: ApiError) => {
      callGetDeployments();
      return err;
    },
  });

  const handleDiscovery = async () => {
    navigate(entityLocation(Entities.DEVICE, `${deviceId}/discovery`));
  };

  const handleEditMappings = () => {
    navigate(entityLocation(Entities.DEVICE, `${deviceId}/mappings/edit`));
  };
  const handleCreateMappings = () => {
    navigate(
      entityLocation(Entities.DEVICE, `${deviceId}/mappings/draftCreate`),
    );
  };
  const handleDecommissionGateway = () =>
    callDecommissionGateway(deviceId)
      .then(() => callGetDeployments())
      .catch(console.warn);

  const onOpenAccessSignaturePopup = () => {
    setShowAccessSignatureAlert(true);
  };
  const onCloseAccessSignaturePopup = () => {
    setShowAccessSignatureAlert(false);
  };

  const handleConfigDownload = async () => {
    const configs = await callGetConfigFile(deviceId);
    if (!configs?.data?.fileContent) return;
    const blob = new Blob([window.atob(configs.data.fileContent)], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(blob, `mqtt1-${deviceId}.cfg`);
  };

  const handleViewDeploymentList = () => {
    navigate(entityLocation(Entities.DEVICE, `${deviceId}/deployments`));
  };

  const handleDownloadMappings = async () => {
    const mappings = await callDownloadMappings(deviceId);
    if (!mappings?.data?.fileContent) return;
    const blob = await b64toBlob(mappings.data.fileContent);
    saveAs(blob, `gatewayConfig-${deviceId}.zip`);
  };
  const handleDeployMappings = () => callDeployMappings(deviceId);

  const handleEditAttributes = () =>
    navigate(`${entityLocation(Entities.DEVICE, deviceId)}/attributes`);

  const handleTriggerAttrUpdate = async () => {
    setUpdatingFirmwareVersion(true);
    setTimeout(() => setUpdatingFirmwareVersion(false), 60000);
    await refreshGatewayFirmwareInfo(deviceId);
  };

  const config = useMemo(() => {
    return responseGetConfig?.device?.gatewayDeviceConnections[0];
  }, [responseGetConfig]);

  return (
    <>
      <Box className={classes.tabContainer}>
        <Box className={classes.tabs}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              value={TabIds.configuration}
              label={t('devices:details.tabs.configuration')}
              data-testid="configuration-tab"
            />
            <Tab
              value={TabIds.deviceDiscovery}
              label={t('devices:details.tabs.devicediscovery')}
              data-testid="devicediscovery-tab"
            />
            <Tab
              value={TabIds.fieldDevices}
              label={t('devices:details.tabs.fielddevices')}
              data-testid="fielddevices-tab"
            />
            <Tab
              value={TabIds.deployments}
              label={t('devices:details.tabs.deployments')}
              data-testid="deployments-tab"
            />
            {attributeSets && attributeSets.length > 0 && (
              <Tab
                value={TabIds.attributes}
                label={t('devices:details.tabs.attributes')}
                data-testid="attributes-tab"
              />
            )}
            {capabilities && capabilities.length > 0 && (
              <Tab
                value={TabIds.capabilities}
                label={t('devices:details.tabs.capabilities')}
                data-testid="capabilities-tab"
              />
            )}
            {capabilities && capabilities.length > 0 && showData && (
              <Tab
                value={TabIds.data}
                label={t('devices:details.tabs.data')}
                data-testid="data-tab"
              />
            )}
          </Tabs>
        </Box>

        <div hidden={value !== TabIds.configuration}>
          <DeviceDetailsGatewayConfig
            loading={loadingGetConfig || loadingGetConfigFile}
            host={config?.host ?? ''}
            port={config?.port ?? 0}
            clientId={config?.name ?? ''}
            user={config?.user ?? ''}
            sharedAccessSignatureExpiry={config?.sharedAccessSignatureExpiry}
            onDownload={handleConfigDownload}
            onGenerateAccessSignature={onOpenAccessSignaturePopup}
          />
        </div>
        <div hidden={value !== TabIds.deviceDiscovery}>
          <DeviceDetailsDiscoveryCard
            loading={loadingGetGatewayDetails}
            deviceScan={deviceScan}
            objectScans={objectScans}
            onDiscovery={
              hasPermission(Permissions.CommissioningWrite)
                ? handleDiscovery
                : undefined
            }
          />
        </div>
        <div hidden={value !== TabIds.fieldDevices}>
          <FieldDeviceListContainer
            loading={loadingDecommissionGateway}
            gatewayId={deviceId}
            defaultExpanded
            onEditMappings={
              hasPermission(Permissions.CommissioningWrite)
                ? handleEditMappings
                : undefined
            }
            onCreateMappings={
              hasPermission(Permissions.CommissioningWrite)
                ? handleCreateMappings
                : undefined
            }
            onDecommissionGateway={
              hasPermission(Permissions.CommissioningWrite)
                ? handleDecommissionGateway
                : undefined
            }
            isHidden={value !== TabIds.fieldDevices}
          />
        </div>
        <div hidden={value !== TabIds.deployments}>
          <DeviceDetailsDeployCard
            loading={
              loadingGetGatewayDetails ||
              loadingGetDeployments ||
              loadingDownloadMappings ||
              loadingDeployMappings
            }
            hasMappingDraft={hasMappingDraft}
            previousDeployments={deployments?.data ?? []}
            deploymentError={deploymentError}
            mappingStats={mappingStats ?? {}}
            commissioningStatus={commissioningStatus ?? gatewayDetails?.status}
            gatewayStatus={gatewayStatus}
            onViewList={handleViewDeploymentList}
            onDownload={
              hasPermission(Permissions.CommissioningWrite)
                ? handleDownloadMappings
                : undefined
            }
            onDeploy={
              hasPermission(Permissions.CommissioningWrite)
                ? handleDeployMappings
                : undefined
            }
          />
        </div>
        <div hidden={value !== TabIds.attributes}>
          <DeviceDetailsAttributeSets
            attributeSets={syncedAttributeSets}
            onEdit={
              hasPermission(Permissions.DevicesWrite)
                ? handleEditAttributes
                : undefined
            }
            updateDisabled={commissioningStatus !== 'idle'}
            onTriggerUpdate={
              hasPermission(Permissions.CommissioningRead) &&
              hasPermission(Permissions.AttributesWrite) &&
              hasUpdateableAttributes(syncedAttributeSets)
                ? handleTriggerAttrUpdate
                : undefined
            }
            updating={updatingFirmwareVersion}
          />
        </div>
        <div hidden={value !== TabIds.capabilities}>
          <DeviceDetailsCapabilities capabilities={capabilities} />
        </div>
        <div hidden={value !== TabIds.data}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            {showData &&
              capabilities?.map((capability) => (
                <Grid item xs={12} sm={12} key={capability.id}>
                  <CapabilityDataContainer
                    deviceId={deviceId}
                    id={capability.id}
                    name={capability.name}
                    unitSymbol={capability.unitSymbol}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </Box>
      <AccessSignatureAlertContainer
        open={showAccessSignatureAlert}
        deviceId={deviceId}
        updateGetGatewayConfig={updateGetGatewayConfig}
        onClose={onCloseAccessSignaturePopup}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorGetGatewayDetails,
          errorGetDeployments,
          errorDownloadMappings,
          errorDeployMappings,
          errorGetConfig,
          errorGetConfigFile,
          errorSubscription,
          errorDecommissionGateway,
        ]}
      />
    </>
  );
};
