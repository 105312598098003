import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';
import { StatusOverviewContainer } from './overview/StatusOverviewContainer';
import { NotFound } from '../errorScreens/NotFound';

const Icon = Pages.STATUS.icon;

export const StatusRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute element={<StatusOverviewContainer />} />}
      />
      <Route path="*" element={<NotFound type="page" />} />
    </Routes>
  );
};
