import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { RegisteredApplicationDelete } from './RegisteredApplicationDelete';
import { deleteRegisteredApplication } from '../../../api/application';

interface RegisteredApplicationDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error) => void;
}

export const RegisteredApplicationDeleteContainer: React.FC<
  RegisteredApplicationDeleteContainerProps
> = ({ id, isOpen, onCompleted, onError, onCancel }) => {
  const {
    mutate: deleteApp,
    error,
    isLoading: loading,
  } = useMutation({
    mutationFn: deleteRegisteredApplication,
    onSuccess: onCompleted,
    onError,
  });

  const handleConfirm = () => deleteApp(id);

  return (
    <RegisteredApplicationDelete
      error={error as ApiError}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
