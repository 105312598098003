import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ErrorAlert } from '../../../../components/Alerts';
import { entityLocation } from '../../../../utils/entity';
import * as Entities from '../../../../constants/Entities';
import {
  DataTypes,
  DeviceCreateAttributeValueType,
  PlacementKind,
  useGatewayCreateDeviceModelsAndSitesQuery,
  useGatewayCreateWithPlacementMutation,
} from '../../../../__generated__/types';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';
import { GatewayCreate, GatewayCreateData } from './GatewayCreate';
import { useValidateDeviceIdentifier } from '../../common/useValidateDeviceIdentifier';

export const GatewayCreateContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general']);
  const navigate = useNavigate();
  const { setTitle, setLoading } = useHeader();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const placementId = searchParams.get('placementId') ?? undefined;
  const placementType =
    (searchParams.get('placementType') as PlacementKind) ?? undefined;
  const { validationState, validate } = useValidateDeviceIdentifier();

  const {
    loading: loadingDeviceModelsAndSites,
    error: errorDeviceModelsAndSites,
    data: dataDeviceModelsAndSites,
  } = useGatewayCreateDeviceModelsAndSitesQuery();
  const deviceModels = dataDeviceModelsAndSites?.deviceModels?.items;
  const allSites = dataDeviceModelsAndSites?.sites?.items ?? [];

  const allDeviceModels = (deviceModels ?? []).map((dm) => ({
    id: dm.id,
    name: dm.name,
    isGateway: dm.deviceType?.isGateway ?? false,
    deviceModelAttributeSets: dm.deviceModelAttributeSets.map(
      (attributeSet) => ({
        id: attributeSet.id,
        name: attributeSet.name,
        attributes: attributeSet.mappingAttributeDeviceModelAttributeSets.map(
          (attribute) => ({
            id: attribute.attribute.id,
            name: attribute.attribute.name,
            required: attribute.attribute.required ?? false,
            dataType: attribute.attribute.dataType,
            value:
              attribute.attribute.required &&
              attribute.attribute.dataType === DataTypes.Bool
                ? 'false'
                : '',
          }),
        ),
      }),
    ),
  }));

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.DEVICE,
        id: '/new',
        name: t('devices:breadcrumb.createGateway'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('devices:breadcrumb.createGateway') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loadingDeviceModelsAndSites);
  }, [setLoading, loadingDeviceModelsAndSites]);

  const [create, { loading: loadingCreate, error: errorCreate }] =
    useGatewayCreateWithPlacementMutation({
      onCompleted: (result) => {
        navigate(
          entityLocation(
            Entities.DEVICE,
            result.createDeviceWithPlacement?.id ?? '',
          ),
        );
      },
    });

  const handleDiscard = () => {
    const locationState = location.state as {
      backToEntity?: Entities.Entity;
      params?: string;
    } | null;
    const returnTo = locationState?.backToEntity ?? Entities.DEVICE;
    const params = locationState?.params ?? '';
    navigate(entityLocation(returnTo, params));
  };
  const handleCreate = (inputData: GatewayCreateData) => {
    const attributeValues: DeviceCreateAttributeValueType[] =
      inputData.attributeSets
        .map((set) =>
          set.attributes
            .filter((attribute) => attribute.required || attribute.value !== '')
            .map((attribute) => ({
              attributeId: attribute.id,
              value: attribute.value ?? '',
            })),
        )
        .flat();
    create({
      variables: {
        device: {
          name: inputData.name,
          description: inputData.description,
          serialNo: inputData.serialNo,
          deviceModelId: inputData.deviceModelId,
          deviceIdentifier: inputData.deviceIdentifier,
        },
        placementId: inputData.locationId,
        placementType: inputData.locationType ?? PlacementKind.Zone,
        attributeValues,
      },
    }).catch(console.warn);
  };

  return (
    <>
      <GatewayCreate
        loading={loadingDeviceModelsAndSites || loadingCreate}
        placementId={placementId ?? '0'}
        placementType={placementType}
        allSites={allSites}
        allDeviceModels={allDeviceModels}
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
        validateDeviceIdentifier={validate}
        deviceIdentifierValidationState={validationState}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorDeviceModelsAndSites, errorCreate]}
      />
    </>
  );
};
