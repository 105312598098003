import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { createClasses } from '@kp/react-ui';
import { Grid, IconButton, Typography, Button } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FancyCard } from '../../../components/FancyCard';
import { Color } from '../../../constants/Colors';
import { InputBaseField } from '../../../components/Fields/InputBaseField';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 28px 16px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    background: Color.inputBackground,
    padding: '8px',
    borderRadius: '4px',
  },
  inputWrapper: {
    flexGrow: 1,
    marginRight: '16px',
    paddingLeft: '8px',
  },
  input: {
    fontSize: '14px',
    fontFamily: 'monospace',
  },
  error: {
    padding: '4px 8px 16px 16px',
    color: 'red',
  },
  infoBox: {
    marginBottom: '16px',
  },
});

export type RegisteredApplicationFormRedirectUrisData = {
  redirectUris: Array<{ uri: string }>;
};
type RegisteredApplicationFormRedirectUrisProps = {
  loading?: boolean;
};

export const getRegisteredApplicationFormRedirectUrisSchema = (
  t: TFunction,
): Yup.ObjectSchema<RegisteredApplicationFormRedirectUrisData> =>
  Yup.object().shape({
    redirectUris: Yup.array()
      .required(t('general:errors.required') ?? '')
      .of(
        Yup.object({
          uri: Yup.string()
            .urlWithoutTLD(t('registeredApplications:validation.url'))
            .required(t('general:errors.required') ?? ''),
        }),
      ),
  });

export const RegisteredApplicationFormRedirectUris: React.FC<RegisteredApplicationFormRedirectUrisProps> =
  React.memo(({ loading }) => {
    const { t } = useTranslation(['registeredApplications', 'general']);
    const {
      control,
      formState: { errors, touchedFields },
    } = useFormContext<RegisteredApplicationFormRedirectUrisData>();
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'redirectUris',
    });

    return (
      <FancyCard
        title={t('registeredApplications:details.redirectUris.title')}
        loading={loading}
        actions={
          <IconButton
            aria-label="add"
            onClick={() => append({ uri: '' })}
            size="medium"
            color="primary"
          >
            <Icons.Add fontSize="small" />
          </IconButton>
        }
      >
        <Grid
          container
          spacing={1}
          direction="column"
          className={classes.cardContent}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="body2" className={classes.infoBox}>
              {t('registeredApplications:details.redirectUris.info')}
            </Typography>
          </Grid>
          {fields.map((field, index) => (
            <Grid item xs={12} md={12} key={field.id}>
              <div className={classes.row}>
                <div className={classes.inputWrapper}>
                  <InputBaseField
                    name={`redirectUris.${index}.uri`}
                    type="text"
                    fullWidth
                    placeholder="https://example.com/auth-callback"
                    inputProps={{
                      className: classes.input,
                      'data-testid': `redirect-uri-input-${index}`,
                    }}
                  />
                </div>
                <div>
                  {(index > 0 || fields.length > 1) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      data-testid={`redirect-uri-remove-${index}`}
                      onClick={() => remove(index)}
                    >
                      {t('registeredApplications:details.remove')}
                    </Button>
                  )}
                </div>
              </div>
              {errors.redirectUris?.[index]?.uri &&
                (index === 0 || touchedFields.redirectUris?.[index]?.uri) && (
                  <div className={classes.error}>
                    <Typography variant="caption">
                      {errors.redirectUris?.[index]?.uri?.message}
                    </Typography>
                  </div>
                )}
            </Grid>
          ))}
        </Grid>
      </FancyCard>
    );
  });

RegisteredApplicationFormRedirectUris.displayName =
  'RegisteredApplicationFormRedirectUris';
