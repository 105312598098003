import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundPage } from '@kp/react-ui';
import { useNavigate } from 'react-router';
import { useHeader } from '../../contexts/header-context';

type NotFoundInterface = {
  type?: 'page' | 'object';
  title?: string;
  subtitle?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
};

export const NotFound: React.FC<NotFoundInterface> = ({
  type,
  title,
  subtitle,
  buttonText,
  buttonOnClick,
}) => {
  const { t } = useTranslation(['general']);
  const navigate = useNavigate();
  const { setHideHeader } = useHeader();

  useEffect(() => setHideHeader(true), [setHideHeader]);

  const handleButtonClick = useCallback(() => {
    setHideHeader(false);
    if (buttonOnClick) {
      buttonOnClick();
    } else {
      navigate('/');
    }
  }, [buttonOnClick, navigate, setHideHeader]);

  const messageTitle =
    title ??
    (type === 'page'
      ? t('general:errors.pageNotFound.title')
      : t('general:errors.objectNotFound.title'));
  const messageSubtitle =
    subtitle ??
    (type === 'page'
      ? t('general:errors.pageNotFound.subtitle')
      : t('general:errors.objectNotFound.subtitle'));
  const messageButtonText =
    buttonText ??
    (type === 'page'
      ? t('general:errors.pageNotFound.button')
      : t('general:errors.objectNotFound.button'));

  return (
    <NotFoundPage
      data-testid="404"
      title={messageTitle ?? ''}
      subtitle={messageSubtitle ?? ''}
      buttonText={messageButtonText ?? ''}
      buttonOnClick={handleButtonClick}
    />
  );
};
