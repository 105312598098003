import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../../components/ActionsBar';
import {
  GatewayFormInfo,
  GatewayFormData,
  getGatewayFormInfoSchema,
} from '../../common/GatewayFormInfo';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type ValidationState = 'LOADING' | 'ERROR' | 'VALID' | 'INVALID';

export type GatewayEditData = GatewayFormData;

type GatewayEditProps = GatewayEditData & {
  allDeviceModels: Array<{
    id: string;
    name: string;
    isGateway: boolean;
  }>;
  loading?: boolean;
  onSubmit: (location: GatewayEditData) => void;
  onDiscard: () => void;
  disabledFieldIds?: string[];
};

export const GatewayEdit: React.FC<GatewayEditProps> = ({
  name,
  description,
  serialNo,
  deviceModelId,
  deviceIdentifier,
  allDeviceModels,
  loading,
  onSubmit,
  onDiscard,
  disabledFieldIds,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const validationSchema = getGatewayFormInfoSchema(t, true);
  const defaultValues: GatewayEditData = {
    name,
    description,
    serialNo,
    deviceModelId,
    deviceIdentifier,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { reset, handleSubmit } = methods;
  useEffect(() => {
    reset({
      name,
      description,
      serialNo,
      deviceModelId,
      deviceIdentifier,
    });
  }, [reset, name, description, serialNo, deviceModelId, deviceIdentifier]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <GatewayFormInfo
        disabledFieldIds={disabledFieldIds}
        loading={loading}
        allDeviceModels={allDeviceModels}
      />
    </FormProvider>
  );
};
