import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';

import { StoreyDetails } from './StoreyDetails';
import { useStoreyDetailsQuery } from '../../../__generated__/types';
import { ErrorMessage } from '../../../components/Errors';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { StoreyDeleteContainer } from '../delete/StoreyDeleteContainer';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const StoreyDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['storeys', 'general']);
  const navigate = useNavigate();
  const { storeyId } = useParams();
  const [deleteMode, setDeleteMode] = useState(false);
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  const { loading, error, data } = useStoreyDetailsQuery({
    variables: { storeyId },
  });

  const storey = data?.storey;

  const hierarchy = storey?.building?.site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: storey.building.site.id,
            name: storey.building.site.name,
          },
          {
            type: Entities.BUILDING,
            id: storey.building.id,
            name: storey.building.name,
          },
          {
            type: Entities.STOREY,
            id: storey.id,
            name: storey.name,
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: storey?.name });
  }, [setTitle, storey]);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.BUILDING, storey?.building?.id ?? ''));
  const handleEdit = () =>
    navigate(`${entityLocation(Entities.STOREY, storeyId)}/edit`);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !storey) {
    return (
      <NotFound
        title={t('storeys:details.notFound.title') ?? ''}
        subtitle={t('storeys:details.notFound.subtitle') ?? ''}
        buttonText={t('storeys:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.STOREY.path)}
      />
    );
  }

  const image = {
    url:
      storey?.image?.url ??
      `${process.env.PUBLIC_URL}/assets/placeholders/storey.png`,
    name: storey?.image?.name ?? '',
  };
  return (
    <>
      <StoreyDetails
        name={storey?.name ?? ''}
        description={storey?.description ?? ''}
        image={image}
        onDelete={
          hasPermission(Permissions.StoreysWrite) ? handleDelete : undefined
        }
        onEdit={
          hasPermission(Permissions.StoreysWrite) ? handleEdit : undefined
        }
        showZones={hasPermission(Permissions.ZonesRead)}
        loading={loading}
      />
      <StoreyDeleteContainer
        id={storeyId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
