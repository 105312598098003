import React from 'react';
import MuiInputBase, {
  InputBaseProps as MuiInputBaseProps,
} from '@mui/material/InputBase';
import { Controller, useFormContext } from 'react-hook-form';

export type InputBaseFieldProps = Omit<
  MuiInputBaseProps,
  'variant' | 'name'
> & {
  name: string;
  optional?: boolean;
};

export const InputBaseField: React.FC<InputBaseFieldProps> = ({
  optional,
  ...props
}) => {
  const { control } = useFormContext();

  const { name, type = 'text' } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiInputBase
          id={name}
          fullWidth
          {...field}
          {...props}
          type={type}
          error={!!error}
        />
      )}
    />
  );
};
