import { TFunction } from 'i18next';
import * as Entities from '../constants/Entities';
import { BreadcrumbLink } from '../contexts/breadcrumb-context';
import { entityLocation } from './entity';

/**
 * Gets the breadcrumb label for each entity
 *
 * @param entity  The entity's type to get the label for
 * @param t       The translate function from i18next
 */
export const entityBreadcrumbLabel = (
  entity: Entities.Entity,
  t: TFunction,
): string => {
  switch (entity) {
    case Entities.APPLICATION:
      return t('general:breadcrumb.application');
    case Entities.SITE:
      return t('general:breadcrumb.site');
    case Entities.BUILDING:
      return t('general:breadcrumb.building');
    case Entities.STOREY:
      return t('general:breadcrumb.storey');
    case Entities.ZONE:
      return t('general:breadcrumb.zone');
    case Entities.DEVICE:
      return t('general:breadcrumb.device');
    case Entities.DEVICEMODEL:
      return t('general:breadcrumb.devicemodel');
    case Entities.TENANT:
      return t('general:breadcrumb.tenant');
    case Entities.ATTRIBUTE:
      return t('general:breadcrumb.attribute');
    case Entities.CAPABILITY:
      return t('general:breadcrumb.capability');
    case Entities.UNIT:
      return t('general:breadcrumb.unit');
    case Entities.REGISTERED_APPLICATION:
      return t('general:breadcrumb.registeredApplication');
    case Entities.USER:
      return t('general:breadcrumb.user');
    case Entities.INTEGRATION:
      return t('general:breadcrumb.integration');
    case Entities.STATUS:
      return t('general:breadcrumb.status');
    default:
      throw new Error('Entity is not supported');
  }
};

export const entityBreadcrumbPrefix = (
  entity: Entities.Entity,
  t: TFunction,
): BreadcrumbLink[] => {
  switch (entity) {
    case Entities.APPLICATION:
      return [
        {
          title: entityBreadcrumbLabel(Entities.APPLICATION, t),
          location: Entities.APPLICATION.path,
        },
      ];
    case Entities.SITE:
      return [
        {
          title: entityBreadcrumbLabel(Entities.SITE, t),
          location: Entities.SITE.path,
        },
      ];
    case Entities.DEVICE:
      return [
        {
          title: entityBreadcrumbLabel(Entities.DEVICE, t),
          location: Entities.DEVICE.path,
        },
      ];
    case Entities.DEVICEMODEL:
      return [
        {
          title: entityBreadcrumbLabel(Entities.DEVICEMODEL, t),
          location: Entities.DEVICEMODEL.path,
        },
      ];
    case Entities.ATTRIBUTE:
      return [
        {
          title: entityBreadcrumbLabel(Entities.ATTRIBUTE, t),
          location: Entities.ATTRIBUTE.path,
        },
      ];
    case Entities.CAPABILITY:
      return [
        {
          title: entityBreadcrumbLabel(Entities.CAPABILITY, t),
          location: Entities.CAPABILITY.path,
        },
      ];
    case Entities.UNIT:
      return [
        {
          title: entityBreadcrumbLabel(Entities.UNIT, t),
          location: Entities.UNIT.path,
        },
      ];
    case Entities.TENANT:
      return [
        {
          title: entityBreadcrumbLabel(Entities.TENANT, t),
          location: Entities.TENANT.path,
        },
      ];
    case Entities.REGISTERED_APPLICATION:
      return [
        {
          title: entityBreadcrumbLabel(Entities.REGISTERED_APPLICATION, t),
          location: Entities.REGISTERED_APPLICATION.path,
        },
      ];
    case Entities.USER:
      return [
        {
          title: entityBreadcrumbLabel(Entities.USER, t),
          location: Entities.USER.path,
        },
      ];
    case Entities.INTEGRATION:
      return [
        {
          title: entityBreadcrumbLabel(Entities.INTEGRATION, t),
          location: Entities.INTEGRATION.path,
        },
      ];
    default:
      return [];
  }
};

interface BreadcrumbEntities {
  type: Entities.Entity;
  id: string;
  name: string;
  query?: Record<string, string>;
}

/**
 * Constructs the breadcrumb hierarchy for an entity.
 *
 * This function's output should be used directly with `useBreadcrumb` hook.
 *
 * It will always follow the entity relationships and throw an error if they are not
 * matched :
 *
 * ```
 * import * as Entities from "../constants/Entities.ts";
 *
 * makeHierarchyBreadcrumb( [
 *   { type: Entities.ZONE, id: "2", name: "My Zone" },
 *   { type: Entities.DEVICE, id: "1", name: "My Sensor" },
 * ], t);
 *
 * // [
 * //   { title: "My Zone", location: "/zones/2" },
 * //   { title: "My Sensor", location: "/devices/1" },
 * // ]
 * ```
 *
 * ```
 *
 * @param entities          The entities to get the breadcrumb for
 * @param t                 The translate function from i18next library
 */

export const makeHierarchyBreadcrumb = (
  entities: BreadcrumbEntities[],
  t: TFunction,
): BreadcrumbLink[] => {
  if (!entities || entities.length === 0) {
    throw new Error('You need to specify at least one entity');
  }

  return entities.reduce<BreadcrumbLink[]>(
    (links, { type, id, name, query }, index) => {
      const previousEntity = entities[index - 1];
      if (
        type.parent &&
        previousEntity &&
        previousEntity.type !== type.parent
      ) {
        throw new Error(
          `Entity ${type.debugKey} should have a ${type.parent.debugKey} defined before`,
        );
      }

      const linksWithPrefix = previousEntity
        ? links
        : links.concat(entityBreadcrumbPrefix(type, t));

      const location = `${entityLocation(type, id)}?${new URLSearchParams(
        query,
      )}`;
      return linksWithPrefix.concat([{ title: name, location }]);
    },
    [],
  );
};
