import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import {
  DeviceModelUpdateType,
  useDeviceModelUpdateMutation,
  useDeviceModelEditDetailsQuery,
  useDeviceTypesQuery,
} from '../../../__generated__/types';
import { DeviceModelForm, DeviceModelData } from '../common/DeviceModelForm';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const DeviceModelEditContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { setTitle } = useHeader();
  const { deviceModelId } = params;

  const {
    loading: loadingDeviceTypes,
    error: errorDeviceTypes,
    data: dataDeviceTypes,
  } = useDeviceTypesQuery();
  const allDeviceTypes = (dataDeviceTypes?.deviceTypes?.items ?? []).map(
    (dt) => ({
      id: dt.id,
      deviceTypeName: dt.name,
      isGateway: dt.isGateway ?? false,
    }),
  );

  const {
    loading: loadingDetails,
    error: errorDetails,
    data: dataDetails,
  } = useDeviceModelEditDetailsQuery({
    variables: { deviceModelId },
  });
  const deviceModel = dataDetails?.deviceModel;
  const isGlobal = deviceModel?.isGlobal ?? false;

  const hierarchy = deviceModel
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICEMODEL,
            id: deviceModel.id,
            name: deviceModel.name || deviceModel.id,
          },
        ],
        t,
      ).concat({
        title: t('deviceModels:breadcrumb.edit'),
        location: entityLocation(
          Entities.DEVICEMODEL,
          `${deviceModel.id}/edit`,
        ),
      })
    : [];
  useBreadcrumb(hierarchy);

  const [updateDeviceModel, { loading: loadingUpdate, error: errorUpdate }] =
    useDeviceModelUpdateMutation({
      onCompleted: () =>
        navigate(entityLocation(Entities.DEVICEMODEL, deviceModel?.id ?? '')),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = async (inputData: DeviceModelData) => {
    const deviceModelInput: DeviceModelUpdateType = {
      id: deviceModelId,
      name: inputData.deviceModelName,
      deviceTypeId: inputData.deviceTypeId,
      deviceIdentifierFieldSelector: inputData.deviceIdentifierFieldSelector,
    };

    return updateDeviceModel({
      variables: { deviceModelInput },
    });
  };

  const handleDiscard = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  useEffect(() => {
    setTitle({ main: t('deviceModels:breadcrumb.edit') });
  }, [setTitle, t]);

  if (errorDetails) {
    return <ErrorMessage error={errorDetails} />;
  }
  if (!loadingDetails && !deviceModel) {
    return (
      <NotFound
        title={t('deviceModels:details.notFound.title') ?? ''}
        subtitle={t('deviceModels:details.notFound.subtitle') ?? ''}
        buttonText={t('deviceModels:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICEMODEL.path)}
      />
    );
  }
  if (isGlobal) {
    return (
      <ErrorMessage error={new Error(t('deviceModels:globalWarning') ?? '')} />
    );
  }

  return (
    <>
      <DeviceModelForm
        loading={loadingDetails || loadingUpdate || loadingDeviceTypes}
        type="edit"
        id={deviceModelId}
        deviceModelName={deviceModel?.name ?? ''}
        deviceTypeId={deviceModel?.deviceType?.id ?? '0'}
        deviceIdentifierFieldSelector={
          deviceModel?.deviceIdentifierFieldSelector ?? ''
        }
        allDeviceTypes={allDeviceTypes}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate, errorDeviceTypes]}
      />
    </>
  );
};
