import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import omitEmpty from 'omit-empty-es';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useQuery } from '@tanstack/react-query';
import { useHeader } from '../../../contexts/header-context';
import { useFilters } from '../../../hooks/filters';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { ApplicationsList } from './ApplicationsList';
import { SortDirections } from '../../../api/common';

import {
  GetApplicationsFilter,
  getApplications,
} from '../../../api/application';

const paramToSortDirection = (param?: string | null) => {
  switch (param) {
    case 'asc':
      return SortDirections.Asc;
    case 'desc':
      return SortDirections.Desc;
    default:
      return undefined;
  }
};

export const ApplicationsListContainer: React.FC = () => {
  const { t } = useTranslation(['applications', 'general']);
  const { page, pageSize, sortDirection, sort, setSort, setPage, setPageSize } =
    useFilters<'name' | 'status'>();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.APPLICATION, t),
    location: Entities.APPLICATION.path,
  });

  const filter: GetApplicationsFilter<'name'> = useMemo(
    () =>
      omitEmpty({
        skip: page * pageSize,
        limit: pageSize,
        sort: sort === 'status' ? undefined : sort,
        sortDir: paramToSortDirection(sortDirection),
      }),
    [page, pageSize, sort, sortDirection],
  );

  const {
    data: response,
    error,
    isLoading: loading,
  } = useQuery({
    queryKey: ['getApplications', filter],
    queryFn: () => getApplications(filter),
  });

  const applications = response?.data ?? [];

  useEffect(() => {
    setTitle({ main: t('applications:list.title') });
  }, [setTitle, t]);

  if (error instanceof Error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <ApplicationsList
      loading={loading}
      page={page}
      pageSize={pageSize}
      sort={sort || 'status'}
      sortDirection={sortDirection}
      count={response?.meta?.totalSize ?? 0}
      onChangePageSize={setPageSize}
      onChangePage={setPage}
      onChangeSort={setSort}
      applications={applications}
      hasPermissionReadOnly={!hasPermission(Permissions.ApplicationsWrite)}
    />
  );
};
