import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import debounce from '@mui/material/utils/debounce';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  getTenantFormInfoSchema,
  TenantFormInfo,
  TenantFormInfoData,
} from '../common/TenantFormInfo';
import {
  AddressFormData,
  AddressForm,
  getRequiredAddressSchema,
} from '../../../components/Address';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  section: {
    '& + &': {
      marginTop: '24px',
    },
  },
});

export type TenantCreateData = TenantFormInfoData & AddressFormData;

interface TenantCreateProps {
  loading?: boolean;
  validateSlug: (slug: string) => Promise<string | null | undefined>;
  onSubmit: (tenant: TenantCreateData) => void;
  onDiscard: () => void;
}

export const TenantCreate: React.FC<TenantCreateProps> = ({
  loading,
  validateSlug,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['tenants', 'general']);

  const validationSchema = getTenantFormInfoSchema(t, validateSlug).concat(
    getRequiredAddressSchema(t),
  );
  const defaultValues: TenantCreateData = {
    name: '',
    slug: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    location: {
      city: '',
      country: '',
      no: '',
      street: '',
      zip: '',
    },
    inviteContactToTenant: true,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, watch, trigger } = methods;
  const slug = watch('slug');
  useEffect(() => {
    if (slug) debounce(() => trigger('slug'), 500)();
  }, [trigger, slug]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <div className={classes.section}>
        <TenantFormInfo loading={loading} type="create" />
      </div>
      <div className={classes.section}>
        <AddressForm title={t('tenants:form.address')} loading={loading} />
      </div>
    </FormProvider>
  );
};
