/**
 * Instance size
 */
export enum InstanceSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

/**
 * Provisioning status
 */
export enum Status {
  pending = 'pending',
  available = 'available',
  failed = 'failed',
}
