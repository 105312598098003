import {
  UserSvcInvitationsService as InvitationService,
  UserSvcInvitation,
} from '@kp/rest-api-javascript-sdk';
import { SortingFilter, ConditionalFilter } from '../common';

export enum UserInvitationStatus {
  Pending = 'pending',
  Accepted = 'accepted',
}

export type UserInvitation = UserSvcInvitation;

/**
 * Filtering options
 */
export enum UserInvitationsOptions {
  StatusPending = 'status:pending',
  StatusAccepted = 'status:accepted',
}

export type UserInvitationsFilter = ConditionalFilter<UserInvitationsOptions> &
  SortingFilter<'email' | 'createdAt'>;

/**
 * List all user invitations
 *
 * @param filter            The filter parameters
 */
export const getUserInvitations = async (filter: UserInvitationsFilter = {}) =>
  InvitationService.getUserApiInvitations(
    filter.skip,
    filter.limit,
    filter.filter,
    filter.sort,
    filter.sortDir,
  );

/**
 * Get details of a user invitation
 *
 * @param userInvitationId  The id of a user invitation that will be fetched
 */
export const getUserInvitation = InvitationService.getUserApiInvitations1;

/**
 * Create a new user invitation
 *
 * @param email             The email of a user that is being invited
 * @param userRoleIds       The roles of a user that is being invited
 */
export const createUserInvitation = InvitationService.postUserApiInvitations;

/**
 * Delete a user invitation
 *
 * @param userInvitationId  The id of a user invitation that will be deleted
 */
export const deleteUserInvitation = InvitationService.deleteUserApiInvitations;

/**
 * Remind an user invitation
 *
 * @param userInvitationId  The id of a user invitation that will be re-sent
 *
 */
export const resendUserInvitation =
  InvitationService.postUserApiInvitationsReminder;
