import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import { DeviceModelDetails } from './DeviceModelDetails';
import { useDeviceModelDetailsQuery } from '../../../__generated__/types';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { entityLocation } from '../../../utils/entity';
import { DeviceModelDeleteContainer } from '../delete/DeviceModelDeleteContainer';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const DeviceModelDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { setTitle } = useHeader();
  const { deviceModelId } = params;
  const { hasPermission } = useAuth();

  const [deleteMode, setDeleteMode] = useState(false);

  const { data, loading, error } = useDeviceModelDetailsQuery({
    variables: { deviceModelId },
  });

  const deviceModel = data?.deviceModel;
  const hierarchy = deviceModel
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICEMODEL,
            id: deviceModel.id,
            name: deviceModel.name || deviceModel.id,
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.DEVICEMODEL, ''));
  const handleEdit = () =>
    navigate(entityLocation(Entities.DEVICEMODEL, `${deviceModelId}/edit`));

  useEffect(() => {
    setTitle({ main: deviceModel?.name });
  }, [setTitle, deviceModel]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !deviceModel) {
    return (
      <NotFound
        title={t('deviceModels:details.notFound.title') ?? ''}
        subtitle={t('deviceModels:details.notFound.subtitle') ?? ''}
        buttonText={t('deviceModels:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICEMODEL.path)}
      />
    );
  }

  const deletePermission = deviceModel?.isLocked
    ? hasPermission(Permissions.DeviceModelsWriteLocked)
    : hasPermission(Permissions.DeviceModelsWrite);
  const editPermission =
    hasPermission(Permissions.DeviceModelsWrite) ||
    hasPermission(Permissions.DeviceModelsWriteLocked);

  return (
    <>
      <DeviceModelDetails
        loading={loading}
        name={deviceModel?.name ?? ''}
        deviceTypeName={deviceModel?.deviceType?.name ?? ''}
        isGateway={deviceModel?.deviceType?.isGateway ?? false}
        deviceIdentifierFieldSelector={
          deviceModel?.deviceIdentifierFieldSelector ?? ''
        }
        isGlobal={deviceModel?.isGlobal ?? false}
        onDelete={deletePermission ? handleDelete : undefined}
        onEdit={editPermission ? handleEdit : undefined}
      />
      <DeviceModelDeleteContainer
        id={deviceModelId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
