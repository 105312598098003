import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../../components/Alerts';
import { entityLocation } from '../../../../utils/entity';
import * as Entities from '../../../../constants/Entities';
import {
  useDeviceModelAttributeSetCreateDetailsQuery,
  useDeviceModelAttributeSetCreateMutation,
} from '../../../../__generated__/types';
import {
  DeviceModelAttributeSetData,
  DeviceModelAttributeSetForm,
} from '../common/DeviceModelAttributeSetForm';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { ErrorMessage } from '../../../../components/Errors';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';

export const DeviceModelAttributeSetCreateContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { deviceModelId } = params;
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useDeviceModelAttributeSetCreateDetailsQuery(
    {
      variables: { deviceModelId },
    },
  );

  const deviceModel = data?.deviceModel;

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.DEVICEMODEL,
        id: deviceModelId,
        name: deviceModel?.name || deviceModelId,
      },
    ],
    t,
  ).concat([
    {
      title: t('deviceModels:attributeSet.breadcrumb.create'),
      location: entityLocation(
        Entities.DEVICEMODEL,
        `${deviceModelId}/attribute-sets/new`,
      ),
    },
  ]);
  useBreadcrumb(hierarchy);

  const [
    createDeviceModelAttributeSet,
    { loading: loadingCreate, error: errorCreate },
  ] = useDeviceModelAttributeSetCreateMutation({
    onError: (e) => console.warn(e),
  });

  const handleDiscard = () => {
    navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId));
  };

  const handleCreate = (inputData: DeviceModelAttributeSetData) => {
    return createDeviceModelAttributeSet({
      variables: {
        deviceModelAttributeSetInput: {
          name: inputData.name,
          deviceModelId,
        },
      },
    }).then((result) =>
      navigate(
        entityLocation(
          Entities.DEVICEMODEL,
          result.data?.createDeviceModelAttributeSet?.id
            ? `${deviceModelId}/attribute-sets/${result.data.createDeviceModelAttributeSet.id}`
            : deviceModelId,
        ),
      ),
    );
  };

  useEffect(() => {
    setLoading(loading);
    setTitle({
      main: t('deviceModels:attributeSet.breadcrumb.create') ?? '',
    });
  }, [setLoading, loading, setTitle, t]);

  if (!loading && !deviceModel) {
    return (
      <ErrorMessage
        error={new Error(t('deviceModels:attributeSet.dataNotFound') ?? '')}
      />
    );
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (deviceModel?.isGlobal) {
    return (
      <ErrorMessage error={new Error(t('deviceModels:globalWarning') ?? '')} />
    );
  }

  return (
    <>
      <DeviceModelAttributeSetForm
        loading={loadingCreate}
        name=""
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorCreate]}
      />
    </>
  );
};
