import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorAlert } from '../../../components/Alerts';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import {
  useBuildingCreateMutation,
  useBuildingCreateParentSiteQuery,
  useBuildingCreateLocationCreateMutation,
} from '../../../__generated__/types';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useHeader } from '../../../contexts/header-context';
import { BuildingCreate, BuildingCreateData } from './BuildingCreate';

export const BuildingCreateContainer: React.FC = () => {
  const { t } = useTranslation(['buildings', 'general']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const siteId = searchParams.get('siteId') ?? '';
  const { setTitle } = useHeader();

  const {
    loading: loadingSite,
    error: errorSite,
    data: dataSite,
  } = useBuildingCreateParentSiteQuery({
    variables: { siteId },
  });

  const parentSite = dataSite?.site;
  const hierarchy = parentSite
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: parentSite.id,
            name: parentSite.name,
          },
        ],
        t,
      ).concat({
        title: t('buildings:breadcrumb.create'),
        location: entityLocation(Entities.BUILDING, `/new?siteId=${siteId}`),
      })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('buildings:breadcrumb.create') });
  }, [setTitle, t]);

  const [
    createLocation,
    { loading: loadingLocationCreate, error: errorLocationCreate },
  ] = useBuildingCreateLocationCreateMutation();

  const [create, { loading: loadingCreate, error: errorCreate }] =
    useBuildingCreateMutation({
      onCompleted: (result) => {
        if (result.createBuilding?.id) {
          navigate(entityLocation(Entities.BUILDING, result.createBuilding.id));
        }
      },
    });

  const handleDiscard = () => {
    if (siteId) {
      navigate(entityLocation(Entities.SITE, siteId));
    }
  };

  const handleCreate = async (data: BuildingCreateData) => {
    return createLocation({
      variables: { locationInput: { ...data.location, county: '' } },
    })
      .then((result) =>
        create({
          variables: {
            building: {
              name: data.name,
              description: data.description,
              dimension: data.dimension,
              locationId: result.data?.createLocation?.id,
              siteId,
            },
          },
        }),
      )
      .catch((e) => console.warn(e));
  };

  if (!loadingSite && !siteId) {
    return (
      <ErrorMessage error={new Error(t('buildings:form.notFound') ?? '')} />
    );
  }

  return (
    <>
      <BuildingCreate
        location={{
          city: parentSite?.location?.city ?? '',
          country: parentSite?.location?.country ?? '',
          street: parentSite?.location?.street ?? '',
          zip: parentSite?.location?.zip ?? '',
          no: parentSite?.location?.no ?? '',
        }}
        loading={loadingSite || loadingCreate || loadingLocationCreate}
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorSite, errorCreate, errorLocationCreate]}
      />
    </>
  );
};
