import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { DataTable } from '../../../components/DataTable';
import { Link } from '../../../components/Link';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { Application, Status } from '../../../api/application';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  icon: {
    marginRight: '8px',
    display: 'block',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface ApplicationsListProps {
  applications: Application[];
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onChangeSort: (value: 'name' | 'status', direction?: 'asc' | 'desc') => void;
  sort: 'name' | 'status';
  sortDirection?: 'asc' | 'desc';
  page: number;
  pageSize: number;
  count: number;
  hasPermissionReadOnly: boolean;
  loading: boolean;
}

export const ApplicationsList: React.FC<ApplicationsListProps> = ({
  applications,
  onChangePageSize,
  onChangePage,
  page,
  pageSize,
  sort,
  sortDirection,
  onChangeSort,
  count,
  hasPermissionReadOnly,
  loading,
}) => {
  const { t } = useTranslation(['applications', 'general']);

  const getStatusString = (status: 'activated' | 'deactivated' | 'updated') => {
    if (status === Status.activated) {
      return t('applications:list.status.activated');
    }
    if (status === Status.deactivated) {
      return t('applications:list.status.deactivated');
    }
    return (
      <div className={classes.status}>
        {t('applications:list.status.updated')}
        <Tooltip
          arrow
          placement="right"
          title={
            <div className={classes.tooltipContent}>
              {hasPermissionReadOnly
                ? t('applications:details.info.tooltip.updatedUser')
                : t('applications:details.info.tooltip.updatedAdmin')}
            </div>
          }
        >
          <Icons.FiberManualRecord
            fontSize="medium"
            color="primary"
            data-testid="updated-icon"
            className={classes.icon}
          />
        </Tooltip>
      </div>
    );
  };

  const handleSortableClick = useCallback(
    (key: string) => {
      if (key === sort) {
        // Don't flip the sort direction of status
        if (key !== 'status') {
          onChangeSort(key, sortDirection === 'desc' ? 'asc' : 'desc');
        }
      } else {
        onChangeSort(key as 'name' | 'status');
      }
    },
    [sort, sortDirection, onChangeSort],
  );

  const headers = [
    {
      key: 'name',
      value: t('general:fields.name'),
      sortable: true,
    },
    {
      key: 'description',
      value: t('general:fields.description'),
    },
    {
      key: 'status',
      value: t('applications:list.headers.status'),
      sortable: true,
    },
    {
      key: 'activatedAt',
      value: t('applications:list.headers.activatedAt'),
    },
    {
      key: 'createdAt',
      value: t('applications:list.headers.createdAt'),
    },
    {
      key: 'updatedAt',
      value: t('applications:list.headers.updatedAt'),
    },
  ];

  const rows = applications.map((application) => ({
    key: `${application.id}-row`,
    cells: [
      {
        key: `${application.id}-cell-name`,
        element: (
          <Link to={entityLocation(Entities.APPLICATION, application.id)}>
            {application.name || application.id}
          </Link>
        ),
      },
      {
        key: `${application.id}-cell-description`,
        element:
          application.description && application.description.length > 160
            ? `${application.description.slice(0, 160)}...`
            : application.description ?? '',
      },
      {
        key: `${application.id}-cell-active`,
        element: getStatusString(application.status),
      },
      {
        key: `${application.id}-cell-activatedAt`,
        element: <FormattedDate value={application.activatedAt} hideSeconds />,
      },
      {
        key: `${application.id}-cell-createdAt`,
        element: <FormattedDate value={application.createdAt} hideSeconds />,
      },
      {
        key: `${application.id}-cell-updatedAt`,
        element: <FormattedDate value={application.updatedAt} hideSeconds />,
        isLast: false,
      },
    ],
  }));

  return (
    <DataTable
      sort={sort}
      sortDirection={sortDirection}
      page={page}
      pageSize={pageSize}
      count={count}
      onSortableClick={handleSortableClick}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      headers={headers}
      rows={rows}
      showPagination
      loading={loading}
    />
  );
};
