import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorAlert } from '../../../components/Alerts';
import {
  useStoreyCreateMutation,
  useStoreyCreateParentBuildingQuery,
} from '../../../__generated__/types';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { StoreyForm, StoreyFormData } from '../common/StoreyForm';
import { ErrorMessage } from '../../../components/Errors';
import { useHeader } from '../../../contexts/header-context';

export const StoreyCreateContainer: React.FC = () => {
  const { t } = useTranslation(['storeys', 'general']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setTitle } = useHeader();
  const buildingId = searchParams.get('buildingId') ?? '';

  const { loading, error, data } = useStoreyCreateParentBuildingQuery({
    variables: { buildingId },
  });

  const building = data?.building;

  const hierarchy = building?.site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: building.site.id,
            name: building.site.name,
          },
          {
            type: Entities.BUILDING,
            id: building.id,
            name: building.name,
          },
        ],
        t,
      ).concat({
        title: t('storeys:breadcrumb.create'),
        location: entityLocation(
          Entities.STOREY,
          `/new?buildingId=${buildingId}`,
        ),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('storeys:breadcrumb.edit') });
  }, [setTitle, t]);

  const [createStorey, { loading: loadingCreate, error: errorCreate }] =
    useStoreyCreateMutation({
      onCompleted: (result) => {
        navigate(
          entityLocation(Entities.STOREY, result.createStorey?.id ?? ''),
        );
      },
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: StoreyFormData) =>
    createStorey({
      variables: {
        storey: {
          name: inputData.name,
          description: inputData.description,
          buildingId,
        },
      },
    });

  if (!buildingId) {
    return <ErrorMessage error={new Error(t('storeys:notFound') ?? '')} />;
  }

  const handleDiscard = () => {
    navigate(entityLocation(Entities.BUILDING, buildingId));
  };

  return (
    <>
      <StoreyForm
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
        name=""
        description=""
        loading={loadingCreate || loading}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorCreate]}
      />
    </>
  );
};
