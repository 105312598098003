import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, TextFieldProps } from './TextField';

export type DescriptionFieldProps = TextFieldProps;

export const DescriptionField: React.FC<DescriptionFieldProps> = ({
  optional = true,
  ...props
}) => {
  const { t } = useTranslation(['general']);

  return (
    <TextField
      label={t('general:fields.description')}
      multiline
      optional={optional}
      {...props}
    />
  );
};
