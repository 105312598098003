import React from 'react';
import { ApolloError } from '@apollo/client';
import { SiteDelete } from './SiteDelete';
import { useSiteDeleteMutation } from '../../../__generated__/types';

interface SiteDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const SiteDeleteContainer: React.FC<SiteDeleteContainerProps> = ({
  id,
  isOpen,
  onCompleted,
  onError,
  onCancel,
}) => {
  const [deleteSite, { loading, error }] = useSiteDeleteMutation({
    onCompleted,
    onError,
  });

  const handleConfirm = () => deleteSite({ variables: { siteId: id } });

  return (
    <SiteDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
