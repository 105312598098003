import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { DataTypes } from '../../__generated__/types';
import { DataTypeLabel } from '../DataTypeLabel';

interface DataTypeSelectProps extends SelectProps {
  name: string;
  label: JSX.Element;
  allowedValues: DataTypes[];
  fullWidth?: boolean;
}

export const DataTypeSelect: React.FC<DataTypeSelectProps> = ({
  label,
  fullWidth,
  allowedValues,
  ...props
}) => {
  const { t } = useTranslation(['general']);
  const { control } = useFormContext();
  const { name } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="standard" fullWidth={fullWidth} error={!!error}>
          <InputLabel id="dataTypeSelectLabel">{label}</InputLabel>
          <Select
            fullWidth={fullWidth}
            id="dataTypeSelect"
            labelId="dataTypeSelectLabel"
            {...field}
            renderValue={(dataType) =>
              dataType ? (
                <DataTypeLabel dataType={dataType as DataTypes} />
              ) : (
                t('general:dataType.select.empty')
              )
            }
            {...props}
          >
            {allowedValues.map((type) => (
              <MenuItem value={type} key={type}>
                <DataTypeLabel dataType={type} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error?.message ?? ' '}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
