import React from 'react';
import { createClasses } from '@kp/react-ui';

const classes = createClasses({
  root: {
    position: 'relative',
  },
  wrapper: {
    position: 'absolute',
    right: -50,
    top: -150,
    width: 800,
    height: 800,
    overflow: 'hidden',
    zIndex: -1,
  },
  icon: {
    opacity: 0.02,
    position: 'absolute',
    right: -150,
    top: 0,
    width: 800,
    height: 800,
    zIndex: -1,
    '& svg': {
      width: 800,
      height: 800,
    },
  },
});

interface FancyBackgroundProps {
  icon?: React.ReactNode;
}

export const FancyBackground: React.FC<FancyBackgroundProps> = ({ icon }) => {
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.icon}>{icon}</div>
      </div>
    </div>
  );
};
