import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityLocation } from '../../../utils/entity';
import { DeviceLocation, DeviceLocationData } from './DeviceLocation';
import {
  PlacementKind,
  useDeviceLocationQuery,
  useDeviceLocationUpdateMutation,
} from '../../../__generated__/types';
import { ErrorAlert } from '../../../components/Alerts';
import { ErrorMessage } from '../../../components/Errors';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const DeviceLocationContainer: React.FC = () => {
  const { deviceId } = useParams();
  const { t } = useTranslation(['devices', 'general']);
  const navigate = useNavigate();
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useDeviceLocationQuery({
    variables: { deviceId },
  });

  const placement = data?.placementOfDevice;
  const device = data?.device;
  const allSites = data?.sites?.items;
  const locationType = placement?.zoneId
    ? PlacementKind.Zone
    : PlacementKind.Building;
  const locationId = placement?.zoneId || placement?.buildingId;

  const [
    updateLocation,
    { loading: loadingUpdateLocation, error: errorUpdateLocation },
  ] = useDeviceLocationUpdateMutation();

  const hierarchy = device?.name
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: deviceId,
            name: device.name,
          },
        ],
        t,
      ).concat({
        title: t('devices:location.breadcrumb.title'),
        location: entityLocation(Entities.DEVICE, `${deviceId}/location`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('devices:location.breadcrumb.title') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  const handleDiscard = () => {
    navigate(entityLocation(Entities.DEVICE, deviceId));
  };

  const handleSubmit = (location: DeviceLocationData) => {
    if (!location.locationId || !location.locationType) {
      return;
    }
    updateLocation({
      variables: {
        deviceId,
        locationId: location.locationId,
        locationType: location.locationType,
      },
    })
      .then(() => navigate(entityLocation(Entities.DEVICE, deviceId)))
      .catch((e) => console.warn(e));
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !device) {
    return (
      <NotFound
        title={t('devices:details.notFound.title') ?? ''}
        subtitle={t('devices:details.notFound.subtitle') ?? ''}
        buttonText={t('devices:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }

  return (
    <>
      <DeviceLocation
        allSites={allSites ?? []}
        loading={loading || loadingUpdateLocation}
        onDiscard={handleDiscard}
        onSubmit={handleSubmit}
        locationId={locationId ?? '0'}
        locationType={locationType}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorUpdateLocation]}
      />
    </>
  );
};
