import React, { useEffect } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { useCapabilityListQuery } from '../../../__generated__/types';
import * as Entities from '../../../constants/Entities';
import { CapabilityList } from './CapabilityList';
import { ErrorMessage } from '../../../components/Errors';
import { entityLocation } from '../../../utils/entity';
import { useFilters } from '../../../hooks/filters';
import { useHeader } from '../../../contexts/header-context';

export const CapabilityListContainer: React.FC = () => {
  const { t } = useTranslation(['capabilities', 'general']);
  const navigate = useNavigate();
  const { page, pageSize, query, setPage, setPageSize, setQuery } =
    useFilters();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  const handleAdd = () => navigate(entityLocation(Entities.CAPABILITY, `new`));

  const { loading, error, data } = useCapabilityListQuery({
    variables: {
      take: pageSize,
      skip: page * pageSize,
      where: {
        name: { contains: query },
      },
    },
  });

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.CAPABILITY, t),
    location: Entities.CAPABILITY.path,
  });

  const capabilities = data?.capabilities?.items ?? [];
  const count = data?.capabilities?.totalCount ?? 0;

  useEffect(() => {
    setTitle({ main: t('capabilities:list.title') });
  }, [setTitle, t]);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <CapabilityList
      loading={loading}
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePageSize={setPageSize}
      onChangePage={setPage}
      searchValue={query}
      onSearchSubmit={setQuery}
      capabilities={capabilities}
      onAdd={
        hasPermission(Permissions.CapabilitiesWrite) ? handleAdd : undefined
      }
    />
  );
};
