import * as Entities from '../constants/Entities';

/**
 * Gets the path of an entity
 *
 * Example:
 *
 * ```
 * import * as Entities from "../constants/Entities.ts";
 *
 * entityLocation(Entities.DEVICE, "1"); // `/devices/1`
 * ```
 *
 * @param entity  The type of the entity to get the location for
 * @param id      The id of the entity
 * @param query   AdditionalQuery Parameters
 */
export const entityLocation = (
  entity: Entities.Entity,
  id: string,
  query?: Record<string, string>,
): string => {
  const queryString = query ? `?${new URLSearchParams(query)}` : '';
  return `${entity.path}/${id}${queryString}`;
};
