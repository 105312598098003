import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  FieldDeviceFormInfo,
  FieldDeviceFormData,
  getFieldDeviceFormInfoSchema,
} from './FieldDeviceFormInfo';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type ValidationState = 'LOADING' | 'ERROR' | 'VALID' | 'INVALID';

export type FieldDeviceEditData = FieldDeviceFormData & { id?: string };

type FieldDeviceEditProps = FieldDeviceEditData & {
  allGateways: Array<{ id: string; name: string }>;
  allDeviceModels: Array<{
    id: string;
    name: string;
    isGateway: boolean;
  }>;
  loading?: boolean;
  onSubmit: (location: FieldDeviceEditData) => void;
  onDiscard: () => void;
  buttonsAtBottom?: boolean;
  disabledFieldIds?: string[];
};

export const FieldDeviceEdit: React.FC<FieldDeviceEditProps> = ({
  name,
  description,
  serialNo,
  deviceIdentifier,
  deviceModelId,
  parentDeviceId,
  allGateways,
  allDeviceModels,
  loading,
  onSubmit,
  onDiscard,
  buttonsAtBottom,
  disabledFieldIds,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const validationSchema = getFieldDeviceFormInfoSchema(t, true);
  const defaultValues: FieldDeviceEditData = {
    name,
    description,
    serialNo,
    deviceIdentifier,
    deviceModelId,
    parentDeviceId,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { reset, handleSubmit } = methods;
  useEffect(() => {
    reset({
      name,
      description,
      serialNo,
      deviceIdentifier,
      deviceModelId,
      parentDeviceId,
    });
  }, [
    reset,
    name,
    description,
    serialNo,
    deviceIdentifier,
    deviceModelId,
    parentDeviceId,
  ]);

  return (
    <FormProvider {...methods}>
      {!buttonsAtBottom && (
        <ActionsBar>
          <Button
            onClick={onDiscard}
            color="secondary"
            size="large"
            className={classes.actionButton}
            aria-label="discard-button"
          >
            {t('general:buttons.discard')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className={classes.actionButton}
            color="primary"
            variant="outlined"
            size="large"
            aria-label="save-button"
            disabled={loading}
          >
            {t('general:buttons.save')}
          </Button>
        </ActionsBar>
      )}
      <FieldDeviceFormInfo
        loading={loading}
        allGateways={allGateways}
        allDeviceModels={allDeviceModels}
        disabledFieldIds={disabledFieldIds}
      />
      {buttonsAtBottom && (
        <ActionsBar>
          <Button
            onClick={onDiscard}
            color="secondary"
            size="large"
            className={classes.actionButton}
            aria-label="discard-button"
          >
            {t('general:buttons.discard')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className={classes.actionButton}
            color="primary"
            variant="outlined"
            size="large"
            aria-label="save-button"
            disabled={loading}
          >
            {t('general:buttons.save')}
          </Button>
        </ActionsBar>
      )}
    </FormProvider>
  );
};
