const getBacnetDeviceType = (type: string) => {
  switch (type) {
    case '0':
      return 'analog-input';
    case '1':
      return 'analog-output';
    case '2':
      return 'analog-value';
    case '3':
      return 'binary-input';
    case '4':
      return 'binary-output';
    case '5':
      return 'binary-value';
    case '6':
      return 'calendar';
    case '7':
      return 'command';
    case '8':
      return 'device';
    case '10':
      return 'file';
    case '11':
      return 'group';
    case '12':
      return 'loop';
    case '13':
      return 'multi-state-input';
    case '14':
      return 'multi-state-output';
    case '15':
      return 'notification-class';
    case '16':
      return 'program';
    case '17':
      return 'schedule';
    case '18':
      return 'averaging';
    case '19':
      return 'multi-state-value';
    case '20':
      return 'trend-log';
    case '21':
      return 'life-safety-point';
    case '22':
      return 'life-safety-zone';
    case '23':
      return 'accumulator';
    case '24':
      return 'pulse-converter';
    case '25':
      return 'event-log';
    case '26':
      return 'global-group';
    case '27':
      return 'trend-log-multiple';
    case '28':
      return 'load-control';
    case '29':
      return 'structured-view';
    case '30':
      return 'access-door';
    case '31':
      return 'timer';
    case '32':
      return 'access-credential';
    case '33':
      return 'access-point';
    case '34':
      return 'access-rights';
    case '35':
      return 'access-user';
    case '36':
      return 'access-zone';
    case '37':
      return 'credential-data-input';
    case '38':
      return '-';
    case '39':
      return 'bitstring-value';
    case '40':
      return 'characterstring-value';
    case '41':
      return 'datepattern-value';
    case '42':
      return 'date-value';
    case '43':
      return 'datetimepattern-value';
    case '44':
      return 'datetime-value';
    case '45':
      return 'integer-value';
    case '46':
      return 'large-analog-value';
    case '47':
      return 'octetstring-value';
    case '48':
      return 'positive-integer-value';
    case '49':
      return 'timepattern-value';
    case '50':
      return 'time-value';
    case '51':
      return 'notification-forwarder';
    case '52':
      return 'alert-enrollment';
    case '53':
      return 'channel';
    case '54':
      return 'lighting-output';
    case '55':
      return 'binary-lighting-output';
    case '56':
      return 'network-port';
    case '57':
      return 'elevator-group ';
    case '58':
      return 'escalator';
    case '59':
      return 'lift';
    case '60':
      return 'staging';
    case '61':
      return 'audit-log';
    case '62':
      return 'audit-reporter';
    default:
      return '?';
  }
};

export const translateBacnetId = (id?: string) => {
  if (!id) return '';
  if (!id.startsWith('(') || !id.endsWith(')') || !(id.indexOf(',') > 1)) {
    throw new Error(`${id} is not a valid BACnet Object Identifier`);
  }
  const withoutBrackets = id.slice(1, -1);
  const [type, identifier] = withoutBrackets.split(',');
  return `(${getBacnetDeviceType(type.trim())},${identifier.trim()})`;
};
