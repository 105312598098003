import React from 'react';
import { Button, IconButton, ListItemText, Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ActionsBar } from '../../../components/ActionsBar';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  uris: {
    fontSize: 14,
    fontFamily: 'monospace',
    background: Color.inputBackground,
  },
});

type UnitDetailsProps = {
  name: string;
  symbol: string;
  uris: string[];
  isGlobal: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  loading?: boolean;
};

export const UnitDetails: React.FC<UnitDetailsProps> = ({
  name,
  symbol,
  uris,
  isGlobal,
  onEdit,
  onDelete,
  loading,
}) => {
  const { t } = useTranslation(['units', 'general']);

  return (
    <>
      <ActionsBar>
        {!loading && !isGlobal && onDelete && (
          <Button color="primary" size="large" onClick={onDelete}>
            {t('units:details.actions.delete')}
          </Button>
        )}
      </ActionsBar>
      <FancyCard
        title={t('units:details.title')}
        actions={
          onEdit && (
            <Tooltip
              arrow
              placement="top-end"
              title={isGlobal ? t('units:details.globalWarning') ?? '' : ''}
              aria-label="settings-tooltip"
            >
              <span>
                <IconButton
                  aria-label="edit"
                  data-testid="card-edit"
                  onClick={onEdit}
                  size="medium"
                  color="primary"
                  disabled={isGlobal}
                >
                  <Icons.Edit fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          )
        }
        loading={loading}
      >
        <InfoList>
          <InfoListItem term={t('units:details.name')}>{name}</InfoListItem>
          <InfoListItem term={t('units:details.symbol')}>{symbol}</InfoListItem>
          <InfoListItem term={t('units:details.uris')}>
            {uris.length
              ? uris.map((uri, index) => (
                  <ListItemText key={index.toString()}>
                    <span className={classes.uris}>{uri}</span>
                  </ListItemText>
                ))
              : '-'}
          </InfoListItem>
        </InfoList>
      </FancyCard>
    </>
  );
};
