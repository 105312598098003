import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import { SiteDetails } from './SiteDetails';
import { useSiteDetailsQuery } from '../../../__generated__/types';
import { ErrorMessage } from '../../../components/Errors';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { SiteDeleteContainer } from '../delete/SiteDeleteContainer';
import { useHeader } from '../../../contexts/header-context';
import { createAddressString } from '../../../utils/helpers';
import { NotFound } from '../../errorScreens';

export const SiteDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['sites', 'general']);
  const navigate = useNavigate();
  const { hasPermission } = useAuth();
  const { siteId } = useParams();
  const { setTitle } = useHeader();
  const [deleteMode, setDeleteMode] = useState(false);

  const { loading, error, data } = useSiteDetailsQuery({
    variables: { siteId },
  });
  const site = data?.site;

  const hierarchy = site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: site.id,
            name: site.name,
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: site?.name, sub: createAddressString(site?.location) });
  }, [setTitle, site]);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.SITE, ''));
  const handleEditInfo = () =>
    navigate(`${entityLocation(Entities.SITE, siteId)}/edit`);
  const handleEditLocation = () =>
    navigate(`${entityLocation(Entities.SITE, siteId)}/location`);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !site) {
    return (
      <NotFound
        title={t('sites:details.notFound.title') ?? ''}
        subtitle={t('sites:details.notFound.subtitle') ?? ''}
        buttonText={t('sites:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.SITE.path)}
      />
    );
  }

  const location = site?.location;

  const image = {
    url:
      site?.image?.url ??
      `${process.env.PUBLIC_URL}/assets/placeholders/site.png`,
    name: site?.image?.name ?? '',
  };

  return (
    <>
      <SiteDetails
        loading={loading}
        name={site?.name ?? ''}
        description={site?.description ?? ''}
        city={location?.city ?? ''}
        street={location?.street ?? ''}
        no={location?.no ?? ''}
        country={location?.country ?? ''}
        zip={location?.zip ?? ''}
        image={image}
        onDelete={
          hasPermission(Permissions.SitesWrite) ? handleDelete : undefined
        }
        onEditInfo={
          hasPermission(Permissions.SitesWrite) ? handleEditInfo : undefined
        }
        onEditLocation={
          hasPermission(Permissions.SitesWrite) ? handleEditLocation : undefined
        }
        showBuildings={hasPermission(Permissions.BuildingsRead)}
      />
      <SiteDeleteContainer
        id={siteId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
