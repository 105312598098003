import React, { useContext, createContext, useState, useMemo } from 'react';
import type { ElementType } from 'react';

export interface Title {
  main?: string | null;
  sub?: string | null;
}
interface HeaderContextValue {
  title: Title;
  icon: ElementType | undefined;
  loading: boolean;
  hideHeader: boolean;
  setIcon: (icon: ElementType) => void;
  setTitle: (title: Title) => void;
  setLoading: (loading: boolean) => void;
  setHideHeader: (hideHeader: boolean) => void;
}

const HeaderContext = createContext<HeaderContextValue>({
  title: {},
  icon: undefined,
  loading: false,
  hideHeader: false,
  setIcon: () => {
    // this is empty on purpose
  },
  setTitle: () => {
    // this is empty on purpose
  },
  setLoading: () => {
    // this is empty on purpose
  },
  setHideHeader: () => {
    // this is empty on purpose
  },
});

export const useHeader = (): HeaderContextValue => useContext(HeaderContext);

type HeaderProviderProps = {
  children: React.ReactNode;
};
export const HeaderProvider: React.FC<HeaderProviderProps> = ({ children }) => {
  const [title, setTitle] = useState<Title>({});
  const [icon, setIcon] = useState<ElementType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hideHeader, setHideHeader] = useState<boolean>(false);
  const value = useMemo(
    () => ({
      title,
      setTitle,
      icon,
      setIcon,
      loading,
      hideHeader,
      setLoading,
      setHideHeader,
    }),
    [hideHeader, icon, loading, title],
  );
  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};
