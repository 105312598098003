import React from 'react';
import { createClasses } from '@kp/react-ui';
import { FormHelperText, InputLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeOptional } from '../Fields';

const classes = createClasses({
  label: {
    marginBottom: '8px',
  },
});

interface DataTypeInputBooleanProps {
  disabled?: boolean;
  value: string;
  id: string;
  name: string;
  label?: string;
  className?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

export const DataTypeInputBoolean = React.forwardRef<
  HTMLButtonElement,
  DataTypeInputBooleanProps
>(
  (
    {
      disabled,
      value,
      id,
      name,
      label,
      className,
      onChange,
      autoFocus = true,
      error,
      helperText,
      optional,
    },
    ref,
  ) => {
    const { t } = useTranslation(['general']);
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
      event,
    ) => {
      onChange(event.target.checked ? 'true' : 'false');
    };

    return (
      <>
        {label && (
          <InputLabel shrink className={classes.label} htmlFor={id}>
            {optional ? makeOptional(t, label) : label}
          </InputLabel>
        )}
        <Switch
          ref={ref}
          color="primary"
          className={className}
          id={id}
          name={name}
          disabled={disabled}
          autoFocus={autoFocus}
          checked={value === 'true'}
          size="small"
          onChange={handleChange}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </>
    );
  },
);

DataTypeInputBoolean.displayName = 'DataTypeInputBoolean';
