import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import { DeviceModelAttributeSetDetails } from './DeviceModelAttributeSetDetails';
import { DeviceModelDeleteContainer } from '../delete/DeviceModelAttributeSetDeleteContainer';
import { useDeviceModelAttributeSetDetailsQuery } from '../../../../__generated__/types';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import * as Entities from '../../../../constants/Entities';
import { ErrorMessage } from '../../../../components/Errors';
import { entityLocation } from '../../../../utils/entity';
import { useHeader } from '../../../../contexts/header-context';
import { NotFound } from '../../../errorScreens';

export const DeviceModelAttributeSetDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { setTitle } = useHeader();
  const { deviceModelId, deviceModelAttributeSetId } = params;
  const [deleteMode, setDeleteMode] = useState(false);
  const { hasPermission } = useAuth();

  const { data, loading, error } = useDeviceModelAttributeSetDetailsQuery({
    variables: { deviceModelAttributeSetId },
  });

  const deviceModelAttributeSet = data?.deviceModelAttributeSet;
  const deviceModel = deviceModelAttributeSet?.deviceModel;

  const hierarchy = deviceModel
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICEMODEL,
            id: deviceModelId,
            name: deviceModel?.name || deviceModelId,
          },
        ],
        t,
      ).concat([
        {
          title: t('deviceModels:attributeSet.breadcrumb.title'),
          location: '',
        },
        {
          title: deviceModelAttributeSet?.name || deviceModelAttributeSetId,
          location: entityLocation(
            Entities.DEVICEMODEL,
            `${deviceModelId}/attribute-sets/${deviceModelAttributeSetId}`,
          ),
        },
      ])
    : [];

  useBreadcrumb(hierarchy);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId));
  const handleEdit = () =>
    navigate(
      entityLocation(
        Entities.DEVICEMODEL,
        `${deviceModelId}/attribute-sets/${deviceModelAttributeSetId}/edit`,
      ),
    );

  useEffect(() => {
    setTitle({ main: deviceModelAttributeSet?.name });
  }, [setTitle, deviceModelAttributeSet]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !deviceModelAttributeSet) {
    return (
      <NotFound
        title={t('deviceModels:attributeSet.notFound.title') ?? ''}
        subtitle={t('deviceModels:attributeSet.notFound.subtitle') ?? ''}
        buttonText={t('deviceModels:attributeSet.notFound.buttonText') ?? ''}
        buttonOnClick={() =>
          navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId))
        }
      />
    );
  }
  if (
    deviceModelAttributeSet?.deviceModelId &&
    deviceModelId !== deviceModelAttributeSet?.deviceModelId
  ) {
    return (
      <NotFound
        title={t('deviceModels:attributeSet.notFound.title') ?? ''}
        subtitle={t('deviceModels:attributeSet.notFound.subtitle') ?? ''}
        buttonText={t('deviceModels:attributeSet.notFound.buttonText') ?? ''}
        buttonOnClick={() =>
          navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId))
        }
      />
    );
  }

  return (
    <>
      <DeviceModelAttributeSetDetails
        name={deviceModelAttributeSet?.name ?? ''}
        loading={loading}
        isGlobal={deviceModel?.isGlobal ?? false}
        onEdit={
          hasPermission(Permissions.AttributesWrite) ? handleEdit : undefined
        }
        onDelete={
          hasPermission(Permissions.AttributesWrite) ? handleDelete : undefined
        }
      />
      <DeviceModelDeleteContainer
        id={deviceModelAttributeSetId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
