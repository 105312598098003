import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Permissions, useAuth } from '@kp/react-sdk';
import {
  Attribute,
  AttributeMapping,
} from './DeviceModelAttributeSetDetailsAttributesCard';
import { DeviceModelAttributeSetDetailsAttributesCardContainer } from './DeviceModelAttributeSetDetailsAttributesCardContainer';
import { ActionsBar } from '../../../../components/ActionsBar';
import { EmptyBanner } from '../../../../components/EmptyBanner';
import { DataTypes } from '../../../../__generated__/types';

interface DeviceModelAttributeSetDetailsAttributesProps {
  attributeSetId: string;
  mappings: Array<AttributeMapping>;
  allAttributes: Array<Attribute>;
  isDeviceModelGlobal: boolean;
}

export const DeviceModelAttributeSetDetailsAttributes: React.FC<
  DeviceModelAttributeSetDetailsAttributesProps
> = ({ isDeviceModelGlobal, allAttributes, mappings, attributeSetId }) => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const { hasPermission } = useAuth();

  const [newMappings, setNewMappings] = useState(mappings);

  const emptyMapping: AttributeMapping = {
    mappingId: '0',
    attributeId: '0',
    attributeName: t('general:forms.pleaseChoose'),
    required: false,
    dataType: DataTypes.NotSpecified,
  };

  const handleAdd = () => {
    setNewMappings([...newMappings, emptyMapping]);
  };
  const handleUpdate = (
    mappingId: string,
    updatedMapping: AttributeMapping,
  ) => {
    const updatedAttributes = [...newMappings];
    const index = updatedAttributes.findIndex((m) => m.mappingId === mappingId);
    if (index !== -1) {
      updatedAttributes[index] = updatedMapping;
    } else {
      updatedAttributes.push(updatedMapping);
    }
    setNewMappings(updatedAttributes);
  };
  const handleDelete = (mappingId: string) => {
    const updatedAttributes = newMappings.filter(
      (m) => m.mappingId !== mappingId,
    );
    setNewMappings(updatedAttributes);
  };

  const hasUnsavedSaved = newMappings.some((m) => m.mappingId === '0');

  // filter the attributes that haven been used in other mappings already
  const mappingsWithAllAttributes = newMappings.map((mapping) => {
    const currentAttribute = allAttributes.find(
      (a) => a.attributeId === mapping.attributeId,
    );
    const filteredAttributes = allAttributes.filter(
      (attribute) =>
        !newMappings.find((m) => m.attributeId === attribute.attributeId),
    );
    const attributesToInclude: Attribute[] = [
      ...(currentAttribute ? [currentAttribute] : []),
      ...filteredAttributes,
    ];
    return { mapping, attributesToInclude };
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        {mappingsWithAllAttributes.length === 0 ? (
          <EmptyBanner
            description={t(
              'deviceModels:details.attributeSets.attributes.empty',
            )}
            actionLabel={t('deviceModels:attributes.actions.addButton')}
            onClickAction={handleAdd}
            disableAction={
              isDeviceModelGlobal || !hasPermission(Permissions.AttributesWrite)
            }
          />
        ) : (
          <>
            <ActionsBar title={t('deviceModels:attributes.actions.title')}>
              {!isDeviceModelGlobal &&
                hasPermission(Permissions.AttributesWrite) && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    startIcon={<Icons.Add />}
                    disabled={hasUnsavedSaved}
                    onClick={handleAdd}
                  >
                    {t('deviceModels:attributes.actions.addButton')}
                  </Button>
                )}
            </ActionsBar>
            {mappingsWithAllAttributes.map(
              ({ mapping, attributesToInclude }) => (
                <DeviceModelAttributeSetDetailsAttributesCardContainer
                  key={mapping.mappingId}
                  attributeSetId={attributeSetId}
                  mapping={mapping}
                  onUpdate={
                    hasPermission(Permissions.AttributesWrite)
                      ? handleUpdate
                      : undefined
                  }
                  onDelete={
                    hasPermission(Permissions.AttributesWrite)
                      ? handleDelete
                      : undefined
                  }
                  allAttributes={attributesToInclude}
                  isDeviceModelGlobal={isDeviceModelGlobal}
                />
              ),
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
