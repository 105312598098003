import React from 'react';
import { createClasses } from '@kp/react-ui';
import {
  Divider,
  IconButton,
  Paper,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DataTypes } from '../../../__generated__/types';
import { DataTypeInput } from '../../../components/DataTypeInput';
import { DataTypeValue } from '../../../components/DataTypeValue';

const classes = createClasses({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    borderRadius: '5px',
  },
  icon: {
    margin: '0 0 0 10px',
  },
  name: {
    fontSize: '16px',
    margin: '0',
  },
  divider: {
    height: 30,
    margin: '8px 10px',
  },
  value: {
    maxWidth: '500px',
    textAlign: 'right',
    margin: '0 10px 0 0',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'center',
    padding: '0 10px 0 5px',
  },
  actions: {
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  iconButton: {
    width: '40px',
    height: '40px',
  },
  textInput: {
    textAlign: 'right',
    fontSize: '16px',
    fontFamily: 'monospace',
  },
  loader: {
    margin: '0 10px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

interface DeviceAttributesAttributeCardProps {
  id: string;
  name: string;
  value: string;
  edit: boolean;
  loading: boolean;
  required: boolean;
  exists: boolean;
  dataType: DataTypes | null;
  onEdit: () => void;
  onSubmit: () => void;
  onDiscard: () => void;
  onDelete: () => void;
  onChange: (value: string) => void;
}

export const DeviceAttributesAttributeCard: React.FC<
  DeviceAttributesAttributeCardProps
> = ({
  id,
  name,
  value,
  edit,
  loading,
  required,
  dataType,
  exists,
  onEdit,
  onSubmit,
  onDiscard,
  onDelete,
  onChange,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const isValueValid = (value ?? '').trim().length > 0;
  return (
    <Paper
      className={classes.root}
      elevation={1}
      data-testid={`attribute-${id}`}
    >
      <Tooltip
        arrow
        placement="top"
        title={
          <div className={classes.tooltipContent}>
            {required
              ? t('general:forms.required')
              : t('general:forms.optional')}
          </div>
        }
      >
        <Icons.FiberManualRecord
          fontSize="medium"
          color={required ? 'primary' : 'disabled'}
          className={classes.icon}
        />
      </Tooltip>
      <Divider orientation="vertical" className={classes.divider} />
      <div className={classes.content}>
        <div className={classes.name}>{name}</div>
        <div className={classes.form}>
          <div className={classes.value}>
            {edit ? (
              <DataTypeInput
                dataType={dataType}
                value={value}
                id={`attributeValue${id}`}
                name={`attributeValue${id}`}
                onChange={onChange}
                className={classes.textInput}
                disabled={loading}
              />
            ) : (
              exists && <DataTypeValue dataType={dataType} value={value} />
            )}
          </div>
          <div className={classes.actions}>
            <Divider orientation="vertical" className={classes.divider} />
            {edit && loading && (
              <CircularProgress size="20px" className={classes.loader} />
            )}
            {edit && !loading && (
              <>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={onSubmit}
                  disabled={!isValueValid}
                  data-testid={`attribute-${id}-save`}
                >
                  <Icons.Check fontSize="small" />
                </IconButton>
                {exists && (
                  <IconButton
                    color="primary"
                    className={classes.iconButton}
                    onClick={onDelete}
                    disabled={required}
                    data-testid={`attribute-${id}-delete`}
                  >
                    <Icons.DeleteOutline fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  onClick={onDiscard}
                  color="secondary"
                  className={classes.iconButton}
                  data-testid={`attribute-${id}-discard`}
                >
                  <Icons.CancelOutlined fontSize="small" />
                </IconButton>
              </>
            )}
            {!edit && (
              <IconButton
                className={classes.iconButton}
                onClick={onEdit}
                data-testid={`attribute-${id}-edit`}
              >
                <Icons.Edit fontSize="small" />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
};
