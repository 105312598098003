export interface Entity {
  debugKey: string;
  path: string;
  parent?: Entity;
}

export const APPLICATION: Entity = {
  debugKey: 'Application',
  path: `/applications`,
};

export const REGISTERED_APPLICATION: Entity = {
  debugKey: 'Registered Application',
  path: `/development`,
};

export const SITE: Entity = {
  debugKey: 'Site',
  path: `/sites`,
};

export const BUILDING: Entity = {
  debugKey: 'Building',
  path: `/buildings`,
  parent: SITE,
};

export const STOREY: Entity = {
  debugKey: 'Storey',
  path: `/storeys`,
  parent: BUILDING,
};

export const ZONE: Entity = {
  debugKey: 'Zone',
  path: `/zones`,
  parent: STOREY,
};

export const DEVICE: Entity = {
  debugKey: 'Device',
  path: `/devices`,
};

export const DATA: Entity = {
  debugKey: 'Data',
  path: `/data`,
  parent: DEVICE,
};

export const DEVICEMODEL: Entity = {
  debugKey: 'Device Models',
  path: `/devicemodels`,
};

export const ATTRIBUTE: Entity = {
  debugKey: 'Attributes',
  path: `/attributes`,
};

export const CAPABILITY: Entity = {
  debugKey: 'Capabilities',
  path: `/capabilities`,
};

export const UNIT: Entity = {
  debugKey: 'Units',
  path: `/units`,
};

export const TENANT: Entity = {
  debugKey: 'Tenant',
  path: `/tenants`,
};

export const USER: Entity = {
  debugKey: 'User',
  path: `/users`,
};

export const INTEGRATION: Entity = {
  debugKey: 'Integration',
  path: `/integrations`,
};

export const STATUS: Entity = {
  debugKey: 'Status',
  path: `/status`,
};
