import React, { useEffect } from 'react';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import { Routes, Route } from 'react-router-dom';
import { ZoneCreateContainer } from './create/ZoneCreateContainer';
import { ZoneDetailsContainer } from './details/ZoneDetailsContainer';
import { ZoneEditContainer } from './edit/ZoneEditContainer';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.SITES.icon;

export const ZonesRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<ZoneCreateContainer />}
            permission={Permissions.ZonesWrite}
          />
        }
      />
      <Route path="/:zoneId" element={<ZoneDetailsContainer />} />
      <Route
        path="/:zoneId/edit"
        element={
          <ProtectedRoute
            element={<ZoneEditContainer />}
            permission={Permissions.ZonesWrite}
          />
        }
      />
    </Routes>
  );
};
