import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Paper, Radio } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useFormContext } from 'react-hook-form';
import { Color } from '../../../constants/Colors';
import {
  permissionStringsToValues,
  KnownPermissionsValueType,
} from '../../../constants/Permissions';

const classes = createClasses({
  infoBox: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  templates: {
    padding: '0px 4px 32px 4px',
  },
  template: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative',
    padding: '16px',
    height: '100%',
  },
  headline: {
    paddingBottom: '8px',
    textAlign: 'center',
    width: '100%',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  radio: {
    position: 'absolute',
    padding: '16px',
    top: 3,
    left: 0,
    color: Color.primaryOrange,
    '&.Mui-checked': {
      color: Color.primaryOrange,
    },
  },
});

enum TemplateId {
  data = 'data',
  device = 'device',
  custom = 'custom',
}

type Template = {
  id: TemplateId;
  testId: string;
  title: string;
  info: string;
  permissions: KnownPermissionsValueType;
};

export type PermissionFormTemplatesProps = {
  onCustom: (customSelected: boolean) => void;
};

export const PermissionFormTemplates: React.FC<PermissionFormTemplatesProps> =
  React.memo(({ onCustom }) => {
    const { t } = useTranslation(['registeredApplications', 'general']);

    const { setValue } = useFormContext<{
      permissions: KnownPermissionsValueType;
    }>();
    const [templateId, setTemplateId] = useState<TemplateId>(TemplateId.custom);

    const templates: Template[] = [
      {
        id: TemplateId.custom,
        testId: 'custom-template',
        title: t(
          'registeredApplications:permissions.templates.customTemplate.title',
        ),
        info: t(
          'registeredApplications:permissions.templates.customTemplate.info',
        ),
        permissions: permissionStringsToValues(t, []),
      },
      {
        id: TemplateId.data,
        testId: 'data-template',
        title: t(
          'registeredApplications:permissions.templates.dataTemplate.title',
        ),
        info: t(
          'registeredApplications:permissions.templates.dataTemplate.info',
        ),
        permissions: permissionStringsToValues(t, [
          'sites:read',
          'buildings:read',
          'storeys:read',
          'zones:read',
          'devices:read',
          'device-data:read',
          'device-models:read',
          'attributes:read',
          'capabilities:read',
          'units:read',
        ]),
      },
      {
        id: TemplateId.device,
        testId: 'device-template',
        title: t(
          'registeredApplications:permissions.templates.deviceTemplate.title',
        ),
        info: t(
          'registeredApplications:permissions.templates.deviceTemplate.info',
        ),
        permissions: permissionStringsToValues(t, [
          'sites:read',
          'sites:write',
          'buildings:read',
          'buildings:write',
          'storeys:read',
          'storeys:write',
          'zones:read',
          'zones:write',
          'devices:read',
          'devices:write',
          'device-data:read',
          'device-models:read',
          'device-models:write',
          'attributes:read',
          'attributes:write',
          'capabilities:read',
          'capabilities:write',
          'units:read',
          'units:write',
        ]),
      },
    ];

    const selectTemplate = (template: Template) => {
      setTemplateId(template.id);
      onCustom(template.id === TemplateId.custom);
      setValue('permissions', template.permissions);
    };
    return (
      <Grid container spacing={3} direction="row" className={classes.templates}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" className={classes.infoBox}>
            {t('registeredApplications:permissions.templates.title')}
          </Typography>
          <Typography variant="body2" className={classes.infoBox}>
            {t('registeredApplications:permissions.templates.info')}
          </Typography>
        </Grid>
        {templates.map((template) => (
          <Grid item sm={12} md={4} key={template.id}>
            <Paper elevation={1} className={classes.template}>
              <Typography variant="h6" className={classes.headline}>
                {template.title}
              </Typography>
              <Radio
                name="template"
                checked={templateId === template.id}
                onChange={() => selectTemplate(template)}
                className={classes.radio}
                inputProps={{
                  // @ts-ignore
                  'data-testid': template.testId,
                }}
              />
              <Typography variant="body2">{template.info}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  });

PermissionFormTemplates.displayName = 'PermissionFormTemplates';
