import {
  ApplicationSvcApplication,
  ApplicationSvcApplicationManagementService as ApplicationService,
  ApplicationSvcApplicationPermissions,
} from '@kp/rest-api-javascript-sdk';
import { SortingFilter } from '../common';

export type Application = ApplicationSvcApplication;

export enum Status {
  activated = 'activated',
  deactivated = 'deactivated',
  updated = 'updated',
}

export interface GetApplicationsFilter<T> extends SortingFilter<T> {
  status?: Status;
}

/**
 * List all applications
 *
 * If filter status is applied, the list will only contain applications
 * that are activated, deactivated or updated.
 *
 * @param filter         The filter parameters
 */
export const getApplications = async (
  filter: GetApplicationsFilter<'name' | 'createdAt' | 'activatedAt'> = {},
) =>
  ApplicationService.getApplicationApiApplications(
    filter.status,
    filter.sort,
    filter.sortDir,
    filter.skip,
    filter.limit,
  );

/**
 * Get details of an application
 *
 * @param applicationId  The id of an application that will be fetched
 */
export const getApplication = ApplicationService.getApplicationApiApplications1;

type ActiateApplicationInput = {
  applicationId: string;
  consentedPermissions?: ApplicationSvcApplicationPermissions;
};
/**
 * Activate a new application
 *
 * @param applicationId        The id of an application that will be activated
 * @param consentedPermissions The permissions that are granted by the user
 */
export const activateApplication = ({
  applicationId,
  consentedPermissions,
}: ActiateApplicationInput) =>
  ApplicationService.postApplicationApiApplicationsActivation(applicationId, {
    consentedPermissions,
  });

/**
 * Remove an active application
 *
 * @param applicationId  The id of an application that will be deactivated
 */
export const deactivateApplication =
  ApplicationService.deleteApplicationApiApplicationsActivation;
