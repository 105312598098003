import {
  QanteonSvcProvisionService as QanteonService,
  QanteonSvcProvision,
} from '@kp/rest-api-javascript-sdk';

export type QanteonProvision = QanteonSvcProvision;
export enum ErrorCodes {
  NO_PROVISION_FOUND = 'NO_PROVISION_FOUND',
}

export interface CreateQanteonEntity
  extends Partial<
    Pick<QanteonProvision, 'name' | 'description' | 'instanceSize'>
  > {
  identifier: string;
}

/**
 * Get details of qanteon provision
 */
export const getQanteonProvision = QanteonService.getQanteonApiProvision;

/**
 * Provision a new qanteon instance
 *
 * @param data   The data for qanteon provision
 */
export const createQanteonProvision = QanteonService.postQanteonApiProvision;
