import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FancyCard } from '../../../components/FancyCard';
import { TextField } from '../../../components/Fields';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

export type UnitData = {
  name: string;
  unitSymbol: string;
};

type UnitFormSettingsProps = {
  loading?: boolean;
};

export const getUnitFormSettingsSchema = (
  t: TFunction,
): Yup.ObjectSchema<UnitData> =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('general:errors.required') ?? '')
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? ''),
    unitSymbol: Yup.string()
      .required(t('general:errors.required') ?? '')
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? ''),
  });

export const UnitFormSettings: React.FC<UnitFormSettingsProps> = ({
  loading,
}) => {
  const { t } = useTranslation(['units', 'general']);

  return (
    <FancyCard title={t('units:details.title')} loading={loading}>
      <Grid
        container
        spacing={1}
        direction="column"
        className={classes.cardContent}
      >
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading}
            id="name"
            name="name"
            label={t('units:form.unitName')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={loading}
            id="unitSymbol"
            name="unitSymbol"
            label={t('units:form.unitSymbol')}
          />
        </Grid>
      </Grid>
    </FancyCard>
  );
};
