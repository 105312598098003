import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import {
  CapabilityCreateType,
  DataTypes,
  useCapabilityCreateMutation,
} from '../../../__generated__/types';
import { CapabilityData, CapabilityForm } from '../common/CapabilityForm';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';

export const CapabilityCreateContainer: React.FC = () => {
  const { t } = useTranslation(['capabilities', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const { setTitle } = useHeader();

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.CAPABILITY,
        id: '/new',
        name: t('capabilities:breadcrumb.create'),
      },
    ],
    t,
  );

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('capabilities:breadcrumb.create') });
  }, [setTitle, t]);

  const [createCapability, { loading: loadingCreate, error: errorCreate }] =
    useCapabilityCreateMutation({
      onCompleted: (result) => {
        navigate(
          entityLocation(
            Entities.CAPABILITY,
            result.createCapability?.id ?? '',
          ),
        );
      },
      onError: (error) => console.warn(error),
    });

  const handleDiscard = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  const handleCreate = (inputData: CapabilityData) => {
    const capabilityInput: CapabilityCreateType = {
      name: inputData.capabilityName,
      description: inputData.description,
      dataType: inputData.dataType ?? DataTypes.NotSpecified,
    };

    return createCapability({
      variables: { capabilityInput },
    });
  };

  return (
    <>
      <CapabilityForm
        loading={loadingCreate}
        type="create"
        capabilityName=""
        dataType={DataTypes.NotSpecified}
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorCreate]}
      />
    </>
  );
};
