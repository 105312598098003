import { TableCell, TableRow } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import React from 'react';

const classes = createClasses({
  errorMessage: { color: 'white', backgroundColor: 'red' },
});

export type ErrorRowProps = {
  span: number;
  message: string;
};
export const ErrorRow: React.FC<ErrorRowProps> = ({ span, message }) => {
  return (
    <TableRow>
      <TableCell colSpan={span} align="center" className={classes.errorMessage}>
        {message}
      </TableCell>
    </TableRow>
  );
};
