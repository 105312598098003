import * as Yup from 'yup';

// eslint-disable-next-line no-control-regex
export const onlyAsciiEmailRegEx = /^[\x00-\x7F]+$/;

// Taken from https://github.com/jquense/yup/issues/224
// prettier-ignore
const rUrl = /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
// rUrl without the protocol (e.g. http://)
// prettier-ignore
const rUri = /^(?:([a-z0-9+.-]+):\/\/)?(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

/**
 * Allows only ascii email
 */
// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'asciiEmail', function (message: string) {
  return this.email(message).matches(onlyAsciiEmailRegEx, message);
});

/**
 * Validates a url that can contain only localhost or any other without a tld at the
 * end.
 *
 * See https://github.com/jquense/yup/issues/224 for more information
 */
// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'urlWithoutTLD', function (message: string) {
  return this.matches(rUrl, {
    name: 'url',
    excludeEmptyString: true,
    message,
  });
});
/**
 * Validates a uri.
 *
 */
// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'uri', function (message: string) {
  return this.matches(rUri, {
    name: 'uri',
    excludeEmptyString: true,
    message,
  });
});
