import React from 'react';
import { ApolloError } from '@apollo/client';
import { DeviceModelCapabilityDelete } from './DeviceModelCapabilityDelete';
import { useDeviceModelCapabilityDeleteMutation } from '../../../../__generated__/types';

interface DeviceModelCapabilityDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const DeviceModelCapabilityDeleteContainer: React.FC<
  DeviceModelCapabilityDeleteContainerProps
> = ({ id, isOpen, onCompleted, onError, onCancel }) => {
  const [deleteDeviceModelCapability, { loading, error }] =
    useDeviceModelCapabilityDeleteMutation({
      onCompleted,
      onError,
    });

  const handleConfirm = () =>
    deleteDeviceModelCapability({
      variables: { deviceModelCapabilityId: id },
    });

  return (
    <DeviceModelCapabilityDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
