import React, { useEffect } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { useDevicesListQuery } from '../../../__generated__/types';
import * as Entities from '../../../constants/Entities';
import { DevicesList } from './DevicesList';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useFilters } from '../../../hooks/filters';
import { useHeader } from '../../../contexts/header-context';

export const DevicesListContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general']);
  const navigate = useNavigate();
  const { page, pageSize, query, setPage, setPageSize, setQuery } =
    useFilters();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();

  const handleAdd = (type: 'gateway' | 'fielddevice') =>
    navigate(entityLocation(Entities.DEVICE, `new-${type}`));

  const { loading, error, data } = useDevicesListQuery({
    variables: {
      take: pageSize,
      skip: page * pageSize,
      where: {
        name: { contains: query },
      },
    },
  });

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.DEVICE, t),
    location: Entities.DEVICE.path,
  });

  const devices = (data?.devices?.items ?? []).map((device) => ({
    name: device.name,
    id: device.id,
    isLocked: device.isLocked ?? false,
    model: device.deviceModel.name ?? '',
    type: device.deviceModel.deviceType.name ?? '',
  }));
  const count = data?.devices?.totalCount ?? 0;

  useEffect(() => {
    setTitle({ main: t('devices:list.title') });
  }, [setTitle, t]);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <DevicesList
      onSearchSubmit={setQuery}
      searchValue={query}
      loading={loading}
      devices={devices}
      onAdd={hasPermission(Permissions.DevicesWrite) ? handleAdd : undefined}
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePageSize={setPageSize}
      onChangePage={setPage}
    />
  );
};
