import {
  UserSvcUser,
  UserSvcUsersService as UserService,
} from '@kp/rest-api-javascript-sdk';
import { SortingFilter } from '../common';

export type User = UserSvcUser;

/**
 * List all users
 *
 * @param filter       The filter parameters
 */
export const getUsers = async (
  filter: SortingFilter<'firstName' | 'lastName' | 'email' | 'createdAt'> = {},
) =>
  UserService.getUserApiUsers(
    filter.skip,
    filter.limit,
    filter.sort,
    filter.sortDir,
  );

/**
 * Get details of a user
 *
 * @param userId       The id of a user that will be fetched
 */
export const getUser = UserService.getUserApiUsers1;

/**
 * Remove a user from tenant and delete their invitation
 *
 * @param userId       The id of a user that will be removed
 */
export const deleteUser = UserService.deleteUserApiUsers;
