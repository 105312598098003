import {
  ApiError,
  CommissioningSvcMappingDraft,
  CommissioningSvcMappingDraftDeviceMapping,
} from '@kp/rest-api-javascript-sdk';
import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getMatchingModels } from '../../../../api/bacnet';
import {
  useMappingsCreateDeviceModelsLazyQuery,
  useMappingsCreateDeviceByNameLazyQuery,
} from '../../../../__generated__/types';
import {
  BACnetTarget,
  Capability,
  CreatedDevice,
  DeviceModel,
  MappedDatapoint,
  findIndexByTarget,
  stateUpdateByModelSelection,
  stateUpdateByDeviceSelection,
  getPreselectedModel,
  getPreselectedDevice,
  mapDeviceModels,
  mapDraftEntryMappingsToDatapoints,
  getDraftEntriesWithModels,
  mapMatchingDeviceModels,
  getCapabilityOptions,
  stateUpdateByRemoveDevice,
  cleanDraft,
  findByTarget,
} from './MappingStateUtils';

export type MappingByDevice = {
  device: CreatedDevice;
  model?: DeviceModel;
  mappings: Array<{
    deviceIdentifier: string;
    objectIdentifier: string;
    capability?: Capability;
    mappingDetails: BACnetTarget;
  }>;
};
export type MappingsByDevice = MappingByDevice[];

export type MappingDraft = {
  devices: Array<{
    name: string;
    deviceModelId: string;
    mappings: Array<CommissioningSvcMappingDraftDeviceMapping>;
  }>;
};

export type UseMappingState = {
  selectDatapoints: (targets: BACnetTarget[]) => Promise<void>;
  selectDeviceModel: (deviceModelId?: string) => void;
  selectDevice: (name?: string) => void;
  deleteDevice: (name: string) => MappedDatapoint[];
  deleteMapping: (target: BACnetTarget) => MappedDatapoint[];
  selectCapability: (
    target: BACnetTarget,
    selectedCapabilityId: string,
  ) => void;
  mappingState: MappedDatapoint[];
  setStateFromMappingDraft: (
    datapoints: CommissioningSvcMappingDraft,
    scanResult: BACnetTarget[],
  ) => Promise<MappedDatapoint[]>;
  getMappingDraft: (sourceState?: MappedDatapoint[]) => MappingDraft;
  storeStateSnapshot: () => void;
  restoreStateSnapshot: () => void;
  resetStateSnapshot: () => void;
  selectedDeviceModel?: DeviceModel;
  selectedDevice?: CreatedDevice;
  deviceModelSelection?: DeviceModel[];
  allCreatedDevices: CreatedDevice[];
  deviceSelection?: CreatedDevice[];
  loading?: boolean;
  error?: Error;
};

type StateSnapshot = {
  mappingState: MappedDatapoint[];
};
export const useMappingState = (): UseMappingState => {
  const [mappingState, setMappingState] = useState<MappedDatapoint[]>([]);
  const [deviceModelSelection, setDeviceModelSelection] = useState<
    DeviceModel[]
  >([]);
  const [selectedDeviceModel, setSelectedDeviceModel] = useState<DeviceModel>();
  const [selectedDevice, setSelectedDevice] = useState<CreatedDevice>();
  const [allCreatedDevices, setAllCreatedDevices] = useState<CreatedDevice[]>(
    [],
  );
  const [stateSnapshot, setStateSnapshot] = useState<StateSnapshot>();

  const [
    loadDeviceModels,
    { loading: loadingDeviceModels, error: errorDeviceModels },
  ] = useMappingsCreateDeviceModelsLazyQuery();
  const [
    loadDevicesByName,
    { loading: loadingDevicesByName, error: errorDevicesByName },
  ] = useMappingsCreateDeviceByNameLazyQuery();

  const {
    mutateAsync: callGetMatchingModels,
    isLoading: loadingMatchingModels,
    error: errorMatchingModels,
  } = useMutation({
    mutationFn: getMatchingModels,
    onError: (err: ApiError) => err,
  });

  const resetSelection = (
    {
      skipDeviceModels,
      skipSelectedDeviceModel,
      skipSelectedDevice,
    }: {
      skipDeviceModels?: boolean;
      skipSelectedDeviceModel?: boolean;
      skipSelectedDevice?: boolean;
    } = {
      skipDeviceModels: false,
      skipSelectedDeviceModel: false,
      skipSelectedDevice: false,
    },
  ) => {
    if (!skipSelectedDeviceModel) setSelectedDeviceModel(undefined);
    if (!skipSelectedDevice) setSelectedDevice(undefined);
    if (!skipDeviceModels) setDeviceModelSelection([]);
  };

  const loadMatchingModels = async (datapoints: MappedDatapoint[]) => {
    const unitUris = datapoints
      .filter((m) => m.isSelected)
      .map((target) => target.unitUri)
      .filter((uri) => uri) as string[];
    if (!unitUris.length) {
      resetSelection();
      return [];
    }
    const matchingModels = await callGetMatchingModels(unitUris);
    const matchPrio3Models =
      matchingModels?.data?.filter((model) => model.matchPrio < 3) ?? [];
    const mappedModels = mapMatchingDeviceModels(matchPrio3Models);
    return mappedModels;
  };

  const prefillDropdowns = async (newMappingState: MappedDatapoint[]) => {
    // load any matching models for the selection
    const matchingModels = await loadMatchingModels(newMappingState);
    resetSelection({ skipDeviceModels: true });
    setDeviceModelSelection(matchingModels);

    const preselectedModel = getPreselectedModel(newMappingState);
    if (preselectedModel) {
      setSelectedDeviceModel(preselectedModel);

      const preselectedDevice = getPreselectedDevice(newMappingState);
      setSelectedDevice(preselectedDevice);
    }
  };

  const selectTargets = (
    targets: BACnetTarget[],
    cleanMappingState: MappedDatapoint[],
  ) => {
    const mappingStateWithTargets = [...cleanMappingState];
    // set selected and append any missing targets
    targets.forEach((target) => {
      const matchingTargetIdx = findIndexByTarget(
        mappingStateWithTargets,
        target,
      );
      if (matchingTargetIdx > -1) {
        mappingStateWithTargets[matchingTargetIdx] = {
          ...mappingStateWithTargets[matchingTargetIdx],
          isSelected: true,
        };
      } else {
        mappingStateWithTargets.push({
          ...target,
          isSelected: true,
        });
      }
    });
    return mappingStateWithTargets;
  };

  const selectDatapoints = async (targets: BACnetTarget[]) => {
    // reset selection
    const cleanMappingState: MappedDatapoint[] = mappingState.map(
      (mapping) => ({
        ...mapping,
        isSelected: false,
      }),
    );

    // no datapoint selected, reset state
    if (!targets.length) {
      setMappingState(cleanMappingState);
      resetSelection();
      return;
    }

    // update selection
    const mappingStateWithSelection = selectTargets(targets, cleanMappingState);
    setMappingState(mappingStateWithSelection);

    // prefill of dropdowns
    await prefillDropdowns(mappingStateWithSelection);
  };

  const selectDeviceModel = (modelId?: string) => {
    const model = deviceModelSelection?.find((m) => m.id === modelId);
    setSelectedDeviceModel(model);
    setMappingState(stateUpdateByModelSelection(model));
  };

  const selectDevice = (deviceName?: string) => {
    const device =
      deviceName && selectedDeviceModel
        ? { name: deviceName, deviceModelId: selectedDeviceModel.id }
        : undefined;
    setSelectedDevice(device);
    if (device && !allCreatedDevices.find((d) => d.name === deviceName)) {
      setAllCreatedDevices(allCreatedDevices.concat(device));
    }

    const usedCapabilities = mappingState
      .filter((dp) => dp.device?.name === deviceName)
      .map((dp) => dp.capability?.id);
    const capabilityOptions = selectedDeviceModel?.deviceModelCapabilities.map(
      (dmc) => ({ ...dmc, isMapped: usedCapabilities.includes(dmc.id) }),
    );

    setMappingState(stateUpdateByDeviceSelection(device, capabilityOptions));
  };

  const deleteDevice = (deviceName: string) => {
    const deviceIndex = allCreatedDevices.findIndex(
      (d) => d.name === deviceName,
    );
    if (deviceIndex > -1) {
      const newCreatedDevices = [...allCreatedDevices];
      newCreatedDevices.splice(deviceIndex, 1);
      setAllCreatedDevices(newCreatedDevices);
    }

    // fixme:
    // because updating the state is batched, we don't have the updated mapping draft to save after the delete
    // this can be done more elegantly once we use react-18
    const newMappingState = stateUpdateByRemoveDevice(deviceName)(mappingState);
    setMappingState(newMappingState);
    return newMappingState;
  };

  const selectCapability = (
    target: BACnetTarget,
    selectedCapabilityId: string,
  ) => {
    // find the target datapoint
    const targetDatapointIdx = findIndexByTarget(mappingState, target);
    const targetDatapoint = mappingState[targetDatapointIdx];

    const targetDeviceName = targetDatapoint?.device?.name;
    if (!targetDeviceName) {
      console.warn(
        'target device name was not found when selecting a capability',
      );
      return;
    }

    const newMappingState = [...mappingState];

    // update the Mapping State with the capability
    const capability = selectedCapabilityId
      ? targetDatapoint.capabilityOptions?.find(
          (c) => c.id === selectedCapabilityId,
        )
      : undefined;
    newMappingState[targetDatapointIdx] = {
      ...targetDatapoint,
      capability,
    };

    // calculate the unassigned capabilities for all relevant datapoints
    const capabilityOptions = getCapabilityOptions(
      targetDeviceName,
      selectedDeviceModel,
      newMappingState,
    );

    // update the unassigned capabilities for all datapoints
    const mappingStateWithCleanCapabilities = newMappingState.map((dp) => ({
      ...dp,
      capabilityOptions:
        dp.device?.name === targetDeviceName
          ? [...capabilityOptions]
          : dp.capabilityOptions,
    }));

    setMappingState(mappingStateWithCleanCapabilities);
  };

  const deleteMapping = (target: BACnetTarget) => {
    const targetDatapoint = findByTarget(mappingState, target);
    const targetDeviceName = targetDatapoint?.device?.name;

    const newMappingState = mappingState.filter(
      (dp) =>
        !(
          dp.deviceIdentifier === target.deviceIdentifier &&
          dp.objectIdentifier === target.objectIdentifier
        ),
    );

    if (!targetDeviceName) {
      console.warn(
        'target device name was not found when selecting a capability',
      );
      setMappingState(newMappingState);
    } else {
      // calculate the unassigned capabilities for all relevant datapoints
      const capabilityOptions = getCapabilityOptions(
        targetDeviceName,
        selectedDeviceModel,
        newMappingState,
      );
      // update the unassigned capabilities for all datapoints
      const mappingStateWithCleanCapabilities = newMappingState.map((dp) => ({
        ...dp,
        capabilityOptions:
          dp.device?.name === targetDeviceName
            ? [...capabilityOptions]
            : dp.capabilityOptions,
      }));
      setMappingState(mappingStateWithCleanCapabilities);
    }

    const deviceName = targetDatapoint?.device?.name;
    if (!newMappingState.find((dp) => dp.device?.name === deviceName)) {
      const newDevices = allCreatedDevices.filter((d) => d.name !== deviceName);
      setAllCreatedDevices(newDevices);
    }
    return newMappingState;
  };

  type MappingsByDeviceMap = Record<string, MappingByDevice>;
  const getMappingStateByDevice = (sourceState: MappedDatapoint[]) => {
    const mappingsByDevice: MappingsByDeviceMap = {};
    sourceState.forEach((dp) => {
      if (dp.device) {
        const mappingDetails = {
          ...dp,
          isSelected: undefined,
          model: undefined,
          device: undefined,
          capabilityOptions: undefined,
          capability: undefined,
        };

        mappingsByDevice[dp.device.name] = {
          device: dp.device,
          model: dp.model,
          mappings: [
            ...(mappingsByDevice[dp.device.name]?.mappings ?? []),
            {
              deviceIdentifier: dp.deviceIdentifier,
              objectIdentifier: dp.objectIdentifier,
              capability: dp.capability,
              mappingDetails,
            },
          ],
        };
      }
    });

    const mappingStateByDevice = Object.values(mappingsByDevice);
    return mappingStateByDevice;
  };
  const getMappingDraft = (sourceState?: MappedDatapoint[]) => {
    const mappingStateByDevice = getMappingStateByDevice(
      sourceState ?? mappingState,
    );
    const mappingDraft = {
      devices: mappingStateByDevice.map((d) => ({
        name: d.device.name ?? '',
        deviceModelId: d.device.deviceModelId ?? '',
        mappings: d.mappings.map((mapping) => ({
          bacnetDeviceId: mapping.deviceIdentifier,
          bacnetObjectId: mapping.objectIdentifier,
          deviceModelCapabilityId: mapping.capability?.id ?? '',
        })),
      })),
    };
    return mappingDraft;
  };

  const getDeviceModels = useCallback(
    async (modelIds: string[]) => {
      const uniqueModelIds = Array.from(new Set(modelIds));
      const result = await loadDeviceModels({
        variables: {
          modelIds: uniqueModelIds,
        },
      });
      return result.data?.deviceModels?.items ?? [];
    },
    [loadDeviceModels],
  );
  const getDevicesByName = useCallback(
    async (deviceNames: string[]) => {
      const uniqueDeviceNames = Array.from(new Set(deviceNames));
      const result = await loadDevicesByName({
        variables: {
          deviceNames: uniqueDeviceNames,
        },
      });
      return result.data?.devices?.items ?? [];
    },
    [loadDevicesByName],
  );

  const setStateFromMappingDraft = useCallback(
    async (
      mappingDraft: CommissioningSvcMappingDraft,
      scanResult: BACnetTarget[],
    ) => {
      if (!mappingDraft?.devices) {
        setMappingState([]);
        return [];
      }

      const modelIdsToLoad = mappingDraft.devices.map((d) => d.deviceModelId);
      const loadedDeviceModels = await getDeviceModels(modelIdsToLoad);
      const mappedDeviceModels = mapDeviceModels(loadedDeviceModels);
      const draftEntriesWithModels = getDraftEntriesWithModels(
        mappingDraft.devices,
        mappedDeviceModels,
      );

      const deviceNamesToLoad = mappingDraft.devices.map((d) => d.name);
      const loadedDevices = await getDevicesByName(deviceNamesToLoad);
      const existingDeviceNames = loadedDevices.map((d) => d.name);
      const cleanedDraft = cleanDraft(
        draftEntriesWithModels,
        existingDeviceNames,
      );

      const createdDevices = cleanedDraft.map((dp) => dp.device);
      setAllCreatedDevices(createdDevices);

      const enrichedDatapoints = mapDraftEntryMappingsToDatapoints(
        cleanedDraft,
        scanResult,
      );

      setMappingState(enrichedDatapoints);
      return enrichedDatapoints;
    },
    [getDeviceModels, getDevicesByName],
  );

  const storeStateSnapshot = () => {
    if (stateSnapshot) return;
    const snapshotWithoutSelection = JSON.parse(
      JSON.stringify(mappingState.map((dp) => ({ ...dp, isSelected: false }))),
    );
    setStateSnapshot({ mappingState: snapshotWithoutSelection });
  };
  const restoreStateSnapshot = () => {
    if (!stateSnapshot) return;
    setMappingState(stateSnapshot.mappingState);
    setStateSnapshot(undefined);
  };
  const resetStateSnapshot = () => {
    setStateSnapshot(undefined);
  };

  return {
    mappingState,
    setStateFromMappingDraft,
    getMappingDraft,
    selectDatapoints,
    selectDeviceModel,
    selectDevice,
    deleteDevice,
    deleteMapping,
    selectCapability,
    storeStateSnapshot,
    restoreStateSnapshot,
    resetStateSnapshot,
    deviceModelSelection,
    allCreatedDevices,
    selectedDeviceModel,
    selectedDevice,
    loading:
      loadingMatchingModels || loadingDeviceModels || loadingDevicesByName,
    error: errorMatchingModels || errorDeviceModels || errorDevicesByName,
  };
};
