import React, { useEffect, useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createClasses } from '@kp/react-ui';
import { useAuth } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';
import { useBadge } from '../../contexts/badge-context';

const DashboardIcon = Pages.DASHBOARD.icon;

const classes = createClasses({
  card: {
    height: '100%',
  },
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const Dashboard: React.FC = () => {
  const { t } = useTranslation(['dashboard', 'general']);
  const { hasPermission } = useAuth();
  const { setTitle, setIcon } = useHeader();
  const { badges } = useBadge();

  useEffect(() => {
    setTitle({ main: t('dashboard:title') });
    setIcon(DashboardIcon);
  }, [setTitle, setIcon, t]);

  const buttons = useMemo(
    () =>
      [
        Pages.APPLICATIONS,
        Pages.SITES,
        Pages.DEVICES,
        Pages.USERS,
        Pages.USER_INVITATIONS,
        Pages.DEVELOPMENT,
      ].filter(({ permission }) =>
        permission ? hasPermission(permission) : true,
      ),
    [hasPermission],
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardHeader title={t('dashboard:cards.welcome.title')} />
            <CardContent>
              <Typography gutterBottom variant="body1" component="p">
                {t('dashboard:cards.welcome.text')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardHeader title={t('dashboard:cards.links.title')} />
            <CardContent>
              {buttons.map(({ label, icon: Icon, path }) => (
                <IconButton
                  component={Link}
                  to={typeof path === 'function' ? path(badges) : path}
                  key={label(t)}
                >
                  <div className={classes.iconButtonLabel}>
                    <Icon />
                    <Typography>{label(t)}</Typography>
                  </div>
                </IconButton>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
