import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TableContainer,
} from '@mui/material';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

export type CapabilityDetails = {
  id: string;
  name: string;
  unitName: string;
  unitSymbol: string;
};
export type HistoricalDataChartProps = {
  data: Array<{
    details: CapabilityDetails;
    values: Array<number[]>;
  }>;
};

export const HistoricalDataChart: React.FC<HistoricalDataChartProps> = ({
  data,
}) => {
  const { t } = useTranslation(['historicalData', 'general']);

  const chartOptions: Highcharts.Options = {
    title: {
      text: undefined,
    },
    chart: {
      zooming: { type: 'x' },
    },
    xAxis: {
      type: 'datetime',
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
  };

  const mappedData = data.map(({ details, values }) => {
    const series = [
      {
        type: 'line',
        name: details.name,
        data: values,
      },
    ];
    return {
      details,
      series,
    };
  });

  return (
    <>
      <Grid container spacing={3}>
        {mappedData.map(({ details, series }) => (
          <Grid item xs={12} sm={12} key={details.id}>
            <Card>
              <CardHeader title={details.name} />
              <TableContainer component={CardContent}>
                {!series.length ? (
                  <div>{t('historicalData:nodata')}</div>
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      ...chartOptions,
                      yAxis: {
                        title: {
                          text: details.unitName,
                        },
                      },
                      series,
                    }}
                  />
                )}
              </TableContainer>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
