import React from 'react';
import * as Icons from '@mui/icons-material';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../components/Link';
import { DataTable, DataTableRow } from '../../../components/DataTable';
import { Address, createAddressString } from '../../../utils/helpers';

export type Site = {
  id: string;
  name?: string;
  location?: Address;
};

type SitesListProps = {
  sites: Array<Site>;
  onAdd?: () => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onSearchSubmit: (query: string) => void;
  searchValue: string;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
};

export const SitesList: React.FC<SitesListProps> = ({
  sites,
  onAdd,
  onChangePageSize,
  onChangePage,
  page,
  pageSize,
  count,
  onSearchSubmit,
  searchValue,
  loading,
}) => {
  const { t } = useTranslation(['sites', 'general']);

  const headers = [
    {
      key: t('sites:list.headers.name'),
      value: t('sites:list.headers.name'),
    },
    {
      key: t('sites:list.headers.address'),
      value: t('sites:list.headers.address'),
    },
  ];

  const rows =
    sites
      .map((site) => {
        if (!site?.id) {
          return null;
        }

        return {
          key: `${site.id}-row`,
          cells: [
            {
              key: `${site.id}-cell-name`,
              element: (
                <Link to={`/sites/${site?.id}`}>{site?.name}</Link>
              ) as React.ReactNode,
            },
            {
              key: `${site.id}-cell-address`,
              element: createAddressString(site.location) as React.ReactNode,
              isLast: false,
            },
          ],
        };
      })
      .filter<DataTableRow>((row): row is DataTableRow => row !== null) ?? [];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <DataTable
          actions={
            onAdd && (
              <Button
                color="secondary"
                variant="outlined"
                size="large"
                startIcon={<Icons.Add />}
                onClick={onAdd}
              >
                {t('sites:list.addButton')}
              </Button>
            )
          }
          showSearch
          page={page}
          loading={loading}
          pageSize={pageSize}
          count={count}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
          onSearchSubmit={onSearchSubmit}
          searchValue={searchValue}
          headers={headers}
          rows={rows}
          showPagination
        />
      </Grid>
    </Grid>
  );
};
