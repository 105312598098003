import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';

const classes = createClasses({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

interface TenantCreateSuccessDialogProps {
  onConfirm: () => void;
  email: string;
  tenant: string;
  inviteContactToTenant: boolean;
}

export const TenantCreateSuccessDialog: React.FC<
  TenantCreateSuccessDialogProps
> = ({ onConfirm, email, tenant, inviteContactToTenant }) => {
  const { t } = useTranslation(['tenants', 'general']);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onConfirm}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6" component="span">
          {t('tenants:create.confirm.title')}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {inviteContactToTenant
            ? t('tenants:create.confirm.messageWithInvite', {
                email,
                tenant,
              })
            : t('tenants:create.confirm.messageWithoutInvite', { tenant })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          {t('general:buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
