import React, { useEffect } from 'react';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import { Routes, Route } from 'react-router-dom';
import { SiteCreateContainer } from './create/SiteCreateContainer';
import { SiteDetailsContainer } from './details/SiteDetailsContainer';
import { SiteEditContainer } from './edit/SiteEditContainer';
import { SitesListContainer } from './list/SitesListContainer';
import * as Pages from '../../constants/Pages';
import { SiteLocationContainer } from './location/SiteLocationContainer';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.SITES.icon;

export const SitesRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route path="/" element={<SitesListContainer />} />
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<SiteCreateContainer />}
            permission={Permissions.SitesWrite}
          />
        }
      />
      <Route path="/:siteId" element={<SiteDetailsContainer />} />
      <Route
        path="/:siteId/edit"
        element={
          <ProtectedRoute
            element={<SiteEditContainer />}
            permission={Permissions.SitesWrite}
          />
        }
      />
      <Route
        path="/:siteId/location"
        element={
          <ProtectedRoute
            element={<SiteLocationContainer />}
            permission={Permissions.SitesWrite}
          />
        }
      />
    </Routes>
  );
};
