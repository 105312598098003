import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import {
  useBuildingLocationCreateMutation,
  useBuildingLocationDetailsQuery,
  useBuildingLocationBuildingUpdateMutation,
  useBuildingLocationUpdateMutation,
} from '../../../__generated__/types';
import { useHeader } from '../../../contexts/header-context';
import { BuildingLocation, BuildingLocationData } from './BuildingLocation';
import { ErrorMessage } from '../../../components/Errors';
import { NotFound } from '../../errorScreens';

export const BuildingLocationContainer: React.FC = () => {
  const { t } = useTranslation(['buildings', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { buildingId } = params;
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useBuildingLocationDetailsQuery({
    variables: { buildingId },
  });
  const building = data?.building;

  const hierarchy = building
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: building.site.id,
            name: building.site.name,
          },
          {
            type: Entities.BUILDING,
            id: buildingId,
            name: building.name,
          },
        ],
        t,
      ).concat({
        title: t('buildings:breadcrumb.location'),
        location: entityLocation(Entities.BUILDING, `${buildingId}/location`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('buildings:breadcrumb.location') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  const [update, { loading: loadingUpdate, error: errorUpdate }] =
    useBuildingLocationUpdateMutation();

  const [create, { loading: loadingCreate, error: errorCreate }] =
    useBuildingLocationCreateMutation();

  const [
    updateBuilding,
    { loading: loadingUpdateBuilding, error: errorUpdateBuilding },
  ] = useBuildingLocationBuildingUpdateMutation();

  const createOrAddLocation = useCallback(
    async (location: BuildingLocationData['location']) => {
      if (!building?.id) {
        return;
      }
      if (!building?.locationId) {
        await create({ variables: { location } })
          .then((result) => result.data?.createLocation?.id)
          .then((locationId) =>
            updateBuilding({
              variables: { building: { id: building.id, locationId } },
            }),
          );
      } else {
        await update({
          variables: {
            location: { ...location, id: building?.locationId },
          },
        });
      }
    },
    [building, create, update, updateBuilding],
  );

  const handleSubmit = (location: BuildingLocationData) => {
    createOrAddLocation(location.location)
      .then(() => navigate(entityLocation(Entities.BUILDING, buildingId)))
      .catch((e) => console.warn(e));
  };

  const handleDiscard = () => {
    navigate(entityLocation(Entities.BUILDING, buildingId));
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !building) {
    return (
      <NotFound
        title={t('buildings:details.notFound.title') ?? ''}
        subtitle={t('buildings:details.notFound.subtitle') ?? ''}
        buttonText={t('buildings:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.BUILDING.path)}
      />
    );
  }

  return (
    <>
      <BuildingLocation
        loading={
          loading || loadingUpdate || loadingCreate || loadingUpdateBuilding
        }
        location={{
          city: building?.location?.city ?? '',
          country: building?.location?.country ?? '',
          no: building?.location?.no ?? '',
          street: building?.location?.street ?? '',
          zip: building?.location?.zip ?? '',
        }}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorUpdate, errorCreate, errorUpdateBuilding]}
      />
    </>
  );
};
