import React, { useEffect, useState } from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UnitDetails } from './UnitDetails';
import { useUnitDetailsQuery } from '../../../__generated__/types';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { entityLocation } from '../../../utils/entity';
import { UnitDeleteContainer } from '../delete/UnitDeleteContainer';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const UnitDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['units', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { setTitle } = useHeader();
  const { hasPermission } = useAuth();
  const { unitId } = params;

  const [deleteMode, setDeleteMode] = useState(false);

  const { data, loading, error } = useUnitDetailsQuery({
    variables: { unitId },
  });

  const unit = data?.unit;
  const hierarchy = unit
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.UNIT,
            id: unit.id,
            name: unit.name || unit.id,
          },
        ],
        t,
      )
    : [];

  useBreadcrumb(hierarchy);

  const handleDeleteCancel = () => setDeleteMode(false);
  const handleDeleteError = () => setDeleteMode(false);
  const handleDelete = () => setDeleteMode(true);
  const handleDeleteComplete = () =>
    navigate(entityLocation(Entities.UNIT, ''));
  const handleEdit = () =>
    navigate(`${entityLocation(Entities.UNIT, unitId)}/edit`);

  useEffect(() => {
    setTitle({ main: unit?.name });
  }, [setTitle, unit]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !unit) {
    return (
      <NotFound
        title={t('units:details.notFound.title') ?? ''}
        subtitle={t('units:details.notFound.subtitle') ?? ''}
        buttonText={t('units:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.UNIT.path)}
      />
    );
  }

  return (
    <>
      <UnitDetails
        loading={loading}
        name={unit?.name ?? ''}
        symbol={unit?.unitSymbol ?? ''}
        uris={unit?.uris ?? []}
        isGlobal={unit?.isGlobal ?? false}
        onDelete={
          hasPermission(Permissions.UnitsWrite) ? handleDelete : undefined
        }
        onEdit={hasPermission(Permissions.UnitsWrite) ? handleEdit : undefined}
      />
      <UnitDeleteContainer
        id={unitId}
        isOpen={deleteMode}
        onCompleted={handleDeleteComplete}
        onCancel={handleDeleteCancel}
        onError={handleDeleteError}
      />
    </>
  );
};
