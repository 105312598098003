import React from 'react';
import { Grid, Button, Tooltip, IconButton } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from '../../../../components/ActionsBar';
import { FancyCard } from '../../../../components/FancyCard';
import { InfoList } from '../../../../components/InfoList';
import { InfoListItem } from '../../../../components/InfoListItem';

type DeviceModelCapabilityDetailsProps = {
  name: string;
  loading: boolean;
  isGlobal: boolean;
  isLocked: boolean;
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
  isUnitless: boolean;
  fieldSelector: string;
  onEdit?: () => void;
  onDelete?: () => void;
};

const classes = createClasses({
  data: {
    fontFamily: 'monospace',
  },
});

export const DeviceModelCapabilityDetails: React.FC<
  DeviceModelCapabilityDetailsProps
> = ({
  name,
  loading,
  isGlobal,
  isLocked,
  technicalMax,
  technicalMin,
  unitSymbol,
  unitName,
  isUnitless,
  fieldSelector,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  const getTooltipTitle = () => {
    if (isGlobal) {
      return t('deviceModels:capabilities.details.globalWarning');
    }
    if (isLocked) {
      return t('deviceModels:capabilities.details.lockedWarning');
    }
    return '';
  };

  return (
    <>
      {!isGlobal && onDelete && (
        <ActionsBar>
          {!loading && (
            <Button color="primary" size="large" onClick={onDelete}>
              {t('deviceModels:capabilities.details.actions.delete')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12}>
              <FancyCard
                title={t('deviceModels:capabilities.details.info.title')}
                actions={
                  onEdit && (
                    <Tooltip
                      arrow
                      placement="top-end"
                      title={getTooltipTitle()}
                      aria-label="edit-tooltip"
                    >
                      <span>
                        <IconButton
                          aria-label="edit"
                          data-testid="card-edit"
                          onClick={onEdit}
                          size="medium"
                          color="primary"
                          disabled={isGlobal || isLocked}
                        >
                          <Icons.Edit fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                }
                fullHeight
                loading={loading}
              >
                <InfoList>
                  <InfoListItem
                    term={t('deviceModels:capabilities.details.info.name')}
                  >
                    {name}
                  </InfoListItem>
                  {unitName && !isUnitless && (
                    <InfoListItem
                      term={t(
                        'deviceModels:capabilities.details.info.unitName',
                      )}
                    >
                      {`${unitName} ( ${unitSymbol} )`}
                    </InfoListItem>
                  )}
                  {fieldSelector && (
                    <InfoListItem
                      term={t(
                        'deviceModels:capabilities.details.info.fieldSelector',
                      )}
                      className={classes.data}
                    >
                      {fieldSelector}
                    </InfoListItem>
                  )}
                  {unitName && !isUnitless && technicalMin !== undefined ? (
                    <InfoListItem
                      term={t(
                        'deviceModels:capabilities.details.info.technicalMin',
                      )}
                      className={classes.data}
                    >
                      {technicalMin}
                    </InfoListItem>
                  ) : null}
                  {unitName && !isUnitless && technicalMax !== undefined ? (
                    <InfoListItem
                      term={t(
                        'deviceModels:capabilities.details.info.technicalMax',
                      )}
                      className={classes.data}
                    >
                      {technicalMax}
                    </InfoListItem>
                  ) : null}
                </InfoList>
              </FancyCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
