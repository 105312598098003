import React, { useCallback } from 'react';
import { createClasses } from '@kp/react-ui';
import {
  Paper,
  Table,
  TableCell as MaterialTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
  TableSortLabel,
} from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { noop } from 'react-use/lib/misc/util';
import { LoadingRow } from './LoadingRow';
import { ErrorRow } from './ErrorRow';
import { TableCell, TableCellProps } from './TableCell';
import { DataTableSearchBox } from './DataTableSearchBox';
import { ErrorMessage } from '../Errors';
import { PAGE_SIZES } from '../../constants/UI';

const classes = createClasses({
  separator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '16px 0',
  },
  searchSeparator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 0',
  },
  loadingRow: {
    opacity: 0.5,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  title: {
    width: '100%',
  },
});

export type DataTableRow = {
  key: string;
  cells: Array<TableCellProps>;
};

export interface DataTableHeader {
  key: string;
  value: React.ReactNode;
  sortable?: boolean;
  align?: 'left' | 'right' | 'center' | 'inherit';
}

export type DataTableProps = {
  headers: DataTableHeader[];
  rows: DataTableRow[];
  showPagination?: boolean;
  showSearch?: boolean;
  onChangePage?: (page: number) => void;
  onChangePageSize?: (size: number) => void;
  onSearchSubmit?: (value: string) => void;
  onSortableClick?: (key: string) => void;
  sort?: string;
  sortDirection?: 'asc' | 'desc';
  searchValue?: string;
  loading?: boolean;
  errorMessage?: string;
  page?: number;
  pageSize?: number;
  count?: number;
  actions?: React.ReactNode;
  title?: string | null;
  info?: React.ReactNode;
};

export const DataTable: React.FC<DataTableProps> = ({
  headers,
  rows,
  showPagination = false,
  showSearch = false,
  searchValue,
  onSearchSubmit,
  sort,
  sortDirection,
  onSortableClick = noop,
  onChangePage = noop,
  onChangePageSize = noop,
  page = 0,
  pageSize = 5,
  count = 5,
  loading = false,
  title,
  info,
  actions,
  errorMessage,
}) => {
  const { t } = useTranslation(['general']);

  const handlePageChange = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      value: number,
    ) => {
      onChangePage(value);
    },
    [onChangePage],
  );

  const handleRowsPerPageChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (event) => {
      onChangePageSize(+event.target.value);
    },
    [onChangePageSize],
  );

  if ((count && page > count / pageSize) || !PAGE_SIZES.includes(pageSize))
    return (
      <ErrorMessage error={new Error(t('general:errors.notFound') ?? '')} />
    );

  return (
    <>
      {(showSearch || actions || title) && (
        <div className={classes.searchSeparator}>
          {showSearch && (
            <DataTableSearchBox
              disabled={loading}
              onSubmit={onSearchSubmit}
              value={searchValue}
            />
          )}
          {title && (
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          )}
          {actions && <div className={classes.actions}>{actions}</div>}
        </div>
      )}
      {info}
      <Paper elevation={1}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <MaterialTableCell key={header.key} align={header.align}>
                    {header.sortable ? (
                      <TableSortLabel
                        active={sort === header.key}
                        direction={sortDirection ?? 'asc'}
                        onClick={() => onSortableClick(header.key)}
                      >
                        {header.value}
                      </TableSortLabel>
                    ) : (
                      header.value
                    )}
                  </MaterialTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && loading ? (
                <LoadingRow span={headers.length} />
              ) : (
                rows.map((row) => (
                  <TableRow
                    key={row.key}
                    className={classNames(loading && classes.loadingRow)}
                  >
                    {row.cells.map(({ key, element, ...props }, index) => (
                      <TableCell
                        key={key}
                        element={element}
                        isFirst={index === 0}
                        isLast={index === headers.length - 1}
                        {...props}
                      />
                    ))}
                  </TableRow>
                ))
              )}
              {errorMessage && (
                <ErrorRow span={headers.length} message={errorMessage} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {showPagination && (
        <div className={classes.separator}>
          <Paper elevation={1}>
            <TablePagination
              component="div"
              rowsPerPageOptions={PAGE_SIZES}
              rowsPerPage={pageSize}
              page={count === 0 ? 0 : page}
              count={count}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </div>
      )}
    </>
  );
};
