import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@mui/material';
import {
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridRenderCellParams,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { DataGrid } from '../../../components/DataGrid';
import { FormattedDate } from '../../../components/FormattedDate';

type DeploymentStatus = 'failed' | 'created' | 'started' | 'deployed';
type DeploymentType = 'online' | 'download';

export type Deployment = {
  id: string;
  type?: DeploymentType;
  mapping?: {
    total?: number;
    new?: number;
    changed?: number;
    removed?: number;
  };
  createdAt: string;
  deployedAt?: string;
  status?: DeploymentStatus;
  errorMessage?: string | null;
};

type DeploymentsListProps = {
  loading?: boolean;
  deployments?: Deployment[];
};

const Toolbar = () => (
  <GridFooterContainer>
    <GridToolbarFilterButton />
    <GridFooter />
  </GridFooterContainer>
);

export const DeploymentsList: React.FC<DeploymentsListProps> = ({
  loading,
  deployments,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const translateDeploymentStatus = (status?: DeploymentStatus | null) => {
    switch (status) {
      case 'created':
        return t('devices:deployments.list.status.created');
      case 'started':
        return t('devices:deployments.list.status.started');
      case 'deployed':
        return t('devices:deployments.list.status.completed');
      case 'failed':
        return t('devices:deployments.list.status.failed');
      default:
        return t('devices:deployments.list.status.unknown');
    }
  };
  const getTypeIcon = (type?: DeploymentType | null) => {
    switch (type) {
      case 'online':
        return (
          <Tooltip
            arrow
            title={t('devices:deployments.list.type.online') ?? ''}
            placement="bottom"
          >
            <Icons.Wifi color="disabled" data-testid="online-icon" />
          </Tooltip>
        );
      case 'download':
        return (
          <Tooltip
            arrow
            title={t('devices:deployments.list.type.download') ?? ''}
            placement="bottom"
          >
            <Icons.Download color="disabled" data-testid="download-icon" />
          </Tooltip>
        );
      default:
        return (
          <Tooltip
            arrow
            title={t('devices:deployments.list.type.unknown') ?? ''}
            placement="bottom"
          >
            <span>-</span>
          </Tooltip>
        );
    }
  };

  const columns: GridColDef<Deployment>[] = [
    {
      field: 'deployedAt',
      headerName: t('devices:deployments.list.headers.deployedAt') ?? '',
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<Deployment, string, Deployment>,
      ) => (
        <FormattedDate
          value={params.row.deployedAt || params.row.createdAt}
          alt={t('devices:deployments.list.unknown') ?? ''}
        />
      ),
    },
    {
      field: 'status',
      headerName: t('devices:deployments.list.headers.status') ?? '',
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<Deployment, DeploymentStatus, Deployment>,
      ) => (
        <Tooltip arrow title={params.row.errorMessage ?? ''} placement="bottom">
          <span>{translateDeploymentStatus(params.row.status)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'type',
      headerName: t('devices:deployments.list.headers.type') ?? '',
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<Deployment, DeploymentType, Deployment>,
      ) => getTypeIcon(params.value),
    },
    {
      field: 'total',
      headerName: t('devices:deployments.list.headers.total') ?? '',
      flex: 1,
    },
    {
      field: 'new',
      headerName: t('devices:deployments.list.headers.new') ?? '',
      flex: 1,
    },
    {
      field: 'changed',
      headerName: t('devices:deployments.list.headers.changed') ?? '',
      flex: 1,
    },
    {
      field: 'removed',
      headerName: t('devices:deployments.list.headers.removed') ?? '',
      flex: 1,
    },
  ];

  const rows =
    deployments?.map((deployment) => ({
      ...deployment,
      total: deployment.mapping?.total,
      new: deployment.mapping?.new,
      changed: deployment.mapping?.changed,
      removed: deployment.mapping?.removed,
    })) ?? [];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <FancyCard
          title={t('devices:deployments.list.title')}
          loading={loading}
        >
          <DataGrid
            loading={loading}
            height={600}
            slots={{
              toolbar: Toolbar,
            }}
            rows={rows}
            columns={columns}
            hideFooter
          />
        </FancyCard>
      </Grid>
    </Grid>
  );
};
