import { ApolloError } from '@apollo/client';
import React from 'react';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { getErrorMessage } from '../../utils/helpers';

const classes = createClasses({
  errorMessage: {
    color: 'white',
    backgroundColor: 'red',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    margin: 50,
  },
});

export type ErrorMessageProps = {
  error?: Error | ApolloError | ApiError;
  message?: string | null;
};
export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  error,
  message,
}) => {
  const { t } = useTranslation(['general']);

  const { message: errorMessage, details: errorDetails } =
    getErrorMessage(error);

  return (
    <div className={classes.errorMessage}>
      <div aria-label="error-message-message">
        {message ?? t('general:errorAlert.message')}
      </div>
      <div aria-label="error-message-error">{errorMessage}</div>
      <div aria-label="error-message-details">{errorDetails}</div>
    </div>
  );
};
