import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import {
  ApiError,
  CommissioningSvcDeviceScan as DeviceScan,
} from '@kp/rest-api-javascript-sdk';
import { useMutation } from '@tanstack/react-query';
import * as API from '../../../api/bacnet';
import { DeviceDiscoveryCard } from './DeviceDiscoveryCard';
import { ErrorAlert } from '../../../components/Alerts';
import { CommissioningStatus } from './CommissioningStatus';
import { ConnectionStates } from '../../../__generated__/types';

export enum UploadStatus {
  completed = 'COMPLETED',
  uploading = 'UPLOADING',
}

type DeviceDiscoveryCardContainerProps = {
  loading?: boolean;
  gatewayId?: string;
  commissioningStatus?: CommissioningStatus;
  gatewayStatus?: ConnectionStates;
  onUpload: (status: UploadStatus) => void;
};

export const DeviceDiscoveryCardContainer: React.FC<
  DeviceDiscoveryCardContainerProps
> = ({ loading, gatewayId, commissioningStatus, gatewayStatus, onUpload }) => {
  const { t } = useTranslation(['general']);
  const { hasPermission } = useAuth();
  const [deviceScan, setDeviceScan] = useState<DeviceScan>();
  const prevCommissioningStatus = useRef(commissioningStatus);

  const {
    mutate: callGetDeviceScan,
    isLoading: loadingDeviceScan,
    error: errorDeviceScan,
  } = useMutation({
    mutationFn: API.getDeviceScan,
    onSuccess: (result) => {
      setDeviceScan(result.data);
    },
    onError: (err: ApiError) => err,
  });

  useEffect(() => {
    setDeviceScan(undefined);
    if (gatewayId) {
      callGetDeviceScan(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  useEffect(() => {
    if (gatewayId && prevCommissioningStatus.current === 'scanning_devices') {
      callGetDeviceScan(gatewayId);
    }
    prevCommissioningStatus.current = commissioningStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissioningStatus]);

  const {
    mutate: callStartDeviceScan,
    isLoading: loadingStartDeviceScan,
    error: errorStartDeviceScan,
  } = useMutation({
    mutationFn: API.startDeviceScan,
    onSuccess: () => {
      if (gatewayId) {
        callGetDeviceScan(gatewayId);
      }
    },
    onError: (err: ApiError) => err,
  });
  const {
    mutate: callUploadDeviceScan,
    isLoading: loadingUploadDeviceScan,
    error: errorUploadDeviceScan,
  } = useMutation({
    mutationFn: API.uploadDeviceScan,
    onSuccess: () => {
      onUpload(UploadStatus.completed);
      if (gatewayId) {
        callGetDeviceScan(gatewayId);
      }
    },
    onError: (err: ApiError) => err,
  });

  const handleStartScan = (settings: API.DeviceDiscoverySettings) => {
    if (!gatewayId) return null;
    return callStartDeviceScan({ gatewayId, settings });
  };

  const handleSelectFile = async (content: string) => {
    if (!gatewayId) return;
    onUpload(UploadStatus.uploading);
    callUploadDeviceScan({ gatewayId, xml: content });
  };

  return (
    <>
      <DeviceDiscoveryCard
        loading={
          loading ||
          loadingDeviceScan ||
          loadingUploadDeviceScan ||
          loadingStartDeviceScan
        }
        deviceScan={deviceScan}
        commissioningStatus={commissioningStatus}
        gatewayStatus={gatewayStatus}
        onStartScan={
          hasPermission(Permissions.CommissioningWrite)
            ? handleStartScan
            : undefined
        }
        onSelectFile={
          hasPermission(Permissions.CommissioningWrite)
            ? handleSelectFile
            : undefined
        }
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorDeviceScan?.status !== 404 ? errorDeviceScan : undefined,
          errorUploadDeviceScan,
          errorStartDeviceScan,
        ]}
      />
    </>
  );
};
