import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Link } from '../../../components/Link';
import { DataTable } from '../../../components/DataTable';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { EmptyBanner } from '../../../components/EmptyBanner';

type Zone = {
  id: string;
  name: string;
};
export interface StoreyDetailsZonesProps {
  zones: Array<Zone>;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onAdd?: () => void;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
}

export const StoreyDetailsZones: React.FC<StoreyDetailsZonesProps> = ({
  zones,
  onChangePageSize,
  onChangePage,
  onAdd,
  page,
  pageSize,
  count,
  loading,
}) => {
  const { t } = useTranslation(['storeys', 'general']);

  const headers = [
    {
      key: t('storeys:zones.headers.name'),
      value: t('storeys:zones.headers.name'),
    },
  ];

  const rows = zones.map((zone) => ({
    key: `${zone.id}`,
    cells: [
      {
        key: `${zone.id}-cell-name`,
        element: (
          <Link to={entityLocation(Entities.ZONE, zone.id)}>{zone.name}</Link>
        ),
      },
    ],
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        {!loading && (!zones || !zones.length) ? (
          <EmptyBanner
            description={t('storeys:zones.nodata')}
            actionLabel={t('storeys:zones.addZone')}
            onClickAction={onAdd}
          />
        ) : (
          <DataTable
            actions={
              onAdd && (
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  startIcon={<Icons.Add />}
                  onClick={onAdd}
                >
                  {t('storeys:zones.addZone')}
                </Button>
              )
            }
            title={t('storeys:zones.title')}
            loading={loading}
            headers={headers}
            rows={rows}
            showPagination
            page={page}
            pageSize={pageSize}
            count={count}
            onChangePage={onChangePage}
            onChangePageSize={onChangePageSize}
          />
        )}
      </Grid>
    </Grid>
  );
};
