import React from 'react';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { makeOptional } from './helpers';

const classes = createClasses({
  field: {
    minHeight: '70px',
  },
});

export type TextFieldProps = Omit<MuiTextFieldProps, 'name'> & {
  name: string;
  optional?: boolean;
};

export const TextField: React.FC<TextFieldProps> = ({ optional, ...props }) => {
  const { t } = useTranslation(['general']);
  const { control } = useFormContext();
  const { name, label, className, InputLabelProps, type = 'text' } = props;
  const optionalLabel = makeOptional(t, label);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <MuiTextField
            id={name}
            fullWidth
            {...field}
            {...props}
            type={type}
            InputLabelProps={{ ...InputLabelProps, shrink: true }}
            className={classNames(className, classes.field)}
            label={optional ? optionalLabel : label}
            error={!!error}
            helperText={error ? error.message : null}
          />
        );
      }}
    />
  );
};
