import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import {
  Link as MaterialLink,
  LinkProps as MaterialLinkProps,
} from '@mui/material';

export type LinkProps = Omit<NavLinkProps, 'children' | 'className' | 'style'> &
  Pick<MaterialLinkProps, 'children' | 'className' | 'style'> & {
    color?:
      | 'initial'
      | 'inherit'
      | 'primary'
      | 'secondary'
      | 'textPrimary'
      | 'textSecondary'
      | 'error';
  };

export const Link: React.FC<LinkProps> = (props) => (
  <MaterialLink component={NavLink} {...props} />
);
