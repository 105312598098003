import React from 'react';
import { createClasses } from '@kp/react-ui';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';

const classes = createClasses({
  loading: {
    margin: 50,
  },
});

type LoadingProps = {
  className?: string;
  size?: number;
};
export const Loading: React.FC<LoadingProps> = ({ className, size }) => {
  return (
    <CircularProgress
      size={size}
      className={classNames(classes.loading, className)}
    />
  );
};
