import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { v4 as uuid } from 'uuid';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../../components/ActionsBar';
import {
  GatewayFormData,
  GatewayFormInfo,
  getGatewayFormInfoSchema,
} from '../../common/GatewayFormInfo';
import {
  DeviceFormLocationProps,
  DeviceFormLocation,
  DeviceFormLocationData,
  getDeviceFormLocationSchema,
} from '../../common/DeviceFormLocation';
import {
  DeviceCreateAttributes,
  AttributeSet,
  getDeviceCreateAttributesSchema,
} from '../../common/DeviceCreateAttributes';
import { PlacementKind } from '../../../../__generated__/types';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  section: {
    '& + &': {
      marginTop: '24px',
    },
  },
});

export type ValidationState = 'LOADING' | 'ERROR' | 'VALID' | 'INVALID';

export type GatewayCreateData = GatewayFormData &
  DeviceFormLocationData & { attributeSets: AttributeSet[] };

interface GatewayCreateProps {
  allSites: DeviceFormLocationProps['sites'];
  allDeviceModels: Array<{
    id: string;
    name: string;
    isGateway: boolean;
    deviceModelAttributeSets: AttributeSet[];
  }>;
  placementId?: string;
  placementType?: PlacementKind;
  loading?: boolean;
  onSubmit: (device: GatewayCreateData) => void;
  onDiscard: () => void;
  validateDeviceIdentifier: (deviceIdentifier: string) => void;
  deviceIdentifierValidationState: ValidationState;
}

export const GatewayCreate: React.FC<GatewayCreateProps> = ({
  allSites,
  allDeviceModels,
  placementId,
  placementType,
  loading,
  onSubmit,
  onDiscard,
  validateDeviceIdentifier,
  deviceIdentifierValidationState,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const validationSchema = getGatewayFormInfoSchema(
    t,
    deviceIdentifierValidationState !== 'INVALID',
  )
    .concat(getDeviceFormLocationSchema(t))
    .concat(getDeviceCreateAttributesSchema(t));

  const deviceIdentifier = useMemo(uuid, []);

  const defaultValues: GatewayCreateData = {
    name: '',
    description: '',
    serialNo: '',
    deviceIdentifier,
    deviceModelId: '0',
    locationId: placementId ?? '0',
    locationType: placementType ?? PlacementKind.Zone,
    attributeSets: [] as AttributeSet[],
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;
  const attributeSets = watch('attributeSets');

  const handleModelSelection = (value: string) => {
    const selectedModel = allDeviceModels.find((dm) => dm.id === value);
    const selectedAttributeSets = selectedModel?.deviceModelAttributeSets;
    setValue('deviceModelId', value);
    setValue(
      'attributeSets',
      (selectedAttributeSets ?? []).map((attributeSet) => ({
        ...attributeSet,
        attributes: attributeSet.attributes.map((attribute) => ({
          ...attribute,
          enabled: attribute.required,
        })),
      })),
      { shouldTouch: false },
    );
    clearErrors('attributeSets');
  };

  const handleDeviceIdentifierValidation = (newDeviceIdentifier: string) => {
    if (newDeviceIdentifier !== undefined) {
      validateDeviceIdentifier(newDeviceIdentifier);
    }
  };

  useEffect(() => {
    if (
      errors.deviceIdentifier?.message === t('devices:form.notUniqueError') &&
      deviceIdentifierValidationState !== 'INVALID'
    ) {
      clearErrors('deviceIdentifier');
    } else if (
      !errors.deviceIdentifier &&
      deviceIdentifierValidationState === 'INVALID'
    ) {
      setError('deviceIdentifier', {
        message: t('devices:form.notUniqueError') ?? '',
      });
    }
  }, [
    deviceIdentifierValidationState,
    errors.deviceIdentifier,
    setError,
    clearErrors,
    t,
  ]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <div className={classes.section}>
        <Grid container spacing={3} alignItems="stretch" direction="row">
          <Grid item xs={12} sm={12} md={8}>
            <GatewayFormInfo
              loading={loading}
              onSelectModel={handleModelSelection}
              allDeviceModels={allDeviceModels}
              validateDeviceIdentifier={handleDeviceIdentifierValidation}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <DeviceFormLocation
              type="create"
              sites={allSites}
              loading={loading}
            />
          </Grid>
        </Grid>
      </div>
      {attributeSets && attributeSets?.length > 0 && (
        <div className={classes.section}>
          <DeviceCreateAttributes
            attributeSets={attributeSets}
            loading={loading}
          />
        </div>
      )}
    </FormProvider>
  );
};
