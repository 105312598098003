import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  AddressForm,
  AddressFormData,
  getRequiredAddressSchema,
} from '../../../components/Address';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type TenantLocationData = AddressFormData;

type TenantLocationProps = TenantLocationData & {
  loading: boolean;
  onSubmit: (location: TenantLocationData) => void;
  onDiscard: () => void;
};

export const TenantLocation: React.FC<TenantLocationProps> = ({
  loading,
  location,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['tenants', 'general']);

  const validationSchema = getRequiredAddressSchema(t);
  const defaultValues: TenantLocationData = { location };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useDeepCompareEffect(() => {
    reset({
      location,
    });
  }, [reset, location]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <AddressForm title={t('tenants:form.address')} loading={loading} />
    </FormProvider>
  );
};
