import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../../components/ActionsBar';
import { FancyCard } from '../../../../components/FancyCard';
import { DataTypes } from '../../../../__generated__/types';
import {
  AutocompleteField,
  FieldWithTooltip,
  TextField,
} from '../../../../components/Fields';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  field: {
    minHeight: '70px',
  },
  selector: {
    fontFamily: 'monospace',
  },
});

export type DeviceModelCapabilityData = {
  capabilityId: string;
  technicalMax: string | null;
  technicalMin: string | null;
  unitId: string;
  fieldSelector?: string;
};

type DeviceModelCapabilityFormProps = DeviceModelCapabilityData & {
  allUnits: Array<{ id: string; unitName: string; unitSymbol?: string }>;
  allCapabilities: Array<{
    id: string;
    name: string;
    dataType: DataTypes;
  }>;
  existingFieldSelectors: string[];
  loading?: boolean;
  onSubmit: (deviceModel: DeviceModelCapabilityData) => void;
  onDiscard: () => void;
};

export const DeviceModelCapabilityForm: React.FC<
  DeviceModelCapabilityFormProps
> = ({
  capabilityId,
  technicalMax,
  technicalMin,
  unitId,
  fieldSelector,
  allUnits,
  allCapabilities,
  existingFieldSelectors,
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  const unitOptions = useMemo(
    () =>
      allUnits
        .map((unit) => ({
          id: unit.id,
          label: `${unit.unitName ?? ''} ( ${unit.unitSymbol ?? ''} )`,
        }))
        .concat([
          {
            id: '0',
            label: t('general:forms.pleaseChoose'),
          },
        ]),
    [allUnits, t],
  );

  const capabilityOptions = useMemo(
    () =>
      allCapabilities
        .map(({ id, name: label }) => ({
          id,
          label,
        }))
        .concat([
          {
            id: '0',
            label: t('general:forms.pleaseChoose'),
          },
        ]),
    [allCapabilities, t],
  );

  const isBooleanCapability = (id: string) => {
    const capability = allCapabilities?.find((capa) => capa.id === id);
    return capability?.dataType === DataTypes.Bool;
  };

  const validationSchema = Yup.object().shape({
    capabilityId: Yup.string()
      .required(t('general:errors.required') ?? '')
      .notOneOf(['0'], t('deviceModels:capabilities.form.selectError') ?? ''),
    unitId: Yup.string().when('capabilityId', ([capaId]) =>
      !isBooleanCapability(capaId)
        ? Yup.string()
            .required(t('general:errors.required') ?? '')
            .notOneOf(
              ['0'],
              t('deviceModels:capabilities.form.selectError') ?? '',
            )
        : Yup.string().optional(),
    ),
    fieldSelector: Yup.string()
      .required(t('general:errors.required') ?? '')
      .notOneOf(
        existingFieldSelectors,
        t('deviceModels:capabilities.form.uniqueFieldSelectorError') ?? '',
      )
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? ''),
    technicalMax: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.number().when('capabilityId', ([capaId]) =>
            !isBooleanCapability(capaId)
              ? Yup.number()
                  .typeError(
                    t('deviceModels:capabilities.form.formatError') ?? '',
                  )
                  .nullable()
                  .min(
                    Yup.ref('technicalMin'),
                    t('deviceModels:capabilities.form.maxMoreThanMin') ?? '',
                  )
              : Yup.number().optional(),
          ),
    ),
    technicalMin: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.number().when('capabilityId', ([capaId]) =>
            !isBooleanCapability(capaId)
              ? Yup.number()
                  .typeError(
                    t('deviceModels:capabilities.form.formatError') ?? '',
                  )
                  .nullable()
              : Yup.number().optional(),
          ),
    ),
  });

  const defaultValues: DeviceModelCapabilityData = {
    capabilityId,
    technicalMax,
    technicalMin,
    unitId,
    fieldSelector,
  };
  const methods = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset, watch } = methods;
  useEffect(() => {
    reset({
      capabilityId,
      technicalMax,
      technicalMin,
      unitId,
      fieldSelector,
    });
  }, [reset, capabilityId, technicalMax, technicalMin, unitId, fieldSelector]);
  const capabilityIdValue = watch('capabilityId');
  const isBoolean = isBooleanCapability(capabilityIdValue);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="stretch" direction="row">
          <Grid item xs={12}>
            <FancyCard
              title={t('deviceModels:capabilities.details.info.title')}
              loading={loading}
            >
              <Grid
                container
                spacing={1}
                className={classes.cardContent}
                direction="column"
              >
                <Grid item xs={12} md={4}>
                  <AutocompleteField
                    disabled={loading}
                    id="capability-select"
                    name="capabilityId"
                    className={classes.field}
                    options={capabilityOptions}
                    data-testid="capaauto"
                    fullWidth
                    label={
                      t('deviceModels:capabilities.details.info.name') ?? ''
                    }
                  />
                </Grid>
                {!isBoolean && (
                  <Grid item xs={12} md={4}>
                    <AutocompleteField
                      disabled={loading}
                      id="unit-select"
                      name="unitId"
                      className={classes.field}
                      options={unitOptions}
                      fullWidth
                      label={
                        t('deviceModels:capabilities.details.info.unitName') ??
                        ''
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <FieldWithTooltip
                    disabled={loading}
                    id="fieldSelector"
                    name="fieldSelector"
                    label={t(
                      'deviceModels:capabilities.details.info.fieldSelector',
                    )}
                    innerComponent={TextField}
                    InputProps={{ className: classes.selector }}
                    tooltip={{
                      content: t(
                        'deviceModels:capabilities.form.infoTooltip.fieldSelector',
                      ),
                    }}
                  />
                </Grid>
                {!isBoolean && (
                  <>
                    <Grid item xs={12} md={4}>
                      <FieldWithTooltip
                        disabled={loading}
                        id="technicalMin"
                        name="technicalMin"
                        label={t(
                          'deviceModels:capabilities.details.info.technicalMin',
                        )}
                        optional
                        innerComponent={TextField}
                        InputProps={{ className: classes.selector }}
                        tooltip={{
                          content: t(
                            'deviceModels:capabilities.form.infoTooltip.technicalMin',
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FieldWithTooltip
                        disabled={loading}
                        id="technicalMax"
                        name="technicalMax"
                        label={t(
                          'deviceModels:capabilities.details.info.technicalMax',
                        )}
                        optional
                        innerComponent={TextField}
                        InputProps={{ className: classes.selector }}
                        tooltip={{
                          content: t(
                            'deviceModels:capabilities.form.infoTooltip.technicalMax',
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </FancyCard>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
