import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { TFunction } from 'i18next';
import { FancyCard } from '../../../components/FancyCard';
import {
  CheckboxField,
  FieldWithTooltip,
  TextField,
} from '../../../components/Fields';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  field: {
    minHeight: '70px',
  },
});

export type TenantFormInfoData = {
  name: string;
  slug: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  inviteContactToTenant?: boolean;
};
interface TenantFormInfoProps {
  loading?: boolean;
  type?: 'edit' | 'create';
  validateSlug?: (slug: string) => Promise<string | null | undefined>;
}

export const getTenantFormInfoSchema = (
  t: TFunction,
  validateSlug?: (slug: string) => Promise<string | null | undefined>,
): Yup.ObjectSchema<TenantFormInfoData> =>
  Yup.object().shape({
    name: Yup.string().required(t('general:errors.required') ?? ''),
    slug: Yup.string()
      .required(t('general:errors.required') ?? '')
      .test(
        'isSlugValid',
        'isSlugValidMessage',
        async (value, { createError }) => {
          if (!value || !validateSlug) return true;
          const result = await validateSlug(value);
          return result
            ? createError({
                path: 'slug',
                message: result,
              })
            : true;
        },
      ),
    contactEmail: Yup.string()
      .required(t('general:errors.required') ?? '')
      .asciiEmail(t('general:validation.email')),
    contactFirstName: Yup.string().required(t('general:errors.required') ?? ''),
    contactLastName: Yup.string().required(t('general:errors.required') ?? ''),
    inviteContactToTenant: Yup.boolean().optional(),
  });

export const TenantFormInfo: React.FC<TenantFormInfoProps> = ({
  loading,
  type,
}) => {
  const { t } = useTranslation(['tenants', 'general']);

  return (
    <FancyCard title={t('tenants:details.info.title')} loading={loading}>
      <Grid
        container
        spacing={1}
        direction="column"
        className={classes.cardContent}
      >
        <Grid item xs={12} md={8}>
          <TextField
            disabled={loading}
            id="name"
            name="name"
            label={t('tenants:details.info.name')}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FieldWithTooltip
            id="slug"
            name="slug"
            label={t('tenants:details.info.slug')}
            disabled={type === 'edit' || loading}
            innerComponent={TextField}
            tooltip={{
              content: t('tenants:form.slug.helperText'),
              type: 'warning',
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            disabled={loading}
            id="contactFirstName"
            name="contactFirstName"
            label={t('tenants:details.info.firstName')}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            disabled={loading}
            id="contactLastName"
            name="contactLastName"
            label={t('tenants:details.info.lastName')}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            disabled={loading}
            id="contactEmail"
            name="contactEmail"
            label={t('tenants:details.info.email')}
          />
        </Grid>
        {type === 'create' && (
          <Grid item xs={12} md={8}>
            <FieldWithTooltip
              disabled={loading}
              id="inviteContactToTenant"
              data-testid="inviteContactToTenant"
              name="inviteContactToTenant"
              type="checkbox"
              innerComponent={CheckboxField}
              Label={{
                label: t('tenants:details.info.inviteContactToTenant'),
              }}
              tooltip={{
                content: t('tenants:details.info.inviteContactToTenantTooltip'),
                type: 'info',
              }}
            />
          </Grid>
        )}
      </Grid>
    </FancyCard>
  );
};
