import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import {
  GridColDef,
  GridFooter,
  GridRowModel,
  GridFooterContainer,
  GridToolbarFilterButton,
  GridValueFormatterParams,
  GridRowSelectionModel,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import { DataGrid } from '../../../components/DataGrid';
import { ScanStatus, translateScanStatus } from '../common/statusTranslation';
import { FormattedDate } from '../../../components/FormattedDate';
import { translateBacnetId } from '../../../utils/bacnet-device-types';

export type Device = {
  id: string;
  scan?: null | {
    status: ScanStatus;
    startedAt?: string | null;
    finishedAt?: string | null;
    numOfObjects?: number | null;
    error?: string | null;
  };
  details?: {
    objectIdentifier: string;
    objectName: string;
    vendorName?: string;
    modelName?: string;
    firmwareRevision?: string;
    applicationSoftwareVersion?: string;
    location?: string;
    description?: string;
  };
};

export type ObjectScanTableProps = {
  loading?: boolean;
  devices?: Device[];
  onSelect: (deviceIds: string[]) => void;
  onDelete?: (deviceId: string) => void;
};

const columnDetails: Partial<GridColDef> = {
  flex: 1,
};

const Toolbar = () => (
  <GridFooterContainer>
    <GridToolbarFilterButton />
    <GridFooter />
  </GridFooterContainer>
);

export const ObjectScanTable: React.FC<ObjectScanTableProps> = ({
  loading,
  devices,
  onSelect,
  onDelete,
}) => {
  const { t } = useTranslation(['devices', 'general']);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );

  const handleSelection = (selection: GridRowSelectionModel) => {
    setSelectionModel(selection);
    const deviceIds = selection.map((d) => d.toString());
    onSelect(deviceIds);
  };

  const handleDelete =
    (deviceId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (onDelete) onDelete(deviceId);
    };

  const columns = [
    {
      field: 'objectName',
      headerName: t('devices:discovery.objectScanTable.headers.device'),
      ...columnDetails,
    },
    {
      field: 'objectIdentifier',
      headerName: t('devices:discovery.objectScanTable.headers.deviceId'),
      ...columnDetails,
    },
    {
      field: 'modelName',
      headerName: t('devices:discovery.objectScanTable.headers.modelName'),
      ...columnDetails,
    },
    {
      field: 'description',
      headerName: t('devices:discovery.objectScanTable.headers.description'),
      ...columnDetails,
    },
    {
      field: 'startedAt',
      headerName: t('devices:discovery.objectScanTable.headers.scanStartedAt'),
      ...columnDetails,
      type: 'string',
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <FormattedDate value={String(params.value)} alt={'-'} />
        ) : (
          '-'
        ),
    },
    {
      field: 'finishedAt',
      headerName: t('devices:discovery.objectScanTable.headers.scanFinishedAt'),
      ...columnDetails,
      type: 'string',
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <FormattedDate value={String(params.value)} alt={'-'} />
        ) : (
          '-'
        ),
    },
    {
      field: 'status',
      headerName: t('devices:discovery.objectScanTable.headers.scanStatus'),
      ...columnDetails,
      valueFormatter: (params: GridValueFormatterParams) =>
        translateScanStatus(
          params.value ? (params.value as ScanStatus) : undefined,
          t,
        ),
    },
    {
      field: 'numOfObjects',
      headerName: t('devices:discovery.objectScanTable.headers.numOfObjects'),
      ...columnDetails,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => {
        if (!params.id) return params.value;
        const status = params.api.getCellValue(params.id, 'status');
        const isCompleted = status === 'completed';
        return isCompleted ? params.value : '-';
      },
    },
    ...(onDelete
      ? [
          {
            field: 'delete',
            headerName: '',
            renderCell: (params: GridRenderCellParams) => {
              const isCompleted = params.row.status === 'completed';
              if (!isCompleted) return null;
              return (
                <IconButton
                  aria-label="delete-button"
                  data-testid="delete-button"
                  onClick={handleDelete(params.row.deviceId)}
                >
                  <Icons.Delete fontSize="small" />
                </IconButton>
              );
            },
            width: 50,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            disableReorder: true,
            resizable: false,
          },
        ]
      : []),
  ];
  const rows = useMemo(
    () =>
      loading || !devices
        ? []
        : devices.map(
            (device) =>
              ({
                ...device.details,
                ...(device.details
                  ? {
                      objectIdentifier: translateBacnetId(
                        device.details.objectIdentifier,
                      ),
                    }
                  : {}),
                ...(device.scan ?? {}),
                id: device.id,
              } as GridRowModel),
          ),
    [devices, loading],
  );

  return (
    <DataGrid
      loading={loading}
      slots={{
        toolbar: Toolbar,
      }}
      rows={rows}
      columns={columns}
      onRowSelectionModelChange={handleSelection}
      rowSelectionModel={selectionModel}
      hideFooter
      checkboxSelection
    />
  );
};
