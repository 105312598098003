import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { ConfirmAlert } from '../../../components/Alerts';

const classes = createClasses({
  option: {
    paddingTop: 0,
    alignSelf: 'flex-start',
  },
});

interface RegisteredApplicationEditConfirmDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const RegisteredApplicationEditConfirmDialog: React.FC<
  RegisteredApplicationEditConfirmDialogProps
> = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation(['registeredApplications', 'general']);

  const [warningConfirmed, setWarningConfirmed] = useState(false);

  const handleConfirmWarning = (value: boolean) => {
    setWarningConfirmed(value);
  };

  const handleConfirm = () => {
    if (warningConfirmed) onConfirm();
  };

  return (
    <ConfirmAlert
      open={open}
      onClose={onCancel}
      onConfirm={handleConfirm}
      title={t('registeredApplications:edit.confirm.title')}
      message={t('registeredApplications:edit.confirm.message')}
      options={{ confirmDisabled: !warningConfirmed }}
      cancelButton={t('general:buttons.cancel')}
      confirmButton={t('general:buttons.ok')}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={warningConfirmed}
            color="primary"
            onChange={(event) => handleConfirmWarning(event.target.checked)}
            className={classes.option}
          />
        }
        label={
          <Typography variant="body1" color="textSecondary">
            {t('registeredApplications:edit.confirm.warning')}
          </Typography>
        }
      />
    </ConfirmAlert>
  );
};
