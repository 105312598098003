import React from 'react';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useNavigate, useParams } from 'react-router';
import { ErrorMessage } from '../../../components/Errors';
import { useFilters } from '../../../hooks/filters';
import { useZoneDetailsDevicesQuery } from '../../../__generated__/types';
import { ZoneDetailsDevices } from './ZoneDetailsDevices';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';

export const ZoneDetailsDevicesContainer: React.FC = () => {
  const { page, pageSize, setPage, setPageSize } = useFilters();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();
  const { zoneId } = useParams();

  const { loading, error, data } = useZoneDetailsDevicesQuery({
    variables: {
      take: pageSize,
      skip: page * pageSize,
      where: {
        zoneId: { eq: zoneId },
      },
    },
  });

  const handleAdd = (type: 'gateway' | 'fielddevice') => {
    navigate(
      entityLocation(
        Entities.DEVICE,
        `new-${type}?placementId=${zoneId}&placementType=ZONE`,
      ),
    );
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const devices = (data?.devicesByBuildings?.items ?? []).map(
    (deviceByBuilding) => deviceByBuilding?.device,
  );
  const count = data?.devicesByBuildings?.totalCount ?? 0;

  return (
    <ZoneDetailsDevices
      onAdd={hasPermission(Permissions.DevicesWrite) ? handleAdd : undefined}
      loading={loading}
      page={page}
      pageSize={pageSize}
      count={count}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      devices={devices}
    />
  );
};
