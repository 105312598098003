import React from 'react';
import { ApolloError } from '@apollo/client';
import { UnitDelete } from './UnitDelete';
import { useUnitDeleteMutation } from '../../../__generated__/types';

interface UnitDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const UnitDeleteContainer: React.FC<UnitDeleteContainerProps> = ({
  id,
  isOpen,
  onCompleted,
  onError,
  onCancel,
}) => {
  const [deleteUnit, { loading, error }] = useUnitDeleteMutation({
    onCompleted,
    onError,
  });

  const handleConfirm = () =>
    deleteUnit({
      variables: { unitId: id },
    });

  return (
    <UnitDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
