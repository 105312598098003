import React from 'react';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { useTranslation } from 'react-i18next';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

const getDateLocale = (locale: string) => {
  switch (locale) {
    case 'en':
    case 'en-US':
      return enLocale;
    case 'de':
    case 'de-DE':
      return deLocale;
    default:
      return enLocale;
  }
};

type PickersProviderProps = {
  children: React.ReactNode;
};
export const PickersProvider: React.FC<PickersProviderProps> = ({
  children,
}) => {
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getDateLocale(i18n.language)}
    >
      {children}
    </LocalizationProvider>
  );
};
