import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Switch } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { DataTypes } from '../../__generated__/types';

const classes = createClasses({
  root: {
    fontSize: '16px',
    margin: '0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface DataTypeValueProps {
  dataType: DataTypes | null;
  value: string;
  className?: string;
}

const formatValue = (
  value: string,
  dataType: DataTypes | null,
): React.ReactNode => {
  switch (dataType) {
    case DataTypes.Date:
      return value ? format(new Date(value), 'dd-MM-yyyy') : value;
    case DataTypes.Datetime:
      return value ? format(new Date(value), 'dd-MM-yyyy HH:mm') : value;
    case DataTypes.Bool:
      return (
        <Switch
          disabled
          checked={value === 'true'}
          size="small"
          data-testid={`boolean-attribute-${value}`}
        />
      );
    case DataTypes.String:
    case DataTypes.Integer:
    case DataTypes.Decimal:
    case DataTypes.Double:
    case DataTypes.Float:
    default:
      return value;
  }
};

export const DataTypeValue: React.FC<DataTypeValueProps> = ({
  dataType,
  value,
  className,
}) => {
  return (
    <pre className={classNames(classes.root, className)}>
      {formatValue(value, dataType)}
    </pre>
  );
};
