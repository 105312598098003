import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import {
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '../../../../components/DataGrid';
import { FancyCard } from '../../../../components/FancyCard';
import { translateBacnetId } from '../../../../utils/bacnet-device-types';
import { DeployAlert } from '../../common/DeployAlert';

const classes = createClasses({
  deviceCard: {
    marginTop: 8,
    marginBottom: 8,
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
  capabilityEntry: {
    display: 'flex',
    flexDirection: 'row',
  },
  emptyMessage: {
    margin: 8,
  },
});

const Toolbar = () => (
  <GridFooterContainer>
    <GridToolbarFilterButton />
    <GridFooter />
  </GridFooterContainer>
);

type ReviewData = Array<{
  deviceName: string;
  deviceModelName: string;
  capabilityName: string;
  bacnetDeviceId: string;
  bacnetObjectId: string;
}>;

export type DeviceMappingsDraftReviewProps = {
  loading: boolean;
  reviewData: ReviewData;
  onDeploy?: () => void;
  onDownload?: () => void;
};

export const DeviceMappingsDraftReview: React.FC<
  DeviceMappingsDraftReviewProps
> = ({ loading, reviewData, onDeploy, onDownload }) => {
  const { t } = useTranslation(['devices', 'general']);

  const [shouldConfirm, setShouldConfirm] = useState(false);

  const confirmDeploy = () => setShouldConfirm(true);
  const handleCancel = () => setShouldConfirm(false);
  const handleDeploy = () => {
    setShouldConfirm(false);
    if (onDeploy) onDeploy();
  };
  const handleDownload = () => {
    setShouldConfirm(false);
    if (onDownload) onDownload();
  };

  const columns: GridColDef[] = [
    {
      field: 'deviceName',
      headerName: t('devices:mappings.review.headers.deviceName') ?? '',
      flex: 1,
    },
    {
      field: 'deviceModelName',
      headerName: t('devices:mappings.review.headers.deviceModelName') ?? '',
      flex: 1,
    },
    {
      field: 'capabilityName',
      headerName: t('devices:mappings.review.headers.capabilityName') ?? '',
      flex: 1,
    },
    {
      field: 'bacnetDeviceId',
      headerName: t('devices:mappings.review.headers.bacnetDeviceId') ?? '',
      flex: 1,
    },
    {
      field: 'bacnetObjectId',
      headerName: t('devices:mappings.review.headers.bacnetObjectId') ?? '',
      flex: 1,
    },
  ];

  const rows = useMemo(
    () =>
      reviewData.map((data) => ({
        id: data.bacnetDeviceId + data.bacnetObjectId,
        ...data,
        bacnetObjectId: translateBacnetId(data.bacnetObjectId),
        bacnetDeviceId: translateBacnetId(data.bacnetDeviceId),
      })),
    [reviewData],
  );

  const hasDraft = !!reviewData?.length;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FancyCard
            title={t('devices:mappings.review.mappings')}
            loading={loading}
            className={classes.deviceCard}
          >
            <DataGrid
              loading={loading}
              height={600}
              slots={{
                toolbar: Toolbar,
              }}
              rows={rows}
              columns={columns}
              hideFooter
            />
          </FancyCard>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Button
            onClick={confirmDeploy}
            disabled={loading || !hasDraft || (!onDeploy && !onDownload)}
            color="primary"
            variant="contained"
          >
            {t('devices:mappings.review.deployButton')}
          </Button>
        </Grid>
      </Grid>
      <DeployAlert
        open={shouldConfirm}
        onCancel={handleCancel}
        onDownload={onDownload ? handleDownload : undefined}
        onDeploy={onDeploy ? handleDeploy : undefined}
      />
    </>
  );
};
