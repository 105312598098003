import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import { entityLocation } from '../../../../utils/entity';
import { ErrorMessage } from '../../../../components/Errors';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import {
  useDeviceModelAttributeSetEditDetailsQuery,
  useDeviceModelAttributeSetUpdateMutation,
} from '../../../../__generated__/types';
import {
  DeviceModelAttributeSetForm,
  DeviceModelAttributeSetData,
} from '../common/DeviceModelAttributeSetForm';
import { useHeader } from '../../../../contexts/header-context';
import { NotFound } from '../../../errorScreens';

export const DeviceModelAttributeSetEditContainer: React.FC = () => {
  const { t } = useTranslation(['deviceModels', 'general']);
  const navigate = useNavigate();
  const params = useParams();
  const { deviceModelAttributeSetId, deviceModelId } = params;
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useDeviceModelAttributeSetEditDetailsQuery({
    variables: { deviceModelAttributeSetId },
  });

  const deviceModelAttributeSet = data?.deviceModelAttributeSet;
  const isDeviceModelGlobal =
    deviceModelAttributeSet?.deviceModel?.isGlobal ?? false;

  const hierarchy = deviceModelAttributeSet
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICEMODEL,
            id: deviceModelId,
            name: deviceModelAttributeSet.deviceModel?.name || deviceModelId,
          },
        ],
        t,
      ).concat([
        {
          title: t('deviceModels:attributeSet.breadcrumb.title'),
          location: '',
        },
        {
          title: deviceModelAttributeSet?.name || deviceModelAttributeSetId,
          location: entityLocation(
            Entities.DEVICEMODEL,
            `${deviceModelId}/attribute-sets/${deviceModelAttributeSetId}`,
          ),
        },
        {
          title: t('deviceModels:attributeSet.breadcrumb.edit'),
          location: '',
        },
      ])
    : [];
  useBreadcrumb(hierarchy);

  const [
    updateDeviceModelAttributeSet,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useDeviceModelAttributeSetUpdateMutation({
    onCompleted: () => {
      navigate(
        entityLocation(
          Entities.DEVICEMODEL,
          `${deviceModelId}/attribute-sets/${deviceModelAttributeSetId}`,
        ),
      );
    },
    onError: (e) => console.warn(e),
  });

  const handleSubmit = async (inputData: DeviceModelAttributeSetData) => {
    return updateDeviceModelAttributeSet({
      variables: {
        deviceModelAttributeSetInput: {
          id: deviceModelAttributeSetId,
          name: inputData.name,
          deviceModelId,
        },
      },
    });
  };

  const handleDiscard = () => {
    navigate(
      entityLocation(
        Entities.DEVICEMODEL,
        `${deviceModelId}/attribute-sets/${deviceModelAttributeSetId}`,
      ),
    );
  };

  useEffect(() => {
    setLoading(loading);
    setTitle({ main: deviceModelAttributeSet?.name });
  }, [setLoading, loading, setTitle, deviceModelAttributeSet]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !deviceModelAttributeSet) {
    return (
      <NotFound
        title={t('deviceModels:attributeSet.notFound.title') ?? ''}
        subtitle={t('deviceModels:attributeSet.notFound.subtitle') ?? ''}
        buttonText={t('deviceModels:attributeSet.notFound.buttonText') ?? ''}
        buttonOnClick={() =>
          navigate(entityLocation(Entities.DEVICEMODEL, deviceModelId))
        }
      />
    );
  }
  if (isDeviceModelGlobal) {
    return (
      <ErrorMessage
        error={new Error(t('deviceModels:attributeSet.globalWarning') ?? '')}
      />
    );
  }

  return (
    <>
      <DeviceModelAttributeSetForm
        loading={loading || loadingUpdate}
        name={deviceModelAttributeSet?.name ?? ''}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorUpdate]}
      />
    </>
  );
};
