import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import {
  useSiteLocationCreateMutation,
  useSiteLocationDetailsQuery,
  useSiteLocationSiteUpdateMutation,
  useSiteLocationUpdateMutation,
} from '../../../__generated__/types';
import { useHeader } from '../../../contexts/header-context';
import { SiteLocation, SiteLocationData } from './SiteLocation';
import { NotFound } from '../../errorScreens';
import { ErrorMessage } from '../../../components/Errors';

export const SiteLocationContainer: React.FC = () => {
  const { t } = useTranslation(['sites', 'general']);
  const navigate = useNavigate();
  const { siteId } = useParams();
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useSiteLocationDetailsQuery({
    variables: { siteId },
  });

  const site = data?.site;

  const hierarchy = site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: siteId,
            name: site.name,
          },
        ],
        t,
      ).concat({
        title: t('sites:breadcrumb.location'),
        location: entityLocation(Entities.SITE, `${siteId}/location`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('sites:breadcrumb.location') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  const [update, { loading: loadingUpdate, error: errorUpdate }] =
    useSiteLocationUpdateMutation();

  const [create, { loading: loadingCreate, error: errorCreate }] =
    useSiteLocationCreateMutation();

  const [updateSite, { loading: loadingUpdateSite, error: errorUpdateSite }] =
    useSiteLocationSiteUpdateMutation();

  const createOrAddLocation = useCallback(
    async (location: SiteLocationData['location']) => {
      if (!site?.id) {
        return;
      }
      if (!site?.locationId) {
        await create({ variables: { location } })
          .then((result) => result.data?.createLocation?.id)
          .then((locationId) =>
            updateSite({ variables: { site: { id: site.id, locationId } } }),
          );
      } else {
        await update({
          variables: {
            location: { ...location, id: site?.locationId },
          },
        });
      }
    },
    [site, create, update, updateSite],
  );

  const handleSubmit = (location: SiteLocationData) => {
    createOrAddLocation(location.location)
      .then(() => navigate(entityLocation(Entities.SITE, siteId)))
      .catch((e) => console.warn(e));
  };

  const handleDiscard = () => {
    navigate(entityLocation(Entities.SITE, siteId));
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !site) {
    return (
      <NotFound
        title={t('sites:details.notFound.title') ?? ''}
        subtitle={t('sites:details.notFound.subtitle') ?? ''}
        buttonText={t('sites:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.SITE.path)}
      />
    );
  }

  return (
    <>
      <SiteLocation
        loading={loading || loadingUpdate || loadingCreate || loadingUpdateSite}
        location={{
          city: site?.location?.city ?? '',
          country: site?.location?.country ?? '',
          no: site?.location?.no ?? '',
          street: site?.location?.street ?? '',
          zip: site?.location?.zip ?? '',
        }}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorUpdate, errorCreate, errorUpdateSite]}
      />
    </>
  );
};
