import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { UserDelete } from './UserDelete';
import { deleteUser } from '../../../api/user';

interface UserDeleteContainerProps {
  userId: string;
  isOpen: boolean;
  onCompleted: () => void;
  onError: (error: Error) => void;
  onCancel: () => void;
}

export const UserDeleteContainer: React.FC<UserDeleteContainerProps> = ({
  userId,
  isOpen,
  onCompleted,
  onError,
  onCancel,
}) => {
  const {
    mutate: deleteUserAndInvitation,
    error,
    isLoading: loading,
  } = useMutation({
    mutationFn: deleteUser,
    onSuccess: onCompleted,
    onError,
  });

  const handleConfirm = () => deleteUserAndInvitation(userId);

  return (
    <UserDelete
      error={error as ApiError}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
