import React from 'react';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import {
  DeviceScanStats,
  DeviceScanStatsTable,
} from '../common/DeviceScanStatsTable';
import {
  ObjectScansStats,
  ObjectScanStatsTable,
} from '../common/ObjectScanStatsTable';

const classes = createClasses({
  cardContent: {
    marginBottom: 16,
  },
  discoverButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 16,
    minWidth: 300,
  },
  discoverButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  discoverIcon: {
    marginLeft: 8,
  },
});

export type GatewayDetails = {};
type DeviceDetailsDiscoveryCardProps = {
  loading?: boolean;
  deviceScan?: DeviceScanStats;
  objectScans?: ObjectScansStats;
  onDiscovery?: () => void;
};

export const DeviceDetailsDiscoveryCard: React.FC<
  DeviceDetailsDiscoveryCardProps
> = ({ loading, deviceScan, objectScans, onDiscovery }) => {
  const { t } = useTranslation(['devices', 'general']);

  return (
    <FancyCard
      title={t('devices:details.discoveryCard.title')}
      loading={loading}
    >
      <Grid container spacing={2} className={classes.cardContent}>
        <Grid item xs={12} lg={4}>
          <DeviceScanStatsTable
            status={deviceScan?.status}
            startedAt={deviceScan?.startedAt}
            finishedAt={deviceScan?.finishedAt}
            numOfDevices={deviceScan?.numOfDevices}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ObjectScanStatsTable objectScans={objectScans} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className={classes.discoverButtonContainer}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onDiscovery}
              disabled={!onDiscovery}
              data-testid={'discvery-button'}
            >
              <div className={classes.discoverButtonContent}>
                {t('devices:details.discoveryCard.discoverButton')}
                <Icons.TrackChanges className={classes.discoverIcon} />
              </div>
            </Button>
          </div>
        </Grid>
      </Grid>
    </FancyCard>
  );
};
