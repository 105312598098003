import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  BuildingFormData,
  BuildingFormInfo,
  getBuildingFormInfoSchema,
} from '../common/BuildingFormInfo';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type BuildingEditData = BuildingFormData;

type BuildingEditProps = BuildingEditData & {
  loading?: boolean;
  onSubmit: (location: BuildingEditData) => void;
  onDiscard: () => void;
};

export const BuildingEdit: React.FC<BuildingEditProps> = ({
  loading,
  name,
  description,
  dimension,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['buildings', 'general']);

  const validationSchema = getBuildingFormInfoSchema(t);
  const defaultValues: BuildingEditData = { name, description, dimension };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    reset({
      name,
      description,
      dimension,
    });
  }, [reset, name, description, dimension]);
  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <BuildingFormInfo loading={loading} />
    </FormProvider>
  );
};
