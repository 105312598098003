import React from 'react';
import { CircularProgress } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import classNames from 'classnames';
import { Color } from '../../constants/Colors';

const classes = createClasses({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '160px',
  },
  loader: {
    color: Color.lightGrey,
  },
});

interface ConditionalLoadingProps {
  loading?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const ConditionalLoading: React.FC<ConditionalLoadingProps> = ({
  loading,
  className,
  children,
}) => {
  return loading ? (
    <div className={classNames(classes.root, className)}>
      <CircularProgress className={classes.loader} thickness={5} />
    </div>
  ) : (
    <>{children}</>
  );
};
