import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { ApolloError } from '@apollo/client';
import { LoadingBackdrop } from '../../../components/Loading';
import { ConfirmAlert, ErrorAlert } from '../../../components/Alerts';

const classes = createClasses({
  conditions: {
    display: 'flex',
    flexDirection: 'column',
  },
});

type Option<T> = {
  parameter: T;
  parameterSetAction: (value: T) => void;
  label: string;
};
interface DeviceDeleteProps {
  error?: Error | ApolloError;
  isLoading?: boolean;
  isOpen: boolean;
  isGateway: boolean;
  deleteOptions?: Option<boolean>[];
  onConfirm: () => void;
  onClose: () => void;
}

export const DeviceDelete: React.FC<DeviceDeleteProps> = ({
  error,
  isLoading = false,
  isOpen,
  isGateway,
  deleteOptions,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  return (
    <>
      <LoadingBackdrop open={isLoading} />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error]}
      />
      <ConfirmAlert
        open={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        title={t('devices:deleteAlert.title')}
        message={
          isGateway
            ? t('devices:deleteAlert.gatewayDevice.message')
            : t('devices:deleteAlert.fieldDevice.message')
        }
        cancelButton={t('general:buttons.cancel')}
        confirmButton={t('general:buttons.delete')}
      >
        <div className={classes.conditions}>
          {deleteOptions
            ? deleteOptions.map((option) => (
                <FormControlLabel
                  key={option.label}
                  control={
                    <Checkbox
                      checked={option.parameter}
                      color="primary"
                      onChange={(event) =>
                        option.parameterSetAction(event.target.checked)
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {option.label}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              ))
            : null}
        </div>
      </ConfirmAlert>
    </>
  );
};
