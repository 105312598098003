import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import {
  AttributeCreateType,
  DataTypes,
  useAttributeCreateMutation,
} from '../../../__generated__/types';
import { AttributeData, AttributeForm } from '../common/AttributeForm';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';

export const AttributeCreateContainer: React.FC = () => {
  const { t } = useTranslation(['attributes', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const { setTitle } = useHeader();

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.ATTRIBUTE,
        id: '/new',
        name: t('attributes:breadcrumb.create'),
      },
    ],
    t,
  );

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('attributes:breadcrumb.create') });
  }, [setTitle, t]);

  const [createAttribute, { loading: loadingCreate, error: errorCreate }] =
    useAttributeCreateMutation({
      onCompleted: (result) => {
        navigate(
          entityLocation(Entities.ATTRIBUTE, result.createAttribute?.id ?? ''),
        );
      },
      onError: (error) => console.warn(error),
    });

  const handleDiscard = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  const handleCreate = (inputData: AttributeData) => {
    const attributeInput: AttributeCreateType = {
      name: inputData.attributeName,
      description: inputData.description,
      required: inputData.required,
      dataType: inputData.dataType ?? DataTypes.NotSpecified,
    };

    return createAttribute({
      variables: { attributeInput },
    });
  };

  return (
    <>
      <AttributeForm
        loading={loadingCreate}
        type="create"
        dataType={DataTypes.NotSpecified}
        required={false}
        attributeName=""
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorCreate]}
      />
    </>
  );
};
