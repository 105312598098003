import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as Entities from '../../../../constants/Entities';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';

import {
  availableRoles,
  createUserInvitation,
  getUserRoles,
} from '../../../../api/user';
import { entityLocation } from '../../../../utils/entity';
import { UserInvitationActionSuccessDialog } from '../../common/UserInvitationActionSuccessDialog';
import { ErrorAlert } from '../../../../components/Alerts';
import {
  UserInvitationCreate,
  UserInvitationCreateData,
} from './UserInvitationCreate';
import { ErrorMessage } from '../../../../components/Errors';

interface State {
  from?: string;
}

export const UserInvitationCreateContainer: React.FC = () => {
  const { t } = useTranslation(['users', 'general']);
  const { setTitle } = useHeader();
  const navigate = useNavigate();
  const { state } = useLocation() as { state: State };

  const {
    data: responseRoles,
    isLoading: loadingRoles,
    error: errorRoles,
  } = useQuery({
    queryKey: ['getUserRoles'],
    queryFn: () => getUserRoles(),
  });

  const rolesWithStatus = useMemo(
    () =>
      (responseRoles?.data ?? [])
        .filter((role) => availableRoles.includes(role.name))
        .map((role) => ({
          ...role,
          enabled: false,
        })),
    [responseRoles?.data],
  );

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.USER,
        id: '/new',
        name: t('users:invitation.breadcrumb.create'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('users:invitation.breadcrumb.create') });
  }, [setTitle, t]);

  const {
    mutateAsync: create,
    error,
    isLoading: loading,
    data: response,
  } = useMutation({ mutationFn: createUserInvitation });

  const handleCreate = ({
    email,
    roles: selectedRoles,
  }: UserInvitationCreateData) => {
    const roleIds = selectedRoles.filter((r) => r.enabled).map((r) => r.id);
    return create({ email, roleIds }).catch(console.warn);
  };

  const handleDiscard = () => {
    // there are 2 entry points to invite users (usersList and userInvitationsList)
    // on discard we want to redirect accordingly
    if (state && state.from === 'users') {
      navigate(entityLocation(Entities.USER, ''));
    } else {
      navigate(entityLocation(Entities.USER, 'invitations'));
    }
  };

  const handleConfirm = () => {
    // even though there are 2 entry points
    // on confirm we want to always be redirected to the list of invitations
    navigate(entityLocation(Entities.USER, 'invitations'));
  };

  const handleAction = () => {
    navigate(entityLocation(Entities.USER, 'invitations'));
  };

  if (errorRoles instanceof Error) {
    return <ErrorMessage error={errorRoles} />;
  }
  return (
    <>
      <UserInvitationCreate
        loading={loading || loadingRoles}
        onSubmit={handleCreate}
        onDiscard={handleDiscard}
        roles={rolesWithStatus}
      />
      {!error && !loading && response?.data && (
        <UserInvitationActionSuccessDialog
          onConfirm={handleConfirm}
          dialogTitle={t('users:invitation.create.confirm.title')}
          dialogContentText={t('users:invitation.create.confirm.message', {
            email: response.data.email,
          })}
        />
      )}
      <ErrorAlert
        title={t('users:invitation.create.error.title')}
        errors={
          error instanceof Error
            ? [
                error?.message === 'Conflict'
                  ? new Error(t('users:invitation.create.error.conflict') ?? '')
                  : error,
              ]
            : []
        }
        actions={[
          {
            label: t('users:invitation.create.error.action'),
            onAction: handleAction,
          },
        ]}
      />
    </>
  );
};
