import React from 'react';
import { Button, Divider, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { StoreyDetailsZonesContainer } from './StoreyDetailsZonesContainer';
import { FancyCard } from '../../../components/FancyCard';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { ActionsBar } from '../../../components/ActionsBar';

const classes = createClasses({
  divider: {
    marginTop: '24px',
    marginBottom: '14px',
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
});

interface StoreyDetailsProps {
  name: string;
  description: string;
  image: { url: string; name: string };
  onDelete?: () => void;
  onEdit?: () => void;
  showZones?: boolean;
  loading?: boolean;
}

export const StoreyDetails: React.FC<StoreyDetailsProps> = ({
  name,
  image,
  description,
  onDelete,
  onEdit,
  showZones,
  loading,
}) => {
  const { t } = useTranslation(['storeys', 'general']);

  return (
    <>
      {onDelete && (
        <ActionsBar>
          {!loading && (
            <Button color="primary" size="large" onClick={onDelete}>
              {t('storeys:actions.delete')}
            </Button>
          )}
        </ActionsBar>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12} sm={12} md={12}>
              <FancyCard
                title={t('storeys:info.title')}
                onEdit={onEdit}
                image={image}
                loading={loading}
                fullHeight
              >
                <InfoList>
                  <InfoListItem
                    term={t('storeys:info.name')}
                    className={classes.text}
                    key={classes.text}
                  >
                    {name}
                  </InfoListItem>
                  {description && (
                    <InfoListItem
                      term={t('general:fields.description')}
                      className={classes.text}
                    >
                      {description}
                    </InfoListItem>
                  )}
                </InfoList>
              </FancyCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showZones && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Divider className={classes.divider} />
            <StoreyDetailsZonesContainer />
          </Grid>
        </Grid>
      )}
    </>
  );
};
