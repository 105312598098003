import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Color } from '../../../constants/Colors';
import { CopyIconButton } from '../../../components/CopyIconButton';

const classes = createClasses({
  row: {
    display: 'flex',
    alignItems: 'center',
    background: Color.inputBackground,
    padding: '4px 8px',
    marginBottom: '16px',
    borderRadius: '4px',
  },
  pre: {
    flexGrow: 1,
    marginRight: '16px',
    paddingLeft: '8px',
    fontSize: '14px',
  },
  divider: {
    marginBottom: '16px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

interface RegisteredApplicationCreateSuccessDialogProps {
  onConfirm: () => void;
  secret: string;
  publicOAuth2Client: string;
  confidentialOAuth2Client: string;
}

export const RegisteredApplicationCreateSuccessDialog: React.FC<
  RegisteredApplicationCreateSuccessDialogProps
> = ({ onConfirm, secret, publicOAuth2Client, confidentialOAuth2Client }) => {
  const { t } = useTranslation(['registeredApplications', 'general']);
  const [confirmed, setConfirmed] = useState(false);

  const handleClose = useCallback(() => {
    if (confirmed) onConfirm();
  }, [confirmed, onConfirm]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6" component="span">
          {t('registeredApplications:create.confirm.title')}
        </Typography>
        <Icons.CheckCircle htmlColor={Color.ok} />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t('registeredApplications:create.confirm.message')}
        </DialogContentText>
        <div className={classes.divider} />
        <Typography variant="h6">
          {t('registeredApplications:create.confirm.publicClient')}
        </Typography>
        <DialogContentText>
          {t('registeredApplications:create.confirm.publicClientFlow')}
        </DialogContentText>
        <Typography variant="caption">
          {t('registeredApplications:create.confirm.publicClientId')}
        </Typography>
        <div className={classes.row}>
          <pre className={classes.pre}>{publicOAuth2Client}</pre>
          <CopyIconButton
            value={publicOAuth2Client}
            aria-label="copy-to-clipboard-public-client-id"
            size="medium"
            color="primary"
          />
        </div>
        <Divider className={classes.divider} />
        <Typography variant="h6">
          {t('registeredApplications:create.confirm.confidentialClient')}
        </Typography>
        <DialogContentText>
          {t('registeredApplications:create.confirm.confidentialClientFlow')}
        </DialogContentText>
        <Typography variant="caption">
          {t('registeredApplications:create.confirm.confidentialClientId')}
        </Typography>
        <div className={classes.row}>
          <pre className={classes.pre}>{confidentialOAuth2Client}</pre>
          <CopyIconButton
            value={confidentialOAuth2Client}
            aria-label="copy-to-clipboard-public-client-id"
            size="medium"
            color="primary"
          />
        </div>
        <DialogContentText>
          {t('registeredApplications:create.confirm.warning')}
        </DialogContentText>
        <Typography variant="caption">
          {t('registeredApplications:create.confirm.confidentialClientSecret')}
        </Typography>
        <div className={classes.row}>
          <pre className={classes.pre}>{secret}</pre>
          <CopyIconButton
            value={secret}
            aria-label="copy-to-clipboard-confidential-client-secret"
            size="medium"
            color="primary"
          />
        </div>
        <div>
          <FormControlLabel
            value={confirmed}
            control={
              <Checkbox
                color="primary"
                onChange={(event) => setConfirmed(event.target.checked)}
              />
            }
            label={String(
              t('registeredApplications:create.confirm.confirmation'),
            )}
            labelPlacement="end"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={!confirmed}>
          {t('general:buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
