import React, { useCallback, useState } from 'react';
import {
  DialogContentText,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { KnownPermissionsType } from '../../../constants/Permissions';
import { ConfirmAlert } from '../../../components/Alerts';
import { PermissionsTable } from '../../../components/PermissionsTable';

const classes = createClasses({
  row: {
    display: 'flex',
    alignItems: 'center',
    background: '#f8f8f8',
    padding: '4px 8px',
    marginBottom: '16px',
    borderRadius: '4px',
  },
  pre: {
    flexGrow: 1,
    marginRight: '16px',
    paddingLeft: '8px',
    fontSize: '14px',
  },
  divider: {
    marginBottom: '16px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  infoIcon: {
    marginRight: '8px',
    display: 'block',
  },
});

export interface ApplicationPermissionsConfirmDialogPermission
  extends KnownPermissionsType {
  hasWrite?: boolean;
  hasRead?: boolean;
}

interface ApplicationPermissionsConfirmDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  permissions: ApplicationPermissionsConfirmDialogPermission[];
  open: boolean;
}

export const ApplicationPermissionsConfirmDialog: React.FC<
  ApplicationPermissionsConfirmDialogProps
> = ({ onConfirm, onCancel, open, permissions }) => {
  const { t } = useTranslation(['applications', 'general']);
  const [confirmed, setConfirmed] = useState(false);

  const handleClose = useCallback(() => {
    if (confirmed) onConfirm();
  }, [confirmed, onConfirm]);

  return (
    <ConfirmAlert
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      onConfirm={handleClose}
      confirmButton={t('general:buttons.ok')}
      cancelButton={t('general:buttons.cancel')}
      closeButton
      options={{ confirmDisabled: !confirmed }}
      title={t('applications:permissions.confirm.title')}
      message={t('applications:permissions.confirm.message')}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <Divider className={classes.divider} />
      <PermissionsTable permissions={permissions} />
      <div className={classes.divider} />
      <DialogContentText>
        {t('applications:permissions.confirm.warning')}
      </DialogContentText>
      <div>
        <FormControlLabel
          value={confirmed}
          control={
            <Checkbox
              color="primary"
              onChange={(event) => setConfirmed(event.target.checked)}
            />
          }
          label={String(t('applications:permissions.confirm.confirmation'))}
          labelPlacement="end"
        />
      </div>
    </ConfirmAlert>
  );
};
