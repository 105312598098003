import React, { useCallback } from 'react';
import {
  createClasses,
  Header as UIHeader,
  IconButton,
  Popover,
  Menu,
  MenuItem,
  Avatar,
  Colors,
  Icons,
  Divider,
  LogoB2B,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';
import { BUILDING_INSIGHTS_APP_URI, ACCOUNT_APP_URI } from '../../utils/env';

const classes = createClasses({
  header: {
    position: 'fixed',
    zIndex: 1201,
  },
  leftActions: {
    flexShrink: 1,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: Colors.Neutral.text,
    marginLeft: '16px',
  },
  rightActions: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  avatar: {
    marginLeft: '16px',
  },
  open: {
    transform: `rotate(180deg)`,
  },
  logo: {
    position: 'absolute',
    left: 'calc(50% - 100px)',
  },
});

interface HeaderProps {
  onCollapseClick?: () => void;
  sidebarOpen?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  onCollapseClick,
  sidebarOpen,
}) => {
  const { t } = useTranslation(['general']);
  const { signOut, user } = useAuth();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const [firstName, lastName] = (user?.info?.name ?? '').split(/ /);

  return (
    <UIHeader className={classes.header}>
      <div className={classes.leftActions}>
        <IconButton
          icon={Icons.IconCollapseMenu}
          onClick={onCollapseClick}
          className={sidebarOpen ? undefined : classes.open}
        />
        <div className={classes.title}>Administration</div>
      </div>
      <LogoB2B className={classes.logo} />
      <div className={classes.rightActions}>
        <Popover
          data-testid="popover"
          trigger={IconButton}
          icon={Icons.IconApps}
          triggerActiveProp="active"
        >
          <Menu>
            <MenuItem
              component="a"
              href={BUILDING_INSIGHTS_APP_URI}
              target="_blank"
            >
              Building Insights
            </MenuItem>
          </Menu>
        </Popover>
        <Avatar
          data-testid="avatar"
          firstName={firstName ?? ''}
          lastName={lastName ?? ''}
          email={user?.info?.email ?? ''}
          className={classes.avatar}
        >
          <Menu>
            <MenuItem component="a" href={ACCOUNT_APP_URI} target="_blank">
              {t('general:header.myAccount')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleSignOut}>
              {t('general:header.signOut')}
            </MenuItem>
          </Menu>
        </Avatar>
      </div>
    </UIHeader>
  );
};
