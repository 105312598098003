import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';

import { Layout } from './components/Layout';
import { LayoutSimple } from './components/LayoutSimple';
import { Dashboard } from './features/dashboard/Dashboard';
import { NotFound } from './features/errorScreens/NotFound';
import { ApplicationsRoutes } from './features/applications/ApplicationsRoutes';
import { TenantsRoutes } from './features/tenants/TenantsRoutes';
import { DevicesRoutes } from './features/devices/DevicesRoutes';
import { DeviceModelsRoutes } from './features/deviceModels/DeviceModelsRoutes';
import { ZonesRoutes } from './features/zones/ZonesRoutes';
import { StoreysRoutes } from './features/storeys/StoreysRoutes';
import { BuildingsRoutes } from './features/buildings/BuildingRoutes';
import { SitesRoutes } from './features/sites/SiteRoutes';
import { DataRoutes } from './features/historicalData/DataRoutes';
import { AttributesRoutes } from './features/attributes/AttributesRoutes';
import { CapabilitiesRoutes } from './features/capabilities/CapabilitiesRoutes';
import { UnitRoutes } from './features/units/UnitRoutes';
import { RegisteredApplicationsRoutes } from './features/registeredApplications/RegisteredApplicationsRoutes';
import { UsersRoutes } from './features/users/UsersRoutes';
import { IntegrationsRoutes } from './features/integrations/IntegrationsRoutes';
import { AuthRegisterContainer } from './features/auth/register';
import { AuthLandingContainer } from './features/auth/landing';
import { AuthAcceptInvitationContainer } from './features/auth/acceptInvitation';
import { Forbidden } from './features/auth/forbidden';
import { StatusRoutes } from './features/status/StatusRoutes';

const defaultLayout = [
  '/tenants',
  '/applications',
  '/sites',
  '/buildings',
  '/storeys',
  '/devices',
  '/zones',
  '/data',
  '/devicemodels',
  '/attributes',
  '/capabilities',
  '/units',
  '/development',
  '/users',
  '/integrations',
  '/status',
];

const App: React.FC = () => {
  const location = useLocation();

  const isDefaultLayout =
    location.pathname === '/' ||
    defaultLayout.some((route) => location.pathname.startsWith(route));

  const LayoutComponent = isDefaultLayout ? Layout : LayoutSimple;

  return (
    <LayoutComponent>
      <Routes>
        <Route path="/" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route
          path="/tenants/*"
          element={<ProtectedRoute element={<TenantsRoutes />} />}
        />
        <Route
          path="/applications/*"
          element={
            <ProtectedRoute
              element={<ApplicationsRoutes />}
              permission={Permissions.ApplicationsRead}
            />
          }
        />
        <Route
          path="/sites/*"
          element={
            <ProtectedRoute
              element={<SitesRoutes />}
              permission={Permissions.SitesRead}
            />
          }
        />
        <Route
          path="/buildings/*"
          element={
            <ProtectedRoute
              element={<BuildingsRoutes />}
              permission={Permissions.BuildingsRead}
            />
          }
        />
        <Route
          path="/storeys/*"
          element={
            <ProtectedRoute
              element={<StoreysRoutes />}
              permission={Permissions.StoreysRead}
            />
          }
        />
        <Route
          path="/zones/*"
          element={
            <ProtectedRoute
              element={<ZonesRoutes />}
              permission={Permissions.ZonesRead}
            />
          }
        />
        <Route
          path="/devices/*"
          element={
            <ProtectedRoute
              element={<DevicesRoutes />}
              permission={Permissions.DevicesRead}
            />
          }
        />
        <Route
          path="/devicemodels/*"
          element={
            <ProtectedRoute
              element={<DeviceModelsRoutes />}
              permission={Permissions.DeviceModelsRead}
            />
          }
        />
        <Route
          path="/attributes/*"
          element={
            <ProtectedRoute
              element={<AttributesRoutes />}
              permission={Permissions.AttributesRead}
            />
          }
        />
        <Route
          path="/capabilities/*"
          element={
            <ProtectedRoute
              element={<CapabilitiesRoutes />}
              permission={Permissions.CapabilitiesRead}
            />
          }
        />
        <Route
          path="/units/*"
          element={
            <ProtectedRoute
              element={<UnitRoutes />}
              permission={Permissions.UnitsRead}
            />
          }
        />
        <Route
          path="/data/*"
          element={
            <ProtectedRoute
              element={<DataRoutes />}
              permission={Permissions.DeviceDataRead}
            />
          }
        />
        <Route
          path="/development/*"
          element={
            <ProtectedRoute
              element={<RegisteredApplicationsRoutes />}
              permission={Permissions.RegisteredApplicationsRead}
            />
          }
        />
        <Route
          path="/users/*"
          element={
            <ProtectedRoute
              element={<UsersRoutes />}
              permission={Permissions.UsersRead}
            />
          }
        />
        <Route
          path="/integrations/*"
          element={
            <ProtectedRoute
              element={<IntegrationsRoutes />}
              permission={Permissions.QanteonProvisioningRead}
            />
          }
        />
        <Route
          path="/status/*"
          element={
            <ProtectedRoute
              element={<StatusRoutes />}
              permission={Permissions.CommissioningRead}
            />
          }
        />
        <Route path="/register" element={<AuthRegisterContainer />} />
        <Route path="/landing" element={<AuthLandingContainer />} />
        <Route
          path="/accept-invitation"
          element={<AuthAcceptInvitationContainer />}
        />
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="*" element={<NotFound type="page" />} />
      </Routes>
    </LayoutComponent>
  );
};

export default App;
