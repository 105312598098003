import React from 'react';
import { Checkbox } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { DataTypes } from '../../../__generated__/types';
import { DataTypeInput } from '../../../components/DataTypeInput';

export interface AttributeData {
  id: string;
  dataType: DataTypes | null;
  name: string;
  value?: string;
  required: boolean;
  enabled?: boolean;
}

type DeviceCreateAttributesFieldProps = {
  fieldId: string;
  loading?: boolean;
};

const classes = createClasses({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkbox: {},
  field: {
    flexGrow: 1,
  },
});

export const DeviceCreateAttributesField: React.FC<
  DeviceCreateAttributesFieldProps
> = ({ loading, fieldId }) => {
  const { control, setValue, watch } = useFormContext();
  const attribute = watch(fieldId);

  const handleChangeValue = (v: string) => {
    setValue(`${fieldId}.value`, v);
  };

  const handleChangeEnabled = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setValue(`${fieldId}.enabled`, checked);
  };

  return (
    <div className={classes.root}>
      <div className={classes.field}>
        <Controller
          name={`${fieldId}.value`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <DataTypeInput
              {...field}
              id={`${fieldId}.value`}
              name={`${fieldId}.value`}
              disabled={loading || !attribute.enabled}
              label={attribute.name}
              dataType={attribute.dataType}
              onChange={handleChangeValue}
              autoFocus={false}
              error={!!error}
              helperText={error?.message ?? ' '}
              optional={!attribute.required}
            />
          )}
        />
      </div>
      <div className={classes.checkbox}>
        <Controller
          name={`${fieldId}.enabled`}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              name={`${fieldId}.enabled`}
              inputProps={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                'data-testid': `${fieldId}-checkbox`,
              }}
              color="primary"
              size="small"
              onChange={handleChangeEnabled}
              disabled={attribute.required}
            />
          )}
        />
      </div>
    </div>
  );
};
