import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { ErrorAlert } from '../../../components/Alerts';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import {
  getRegisteredApplication,
  updateRegisteredApplication,
} from '../../../api/application';
import {
  RegisteredApplicationEdit,
  RegisteredApplicationEditData,
} from './RegisteredApplicationEdit';
import { RegisteredApplicationEditConfirmDialog } from './RegisteredApplicationEditConfirmDialog';
import {
  permissionStringsToValues,
  permissionValuesToStrings,
} from '../../../constants/Permissions';
import { NotFound } from '../../errorScreens';
import { ErrorMessage } from '../../../components/Errors';

export const RegisteredApplicationEditContainer: React.FC = () => {
  const { t } = useTranslation(['registeredApplications', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { applicationId } = params;
  const { setTitle } = useHeader();
  const [editData, setEditData] = useState<RegisteredApplicationEditData>();
  const [openConfirm, setOpenConfirm] = useState(false);

  const {
    data: responseDetails,
    isLoading: loadingDetails,
    error: errorDetails,
  } = useQuery({
    queryKey: ['getRegisteredApplication', applicationId],
    queryFn: () => getRegisteredApplication(applicationId),
    onError: (err: ApiError) => err,
  });
  const application = responseDetails?.data && {
    ...responseDetails.data,
    redirectUris: responseDetails.data.redirectUris?.map((uri) => ({ uri })),
  };
  const hierarchy = application
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.REGISTERED_APPLICATION,
            id: applicationId,
            name: application?.name ?? '',
          },
        ],
        t,
      ).concat({
        title: t('registeredApplications:breadcrumb.edit'),
        location: entityLocation(
          Entities.REGISTERED_APPLICATION,
          `${applicationId}/edit`,
        ),
      })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('registeredApplications:breadcrumb.edit') });
  }, [setTitle, t]);

  const {
    mutate: update,
    isLoading: loadingUpdate,
    error: errorUpdate,
  } = useMutation({
    mutationFn: updateRegisteredApplication,
    onSuccess: () =>
      navigate(entityLocation(Entities.REGISTERED_APPLICATION, applicationId)),
    onError: (err: ApiError) => err,
  });

  const handleDiscard = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  const handleSubmit = (inputData: RegisteredApplicationEditData) => {
    const permissionStrings = permissionValuesToStrings(inputData.permissions);
    const permissionsExtended = permissionStrings.some(
      (p) => !application?.permissions?.includes(p),
    );
    if (!permissionsExtended) {
      const data = {
        applicationId,
        ...inputData,
        redirectUris: inputData.redirectUris.map((entry) => entry.uri),
        permissions: permissionStrings,
      };
      return update(data);
    }

    setEditData(inputData);
    setOpenConfirm(true);
    return null;
  };

  const handleConfirm = () => {
    setOpenConfirm(false);
    const data = editData
      ? {
          applicationId,
          ...editData,
          permissions: permissionValuesToStrings(editData.permissions),
          redirectUris: editData.redirectUris.map((entry) => entry.uri),
        }
      : undefined;
    return data && update(data);
  };

  const handleCancel = () => {
    setOpenConfirm(false);
  };
  const permissions = permissionStringsToValues(
    t,
    application?.permissions ?? [],
  );

  if (errorDetails instanceof Error) {
    if (errorDetails.status === 404) {
      return (
        <NotFound
          title={t('registeredApplications:details.notFound.title') ?? ''}
          subtitle={t('registeredApplications:details.notFound.subtitle') ?? ''}
          buttonText={
            t('registeredApplications:details.notFound.buttonText') ?? ''
          }
          buttonOnClick={() => navigate(Entities.REGISTERED_APPLICATION.path)}
        />
      );
    }
    return <ErrorMessage error={errorDetails} />;
  }

  return (
    <>
      <RegisteredApplicationEdit
        loading={loadingDetails || loadingUpdate}
        name={application?.name ?? ''}
        description={application?.description ?? ''}
        url={application?.url ?? ''}
        redirectUris={application?.redirectUris ?? []}
        permissions={permissions}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <RegisteredApplicationEditConfirmDialog
        open={openConfirm}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
