import { TFunction } from 'i18next';
import { Permissions } from '@kp/react-sdk';

export type KnownPermissionsType = {
  id: string;
  label: string;
  description: string;
  read?: Permissions;
  write?: Permissions;
};
type PermissionsValueType = KnownPermissionsType & {
  hasRead?: boolean;
  hasWrite?: boolean;
};

export type KnownPermissionsValueType = {
  users: PermissionsValueType[];
  applications: PermissionsValueType[];
  data: PermissionsValueType[];
  other: PermissionsValueType[];
};

type GetKnownPermissionsType = (t: TFunction) => {
  users: KnownPermissionsType[];
  applications: KnownPermissionsType[];
  data: KnownPermissionsType[];
  other: KnownPermissionsType[];
};

export const getKnownPermissions: GetKnownPermissionsType = (t) => ({
  users: [
    {
      id: 'Users',
      label: t('general:permissions.users.label'),
      description: t('general:permissions.users.description'),
      read: Permissions.UsersRead,
    },
    {
      id: 'UserDetails',
      label: t('general:permissions.userDetails.label'),
      description: t('general:permissions.userDetails.description'),
      read: Permissions.UserDetailsRead,
    },
    {
      id: 'UserPermissions',
      label: t('general:permissions.userPermissions.label'),
      description: t('general:permissions.userPermissions.description'),
      read: Permissions.UserPermissionsRead,
    },
    {
      id: 'UserRoles',
      label: t('general:permissions.userRoles.label'),
      description: t('general:permissions.userRoles.description'),
      read: Permissions.UserRolesRead,
      write: Permissions.UserRolesWrite,
    },
    {
      id: 'Invitations',
      label: t('general:permissions.invitations.label'),
      description: t('general:permissions.invitations.description'),
      read: Permissions.InvitationsRead,
      write: Permissions.InvitationsWrite,
    },
  ],
  applications: [
    {
      id: 'RegisteredApplications',
      label: t('general:permissions.registeredApplications.label'),
      description: t('general:permissions.registeredApplications.description'),
      read: Permissions.RegisteredApplicationsRead,
      write: Permissions.RegisteredApplicationsWrite,
    },
    {
      id: 'Applications',
      label: t('general:permissions.applications.label'),
      description: t('general:permissions.applications.description'),
      read: Permissions.ApplicationsRead,
      write: Permissions.ApplicationsWrite,
    },
  ],
  data: [
    {
      id: 'Sites',
      label: t('general:permissions.sites.label'),
      description: t('general:permissions.sites.description'),
      read: Permissions.SitesRead,
      write: Permissions.SitesWrite,
    },
    {
      id: 'Buildings',
      label: t('general:permissions.buildings.label'),
      description: t('general:permissions.buildings.description'),
      read: Permissions.BuildingsRead,
      write: Permissions.BuildingsWrite,
    },
    {
      id: 'Storeys',
      label: t('general:permissions.storeys.label'),
      description: t('general:permissions.storeys.description'),
      read: Permissions.StoreysRead,
      write: Permissions.StoreysWrite,
    },
    {
      id: 'Zones',
      label: t('general:permissions.zones.label'),
      description: t('general:permissions.zones.description'),
      read: Permissions.ZonesRead,
      write: Permissions.ZonesWrite,
    },
    {
      id: 'Devices',
      label: t('general:permissions.devices.label'),
      description: t('general:permissions.devices.description'),
      read: Permissions.DevicesRead,
      write: Permissions.DevicesWrite,
    },
    {
      id: 'DeviceData',
      label: t('general:permissions.deviceData.label'),
      description: t('general:permissions.deviceData.description'),
      read: Permissions.DeviceDataRead,
    },
    {
      id: 'DeviceModels',
      label: t('general:permissions.deviceModels.label'),
      description: t('general:permissions.deviceModels.description'),
      read: Permissions.DeviceModelsRead,
      write: Permissions.DeviceModelsWrite,
    },
    {
      id: 'Attributes',
      label: t('general:permissions.attributes.label'),
      description: t('general:permissions.attributes.description'),
      read: Permissions.AttributesRead,
      write: Permissions.AttributesWrite,
    },
    {
      id: 'Capabilities',
      label: t('general:permissions.capabilities.label'),
      description: t('general:permissions.capabilities.description'),
      read: Permissions.CapabilitiesRead,
      write: Permissions.CapabilitiesWrite,
    },
    {
      id: 'Units',
      label: t('general:permissions.units.label'),
      description: t('general:permissions.units.description'),
      read: Permissions.UnitsRead,
      write: Permissions.UnitsWrite,
    },
  ],
  other: [
    {
      id: 'QanteonProvisioning',
      label: t('general:permissions.qanteonProvisioning.label'),
      description: t('general:permissions.qanteonProvisioning.description'),
      read: Permissions.QanteonProvisioningRead,
      write: Permissions.QanteonProvisioningWrite,
    },
    {
      id: 'Widgets',
      label: t('general:permissions.widgets.label'),
      description: t('general:permissions.widgets.description'),
      read: Permissions.WidgetsRead,
      write: Permissions.WidgetsWrite,
    },
  ],
});

export const getListKnownPermissions = (
  t: TFunction,
): KnownPermissionsType[] => {
  const { users, applications, data, other } = getKnownPermissions(t);
  return [...users, ...applications, ...data, ...other];
};

export const permissionStringsToValues = (
  t: TFunction,
  values: string[],
): KnownPermissionsValueType => {
  const permissions = getKnownPermissions(t);
  const permissionsWithValues = {
    users: permissions.users.map((p) => ({
      ...p,
      hasRead: p.read && values.includes(p.read),
      hasWrite: p.write && values.includes(p.write),
    })),
    applications: permissions.applications.map((p) => ({
      ...p,
      hasRead: p.read && values.includes(p.read),
      hasWrite: p.write && values.includes(p.write),
    })),
    data: permissions.data.map((p) => ({
      ...p,
      hasRead: p.read && values.includes(p.read),
      hasWrite: p.write && values.includes(p.write),
    })),
    other: permissions.other.map((p) => ({
      ...p,
      hasRead: p.read && values.includes(p.read),
      hasWrite: p.write && values.includes(p.write),
    })),
  };

  return permissionsWithValues;
};

export const permissionValuesToStrings = (
  permissions: KnownPermissionsValueType,
): string[] => {
  return [
    ...permissions.users,
    ...permissions.applications,
    ...permissions.data,
    ...permissions.other,
  ]
    .map((p) => [
      ...(p.hasRead ? [p.read] : []),
      ...(p.hasWrite ? [p.write] : []),
    ])
    .flat()
    .map((p) => p as string);
};
