import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Permissions, useAuth } from '@kp/react-sdk';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import * as Pages from '../../../constants/Pages';
import { GatewayListContainer } from '../../../components/GatewayList';
import { StatusOverviewFieldDeviceListContainer } from './StatusOverviewFieldDeviceListContainer';
import { FieldDevice } from './StatusOverviewFieldDeviceList';
import { StatusOverviewDeviceDataContainer } from './StatusOverviewDeviceDataContainer';
import { StatusOverviewDeviceData } from './StatusOverviewDeviceData';

export const StatusOverviewContainer: React.FC = () => {
  const { t } = useTranslation(['status', 'general']);
  const { hasPermission } = useAuth();
  const { setTitle, setIcon } = useHeader();

  const [gatewayId, setGatewayId] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<FieldDevice>();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.STATUS, t),
    location: Entities.STATUS.path,
  });
  useEffect(() => {
    setTitle({ main: t('status:overview.title') });
    setIcon(Pages.DASHBOARD.icon);
  }, [setTitle, setIcon, t]);

  const handleSelectGateway = (id?: string) => {
    setGatewayId(id);
    setSelectedDevice(undefined);
  };
  const handleSelectDevice = (device?: FieldDevice) => {
    setSelectedDevice(device);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <GatewayListContainer
          selectedId={gatewayId}
          onSelect={handleSelectGateway}
        />
      </Grid>
      <Grid item xs={12}>
        <StatusOverviewFieldDeviceListContainer
          gatewayId={gatewayId}
          onSelectDevice={handleSelectDevice}
        />
      </Grid>
      {hasPermission(Permissions.DeviceDataRead) && (
        <Grid item xs={12}>
          {selectedDevice ? (
            <StatusOverviewDeviceDataContainer device={selectedDevice} />
          ) : (
            <StatusOverviewDeviceData />
          )}
        </Grid>
      )}
    </Grid>
  );
};
