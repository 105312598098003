import React from 'react';
import { createClasses } from '@kp/react-ui';
import { Tooltip } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { Color } from '../../constants/Colors';

const classes = createClasses({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: '4px',
  },
});

type TooltipPropsIcon = {
  text: string;
  tootltipText: string;
};
export const TooltipIcon: React.FC<TooltipPropsIcon> = ({
  text,
  tootltipText,
}) => (
  <div className={classes.headerContainer}>
    {text}
    <Tooltip arrow placement="top" title={tootltipText}>
      <Icons.Help
        className={classes.icon}
        fontSize="small"
        htmlColor={Color.mediumGrey}
        data-testid="help-icon"
      />
    </Tooltip>
  </div>
);
