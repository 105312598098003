import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth } from '@kp/react-sdk';
import {
  initAppInsights,
  AppInsightsErrorBoundary,
} from '@kp/react-sdk/app-insights';
import 'normalize.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApiError, OpenAPI } from '@kp/rest-api-javascript-sdk';
import App from './App';
import { BreadcrumbProvider } from './contexts/breadcrumb-context';
import './utils/i18n';
import './utils/validation';
import {
  AUTH_URI,
  CLIENT_ID,
  APPLICATIONINSIGHTS_CONNECTION_STRING,
  REST_URI,
} from './utils/env';
import { GlobalLoading } from './components/GlobalLoading';
import { GraphQLProvider } from './contexts/graphql-context';
import { ThemeProvider } from './components/ThemeProvider';
import { PickersProvider } from './contexts/pickers-context';
import { HeaderProvider } from './contexts/header-context';
import { BadgeProvider } from './contexts/badge-context';
import { SettingsProvider } from './contexts/settings-provider';
import { ErrorFallback } from './components/Errors';

if (APPLICATIONINSIGHTS_CONNECTION_STRING) {
  initAppInsights('admin-app', {
    connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
    correlationHeaderExcludedDomains: ['id.*'],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // don't retry for 404 errors. We expect those for resources that don't exist
      // https://github.com/TanStack/query/discussions/372
      retry: (failureCount, error) => {
        if (error instanceof Error && 'status' in error) {
          const apiError = error as ApiError;
          if (apiError.status === 404) return false;
        }
        return failureCount < 3;
      },
    },
  },
});
// eslint-disable-next-line no-underscore-dangle
OpenAPI.TOKEN = async () => window.__AUTH_TOKEN__ ?? '';
OpenAPI.BASE = REST_URI;

const Root: React.FC = () => (
  <ThemeProvider>
    <Suspense fallback={<GlobalLoading />}>
      <AppInsightsErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={console.warn}
      >
        {/* the keycloak provider needs to be rendered outside of strict mode, because strict mode causes it to relaod and loose the auth information */}
        <Auth
          clientId={CLIENT_ID}
          url={AUTH_URI}
          forbiddenUrl="/forbidden"
          LoadingComponent={GlobalLoading}
        >
          <React.StrictMode>
            <QueryClientProvider client={queryClient}>
              <SettingsProvider>
                <BadgeProvider>
                  <BreadcrumbProvider>
                    <Router>
                      <GraphQLProvider>
                        <PickersProvider>
                          <HeaderProvider>
                            <App />
                          </HeaderProvider>
                        </PickersProvider>
                      </GraphQLProvider>
                    </Router>
                  </BreadcrumbProvider>
                </BadgeProvider>
              </SettingsProvider>
            </QueryClientProvider>
          </React.StrictMode>
        </Auth>
      </AppInsightsErrorBoundary>
    </Suspense>
  </ThemeProvider>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);
