import { QueryResult } from '@apollo/client';
import { useState } from 'react';
import {
  DeviceIdentifierExistsQuery,
  Exact,
  useDeviceIdentifierExistsLazyQuery,
} from '../../../__generated__/types';

export type ValidationState = 'LOADING' | 'ERROR' | 'VALID' | 'INVALID';

export type UseValidateDeviceIdentifier = {
  validationState: ValidationState;
  validate: (deviceIdentifier: string) => void;
};

export const useValidateDeviceIdentifier = (
  usedDeviceIdentifiers: {
    devices: Array<{
      deviceIdentifier: string;
      id: string;
    }>;
  } = { devices: [] },
): UseValidateDeviceIdentifier => {
  const [newDeviceIdentifier, setNewDeviceIdentifier] = useState<string>();

  const [validateDeviceIdentifier, validationResult] =
    useDeviceIdentifierExistsLazyQuery();

  const handleDeviceIdentifierValidation = (deviceIdentifier: string) => {
    setNewDeviceIdentifier(deviceIdentifier);
    if (deviceIdentifier && deviceIdentifier !== '0') {
      validateDeviceIdentifier({
        variables: {
          deviceIdentifier,
        },
      });
    }
  };

  const calculateValidationState = ({
    called,
    loading,
    error,
    data,
  }: QueryResult<
    DeviceIdentifierExistsQuery,
    Exact<{
      deviceIdentifier: string;
    }>
  >): ValidationState => {
    if (data?.devices?.items?.length) return 'INVALID';
    if (!newDeviceIdentifier) return 'VALID';
    if (!called || loading) return 'LOADING';
    if (error) {
      return 'ERROR';
    }
    return 'VALID';
  };

  const deviceIdentifierValidationState =
    calculateValidationState(validationResult);

  return {
    validationState: deviceIdentifierValidationState,
    validate: handleDeviceIdentifierValidation,
  };
};
