import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import { ErrorMessage } from '../../../components/Errors';
import {
  useStoreyUpdateMutation,
  useStoreyEditQuery,
} from '../../../__generated__/types';

import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { StoreyForm, StoreyFormData } from '../common/StoreyForm';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const StoreyEditContainer: React.FC = () => {
  const { t } = useTranslation(['storeys', 'general']);
  const navigate = useNavigate();
  const { storeyId } = useParams();
  const { setTitle } = useHeader();

  const { loading, error, data } = useStoreyEditQuery({
    variables: { storeyId },
  });

  const storey = data?.storey;

  const hierarchy = storey?.building?.site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: storey.building.site.id,
            name: storey.building.site.name,
          },
          {
            type: Entities.BUILDING,
            id: storey.building.id,
            name: storey.building.name,
          },
          {
            type: Entities.STOREY,
            id: storeyId,
            name: storey.name,
          },
        ],
        t,
      ).concat({
        title: t('storeys:breadcrumb.edit'),
        location: entityLocation(Entities.STOREY, `${storeyId}/edit`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('storeys:breadcrumb.edit') });
  }, [setTitle, t]);

  const [updateStorey, { loading: loadingUpdate, error: errorUpdate }] =
    useStoreyUpdateMutation({
      onCompleted: () => navigate(entityLocation(Entities.STOREY, storeyId)),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: StoreyFormData) =>
    updateStorey({
      variables: {
        storeyInput: {
          id: storeyId,
          name: inputData.name,
          description: inputData.description,
        },
      },
    });

  const handleDiscard = () => {
    navigate(entityLocation(Entities.STOREY, storeyId));
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !storey) {
    return (
      <NotFound
        title={t('storeys:details.notFound.title') ?? ''}
        subtitle={t('storeys:details.notFound.subtitle') ?? ''}
        buttonText={t('storeys:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.STOREY.path)}
      />
    );
  }

  return (
    <>
      <StoreyForm
        name={storey?.name ?? ''}
        description={storey?.description ?? ''}
        loading={loading || loadingUpdate}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
