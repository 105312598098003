import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { getTenant, updateTenant } from '../../../api/user';
import { useHeader } from '../../../contexts/header-context';
import { TenantEdit, TenantEditData } from './TenantEdit';
import { NotFound } from '../../errorScreens';

export const TenantEditContainer: React.FC = () => {
  const { t } = useTranslation(['tenants', 'general']);
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const { setTitle, setLoading } = useHeader();

  const {
    isLoading: loading,
    error,
    data: response,
  } = useQuery({
    queryKey: ['getTenant', tenantId],
    queryFn: () => getTenant(tenantId),
    onError: (err: ApiError) => err,
  });
  const tenant = response?.data ?? null;

  const hierarchy = tenant
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.TENANT,
            id: tenantId,
            name: tenant.name,
          },
        ],
        t,
      ).concat({
        title: t('tenants:breadcrumb.edit'),
        location: entityLocation(Entities.TENANT, `${tenantId}/edit`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('tenants:breadcrumb.edit') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const {
    mutate: update,
    isLoading: loadingUpdate,
    error: errorUpdate,
  } = useMutation({
    mutationFn: updateTenant,
    onSuccess: () => navigate(entityLocation(Entities.TENANT, tenantId)),
    onError: (err: ApiError) => err,
  });

  const handleSubmit = (tenantData: TenantEditData) =>
    update({
      tenantId,
      name: tenantData.name,
      contactFirstName: tenantData.contactFirstName,
      contactLastName: tenantData.contactLastName,
      contactEmail: tenantData.contactEmail,
    });

  const handleDiscard = () => {
    navigate(entityLocation(Entities.TENANT, tenantId));
  };

  if (error instanceof Error) {
    if (error.status === 404) {
      return (
        <NotFound
          title={t('tenants:details.notFound.title') ?? ''}
          subtitle={t('tenants:details.notFound.subtitle') ?? ''}
          buttonText={t('tenants:details.notFound.buttonText') ?? ''}
          buttonOnClick={() => navigate(Entities.TENANT.path)}
        />
      );
    }
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <TenantEdit
        loading={loading || loadingUpdate}
        name={tenant?.name ?? ''}
        slug={tenant?.slug ?? ''}
        contactFirstName={tenant?.contactFirstName ?? ''}
        contactLastName={tenant?.contactLastName ?? ''}
        contactEmail={tenant?.contactEmail ?? ''}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
