import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { TenantDelete } from './TenantDelete';
import { deleteTenant } from '../../../api/user';

interface TenantDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error) => void;
}

export const TenantDeleteContainer: React.FC<TenantDeleteContainerProps> = ({
  id,
  isOpen,
  onCompleted,
  onError,
  onCancel,
}) => {
  const {
    mutate: action,
    error,
    isLoading: loading,
  } = useMutation({
    mutationFn: deleteTenant,
    onSuccess: onCompleted,
    onError,
  });

  const handleConfirm = () => action(id);

  return (
    <TenantDelete
      error={error as ApiError}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
