import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { ErrorMessage } from '../../../components/Errors';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import {
  useSiteEditUpdateMutation,
  useSiteEditDetailsQuery,
} from '../../../__generated__/types';
import { SiteEdit, SiteEditData } from './SiteEdit';
import { useHeader } from '../../../contexts/header-context';
import { NotFound } from '../../errorScreens';

export const SiteEditContainer: React.FC = () => {
  const { t } = useTranslation(['sites', 'general']);
  const navigate = useNavigate();
  const { siteId } = useParams();
  const { setTitle, setLoading } = useHeader();

  const { loading, error, data } = useSiteEditDetailsQuery({
    variables: { siteId },
  });
  const site = data?.site;

  const hierarchy = site
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.SITE,
            id: siteId,
            name: site.name,
          },
        ],
        t,
      ).concat({
        title: t('sites:breadcrumb.edit'),
        location: entityLocation(Entities.SITE, `${siteId}/edit`),
      })
    : [];

  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('sites:breadcrumb.edit') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  const [update, { loading: loadingUpdate, error: errorUpdate }] =
    useSiteEditUpdateMutation({
      onCompleted: () => navigate(entityLocation(Entities.SITE, siteId)),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: SiteEditData) => {
    if (!site?.id) {
      return;
    }
    // eslint-disable-next-line no-void
    void update({ variables: { site: { id: site.id, ...inputData } } });
  };

  const handleDiscard = () => {
    navigate(entityLocation(Entities.SITE, siteId));
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !site) {
    return (
      <NotFound
        title={t('sites:details.notFound.title') ?? ''}
        subtitle={t('sites:details.notFound.subtitle') ?? ''}
        buttonText={t('sites:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.SITE.path)}
      />
    );
  }

  return (
    <>
      <SiteEdit
        loading={loading || loadingUpdate}
        name={site?.name ?? ''}
        description={site?.description ?? ''}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[errorUpdate]}
      />
    </>
  );
};
