import React from 'react';
import { Tooltip } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Color } from '../../constants/Colors';

const classes = createClasses({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginLeft: '10px',
  },
});

interface AttributeDecoratorProps {
  required: boolean;
  hasValue: boolean;
}

export const AttributeDecorator: React.FC<AttributeDecoratorProps> = ({
  required,
  hasValue,
}) => {
  const { t } = useTranslation(['general']);

  return (
    <div className={classes.root}>
      {required && !hasValue && (
        <Tooltip
          arrow
          placement="top"
          title={t('general:attributeDecorator.requiredWithoutValue') ?? ''}
        >
          <Icons.Warning
            className={classes.icon}
            fontSize="medium"
            htmlColor={Color.warning}
            data-testid="warning-icon"
          />
        </Tooltip>
      )}
    </div>
  );
};
