import React, { useEffect } from 'react';
import { ProtectedRoute, Permissions } from '@kp/react-sdk';
import { Routes, Route } from 'react-router-dom';
import { StoreyCreateContainer } from './create/StoreyCreateContainer';
import { StoreyDetailsContainer } from './details/StoreyDetailsContainer';
import { StoreyEditContainer } from './edit/StoreyEditContainer';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';

const Icon = Pages.SITES.icon;

export const StoreysRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route
        path="/new"
        element={
          <ProtectedRoute
            element={<StoreyCreateContainer />}
            permission={Permissions.StoreysWrite}
          />
        }
      />
      <Route path="/:storeyId" element={<StoreyDetailsContainer />} />
      <Route
        path="/:storeyId/edit"
        element={
          <ProtectedRoute
            element={<StoreyEditContainer />}
            permission={Permissions.StoreysWrite}
          />
        }
      />
    </Routes>
  );
};
