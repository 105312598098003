import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { createClasses, LogoB2B } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { FancyCard } from '../../../components/FancyCard';
import { TextField } from '../../../components/Fields';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  root: {
    textAlign: 'left',
  },
  content: {
    padding: '32px 16px 8px 16px',
  },
  footer: {
    padding: '8px 16px 28px 16px',
  },
  actions: {
    marginTop: 0,
    marginBottom: 0,
  },
  field: {
    minHeight: '80px',
    '& + &': {
      marginTop: '16px',
    },
  },
  formDivider: {
    margin: '16px 0 32px 0',
  },
  subtitle: {
    margin: '8px 0 24px 0',
  },
  checkbox: {
    margin: '0 0 16px 0',
  },
  lastDivider: {
    margin: '16px 0 16px 0',
  },
  detailsWrapper: {
    display: 'flex',
    height: '100%',
  },
  detailsSeparator: {
    height: '100%',
    marginRight: '24px',
    marginLeft: '16px',
  },
  detailsContent: {
    flexGrow: 1,
  },
  detailItem: {
    paddingLeft: 0,
  },
  detailList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  error: {
    color: 'red',
    padding: '32px 16px 0 16px',
  },
  copyright: {
    marginTop: '15px',
    textAlign: 'center',
    color: Color.copyright,
  },
  logo: {
    display: 'flex',
    marginBottom: '15px',
    justifyContent: 'center',
  },
});

export interface RegisterData {
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
}

interface RegisterProps {
  onSubmit: (data: RegisterData) => void;
  email: string;
  tenant: string;
  loading?: boolean;
  error?: string;
}

export const AuthRegister: React.FC<RegisterProps> = ({
  onSubmit,
  email,
  tenant,
  loading,
  error,
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const { t } = useTranslation(['auth', 'general']);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('general:errors.required') ?? ''),
    lastName: Yup.string().required(t('general:errors.required') ?? ''),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&'"`^.,;:|~+=<>(){}[\]_/-])[a-zA-Z\d@$!%*#?&'"`^.,;:|~+=<>(){}[\]_/-]{8,}$/,
        t('auth:registerForm.passwordError') ?? '',
      )
      .required(t('general:errors.required') ?? ''),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password')],
      t('auth:registerForm.passwordConfirmationError') ?? '',
    ),
  });

  const endAdornment = (field: string) => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        edge="end"
        data-testid={`visibility-toggle-${field}`}
      >
        {isPasswordVisible ? (
          <Icons.VisibilityOff data-testid={`icon-visibility-off-${field}`} />
        ) : (
          <Icons.Visibility data-testid={`icon-visibility-on-${field}`} />
        )}
      </IconButton>
    </InputAdornment>
  );

  const defaultValues: RegisterData = {
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <Container maxWidth="md">
      <div className={classes.root}>
        <div className={classes.logo}>
          <LogoB2B />
        </div>
        <FancyCard title={t('auth:registerForm.title')} loading={loading}>
          {error && (
            <>
              <Typography variant="body2" className={classes.error}>
                {t('auth:registerForm.errorTitle')}
                <br />
                {`${t('auth:registerForm.error')}: ${error}`}
              </Typography>
            </>
          )}

          <FormProvider {...methods}>
            <div className={classes.content}>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12} md={6}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label={t('auth:registerForm.fields.firstName')}
                    variant="outlined"
                    disabled={loading}
                    className={classes.field}
                  />
                  <TextField
                    id="lastName"
                    name="lastName"
                    label={t('auth:registerForm.fields.lastName')}
                    variant="outlined"
                    disabled={loading}
                    className={classes.field}
                  />
                  <Divider className={classes.formDivider} />
                  <TextField
                    id="password"
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    disabled={loading}
                    label={t('auth:registerForm.fields.password')}
                    variant="outlined"
                    className={classes.field}
                    InputProps={{
                      endAdornment: endAdornment('password'),
                    }}
                  />
                  <TextField
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type={isPasswordVisible ? 'text' : 'password'}
                    label={t('auth:registerForm.fields.passwordConfirmation')}
                    disabled={loading}
                    variant="outlined"
                    className={classes.field}
                    InputProps={{
                      endAdornment: endAdornment('passwordConfirmation'),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.detailsWrapper}>
                    <div className={classes.detailsSeparator}>
                      <Divider orientation="vertical" />
                    </div>
                    <div className={classes.detailsContent}>
                      <Typography
                        variant="subtitle1"
                        className={classes.subtitle}
                      >
                        {t('auth:registerForm.subtitle')}
                      </Typography>
                      <InfoList className={classes.detailList}>
                        <InfoListItem
                          term={t('auth:registerForm.detail.email')}
                          itemClassName={classes.detailItem}
                        >
                          {email}
                        </InfoListItem>
                        <InfoListItem
                          term={t('auth:registerForm.detail.tenant')}
                          itemClassName={classes.detailItem}
                        >
                          {tenant}
                        </InfoListItem>
                      </InfoList>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Divider className={classes.lastDivider} />
            <div className={classes.footer}>
              <div className={classes.checkbox}>
                <FormControlLabel
                  value={confirmed}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(event) => setConfirmed(event.target.checked)}
                    />
                  }
                  label={String(t('auth:registerForm.terms'))}
                  labelPlacement="end"
                />
              </div>
              <ActionsBar className={classes.actions}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  disabled={!confirmed || isSubmitting || loading}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  aria-label="register-button"
                >
                  {t('auth:registerForm.submit')}
                </Button>
              </ActionsBar>
            </div>
          </FormProvider>
        </FancyCard>
        <div className={classes.copyright}>
          <Typography variant="caption">{t('general:companyInfo')}</Typography>
        </div>
      </div>
    </Container>
  );
};
