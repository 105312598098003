import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Link } from '../../../components/Link';
import { DataTable } from '../../../components/DataTable';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { Address, createAddressString } from '../../../utils/helpers';
import { EmptyBanner } from '../../../components/EmptyBanner';

type Building = {
  id: string;
  name: string;
  storeys: Array<{ id: string; name: string }>;
  location?: Address;
  dimension?: null | string;
};
interface SiteDetailsBuildingsProps {
  buildings: Array<Building>;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onAdd?: () => void;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
}

export const SiteDetailsBuildings: React.FC<SiteDetailsBuildingsProps> = ({
  buildings,
  onChangePageSize,
  onChangePage,
  onAdd,
  page,
  pageSize,
  count,
  loading,
}) => {
  const { t } = useTranslation(['sites', 'general']);

  const headers = [
    {
      key: t('sites:details.buildingsList.headers.name'),
      value: t('sites:details.buildingsList.headers.name'),
    },
    {
      key: t('sites:details.buildingsList.headers.address'),
      value: t('sites:details.buildingsList.headers.address'),
    },
    {
      key: t('sites:details.buildingsList.headers.storeys'),
      value: t('sites:details.buildingsList.headers.storeys'),
    },
    {
      key: t('sites:details.buildingsList.headers.dimension'),
      value: t('sites:details.buildingsList.headers.dimension'),
    },
  ];

  const rows = (buildings ?? []).map((building) => {
    return {
      key: `${building.id}`,
      cells: [
        {
          key: `${building.id}-cell-name`,
          element: (
            <Link to={entityLocation(Entities.BUILDING, building.id)}>
              {building.name}
            </Link>
          ),
        },
        {
          key: `${building.id}-cell-address`,
          element: createAddressString(building?.location),
        },
        {
          key: `${building.id}-cell-storeys`,
          element: building.storeys?.length ?? 0,
        },
        {
          key: `${building.id}-cell-dimension`,
          element: building.dimension,
          isLast: false,
        },
      ],
    };
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        {!loading && (!buildings || !buildings.length) ? (
          <EmptyBanner
            description={t('sites:details.buildings.empty')}
            actionLabel={t('sites:details.buildings.addBuilding')}
            onClickAction={onAdd}
          />
        ) : (
          <DataTable
            actions={
              onAdd && (
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  startIcon={<Icons.Add />}
                  onClick={onAdd}
                >
                  {t('sites:details.buildings.addBuilding')}
                </Button>
              )
            }
            title={t('sites:details.buildings.title')}
            loading={loading}
            headers={headers}
            rows={rows}
            showPagination
            page={page}
            pageSize={pageSize}
            count={count}
            onChangePage={onChangePage}
            onChangePageSize={onChangePageSize}
          />
        )}
      </Grid>
    </Grid>
  );
};
