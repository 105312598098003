import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingBackdrop } from '../../../components/Loading';
import { ConfirmAlert, ErrorAlert } from '../../../components/Alerts';

interface RegisteredApplicationDeleteProps {
  error?: Error;
  isLoading?: boolean;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const RegisteredApplicationDelete: React.FC<
  RegisteredApplicationDeleteProps
> = ({ error, isLoading = false, isOpen, onConfirm, onClose }) => {
  const { t } = useTranslation(['registeredApplications', 'general']);

  return (
    <>
      <LoadingBackdrop open={isLoading} />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error]}
      />
      <ConfirmAlert
        open={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        title={t('registeredApplications:deleteAlert.title')}
        message={t('registeredApplications:deleteAlert.message')}
        cancelButton={t('general:buttons.cancel')}
        confirmButton={t('general:buttons.delete')}
      />
    </>
  );
};
