import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorAlert } from '../../../../components/Alerts';
import { entityLocation } from '../../../../utils/entity';
import * as Entities from '../../../../constants/Entities';
import {
  useDeviceUpdateMutation,
  useDeviceEditDetailsQuery,
} from '../../../../__generated__/types';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { ErrorMessage } from '../../../../components/Errors';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';
import { GatewayEdit, GatewayEditData } from './GatewayEdit';
import { NotFound } from '../../../errorScreens';

export const GatewayEditContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general']);
  const navigate = useNavigate();
  const { setTitle, setLoading } = useHeader();
  const { deviceId } = useParams();

  const { loading, error, data } = useDeviceEditDetailsQuery({
    variables: { deviceId },
  });
  const device = data?.device;

  // All device models is only the device model of that device, since we don't allow
  // to edit the device model as it is immutable
  const deviceModels = device?.deviceModel ? [device.deviceModel] : [];
  const allDeviceModels = deviceModels.map((dm) => ({
    id: dm.id,
    name: dm.name,
    isGateway: dm.deviceType?.isGateway ?? false,
  }));

  const hierarchy = device
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: device.id,
            name: device.name || device.id,
          },
        ],
        t,
      ).concat({
        title: t('devices:breadcrumb.edit'),
        location: entityLocation(Entities.DEVICE, `${device.id}/edit`),
      })
    : [];
  useBreadcrumb(hierarchy);

  const [update, { loading: loadingUpdate, error: errorUpdate }] =
    useDeviceUpdateMutation({
      onCompleted: () => navigate(entityLocation(Entities.DEVICE, deviceId)),
      onError: (e) => console.warn(e),
    });

  const handleSubmit = (inputData: GatewayEditData) =>
    update({
      variables: {
        device: {
          id: deviceId,
          name: inputData.name,
          description: inputData.description,
          serialNo: inputData.serialNo,
        },
      },
    });

  const handleDiscard = () => {
    navigate(entityLocation(Entities.DEVICE, deviceId));
  };

  const disabledFieldIds = ['deviceIdentifier', 'deviceModelId'];

  useEffect(() => {
    setTitle({ main: t('devices:breadcrumb.edit') });
  }, [setTitle, t]);

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!loading && !device) {
    return (
      <NotFound
        title={t('devices:details.notFound.title') ?? ''}
        subtitle={t('devices:details.notFound.subtitle') ?? ''}
        buttonText={t('devices:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }
  return (
    <>
      <GatewayEdit
        name={device?.name ?? ''}
        description={device?.description ?? ''}
        serialNo={device?.serialNo ?? ''}
        deviceModelId={device?.deviceModelId ?? '0'}
        deviceIdentifier={device?.deviceIdentifier ?? ''}
        loading={loading || loadingUpdate}
        allDeviceModels={allDeviceModels}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
        disabledFieldIds={disabledFieldIds}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[error, errorUpdate]}
      />
    </>
  );
};
