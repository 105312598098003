import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import {
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { PermissionFormGroup } from './PermissionFormGroup';
import { KnownPermissionsValueType } from '../../../constants/Permissions';
import { PermissionFormTemplates } from './PermissionFormTemplates';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 28px 16px',
  },
  checkboxCell: {
    width: '30px',
  },
  infoBox: {
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',
  },
  icon: {
    margin: '4px',
  },
});

export type RegisteredApplicationFormPermissionsData = {
  permissions: KnownPermissionsValueType;
};

export type RegisteredApplicationFormPermissionsProps = {
  loading?: boolean;
  showTemplates?: boolean;
};

export const RegisteredApplicationFormPermissions: React.FC<RegisteredApplicationFormPermissionsProps> =
  React.memo(({ loading, showTemplates = false }) => {
    const { t } = useTranslation(['registeredApplications', 'general']);
    const [enabled, setEnabled] = useState(true);
    const enableForm = (customSelected: boolean) => {
      setEnabled(customSelected);
    };

    return (
      <FancyCard
        title={t('general:permissions.headers.title')}
        loading={loading}
      >
        <Grid
          container
          spacing={1}
          direction="column"
          className={classes.cardContent}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="body2" className={classes.infoBox}>
              {t('registeredApplications:permissions.info')}
            </Typography>
            <Typography variant="body2" className={classes.infoBox}>
              <Icons.Warning
                className={classes.icon}
                fontSize="medium"
                htmlColor={Color.warning}
                data-testid="warning-icon"
              />
              {t('registeredApplications:permissions.warning')}
            </Typography>
          </Grid>

          {showTemplates && <PermissionFormTemplates onCustom={enableForm} />}

          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.infoBox}>
              {t('registeredApplications:permissions.details.title')}
            </Typography>
            <Typography variant="body2" className={classes.infoBox}>
              {t('registeredApplications:permissions.details.info')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper elevation={1}>
              <TableContainer>
                <Table aria-label="permissions table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell
                        align="center"
                        className={classes.checkboxCell}
                      >
                        {t('general:permissions.headers.read')}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.checkboxCell}
                      >
                        {t('general:permissions.headers.write')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <PermissionFormGroup
                    title={t('registeredApplications:permissions.headers.data')}
                    permissionSet={'data'}
                    enabled={enabled}
                  />
                  <PermissionFormGroup
                    title={t(
                      'registeredApplications:permissions.headers.users',
                    )}
                    permissionSet={'users'}
                    enabled={enabled}
                  />
                  <PermissionFormGroup
                    title={t(
                      'registeredApplications:permissions.headers.applications',
                    )}
                    permissionSet={'applications'}
                    enabled={enabled}
                  />
                  <PermissionFormGroup
                    title={t(
                      'registeredApplications:permissions.headers.other',
                    )}
                    permissionSet={'other'}
                    enabled={enabled}
                  />
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </FancyCard>
    );
  });

RegisteredApplicationFormPermissions.displayName =
  'RegisteredApplicationFormPermissions';
