import { countries } from 'countries-list';
import { ApolloError } from '@apollo/client';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { Location } from '../__generated__/types';

export const countryCodeToCountry = (countryCode?: string | null): string => {
  return countryCode && Object.keys(countries).includes(countryCode)
    ? countries[countryCode as keyof typeof countries]?.name
    : countryCode ?? '';
};

export type Address = Pick<
  Location,
  'street' | 'no' | 'zip' | 'city' | 'country'
> | null;

export const createAddressString = (address?: Address): string => {
  if (!address) return '';

  let result = '';
  if (address.street) result += address.street;
  if (address.no) result += ` ${address.no}`;
  if (result.length) result += ',';
  if (address.zip) result += ` ${address.zip}`;
  if (address.city) result += ` ${address.city}`;
  if (result.length && !result.endsWith(',')) result += ',';
  if (address.country) result += ` ${countryCodeToCountry(address.country)}`;
  if (result.endsWith(',')) result = result.slice(0, -1);
  return result.trim();
};

export const createRangeString = (
  capability?: {
    unitSymbol?: string;
    technicalMin?: number;
    technicalMax?: number;
  } | null,
): string => {
  if (!capability) return '-';

  const symbol = capability.unitSymbol ?? '';

  if (
    capability.technicalMin !== undefined &&
    capability.technicalMax === undefined
  )
    return `> ${capability.technicalMin}${symbol}`;
  if (
    capability.technicalMin === undefined &&
    capability.technicalMax !== undefined
  )
    return `< ${capability.technicalMax}${symbol}`;

  if (
    capability.technicalMin !== undefined &&
    capability.technicalMax !== undefined
  )
    return `${capability.technicalMin}${symbol} - ${capability.technicalMax}${symbol}`;

  return '-';
};

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export const getErrorMessage = (
  error?: string | Error | ApolloError | ApiError | null,
): { message: string; details?: string } => {
  let message = '';
  let details = '';
  if (error) {
    if (typeof error === 'string') {
      return { message: error };
    }
    if ('graphQLErrors' in error && error.graphQLErrors) {
      const messages = error.graphQLErrors.map((e) => e.message).join(',');
      details += messages;
    }
    if ('clientErrors' in error && error.clientErrors) {
      const causes = error.clientErrors
        .map((e) => e.cause?.toString())
        .filter((cause) => cause)
        .join(',');
      details += causes;
    }
    if ('networkError' in error && error.networkError) {
      const { networkError } = error;
      if ('result' in networkError && networkError.result) {
        if (networkError.result.error?.message) {
          details += networkError.result.error.message;
        } else {
          details += networkError.result.toString();
        }
      }
    }

    if ('body' in error && error.body) {
      message = error.body.message;
      details += error.body.code ? `(${error.body.code}) - ` : '';
      details += error.cause ? error.cause : '';
    }
    if ('message' in error && error.message) {
      message = String(error.message);
    }
    if ('statusText' in error && error.statusText) {
      message = String(error.statusText);
    }
  }
  if (message === details) details = '';
  return { message, details };
};
