import React from 'react';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { EmptyBanner } from '../../../components/EmptyBanner';
import {
  DataTable,
  DataTableHeader,
  DataTableRow,
} from '../../../components/DataTable';
import { Link } from '../../../components/Link';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { User } from '../../../api/user';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  showInvitations: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: '8px',
  },
});

interface UsersListProps {
  users: User[];
  onAdd?: () => void;
  onShowInvitations?: () => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
  linkToDetails: boolean;
}

export const UsersList: React.FC<UsersListProps> = ({
  users,
  onAdd,
  onShowInvitations,
  onChangePage,
  onChangePageSize,
  page,
  pageSize,
  count,
  loading,
  linkToDetails,
}) => {
  const { t } = useTranslation(['users', 'general']);

  const headers: DataTableHeader[] = [
    {
      key: 'name',
      value: t('users:headers.name'),
    },
    {
      key: 'email',
      value: t('users:headers.email'),
    },
    {
      key: 'roles',
      value: t('users:headers.roles'),
    },
    {
      key: 'createdAt',
      value: t('users:headers.createdAt'),
    },
  ];

  const rows: DataTableRow[] = (users ?? []).map((user) => ({
    key: `${user.id}-row`,
    cells: [
      {
        key: `${user.id}-cell-name`,
        element: linkToDetails ? (
          <Link to={entityLocation(Entities.USER, user.id)} data-testid="sort">
            {`${user.firstName} ${user.lastName}`}
          </Link>
        ) : (
          `${user.firstName} ${user.lastName}`
        ),
      },
      {
        key: `${user.id}-cell-email`,
        element: user.email || '-',
      },
      {
        key: `${user.id}-cell-roles`,
        element: user.roles?.map(({ name }, index) => (
          <div key={index.toString()}>{name}</div>
        )),
      },
      {
        key: `${user.id}-cell-createdAt`,
        element: <FormattedDate value={user.createdAt} />,
        isLast: false,
      },
    ],
  }));

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {!loading && (!users || !users.length) ? (
            <>
              {onShowInvitations && (
                <div className={classes.showInvitations}>
                  <Button
                    onClick={onShowInvitations}
                    color="secondary"
                    variant="outlined"
                    size="large"
                    aria-label="show-all-button"
                  >
                    {t('users:actions.showInvitations')}
                  </Button>
                </div>
              )}
              <EmptyBanner
                description={t('users:list.empty')}
                actionLabel={t('users:actions.addButton')}
                onClickAction={onAdd}
              />
            </>
          ) : (
            <DataTable
              actions={
                <>
                  {onShowInvitations && (
                    <Button
                      onClick={onShowInvitations}
                      color="secondary"
                      size="large"
                      aria-label="show-all-button"
                    >
                      {t('users:actions.showInvitations')}
                    </Button>
                  )}
                  {onAdd && (
                    <Button
                      className={classes.actionButton}
                      color="secondary"
                      variant="outlined"
                      size="large"
                      startIcon={<Icons.Add />}
                      onClick={onAdd}
                    >
                      {t('users:actions.addButton')}
                    </Button>
                  )}
                </>
              }
              loading={loading}
              headers={headers}
              rows={rows}
              showPagination
              page={page}
              pageSize={pageSize}
              count={count}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
