import React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import {
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import { KnownPermissionsType } from '../../constants/Permissions';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  infoIcon: {
    marginRight: '8px',
    display: 'block',
  },
});

export type PermissionType = KnownPermissionsType & {
  hasWrite?: boolean;
  hasRead?: boolean;
};

type PermissionsTableProps = {
  permissions: PermissionType[];
  showHeader?: boolean;
};

export const PermissionsTable: React.FC<PermissionsTableProps> = ({
  permissions,
}) => {
  const { t } = useTranslation(['general']);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left" colSpan={2}>
            {t('general:permissions.headers.title')}
          </TableCell>
          <TableCell align="center">
            {t('general:permissions.headers.read')}
          </TableCell>
          <TableCell align="center">
            {t('general:permissions.headers.write')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {permissions.map((permission) => (
          <TableRow key={`${permission.id}-row`}>
            <TableCell align="left" width="30px">
              <Tooltip
                arrow
                placement="right"
                title={
                  <div className={classes.tooltipContent}>
                    {permission.description}
                  </div>
                }
              >
                <Icons.Info
                  fontSize="medium"
                  className={classes.infoIcon}
                  color="action"
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body1">{permission.label}</Typography>
            </TableCell>
            <TableCell align="center">
              {permission.read && (
                <Checkbox
                  color="primary"
                  disabled
                  checked={permission.hasRead}
                  inputProps={{
                    // @ts-ignore
                    'data-testid': `${permission.read}-checkbox`,
                  }}
                />
              )}
            </TableCell>
            <TableCell align="center">
              {permission.write && (
                <Checkbox
                  color="primary"
                  disabled
                  checked={permission.hasWrite}
                  inputProps={{
                    // @ts-ignore
                    'data-testid': `${permission.write}-checkbox`,
                  }}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
