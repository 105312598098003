import React from 'react';
import * as Icons from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../components/Link';
import { DataTable } from '../../../components/DataTable';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { TooltipIcon } from '../../../components/TooltipIcon';

interface DevicesListDevice {
  name: string;
  id: string;
  model: string;
  type: string;
  isLocked: boolean;
}

interface DevicesListProps {
  devices: DevicesListDevice[];
  onAdd?: (type: 'gateway' | 'fielddevice') => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onSearchSubmit: (query: string) => void;
  searchValue: string;
  page: number;
  pageSize: number;
  count: number;
  loading: boolean;
}

export const DevicesList: React.FC<DevicesListProps> = ({
  devices,
  onAdd,
  onChangePageSize,
  onChangePage,
  page,
  pageSize,
  count,
  onSearchSubmit,
  searchValue,
  loading,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const handleAdd = (type: 'gateway' | 'fielddevice') => () => {
    if (onAdd) onAdd(type);
  };

  const headers = [
    {
      key: 'name',
      value: t('devices:list.headers.name'),
    },
    {
      key: 'model',
      value: t('devices:list.headers.deviceModel'),
    },
    {
      key: 'type',
      value: t('devices:list.headers.deviceType'),
    },
    {
      key: 'hasMappings',
      value: (
        <TooltipIcon
          text={t('devices:list.headers.hasMappings')}
          tootltipText={t('devices:list.hasMappingsHeaderTooltip')}
        />
      ),
    },
    {
      key: 'actions',
      value: '',
    },
  ];

  const getHasMappingsValue = (device: DevicesListDevice) => {
    if (device.type === 'Gateway Device') return t('devices:list.notAvailable');

    if (device.isLocked) return t('devices:list.hasMappings');

    return t('devices:list.hasNoMappings');
  };

  const rows = devices.map((device) => ({
    key: `${device.id}-row`,
    cells: [
      {
        key: `${device.id}-cell-name`,
        element: (
          <Link to={entityLocation(Entities.DEVICE, device.id)}>
            {device.name}
          </Link>
        ),
      },
      {
        key: `${device.id}-cell-model`,
        element: device.model,
      },
      {
        key: `${device.id}-cell-type`,
        element: device.type,
      },
      {
        key: `${device.id}-cell-hasMappings`,
        element: getHasMappingsValue(device),
      },
      {
        key: `${device.id}-cell-actions`,
        element: null,
      },
    ],
  }));

  return (
    <DataTable
      actions={
        onAdd && (
          <>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              startIcon={<Icons.Add />}
              onClick={handleAdd('gateway')}
            >
              {t('devices:list.actions.addGatewayButton')}
            </Button>
            <Divider> </Divider>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              startIcon={<Icons.Add />}
              onClick={handleAdd('fielddevice')}
            >
              {t('devices:list.actions.addFieldDeviceButton')}
            </Button>
          </>
        )
      }
      showSearch
      page={page}
      loading={loading}
      pageSize={pageSize}
      count={count}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onSearchSubmit={onSearchSubmit}
      searchValue={searchValue}
      headers={headers}
      rows={rows}
      showPagination
    />
  );
};
