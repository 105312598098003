import { createClasses } from '@kp/react-ui';
import React from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { createRangeString } from '../../../../utils/helpers';
import { Capability } from './MappingStateUtils';

const classes = createClasses({
  tooltipContent: {
    padding: 0,
    fontSize: '14px',
    lineHeight: '16px',
  },
  tooltipItem: {
    padding: 4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
  },
  menuItemIcon: {
    marginLeft: 8,
    fontSize: '0.875rem',
  },
});

const Icon = ({
  isMapped,
  unitsMatch,
}: {
  isMapped: boolean;
  unitsMatch: boolean;
}) => {
  if (isMapped) {
    return (
      <Icons.Check
        fontSize="small"
        color="success"
        className={classes.menuItemIcon}
      />
    );
  }
  if (!isMapped && unitsMatch) {
    return (
      <Icons.BlurCircular
        fontSize="small"
        color="secondary"
        className={classes.menuItemIcon}
      />
    );
  }
  return (
    <Icons.Block
      fontSize="small"
      color="error"
      className={classes.menuItemIcon}
    />
  );
};

type MatchIconWithTooltipProps = {
  capability: Capability;
  unitsMatch: boolean;
};

export const MatchIconWithTooltip: React.FC<MatchIconWithTooltipProps> = ({
  capability,
  unitsMatch,
}) => {
  const { t } = useTranslation(['devices', 'general']);

  const { isMapped, unitName } = capability;
  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <div className={classes.tooltipContent}>
          <div className={classes.row}>
            <div className={classes.tooltipItem}>
              {t('devices:mappings.edit.deviceCapabilities.range')}:
            </div>
            <div className={classes.tooltipItem}>
              {createRangeString(capability)}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.tooltipItem}>
              {t('devices:mappings.edit.deviceCapabilities.unit')}:
            </div>
            <div className={classes.tooltipItem}>{unitName}</div>
          </div>
        </div>
      }
    >
      <span>
        <Icon isMapped={isMapped} unitsMatch={unitsMatch} />
      </span>
    </Tooltip>
  );
};
