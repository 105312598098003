import React, { useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DescriptionField, TextField } from '../../../components/Fields';
import { ActionsBar } from '../../../components/ActionsBar';
import { FancyCard } from '../../../components/FancyCard';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

export type ZoneFormData = {
  name: string;
  description: string;
};

export type ZoneFormProps = ZoneFormData & {
  loading?: boolean;
  onSubmit: (zone: ZoneFormData) => void;
  onDiscard: () => void;
};

export const ZoneForm: React.FC<ZoneFormProps> = ({
  name,
  description,
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation(['zones', 'general']);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('general:errors.required') ?? '')
      .matches(/^\S+.*$/, t('zones:validation.name.startsWith') ?? '')
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? ''),
  });

  const defaultValues: ZoneFormData = {
    name,
    description,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    reset({
      name,
      description,
    });
  }, [reset, name, description]);

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <FancyCard title={t('zones:info.title')} loading={loading}>
        <Grid
          container
          spacing={1}
          direction="column"
          className={classes.cardContent}
        >
          <Grid item xs={12} md={4}>
            <TextField
              disabled={loading}
              id="name"
              name="name"
              label={t('zones:info.name')}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <DescriptionField
              disabled={loading}
              id="description"
              name="description"
            />
          </Grid>
        </Grid>
      </FancyCard>
    </FormProvider>
  );
};
