import React from 'react';
import { ApolloError } from '@apollo/client';
import { ZoneDelete } from './ZoneDelete';
import { useZoneDeleteMutation } from '../../../__generated__/types';

interface ZoneDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const ZoneDeleteContainer: React.FC<ZoneDeleteContainerProps> = ({
  id,
  isOpen,
  onCompleted,
  onError,
  onCancel,
}) => {
  const [deleteZone, { loading, error }] = useZoneDeleteMutation({
    onCompleted,
    onError,
  });

  const handleConfirm = () => deleteZone({ variables: { zoneId: id } });

  return (
    <ZoneDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
