import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { ExistingDevice } from './MappingStateUtils';

const classes = createClasses({
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  select: {
    fontSize: '0.875rem',
  },
});

export type DeviceSelectionDropdownProps = {
  onSelect: (event: SelectChangeEvent<string>) => void;
  deviceOptions?: ExistingDevice[];
  selectedDeviceId?: string;
};
export const DeviceSelectionDropdown: React.FC<
  DeviceSelectionDropdownProps
> = ({ selectedDeviceId, onSelect, deviceOptions }) => {
  const { t } = useTranslation(['devices', 'general']);
  return (
    <FormControl variant="standard" fullWidth>
      <Select
        defaultValue=""
        value={selectedDeviceId ?? ''}
        onChange={onSelect}
        renderValue={(cId) =>
          deviceOptions?.find(({ id }: { id: string }) => id === cId)?.name
        }
        disableUnderline
        SelectDisplayProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'data-testid': 'device-selection-dropdown',
        }}
        className={classes.select}
      >
        <MenuItem value="" dense>
          <em>{t('devices:mappings.edit.scanResult.noneFemale')}</em>
        </MenuItem>
        {deviceOptions
          ? deviceOptions.map((deviceOption) => {
              return (
                <MenuItem
                  key={deviceOption.id}
                  value={deviceOption.id}
                  // disabled={deviceOption.isMapped || !unitsMatch}
                  className={classes.menuItem}
                  dense
                >
                  {deviceOption.name}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
};
