import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../../components/ActionsBar';
import { FancyCard } from '../../../../components/FancyCard';
import { TextField } from '../../../../components/Fields';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  selector: {
    fontFamily: 'monospace',
  },
});

export type DeviceModelAttributeSetData = {
  name: string;
};

type DeviceModelAttributeSetFormProps = DeviceModelAttributeSetData & {
  loading?: boolean;
  onSubmit: (deviceModel: DeviceModelAttributeSetData) => void;
  onDiscard: () => void;
};

export const DeviceModelAttributeSetForm: React.FC<
  DeviceModelAttributeSetFormProps
> = ({ name, loading, onSubmit, onDiscard }) => {
  const { t } = useTranslation(['deviceModels', 'general']);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(250, t('general:errors.maxLength', { max: 250 }) ?? '')
      .required(t('general:errors.required') ?? ''),
  });

  const defaultValues: DeviceModelAttributeSetData = {
    name,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    reset({
      name,
    });
  }, [reset, name]);
  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="stretch" direction="row">
          <Grid item xs={12}>
            <FancyCard
              title={t('deviceModels:capabilities.details.info.title')}
              loading={loading}
            >
              <Grid
                container
                spacing={1}
                className={classes.cardContent}
                direction="column"
              >
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={loading}
                    id="name"
                    name="name"
                    label={t('deviceModels:attributeSet.details.info.name')}
                  />
                </Grid>
              </Grid>
            </FancyCard>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
