import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import * as Icons from '@mui/icons-material';
import { DataTable } from '../../../components/DataTable';
import { Link } from '../../../components/Link';
import * as Entities from '../../../constants/Entities';
import { entityLocation } from '../../../utils/entity';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { Status } from '../../../api/qanteon/common';
import { statuses } from '../qanteonProvision/common/constants';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  root: {
    color: Color.black,
  },
});

export interface Integration {
  integration: string;
  name: string;
  status: Status;
  url: string;
  path: string;
}

interface IntegrationsListProps {
  integrations: Integration[];
  onAdd?: () => void;
  loading: boolean;
  noProvision: boolean;
}

export const IntegrationsList: React.FC<IntegrationsListProps> = ({
  integrations,
  onAdd,
  loading,
  noProvision,
}) => {
  const { t } = useTranslation(['integrations', 'general']);

  const headers = [
    {
      key: 'integration',
      value: t('integrations:list.headers.integration'),
    },
    {
      key: 'name',
      value: t('general:fields.name'),
    },
    {
      key: 'status',
      value: t('integrations:list.headers.status'),
    },
    {
      key: 'url',
      value: t('integrations:list.headers.url'),
    },
  ];

  const rows = integrations.map((integration) => ({
    key: `${integration.integration}-row`,
    cells: [
      {
        key: `${integration.integration}-cell-integration`,
        element: (
          <Link to={entityLocation(Entities.INTEGRATION, integration.path)}>
            {integration.integration}
          </Link>
        ),
      },
      {
        key: `${integration.integration}-cell-name`,
        element: integration.name,
      },
      {
        key: `${integration.integration}-cell-status`,
        element: statuses(t)[integration.status],
      },
      {
        key: `${integration.integration}-cell-url`,
        element: (
          <a
            className={classes.root}
            href={integration.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {integration.url}
          </a>
        ),
        isLast: false,
      },
    ],
  }));

  return (
    <>
      {noProvision ? (
        <EmptyBanner
          description={t('integrations:list.empty')}
          actionLabel={t('integrations:list.actions.addButton')}
          onClickAction={onAdd}
        />
      ) : (
        <DataTable
          actions={
            onAdd && (
              <Button
                data-testid="provision-qanteon"
                color="secondary"
                variant="outlined"
                size="large"
                startIcon={<Icons.Add />}
                onClick={onAdd}
                disabled={!noProvision}
              >
                {t('integrations:list.actions.addButton')}
              </Button>
            )
          }
          headers={headers}
          rows={rows}
          showPagination={false}
          loading={loading}
        />
      )}
    </>
  );
};
