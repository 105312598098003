import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionsBar } from '../../../components/ActionsBar';
import {
  getRegisteredApplicationFormInfoSchema,
  RegisteredApplicationFormInfo,
  RegisteredApplicationFormInfoData,
} from '../common/RegisteredApplicationFormInfo';
import {
  RegisteredApplicationFormUrl,
  RegisteredApplicationFormUrlData,
  getRegisteredApplicationFormUrlSchema,
} from '../common/RegisteredApplicationFormUrl';
import {
  RegisteredApplicationFormRedirectUris,
  RegisteredApplicationFormRedirectUrisData,
  getRegisteredApplicationFormRedirectUrisSchema,
} from '../common/RegisteredApplicationFormRedirectUris';
import {
  RegisteredApplicationFormPermissions,
  RegisteredApplicationFormPermissionsData,
} from '../common/RegisteredApplicationFormPermissions';
import { getKnownPermissions } from '../../../constants/Permissions';

const classes = createClasses({
  actionButton: {
    marginLeft: '8px',
  },
});

export type RegisteredApplicationCreateData =
  RegisteredApplicationFormInfoData &
    RegisteredApplicationFormUrlData &
    RegisteredApplicationFormRedirectUrisData &
    RegisteredApplicationFormPermissionsData;

type RegisteredApplicationCreateProps =
  Partial<RegisteredApplicationCreateData> & {
    loading?: boolean;
    onSubmit: (data: RegisteredApplicationCreateData) => void;
    onDiscard: () => void;
  };

export const RegisteredApplicationCreate: React.FC<
  RegisteredApplicationCreateProps
> = ({ loading, onSubmit, onDiscard }) => {
  const { t } = useTranslation(['registeredApplications', 'general']);

  const validationSchema = getRegisteredApplicationFormInfoSchema(t)
    .concat(getRegisteredApplicationFormUrlSchema(t))
    .concat(getRegisteredApplicationFormRedirectUrisSchema(t));

  const knownPermissions = useMemo(() => getKnownPermissions(t), [t]);
  const defaultValues: RegisteredApplicationCreateData = {
    name: '',
    description: '',
    url: '',
    redirectUris: [{ uri: '' }],
    permissions: knownPermissions,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <ActionsBar>
        <Button
          onClick={onDiscard}
          color="secondary"
          size="large"
          className={classes.actionButton}
          aria-label="discard-button"
        >
          {t('general:buttons.discard')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          className={classes.actionButton}
          color="secondary"
          variant="outlined"
          size="large"
          aria-label="save-button"
          disabled={loading}
        >
          {t('general:buttons.save')}
        </Button>
      </ActionsBar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RegisteredApplicationFormInfo loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <RegisteredApplicationFormUrl loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <RegisteredApplicationFormRedirectUris loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <RegisteredApplicationFormPermissions showTemplates />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
