import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import { FancyCard } from '../../../components/FancyCard';
import { EmptyBanner } from '../../../components/EmptyBanner';
import {
  GridFooterContainer,
  GridRowSelectionModel,
  GridToolbarFilterButton,
  GridValueGetterParams,
  GridFooter,
  DataGrid,
} from '../../../components/DataGrid';

const classes = createClasses({
  tableContainer: {
    height: 270,
  },
  bannerContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  banner: {
    margin: 'auto',
  },
});

const Toolbar = () => (
  <GridFooterContainer>
    <GridToolbarFilterButton />
    <GridFooter />
  </GridFooterContainer>
);
type NoRowsOverlayProps = { children: React.ReactNode };
const NoRowsOverlay: React.FC<NoRowsOverlayProps> = ({ children }) => {
  return <div className={classes.bannerContainer}>{children}</div>;
};

export enum Type {
  gateway = 'gateway',
  fieldDevice = 'field-device',
}
export type FieldDevice = {
  name: string;
  id: string;
  model: string;
  capabilities: Array<{
    id: string;
    name: string;
    unitSymbol: string;
  }>;
  parentDeviceName: string;
  mappingCount?: number;
};
type StatusOverviewFieldDeviceListProps = {
  loading?: boolean;
  devices?: FieldDevice[];
  onSelect: (id?: FieldDevice) => void;
};

export const StatusOverviewFieldDeviceList: React.FC<
  StatusOverviewFieldDeviceListProps
> = ({ loading, devices, onSelect }) => {
  const { t } = useTranslation(['status', 'general']);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );

  useEffect(() => {
    setSelectionModel([]);
  }, [devices]);

  const handleSelect = ([currentId]: GridRowSelectionModel) => {
    const [previousId] = selectionModel;
    const selectedId = previousId === currentId ? undefined : String(currentId);
    const newSelection = previousId === currentId ? [] : [currentId];
    const selectedDevice = devices?.find((d) => d.id === selectedId);
    onSelect(selectedDevice);
    setSelectionModel(newSelection);
  };

  const columns = [
    {
      field: 'name',
      headerName: t('status:overview.fieldDeviceList.headers.name'),
      description: t('status:overview.fieldDeviceList.headers.nameDescription'),
      flex: 1,
    },
    {
      field: 'model',
      headerName: t('status:overview.fieldDeviceList.headers.deviceModel'),
      description: t(
        'status:overview.fieldDeviceList.headers.deviceModelDescription',
      ),
      flex: 1,
    },
    {
      field: 'description',
      headerName: t('status:overview.fieldDeviceList.headers.description'),
      description: t(
        'status:overview.fieldDeviceList.headers.descriptionDescription',
      ),
      flex: 1,
    },
    {
      field: 'mappedCapabilities',
      headerName: t(
        'status:overview.fieldDeviceList.headers.mappedCapabilities',
      ),
      description: t(
        'status:overview.fieldDeviceList.headers.mappedCapabilitiesDescription',
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.mappingCount ?? '-'}/${params.row.capabilities.length}`,
    },
  ];

  return (
    <FancyCard
      title={t('status:overview.fieldDeviceList.title')}
      loading={loading}
    >
      <DataGrid
        loading={loading}
        height={270}
        slotProps={{
          noRowsOverlay: {
            children: devices ? (
              <EmptyBanner
                className={classes.banner}
                description={t('status:overview.noDevices')}
              />
            ) : (
              <EmptyBanner
                className={classes.banner}
                description={t('status:overview.noGateway')}
              />
            ),
          },
        }}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: Toolbar,
        }}
        rows={devices ?? []}
        columns={columns}
        onRowSelectionModelChange={handleSelect}
        rowSelectionModel={selectionModel}
        hideFooter
        hideFooterSelectedRowCount
      />
    </FancyCard>
  );
};
