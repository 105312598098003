import React from 'react';
import { ApolloError } from '@apollo/client';
import { AttributeDelete } from './AttributeDelete';
import { useAttributeDeleteMutation } from '../../../__generated__/types';

interface AttributeDeleteContainerProps {
  id: string;
  isOpen: boolean;
  onCompleted: () => void;
  onCancel: () => void;
  onError: (error: Error | ApolloError) => void;
}

export const AttributeDeleteContainer: React.FC<
  AttributeDeleteContainerProps
> = ({ id, isOpen, onCompleted, onError, onCancel }) => {
  const [deleteAttribute, { loading, error }] = useAttributeDeleteMutation({
    onCompleted,
    onError,
  });

  const handleConfirm = () =>
    deleteAttribute({
      variables: { attributeId: id },
    });

  return (
    <AttributeDelete
      error={error}
      isLoading={loading}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onCancel}
    />
  );
};
