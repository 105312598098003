import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Grid, Typography } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { useFormContext } from 'react-hook-form';
import { FancyCard } from '../../../components/FancyCard';
import { Color } from '../../../constants/Colors';
import { InputBaseField } from '../../../components/Fields/InputBaseField';

const classes = createClasses({
  cardContent: {
    padding: '22px 16px 28px 16px',
  },
  infoBox: {
    marginBottom: '16px',
  },
  inputWrapper: {
    background: Color.inputBackground,
    padding: '8px 16px',
    borderRadius: '4px',
  },
  input: {
    fontSize: '14px',
    fontFamily: 'monospace',
  },
  error: {
    padding: '4px 8px 16px 16px',
    color: 'red',
  },
});

export type RegisteredApplicationFormUrlData = {
  url: string;
};

type RegisteredApplicationFormUrlProps = {
  loading?: boolean;
};

export const getRegisteredApplicationFormUrlSchema = (
  t: TFunction,
): Yup.ObjectSchema<RegisteredApplicationFormUrlData> =>
  Yup.object().shape({
    url: Yup.string()
      .urlWithoutTLD(t('registeredApplications:validation.url'))
      .required(t('general:errors.required') ?? ''),
  });

export const RegisteredApplicationFormUrl: React.FC<RegisteredApplicationFormUrlProps> =
  React.memo(({ loading }) => {
    const { t } = useTranslation(['registeredApplications', 'general']);
    const {
      formState: { errors },
    } = useFormContext<RegisteredApplicationFormUrlData>();

    return (
      <FancyCard
        title={t('registeredApplications:details.url.title')}
        loading={loading}
      >
        <Grid
          container
          spacing={1}
          direction="column"
          className={classes.cardContent}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="body2" className={classes.infoBox}>
              {t('registeredApplications:details.url.info')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.inputWrapper}>
              <InputBaseField
                disabled={loading}
                id="url"
                name="url"
                type="text"
                fullWidth
                placeholder="https://application.kieback-peter.com"
                inputProps={{
                  className: classes.input,
                  'data-testid': 'url',
                }}
              />
            </div>
            {errors.url && (
              <div className={classes.error}>
                <Typography variant="caption">{errors.url.message}</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </FancyCard>
    );
  });

RegisteredApplicationFormUrl.displayName = 'RegisteredApplicationFormUrl';
