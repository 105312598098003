import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmAlert } from '.';

type BasicConfirmAlertProps = {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  title: string;
  message: string;
};

export const BasicConfirmAlert: React.FC<BasicConfirmAlertProps> = ({
  onConfirm,
  onCancel,
  open,
  title,
  message,
}) => {
  const { t } = useTranslation(['general']);

  return (
    <ConfirmAlert
      open={open}
      fullWidth
      onClose={onCancel}
      onConfirm={onConfirm}
      confirmButton={t('general:buttons.ok')}
      cancelButton={t('general:buttons.cancel')}
      closeButton
      title={title}
      message={message}
      aria-labelledby="basic-confirm-dialog-title"
      aria-describedby="basic-confirm-dialog-description"
    />
  );
};
