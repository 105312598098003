import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Permissions, useAuth } from '@kp/react-sdk';
import { saveAs } from 'file-saver';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import {
  ConnectionStates,
  useGatewayStatusQuery,
  useMappingsCreateGatewayDetailsQuery,
} from '../../../../__generated__/types';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { ErrorAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { useHeader } from '../../../../contexts/header-context';
import { ErrorMessage } from '../../../../components/Errors';
import {
  deployMappingDraft as deployMappingDraftFromAPI,
  downloadMappingDraftMappings,
  getReviewData,
} from '../../../../api/bacnet';
import { entityLocation } from '../../../../utils/entity';
import { DeviceMappingsDraftReview } from './DeviceMappingsDraftReview';
import { b64toBlob } from '../../../../utils/file-tools';
import {
  MappingDraftValidationErrorDialog,
  ValidationResult,
} from '../common/MappingDraftValidationErrorDialog';
import { useGatewayDetailsSubscription } from '../../../../hooks/useGatewayDetailsSubscription';
import { NotFound } from '../../../errorScreens';
import { useGatewayStatusSubscription } from '../../../../hooks/useGatewayStatusSubscription';

export const DeviceMappingsDraftReviewContainer: React.FC = () => {
  const { t } = useTranslation(['devices', 'general', 'errors']);
  const { hasPermission } = useAuth();
  const { gatewayId = '0' } = useParams();
  const { setTitle, setLoading } = useHeader();
  const navigate = useNavigate();

  const [mappingDraftValidationResult, setMappingDraftValidationResult] =
    useState<ValidationResult>();

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useMappingsCreateGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;
  const gatewayLocation = dataGateway?.placementOfDevice;

  const { details: gatewayDetails } = useGatewayDetailsSubscription(gatewayId);
  const gatewayStatus = gatewayDetails?.status;

  const { data: initialGatewayStatus } = useGatewayStatusQuery({
    variables: { deviceId: gatewayId },
  });
  const { connectionState: connectionStatus } =
    useGatewayStatusSubscription(gatewayId);

  const gatewayConnectionStatus = useMemo(() => {
    if (!connectionStatus) {
      return (
        initialGatewayStatus?.device?.connectionState ??
        ConnectionStates.Unknown
      );
    }
    return connectionStatus;
  }, [connectionStatus, initialGatewayStatus]);

  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEVICE,
            id: gatewayId,
            name: gateway.name,
            query: { activeTab: 'FieldDevices' },
          },
        ],
        t,
      )
        .concat({
          title: t('devices:mappings.create.breadcrumb'),
          location: entityLocation(
            Entities.DEVICE,
            `${gatewayId}/mappings/draftCreate`,
          ),
        })
        .concat({
          title: t('devices:mappings.review.breadcrumb'),
          location: entityLocation(
            Entities.DEVICE,
            `${gatewayId}/mappings/draftReview`,
          ),
        })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('devices:mappings.review.title'),
      sub:
        gatewayLocation?.siteName && gatewayLocation?.buildingName
          ? `${gatewayLocation?.siteName}, ${gatewayLocation?.buildingName}`
          : '',
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t, gatewayLocation]);

  const {
    data: responseReviewData,
    isLoading: loadingReviewData,
    error: errorReviewData,
  } = useQuery({
    queryKey: ['getReviewData', gatewayId],
    queryFn: () => getReviewData(gatewayId),
    onError: (err: ApiError) => err,
  });
  const reviewData = responseReviewData?.data
    ?.map((data) => data.new)
    .filter((data) => data.bacnetDeviceId && data.bacnetObjectId);

  const {
    mutateAsync: deployMappingDraft,
    isLoading: loadingDeployMappingDraft,
    error: errorDeployMappingDraft,
  } = useMutation({
    mutationFn: deployMappingDraftFromAPI,
    onError: (err: ApiError) => err,
  });

  const {
    mutateAsync: callDownloadMappingDraftMappings,
    isLoading: loadingDownloadMappingDraftMappings,
    error: errorDownloadMappingDraftMappings,
  } = useMutation({
    mutationFn: downloadMappingDraftMappings,
    onError: (err: ApiError) => err,
  });

  const navigateBack = () => {
    navigate(
      entityLocation(Entities.DEVICE, gatewayId, { activeTab: 'Deployments' }),
    );
  };

  const handleDeployMappingDraft = async () => {
    let deployError: ApiError | undefined;
    try {
      await deployMappingDraft(gatewayId);
    } catch (err) {
      deployError = err as ApiError;
    }
    if (!deployError || !deployError.body) {
      navigateBack();
      return;
    }

    const { code, message } = deployError.body;
    if (code === 'DRAFT_VALIDATION_ERROR' && message) {
      try {
        const errors = JSON.parse(message) as ValidationResult['errors'];
        setMappingDraftValidationResult({
          showDialog: true,
          source: 'DEPLOY',
          isValid: false,
          errors,
        });
      } catch (e) {
        console.warn('Error parsing Deploy Mappings Error', e);
      }
    }
  };

  const handleDownloadMappings = async () => {
    let downloadError: ApiError | undefined;
    try {
      const result = await callDownloadMappingDraftMappings(gatewayId);
      if (!result?.data?.fileContent) return;
      const blob = await b64toBlob(result.data.fileContent);
      saveAs(blob, `gatewayConfig-${gatewayId}.zip`);
      navigateBack();
    } catch (err) {
      downloadError = err as ApiError;
    }

    if (!downloadError || !downloadError.body) return;
    const { code, message } = downloadError.body;
    if (code !== 'DRAFT_VALIDATION_ERROR' || !message) return;
    try {
      const errors = JSON.parse(message) as ValidationResult['errors'];
      setMappingDraftValidationResult({
        showDialog: true,
        source: 'DOWNLOAD',
        isValid: false,
        errors,
      });
    } catch (e) {
      console.warn('Error parsing Download Mappings Error', e);
    }
  };
  const handleConfirmError = () => {
    setMappingDraftValidationResult({
      ...mappingDraftValidationResult,
      showDialog: false,
    });
  };

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  if (errorGateway) {
    return <ErrorMessage error={errorGateway} />;
  }
  if (!loadingGateway && !gateway) {
    return (
      <NotFound
        title={t('devices:details.notFound.title') ?? ''}
        subtitle={t('devices:details.notFound.subtitle') ?? ''}
        buttonText={t('devices:details.notFound.buttonText') ?? ''}
        buttonOnClick={() => navigate(Entities.DEVICE.path)}
      />
    );
  }

  return (
    <>
      <DeviceMappingsDraftReview
        loading={
          loadingGateway ||
          loadingReviewData ||
          loadingDeployMappingDraft ||
          loadingDownloadMappingDraftMappings
        }
        reviewData={reviewData ?? []}
        onDeploy={
          hasPermission(Permissions.CommissioningWrite) &&
          gatewayStatus === 'idle' &&
          gatewayConnectionStatus === ConnectionStates.Connected
            ? handleDeployMappingDraft
            : undefined
        }
        onDownload={
          hasPermission(Permissions.CommissioningWrite)
            ? handleDownloadMappings
            : undefined
        }
      />
      <MappingDraftValidationErrorDialog
        validationResult={mappingDraftValidationResult}
        actions={[
          {
            onClick: handleConfirmError,
            title: t('general:buttons.ok'),
          },
        ]}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        errors={[
          errorDeployMappingDraft?.body.code !== 'DRAFT_VALIDATION_ERROR'
            ? errorDeployMappingDraft
            : undefined,
          errorDownloadMappingDraftMappings?.body.code !==
          'DRAFT_VALIDATION_ERROR'
            ? errorDownloadMappingDraftMappings
            : undefined,
          errorReviewData,
        ]}
      />
    </>
  );
};
