import React from 'react';
import { Box, Divider } from '@mui/material';
import { createClasses } from '@kp/react-ui';
import { DeviceAttributesAttributeContainer } from './DeviceAttributesAttributeContainer';
import { DataTypes } from '../../../__generated__/types';

const classes = createClasses({
  container: {
    width: '100%',
    marginBottom: '16px',
  },
  attributeSetTitle: {
    marginBottom: '10px',
    marginLeft: '20px',
    paddingTop: '20px',
  },
});

export interface AttributeSet {
  id: string;
  name: string;
  attributes: Array<{
    id: string;
    dataType: DataTypes | null;
    name: string;
    required: boolean;
    value: string;
    exists: boolean;
  }>;
}

interface DeviceAttributesProps {
  attributeSets: Array<AttributeSet>;
  deviceId: string;
  onChange: () => void;
}

export const DeviceAttributes: React.FC<DeviceAttributesProps> = ({
  attributeSets,
  deviceId,
  onChange,
}) => {
  return (
    <Box className={classes.container}>
      {attributeSets &&
        attributeSets.length > 0 &&
        attributeSets.map((attributeSet) => (
          <div key={attributeSet.id}>
            <h3 className={classes.attributeSetTitle}>{attributeSet.name}</h3>
            <Divider />
            {attributeSet.attributes.map((attribute) => (
              <DeviceAttributesAttributeContainer
                exists={attribute.exists}
                key={attribute.id}
                dataType={attribute.dataType}
                id={attribute.id}
                name={attribute.name}
                value={attribute.value}
                required={attribute.required}
                deviceId={deviceId}
                onChange={onChange}
              />
            ))}
          </div>
        ))}
    </Box>
  );
};
